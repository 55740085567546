import { Injectable } from '@angular/core';
import { ActiveState, Store, StoreConfig } from '@datorama/akita';
import { FireAuthState, initialAuthState } from 'akita-ng-fire';


export interface AuthState extends FireAuthState, ActiveState<string> { }

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'auth' })
export class AuthStore extends Store<AuthState> {

    constructor() {
        super(initialAuthState);
    }

}
