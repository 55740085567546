<div class="punch-container">

    <!-- If checking out, show the punch template -->
    <ng-container *ngIf="!isCheckIn; then punchTemplate; else checkingIn"></ng-container>

    <!-- If checking in, go to the corresponding template based on punch allowed -->
    <ng-template #checkingIn>
        <ng-container *ngIf="punchAllowed$ | async; then punchTemplate else punchNotYetAllowed"></ng-container>
    </ng-template>

    <!-- The punch template -->
    <ng-template #punchTemplate>
        <input placeholder="{{ isCheckIn ? ('modal.shift-detail.check-in-message' | translate) : ('modal.shift-detail.check-out-message' | translate) }}"
               [(ngModel)]="messageInput">
        <ui-button-action wide
                          tall
                          [color]="isCheckIn ? 'primary' : 'warn'"
                          (click)="punch()">
            {{ isCheckIn ? ('widget.check-in' | translate) : ('widget.check-out' | translate) }}</ui-button-action>
    </ng-template>

    <!-- The punch not (yet) allowed template -->
    <ng-template #punchNotYetAllowed
                 let-x="$implicit">
        <ui-button-action wide
                          tall
                          [disabled]="true"
                          [loading]="x === null">
            <ng-container *ngIf="x === false">
                {{ 'modal.shift-detail.not-within-range-message' | translate }}
            </ng-container>
        </ui-button-action>
    </ng-template>

</div>