import { Directive, ElementRef, HostBinding, HostListener, Input, Renderer2 } from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { GoogleAnalyticsService } from '../../../../core/services/google-analytics.service';

@Directive()
export class ButtonDirective {
    public get loading(): boolean {
        return this._loading;
    }

    @Input() public set loading(value: boolean) {
        this._loading = value;
        value ? this.disableButton() : this.resetButton();
    }

    public get wide(): boolean | '' {
        return this._wide;
    }
    @Input() @HostBinding('class.wide')
    public set wide(value: boolean | '') {
        if (value === '') this._wide = true;
        else this._wide = !!value;
    }
    public get tall(): boolean | '' {
        return this._tall;
    }
    @Input() @HostBinding('class.tall')
    public set tall(value: boolean | '') {
        if (value === '') this._tall = true;
        else this._tall = !!value;
    }

    @Input() public color: ThemePalette;
    @Input() @HostBinding('class.disabled') public disabled: boolean;
    @Input() @HostBinding('class.transparent') public transparent: boolean;
    @Input() public disableRipple: boolean = true;
    @Input() public type: string = 'button';
    private _wide: boolean;
    private _tall: boolean;
    private _loading: boolean = false;

    constructor(private gas: GoogleAnalyticsService,
        private renderer: Renderer2,
        private elementRef: ElementRef) {
    }

    @HostListener('click', ['$event.target'])
    public click(arg: HTMLElement): void {
        // Whenever a button is clicked, track the click
        this.gas.trackButtonClick(
            // Track the text of the button if present
            arg.innerText.trim() ||
            // Track the class list of the icon if present
            arg.getElementsByTagName('i')[0]?.classList.toString() ||
            // Track as unknown if no text or icon is present
            'Knap uden tekst og ikon',
        );
    }

    private disableButton(): void {
        this.renderer.addClass(
            this.elementRef.nativeElement.children[0],
            'disabled-pointer-events');
        this.renderer.addClass(
            this.elementRef.nativeElement.children[0]?.children[0]?.children[0],
            'hidden-text');
    }

    private resetButton(): void {
        this.renderer.removeClass(
            this.elementRef.nativeElement.children[0],
            'disabled-pointer-events');
        this.renderer.removeClass(
            this.elementRef.nativeElement.children[0]?.children[0]?.children[0],
            'hidden-text');
    }
}
