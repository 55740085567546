import { GPSZone } from '../../core/model';
import { Coordinates } from '../../core/model/Coordinates';

export function isWithinRange(gpsZone: GPSZone, userPosition: Coordinates): boolean {
    const dLat = (userPosition.latitude - gpsZone.geopoint.latitude) * Math.PI / 180;
    const dLon = (userPosition.longitude - gpsZone.geopoint.longitude) * Math.PI / 180;
    const a = 0.5 - Math.cos(dLat) / 2 +
        Math.cos(gpsZone.geopoint.latitude * Math.PI / 180) * Math.cos(userPosition.latitude * Math.PI / 180) * (1 - Math.cos(dLon)) / 2;
    const d = Math.round(6371000 * 2 * Math.asin(Math.sqrt(a)));
    return d < gpsZone.radius;
}
