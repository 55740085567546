<div class="edit-shift-container d-flex flex-column w-100">
    <ui-button-standard *ngIf="!editing"
                        (click)="edit()"
                        wide
                        tall
                        class="edit-button"
                        color="primary">
        <span *ngIf="(autoPunchclockEnabled$ | async); else registerTimeTemplate">
            {{ 'punch-clock.modify-shift-times' | translate }}
        </span>
        <ng-template #registerTimeTemplate>
            <span>
                {{ 'punch-clock.register-shift-times' | translate }}
            </span>
        </ng-template>
    </ui-button-standard>
    <div *ngIf="editing"
         class="editing-container">
        <ui-time-input [punchclock]="true"
                       [(start)]="start"
                       [(end)]="end"></ui-time-input>
        <input [(ngModel)]="message"
               [placeholder]="'punch-clock.why-will-you-edit-times' | translate">
        <ui-button-standard (click)="save()"
                            wide
                            class="save-button"
                            color="primary">
            {{ 'punch-clock.save-new-time' | translate }}
        </ui-button-standard>
        <ui-button-standard (click)="cancel()"
                            wide>
            {{ 'shared.cancel' | translate }}
        </ui-button-standard>
    </div>
</div>