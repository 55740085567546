import { Injectable } from '@angular/core';
import { CanLoad, Router, UrlTree } from '@angular/router';
import { from, Observable, of } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { routes } from '../../app-settings';
import { Employee } from '../model';
import { AuthService, AuthUser, EmployeeService, SidebarService } from '../services';

@Injectable()
export class LoginGuard implements CanLoad {
    constructor(
        private authService: AuthService,
        private employeeService: EmployeeService,
        private router: Router,
        private sidebarService: SidebarService,
    ) { }

    /**
     * This method is called through the CanActivate
     * @param route The route that is attempted
     * @param state The current state of the router
     */
    public canLoad(): Observable<boolean | UrlTree> {
        return this.authService.getUser().pipe(
            // Only look at the user if it's not undefined (still loading)
            filter((user: AuthUser) => user !== undefined),

            // Do the login check
            switchMap((user: AuthUser) => {
                if (!user) {
                    // Otherwise, redirect to the login screen
                    this.sidebarService.disableSidebar();
                    return of(this.router.createUrlTree(
                        [routes.login.path], { queryParams: { path: window.location.pathname } }));
                } else {
                    return from(this.employeeService.getEmployee(user.user_id)).pipe(
                        // Get whether the logged in user has departments
                        map((emp: Employee) => emp.primaryDepartment || emp.departments.length > 0),

                        // If user has none, redirect to no departments route, otherwise proceed
                        map((hasDepartment: boolean) => {
                            if (hasDepartment || user.admin) {
                                this.sidebarService.enableSidebar();
                                return true;
                            } else {
                                this.sidebarService.disableSidebar();
                                return this.router.createUrlTree([routes.pendingApproval.path]);
                            }
                        }),
                    );
                }
            }),
        );
    }
}

