import { animate, animation, AnimationMetadata, AnimationReferenceMetadata, group, keyframes, style, transition, trigger, useAnimation } from '@angular/animations';

/**
 * Reference animations that can be reused in composite animations
 */

export const pulsate: AnimationReferenceMetadata = animation([
    animate('300ms', keyframes([
        style({ transform: 'scale(1)', offset: 0 }),
        style({ transform: 'scale(1.02)', offset: .25 }),
        style({ transform: 'scale(.98)', offset: .50 }),
        style({ transform: 'scale(1)', offset: 1 }),
    ])),
]);

export const fadeInRight: AnimationReferenceMetadata = animation([
    style({ transform: 'translateX(-100%)', opacity: 0 }),
    group([
        animate('600ms ease-out', style({ transform: ' translateX(0)' })),
        animate('600ms 150ms ease-out', style({ opacity: 1 })),
    ]),
]);

export const fadeOutLeft: AnimationReferenceMetadata = animation([
    style({ transform: 'translateX(0)', opacity: 1 }),
    animate('600ms ease-out', style({ transform: 'translateX(-100%)', opacity: 0 })),
]);

export const fadeOutRight: AnimationReferenceMetadata = animation([
    style({ transform: 'translateX(0)', opacity: 1 }),
    animate('600ms ease-out', style({ transform: ' translateX(100%)', opacity: 0 })),
]);

export const scaleIn: AnimationReferenceMetadata = animation([
    style({ transform: 'scale(.5)', opacity: 0, height: 0 }),
    animate('300ms ease-out', style({ transform: 'scale(1)', opacity: 1, height: '*' })),
]);

export const scaleOut: AnimationReferenceMetadata = animation([
    style({ transform: 'scale(1)', opacity: 1, height: '*' }),
    animate('300ms ease-out', style({ transform: 'scale(.5)', opacity: 0, height: 0 })),
]);

export const scaleInOut: AnimationMetadata = trigger('scaleInOut', [
    transition(':enter', useAnimation(scaleIn)),
    transition(':leave', useAnimation(scaleOut)),
]);
