import { Directive, HostListener, Injector, Input, OnInit } from '@angular/core';
import { AbstractControl, NgControl } from '@angular/forms';

@Directive({
    selector: '[appRegexMask]',
})
/**
 * This directive can be put on an input field,
 * then it'll make sure that the input field only can
 * contain the regular expressions you supply as input
 * Ex. [appRegexMask]="^[0-9]$" accepts only numbers 0-9
 */
export class RegexMaskDirective implements OnInit {
    @Input() set appRegexMask(value: string) {
        this.regExpr = new RegExp(value);
    }
    @Input() public regExMaskStartValue: string = '';

    private _oldvalue: string;
    private regExpr: RegExp;
    private control: NgControl;
    constructor(injector: Injector) {
        // This makes sure that there's no error if not applied to a NgControl
        try {
            this.control = injector.get(NgControl);
        } catch (e) { }
    }

    public ngOnInit(): void {
        this._oldvalue = this.regExMaskStartValue;
    }

    /**
     * Handling of the input change event
     * @param $event The input event
     */
    @HostListener('input', ['$event'])
    public change($event: Event): void {
        const item: HTMLInputElement = <HTMLInputElement> $event.target;
        const value: string = item.value;
        let pos: number | null = item.selectionStart; // Get the position of the cursor
        const matchvalue: string = value;

        if (pos === null) return;

        const noMatch: boolean = (!!value && !(this.regExpr.test(matchvalue)));
        if (noMatch) {
            item.selectionStart = item.selectionEnd = pos - 1;

            if (item.value.length < this._oldvalue.length && pos === 0) {
                pos = 2;
            }
            if (this.control) {
                (<AbstractControl> this.control.control).setValue(this._oldvalue, { emit: false });
            }

            item.value = this._oldvalue;
            item.selectionStart = item.selectionEnd = pos - 1; // Recover the position
        } else {
            this._oldvalue = value;
        }
    }
}
