<div class="sign-up-container">
    <div class="sign-up">
        <div class="title style-1">
            {{ 'signup.sign-up-your-company' | translate }}
        </div>
        <form name="sign-up-form"
              [formGroup]="formGroup"
              (submit)="onSubmit()">
            <input autocomplete="organization"
                   formControlName="COMPANY_NAME"
                   required
                   appFloatingLabel="{{ 'signup.enter-your-company-name' | translate }}"
                   [errorMessage]="formGroup.get('COMPANY_NAME')?.errors| getErrorString" />
            <input title="{{ 'signup.enter-both-first-name-and-last-name' | translate }}"
                   autocomplete="name"
                   formControlName="NAME"
                   required
                   appFloatingLabel="{{ 'signup.enter-your-name' | translate }}"
                   [errorMessage]="formGroup.get('NAME')?.errors | getErrorString" />
            <input autocomplete="tel"
                   formControlName="PHONE"
                   required
                   appFloatingLabel="{{ 'shared.phone-number' | translate }}"
                   type="tel"
                   [errorMessage]="formGroup.get('PHONE')?.errors | getErrorString" />
            <input autocomplete="username"
                   formControlName="EMAIL"
                   required
                   type="email"
                   appFloatingLabel="{{ 'signup.enter-your-email' | translate }}"
                   [errorMessage]="formGroup.get('EMAIL')?.errors | getErrorString" />
            <input title="{{ 'signup.password-requirements-text' | translate }}"
                   autocomplete="new-password"
                   formControlName="PASSWORD"
                   required
                   type="password"
                   appFloatingLabel="{{ 'signup.enter-a-new-password' | translate }}"
                   [errorMessage]="formGroup.get('PASSWORD')?.errors | getErrorString" />
            <label>
                <input name="AGREE_TO_TERMS"
                       formControlName="AGREE_TO_TERMS"
                       required
                       type="checkbox"
                       class="ml-2 mr-2"
                       value="1" />
                <span class="style-9">
                    {{ 'signup.i-accept-relions' | translate }}
                    <a href="https://relion.dk/vilkaar-og-betingelser/"
                       target="_blank"
                       rel="noopener noreferrer">
                        {{ 'signup.terms-&-conditions' | translate }}
                    </a>
                </span>
            </label>
            <ui-button-action [disabled]="formGroup.invalid || loading"
                              class="create-profile"
                              color="success">
                <span *ngIf="!loading">
                    {{ 'signup.create-profile' | translate }}
                </span>
                <i class="far fa-spin fa-spinner"
                   *ngIf="loading"></i>
            </ui-button-action>
        </form>
    </div>
</div>