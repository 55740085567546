import { Pipe, PipeTransform } from '@angular/core';
import { Employee } from '../../core/model';
import { Department } from '../../core/model/Department/Department.model';
import { EmployeeWithSalary } from '../../core/services/salary.service';
import { EmployeeRelationPipe } from './employee-relation.pipe';

@Pipe({
    name: 'employeesInDepartment',
})
export class EmployeeRelationsPipe implements PipeTransform {

    constructor(private employeeRelationPipe: EmployeeRelationPipe) { }

    /**
     * Filter a list of employees based on their relation to a department
     * Only show them if they are in the department or if they have hours in the department
     * @param employee The employee to check
     * @param department The department to check
     */
    public transform(employees: EmployeeWithSalary[], department?: Department | null): Employee[] {
        return employees.filter(
            (employee: EmployeeWithSalary) => this.employeeRelationPipe.transform(employee, department) || employee.hours > 0,
        );
    }

}
