<div class="edit-employee-department-container">
    <div class="select-primary-department">
        <div class="title">{{ 'admin.department.select-primary-department' | translate | capitalize }}</div>
        <ng-select class="department-dropdown"
                   [items]="availableDepartments"
                   bindLabel="name"
                   [clearable]="false"
                   notFoundText="{{ 'admin.department.employee-no-departments' | translate | capitalize}}"
                   [(ngModel)]="selectedEmployee.primaryDepartment"
                   (change)="saveEmployee()"
                   [searchable]="false"></ng-select>
        <div class="subtext">
            {{'admin.department.primary-employee-description' | translate | capitalize}}
        </div>
    </div>
    <div class="select-departments">
        <div class="department-admin">
            <ui-button-text color="primary"
                            (click)="openDepartmentAdmin()">
                {{'admin.department.manage-departments' | translate | capitalize}}</ui-button-text>
        </div>
        <div class="department-list">
            <div *ngFor="let department of departments$ | async"
                 class="department-list-item">
                <app-edit-employee-department-item [department]="department"
                                                   [departments$]="departments$"
                                                   [roles$]="roles$"
                                                   [(selectedEmployee)]="selectedEmployee"
                                                   (selectedEmployeeChange)="saveEmployee()">
                </app-edit-employee-department-item>
            </div>
        </div>
    </div>
</div>