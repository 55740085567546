<div class="top-banner no-internet-connection"
     *ngIf="!online">
    {{ 'error.no-internet-connection' | translate }}
</div>

<div class="top-banner new-version-available"
     (click)="refreshPage()"
     *ngIf="online && newVersionStatus === 'new-version-available'">
    <div class="spacing"></div>
    <div>
        {{ 'shared.new-version-message' | translate }}
    </div>
    <i class="far fa-times"
       (click)="postponeNewVersionBanner()"></i>
</div>

<!-- MAIN PAGE -->
<mat-sidenav-container class="h-100">

    <!-- NAVIGATION SIDEBAR -->
    <mat-sidenav *ngIf="!bigScreen || !(authUser$ | async)?.admin"
                 class="sidebar-left"
                 mode="{{ bigScreen ? 'side' : 'push' }}"
                 [opened]="sidebarService.getSidebarObservable() | async"
                 role="navigation"
                 [disableClose]="bigScreen"
                 (openedChange)="sidebarService.setSideBarOpenState($event)">
        <div class="sidebar-container">
            <div class="top-logo mb-4">
                <img src="assets/images/blacklion-nospace.png"
                     routerLink="{{ homeRoute$ | async }}"
                     (click)="onNavLinkClicked()">
            </div>

            <!-- MAIN NAVIGTION CATEGORIES -->
            <ul class="navbar-nav menubar">
                <li *ngFor="let data of sideBarCategories"
                    class="nav-item">
                    <a class="nav-link"
                       routerLink="{{ data.routerLink }}"
                       [routerLinkActive]="['active']"
                       (click)="onNavLinkClicked()">
                        <div class="nav-icon">
                            <i class="far fa-fw"
                               [ngClass]="data.iconClass"></i>
                            {{ data.title | translate | capitalize }}
                        </div>
                    </a>
                </li>
            </ul>

            <div>

                <!-- PROFILE PICTURE AND NAME -->
                <div class="profile"
                     *ngIf="employee"
                     (click)="profileClicked()">
                    <img class="profilePic"
                         src="{{employee.imageURL }}">
                    <div class="profileText">
                        <p class="style-7">{{employee.firstname}} {{employee.lastname}}</p>
                    </div>
                </div>
            </div>
        </div>
    </mat-sidenav>

    <mat-sidenav position="end"
                 class="side-drawer"
                 (openedChange)="sideDrawerService.setOpened($event)"
                 [opened]="sideDrawerService.opened">
        <div *ngFor="let component of sideDrawerService.components; let i = index"
             class="component"
             [style.zIndex]="i">
            <ndc-dynamic [ndcDynamicComponent]="component.component"
                         [ndcDynamicInputs]="component.inputData"></ndc-dynamic>
        </div>
    </mat-sidenav>

    <!-- MAIN CONTENT -->
    <mat-sidenav-content role="main">
        <app-top-nav *ngIf="(authUser$ | async)?.admin && bigScreen"></app-top-nav>
        <div class="main-content">
            <router-outlet #outlet="outlet"></router-outlet>
        </div>
        <app-bottom-nav *ngIf="!bigScreen"></app-bottom-nav>
    </mat-sidenav-content>
</mat-sidenav-container>