<div class="settings-page">
    <app-settings-header title="{{ 'shared.shift-schedule' | translate | capitalize }}"></app-settings-header>

    <div class="settings-content">
        <!-- Calendar sync button -->
        <ui-button-standard class="cal-sync-button"
                            color="primary"
                            [transparent]="true"
                            (click)="openCalendarSync()">
            {{ 'settings.sync-your-calendar' | translate | capitalize }}
        </ui-button-standard>

        <!-- Shift change section -->
        <app-settings-divider title="{{ 'shared.shift-change' | translate | capitalize }}"></app-settings-divider>

        <!-- PRO PART -->
        <ng-container *appIfPro="packages.PRO">
            <app-settings-toggle [class.no-bottom-radius]="shiftTradeEnabled$ | async"
                                 title="{{ 'freemium.shift-change' | translate }}"
                                 subtitle="{{ 'freemium.shift-change-description' | translate }}"
                                 [checked]="shiftTradeEnabled$ | async"
                                 (checkedChange)="shiftTradeChanged($event)"></app-settings-toggle>
            <app-settings-toggle class="auto-shift-trade-card"
                                 *ngIf="shiftTradeEnabled$ | async"
                                 title="{{ 'settings.auto-approve-shift-change' | translate | capitalize }}"
                                 subtitle="{{ 'settings.auto-approve-shift-change-info' | translate | capitalize }}"
                                 [checked]="autoShiftTradeEnabled$ | async"
                                 (checkedChange)="autoShiftTradeChanged($event)"></app-settings-toggle>
            <app-settings-toggle class="auto-approve-available-shift"
                                 title="{{ 'settings.auto-approve-available-shift' | translate }}"
                                 subtitle="{{ 'settings.auto-approve-available-shift-info' | translate }}"
                                 [checked]="autoApproveAvailableShiftEnabled$ | async"
                                 (checkedChange)="autoApproveAvailableShiftChanged($event)"></app-settings-toggle>
            <app-settings-toggle [title]="'punch-clock.manual-punch-clock' | translate"
                                 [subtitle]="'punch-clock.manual-punch-clock-description' | translate"
                                 [checked]="manualPunchclockEnabled$ | async"
                                 (checkedChange)="manualPunchclockEnabledChanged($event)">
            </app-settings-toggle>
        </ng-container>

        <!-- FREEMIUM PART -->
        <ng-container *appIfNotPro="packages.PRO">
            <div class="card freemium d-flex flex-row align-items-center justify-content-between">
                <div class="title">
                    {{ 'shared.shift-change' | translate | capitalize }}
                    <app-upgrade-arrow [package]="packages.PRO"></app-upgrade-arrow>
                </div>
                <app-upgrade-button></app-upgrade-button>
            </div>
            <div class="card freemium d-flex flex-row align-items-center justify-content-between">
                <div class="title">
                    {{ 'settings.auto-approve-available-shift-freemium' | translate }}
                    <app-upgrade-arrow [package]="packages.PRO"></app-upgrade-arrow>
                </div>
                <app-upgrade-button></app-upgrade-button>
            </div>
            <div class="card freemium d-flex flex-row align-items-center justify-content-between">
                <div class="title">
                    {{ 'punch-clock.manual-punch-clock' | translate }}
                    <app-upgrade-arrow [package]="packages.PRO"></app-upgrade-arrow>
                </div>
                <app-upgrade-button></app-upgrade-button>
            </div>
        </ng-container>
    </div>
</div>