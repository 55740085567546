import { Pipe, PipeTransform } from '@angular/core';
import { Shift, Template } from '../../core/model';

@Pipe({
    name: 'templateHasEmployees',
})
export class TemplateHasEmployeesPipe implements PipeTransform {

    public transform(value: Template): boolean {
        return !!(value.monday.find((shift: Shift) => shift.employee) ||
            value.tuesday.find((shift: Shift) => shift.employee) ||
            value.wednesday.find((shift: Shift) => shift.employee) ||
            value.thursday.find((shift: Shift) => shift.employee) ||
            value.friday.find((shift: Shift) => shift.employee) ||
            value.saturday.find((shift: Shift) => shift.employee) ||
            value.sunday.find((shift: Shift) => shift.employee)
        );
    }
}
