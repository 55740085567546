export enum PunchSource {
    MANUAL = 'manual',
    AUTOMATIC = 'automatic',
}
export interface Punch {
    source: PunchSource;
    time: Date;
    message?: string;
    overwriting?: Punch;
}
