export enum RoleColor {
    ROLE_COLOR_1 = 'role_color_1',
    ROLE_COLOR_2 = 'role_color_2',
    ROLE_COLOR_3 = 'role_color_3',
    ROLE_COLOR_4 = 'role_color_4',
    ROLE_COLOR_5 = 'role_color_5',
    ROLE_COLOR_6 = 'role_color_6',
    ROLE_COLOR_7 = 'role_color_7',
    ROLE_COLOR_8 = 'role_color_8',
    ROLE_COLOR_9 = 'role_color_9',
    ROLE_COLOR_10 = 'role_color_10',
    ROLE_COLOR_11 = 'role_color_11',
    ROLE_COLOR_12 = 'role_color_12',
    ROLE_COLOR_13 = 'role_color_13',
    ROLE_COLOR_14 = 'role_color_14',
    ROLE_COLOR_15 = 'role_color_15',
    ROLE_COLOR_16 = 'role_color_16',
    ROLE_COLOR_17 = 'role_color_17',
    ROLE_COLOR_18 = 'role_color_18',
    ROLE_COLOR_19 = 'role_color_19',
    ROLE_COLOR_20 = 'role_color_20',
    ROLE_COLOR_21 = 'role_color_21'
}
