<div class="edit-employee-modal">
    <ng-container [ngSwitch]="activeModalState">
        <div class="employee-modal-list"
             *ngSwitchCase="modalStates.TeamList">
            <app-team-list (employeeClicked)="selectEmployee($event)">
            </app-team-list>
        </div>
        <div class="employee-modal-tabs"
             *ngSwitchCase="modalStates.ProfileTabs">
            <div class="employee-modal-tabs-header">
                <div class="header-left">
                    <ui-button-icon basic="true"
                                    class="back-arrow"
                                    (click)="activeModalState = modalStates.TeamList">
                        <i class="far fa-chevron-left"></i>
                    </ui-button-icon>
                    <img class="header-image"
                         [src]="selectedEmployee.imageURL">
                    <div class="header-name">
                        {{selectedEmployee.firstname.trim() + ' ' + selectedEmployee.lastname.trim()}}
                    </div>
                </div>
                <div class="header-right pointer"
                     *ngIf="!!selectedEmployee.phone"
                     (click)="openContactEmployeeModal(selectedEmployee)">
                    <i class="fas fa-phone"></i>
                </div>
                <div class="header-right"
                     *ngIf="!selectedEmployee.phone">
                    <i class="fas fa-phone disabled"></i>
                </div>
            </div>
            <mat-tab-group mat-stretch-tabs
                           disableRipple
                           *ngIf="!isMobile; else employeeInfo">
                <mat-tab label="{{ 'shared.profile' | translate }}">
                    <ng-container [ngTemplateOutlet]="employeeInfo"></ng-container>
                </mat-tab>
                <mat-tab label="{{ 'shared.departments-and-roles' | translate }}">
                    <app-edit-employee-department [selectedEmployee]="selectedEmployee">
                    </app-edit-employee-department>
                </mat-tab>
            </mat-tab-group>
            <ng-template #employeeInfo>
                <app-edit-employee-info [selectedEmployee]="selectedEmployee"
                                        [isMobile]="isMobile"
                                        (employeeDeleted)="activeModalState = modalStates.TeamList">
                </app-edit-employee-info>
            </ng-template>
        </div>
    </ng-container>
</div>