import * as _ from 'lodash';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Employee, Shift, ShiftForSale, ShiftForSaleStatus } from '../../model';
import { Department } from '../../model/Department/Department.model';

export abstract class ShiftForSaleService {

    /**
     * Gets shifts for sale that may give value to the end user.
     * The potentially relevant are ACCEPTED, DECLINED or actionable shifts
     */
    public getRelevantShiftsForSale(): Observable<ShiftForSale[]> {
        return combineLatest([
            this.getShiftsForSale(undefined, ShiftForSaleStatus.ACCEPTED),
            this.getShiftsForSale(undefined, ShiftForSaleStatus.DECLINED),
            this.getActionableShiftsForSale(),
        ]).pipe(map((_.flatten)));
    }

    /**
     * Gets shifts for sale that the admin can take action on, i.e. PENDING with with bids
     */
    public getActionableShiftsForSale(): Observable<ShiftForSale[]> {
        return this.getShiftsForSale(undefined, ShiftForSaleStatus.PENDING).pipe(
            map((shiftsForSale: ShiftForSale[]) => shiftsForSale.filter((shiftForSale: ShiftForSale) => shiftForSale.bids.length)));
    }

    /**
     * Returns an observable with the results from the query, specified with the given optional parameters
     * @param employee An optional employee, added to query if given
     * @param status An optional status, added to query if given
     * @param buyer An optional buyer, added to query if given
     * @param from An optional date to search from
     */
    public abstract getShiftsForSale(
        employee?: Employee,
        status?: ShiftForSaleStatus,
        buyer?: Employee,
        from?: Date,
        department?: Department,
    ): Observable<ShiftForSale[]>;

    /**
     * Creates a new ShiftForSale and stores it in the database
     * @param shift The shift which is put up for sale
     */
    public abstract createShiftForSale(shift: Shift): Promise<ShiftForSale>;

    /**
     * Updates a ShiftForSale with the values in the given ShiftForSale
     * @param shiftForSale The updated ShiftForSale to be used to update the database
     */
    public abstract updateShiftForSale(shiftForSale: ShiftForSale): Promise<ShiftForSale>;

    /**
     * Tries to revoke a shiftForSale
     * @param shiftForSale The shiftForSale to revoke
     * @returns A promise that resolves if revoked and rejects if the revokation failed
     */
    public abstract revokeShiftForSale(shiftForSale: ShiftForSale): Promise<ShiftForSale>;

    /**
     * Removes a bid on a shift for sale
     * @param shiftForSale The ShiftForSale for which to remove a bid
     * @param employee The bidder
     * @returns A promise that resolves if the bid was removed and rejects if the bid removal failed
     */
    public abstract removeBidOnShiftForSale(shiftForSale: ShiftForSale, employee: Employee): Promise<ShiftForSale>;

    /**
     * Closes a shift for sale by accepting a buyer
     * @param shiftForSale The ShiftForSale to close
     * @param employee The accepted buyer of the ShiftForSale
     * @returns A promise that resolves if accepted and rejects if the closing failed
     */
    public abstract acceptShiftForSale(shiftForSale: ShiftForSale, employee: Employee): Promise<ShiftForSale>;

    /**
     * Closes a shift for sale by rejecting it
     * @param shiftForSale The ShiftForSale to reject
     * @returns A promise that resolves if rejected and rejects if the closing failed
     */
    public abstract rejectShiftForSale(shiftForSale: ShiftForSale): Promise<ShiftForSale>;

    /**
     * Bids on a ShiftForSale
     * @param shiftForSale The ShiftForSale to bid on
     * @param employee The bidder
     * @returns A promise that resolves if the bid succeded and rejects otherwise
     */
    public abstract bidOnShiftForSale(shiftForSale: ShiftForSale, employee: Employee): Promise<ShiftForSale>;

    /**
     * Returns a shiftForSale object, if the shift is for sale, otherwise returns null
     * @param shift The shift to get the shiftForSale object for
     */
    public abstract getShiftForSaleByShift(shift: Shift): Observable<ShiftForSale | null>;
}
