<div class="edit-salary-supplement"
     [class.holidays]="model.type === SalarySupplementType.HOLIDAY">
    <div class="header">
        <ui-button-icon (click)="cancel()">
            <i class="far fa-chevron-left"></i>
        </ui-button-icon>
        <div class="title">
            {{ model.id ? ('modal.salary-supplements.edit-supplement' | translate) : ('modal.salary-supplements.new-supplement' | translate) }}
        </div>
    </div>
    <form [formGroup]="formGroup"
          (keyup.enter)="save()">
        <!-- SUPPLEMENT NAME -->
        <div class="name">
            <input formControlName="SUPPLEMENT_NAME"
                   [appFloatingLabel]="'modal.salary-supplements.input.name' | translate"
                   errorMessage=""
                   inputColor="grey"
                   [(ngModel)]="model.name" />
        </div>

        <!-- SUPPLEMENT AMOUNT -->
        <input formControlName="SUPPLEMENT_AMOUNT"
               [appFloatingLabel]="'modal.salary-supplements.input.amount' | translate"
               [errorMessage]=" 'error.supplement-amount-requirements' | translate "
               inputColor="grey"
               [(ngModel)]="model.amount" />

        <!-- SUPPLEMENT TIME -->
        <div class="d-flex justify-content-center align-items-center time"
             formGroupName="SUPPLEMENT_TIME_SPAN">
            {{ 'shared.from' | translate }}
            <input formControlName="SUPPLEMENT_START_HOUR"
                   [class.time-input-not-filled]="!model.startHour || !model.startMin || !model.endHour || !model.endMin"
                   (input)="timeInputChange($event.target.value, 'HOUR', startMin)"
                   appFloatingLabel
                   errorMessage=""
                   inputColor="grey"
                   onclick="this.select()"
                   [(ngModel)]="model.startHour" />
            :
            <input formControlName="SUPPLEMENT_START_MIN"
                   #startMin
                   [class.time-input-not-filled]="!model.startHour || !model.startMin || !model.endHour || !model.endMin"
                   (input)="timeInputChange($event.target.value, 'MIN', endHour)"
                   appFloatingLabel
                   errorMessage=""
                   inputColor="grey"
                   onclick="this.select()"
                   [(ngModel)]="model.startMin" />
            {{ 'shared.to' | translate }}
            <input formControlName="SUPPLEMENT_END_HOUR"
                   #endHour
                   [class.time-input-not-filled]="!model.startHour || !model.startMin || !model.endHour || !model.endMin"
                   (input)="timeInputChange($event.target.value, 'HOUR', endMin)"
                   appFloatingLabel
                   errorMessage=""
                   inputColor="grey"
                   onclick="this.select()"
                   [(ngModel)]="model.endHour" />
            :
            <input formControlName="SUPPLEMENT_END_MIN"
                   #endMin
                   [class.time-input-not-filled]="!model.startHour || !model.startMin || !model.endHour || !model.endMin"
                   (input)="timeInputChange($event.target.value, 'MIN')"
                   appFloatingLabel
                   errorMessage=""
                   inputColor="grey"
                   onclick="this.select()"
                   [(ngModel)]="model.endMin" />
        </div>

        <!-- SUPPLEMENT TYPE -->
        <div class="days d-flex flex-column align-items-center">
            <mat-radio-group formControlName="SUPPLEMENT_TYPE"
                             [(ngModel)]="model.type">
                <mat-radio-button [value]="SalarySupplementType.WEEKDAY"
                                  color="primary"
                                  (click)="typeChanged()">
                    <span>
                        {{ 'shared.weekdays' | translate }}
                    </span>
                </mat-radio-button>
                <mat-radio-button [value]="SalarySupplementType.HOLIDAY"
                                  color="primary"
                                  (click)="typeChanged()">
                    <span>
                        {{ 'shared.holidays' | translate }}
                    </span>
                </mat-radio-button>
            </mat-radio-group>

            <overlay-scrollbars class="h-100 w-100">
                <!-- SUPPLEMENT WEEKDAYS -->
                <div *ngIf="model.type === SalarySupplementType.WEEKDAY"
                     class="weekday-checkboxes"
                     formGroupName="SUPPLEMENT_WEEKDAY">
                    <input formControlName="SUPPLEMENT_MONDAY"
                           id="SUPPLEMENT_MONDAY"
                           type="checkbox"
                           [(ngModel)]="model.weekdays[Weekday.MONDAY]">
                    <label for="SUPPLEMENT_MONDAY"> {{ 'time.abbreviations.monday' | translate }} </label>

                    <input formControlName="SUPPLEMENT_TUESDAY"
                           id="SUPPLEMENT_TUESDAY"
                           type="checkbox"
                           [(ngModel)]="model.weekdays[Weekday.TUESDAY]">
                    <label for="SUPPLEMENT_TUESDAY"> {{ 'time.abbreviations.tuesday' | translate }} </label>

                    <input formControlName="SUPPLEMENT_WEDNESDAY"
                           id="SUPPLEMENT_WEDNESDAY"
                           type="checkbox"
                           [(ngModel)]="model.weekdays[Weekday.WEDNESDAY]">
                    <label for="SUPPLEMENT_WEDNESDAY"> {{ 'time.abbreviations.wednesday' | translate }} </label>

                    <input formControlName="SUPPLEMENT_THURSDAY"
                           id="SUPPLEMENT_THURSDAY"
                           type="checkbox"
                           [(ngModel)]="model.weekdays[Weekday.THURSDAY]">
                    <label for="SUPPLEMENT_THURSDAY"> {{ 'time.abbreviations.thursday' | translate }} </label>

                    <input formControlName="SUPPLEMENT_FRIDAY"
                           id="SUPPLEMENT_FRIDAY"
                           type="checkbox"
                           [(ngModel)]="model.weekdays[Weekday.FRIDAY]">
                    <label for="SUPPLEMENT_FRIDAY"> {{ 'time.abbreviations.friday' | translate }} </label>

                    <input formControlName="SUPPLEMENT_SATURDAY"
                           id="SUPPLEMENT_SATURDAY"
                           type="checkbox"
                           [(ngModel)]="model.weekdays[Weekday.SATURDAY]">
                    <label for="SUPPLEMENT_SATURDAY"> {{ 'time.abbreviations.saturday' | translate }} </label>

                    <input formControlName="SUPPLEMENT_SUNDAY"
                           id="SUPPLEMENT_SUNDAY"
                           type="checkbox"
                           [(ngModel)]="model.weekdays[Weekday.SUNDAY]">
                    <label for="SUPPLEMENT_SUNDAY"> {{ 'time.abbreviations.sunday' | translate }} </label>
                </div>

                <!-- SUPPLEMENT HOLIDAYS -->
                <ul *ngIf="model.type === SalarySupplementType.HOLIDAY">
                    <li *ngFor="let holiday of holidayTranslations">
                        {{ holiday | translate }}
                    </li>
                </ul>
            </overlay-scrollbars>
        </div>
    </form>

    <div class="explainer">
        {{ 'modal.salary-supplements.explainer' | translate }}
    </div>

    <div class="buttons">
        <!-- SAVE / UPDATE BUTTON -->
        <ui-button-action [disabled]="formGroup.invalid || saving"
                          (click)="save()">
            <i *ngIf="saving"
               class="fad fa-spin fa-spinner-third"></i>
            <ng-container *ngIf="!saving">
                {{ model.id ? ('shared.update' | translate | capitalize) : ('shared.save' | translate | capitalize) }}
            </ng-container>
        </ui-button-action>

        <!-- CANCEL BUTTON (if creating new supplement) -->
        <ui-button-standard *ngIf="!model.id"
                            (click)="cancel()">
            {{ 'shared.cancel' | translate }}
        </ui-button-standard>

        <!-- DELETE BUTTON (if editing existing supplement) -->
        <ui-button-standard *ngIf="model.id"
                            [disabled]="deleting"
                            (click)="deleteSupplement()"
                            color="warn">
            <i *ngIf="deleting"
               class="fad fa-spin fa-spinner-third"></i>
            <ng-container *ngIf="!deleting">
                {{ 'shared.delete' | translate | capitalize }}
                {{ 'shared.supplement' | translate }}
            </ng-container>
        </ui-button-standard>
    </div>
</div>