<div class="notification-center">
    <div class="title">
        {{ 'shared.notifications' | translate }}
    </div>
    <overlay-scrollbars class="h-100">
        <div *ngIf="notifications$ | loading"
             class="loading-item d-flex justify-content-center align-items-center">
            <i class="fad fa-spinner-third fa-spin"></i>
        </div>
        <app-notification *ngFor="let notification of notifications$ | async"
                          [notification]="notification"></app-notification>
    </overlay-scrollbars>
</div>