/**
 * This is the interface for a Shift, reflecting the structure in the application.
 */
import * as _ from 'lodash';
import * as Moment from 'moment';
import { extendMoment, MomentRange } from 'moment-range';
import { Approval } from './Approval.model';
import { Comment } from './Comment.model';
import { CustomBreak } from './CustomBreak';
import { Department } from './Department/Department.model';
import { Employee, sortEmployeesByName } from './Employee.model';
import { Punch } from './Punch/Punch.model';
import { Role } from './Role/Role.model';
import { SalarySwipe } from './salary-swipe.model';
import { SalaryExport } from './SalaryExport';
const moment: MomentRange = extendMoment(Moment);

export interface Shift {
    id: string;
    role: Role;
    start: Date;
    end: Date;
    isForSale: boolean;
    employee: Employee | null;
    released: boolean;
    checkin?: Punch;
    checkout?: Punch;
    approved: boolean;
    approval?: Approval;
    comment?: Comment;
    exportLogs: SalaryExport[];
    salarySwipe?: SalarySwipe;
    customBreak?: CustomBreak;
}

/**
 * A shift without id and an optional role, start and end (a newly created shift)
 */
export type NewShift = Omit<Omit<Omit<Omit<Shift,
    'id'>,
    'role'>,
    'start'>,
    'end'>
    & {
        role?: Role,
        start?: Date,
        end?: Date,
    };

/**
 * Type checking method returning whether a shift is a valid Shift (not checking for id)
 * @param shift - The shift to check
 */
export function isValidShift(shift: unknown): shift is Omit<Shift, 'id'> {
    const castedShift: Shift = shift as Shift;
    return !!castedShift && !!castedShift.role && !!castedShift.start && !!castedShift.end;
}

/**
 * Type checking method returning whether a shift is a valid Shift (also checking for id)
 * @param shift - The shift to check
 */
export function isShift(shift: unknown): shift is Shift {
    if (!isValidShift(shift)) return false;

    const castedShift: Shift = shift as Shift;
    return !!castedShift.id && !!_.isDate(castedShift.start);
}

/**
 * Sorts shifts by their role names
 */
export function sortShiftsByRoleName(a: Shift, b: Shift): number {
    return a.role.name.localeCompare(b.role.name)
        || sortShiftsByName(a, b);
}

/**
 * Sorts shifts by their start (then end) dates
 */
export function sortShiftsByTime(a: Shift, b: Shift): number {
    return a.start.getTime() - b.start.getTime()
        || a.end.getTime() - b.end.getTime()
        || sortShiftsByName(a, b);
}

/**
 * Sorts shifts by checkin, and then by time
 * @param shift1
 * @param shift2
 */
export function sortShiftsByCheckin(shift1: Shift, shift2: Shift): number {
    return (!!shift1.checkin && !!shift2.checkin || !shift1.checkin && !shift2.checkin)
        ? sortShiftsByTime(shift1, shift2)
        : (!!shift1.checkin && !shift2.checkin
            ? -1
            : 1);
}

/**
 * Sorts shifts by their employee
 */
function sortShiftsByName(a: Shift, b: Shift): number {
    if (!a.employee || !b.employee) return 0;
    return sortEmployeesByName(a.employee, b.employee);
}

/**
 * determines whether a shift has ended
 * @param shift - The employee shift
 * @param checkoutUntil - Timespan in minutes, employees can check out after the shift ends
 */
export function shiftEnded(shift: Shift, checkoutUntil: number | null): boolean {
    return moment(shift.end).add((!!checkoutUntil && checkoutUntil) || 0, 'minutes').toDate().getTime() < Date.now();
}

/**
 * filters out shifts by department
 */
export function filterByDepartment(shifts: Shift[], department?: Department): Shift[] {
    return shifts.filter((shift: Shift) => (!department || shift.role.department.id === department.id));
}

/**
 * filters out shifts in date range
 */
export function filterByDateRange(shifts: Shift[], start?: Date, end?: Date): Shift[] {
    return (start && end) ? shifts.filter((shift: Shift) => shift.start >= start && shift.end < end) : shifts;
}

/**
 * filters out ended shifts
 */
export function filterEnded(shifts: Shift[], checkoutUntil: number | null): Shift[] {
    return !!checkoutUntil ? shifts.filter((shift: Shift) => shiftEnded(shift, checkoutUntil) && !shift.checkin?.time) : shifts;
}
