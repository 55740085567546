import { Injectable } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Employee, Role, Shift, Template } from '../../../../../../core/model';
import { Package } from '../../../../../../core/model/Freemium/Package';
import { RelionModule } from '../../../../../../core/model/RelionModule';
import { EmployeeService, RoleService, ShiftService, TemplateService, UserActivity, UserActivityService } from '../../../../../../core/services';
import { ConfigurationService } from '../../../../../../core/services/Configuration/configuration-service.model';

@Injectable({
    providedIn: 'root',
})
export class OnboardingService {


    constructor(
        private shiftService: ShiftService,
        private configurationService: ConfigurationService,
        private employeeService: EmployeeService,
        private templateService: TemplateService,
        private roleService: RoleService,
        private userActivityService: UserActivityService,
    ) { }

    public companyHasShift(): Observable<boolean> {
        const hasShift = (shifts: Shift[]): boolean => shifts.length > 0;

        return this.shiftService.getShifts({ resultLimit: 1 }).pipe(
            map(hasShift),
        );
    }

    public companyHasTemplate(): Observable<boolean> {
        const hasTemplate = (templates: Template[]): boolean => templates.length > 0;

        return this.templateService.getTemplates().pipe(
            map(hasTemplate),
        );
    }

    public companyHasConfiguredEmployee(): Observable<boolean> {
        const configuredEmployeeExists = ([employees, currentEmployee]: [Employee[], Employee]) => employees.filter((employee: Employee) =>
            this.isNotCurrentEmployee(employee, currentEmployee)
            && this.hasRoles(employee)
            && this.hasDepartment(employee)).length > 0;

        return this.mapAllAndCurrentEmployee(configuredEmployeeExists);
    }

    public companyHasUnconfiguredEmployee(): Observable<boolean> {
        const unconfiguredEmployeeExists = ([employees, currentEmployee]) => employees.filter((employee: Employee) =>
            this.isNotCurrentEmployee(employee, currentEmployee)
            && !this.hasRoles(employee)
            && !this.hasDepartment(employee)).length > 0;

        return this.mapAllAndCurrentEmployee(unconfiguredEmployeeExists);
    }

    public companyHasRoles(): Observable<boolean> {
        const hasRoles = (roles: Role[]) => roles.length > 0;

        return this.roleService.getRoles({ isDeleted: true }).pipe(
            map(hasRoles),
        );
    }

    public showReadyForProModal(module: RelionModule): Observable<boolean> {
        switch (module) {
            case RelionModule.SALARY:
                // Show Salary ready for pro modal if on free version, and never completed pro salary onboarding
                return combineLatest([
                    this.configurationService.isPro(Package.PRO),
                    this.proOnboardingCompleted(RelionModule.SALARY),
                    this.userActivityService.load(UserActivity.CLOSED_SALARY_READY_FOR_PRO_MODAL),
                ]).pipe(map(([isPro, proOnboardingCompleted, closedReadyModal]: [boolean, boolean, Date | null]) =>
                    !isPro && !proOnboardingCompleted && !closedReadyModal));
            default: throw Error(`No ReadyForPro modal exists for the ${ module } module`);
        }
    }

    public freeOnboardingCompleted(module: RelionModule): Observable<boolean> {
        switch (module) {
            case RelionModule.SALARY: return combineLatest([
                this.userActivityService.load(UserActivity.COMPLETED_SALARY_FREEMIUM_INTRODUCTION_ONBOARDING),
                this.userActivityService.load(UserActivity.COMPLETED_SALARY_FREEMIUM_SALARY_PERIOD_ONBOARDING),
                this.userActivityService.load(UserActivity.COMPLETED_SALARY_FREEMIUM_EMPLOYEE_WAGES_ONBOARDING),
            ]).pipe(map((activities: (Date | null)[]) => activities.every((activity: Date | null) => !!activity)));
            default: throw Error(`No free onboarding exists for the ${ module } module`);
        }
    }

    public proOnboardingCompleted(module: RelionModule): Observable<boolean> {
        switch (module) {
            case RelionModule.SALARY: return combineLatest([
                this.userActivityService.load(UserActivity.COMPLETED_SALARY_PRO_SHIFT_BREAKS_ONBOARDING),
                this.userActivityService.load(UserActivity.COMPLETED_SALARY_PRO_INTEGRATIONS_ONBOARDING),
            ]).pipe(map((activities: (Date | null)[]) => activities.every((activity: Date | null) => !!activity)));
            default: throw Error(`No pro onboarding exists for the ${ module } module`);
        }
    }

    private isNotCurrentEmployee = (employee: Employee, currentEmployee: Employee): boolean => employee.id !== currentEmployee.id;
    private hasRoles = (employee: Employee): boolean => employee.roles.length > 0;
    private hasDepartment = (employee: Employee): boolean => employee.departments.length > 0;
    private mapAllAndCurrentEmployee = (mapper: ([employees, currentEmployee]) => boolean): Observable<boolean> => {
        return combineLatest(
            this.employeeService.getEmployees({ isDeleted: true }),
            this.employeeService.getAuthenticatedEmployee(),
        ).pipe(map(mapper));
    }
}
