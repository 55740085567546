import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'plural',
})
export class PluralPipe implements PipeTransform {

    /**
     * Returns the singular or plural parameter based on whether the number === 0
     * @param num The number to use for plurality
     * @param singular The singular value
     * @param plural The plural value
     */
    public transform<T>(num: number | string | null, singular: T, plural: T): T {
        return (num === 1 || num === '1') ? singular : plural;
    }
}
