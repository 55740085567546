<ui-button-standard [ngbPopover]="popContent"
                    #popover="ngbPopover"
                    autoClose="outside"
                    popoverClass="date-range-picker-popover"
                    placement="bottom-left">
    <div class="dates">
        {{ startDate | date}} - {{ endDate | date}}
    </div>
    <i class="far fa-chevron-down"></i>
</ui-button-standard>

<ng-template #popContent>
    <div class="d-flex">
        <div class="calendar">
            <h5 class="calHeader">{{'shared.from-date' | translate | uppercase}}</h5>
            <dl-date-time-picker minView="day"
                                 startView="day"
                                 maxView="month"
                                 [(ngModel)]="startDate">
            </dl-date-time-picker>
        </div>

        <div class="calendar">
            <h5 class="calHeader">{{'shared.to-date' | translate | uppercase}}</h5>
            <dl-date-time-picker minView="day"
                                 startView="day"
                                 maxView="month"
                                 [(ngModel)]="endDate"
                                 [selectFilter]="isEndDateSelectable">
            </dl-date-time-picker>
        </div>
    </div>
</ng-template>