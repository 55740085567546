import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { enableAkitaProdMode } from '@datorama/akita';
import { AppModule } from './app/app.module';
import { addGoogleAnalytics } from './assets/attachGoogleAnalytics';
import { environment } from './environments/environment.dev';

if (environment.production) {
    enableProdMode();
    enableAkitaProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule);

addGoogleAnalytics(environment.googleAnalyticsTrackingId);
