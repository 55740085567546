import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, map, shareReplay } from 'rxjs/operators';
import { environment } from '../../../../../../../../environments/environment.dev';
import { GPSZone } from '../../../../../../../core/model';
import { CompanySetting, CompanySettingsService } from '../../../../../../../core/services';

@Injectable({
    providedIn: 'root',
})
export class GpsZoneService {
    public readonly apiLoaded$: Observable<boolean>;

    private zoneUnderEdit: BehaviorSubject<Partial<GPSZone> | undefined>;
    private zoneUnderEditIndex: number;
    private gpsZones: GPSZone[];

    constructor(
        private settingsService: CompanySettingsService,
        httpClient: HttpClient,
    ) {
        this.zoneUnderEdit = new BehaviorSubject(undefined);
        this.settingsService.loadSetting(CompanySetting.LOCATIONS).subscribe((zones: GPSZone[]) => this.gpsZones = zones);
        const apiKey = environment.googleMapsAPIKey;
        this.apiLoaded$ =
            httpClient.jsonp(`https://maps.googleapis.com/maps/api/js?key=${ apiKey }&libraries=places`, 'callback')
                .pipe(
                    map(() => true),
                    catchError(() => of(false)),
                    shareReplay(1),
                );
    }

    public getZoneUnderEdit(): Observable<Partial<GPSZone> | undefined> {
        return this.zoneUnderEdit.asObservable();
    }

    public createNewZone(): void {
        this.editZone({});
    }

    public editZone(zone: Partial<GPSZone>): void {
        this.zoneUnderEdit.next({ ...zone });
        this.zoneUnderEditIndex = this.gpsZones.findIndex((_zone: GPSZone) => _zone === zone);
    }

    public cancelEdit(): void {
        this.zoneUnderEdit.next(undefined);
    }

    public deleteZone(deletedZone: GPSZone): void {
        const remainingZones = this.gpsZones.filter((zone: GPSZone) => zone !== deletedZone);
        this.settingsService.saveSetting(CompanySetting.LOCATIONS, remainingZones);
    }

    public saveZone(zone: Partial<GPSZone>): void {
        const newZones: GPSZone[] = [...this.gpsZones];
        if (!this.isValidGPSZone(zone)) return;
        if (this.zoneUnderEditIndex > -1) newZones[this.zoneUnderEditIndex] = zone;
        else newZones.push(zone);
        this.settingsService.saveSetting(CompanySetting.LOCATIONS, newZones);
        this.zoneUnderEdit.next(undefined);
    }

    private isValidGPSZone(zone: Partial<GPSZone>): zone is GPSZone {
        return !!(
            zone.name?.length
            && zone.address?.length
            && zone.geopoint?.latitude
            && zone.geopoint?.longitude
            && zone.radius);
    }

}
