<div class="signup-container">
    <div *ngIf="company$ | async as company"
         class="signup-content">

        <div class="title dot-dot-dot">
            {{ company.name }}
        </div>
        <div class="subtitle">
            {{ 'signup.create-user' | translate }}
        </div>

        <form name="sign-up-form"
              [formGroup]="formGroup"
              (submit)="onSubmit()">
            <input autocomplete="name"
                   formControlName="NAME"
                   required
                   inputColor="grey"
                   [appFloatingLabel]="'shared.full-name' | translate"
                   [errorMessage]="'error.input.user-name' | translate" />
            <input autocomplete="tel"
                   type="tel"
                   formControlName="PHONE"
                   required
                   inputColor="grey"
                   [appFloatingLabel]="'shared.phone-number' | translate"
                   [errorMessage]="'error.input.phone' | translate" />
            <input autocomplete="email"
                   type="email"
                   formControlName="EMAIL"
                   required
                   inputColor="grey"
                   [appFloatingLabel]="'shared.email' | translate"
                   [errorMessage]="'shared.input-email' | translate" />
            <input autocomplete="new-password"
                   type="password"
                   formControlName="PASSWORD"
                   required
                   inputColor="grey"
                   [appFloatingLabel]="'signup.password' | translate"
                   [errorMessage]="'signup.password-requirements-text' | translate" />
            <ui-button-action [disabled]="formGroup.invalid || loading">
                <ng-container *ngIf="!loading"> {{ 'signup.sign-me-up' | translate }} </ng-container>
                <i *ngIf="loading"
                   class="fad fa-spin fa-spinner-third"></i>
            </ui-button-action>
        </form>

        <ui-button-text [routerLink]="'/' + routes.login.path"
                        color="primary">
            <span>{{ 'signup.already-user' | translate }}</span>
        </ui-button-text>
    </div>
</div>