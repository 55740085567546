import { Component, Injector, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { combineLatest, Observable } from 'rxjs';
import { filter, map, shareReplay, startWith, switchMap, take } from 'rxjs/operators';
import { Routes, routes } from '../../../app-settings';
import { Employee } from '../../../core/model';
import { Package } from '../../../core/model/Freemium/Package';
import { RelionModule } from '../../../core/model/RelionModule';
import { SubscriptionStatus } from '../../../core/model/SubscriptionStatus';
import { AuthService, AuthUser, EmployeeService, ShiftForSaleService } from '../../../core/services';
import { ConfigurationService } from '../../../core/services/Configuration/configuration-service.model';
import { ModalService } from '../../../core/services/ModalService.model';
import { ModuleService } from '../../../core/services/module.service';
import { SideDrawerService } from '../../../core/services/side-drawer.service';
import { OnboardingService } from '../../../modules/admin/onboarding/web/services/Onboarding/onboarding.service';
import { NavbarSettings } from '../../../modules/admin/shared/components/settings-modal/settings-modal.component';
import { ManageShiftsWidgetComponent } from '../../../modules/employee/home/responsive/components/manage-shifts-widget/manage-shifts-widget.component';
import { NotificationCenterComponent } from '../notification-center/notification-center.component';

@Component({
    selector: 'app-top-nav',
    templateUrl: './top-nav.component.html',
    styleUrls: ['./top-nav.component.scss'],
})
export class TopNavComponent implements OnInit {
    public SubscriptionStatus: typeof SubscriptionStatus = SubscriptionStatus;
    public RelionModule: typeof RelionModule = RelionModule;
    public Package: typeof Package = Package;
    public employee$: Observable<Employee>;
    public notifications$: Observable<number>;
    public selectedModule$: Observable<RelionModule>;
    public selectedModuleIsPro$: Observable<boolean>;
    public routes: Routes = routes;
    public showInviteTip$: Observable<boolean>;
    public trialExpiration$: Observable<Date | null>;
    public freeTrialAvailable$: Observable<boolean>;
    public subscriptionStatus$: Observable<SubscriptionStatus>;

    constructor(
        public modalService: ModalService,
        private employeeService: EmployeeService,
        private shiftForSaleService: ShiftForSaleService,
        private sideDrawerService: SideDrawerService,
        private moduleService: ModuleService,
        private injector: Injector,
        private configurationService: ConfigurationService,
    ) { }

    public ngOnInit(): void {
        this.selectedModule$ = this.moduleService.currentModule$;
        // Start with true to avoid upgrade button flickering when loading pro status
        this.selectedModuleIsPro$ = this.moduleService.currentModuleIsPro$.pipe(startWith(true));
        this.employee$ = this.employeeService.getAuthenticatedEmployee().pipe(shareReplay(1));
        this.notifications$ = this.shiftForSaleService.getActionableShiftsForSale().pipe(map(_.size));
        this.showInviteTip$ = this.shouldShowInviteTip();
        this.trialExpiration$ = this.configurationService.trialExpiration(Package.PRO).pipe(shareReplay(1));
        this.subscriptionStatus$ = this.configurationService.getSubscriptionStatus(Package.PRO);
    }

    public openSideDrawer(): void {
        this.sideDrawerService.open(ManageShiftsWidgetComponent);
    }

    public openNotificationsDrawer(): void {
        this.sideDrawerService.open(NotificationCenterComponent);
    }

    public openSettingsModal(): void {
        this.moduleService.currentModule$
            .pipe(take(1))
            .subscribe((value: RelionModule) => {
                switch (value) {
                    case RelionModule.SCHEDULE:
                        this.modalService.openSettingsModal(NavbarSettings.SCHEDULE);
                        break;
                    case RelionModule.SALARY:
                        this.modalService.openSettingsModal(NavbarSettings.SALARY);
                        break;
                    default:
                        this.modalService.openSettingsModal();
                }
            });
    }

    private shouldShowInviteTip(): Observable<boolean> {
        return this.injector.get(AuthService).getUser().pipe(
            filter((user: AuthUser | null) => !!user?.admin),
            switchMap(() => combineLatest([
                this.injector.get(OnboardingService).companyHasShift(),
                this.injector.get(OnboardingService).companyHasTemplate(),
                this.injector.get(EmployeeService).getEmployees({ isDeleted: true })])),
            map(([hasShift, hasTemplate, employees]: [boolean, boolean, Employee[]]) => hasShift && hasTemplate && employees.length < 2),
        );
    }
}
