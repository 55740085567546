/**
 * Checks whether to Date objects represent the same day
 * @param date1 - The first date to compare
 * @param date2 - The second date to compare
 * @returns True if they are on the same date, false otherwise
 */
export function isSameDate(date1: Date, date2: Date): boolean {
    return date1.getDate() === date2.getDate()
        && date1.getMonth() === date2.getMonth()
        && date1.getFullYear() === date2.getFullYear();
}
