import { Component, Input } from '@angular/core';
import { LazyLoadImageProviderFactory } from '../../../factories/LazyLoadImageHooks';

interface NotificationBaseData {
    title: string;
    subtitle: string;
    imageSrc?: string;
    iconClass?: string;
    time: Date;
    read: boolean;
    clickAction: () => void;
}

export type NotificationData =
    NotificationBaseData & { imageSrc: string; } |
    NotificationBaseData & { iconClass: string; };

@Component({
    selector: 'app-notification',
    templateUrl: './notification.component.html',
    styleUrls: ['./notification.component.scss'],
    providers: [LazyLoadImageProviderFactory(1)],
})
export class NotificationComponent {
    @Input() public notification: NotificationData;
}
