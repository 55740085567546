<div class="flipper"
     [class.flipped]="selected">

    <!-- Card frontside -->
    <div class="flip-front"
         *ngIf="!loading">

        <!-- Shift -->
        <mat-card class="shift mat-elevation-z0">
            <div class="shift-header d-flex flex-row align-items-center">
                <img lazyLoad="{{shiftForSale.seller?.imageURL || '/assets/images/no-employee.svg' }}"
                     alt="profile image">
                <div *ngIf="!(hasBidOnShiftForSale$ | async)"
                     class="shift-seller d-flex flex-row flex-wrap">
                    <ng-container *ngIf="shiftForSale.seller">
                        <span>
                            {{ shiftForSale.seller?.firstname }}
                            {{ shiftForSale.seller?.lastname }}
                        </span>
                        <span>
                            {{ 'employee.free-shifts.is-selling-his-shift' | translate }}
                        </span>
                    </ng-container>
                    <ng-container *ngIf="!shiftForSale.seller">
                        <span>
                            {{'admin.calendar.free-shift' | translate | capitalize}}
                        </span>
                    </ng-container>
                </div>
                <div *ngIf="hasBidOnShiftForSale$ | async"
                     class="shift-seller d-flex flex-row flex-wrap">
                    <ng-container *ngIf="shiftForSale.seller">
                        <span>
                            {{ 'employee.free-shifts.you-have-bid-on-shift-of' | translate | capitalize }}
                        </span>
                        <span>
                            {{ shiftForSale.seller?.firstname }}
                            {{ shiftForSale.seller?.lastname }}
                        </span>
                    </ng-container>
                    <ng-container *ngIf="!shiftForSale.seller">
                        <span>
                            {{ 'employee.free-shifts.you-have-bid-on-a-free-shift' | translate | capitalize }}
                        </span>
                    </ng-container>
                </div>
            </div>

            <div class="shift-content d-flex flex-row align-content-center align-items-center">
                <div class="shift-date d-flex flex-column justify-content-center align-items-center">
                    <app-salary-swipe-label
                            *ngIf="!!shiftForSale.shift.salarySwipe"
                            [shift]="shiftForSale.shift"
                            [dayShiftCard]="true">
                    </app-salary-swipe-label>
                    <span class="date-weekday">
                        {{ shiftForSale.shift.start | date:'EEE' : '' : locale | titlecase }}
                    </span>
                    <div class="d-flex flex-row">
                        <span class="date-day">
                            {{ shiftForSale.shift.start | date:'d' }}.
                        </span>
                        <span class="date-month">
                            {{ shiftForSale.shift.start | date:'MMM' : '' : locale | lowercase }}
                        </span>
                    </div>
                </div>
                <div class="shift-info d-flex flex-column justify-content-center align-items-start">
                    <span class="shift-role">
                        {{ shiftForSale.shift.role?.name }}
                    </span>
                    <span class="shift-times">
                        {{ shiftForSale.shift.start | date:'H:mm' }}
                        -
                        {{ shiftForSale.shift.end | date:'H:mm' | convertMidnight }}
                    </span>
                    <span class="shift-department">
                        {{ shiftForSale.shift.role.department.name }}
                    </span>
                </div>
            </div>

            <div class="shift-actions d-flex flex-row justify-content-between">
                <div class="potential-salary">
                    <ng-container *ngIf="isPro$ | async">
                        <ng-container *ngIf="potentialGain$ | async as potentialGain">
                            <span>{{'freemium.dkk' | translate: { amount: potentialGain | number:'1.2-2' } }}</span>
                            <i class="far fa-coins"></i>
                        </ng-container>
                    </ng-container>
                </div>

                <ui-button-standard (click)="setSelected(true, $event)"
                                    *ngIf="!(hasBidOnShiftForSale$ | async)">
                    {{ 'employee.free-shifts.bid-on-shift' | translate }}
                </ui-button-standard>
                <ui-button-standard (click)="setSelected(true, $event)"
                                    color="warn"
                                    *ngIf="hasBidOnShiftForSale$ | async">
                    {{ 'employee.free-shifts.remove-bid-on-shift' | translate | capitalize }}
                </ui-button-standard>
            </div>
        </mat-card>
    </div>

    <!-- Card backside -->
    <mat-card class="shift-backside mat-elevation-z0 flip-back d-flex flex-column"
              *ngIf="!(hasBidOnShiftForSale$ | async)">
        <div class="shift-header d-flex flex-row align-items-center">
            <img lazyLoad="{{shiftForSale.seller?.imageURL || '/assets/images/no-employee.svg'}}"
                 alt="profile image">
            <div *ngIf="!(hasBidOnShiftForSale$ | async)"
                 class="shift-seller d-flex flex-row flex-wrap">
                <ng-container *ngIf="shiftForSale.seller">
                    <span>
                        {{ shiftForSale.seller?.firstname }}
                        {{ shiftForSale.seller?.lastname }}
                    </span>
                    <span>
                        {{ 'employee.free-shifts.is-selling-his-shift' | translate }}
                    </span>
                </ng-container>
                <ng-container *ngIf="!shiftForSale.seller">
                    <span>
                        {{'admin.calendar.free-shift' | translate | capitalize}}
                    </span>
                </ng-container>
            </div>
            <div *ngIf="hasBidOnShiftForSale$ | async"
                 class="shift-seller d-flex flex-row flex-wrap">
                <ng-container *ngIf="shiftForSale.seller">
                    <span>
                        {{ 'employee.free-shifts.you-have-bid-on-shift-of' | translate | capitalize }}
                    </span>
                    <span>
                        {{ shiftForSale.seller?.firstname }}
                        {{ shiftForSale.seller?.lastname }}
                    </span>
                </ng-container>
                <ng-container *ngIf="!shiftForSale.seller">
                    <span>{{ 'employee.free-shifts.you-have-bid-on-a-free-shift' | translate }}</span>
                </ng-container>
            </div>
        </div>

        <div class="shift-content text-center d-flex flex-grow-1 align-items-center justify-content-center">
            {{ 'employee.free-shifts.confirm-bid-on-shift' | translate | capitalize }}
        </div>

        <div class="shift-actions d-flex flex-row justify-content-end">
            <ui-button-text (click)="setSelected(false, $event)"
                            class="mr-2">
                {{ 'shared.cancel' | translate }}
            </ui-button-text>
            <ui-button-action color="success"
                              (click)="bidOnShift(shiftForSale, $event); setSelected(true, $event); gas.trackButtonClick('bid on shift for sale')">
                {{ 'shared.confirm' | translate | capitalize }}
            </ui-button-action>
        </div>
    </mat-card>

    <!-- Card backside (cancel bid) -->
    <mat-card class="shift-backside mat-elevation-z0 flip-back d-flex flex-column"
              *ngIf="hasBidOnShiftForSale$ | async">
        <div class="shift-header d-flex flex-row align-items-center">
            <img lazyLoad="{{shiftForSale.seller?.imageURL || '/assets/images/no-employee.svg'}}"
                 alt="profile image">
            <div *ngIf="!(hasBidOnShiftForSale$ | async)"
                 class="shift-seller d-flex flex-row flex-wrap">
                <ng-container *ngIf="shiftForSale.seller">
                    <span>
                        {{ shiftForSale.seller?.firstname }}
                        {{ shiftForSale.seller?.lastname }}
                    </span>
                    <span>
                        {{ 'employee.free-shifts.is-selling-his-shift' | translate | capitalize }}
                    </span>
                </ng-container>
                <ng-container *ngIf="!shiftForSale.seller">
                    <span>
                        {{'admin.calendar.free-shift' | translate | capitalize}}
                    </span>
                </ng-container>
            </div>
            <div *ngIf="hasBidOnShiftForSale$ | async"
                 class="shift-seller d-flex flex-row flex-wrap">
                <ng-container *ngIf="shiftForSale.seller">
                    <span>
                        {{ 'employee.free-shifts.you-have-bid-on-shift-of' | translate | capitalize }}
                    </span>
                    <span>
                        {{ shiftForSale.seller?.firstname }}
                        {{ shiftForSale.seller?.lastname }}
                    </span>
                </ng-container>
                <ng-container *ngIf="!shiftForSale.seller">
                    <span>{{ 'employee.free-shifts.you-have-bid-on-a-free-shift' | translate }}</span>
                </ng-container>
            </div>
        </div>

        <div class="shift-content text-center d-flex flex-grow-1 align-items-center justify-content-center">
            {{ 'employee.free-shifts.confirm-remove-bid-on-shift' | translate | capitalize }}
        </div>

        <div class="shift-actions d-flex flex-row justify-content-end">
            <ui-button-text class="mr-2"
                            (click)="setSelected(false, $event)">
                {{ 'shared.cancel' | translate }}
            </ui-button-text>
            <ui-button-standard color="warn"
                                (click)="removeBidOnShift(shiftForSale, $event); setSelected(true, $event); gas.trackButtonClick('remove bid on shift for sale')">
                {{ 'shared.yes' | translate | capitalize }}
            </ui-button-standard>
        </div>
    </mat-card>
</div>
