import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, shareReplay, switchMap, take } from 'rxjs/operators';
import { Employee } from '../../../model';
import Timestamp from '../../../model/Firestore/FirestoreTimestamp';
import { Auth, FirestoreService, UserActivity, UserActivityService } from '../../../services';

interface FSActivities {
    created_first_department?: Timestamp;
}

@Injectable({
    providedIn: 'root',
})
export class FirestoreUserActivityService extends UserActivityService {
    private readonly collectionPath: string = 'employees';
    private cache: Map<UserActivity, Observable<Date | null>>;

    constructor(private firestoreService: FirestoreService) {
        super();
        this.cache = new Map();
    }

    public load(activity: UserActivity): Observable<Date | null> {
        const cachedObservable = this.cache.get(activity);
        if (cachedObservable) return cachedObservable;
        const loadedActivity$ = this.firestoreService.authenticate<Employee>(this.collectionPath).pipe(
            switchMap((auth: Auth<Employee>) => {
                return auth.collection.doc<{ activities: FSActivities }>(auth.user.user_id).valueChanges();
            }),
            map((employee?: { activities: FSActivities }) => {
                if (!employee || !employee.activities) return null;
                const loadedActivity: Timestamp | undefined = employee.activities[activity];
                return loadedActivity ? loadedActivity.toDate() : null;
            }),
            shareReplay(1),
        );
        this.cache.set(activity, loadedActivity$);
        return loadedActivity$;
    }

    public save(activity: UserActivity): Observable<void> {
        return this.load(activity).pipe(
            take(1),
            switchMap((activityTime: Date | null) => activityTime
                ? of(void 0)
                : this.firestoreService.authenticate<Employee>(this.collectionPath).pipe(
                    switchMap((auth: Auth<Employee>) => {
                        const mergeActivities: Partial<FSActivities> = {};
                        mergeActivities[activity] = Timestamp.now();
                        return auth.collection.doc<{ activities: FSActivities; }>(auth.user.user_id)
                            .set({ activities: mergeActivities }, { merge: true });
                    }),
                )));
    }
}
