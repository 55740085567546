import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { UserActivity, UserActivityService } from '../../../../core/services';
import { ModalService } from '../../../../core/services/ModalService.model';

@Component({
    templateUrl: './ready-for-pro-modal.component.html',
    styleUrls: ['./ready-for-pro-modal.component.scss'],
})
export class ReadyForProModalComponent {

    constructor(
        public modalRef: MatDialogRef<ReadyForProModalComponent>,
        private modalService: ModalService,
        private userActivityService: UserActivityService,
    ) { }

    public openProShop(): void {
        this.close();
        this.modalService.openProShopOverlay();
    }

    public close(): void {
        this.userActivityService.save(UserActivity.CLOSED_SALARY_READY_FOR_PRO_MODAL).subscribe();
        this.modalRef.close();
    }
}
