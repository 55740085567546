<div class="contact-employee-modal-container">
    <div class="employee-contact">
        <div class="employee-contact-image">
            <img [src]="employee.imageURL">
        </div>
        <span>{{ employee.firstname }} {{ employee.lastname }}</span>
    </div>
    <div class="employee-contact-buttons">
        <a [href]="smsLink">
            <ui-button-standard>
                {{ 'shared.text-message' | translate }}
            </ui-button-standard>
        </a>
        <a [href]="phoneLink">
            <ui-button-standard>
                {{ 'shared.phone-call' | translate }}
            </ui-button-standard>
        </a>
    </div>
</div>