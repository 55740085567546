import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    templateUrl: './hourly-wage-modal.component.html',
    styleUrls: ['./hourly-wage-modal.component.scss'],
})
export class HourlyWageModalComponent {

    constructor(
        private modalRef: MatDialogRef<HourlyWageModalComponent>,
    ) { }


    /**
     * Closes the modal for setting the employee wages
     */
    public closeModal(): void {
        this.modalRef.close();
    }

}
