import { Injectable } from '@angular/core';
import { QueryDocumentSnapshot, QuerySnapshot } from '@angular/fire/firestore';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { combineLatest, Observable, of } from 'rxjs';
import { distinctUntilChanged, map, pluck, shareReplay, switchMap, take } from 'rxjs/operators';
import { BackgroundColor } from '../../../../../shared/ui/colors/BackgroundColor';
import { BorderColor } from '../../../../../shared/ui/colors/BorderColor';
import { ConfirmDialogType } from '../../../../../shared/ui/confirm-dialog/ConfirmDialogType';
import { IntegrationStatusInfo } from '../../../../../shared/ui/integration-panel/integration-panel.component';
import { Employee } from '../../../../model';
import { integrationsCollectionPath } from '../../../../model/Firestore';
import { EmployeeMapping, IntegrationStatus, SalaryIntegration, SalaryIntegrationSettings } from '../../../../model/Integrations/Shared';
import { WithID } from '../../../../model/WithID';
import { EmployeeService } from '../../../Employee/EmployeeService.model';
import { Auth, FirestoreService } from '../../../firestore.service';
import { ModalService } from '../../../ModalService.model';

@Injectable({
    providedIn: 'root',
})
export class IntegrationSettingsService {
    private cachedSettings$: Observable<SalaryIntegrationSettings>;

    constructor(
        private firestoreService: FirestoreService,
        private employeeService: EmployeeService,
        private translateService: TranslateService,
        private modalService: ModalService,
    ) { }

    public saveSetting(setting: SalaryIntegration, value: SalaryIntegrationSettings):
        Observable<SalaryIntegrationSettings> {
        return this.firestoreService.authenticate<SalaryIntegrationSettings>(integrationsCollectionPath)
            .pipe(
                map((auth: Auth<SalaryIntegrationSettings>) => auth.collection.doc(setting).set(value, { merge: true })),
                switchMap(() => this.loadSetting(setting)));
    }

    public loadSetting(setting: SalaryIntegration): Observable<SalaryIntegrationSettings> {
        if (!this.cachedSettings$) this.setupCache();
        return this.cachedSettings$.pipe(pluck(setting));
    }

    public getIntegrationStatus(setting?: SalaryIntegration): Observable<IntegrationStatus> {
        return !setting
            ? of({ integration: null })
            : combineLatest([
                this.loadSetting(setting),
                this.getUnmatchedEmployees(setting),
            ]).pipe(switchMap(
                async ([loadedSetting, employees]: [SalaryIntegrationSettings, Employee[]]) =>
                    loadedSetting
                        ? await this.existingIntegrationStatus(employees, setting)
                        : { integration: null },
            ));
    }

    public getIntegrationStatusInfo(setting: SalaryIntegration): Observable<IntegrationStatusInfo | undefined> {
        return this.getIntegrationStatus(setting)
            .pipe(switchMap(async (integrationStatus: IntegrationStatus) =>
                integrationStatus.integration ?
                    integrationStatus.error
                        ? ({
                            labelText: integrationStatus.error,
                            labelColor: BackgroundColor.WARN,
                            borderColor: BorderColor.WARN,
                            integration: setting,
                        })
                        : ({
                            labelText: await this.translateService.get('integrations.integrated').toPromise(),
                            labelColor: BackgroundColor.SUCCESS,
                            borderColor: BorderColor.SUCCESS,
                            integration: setting,
                        })
                    : undefined,
            ));
    }

    public removeIntegration(setting: SalaryIntegration): Observable<void> {
        return this.firestoreService.authenticate<SalaryIntegrationSettings>(integrationsCollectionPath)
            .pipe(switchMap((auth: Auth<SalaryIntegrationSettings>) => auth.collection.doc(setting).delete()));
    }

    /**
     * Starts a new integration. If user is already integrated,
     * confirm removal of old integration before setting up the new
     * @param newIntegration - The new integration to set up
     */
    public async startIntegration(newIntegration: SalaryIntegration): Promise<void> {
        const currentIntegration = await this.getActiveSalaryIntegration().pipe(take(1)).toPromise();

        // If the user already set up an integration different from the one he's starting
        if (currentIntegration && currentIntegration !== newIntegration) {
            const currentIntegrationName = await this.translateService.get(
                'integrations.' + currentIntegration + '.name').pipe(take(1)).toPromise();
            const newIntegrationName = await this.translateService.get(
                'integrations.' + newIntegration + '.name').pipe(take(1)).toPromise();

            // Ask user to confirm removal of old integration
            const overwriteExisting = await this.modalService.openConfirmDialog({
                confirmDialogType: ConfirmDialogType.WARNING,
                titleTranslationKey: 'integrations.overwrite-existing-modal.title',
                subtitleTranslationKey: 'integrations.overwrite-existing-modal.subtitle',
                subtitleTranslationParams: { currentIntegrationName, newIntegrationName },
                closeButtonTranslationKey: 'integrations.overwrite-existing-modal.close',
                closeButtonTranslationParams: { currentIntegrationName },
                acceptButtonTranslationKey: 'integrations.overwrite-existing-modal.accept',
                acceptButtonTranslationParams: { currentIntegrationName, newIntegrationName },
            }, true).toPromise();

            // If the user cancels, return and do nothing
            if (!overwriteExisting) return;

            // Remove the old integration
            await this.removeIntegration(currentIntegration).pipe(take(1)).toPromise();
        }

        // Finally open the integration modal
        this.modalService.openIntegrationModal(newIntegration);
    }

    /**
     * Get an active salary integration, or undefined if none
     */
    public getActiveSalaryIntegration(): Observable<SalaryIntegration | undefined> {
        return this.firestoreService.authenticate<SalaryIntegrationSettings>(integrationsCollectionPath).pipe(
            switchMap((auth: Auth<SalaryIntegrationSettings>) => this.firestoreService.observeOnSnapshot(auth.collection)),
            map(this.findSalaryIntegration),
            distinctUntilChanged(),
        );
    }

    /**
     * Find a salary integration setting from a list of document changes
     * @param docChanges - The document changes to search through
     */
    private findSalaryIntegration(
        snap: QuerySnapshot<SalaryIntegrationSettings>,
    ): SalaryIntegration | undefined {

        // Search for a salary integration document
        const salaryIntegrationDoc = snap.docs.find(
            (docSnap: QueryDocumentSnapshot<SalaryIntegrationSettings>) =>
                _.values(SalaryIntegration).find((salaryIntegrationSetting: SalaryIntegration) =>
                    salaryIntegrationSetting === docSnap.id),
        );

        // If found, return the id (which is the IntegrationSetting)
        if (salaryIntegrationDoc) return salaryIntegrationDoc.id as SalaryIntegration;
    }

    private setupCache(): void {
        this.cachedSettings$ = this.firestoreService.authenticate<SalaryIntegrationSettings>(integrationsCollectionPath)
            .pipe(
                switchMap((auth: Auth<SalaryIntegrationSettings>) => auth.collection.valueChanges<'id'>({ idField: 'id' })),
                map(this.buildSettingsObject),
                shareReplay(1),
            );
    }

    private getUnmatchedEmployees(setting: SalaryIntegration): Observable<Employee[]> {
        return combineLatest([
            this.employeeService.getEmployees(),
            this.loadSetting(setting),
        ]).pipe(map(
            ([employees, loadedSetting]: [Employee[], SalaryIntegrationSettings]) => {
                return employees.filter((employee: Employee) =>
                    !(loadedSetting)?.employeeMap?.find((mapping: EmployeeMapping) =>
                        Object.keys(mapping)[0] === employee.id));
            }));
    }

    private buildSettingsObject(snapshot: WithID<SalaryIntegrationSettings>[]): SalaryIntegrationSettings {
        return snapshot
            .reduce((acc: SalaryIntegrationSettings, doc: WithID<SalaryIntegrationSettings>) =>
                ({ ...acc, [doc.id]: _.omit(doc, ['id']) }), {});
    }

    private async existingIntegrationStatus(employees: Employee[], setting: SalaryIntegration): Promise<IntegrationStatus> {
        const error = await this.translateService.get(
            employees.length > 1
                ? 'integrations.employees-without-match'
                : 'integrations.employee-without-match',
            { amount: employees.length }).toPromise();

        return employees.length ?
            ({ integration: setting, error })
            : ({ integration: setting });
    }
}
