import { OnDestroy, Pipe, PipeTransform } from '@angular/core';
import { combineLatest, Observable, Subscription, timer } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

const LOADING_DELAY_MS = 1000;

@Pipe({
    name: 'loading',
    pure: false,
})
export class LoadingPipe implements OnDestroy, PipeTransform {
    // The current state
    private _isLoading: boolean = false;

    // The subscription to the observable (or null if pipe's just been created)
    private _subscription: Subscription | null = null;

    // The observable to check for loading (or null if pipe's just been created)
    private _observable: Observable<unknown> | null = null;

    public transform(observable: Observable<unknown>): boolean {
        // If there's currently no observable, subcribe to the given one
        if (!this._observable && observable) this._subscribe(observable);

        // If the input observable and the current are not the same, dispose of pipe and initialize with the new observable
        if (observable !== this._observable) {
            this._dispose();
            return this.transform(observable);
        }

        // Return the current is loading state
        return this._isLoading;
    }

    public ngOnDestroy(): void {
        this._dispose();
    }

    private _subscribe(observable: Observable<unknown>): void {
        this._observable = observable;
        // Subscribe to the observable and the loading delay timer
        this._subscription = combineLatest([
            // When observable emits, set isLoading to false
            this._observable.pipe(tap(() => this._isLoading = false)),
            // If observable hasn't emitted and the loading delay has passed, set isLoading to true
            timer(LOADING_DELAY_MS).pipe(takeUntil(this._observable), tap(() => this._isLoading = true)),
        ]).subscribe();
    }

    private _dispose(): void {
        this._isLoading = false;
        this._subscription?.unsubscribe();
        this._subscription = null;
        this._observable = null;
    }
}
