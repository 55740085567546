<div class="dialog-container d-flex flex-column"
     *ngIf="!showConflicts">
    <div class="dialog-header relion-bg-success d-flex align-items-center justify-content-center">
        <i class="far fa-check"></i>
    </div>
    <div class="dialog-content text-center d-flex flex-column">
        <span class="font-bold">{{ 'admin.calendar.fill-shifts-dialog-title' | translate | capitalize}}</span>
        <span class="text-small">{{ 'admin.calendar.fill-shifts-dialog-subtitle' | translate | capitalize}}</span>
    </div>
    <div class="dialog-actions text-center"
         *ngIf="!data.success">
        <ui-button-standard (click)="showConflicts = true">{{ 'shared.show-conflicts' | translate | capitalize}}
        </ui-button-standard>
    </div>
</div>

<!-- Conflicts -->
<div class="dialog-container d-flex flex-column"
     *ngIf="showConflicts">
    <div class="dialog-header d-flex flex-column">
        <span class="font-bold">{{ 'shared.conflicts' | translate | capitalize}}</span>
        <span class="text-small">{{ data.msg }}</span>
    </div>
    <div class="dialog-actions text-center">
        <ui-button-standard (click)="closeDialog()">{{ 'shared.understood' | translate | capitalize }}
        </ui-button-standard>
    </div>
</div>