<app-header *ngIf="modalData.mobile">
    <div class="left">
        <button mat-stroked-button
                color="primary"
                class="btn-back ml-4"
                (click)="modalRef.close()">
            <i class="fa fa-arrow-left"></i>
            {{'shared.back' | translate | capitalize}}
        </button>
    </div>
</app-header>

<div class="approve-container">
    <mat-toolbar class="shifts-actions">
        <mat-toolbar-row>
            <span class="spacer"></span>
            <button mat-stroked-button
                    (click)="approveShifts()"
                    class="btn-approve">
                {{'shared.approve' | translate }}
            </button>
        </mat-toolbar-row>
    </mat-toolbar>

    <!-- Header titles -->
    <div class="shifts-header">
        <div class="shift-col shift-date">{{'shared.date' | translate | capitalize}}</div>
        <div class="shift-col shift-emp">{{'shared.employee' | translate | capitalize}}</div>
        <div class="shift-col shift-start">{{'shared.start' | translate | capitalize}}</div>
        <div class="shift-col shift-end">{{'shared.end' | translate | capitalize}}</div>
        <div class="shift-col shift-length">{{'shared.planned' | translate | capitalize}}</div>
        <div class="shift-col shift-difference">{{'shared.new-time' | translate | capitalize}}</div>
        <div class="shift-col shift-select">
            <mat-checkbox [(ngModel)]="allShiftsSelected"
                          [(indeterminate)]="indeterminateSelection"
                          (change)="toggleSelectAllShifts($event)">
            </mat-checkbox>
        </div>
        <div class="shift-col shift-edit"></div>
    </div>

    <!-- Shifts -->
    <div class="shifts-container">
        <div class="shifts"
             *ngFor="let shiftToApprove of shiftsToApprove; trackBy: trackShiftToApprove">
            <app-approve-shift-item [shiftToApprove]=shiftToApprove
                                    [selectedClicked]=selectedClicked>
            </app-approve-shift-item>
        </div>
        <div class="allShiftsApprovedText">
            <span *ngIf="shiftsToApprove?.length === 0">
                {{ 'shared.all-shifts-approved' | translate | capitalize }}
            </span>
            <span *ngIf="!shiftsToApprove">
                {{ 'shared.loading' | translate | capitalize }}...
            </span>
        </div>
    </div>
</div>
