import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'searchFilter',
})
export class SearchFilterPipe<T> implements PipeTransform {

    public transform(
        searchList: T[] | null | undefined,
        searchStringBuilder: (element: T) => string,
        searchKeywordString: string): T[] {

        const result: T[] = [];
        const searchKeywords = searchKeywordString.toLowerCase().split(' ');

        if (!searchList) return result;

        for (const searchItem of searchList) {
            let numberOfMatches: number = 0;

            const searchString: string = searchStringBuilder(searchItem).toLowerCase();
            for (const keyword of searchKeywords) {
                if (searchString.indexOf(keyword) !== -1) {
                    numberOfMatches += 1;
                }
            }
            // We need exact number of matches to match number of keywords
            if (numberOfMatches === searchKeywords.length) {
                result.push(searchItem);
            }
        }
        return result;
    }
}
