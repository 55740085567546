import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { UserActivity, UserActivityService } from '../../../../../../core/services';

@Component({
    selector: 'app-calendar-stepper-completed-modal',
    templateUrl: './calendar-stepper-completed-modal.component.html',
    styleUrls: ['./calendar-stepper-completed-modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CalendarStepperCompletedModalComponent {

    constructor(
        private modalRef: MatDialogRef<CalendarStepperCompletedModalComponent>,
        private userActivityService: UserActivityService,
    ) { }

    public closeModal(): void {
        this.userActivityService.save(UserActivity.COMPLETED_CALENDAR_STEPPER).subscribe();
        this.modalRef.close();
    }
}
