import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Employee, Role } from '../../../../../../../../core/model';
import { LazyLoadImageProviderFactory } from '../../../../../../../../shared/factories/LazyLoadImageHooks';

@Component({
    selector: 'app-edit-department-employee-item',
    templateUrl: './edit-department-employee-item.component.html',
    styleUrls: ['./edit-department-employee-item.component.scss'],
    providers: [LazyLoadImageProviderFactory(1)],
})
export class EditDepartmentEmployeeItemComponent implements OnInit {

    @Input() public roles$: Observable<Role[]>;
    @Input() public employee: Employee;
    public selectedDepartmentRoles$: Observable<Role[]>;
    @Output() public removeEmployeeClicked: EventEmitter<void>;
    @Output() public selectedRoles: EventEmitter<Role[]>;
    public newSelection: Role[];
    constructor() {
        this.removeEmployeeClicked = new EventEmitter();
        this.selectedRoles = new EventEmitter();
    }

    public ngOnInit(): void {
        this.selectedDepartmentRoles$ = this.roles$.pipe(
            map((roles: Role[]) => roles.filter((depRole: Role) =>
                this.employee.roles.some((role: Role) => role.id === depRole.id))));
    }

}
