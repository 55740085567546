import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-settings-divider',
    templateUrl: './divider.component.html',
    styleUrls: ['./divider.component.scss'],
})
export class DividerComponent {
    @Input() public title: string;
    @Input() public mobile?: boolean;
}
