import { Injectable } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/functions';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { CustomError, isCustomError } from '../../../../functions/src/errorHandling/models';
import { environment } from '../../../environments/environment.dev';
import { CompanyIdRequest, CreateCompanyRequestBody, CreateFenerumAccountRequest, CreateSubscriptionClientRequest, ExportToSalaryRequest, FenerumCardURL, PredictionParams, SetAdminRequest, SignupEmployeeRequest } from '../../shared/utilities/CloudFunctionUtils';
import { Company, Employee, Prediction } from '../model';
import { FenerumAccount } from '../model/Fenerum/FenerumAccount';
import { FenerumSubscriptionPlans } from '../model/Fenerum/FenerumSubscriptionPlans';
import { FirestoreEmployee, FirestoreShift } from '../model/Firestore';
import { DanloenExportData } from '../model/Integrations/Danloen';
import { DataloenEmployee, DataloenExportData } from '../model/Integrations/Dataloen';
import { Employee as SalaryEmployee } from '../model/Integrations/SalaryDK';
import { Language } from '../model/UserSettings';

@Injectable({
    providedIn: 'root',
})
export class CloudFunctionsService {

    constructor(private cloudFunctionsService: AngularFireFunctions) {
        if (environment.useEmulator) cloudFunctionsService.useFunctionsEmulator!('http://localhost:5001');
    }

    public async updateFenerumPaymentCards(requestBody: CompanyIdRequest): Promise<void> {
        await this.callCloudFunction('updateFenerumPaymentCards', requestBody);
    }

    public async signupCompanyWithAdmin(requestBody: CreateCompanyRequestBody): Promise<void> {
        await this.callCloudFunction('signupCompany', requestBody);
    }

    public async createFenerumAccount(requestBody: CreateFenerumAccountRequest): Promise<void> {
        await this.callCloudFunction('createFenerumAccount', requestBody);
    }

    public async getFenerumAccount(requestBody: CompanyIdRequest): Promise<FenerumAccount> {
        return await this.callCloudFunction('getFenerumAccount', requestBody);
    }

    public async getCardURL(requestBody: CompanyIdRequest): Promise<string> {
        const response: FenerumCardURL = await this.callCloudFunction<CompanyIdRequest, FenerumCardURL>('getFenerumCardUrl', requestBody);
        return response.url;
    }

    public async createSubscription(requestBody: CreateSubscriptionClientRequest): Promise<void> {
        await this.callCloudFunction('createFenerumSubscription', requestBody);
    }

    public async getSubscriptionPlans(): Promise<FenerumSubscriptionPlans> {
        return await this.callCloudFunction('getFenerumSubscriptionPlans');
    }
    public checkSalaryApiKey(apiKey: string): Promise<boolean> {
        return this.callCloudFunction('checkSalaryApiKey', apiKey);
    }

    public listSalaryEmployees(): Observable<SalaryEmployee[]> {
        return this.cloudFunctionsService.httpsCallable<void, SalaryEmployee[]>('listSalaryEmployees')();
    }

    public listDataloenEmployees(): Observable<DataloenEmployee[]> {
        return this.cloudFunctionsService.httpsCallable<void, DataloenEmployee[]>('listDataloenEmployees')();
    }

    public exportToDataloen(req: DataloenExportData): Promise<void> {
        return this.cloudFunctionsService.httpsCallable<DataloenExportData>('exportToDataloen')(req).toPromise();
    }

    public exportToSalary(req: ExportToSalaryRequest): Observable<void> {
        return this.cloudFunctionsService.httpsCallable<ExportToSalaryRequest, void>('exportToSalary')(req);
    }

    public checkDanloenApiKey(apiKey: string): Promise<boolean> {
        return this.callCloudFunction('checkDanloenAPIKey', apiKey);
    }

    public exportToDanloen(payParts: DanloenExportData): Promise<void> {
        return this.callCloudFunction('exportToDanloen', payParts);
    }

    public isAdmin(employee: Employee): Observable<boolean> {
        return this.cloudFunctionsService.httpsCallable<Employee, boolean>('getAdmin')(employee);
    }

    public setAdmin(employee: Employee, shouldBeAdmin: boolean): Observable<boolean> {
        return this.cloudFunctionsService.httpsCallable<SetAdminRequest, boolean>('setAdmin')({ employee, shouldBeAdmin });
    }

    public signupEmployee(employee: FirestoreEmployee, company: Company, password: string, language: Language): Promise<void> {
        return this.callCloudFunction<SignupEmployeeRequest, void>('signupEmployee', { employee, company, password, language });
    }

    public checkDataloenAPIKey(apiKey: string): Promise<boolean> {
        return this.callCloudFunction('checkDataloenAPIKey', apiKey);
    }

    public async getDailyRevenueForecast(departmentID: string, modelVersion: string, startDate: Date, endDate: Date)
        : Promise<void> {
        await this.callCloudFunction<PredictionParams, Prediction[]>('getDailyRevenueForecast', {
            departmentID, modelVersion,
            startDateString: moment(startDate).format('YYYY-MM-DD'),
            endDateString: moment(endDate).format('YYYY-MM-DD'),
        });
    }

    public async claimNotificationToken(token: string): Promise<void> {
        return this.callCloudFunction('claimNotificationToken', token);
    }

    public async revokeNotificationTokenFromCurrentUser(token: string): Promise<void> {
        return this.callCloudFunction('revokeNotificationTokenFromCurrentUser', token);
    }

    public async notifyEmployeesOnShiftRelease(shifts: Omit<FirestoreShift, 'shiftForSaleID'>[], companyID: string): Promise<void> {
        return this.callCloudFunction('notifyEmployeesOnShiftRelease', { companyID, shifts });
    }

    /**
     * Call a Cloud Function with a specified endpoint and an optional request body
     * @param endpoint - The endpoint to call
     * @param requestBody - An optional request body to supply to the Cloud Function at the endpoint
     * @returns - The data of the response from the Cloud Function (with the type of U)
     */
    private async callCloudFunction<T, U>(endpoint: string, requestBody?: T): Promise<U> {
        const cloudFunction = this.cloudFunctionsService.httpsCallable<T | undefined, U>(endpoint);
        const result = await cloudFunction(requestBody).pipe(take(1)).toPromise();
        this.throwIfErrorResult(result);
        return result;
    }


    /**
     * Used to throw an error if the given result has an errorCode.
     * This is necessary to receive custom errors from cloud function.
     * @param result A result from a cloud function
     */
    private throwIfErrorResult<U>(result: U | CustomError): void {
        if (isCustomError(result)) throw result.errorCode;
    }
}
