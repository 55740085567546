<ng-container *ngIf="(gpsZones$ | async) as zones">
    <div class="zones-container">
        <div *ngFor="let zone of zones, let i = index"
             class="gps-zone-container">
            <div class="image-container">
                <img *ngIf="zone.imageURL"
                     [src]="zone.imageURL">
                <i *ngIf="!zone.imageURL"
                   class="far fa-map-marked-alt"></i>
            </div>
            <div class="text-container">
                <div class="name">{{zone.name}}</div>
                <div class="address">{{zone.address}}</div>
            </div>
            <div class="button-container">
                <ui-button-icon class="edit"
                                (click)="startEditZone(zone)"
                                [basic]="true"><i class="far fa-pen"></i></ui-button-icon>
                <ui-button-icon [basic]="true"
                                (click)="deleteZone(zone)"><i class="far fa-trash-alt"></i></ui-button-icon>
            </div>
        </div>
        <button class="new-zone-button"
                type="button"
                (click)="createNewZone()"
                [class.no-zones]="!zones?.length">{{ 'settings.punch-clock.create-new-zone' | translate }}</button>
    </div>
    <div class="warning-text"
         *ngIf="!zones?.length">
        {{ 'settings.punch-clock.gps-zone-warning' | translate }}
    </div>
</ng-container>