<div class="d-flex flex-column justify-content-between h-100">
    <div>
        <div class="top d-flex flex-row align-items-center">
            <i (click)="dismiss()"
               class="close-icon far"
               [ngClass]="dialogRef ? 'fa-chevron-down' : 'fa-arrow-left'"></i>
            <div class="title">
                {{ isShift(shift) ? ('modal.shift-detail.edit-shift' | translate) : ('modal.shift-detail.new-shift' | translate)}}
            </div>
        </div>

        <app-day-view-shift [(shift)]="shift"
                            [roles$]="roles$"
                            [wishes$]="wishes$"
                            [dismiss]="dismiss"
                            [mobileVersion]="true"></app-day-view-shift>
    </div>
    <div>
        <app-salary-swipe-label [shift]="shift" [mobileVersion]="true"></app-salary-swipe-label>
        <ui-button-action class="save-and-publish"
                          [disabled]="!isValidShift(shift)"
                          [wide]="true"
                          [tall]="true"
                          (click)="publish()">
            {{ shift.released ? ('shared.save' | translate | capitalize) : ('shared.save-and-release' | translate) }}
        </ui-button-action>
    </div>
</div>
