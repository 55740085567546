<div class="card-details-container">
    <i class="spinner fad fa-spinner-third fa-spin"></i>
    <div class="title">
        {{ 'modal.card-details.title' | translate }}
    </div>
    <div class="text">
        {{ 'modal.card-details.description' | translate }}
    </div>
    <ui-button-standard (click)="openPaymentTab()">{{ 'modal.card-details.open-tab' | translate }}</ui-button-standard>
    <ui-button-text (click)="cancel()">{{'shared.cancel' | translate }}</ui-button-text>
</div>