import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    templateUrl: './salary-dk-integration-help-modal.component.html',
    styleUrls: ['./salary-dk-integration-help-modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SalaryDkIntegrationHelpModalComponent {

    constructor(public modalRef: MatDialogRef<SalaryDkIntegrationHelpModalComponent>) { }

}
