import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Package } from '../../../../core/model/Freemium/Package';
import { ConfigurationService } from '../../../../core/services/Configuration/configuration-service.model';

@Component({
    selector: 'app-upgrade-arrow',
    templateUrl: './upgrade-arrow.component.html',
    styleUrls: ['./upgrade-arrow.component.scss'],
})
export class UpgradeArrowComponent implements OnInit {
    public pro$: Observable<boolean>;
    @Input()
    public set package(value: Package | undefined) {
        this.pro$ = this.configurationService.isPro(value);
    }
    @Input() public alwaysShow: boolean;

    constructor(private configurationService: ConfigurationService) { }

    public ngOnInit(): void {
        if (!this.pro$) this.package = undefined;
    }
}
