import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export abstract class UserActivityService {

    /**
     * Loads a user activity
     * @param activity - The {UserActivity} to load
     */
    public abstract load(activity: UserActivity): Observable<Date | null>;

    /**
     * Saves a user activity, if it has not already been saved
     * @param activity - The {UserActivity} to save
     */
    public abstract save(activity: UserActivity): Observable<void>;
}

export enum UserActivity {
    CLOSED_SALARY_READY_FOR_PRO_MODAL = 'closed_salary_ready_for_pro_modal',
    COMPLETED_CALENDAR_STEPPER = 'completed_calendar_stepper',
    COMPLETED_SALARY_FREEMIUM_EMPLOYEE_WAGES_ONBOARDING = 'completed_salary_freemium_employee_wages_onboarding',
    COMPLETED_SALARY_FREEMIUM_INTRODUCTION_ONBOARDING = 'completed_salary_freemium_introduction_onboarding',
    COMPLETED_SALARY_FREEMIUM_SALARY_PERIOD_ONBOARDING = 'completed_salary_freemium_salary_period_onboarding',
    COMPLETED_SALARY_PRO_INTEGRATIONS_ONBOARDING = 'completed_salary_pro_integrations_onboarding',
    COMPLETED_SALARY_PRO_SHIFT_BREAKS_ONBOARDING = 'completed_salary_pro_shift_breaks_onboarding',
    COMPLETED_SALARY_PRO_SUPPLEMENTS_ONBOARDING = 'completed_salary_pro_supplements_onboarding',
    COPIED_FIRST_DAY = 'copied_first_day',
    CREATED_FIRST_DEPARTMENT = 'created_first_department',
    CREATED_FIRST_SHIFT = 'created_first_shift',
    CREATED_FIRST_TEMPLATE = 'created_first_template',
    DISMISSED_TRIAL_EXPIRATION = 'dismissed_trial_expiration',
    SEEN_FIRST_EMPLOYEE = 'seen_first_employee',
    SELECTED_WEEK_DATE_RANGE = 'selected_week_date_range',
    UNDERSTOOD_PUBLISH = 'understood_publish',
}
