import { Component, ComponentFactory, ComponentFactoryResolver, ComponentRef, Inject, OnDestroy, OnInit, Type, ViewChild, ViewContainerRef } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GeneralSettingsComponent } from './pages/general-settings/general-settings.component';
import { PaymentSettingsComponent } from './pages/payment-settings/payment-settings.component';
import { ProfileSettingsComponent } from './pages/profile-settings/profile-settings.component';
import { PunchclockSettingsComponent } from './pages/punchclock-settings/punchclock-settings.component';
import { SalarySettingsComponent } from './pages/salary-settings/salary-settings.component';
import { ScheduleSettingsComponent } from './pages/schedule-settings/schedule-settings.component';

// The modal input data
export interface SettingsModalData {
    selectedSetting: NavbarSettings;
}

// The different settings pages
export enum NavbarSettings {
    PROFILE,
    GENERAL,
    SCHEDULE,
    SALARY,
    PUNCHCLOCK,
    PAYMENT,
}

type SettingsComponent =
    GeneralSettingsComponent |
    PaymentSettingsComponent |
    ProfileSettingsComponent |
    PunchclockSettingsComponent |
    SalarySettingsComponent |
    ScheduleSettingsComponent;

// The type of each setting page
export interface NavbarSetting {
    title: string;
    icon: string;
    component: Type<SettingsComponent>;
}

@Component({
    selector: 'app-settings-modal',
    templateUrl: './settings-modal.component.html',
    styleUrls: ['./settings-modal.component.scss'],
})
export class SettingsModalComponent implements OnInit, OnDestroy {
    public settings: NavbarSetting[];
    public selectedSetting: NavbarSetting;

    // Reference to the template container used to render the settings content
    @ViewChild('settingsContent', { read: ViewContainerRef, static: true }) private container: ViewContainerRef;
    // Reference to the rendered settings content
    private componentRef?: ComponentRef<SettingsComponent>;

    constructor(
        @Inject(MAT_DIALOG_DATA) private data: SettingsModalData | null,
        private resolver: ComponentFactoryResolver,
    ) {
        // Init the setting list
        this.settings = [];
        this.settings[NavbarSettings.PROFILE] = {
            title: '',
            icon: '',
            component: ProfileSettingsComponent,
        };
        this.settings[NavbarSettings.GENERAL] = {
            title: 'shared.general',
            icon: 'far fa-cog',
            component: GeneralSettingsComponent,
        };
        this.settings[NavbarSettings.SCHEDULE] = {
            title: 'shared.shift-schedule',
            icon: 'far fa-calendar-alt',
            component: ScheduleSettingsComponent,
        };
        this.settings[NavbarSettings.SALARY] = {
            title: 'salary.salary',
            icon: 'far fa-coins',
            component: SalarySettingsComponent,
        };
        this.settings[NavbarSettings.PUNCHCLOCK] = {
            title: 'shared.punch-clock',
            icon: 'far fa-stopwatch',
            component: PunchclockSettingsComponent,
        };
        this.settings[NavbarSettings.PAYMENT] = {
            title: 'shared.payment',
            icon: 'far fa-credit-card',
            component: PaymentSettingsComponent,
        };
    }

    public ngOnInit(): void {
        // If modal data is supplied, select the requested setting, otherwise, select the schedule setting
        if (this.data) this.selectedSetting = this.settings[this.data.selectedSetting]!;
        else this.selectedSetting = this.settings[NavbarSettings.SCHEDULE]!;
        // Render the settings content of the selected component
        this.createComponent(this.selectedSetting.component);
    }

    /**
     * Renders a component in the settings content template ref
     * @param component - The component to render
     */
    public createComponent(component: Type<SettingsComponent>): void {
        // Clear the container of any previously rendered content
        this.container.clear();
        // Initialize the component creating factory
        const factory: ComponentFactory<SettingsComponent> =
            this.resolver.resolveComponentFactory(component);
        // Render the component in the settings content template reference using the factory
        this.componentRef = this.container.createComponent(factory);
    }

    public ngOnDestroy(): void {
        // Destroy the rendered component (if any) when closing the modal
        if (this.componentRef) this.componentRef.destroy();
    }
}
