<div class="approve-trade">
    <div *ngIf="isPro$ | async">
        <div *ngIf="shiftForSale.bids.length === 1"
             class="single-bidder d-flex">
            <div class="picture">
                <img class="picture"
                     [src]="selectedBuyer.imageURL">
            </div>
            <div class="d-flex flex-column name-and-text">
                <div class="name dot-dot-dot"
                     *ngIf="shiftForSale.bids[0]!.bidder as bidder">
                    {{ bidder.firstname + ' ' + bidder.lastname }}
                </div>
                <div class="text">
                    {{ 'notification.shift-cover.wants-to-take-over-shift' | translate }}
                </div>
            </div>
        </div>
        <div *ngIf="shiftForSale.bids.length > 1"
             class="multiple-bidders">
            {{ 'notification.shift-cover.multiple-want-to-take-over-shift' | translate : {amount:
            shiftForSale.bids.length} }}
            <div class="buyer-select"
                 ngbDropdown
                 placement="top">
                <div ngbDropdownToggle
                     class="d-flex align-items-center selected-buyer">
                    <div class="picture">
                        <img class="picture"
                             [src]="selectedBuyer.imageURL">
                    </div>
                    <div class="buyer-name dot-dot-dot">
                        {{ selectedBuyer.firstname + ' ' + selectedBuyer.lastname }}
                    </div>
                    <i class="fa fa-chevron-down flex-shrink-0"></i>
                </div>
                <div ngbDropdownMenu
                     class="w-100">
                    <button ngbDropdownItem
                            *ngFor="let bid of shiftForSale.bids"
                            (click)="selectedBuyer = bid.bidder"
                            class="bidder">
                        <div class="picture">
                            <img class="picture"
                                 [lazyLoad]="bid.bidder.imageURL">
                        </div>
                        <div class="name dot-dot-dot">
                            {{ bid.bidder.firstname + ' ' + bid.bidder.lastname }}
                        </div>
                    </button>
                </div>
            </div>
        </div>
        <ui-button-action (click)="acceptTrade(shiftForSale)"
                          [wide]="true">{{ 'shared.approve' | translate }}</ui-button-action>
        <ui-button-standard (click)="rejectTrade(shiftForSale)"
                            *ngIf="!!shiftForSale.seller"
                            [wide]="true">{{ 'shared.reject' | translate }}</ui-button-standard>
    </div>
    <div *ngIf="(isPro$ | async) === false"
         class="freemium">
        <div class="d-flex flex-column name-and-text w-100">
            <div class="name">
                {{ 'notification.shift-cover.approve-shift-change' | translate }}
                <app-upgrade-arrow [alwaysShow]="true"></app-upgrade-arrow>
            </div>
            <div class="text">
                {{ 'notification.shift-cover.multiple-want-to-take-over-shift' | translate : {amount:
                shiftForSale.bids.length} }}
            </div>
        </div>

        <ui-button-action [disabled]="true"
                          [wide]="true">{{'freemium.get-pro-to-approve' | translate}}</ui-button-action>
        <ui-button-standard (click)="rejectTrade(shiftForSale)"
                            *ngIf="!!shiftForSale.seller"
                            [wide]="true">{{ 'shared.reject' | translate }}</ui-button-standard>
    </div>
</div>