import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { RouterModule } from '@angular/router';
import { NgbModule, NgbTypeaheadModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { ImageCropperModule } from 'ngx-image-cropper';
import { OverlayscrollbarsModule } from 'overlayscrollbars-ngx';
import { SnackbarComponent } from '../core/services/snackbar/snackbar/snackbar.component';
import { WebpackTranslateLoader } from '../core/webpack-translate-loader';
import { MaterialModule } from '../material-module';
import { DividerComponent } from '../modules/admin/shared/components/settings-modal/components/divider/divider.component';
import { WishLegendComponent } from '../modules/employee/calendar/responsive/components/wish-legend/wish-legend.component';
import { TeamListComponent } from '../modules/employee/team-list/team-list.component';
import { DayShiftCardComponent } from './components/day-shift-card/day-shift-card.component';
import { DepartmentSelectorComponent } from './components/department-selector/department-selector.component';
import { EditEmployeeDepartmentItemComponent } from './components/edit-employee-modal/edit-employee-department/edit-employee-department-item/edit-employee-department-item.component';
import { EditEmployeeDepartmentComponent } from './components/edit-employee-modal/edit-employee-department/edit-employee-department.component';
import { EditEmployeeInfoComponent } from './components/edit-employee-modal/edit-employee-info/edit-employee-info.component';
import { EmployeeFilesComponent } from './components/edit-employee-modal/edit-employee-info/employee-files/employee-files.component';
import { EmployeeNotesComponent } from './components/edit-employee-modal/edit-employee-info/employee-notes/employee-notes.component';
import { EditEmployeeModalComponent } from './components/edit-employee-modal/edit-employee-modal.component';
import { EmployeeContactModalComponent } from './components/employee-contact-modal/employee-contact-modal.component';
import { FooterComponent } from './components/footer/footer.component';
import { CardDetailsModalComponent } from './components/freemium/card-details-modal/card-details-modal.component';
import { ConfirmGoBackToFreeComponent } from './components/freemium/confirm-go-back-to-free/confirm-go-back-to-free.component';
import { ProShopOverlayComponent } from './components/freemium/pro-shop-overlay/pro-shop-overlay.component';
import { ProUpgradeOverlayComponent } from './components/freemium/pro-upgrade-overlay/pro-upgrade-overlay.component';
import { ReadyForProModalComponent } from './components/freemium/ready-for-pro-modal/ready-for-pro-modal.component';
import { StartTrialComponent } from './components/freemium/start-trial/start-trial.component';
import { UpgradeArrowComponent } from './components/freemium/upgrade-arrow/upgrade-arrow.component';
import { UpgradeButtonComponent } from './components/freemium/upgrade-button/upgrade-button.component';
import { UpgradeOverlayBaseComponent } from './components/freemium/upgrade-overlay-base/upgrade-overlay-base.component';
import { FullShiftCardComponent } from './components/full-shift-card/full-shift-card.component';
import { HeaderComponent } from './components/header/header.component';
import { LanguageDropdownComponent } from './components/language-dropdown/language-dropdown.component';
import { MobileShiftCalendarComponent } from './components/mobile-shift-calendar/mobile-shift-calendar.component';
import { MobileWishCalendarComponent } from './components/mobile-wish-calendar/mobile-wish-calendar.component';
import { WishToggleComponent } from './components/mobile-wish-calendar/wish-toggle/wish-toggle.component';
import { NotificationCenterComponent } from './components/notification-center/notification-center.component';
import { NotificationComponent } from './components/notification-center/notification/notification.component';
import { NotificationSettingsToggleComponent } from './components/notification-settings-toggle/notification-settings-toggle.component';
import { ProfilePictureModalComponent } from './components/profile-picture-uploader/profile-picture-modal/profile-picture-modal.component';
import { ProfilePictureUploaderComponent } from './components/profile-picture-uploader/profile-picture-uploader.component';
import { PushNotificationSettingsComponent } from './components/push-notification-settings/push-notification-settings.component';
import { SalarySwipeLabelComponent } from './components/salary-swift-label/salary-swipe-label.component';
import { ExportLogInfoComponent } from './components/shift-detail-modal/export-log/export-log-info/export-log-info.component';
import { ExportLogComponent } from './components/shift-detail-modal/export-log/export-log.component';
import { ExportLogsComponent } from './components/shift-detail-modal/export-logs/export-logs.component';
import { ShiftDetailActionControllerComponent } from './components/shift-detail-modal/shift-detail-action-controller/shift-detail-action-controller.component';
import { ShiftDetailApprovePunchActionComponent } from './components/shift-detail-modal/shift-detail-approve-punch-action/shift-detail-approve-punch-action.component';
import { ShiftDetailApproveTradeActionComponent } from './components/shift-detail-modal/shift-detail-approve-trade-action/shift-detail-approve-trade-action.component';
import { ShiftDetailBuyShiftActionComponent } from './components/shift-detail-modal/shift-detail-buy-shift-action/shift-detail-buy-shift-action.component';
import { ShiftDetailEditShiftTimeActionComponent } from './components/shift-detail-modal/shift-detail-edit-shift-time-action/shift-detail-edit-shift-time-action.component';
import { ShiftDetailPunchActionComponent } from './components/shift-detail-modal/shift-detail-punch-action/shift-detail-punch-action.component';
import { ShiftDetailSellShiftActionComponent } from './components/shift-detail-modal/shift-detail-sell-shift-action/shift-detail-sell-shift-action.component';
import { ShiftStatusPipe } from './components/shift-detail-modal/shift-status.pipe';
import { ShiftDividerComponent } from './components/shift-divider/shift-divider.component';
import { DirectivesModule } from './directives/directives.module';
import { LazyLoadImageProviderFactory } from './factories/LazyLoadImageHooks';
import { PipeModule } from './pipes/pipe.module';
import { UIModule } from './ui/ui.module';


@NgModule({
    declarations: [
        CardDetailsModalComponent,
        ConfirmGoBackToFreeComponent,
        DayShiftCardComponent,
        DepartmentSelectorComponent,
        DividerComponent,
        EditEmployeeDepartmentComponent,
        EditEmployeeDepartmentItemComponent,
        EditEmployeeInfoComponent,
        EditEmployeeModalComponent,
        EmployeeContactModalComponent,
        EmployeeFilesComponent,
        EmployeeNotesComponent,
        ExportLogComponent,
        ExportLogsComponent,
        ExportLogInfoComponent,
        FooterComponent,
        FullShiftCardComponent,
        HeaderComponent,
        LanguageDropdownComponent,
        MobileShiftCalendarComponent,
        MobileWishCalendarComponent,
        NotificationCenterComponent,
        NotificationComponent,
        NotificationSettingsToggleComponent,
        ProfilePictureModalComponent,
        ProfilePictureUploaderComponent,
        ProShopOverlayComponent,
        ProUpgradeOverlayComponent,
        PushNotificationSettingsComponent,
        ReadyForProModalComponent,
        SalarySwipeLabelComponent,
        ShiftDetailActionControllerComponent,
        ShiftDetailApprovePunchActionComponent,
        ShiftDetailApproveTradeActionComponent,
        ShiftDetailBuyShiftActionComponent,
        ShiftDetailEditShiftTimeActionComponent,
        ShiftDetailPunchActionComponent,
        ShiftDetailSellShiftActionComponent,
        ShiftDividerComponent,
        ShiftStatusPipe,
        SnackbarComponent,
        StartTrialComponent,
        TeamListComponent,
        UpgradeArrowComponent,
        UpgradeButtonComponent,
        UpgradeOverlayBaseComponent,
        WishLegendComponent,
        WishToggleComponent,
    ],
    imports: [
        CommonModule,
        DirectivesModule,
        FormsModule,
        ImageCropperModule,
        LazyLoadImageModule,
        MatButtonModule,
        MaterialModule,
        NgbModule,
        NgSelectModule,
        NgbTypeaheadModule,
        OverlayscrollbarsModule,
        PipeModule,
        ReactiveFormsModule,
        RouterModule,
        TranslateModule.forChild({ loader: { provide: TranslateLoader, useClass: WebpackTranslateLoader } }),
        UIModule,
    ],
    exports: [
        CardDetailsModalComponent,
        ExportLogComponent,
        ExportLogsComponent,
        ExportLogInfoComponent,
        ConfirmGoBackToFreeComponent,
        DayShiftCardComponent,
        DepartmentSelectorComponent,
        DividerComponent,
        EditEmployeeDepartmentComponent,
        EditEmployeeDepartmentItemComponent,
        EditEmployeeInfoComponent,
        EditEmployeeModalComponent,
        EmployeeContactModalComponent,
        FooterComponent,
        FullShiftCardComponent,
        HeaderComponent,
        LanguageDropdownComponent,
        LazyLoadImageModule,
        MobileShiftCalendarComponent,
        MobileWishCalendarComponent,
        NotificationCenterComponent,
        NotificationComponent,
        NotificationSettingsToggleComponent,
        ProfilePictureModalComponent,
        ProfilePictureUploaderComponent,
        ProShopOverlayComponent,
        ProUpgradeOverlayComponent,
        PushNotificationSettingsComponent,
        ReadyForProModalComponent,
        SalarySwipeLabelComponent,
        ShiftDetailActionControllerComponent,
        ShiftDetailApprovePunchActionComponent,
        ShiftDetailApproveTradeActionComponent,
        ShiftDetailBuyShiftActionComponent,
        ShiftDetailEditShiftTimeActionComponent,
        ShiftDetailPunchActionComponent,
        ShiftDetailSellShiftActionComponent,
        ShiftDividerComponent,
        ShiftStatusPipe,
        StartTrialComponent,
        TeamListComponent,
        UpgradeArrowComponent,
        UpgradeButtonComponent,
        UpgradeOverlayBaseComponent,
        WishToggleComponent,
        WishLegendComponent,
    ],
    providers: [
        LazyLoadImageProviderFactory(0),
    ],
})
export class SharedModule { }
