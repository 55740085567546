import { Injectable } from '@angular/core';
import { AngularFirestoreCollection } from '@angular/fire/firestore';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { pluck, shareReplay, switchMap, take } from 'rxjs/operators';
import { Day, Department, isDay } from '../model';
import { FirestoreDay, getDaysCollectionPath } from '../model/Firestore';
import Timestamp from '../model/Firestore/FirestoreTimestamp';
import { DateService } from './date.service';
import { EmployeeService } from './Employee/EmployeeService.model';
import { FirestoreService } from './firestore.service';

@Injectable({
    providedIn: 'root',
})
export class DayService {
    private cachedDays: Map<string, Map<string, Observable<Day>>>;

    constructor(
        private employeeService: EmployeeService,
        private firestoreService: FirestoreService,
    ) {
        this.cachedDays = new Map();
    }

    public async updateDay(day: Day, department: Department): Promise<void> {
        const collection: AngularFirestoreCollection<FirestoreDay> = await this.getDaysCollection(department).toPromise();
        return collection.doc<FirestoreDay>(this.getId(day)).set(this.mapDayToFirestoreDay(day));
    }

    public getDay(date: Date, department: Department): Observable<Day> {
        const departmentDayCache: Map<string, Observable<Day>> | undefined = this.cachedDays.get(department.id);
        if (!departmentDayCache) {
            this.cachedDays.set(department.id, new Map());
            return this.getDay(date, department);
        }
        if (departmentDayCache) {
            const cachedDay$ = departmentDayCache.get(this.getId(date));
            if (cachedDay$) return cachedDay$;
            const id: string = this.getId(date);
            departmentDayCache.set(id,
                this.getDaysCollection(department).pipe(
                    switchMap((collection: AngularFirestoreCollection<FirestoreDay>) => collection.doc<FirestoreDay>(id).valueChanges()),
                    switchMap((firestoreDay?: FirestoreDay) => this.mapFirestoreDayToDay(firestoreDay, date)),
                    shareReplay(1),
                ));
        }

        return this.getDay(date, department);
    }

    /**
     * Map a FirestoreDay to a Day
     * @param firestoreDay The firestore day to map from
     * @param date The day of the Day
     */
    private async mapFirestoreDayToDay(firestoreDay: FirestoreDay | undefined, date: Date): Promise<Day> {
        // Set the date of the Day
        const day: Day = { date: DateService.midnight(date) };

        // If there's no day in firestore, return an object with only the date
        if (!firestoreDay) return day;

        // If there's a note on the FirestoreDay, set the note on the Day
        if (firestoreDay.note) {
            day.note = {
                message: firestoreDay.note.message,
                time: firestoreDay.note.time.toDate(),
                sender: await this.employeeService.getEmployee(firestoreDay.note.sender).pipe(take(1)).toPromise(),
            };
        }
        if (firestoreDay.manualRevenue !== undefined) day.manualRevenue = firestoreDay.manualRevenue;

        // Return the Day
        return day;
    }

    /**
     * Map a Day to a FirestoreDay
     * @param day The Day to map from
     */
    private mapDayToFirestoreDay(day: Day): FirestoreDay {
        // Init an empty FirestoreDay
        const firestoreDay: FirestoreDay = {};

        // If there's a note on the Day, set the note on the FirestoreDay
        if (day.note) {
            firestoreDay.note = {
                message: day.note.message,
                time: Timestamp.fromDate(day.note.time),
                sender: day.note.sender.id,
            };
        }
        if (day.manualRevenue !== undefined) firestoreDay.manualRevenue = day.manualRevenue;

        // Return the FirestoreDay
        return firestoreDay;
    }

    /**
     * Get the id of a Date or a Day
     */
    private getId(day: Day | Date): string {
        // Unwrap the date
        const date: Date = isDay(day) ? day.date : day;
        // Get the id using YYYY-MM-DD
        return moment(date).format('YYYY-MM-DD');
    }

    /**
     * Get the FirestoreDay days collection
     */
    private getDaysCollection(department: Department): Observable<AngularFirestoreCollection<FirestoreDay>> {
        return this.firestoreService.authenticate<FirestoreDay>(getDaysCollectionPath(department))
            .pipe(take(1), pluck('collection'));
    }
}
