import { Component, Input } from '@angular/core';
import { BackgroundColor } from '../colors/BackgroundColor';

@Component({
    selector: 'app-label',
    templateUrl: './label.component.html',
    styleUrls: ['./label.component.scss'],
})
export class LabelComponent {
    @Input() public text: string;
    @Input() public color: BackgroundColor;
}
