<div class="punch-times-settings-container d-flex justify-content-between align-items-center">
    <div class="title">{{ 'settings.punch-times.title' | translate }}</div>
    <div class="buttons d-flex align-items-center"
         [ngbPopover]="popover"
         #settingPopover="ngbPopover"
         container="body"
         triggers="manual"
         autoClose="outside"
         placement="bottom-right"
         popoverClass="punch-times-settings-popover"
         (hidden)="save()">
        <ui-button-text (click)="settingPopover.open()"
                        *ngIf="bufferTimes$ | async as bufferTimes">
            <span class="button">
                {{ 'shared.set' | translate }}
            </span>
            <span class="punch-times">
                {{ bufferTimes.checkinBefore }}{{ 'shared.minute-short' | translate }}
                {{ 'shared.before' | translate }},

                {{ bufferTimes.checkoutAfter }}{{ 'shared.minute-short' | translate }}
                {{ 'shared.after' | translate }}
            </span>
        </ui-button-text>
    </div>

    <ng-template #popover>
        <div class="checkin-before d-flex flex-row justify-content-end align-items-center">
            {{ 'settings.punch-times.checkin-before' | translate }}
            <input [value]="checkinBefore"
                   (change)="checkinBefore = $event.target.value"
                   [appRegexMask]="'^[0-9]+$'"
                   onclick="this.select()">
            <span class="minutes">
                {{ 'shared.minutes-short' | translate }}
            </span>
        </div>
        <div class="checkout-after d-flex flex-row justify-content-end align-items-center">
            {{ 'settings.punch-times.checkout-after' | translate }}
            <input [value]="checkoutAfter"
                   (change)="checkoutAfter = $event.target.value"
                   [appRegexMask]="'^[0-9]+$'"
                   onclick="this.select()">
            <span class="minutes">
                {{ 'shared.minutes-short' | translate }}
            </span>
        </div>
    </ng-template>
</div>