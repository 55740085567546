import { Injectable } from '@angular/core';
import { AngularFirestoreDocument, QueryDocumentSnapshot, QuerySnapshot } from '@angular/fire/firestore';
import * as _ from 'lodash';
import { Dictionary } from 'lodash';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { map, shareReplay, switchMap, take } from 'rxjs/operators';
import { TupleOf } from '../../shared/utilities/TypeUtils';
import { Company, Employee, Shift, Template } from '../model';
import { Department } from '../model/Department/Department.model';
import { FirestoreTemplate, FirestoreTemplateShift } from '../model/Firestore/FirestoreTemplate';
import Timestamp from '../model/Firestore/FirestoreTimestamp';
import { DateService } from './date.service';
import { DepartmentService } from './Department/DepartmentService.model';
import { EmployeeService } from './Employee/EmployeeService.model';
import { Auth, FirestoreService } from './firestore.service';
import { RoleService } from './Role/RoleService.model';
import { ShiftService } from './Shift/ShiftService.model';

type NewTemplate = Omit<Template, 'id'>;
@Injectable()
export class TemplateService {
    private readonly collectionID: string = 'templates';
    private cache$?: Observable<Template[]>;

    constructor(private firestoreService: FirestoreService,
        private employeeService: EmployeeService,
        private roleService: RoleService,
        private departmentService: DepartmentService,
        private shiftService: ShiftService,
    ) {
    }

    /**
     * Creates a template based on the start and end dates given
     * @param dateInWeek A date of the week to save as a template
     */
    public async createWeekTemplateFromDate(
        name: string,
        department: Department,
        dateInWeek: Date,
        includeEmployees: boolean,
    ): Promise<string> {
        const monday = moment(dateInWeek).startOf('isoWeek').toDate();
        const mondayWeekAfter = moment(dateInWeek).endOf('isoWeek').add(1, 'days').startOf('day').toDate();

        return this.shiftService.getShifts({
            department,
            startDateFrom: monday,
            startDateTo: mondayWeekAfter,
        }).pipe(
            take(1),
            map((shifts: Shift[]) => includeEmployees ? shifts : shifts.map((shift: Shift) => ({ ...shift, employee: null }))),
            map((shifts: Shift[]) => this.createTemplateFromShifts(name, shifts)),
            switchMap((template: NewTemplate) => this.addTemplate(template)),
        ).toPromise();
    }

    public async insertTemplateInWeek(template: Template, date: Date): Promise<void> {
        const momentMonday = moment(date).startOf('isoWeek');
        const monday = momentMonday.toDate();
        const tuesday = momentMonday.clone().add(1, 'days').toDate();
        const wednesday = momentMonday.clone().add(2, 'days').toDate();
        const thursday = momentMonday.clone().add(3, 'days').toDate();
        const friday = momentMonday.clone().add(4, 'days').toDate();
        const saturday = momentMonday.clone().add(5, 'days').toDate();
        const sunday = momentMonday.clone().add(6, 'days').toDate();
        const getShiftCopyOnNewDate =
            (newDate: Date) => (shift: Shift) => ({ ...shift, ...TemplateService.getStartAndEndDates(newDate, shift) });
        const shiftsToAdd = [
            ...template.monday.map(getShiftCopyOnNewDate(monday)),
            ...template.tuesday.map(getShiftCopyOnNewDate(tuesday)),
            ...template.wednesday.map(getShiftCopyOnNewDate(wednesday)),
            ...template.thursday.map(getShiftCopyOnNewDate(thursday)),
            ...template.friday.map(getShiftCopyOnNewDate(friday)),
            ...template.saturday.map(getShiftCopyOnNewDate(saturday)),
            ...template.sunday.map(getShiftCopyOnNewDate(sunday))];
        this.shiftService.createShifts(shiftsToAdd).pipe(take(1)).subscribe();
    }

    /**
     * Deletes an template
     * @param template - The template to delete
     */
    public deleteTemplate(template: Template): Promise<void> {
        return this.firestoreService.whenReady()
            .then((companyDoc: AngularFirestoreDocument<Company>) => {
                return companyDoc.collection(this.collectionID).doc(template.id).delete();
            });
    }

    public getTemplates(params?: TemplateQueryParams): Observable<Template[]> {
        if (!this.cache$) this.setupCache();

        return this.cache$!.pipe(
            map((templates: Template[]) => {
                if (params?.department) {
                    templates = templates.filter((template: Template) => params.department.id === template.department.id);
                }
                return templates;
            }),
            map((templates: Template[]) => _.sortBy(templates, 'name', String.prototype.localeCompare.bind(this))),
        );
    }

    /**
     * Adds a template to the database
     * @param template - The template to save
     * @returns A promise resolving with the ID of the saved template when it has been added or rejecting on an error
     */
    public addTemplate(template: NewTemplate): Promise<string> {
        if (template.name === undefined) return Promise.reject('Skabelon skal have et navn');

        const id: string = this.firestoreService.angularFireStore.createId();
        return this.firestoreService.whenReady()
            .then((companyDoc: AngularFirestoreDocument<Company>) =>
                companyDoc.collection(this.collectionID).doc(id).set(TemplateService.mapTemplateToFirestoreTemplate(template))
                    .then(() => id)
                    .catch((reason: string) => reason));
    }

    /**
     * Returns a start and end date for a given shift based on the supplied date rather than the shift's own
     * @param day The day that the shift should take place on
     * @param shift The shift to get start and end dates for
     */
    private static getStartAndEndDates(day: Date, shift: Omit<Shift, 'id'>): { end: Date; start: Date } {
        const pastMidnight: boolean = shift.end.getDate() !== shift.start.getDate();
        return {
            start: DateService.getSameTimeOnDifferentDay(day, shift.start),
            end: DateService.getSameTimeOnDifferentDay(pastMidnight ? DateService.getTheDayAfter(day) : day, shift.end),
        };
    }

    /**
     * Turns a regular shift into a shift to be used in the template
     * @param shift - The shift to transform
     * @param employeeCheck - Check whether the template should include employee info
     */
    private static transformShiftToFirestoreTemplateShift(shift: Omit<Shift, 'id'>): FirestoreTemplateShift {
        return {
            role: shift.role.id,
            // (x && y) -> returns y if (x && y) is true
            employeeID: (shift.employee && shift.employee.id) || null,
            start: Timestamp.fromDate(shift.start),
            end: Timestamp.fromDate(shift.end),
        };
    }

    /**
     * Map a FirestoreTemplateShift to a Shift
     * @param fsTemplateShift - The {FirestoreTemplateShift} to map
     * @returns {Shift} - The resulting Shift
     */
    private static mapTemplateToFirestoreTemplate(template: NewTemplate): FirestoreTemplate {
        return {
            name: template.name,
            department: template.department.id,
            monday: template.monday.map(TemplateService.transformShiftToFirestoreTemplateShift),
            tuesday: template.tuesday.map(TemplateService.transformShiftToFirestoreTemplateShift),
            wednesday: template.wednesday.map(TemplateService.transformShiftToFirestoreTemplateShift),
            thursday: template.thursday.map(TemplateService.transformShiftToFirestoreTemplateShift),
            friday: template.friday.map(TemplateService.transformShiftToFirestoreTemplateShift),
            saturday: template.saturday.map(TemplateService.transformShiftToFirestoreTemplateShift),
            sunday: template.sunday.map(TemplateService.transformShiftToFirestoreTemplateShift),
        };
    }

    private setupCache(): void {
        this.cache$ = this.firestoreService.authenticate(this.collectionID)
            .pipe(
                switchMap((auth: Auth<FirestoreTemplate>) => this.firestoreService.observeOnSnapshot(auth.collection)),
                switchMap(async (snapshot: QuerySnapshot<FirestoreTemplate>) =>
                    Promise.all(
                        snapshot.docs.map(async (doc: QueryDocumentSnapshot<FirestoreTemplate>) =>
                            await this.mapDocumentSnapshotToTemplate(doc)),
                    )),
                shareReplay(1));
    }

    /**
     * Creates a template from a name and an array of shifts.
     * Throws an error if there are no shifts in the given array
     * @param name Name of the create template
     * @param shifts Shifts to put in the created template
     */
    private createTemplateFromShifts(name: string, shifts: Shift[]): NewTemplate {
        if (shifts.length === 0) throw Error('You cannot create a template without any shifts');
        const shiftToWeekDay: (shift: Shift) => number = (shift: Shift) => (shift.start.getDay() + 6) % 7;
        const mapShiftsToWeekDays: (shifts: Shift[]) => Dictionary<Shift[]> = (shifts: Shift[]) => _.groupBy(shifts, shiftToWeekDay);
        const department: Department = shifts[0]!.role.department;
        const createTemplate: (shiftDictionary: Dictionary<Shift[]>) => NewTemplate =
            (shiftDictionary: Dictionary<Shift[]>) => ({
                name,
                department,
                monday: shiftDictionary[0] || [],
                tuesday: shiftDictionary[1] || [],
                wednesday: shiftDictionary[2] || [],
                thursday: shiftDictionary[3] || [],
                friday: shiftDictionary[4] || [],
                saturday: shiftDictionary[5] || [],
                sunday: shiftDictionary[6] || [],
            });

        return createTemplate(mapShiftsToWeekDays(shifts));
    }

    /**
     * Map a FirestoreTemplateShift to a Shift
     * @param fsTemplateShift - The {FirestoreTemplateShift} to map
     * @returns {Shift} - The resulting Shift
     */
    private async mapFirestoreTemplateShiftToShift(fsTemplateShift: FirestoreTemplateShift): Promise<Omit<Shift, 'id'>> {

        const employee: Employee | null = await (fsTemplateShift.employeeID
            ? this.employeeService.getEmployee(fsTemplateShift.employeeID).pipe(take(1)).toPromise()
            : Promise.resolve(null));

        return new Promise((resolve) => {
            this.roleService.getRole(fsTemplateShift.role).pipe(take(1)).toPromise()
                .then(async role => {
                    resolve({
                        employee,
                        end: fsTemplateShift.end.toDate(),
                        role,
                        start: fsTemplateShift.start.toDate(),
                        isForSale: false,
                        released: false,
                        approved: false,
                        exportLogs: [],
                    });
                });
        });
    }

    /**
     * Takes a QueryDocumentSnapshot and maps its into a Template object
     * @param {QueryDocumentSnapshot} snapshot The snapshot to map to a Template object
     * @returns {Template} The resulting template
     */
    private mapDocumentSnapshotToTemplate(snapshot: QueryDocumentSnapshot<FirestoreTemplate>): Promise<Template> {
        const firestoreTemplate: FirestoreTemplate = snapshot.data();

        const fun: (fs) => Promise<Omit<Shift, 'id'>> = (fs) => this.mapFirestoreTemplateShiftToShift(fs);
        return Promise.all([
            Promise.all(firestoreTemplate.monday ? firestoreTemplate.monday.map(fun) : []),
            Promise.all(firestoreTemplate.tuesday ? firestoreTemplate.tuesday.map(fun) : []),
            Promise.all(firestoreTemplate.wednesday ? firestoreTemplate.wednesday.map(fun) : []),
            Promise.all(firestoreTemplate.thursday ? firestoreTemplate.thursday.map(fun) : []),
            Promise.all(firestoreTemplate.friday ? firestoreTemplate.friday.map(fun) : []),
            Promise.all(firestoreTemplate.saturday ? firestoreTemplate.saturday.map(fun) : []),
            Promise.all(firestoreTemplate.sunday ? firestoreTemplate.sunday.map(fun) : []),
        ]).then(async (shifts: TupleOf<Omit<Shift, 'id'>[], 7>) => ({
            id: snapshot.id,
            department: await this.departmentService.getDepartment(firestoreTemplate.department).pipe(take(1)).toPromise(),
            name: firestoreTemplate.name,
            monday: firestoreTemplate.monday && shifts[0],
            tuesday: firestoreTemplate.tuesday && shifts[1],
            wednesday: firestoreTemplate.wednesday && shifts[2],
            thursday: firestoreTemplate.thursday && shifts[3],
            friday: firestoreTemplate.friday && shifts[4],
            saturday: firestoreTemplate.saturday && shifts[5],
            sunday: firestoreTemplate.sunday && shifts[6],
        }));

    }
}

export interface TemplateQueryParams {
    department: Department;
}
