import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { MediaService } from '../../../core/services';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
    public bigScreen: boolean;
    private subscriptions: Subscription[];

    constructor(private mediaService: MediaService) {
        this.subscriptions = [];

    }

    public ngOnInit(): void {
        this.subscriptions.push(
            this.mediaService.observeMediaChanges()
                .subscribe((big: boolean) => {
                    this.bigScreen = big;
                }));
    }
    public ngOnDestroy(): void {
        this.subscriptions.forEach((subs: Subscription) => subs.unsubscribe());
    }

}
