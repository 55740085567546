import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { combineLatest } from 'rxjs';
import { first, map, pluck, take } from 'rxjs/operators';
import { Employee, sortEmployeesByName } from '../../../../../../../../../../core/model';
import { EmployeeMapping, SalaryIntegration, SalaryIntegrationSettings } from '../../../../../../../../../../core/model/Integrations/Shared';
import { EmployeeService } from '../../../../../../../../../../core/services';
import { IntegrationSettingsService } from '../../../../../../../../../../core/services/Settings/IntegrationSettings/Firestore/integration-settings.service';
import { LazyLoadImageProviderFactory } from '../../../../../../../../../../shared/factories/LazyLoadImageHooks';
import { DanloenIntegrationMatchingHelpModalComponent } from '../danloen-integration-matching-help-modal/danloen-integration-matching-help-modal.component';

type IgnorableEmployee = Employee & { notIgnored: boolean, mapping: EmployeeMapping };

@Component({
    templateUrl: './danloen-integration-matching-modal.component.html',
    styleUrls: ['./danloen-integration-matching-modal.component.scss'],
    providers: [LazyLoadImageProviderFactory(1)],
})
export class DanloenIntegrationMatchingModalComponent implements OnInit {
    public ignorableEmployees: IgnorableEmployee[] = [];
    public integration: SalaryIntegration = SalaryIntegration.DANLOEN;
    public allEmployeesMapped: boolean = false;

    constructor(
        public modalRef: MatDialogRef<DanloenIntegrationMatchingModalComponent>,
        private employeeService: EmployeeService,
        private integrationSettingsService: IntegrationSettingsService,
        private modal: MatDialog,
    ) { }

    public async ngOnInit(): Promise<void> {
        const employees$ = this.employeeService.getEmployees().pipe(take(1));
        const integrationSettings$ = this.integrationSettingsService.loadSetting(SalaryIntegration.DANLOEN);
        this.ignorableEmployees = await combineLatest([
            employees$,
            integrationSettings$.pipe(
                first((integrationSettings: SalaryIntegrationSettings) => !!integrationSettings),
                pluck<SalaryIntegrationSettings, 'employeeMap'>('employeeMap'),
            ),
        ]).pipe(
            map(([employees, employeeMap]: [Employee[], EmployeeMapping[] | undefined]) =>
                employees.map((employee: Employee) => {
                    const mapping = !employeeMap ? undefined : this.findEmployeeInEmployeeMap(employee, employeeMap);
                    return ({
                        ...employee,
                        notIgnored: mapping ? !!Object.values(mapping)[0] : true,
                        mapping: mapping || { [employee.id]: null },
                    });
                })),
            map((employees: IgnorableEmployee[]) => employees.sort((a, b) => {
                const aMapping = a.mapping[a.id];
                const bMapping = b.mapping[b.id];
                return aMapping === null && bMapping !== null ?
                    -1 : aMapping !== null && bMapping === null ?
                        1 : sortEmployeesByName(a, b);
            })),
            take(1),
        ).toPromise();
        this.checkEmployeeMapping();
    }

    public saveMapping(): void {
        const employeeMap: EmployeeMapping[] = this.ignorableEmployees
            // We save the employees that are ignored, or have a mapping
            .filter((employee: IgnorableEmployee) => !employee.notIgnored || employee.mapping[employee.id])
            .map((employee: IgnorableEmployee) => {
                // Remove empty from ignored mappings
                if (employee.mapping[employee.id] === '') employee.mapping[employee.id] = null;
                return employee.mapping;
            });

        this.integrationSettingsService.saveSetting(SalaryIntegration.DANLOEN, { employeeMap }).pipe(take(1))
            .subscribe();
        this.modalRef.close();
    }

    public checkEmployeeMapping(): void {
        this.allEmployeesMapped = this.ignorableEmployees
            .every((employee: IgnorableEmployee) => employee.mapping[employee.id] || !employee.notIgnored);
    }

    public openHelpModal(): void {
        this.modal.open(DanloenIntegrationMatchingHelpModalComponent, { maxWidth: '460px', autoFocus: false });
    }
    private findEmployeeInEmployeeMap(employee: Employee, employeeMap: EmployeeMapping[]): EmployeeMapping | undefined {
        return employeeMap.find((employeeMapping: EmployeeMapping) => Object.keys(employeeMapping)[0] === employee.id);
    }
}
