import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { DateButton } from 'angular-bootstrap-datetimepicker';
import { Subscription } from 'rxjs';

@Component({
    selector: 'ui-date-range-picker',
    templateUrl: './date-range-picker.component.html',
    styleUrls: ['./date-range-picker.component.scss'],
})
export class DateRangePickerComponent implements OnInit, OnDestroy {

    @ViewChild('popover', { static: true }) public popover: NgbPopover;
    @Output() public change: EventEmitter<{ start: Date, end: Date }>;
    @Input() public set startDate(date: Date) { this.setStartDate(date); }

    @Input() public set endDate(date: Date) { this.setEndDate(date); }

    public get startDate(): Date { return this._startDate; }
    public get endDate(): Date { return this._endDate; }
    private _startDate: Date;
    private _endDate: Date;
    private subscriptions: Subscription[];

    constructor() {
        this.change = new EventEmitter();
        this.subscriptions = [];
    }

    public ngOnInit(): void {
        // Throw an error if the input date are invalid
        if (this.startDate > this.endDate) throw Error('Start date cannot be after end date');

        // Emit changes on popover close
        this.subscriptions.push(
            this.popover.hidden.subscribe({
                next: () => this.emitSelectedDates(),
            }),
        );
    }

    /**
     * Determines whether a date button in the end date picker is selectable.
     * @returns true if the date of the button is after the start date, false otherwise
     */
    public isEndDateSelectable = (dateButton: DateButton): boolean => {
        return dateButton.value >= (this.startDate?.valueOf() || 0);
    }

    public ngOnDestroy(): void {
        this.subscriptions.forEach((sub: Subscription) => sub.unsubscribe());
    }

    /**
     * Set the start date and update the end date if it's before the new start date
     */
    private setStartDate(date: Date): void {
        // If the date has not changed, do nothing
        if (this._startDate === date) return;

        // If the end date has been initialized
        if (this.endDate) {
            // If the new start date is after the current end date, update it
            if (date > this.endDate) this._endDate = new Date(date);
            // Otherwise, reset the end date to trigger disabled dates rendering
            else this._endDate = new Date(this.endDate);
        }

        // Update the start date
        this._startDate = date;
    }

    /**
     * Set the end date and close the popover triggering event emission
     */
    private setEndDate(date: Date): void {
        // If the date has not changed, do nothing
        if (this._endDate === date) return;

        if (!this._endDate) {
            // If the end date is not yet set, set it
            this._endDate = date;
        } else {
            // Otherwise the user has changed it, so set it and close the popover (triggering event emission)
            this._endDate = date;
            this.popover.close();
        }
    }

    /**
     * Emit the selected dates
     */
    private emitSelectedDates(): void {
        const start = new Date(this.startDate);
        const end = new Date(this.endDate);
        this.change.emit({ start, end });
    }
}
