<div class="stats-modal">
    <div mat-dialog-content
         class="mx-auto">
        <div class="dialog-header d-flex flex-row align-items-center justify-content-between">
            <div class="slider-text font-normal font-bold">{{ 'widget.include-upcoming-shifts' | translate }}</div>
            <ui-toggle color="primary"
                       [(checked)]="data.includeFutureShifts">
            </ui-toggle>
        </div>
        <hr class="border">
        <mat-radio-group aria-labelledby="
        radio-group-label"
                         class="radio-group"
                         color="primary"
                         [(ngModel)]="data.includeRange">

            <mat-radio-button class="radio-button w-100"
                              *ngFor="let range of includeRanges"
                              [value]="range">
                {{ range | translate }}
            </mat-radio-button>
        </mat-radio-group>
    </div>
    <div mat-dialog-actions>
        <ui-button-standard class="mx-auto"
                            (click)="close()">
            OK
        </ui-button-standard>
    </div>
</div>