import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as moment from 'moment';
import { isWebview } from '../../utilities/BrowserUtils';

@Component({
    selector: 'ui-month-picker',
    templateUrl: './month-picker.component.html',
    styleUrls: ['./month-picker.component.scss'],
})
export class MonthPickerComponent implements OnInit {

    @Input() public date: Date;
    @Output() public dateChange: EventEmitter<Date>;
    public isWebview: boolean;
    public months: Date[];

    constructor() {
        this.dateChange = new EventEmitter();
    }

    public async ngOnInit(): Promise<void> {
        this.isWebview = isWebview();
        if (this.isWebview) this.months = this.getMonths();
    }

    public dateSelected(date: Date): void {
        this.date = new Date(date);
        this.dateChange.emit(new Date(date));
    }

    private getMonths(): Date[] {
        const startYear = 2017;
        const endYear = 2030;
        const getYearMonths = (year: number) => {
            const january = moment(`${ year }-01-01`);
            const yearMonths: moment.Moment[] = [];
            for (let month = 0; month < 12; month++) {
                yearMonths.push(january.clone().add(month, 'months'));
            }
            return yearMonths;
        };

        const months: Date[] = [];
        for (let year = startYear; year <= endYear; year++) {
            months.push(...getYearMonths(year).map((month: moment.Moment) => month.toDate()));
        }
        return months;
    }
}
