import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Package } from '../../../../../../../core/model/Freemium/Package';
import { CompanySetting, CompanySettingsService } from '../../../../../../../core/services';
import { CalendarSyncService } from '../../../../../../../core/services/calendar-sync.service';

@Component({
    selector: 'app-schedule-settings',
    templateUrl: './schedule-settings.component.html',
    styleUrls: ['./schedule-settings.component.scss'],
})
export class ScheduleSettingsComponent implements OnInit {

    public shiftTradeEnabled$: Observable<boolean>;
    public autoShiftTradeEnabled$: Observable<boolean>;
    public autoApproveAvailableShiftEnabled$: Observable<Date | null>;
    public manualPunchclockEnabled$: Observable<boolean>;
    public packages: typeof Package = Package;

    constructor(
        private companySettingsService: CompanySettingsService,
        private calSyncService: CalendarSyncService,
    ) { }

    public ngOnInit(): void {
        this.autoShiftTradeEnabled$ =
            this.companySettingsService.loadSetting(CompanySetting.AUTO_SHIFT_TRADE);
        this.shiftTradeEnabled$ =
            this.companySettingsService.loadSetting(CompanySetting.SHIFT_TRADE);
        this.autoApproveAvailableShiftEnabled$ =
            this.companySettingsService.loadSetting(CompanySetting.AUTO_APPROVE_AVAILABLE_SHIFT_ENABLED_ON);
        this.manualPunchclockEnabled$ = this.companySettingsService.loadSetting(CompanySetting.MANUAL_PUNCHCLOCK);
    }

    /**
     * Opens logged in user's calendar sync link
     */
    public openCalendarSync(): void {
        this.calSyncService.openCalendarSync();
    }

    public shiftTradeChanged(enabled: boolean): void {
        this.companySettingsService.saveSetting(CompanySetting.SHIFT_TRADE, enabled);
    }

    public autoShiftTradeChanged(enabled: boolean): void {
        this.companySettingsService.saveSetting(CompanySetting.AUTO_SHIFT_TRADE, enabled);
    }

    public autoApproveAvailableShiftChanged(enabled: boolean): void {
        this.companySettingsService.saveSetting(CompanySetting.AUTO_APPROVE_AVAILABLE_SHIFT_ENABLED_ON, enabled ? new Date() : null);
    }

    public manualPunchclockEnabledChanged(enabled: boolean): void {
        this.companySettingsService.saveSetting(CompanySetting.MANUAL_PUNCHCLOCK, enabled);
    }
}
