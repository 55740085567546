import { Inject, Injectable } from '@angular/core';
import { CollectionConfig, FireAuthService, getCustomClaims } from 'akita-ng-fire';
import firebase from 'firebase/app';
import { Subscription } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { AuthQuery } from './auth.query';
import { AuthState, AuthStore } from './auth.store';
type User = firebase.User;

@Injectable({ providedIn: 'root' })
@CollectionConfig({ path: 'akitaUsers' })
export class AkitaAuthService extends FireAuthService<AuthState> {
    public authSyncSub: Subscription;

    constructor(
        @Inject(AuthStore) store: AuthStore,
        @Inject(AuthQuery) query: AuthQuery,
    ) {
        super(store);
        // We save our session locally, so we can pick it up whenever we re-open the site.
        this.auth.setPersistence('local');
        // We show in the store that we are loading
        store.setLoading(true);
        // Start syncing the authstate, so we update the store with the user data, including custom claims
        this.authSyncSub = this.sync().subscribe();
        // Show that we have stopped loading whenever we have the updated authState, with a logged in user.
        // With this, we can wait for the authstate to be done loading, before we attempt to load other things, which require a user session
        query.select().pipe(filter(state => !!state.uid && !!state.roles?.companyID), take(1))
            .subscribe(() => store.setLoading(false));
    }

    public selectRoles(user: User): Promise<AuthState['roles']> {
        // We override the selectRoles method to load the admin state, companyID and email
        //  from the auth user's custom claims into the store in the authstate.
        return getCustomClaims(user, ['admin', 'companyID', 'email']);
    }

    // This is triggered on login, when the user has not logged in before.
    // The object returned defines that we want to save in the akitaUsers document, associated with the profile.
    protected createProfile(): {} {
        return {};
    }

}
