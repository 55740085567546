<div class="d-flex flex-row">
    <ui-button-icon (click)="onDateChangePressed(-1)"
                    disableRipple="true"
                    class="previous-button">
        <i class="fal fa-angle-left"></i>
    </ui-button-icon>
    <ui-button-standard (click)="onCurrentPressed()"
                        disableRipple="true"
                        class="current-button">{{'shared.today' | translate | capitalize}}</ui-button-standard>
    <ui-button-icon (click)="onDateChangePressed(1)"
                    disableRipple="true"
                    class="next-button">
        <i class="fal fa-angle-right"></i>
    </ui-button-icon>
</div>