import { Component, Input } from '@angular/core';
import { ButtonDirective } from '../button/button.directive';

@Component({
    selector: 'ui-button-icon-raised',
    templateUrl: './button-icon-raised.component.html',
    styleUrls: ['./button-icon-raised.component.scss'],
})

export class ButtonIconRaisedComponent extends ButtonDirective {
    @Input() public round: boolean;
}
