import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export enum Range {
    TOTAL = 'widget.total',
    THIS_WEEK = 'widget.this-week',
    THIS_MONTH = 'widget.this-month',
}

@Component({
    selector: 'app-stats-dialog',
    templateUrl: './stats-dialog.component.html',
    styleUrls: ['./stats-dialog.component.scss'],
})

export class StatsDialogComponent {

    public includeRanges: Range[];

    constructor(
        public dialogRef: MatDialogRef<StatsDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: { includeFutureShifts: boolean, includeRange: Range },
    ) {
        this.includeRanges = [Range.TOTAL, Range.THIS_WEEK, Range.THIS_MONTH];
    }

    /**
     * The includeFutureShifts was changed by the toggle
     * @param change The value changed to
     */
    public includeFutureShiftChange(change: boolean): void {
        this.data.includeFutureShifts = change;
    }

    /**
     * Closed the dialog with data as result
     */
    public close(): void {
        this.dialogRef.close(this.data);
    }
}
