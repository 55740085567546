
export interface CustomError {
    errorCode: CustomErrorCode;
    message: string;
}

export enum CustomErrorCode {
    InvalidRequest = 'InvalidRequest',
    UnableToCreateCompany = 'UnableToCreateCompany',
    UnableToCreateAuthUser = 'UnableToCreateAuthUser',
    UnableToSetCustomClaims = 'UnableToSetCustomClaims',
    UnableToCreateEmployee = 'UnableToCreateEmployee',
    UnableToCreateDepartment = 'UnableToCreateDepartment',
    NoCompanyAccountFound = 'NoCompanyAccountFound',
    AlreadyExported = 'AlreadyExported',
    DuplicatedEmployeeNumbersAtDanloen = 'DuplicatedEmployeeNumbersAtDanloen',
    DuplicatedEmployeeNumbersAtRelion = 'DuplicatedEmployeeNumbersAtRelion',
    Unknown = 'Unknown',
}

/**
 * Check if a given object is a CustomError
 * @param object The object to check
 */
export function isCustomError(object: unknown): object is CustomError {
    const possibleError: CustomError = object as CustomError;
    return !!possibleError && possibleError.errorCode != null && possibleError.message != null;
}
