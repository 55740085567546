<div class="d-flex flex-row justify-content-between">
    <div class="seller d-flex flex-row align-items-center">
        <img [src]="shiftForSale.seller?.imageURL || '/assets/images/no-employee.svg'">
        <div class="text-container">
            <ng-container *ngIf="shiftForSale.seller">
                <div class="name dot-dot-dot">
                    {{ shiftForSale.seller?.firstname }}
                    {{ shiftForSale.seller?.lastname }}
                </div>
                <div class="subtext">{{ 'employee.free-shifts.is-selling-his-shift' | translate }}</div>
            </ng-container>
            <ng-container *ngIf="!shiftForSale.seller">
                <div class="name dot-dot-dot">
                    {{ 'admin.calendar.free-shift' | translate }}
                </div>
            </ng-container>
        </div>
    </div>
    <div *appIfPro
         class="potential-gain-container d-flex flex-row align-items-center">
        <div class="potential-gain">
            + {{ 'freemium.dkk' | translate: { amount: potentialGain | number: '1.0-0' } }}
        </div>
        <i class="far fa-coins"></i>
    </div>
</div>
<ui-button-action *ngIf="!hasMyBid"
                  (click)="bidOnShift()"
                  class="buy"
                  color="success"
                  tall
                  wide>{{ 'employee.free-shifts.bid-on-shift' | translate }}
</ui-button-action>
<ui-button-standard *ngIf="hasMyBid"
                    (click)="cancelBidOnShift()"
                    color="warn"
                    class="buy"
                    tall
                    wide>{{ 'employee.free-shifts.remove-bid-on-shift' | translate }}
</ui-button-standard>