<div class="full-shift-card">
    <app-salary-swipe-label *ngIf="!!shift.salarySwipe"
                            [shift]="shift"
                            [dayShiftCard]="true">
    </app-salary-swipe-label>
    <div class="date">
        <ng-container *ngIf="!(shiftIsLive$ | async)">
            <div class="weekday">{{ shift.start | date: 'EEEE' }}</div>
            <div class="day-of-month">{{ shift.start | date: 'd' }}</div>
            <div class="month">{{ shift.start | date: 'MMMM' }}</div>
        </ng-container>
        <ng-container *ngIf="shiftIsLive$ | async">
            <i [class.should-be-punched]="shiftShouldBePunchedIn$ | async"
               class="far fa-stopwatch punch-clock"></i>
        </ng-container>
    </div>
    <div class="divider"></div>
    <div class="main-content">
        <div class="role">
            <div class="dot"
                 [ngClass]="shift.role.color"></div>
            <div class="dot-dot-dot"> {{ shift.role.name }}</div>
            <i *ngIf="shift.comment || (day$ | async)?.note"
               class="far fa-comment comment-icon"></i>
        </div>
        <div class="time-and-department dot-dot-dot">
            {{ shift.start | date: 'H:mm' }}
            -
            {{ shift.end | date: 'H:mm' | convertMidnight }}
            ·
            {{ shift.role.department.name }}
        </div>
    </div>
    <div class="logo-container">
        <i *ngIf="(shiftForSale$ | async) as shiftForSale"
           [class.hasBids]="shiftForSale!.bids.length > 0"
           class="far fa-sync-alt for-sale"></i>
        <i *ngIf="shouldBeApproved$ | async"
           [class.approved]="shift.approval || shift.approved"
           class="fas fa-check-circle checked-out"></i>
    </div>
</div>