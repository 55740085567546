import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, Input, OnInit, Optional } from '@angular/core';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { Router } from '@angular/router';
import { combineLatest, Observable, of } from 'rxjs';
import { catchError, map, shareReplay, startWith, switchMap, take } from 'rxjs/operators';
import { routes } from '../../../app-settings';
import { Day, isShift, NewShift, PunchSource, Shift, sortShiftsByCheckin } from '../../../core/model';
import { AuthService, AuthUser, CompanySettingsService, DateService, ShiftService } from '../../../core/services';
import { DayService } from '../../../core/services/day.service';
import { ModalService } from '../../../core/services/ModalService.model';
import { getPunchClockSettings, PunchclockConfig } from '../../utilities/PunchclockUtils';
import { ShiftStatus } from './shift-status.pipe';

enum State {
    VIEW = 1,
    EDIT = 2,
}

interface ShiftDetailData {
    shift: Shift | NewShift;
    openInEditMode?: boolean;
}

@Component({
    templateUrl: './shift-detail-modal.component.html',
    styleUrls: ['./shift-detail-modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShiftDetailModalComponent implements OnInit {
    public state: State;
    public State: typeof State = State;
    public ShiftStatus: typeof ShiftStatus = ShiftStatus;
    public PunchSource: typeof PunchSource = PunchSource;
    public shift$: Observable<Shift | NewShift>;
    public day$: Observable<Day>;
    public isMyShift$: Observable<boolean>;
    public isShiftAvailable$: Observable<boolean>;
    public isAdmin$: Observable<boolean>;
    public otherShiftsOnDay$: Observable<Shift[]>;
    public user$: Observable<AuthUser | null>;
    public punchclockEnabled$: Observable<boolean>;
    public dismissFunction: () => void;
    @Input() private shiftDetailData?: ShiftDetailData;
    @Input() private back?: () => void;

    constructor(
        @Optional() @Inject(MAT_BOTTOM_SHEET_DATA) shiftDetailData: ShiftDetailData | null,
        @Optional() public dialogRef: MatBottomSheetRef | null,
        private authService: AuthService,
        private dateService: DateService,
        private dayService: DayService,
        private modalService: ModalService,
        private router: Router,
        private shiftService: ShiftService,
        private companySettingsService: CompanySettingsService,
        private cdr: ChangeDetectorRef,
    ) {
        if (!shiftDetailData) return;
        this.setupShift(shiftDetailData);
    }

    public ngOnInit(): void {
        if (this.shiftDetailData) this.setupShift(this.shiftDetailData);

        if (!this.shift$) throw Error('No shiftDetailData provided');

        this.dismissFunction = this.dismiss.bind(this);

        this.day$ = this.shift$
            .pipe(switchMap((shift: Shift) => this.dayService.getDay(shift.start, shift.role.department)));

        this.user$ = this.authService.getUser();
        this.isAdmin$ = this.user$.pipe(map((user: AuthUser) => !!user?.admin));
        this.isMyShift$ = combineLatest([this.user$, this.shift$])
            .pipe(map(([user, shift]: [AuthUser | null, Shift]) => !!user && user.user_id === shift.employee?.id));
        this.isShiftAvailable$ = this.shift$.pipe(map((shift: Shift) => !shift.employee));

        this.otherShiftsOnDay$ = this.shift$.pipe(
            switchMap((primaryShift: Shift) =>
                this.shiftService.getShiftsByDate(primaryShift.start, true).pipe(
                    map((shifts: Shift[]) => shifts
                        .filter((shift: Shift) => primaryShift.id !== shift.id && !!shift.employee)
                        .sort(sortShiftsByCheckin),
                    ),
                ),
            ),
        );

        this.punchclockEnabled$ =
            getPunchClockSettings(this.companySettingsService).pipe(map((pcc: PunchclockConfig) => pcc.autoPunchClockEnabled));
    }

    public openShiftDetail(shift: Shift): void {
        this.modalService.openShiftDetailModal(shift);
    }

    public async goToDaySchedule(shift: Shift): Promise<void> {
        this.dateService.changeDate(shift.start);
        this.modalService.closeAll();
        const scheduleRoute = (await this.isAdmin$.pipe(take(1)).toPromise())
            ? routes.admin.schedule
            : routes.employee.calendar;
        this.router.navigate([scheduleRoute]);
    }

    /**
     * Resets the shift observable. Needed when creating a new shift that initally has no id.
     */
    public shiftEdited(shift: Shift): void {
        this.state = State.VIEW;
        this.shift$ = this.setupShiftObservable(shift);
    }

    public dismiss(): void {
        // Dismiss the shift detail modal via the back function or the bottom sheet dialog ref
        this.dialogRef?.dismiss();
        if (this.back) this.back();
    }

    private setupShift(shiftDetailData: ShiftDetailData): void {
        const { shift, openInEditMode }: ShiftDetailData = shiftDetailData;
        this.shift$ = isShift(shift) ? this.setupShiftObservable(shift) : of(shift);
        this.setState(!!openInEditMode, shift).then(() => this.cdr.markForCheck());
    }

    private async setState(openInEditMode: boolean, shift: Shift | NewShift): Promise<State> {
        // If told to open in edit state, do it
        if (!!openInEditMode) return this.state = State.EDIT;
        // If shift is released, open in view state
        if (shift.released) return this.state = State.VIEW;

        // If shift is unreleased, open in edit state if admin and view state if employee
        const admin = await this.authService.userIsAdmin().pipe(take(1)).toPromise();
        return this.state = admin ? State.EDIT : State.VIEW;
    }

    /**
     * Setup the shift observable and ignore deletion error
     */
    private setupShiftObservable(shift: Shift): Observable<Shift> {
        return this.shiftService.getShift(shift.id).pipe(
            startWith(shift),
            shareReplay(1),
            catchError((err: string) => {
                // Handle the error thrown if error happened because of shift deletion
                if (err.includes('No shift with id')) return of(shift);
                throw err;
            }),
        );
    }
}
