import { Component, Input } from '@angular/core';
import { LazyLoadImageProviderFactory } from '../../factories/LazyLoadImageHooks';

export enum StatusType {
    SUCCESS,
    WARN,
}
@Component({
    selector: 'ui-profile-card',
    templateUrl: './profile-card.component.html',
    styleUrls: ['./profile-card.component.scss'],
    providers: [LazyLoadImageProviderFactory(1)],
})
export class ProfileCardComponent {
    @Input() public status: StatusType;
    public statusTypes: typeof StatusType = StatusType;
    @Input() public imageURL: string;
    @Input() public title: string;
    @Input() public subtitle: string;
}
