import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    templateUrl: './dataloen-integration-help-modal.component.html',
    styleUrls: ['./dataloen-integration-help-modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DataloenIntegrationHelpModalComponent {

    constructor(public modalRef: MatDialogRef<DataloenIntegrationHelpModalComponent>) { }

}
