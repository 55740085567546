import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { first, map, take } from 'rxjs/operators';
import { routes } from '../../../../../app-settings';
import { DEFAULT_LANGUAGE, Language } from '../../../../../core/model/UserSettings';
import { AuthService, AuthUser } from '../../../../../core/services';
import { CloudFunctionsService } from '../../../../../core/services/cloud-functions.service';
import { SnackbarColor, SnackbarService } from '../../../../../core/services/snackbar/snackbar.service';
import { CreateCompanyRequestBody } from '../../../../../shared/utilities/CloudFunctionUtils';
import { CustomValidators, splitName } from '../../../../../shared/utilities/FormUtils';

@Component({
    selector: 'app-company-signup',
    templateUrl: './company-signup.component.html',
    styleUrls: ['./company-signup.component.scss'],
})
export class CompanySignupComponent implements OnInit {

    public formGroup: FormGroup;
    public loading: boolean;

    constructor(
        private snackbarService: SnackbarService,
        private authService: AuthService,
        private gtmService: GoogleTagManagerService,
        private router: Router,
        private cloudFunctionsService: CloudFunctionsService,
        private activatedRoute: ActivatedRoute,
        private translateService: TranslateService,
    ) { }

    public ngOnInit(): void {
        // If user is logged in, log out and reload
        this.authService.getUser().pipe(take(1)).toPromise().then((user: AuthUser | null) => {
            if (user) this.authService.logout().then(() => window.location.reload(true));
        });

        this.formGroup = this.buildFormGroup();
    }

    /**
     * Builds and returns a form group, including form controls with validators.
     */
    public buildFormGroup(): FormGroup {
        const COMPANY_NAME: FormControl = new FormControl('', Validators.required);
        const NAME: FormControl = new FormControl('', [Validators.required, CustomValidators.FullNameValidator]);
        const PHONE: FormControl = new FormControl('', [Validators.required, CustomValidators.PhoneValidator]);
        const EMAIL: FormControl = new FormControl('', [Validators.required, Validators.email]);
        const AGREE_TO_TERMS: FormControl = new FormControl(false, Validators.requiredTrue);
        const PASSWORD: FormControl = new FormControl('', [Validators.required, CustomValidators.PasswordValidator]);

        return new FormGroup({
            COMPANY_NAME, NAME, PHONE, EMAIL, AGREE_TO_TERMS, PASSWORD,
        });
    }

    /**
     * Attempts to send a request to the server, to create a new company with user input in the form
     */
    public async onSubmit(): Promise<void> {
        try {
            this.setLoadingState(true);
            const body: CreateCompanyRequestBody = await this.prepareRequestBody(this.formGroup);
            await this.cloudFunctionsService.signupCompanyWithAdmin(body);
            await this.authService.loginWithEmailPassword(body.email, body.password);
            await this.router.navigateByUrl(routes.admin.schedule);
            this.registerCreatedAccountEventInGTM();
        } catch (error) {
            if (error === 'UnableToCreateAuthUser') {
                this.snackbarService.displaySnack('Denne email er allerede i brug', SnackbarColor.warn);
            } else {
                this.snackbarService.displaySnack('Der skete en fejl under oprettelsen', SnackbarColor.warn);
            }
        }
        this.setLoadingState(false);
    }

    /**
     * Sets the loading state of the component to the given state
     * @param newState the new loading state
     */
    private setLoadingState(newState: boolean): void {
        this.loading = newState;
    }

    /**
     * Extracts and prepares user input from the given formgroup, and returns a request body.
     * @param formGroup The formgroup containing controls with user input
     */
    private async prepareRequestBody(formGroup: FormGroup): Promise<CreateCompanyRequestBody> {
        const referrer = (await this.activatedRoute.queryParams.pipe(take(1)).toPromise()).referrer;

        const { firstname, lastname }: { firstname: string; lastname: string; } = splitName(formGroup.controls.NAME!.value);
        const body: CreateCompanyRequestBody = {
            name: formGroup.controls.COMPANY_NAME!.value.trim(),
            country: 'unknown',
            phone: formGroup.controls.PHONE!.value,
            email: formGroup.controls.EMAIL!.value,
            password: formGroup.controls.PASSWORD!.value,
            firstname,
            lastname,
            language: this.translateService.currentLang as Language || DEFAULT_LANGUAGE,
        };
        if (referrer) body.referrer = referrer;
        return body;
    }

    private async registerCreatedAccountEventInGTM(): Promise<void> {
        const companyID: string = await this.authService.getUser().pipe(
            first((user: AuthUser | null) => !!user),
            map((user: AuthUser) => user.companyID),
        ).toPromise();
        return this.gtmService.pushTag({ event: 'accountCreated', companyID });
    }
}
