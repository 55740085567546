import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'nameShorten',
})
export class NameShortenPipe implements PipeTransform {
    /**
     * Transforms a name into a shorter version depending on the limit.
     * @param value The name.
     * @param limit The length limit.
     */
    public transform(value: string, limit: number): string {
        if (value.length <= limit) return value;

        let words: string[] = value.split(' ');

        const firstname: string = words[0] || '';
        let lastname: string = words[words.length - 1] || '';

        // Set space between last middle name and last name
        let space_last: string = ' ';

        // Abbreviate all middle names.
        words = words.slice(1, words.length - 1).map((middlename: string) => middlename.substr(0, 1) + '.');

        // Check first name
        if (firstname.length > limit) return firstname.substr(0, limit);

        // Try to add last name or an abbreviation.
        if ((firstname + ' ' + lastname).length > limit) {
            const first_ab_last: string = firstname + ' ' + lastname.substr(0, 1) + '.';
            if (first_ab_last.length <= limit) {
                lastname = lastname.substr(0, 1) + '.';
                space_last = '';
            } else {
                return firstname;
            }
        }

        // Try to add one middle name
        let buffer: string;
        if (words[words.length - 1] !== undefined &&
            (firstname + ' ' + words[words.length - 1] + space_last + lastname).length <= limit) {
            buffer = words[words.length - 1]!;
        } else {
            return firstname + ' ' + lastname;
        }

        // Try to add more middle names
        for (let i: number = words.length - 2; i >= 0; i--) {
            // Try to add a middle name to the buffer
            if ((firstname + ' ' + buffer + words[i] + space_last + lastname).length <= limit) {
                buffer = words[i] + buffer;
            } else {
                break;
            }
        }
        return firstname + ' ' + buffer + space_last + lastname;
    }
}
