import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import * as moment from 'moment';
import { combineLatest, Observable, of } from 'rxjs';
import { map, switchMap, take, tap } from 'rxjs/operators';
import { CustomErrorCode } from '../../../../../../../functions/src/errorHandling/models';
import { Department, Employee, Shift, ShiftBreak } from '../../../../../core/model';
import { Package } from '../../../../../core/model/Freemium/Package';
import { getIntegrationWebsite, IntegrationStatus, SalaryIntegration, SalaryIntegrationSettings } from '../../../../../core/model/Integrations/Shared';
import { SalarySupplement } from '../../../../../core/model/SalarySupplement.model';
import { SalaryRange } from '../../../../../core/model/UserSettings';
import { CompanySetting, CompanySettingsService } from '../../../../../core/services';
import { CloudFunctionsService } from '../../../../../core/services/cloud-functions.service';
import { ConfigurationService } from '../../../../../core/services/Configuration/configuration-service.model';
import { DepartmentService } from '../../../../../core/services/Department/DepartmentService.model';
import { ModalService } from '../../../../../core/services/ModalService.model';
import { SalarySupplementService } from '../../../../../core/services/salary-supplement.service';
import { EmployeeWithSalary, SalaryService } from '../../../../../core/services/salary.service';
import { IntegrationSettingsService } from '../../../../../core/services/Settings/IntegrationSettings/Firestore/integration-settings.service';
import { ConfirmDialogData } from '../../../../../shared/ui/confirm-dialog/confirm-dialog.component';
import { ConfirmDialogType } from '../../../../../shared/ui/confirm-dialog/ConfirmDialogType';
import { generateCSVExport } from '../../../../../shared/utilities/CSVUtils';
import { generateDanloenPayParts } from '../../../../../shared/utilities/DanloenUtility';
import { generateDataloenExportData } from '../../../../../shared/utilities/DataloenUtility';
import { getTimeRegistrations } from '../../../../../shared/utilities/SalaryDkUtility';
import { mapEmployeesToIntegrationMapping } from '../../../../../shared/utilities/SalaryUtility';
import { DanloenIntegrationMatchingModalComponent } from '../settings-modal/components/salary/integrations/danloen/danloen-integration-matching-modal/danloen-integration-matching-modal.component';
import { NavbarSettings } from '../settings-modal/settings-modal.component';

export interface SalaryExportData {
    range: SalaryRange;
    selectedDepartment?: Department;
    shifts: Shift[];
}

enum Export {
    IN_PROGRESS = 1,
    DONE = 2,
}

@Component({
    templateUrl: './salary-export-modal.component.html',
    styleUrls: ['./salary-export-modal.component.scss'],
})
export class SalaryExportModalComponent implements OnInit {
    public multipleDepartments$: Observable<boolean>;
    public rangeIsInPast: boolean;
    public integrationStatus?: IntegrationStatus;
    public employeesWithoutSalary: number;
    public unapprovedShifts: number;
    public totalSalary: number;
    public totalHours: number;
    public highlightExportBtn: boolean;
    public export?: Export;
    public Export: typeof Export = Export;
    public Package: typeof Package = Package;
    public isPro$: Observable<boolean>;
    public get shiftsNonExported(): Shift[] {
        return this.modalData.shifts.filter((shift: Shift) => shift.exportLogs.length === 0);
    }
    public get noShiftsExported(): number {
        return this.modalData.shifts.length - this.shiftsNonExported.length;
    }
    public get noShiftsNonExported(): string {
        return this.shiftsNonExported.length > 0
            ? '(' + this.shiftsNonExported.length + ')'
            : '';
    }
    private shiftBreak: ShiftBreak | null;

    constructor(
        @Inject(MAT_DIALOG_DATA) public modalData: SalaryExportData,
        private cloudFunctionsService: CloudFunctionsService,
        private configurationService: ConfigurationService,
        private companySettingsService: CompanySettingsService,
        private departmentService: DepartmentService,
        private integrationSettingsService: IntegrationSettingsService,
        private modalRef: MatDialogRef<SalaryExportModalComponent>,
        private modalService: ModalService,
        private salaryService: SalaryService,
        private translateService: TranslateService,
        private supplementService: SalarySupplementService,
        private modal: MatDialog,
    ) { }

    public async ngOnInit(): Promise<void> {
        this.multipleDepartments$ = this.departmentService.getDepartments().pipe(
            map((departments: Department[]) => departments.length > 1),
        );

        this.isPro$ = this.configurationService.isPro(Package.PRO);

        this.rangeIsInPast = this.modalData.range.end < new Date();

        const integration: SalaryIntegration | undefined =
            await this.integrationSettingsService.getActiveSalaryIntegration().pipe(take(1)).toPromise();

        this.integrationStatus =
            await this.integrationSettingsService.getIntegrationStatus(integration).pipe(take(1)).toPromise();

        const employeesToExport: Employee[] = [];
        this.modalData.shifts.forEach((shift: Shift) => {
            const emp: Employee | null = shift.employee;
            if (emp && !employeesToExport.find((empl: Employee) => empl.id === emp.id)) employeesToExport.push(emp);
        });

        this.employeesWithoutSalary = SalaryService.employeesWithoutSalary(employeesToExport);

        this.unapprovedShifts = this.modalData.shifts
            .filter((shift: Shift) => shift.checkin && !shift.approved).length;

        this.shiftBreak = await this.companySettingsService.loadSetting(CompanySetting.SHIFT_BREAK).pipe(take(1)).toPromise();

        [this.totalSalary, this.totalHours] = await this.salaryService.addSalaryAndHoursToEmployees(
            [employeesToExport, this.modalData.shifts]).pipe(take(1), map((employeesWithSalary: EmployeeWithSalary[]) =>
                employeesWithSalary.reduce<[number, number]>(
                    (acc: [number, number], empWithSalary: EmployeeWithSalary) => [
                        acc[0] + (empWithSalary.salaryInPeriod || 0),
                        acc[1] + (empWithSalary.hours - empWithSalary.breaks),
                    ], [0, 0]))).toPromise();

        this.highlightExportBtn =
            this.unapprovedShifts === 0 &&
            this.employeesWithoutSalary === 0 &&
            this.rangeIsInPast &&
            !this.integrationStatus.error;
    }

    public openIntegrationSettings(): void {
        this.modalRef.close();
        this.modalService.openSettingsModal(NavbarSettings.SALARY);
    }

    public openApproveShiftsModal(): void {
        this.modalRef.close();
        this.modalService.openApproveShiftsModal(
            this.modalData.range.start,
            moment(this.modalData.range.end).add(1, 'day').toDate(),
            this.modalData.selectedDepartment,
        );
    }

    public openEmployeeHourlyWageModal(): void {
        this.modalRef.close();
        this.modalService.openEmployeeHourlyWageModal();
    }

    /**
     * Exports the salary from the currently selected range, and outputs it in csv format.
     */
    public async exportToCSV(): Promise<void> {
        const isPro = await this.isPro$.pipe(take(1)).toPromise();
        if (!isPro) return this.openSalaryProShopModal();
        generateCSVExport(
            this.modalData.shifts, this.translateService, this.modalData.range, this.shiftBreak, this.salaryService, this.supplementService,
        );
    }

    public async exportOrIntegrate(): Promise<void> {
        const isPro = await this.isPro$.pipe(take(1)).toPromise();
        if (!isPro) {
            return this.openSalaryProShopModal();
        }

        if (!this.integrationStatus?.integration) return this.openIntegrationSettings();

        if (!this.highlightExportBtn) {
            const confirmed = await this.modalService.openConfirmDialog({
                confirmDialogType: ConfirmDialogType.WARNING,
                titleTranslationKey: 'modal.confirm-send-despite-error.title',
                titleTranslationParams: {
                    integration:
                        await this.translateService
                            .get('integrations.' + this.integrationStatus.integration.toString() + '.name').toPromise(),
                },
                subtitleTranslationKey: 'modal.confirm-send-despite-error.subtitle',
                acceptButtonTranslationKey: 'modal.confirm-send-despite-error.confirm',
                closeButtonTranslationKey: 'shared.cancel',
            }, true).toPromise();
            if (!confirmed) return;
        }

        if (this.shiftsNonExported.length > 0) {
            this.modalData.shifts = this.shiftsNonExported;
        } else {
            const confirmed = await this.modalService.openConfirmDialog({
                confirmDialogType: ConfirmDialogType.INFO,
                titleTranslationKey: 'modal.export-all-shifts.title',
                subtitleTranslationKey: 'modal.export-all-shifts.subtitle',
                acceptButtonTranslationKey: 'modal.export-all-shifts.acceptButton',
                closeButtonTranslationKey: 'modal.export-all-shifts.closeButton',
            }, true).toPromise();
            if (!confirmed) return;
        }

        switch (this.integrationStatus.integration) {
            case SalaryIntegration.DANLOEN: return this.exportToDanloen();
            case SalaryIntegration.DATALOEN: return this.exportToDataloen();
            case SalaryIntegration.SALARYDK: return this.exportToSalaryDK();

            default: throw Error('Export to ' + this.integrationStatus.integration + ' not yet implemented');
        }
    }

    public openSalaryProShopModal(): void {
        this.modalService.openProShopOverlay();
    }

    public openSalarySystemWebsite(): void {
        this.modalRef.close();
        if (!this.integrationStatus?.integration) throw Error('No integration is loaded');
        const url: string = getIntegrationWebsite(this.integrationStatus.integration);
        window.open(url, '_blank');
    }

    private exportToDataloen(): void {
        this.export = Export.IN_PROGRESS;
        combineLatest([
            this.integrationSettingsService.loadSetting(SalaryIntegration.DATALOEN),
            this.supplementService.getSalarySupplements(),
        ]).pipe(
            take(1),
            switchMap(([integrationSettings, supplements]: [SalaryIntegrationSettings, SalarySupplement[]]) => {
                if (!integrationSettings) throw Error('Integration settings not found');
                if (!integrationSettings.employeeMap) throw Error('Employee map not found');

                // Forward the employees mapped to DataLøn ID's and the supplements
                return combineLatest([
                    of(mapEmployeesToIntegrationMapping(
                        this.getEmployeesWithShifts(this.modalData.shifts),
                        integrationSettings.employeeMap)),
                    of(supplements),
                    this.multipleDepartments$.pipe(take(1)),
                ]);
            }),
            switchMap(([mappedEmployees, supplements, multipleDepartments]: [[Employee, string][], SalarySupplement[], boolean]) => {
                const dataloenExportData = generateDataloenExportData(
                    mappedEmployees,
                    this.modalData.shifts,
                    this.shiftBreak,
                    supplements,
                    multipleDepartments);
                return this.cloudFunctionsService.exportToDataloen(dataloenExportData);
            }),
        ).subscribe({
            next: () => this.export = Export.DONE,
            error: () => this.showErrorDialog(),
        });
    }

    private exportToDanloen(): void {
        combineLatest([
            this.integrationSettingsService.loadSetting(SalaryIntegration.DANLOEN),
            this.supplementService.getSalarySupplements(),
        ]).pipe(
            take(1),
            map(([integrationSettings, supplements]: [SalaryIntegrationSettings, SalarySupplement[]]) => {
                if (!integrationSettings) throw Error('Integration settings not found');
                if (!integrationSettings.employeeMap) throw Error('Employee map not found');

                // Forward the employees mapped to Danløn ID's and the supplements
                return [
                    mapEmployeesToIntegrationMapping(this.getEmployeesWithShifts(this.modalData.shifts), integrationSettings.employeeMap),
                    supplements,
                ];
            }),
            switchMap(([mappedEmployees, supplements]: [[Employee, string][], SalarySupplement[]]) => {
                // Update UI, generate the Danløn payparts and call the cloud function exporting to Danløn
                this.export = Export.IN_PROGRESS;
                const danloenPayParts = generateDanloenPayParts(mappedEmployees, this.modalData.shifts, this.shiftBreak, supplements);
                return this.cloudFunctionsService.exportToDanloen(danloenPayParts);
            }),
        ).subscribe({
            next: () => this.export = Export.DONE,
            error: (errorCode: CustomErrorCode) => {
                if (errorCode === CustomErrorCode.Unknown) return this.showErrorDialog();
                return this.showCustomErrorDialog(errorCode);
            },
        });
    }

    private exportToSalaryDK(): void {
        this.integrationSettingsService.loadSetting(SalaryIntegration.SALARYDK).pipe(
            take(1),
            map((integrationSettings: SalaryIntegrationSettings) => {
                if (!integrationSettings) throw Error('Integration settings not found');
                if (!integrationSettings.employeeMap) throw Error('Employee map not found');

                const employeesToExport: Employee[] = this.getEmployeesWithShifts(this.modalData.shifts);
                return mapEmployeesToIntegrationMapping(employeesToExport, integrationSettings.employeeMap);
            }),
            switchMap((mappedEmployees: [Employee, string][]) => combineLatest([
                of(mappedEmployees),
                this.multipleDepartments$,
                this.supplementService.getSalarySupplements(),
            ])),
            take(1),
            tap(() => this.export = Export.IN_PROGRESS),
            switchMap(([mappedEmployees, multipleDepartments, supplements]: [[Employee, string][], boolean, SalarySupplement[]]) =>
                this.cloudFunctionsService.exportToSalary({
                    mappedEmployees: mappedEmployees.filter(([employee, _salaryId]: [Employee, string]) => !!employee.hourlyWage),
                    ...getTimeRegistrations(this.modalData.shifts, mappedEmployees, multipleDepartments, this.shiftBreak),
                    supplements,
                })),
        ).subscribe({
            next: () => this.export = Export.DONE,
            error: () => this.showErrorDialog(),
        });
    }

    private async showErrorDialog(): Promise<Observable<boolean>> {
        this.modalRef.close();
        const integrationTranslateKey = 'integrations.' + this.integrationStatus!.integration!.toString();
        const integration: string = await this.translateService.get(integrationTranslateKey + '.name').toPromise();
        const integrationWebsite: string = await this.translateService.get(integrationTranslateKey + '.website').toPromise();
        return this.modalService.openConfirmDialog({
            confirmDialogType: ConfirmDialogType.WARNING,
            titleTranslationKey: 'integrations.error-exporting-to',
            titleTranslationParams: { integration },
            subtitleTranslationKey: 'integrations.error-exporting-to-elaborate',
            subtitleTranslationParams: { integration, integrationWebsite },
            closeButtonTranslationKey: 'integrations.i-will-check-website',
            closeButtonTranslationParams: { integrationWebsite },
        }, true);
    }

    private async showCustomErrorDialog(errorCode: CustomErrorCode): Promise<void> {
        this.modalRef.close();
        const integrationTranslateKey = 'integrations.' + this.integrationStatus!.integration!;
        const path: string = `${ integrationTranslateKey }.error-messages.${ errorCode }`;
        const modalData: ConfirmDialogData = {
            confirmDialogType: ConfirmDialogType.WARNING,
            titleTranslationKey: `${ path }.title`,
            subtitleTranslationKey: `${ path }.subtitle`,
            closeButtonTranslationKey: `${ path }.close-button`,
        };
        const acceptButtonValue: string = await this.translateService
            .get(`${ path }.accept-button`)
            .toPromise();
        modalData.acceptButtonTranslationKey = !!acceptButtonValue ? `${ path }.accept-button` : '';
        this.modalService.openConfirmDialog(modalData, true)
            .subscribe({
                next: (didConfirm: boolean) => {
                    if (didConfirm) {
                        this.modal.open(DanloenIntegrationMatchingModalComponent, {
                            width: '808px', maxHeight: '80vh',
                        });
                    }
                },
            });
    }

    /**
     * Get a list of unique employees working on a given list of shifts
     * @param shifts - The shifts to search through
     */
    private getEmployeesWithShifts(shifts: Shift[]): Employee[] {
        // Map shifts to their employees, remove nulls, and remove duplicates
        return _.uniqBy(_.compact(_.map(shifts, 'employee')), 'id');
    }

}
