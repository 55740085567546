import { Component, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ImageCroppedEvent, ImageCropperComponent } from 'ngx-image-cropper';
import { Observable, throwError } from 'rxjs';
import { catchError, switchMap, take, tap } from 'rxjs/operators';
import { Employee } from '../../../../core/model';
import { EmployeeService } from '../../../../core/services';
import { SnackbarColor, SnackbarService } from '../../../../core/services/snackbar/snackbar.service';

export interface ProfilePictureModalData {
    imageChangedEvent: { base64: string };
    employee?: Employee;
}

@Component({
    selector: 'app-profile-picture-modal',
    templateUrl: './profile-picture-modal.component.html',
    styleUrls: ['./profile-picture-modal.component.scss'],
})
export class ProfilePictureModalComponent {

    @ViewChild('cropper') public cropper: ImageCropperComponent;
    public croppedImage: string = '';
    public saving: boolean;
    public loading: boolean = true;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: ProfilePictureModalData,
        private modalRef: MatDialogRef<ProfilePictureModalComponent>,
        private employeeService: EmployeeService,
        private snackService: SnackbarService,
    ) {
        this.croppedImage = data.imageChangedEvent.base64;
    }

    public imageCropped(event: ImageCroppedEvent): void {
        if (event.base64) {
            this.croppedImage = event.base64;
        } else {
            console.error('No base64 image data was output');
            this.snackService.displaySnack({ translationKey: 'profile-picture.error-messages.could-not-crop' }, SnackbarColor.warn);
        }
    }

    /**
     * Closes all active modals based on the initialization
     */
    public closeModal(): void {
        this.modalRef.close();
    }

    /**
     * Uploads the current image to a user
     */
    public upload(): void {
        this.saving = true;
        if (this.data?.employee) {
            this.employeeService.setEmployeeImage(this.data.employee, this.croppedImage).pipe(
                take(1),
                tap(() => this.modalRef.close()),
                catchError((err: Error) => {
                    return this.handleError(err);
                }),
            ).subscribe();
        } else {
            this.employeeService.getAuthenticatedEmployee().pipe(
                take(1),
                switchMap((employee: Employee) => this.employeeService.setEmployeeImage(employee, this.croppedImage)),
                take(1),
                tap(() => this.modalRef.close()),
                catchError((err: Error) => {
                    return this.handleError(err);
                }),
            ).subscribe();
        }
    }

    private handleError(err: Error): Observable<never> {
        this.saving = false;
        this.snackService.displaySnack({ translationKey: 'profile-picture.error-messages.could-not-save' }, SnackbarColor.warn);
        return throwError(err);
    }
}
