import { Pipe, PipeTransform } from '@angular/core';
import { Shift } from '../../core/model';
import { getShiftDuration } from '../utilities/ShiftUtils/durationUtils';

@Pipe({
    name: 'shiftDuration',
})
export class ShiftDurationPipe implements PipeTransform {

    public transform(value: Shift): number {
        return getShiftDuration(value);
    }

}
