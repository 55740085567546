import { Wish } from '../../../../../../core/model';
import { Translation } from '../../../../../../core/services/snackbar/snackbar.service';

export enum EmployeeSourceType {
    Wish = 'Wish',
    FreeShift = 'Free Shift',
    Divider = 'Divider',
    NewEmployee = 'New Employee',
}

export enum EmployeeCategory {
    PrimaryEmployee,
    SecondaryEmployee,
    WithoutRoleInDepartmentEmployee,
    FreeShift,
    CreateEmployee,
    SecondaryDivider,
    WithoutRoleInDepartmentDivider,
}

export interface EmployeeSource {
    readonly type: EmployeeSourceType;
    readonly employeeCategory: EmployeeCategory;
    readonly name?: string;
    data?: { wish: Wish, hoursThisWeek: number } | string | Translation;
}

export class EmployeeWish implements EmployeeSource {
    public readonly type: EmployeeSourceType = EmployeeSourceType.Wish;
    public readonly name: string;

    constructor(public data: { wish: Wish, hoursThisWeek: number }, public employeeCategory: EmployeeCategory) {
        this.name = `${data.wish.employee.firstname} ${data.wish.employee.lastname}`;
    }
}

export class FreeShift implements EmployeeSource {
    public readonly type: EmployeeSourceType = EmployeeSourceType.FreeShift;
    public readonly employeeCategory: EmployeeCategory = EmployeeCategory.FreeShift;
}

export class Divider implements EmployeeSource {
    public readonly type: EmployeeSourceType = EmployeeSourceType.Divider;

    constructor(public data: Translation, public employeeCategory: EmployeeCategory) {
    }
}

export class NewEmployee implements EmployeeSource {
    public readonly type: EmployeeSourceType = EmployeeSourceType.NewEmployee;
    public readonly employeeCategory: EmployeeCategory = EmployeeCategory.CreateEmployee;
}
