<div class="language-setting"
     ngbDropdown
     placement="top-left">
    <ui-button-standard ngbDropdownToggle>
        <!-- Loading placeholder -->
        <div class="language-placeholder"
             *ngIf="!(selectedLanguage | async)">{{ 'shared.language' | translate | capitalize }}>
        </div>
        {{ selectedLanguage | async }}
        <div class="arrow">
            <i class="fa fa-chevron-down"></i>
        </div>
    </ui-button-standard>
    <div ngbDropdownMenu>
        <button ngbDropdownItem
                *ngFor="let language of languages"
                (click)="setLanguage(language.value)">
            {{ language.display }}
        </button>
    </div>
</div>