import { Injectable } from '@angular/core';
import { CollectionReference, Query, QueryFn } from '@angular/fire/firestore';
import { combineLatest, from, Observable, of } from 'rxjs';
import { first, map, pluck, shareReplay, switchMap } from 'rxjs/operators';
import { Employee, Wish } from '../../../model';
import { FirestoreWish } from '../../../model/Firestore';
import { EmployeeQuery } from '../../Employee/state/employee.query';
import { WishQueryParams, WishService } from '../WishService.model';
import { WishQuery } from './wish.query';
import { AkitaWishService } from './wish.service';

@Injectable({
    providedIn: 'root',
})
export class AkitaWishFacadeService extends WishService {

    constructor(
        private wishQuery: WishQuery,
        private wishService: AkitaWishService,
        private employeeQuery: EmployeeQuery,
    ) { super(); }

    public createWish(wish: Omit<Wish, 'id'>): Observable<Wish> {
        return from(this.wishService.add(wish)).pipe(switchMap((wishID: string) => this.wishQuery.select(wishID)));
    }

    public createWishes(wishes: Wish[]): Observable<Wish[]> {
        return from(Promise.all(wishes.map((wish: Wish) => this.wishService.add(wish)))).pipe(
            switchMap((wishIDs: string[]) => this.wishQuery.selectMany(wishIDs)));
    }

    public getWish(_employee: Employee, wishID: string): Observable<Wish> {
        return this.wishQuery.selectEntity(wishID).pipe(map((wish: Wish) => {
            if (!wish) throw new Error(`No wish with id ${ wishID }`);
            return wish;
        }));
    }

    public getWishes(queryParams: WishQueryParams): Observable<Wish[]> {
        const queryFn: QueryFn = (ref: CollectionReference<FirestoreWish>) => {
            let query: Query = ref;
            query = queryParams.from ? query.where('from', '>=', queryParams.from) : query;
            query = queryParams.to ? query.where('from', '<', queryParams.to) : query;
            query = queryParams.employee ? query.where('employeeID', '==', queryParams.employee.id) : query;
            return query;
        };
        return combineLatest([
            this.wishQuery.selectAll({
                filterBy: (wish: Wish) => {
                    return (!queryParams.employee || queryParams.employee.id === wish.employee.id)
                        && (!queryParams.from || wish.from >= queryParams.from)
                        && (!queryParams.to || wish.from < queryParams.to);
                },
            }),
            this.employeeQuery.selectLoading().pipe(
                first((loading: boolean) => !loading),
                switchMap(() => this.wishService.syncCollection(queryFn))),
        ]).pipe(pluck(0), shareReplay({ refCount: true, bufferSize: 1 }));
    }

    public updateWish(wish: Wish): Observable<Wish> {
        return from(this.wishService.update(wish)).pipe(switchMap(() => this.getWish(wish.employee, wish.id)));
    }

    public updateWishes(wishes: Wish[]): Observable<Wish[]> {
        if (!wishes.length) return of([]);
        return from(this.wishService.update(wishes))
            .pipe(switchMap(() => combineLatest(wishes.map((wish: Wish) => this.getWish(wish.employee, wish.id)))));
    }
}
