<div class="main">
    <app-header class="header">
        <div class="title left"
             *ngIf="departmentEmployees?.length">
            {{(primary ? 'shared.primary-employees' : 'shared.secondary-employees') | translate | capitalize}}</div>
        <div class="center"></div>
        <div class="right"
             ngbDropdown
             (openChange)="menuOpen = $event"
             [class.menuOpen]="menuOpen"
             placement="bottom"
             #dropdown="ngbDropdown">
            <ui-search-field #searchField
                             [searchStringBuilder]="getSearchString"
                             [searchList]="employees$ | async"
                             placeholderText="{{'admin.department.choose-employees' | translate | capitalize}}"
                             (filteredList)="searchResultEmployees = $event"
                             (click)="focusSearchbar(dropdown, searchField)"></ui-search-field>
            <div id="anchor"
                 ngbDropdownAnchor></div>
            <div class="dropdownMenu"
                 ngbDropdownMenu>
                <div *ngFor="let group of groupedEmployees; trackBy: trackByEmployeesOfDepartmentGroup"
                     class="dropdownItemGroup">
                    <div class="groupName"
                         *ngIf="group.department">
                        {{ 'admin.department.employee-department-group-header' | translate: {departmentName:
                        group.department?.name} }}
                    </div>
                    <div *ngFor="let emp of group.employees | intersection : searchResultEmployees; trackBy: trackByEmployee"
                         class="dropdownItem">
                        <div class="pic-name-container">
                            <img class="pic"
                                 [lazyLoad]="emp.imageURL">
                            {{emp.firstname + ' ' + emp.lastname}}
                        </div>
                        <div *ngIf="primary"
                             class="primary-buttons">
                            <ui-button-text *ngIf="!emp.primaryDepartment"
                                            color="primary"
                                            (click)="saveEmployee(addToDepartment(emp, department, true))">
                                {{'shared.add' | translate | capitalize}}
                            </ui-button-text>
                            <ui-button-text *ngIf="emp.primaryDepartment && emp.primaryDepartment?.id !== department.id"
                                            color="primary"
                                            (click)="saveEmployee(moveToDepartment(emp, department))">
                                {{ 'shared.move' | translate | capitalize}}
                            </ui-button-text>
                            <ui-button-text *ngIf="emp.primaryDepartment?.id === department.id && !previousDepartmentForEmployee.has(emp.id)"
                                            class="remove-button"
                                            (click)="saveEmployee(removeFromDepartment(emp, department))">
                                {{'shared.remove' | translate | capitalize}}
                            </ui-button-text>
                            <ui-button-text *ngIf="emp.primaryDepartment?.id === department.id && previousDepartmentForEmployee.has(emp.id)"
                                            class="remove-button"
                                            (click)="saveEmployee(moveToPreviousDepartment(emp))">
                                {{ 'shared.send-back' | translate | capitalize}}
                            </ui-button-text>
                        </div>
                        <div *ngIf="!primary"
                             class="secondary-buttons">
                            <ui-button-text *ngIf="!(emp.departments | intersection : [department]).length"
                                            color="primary"
                                            (click)="saveEmployee(addToDepartment(emp, department, false))">
                                {{'shared.add' | translate | capitalize}}
                            </ui-button-text>
                            <ui-button-text *ngIf="(emp.departments | intersection : [department]).length"
                                            class="remove-button"
                                            (click)="saveEmployee(removeFromDepartment(emp, department))">
                                {{'shared.remove' | translate | capitalize}}
                            </ui-button-text>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </app-header>
    <overlay-scrollbars class="employee-item-container">
        <div class="placeholder"
             *ngIf="!departmentEmployees?.length">
            <div class="style-3">
                {{(primary ? 'admin.department.assign-employees' : 'admin.department.give-employees-access') | translate
                | capitalize}}
            </div>
            <div class="style-9 pb-3"
                 *ngIf="primary">{{ 'admin.department.assign-employees-primary-text' | translate }}</div>
            <div class="style-9 pb-3"
                 *ngIf="!primary"
                 [innerHTML]="'admin.department.assign-employees-secondary-text' | translate">
            </div>
            <ui-button-action *ngIf="primary"
                              (click)="focusSearchbar(dropdown, searchField)">
                {{ 'admin.department.choose-employees' | translate }}</ui-button-action>
            <ui-button-standard *ngIf="!primary"
                                (click)="focusSearchbar(dropdown, searchField)">
                {{ 'admin.department.choose-employees' | translate }}</ui-button-standard>
        </div>
        <app-edit-department-employee-item *ngFor="let emp of departmentEmployees; trackBy: trackByEmployee"
                                           [employee]="emp"
                                           [roles$]="roles$"
                                           (selectedRoles)="saveEmployee(updateRoles(emp, department, $event))"
                                           (removeEmployeeClicked)="saveEmployee(previousDepartmentForEmployee.has(emp.id) ? moveToPreviousDepartment(emp) : removeFromDepartment(emp, department))">
        </app-edit-department-employee-item>
    </overlay-scrollbars>
</div>