import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { TupleOf } from './TypeUtils';

/**
 * Returns a validator function which compares the value of the matchControl
 *  with the value of the control that you apply the validator function to
 * @param matchControl the control you want to compare values with
 */
export function createControlMatchValidator(matchControl: FormControl): ValidatorFn {
    return (control: FormControl): ValidationErrors | null => {
        const controlValue: string = control.value;
        const matchValue: string = matchControl.value;
        if (matchValue !== controlValue) {
            return { noMatch: true };
        }
        return null;
    };
}

/**
 * Returns a validatorfunction which tests control values against the supplied pattern,
 *  and returns the given custom error if the validation fails
 */
function createCustomErrorPatternValidator(pattern: RegExp, customErrors: ValidationErrors): ValidatorFn {
    return (control: FormControl): ValidationErrors | null => {
        const validator: ValidatorFn = Validators.pattern(pattern);
        const result: ValidationErrors | null = validator(control);
        return result ? customErrors : null;
    };
}

/**
 * Returns the numerical value of an AbstractControl and throws error if NaN
 */
const getNumValue = (control: AbstractControl): number => {
    const value = Number(control.value);
    if (isNaN(value)) throw Error('Control value NaN');
    return value;
};


/**
 * Sets or removes the invalidTimeInputError on a list of controls
 * @param shouldSetError Whether the error should be set or removed
 * @param controls The controls on which to set or removethe error
 */
const setInvalidTimeInputError = (shouldSetError: boolean, ...controls: AbstractControl[]): void =>
    controls.forEach((control: AbstractControl) => {
        // Get the current errors of the control
        const currentErrors = control.errors;

        // Check if there are no errors or if the invalidTimeInput error is the only error
        const noErrorsOrOnlyTimeInputError = !currentErrors || Object.keys(currentErrors).length === 1
            && !!Object.keys(currentErrors).find((key: string) => key === 'invalidTimeInput');

        // Overwrite the errors of the control if no errors or if the invalidTimeInput error is the only error
        if (noErrorsOrOnlyTimeInputError) return control.setErrors(shouldSetError ? { invalidTimeInput: true } : null);

        // Otherwise, either set or delete the invalidTimeInput error on the control error object
        if (shouldSetError) currentErrors!.invalidTimeInput = { invalidTimeInput: true };
        else delete currentErrors!.invalidTimeInput;
    });

const fullNameValidator: ValidatorFn = createCustomErrorPatternValidator(
    /\S+( \S+)+/,
    { notFullName: true });

const passwordValidator: ValidatorFn = createCustomErrorPatternValidator(
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[\S]{8,}$/,
    { badPassword: true });

const VATValidator: ValidatorFn = createCustomErrorPatternValidator(
    /^[1-9][0-9]{7}$/,
    { invalidVAT: true });

const StreetValidator: ValidatorFn = createCustomErrorPatternValidator(
    /^[A-z\u00C0-\u00ff].* [0-9]/,
    { invalidStreet: true });

const FirstCharIsLetter: ValidatorFn = createCustomErrorPatternValidator(
    /^[A-z\u00C0-\u00ff]/,
    { firstCharNotLetter: true });

const PhoneValidator: ValidatorFn = createCustomErrorPatternValidator(
    /^[0-9]{8,}$/,
    { invalidPhone: true });

const MoneyValidator: ValidatorFn = createCustomErrorPatternValidator(
    /^[0-9]+(,[0-9]{1,2})?$/,
    { invalidMoney: true });

const PositiveIntegerValidator: ValidatorFn = createCustomErrorPatternValidator(
    /^[0-9]+$/,
    { invalidInteger: true });

const RequireCheckboxesCheckedIf =
    (shouldCheck: () => boolean): ValidatorFn =>
        (formGroup: FormGroup): ValidationErrors | null => {
            // If we should not check, return null (i.e. no validation error)
            if (!shouldCheck()) return null;

            // Otherwise, check if one of the checkboxes is checked and return an error if not
            const checked: boolean = !!Object.keys(formGroup.controls).find((checkBoxKey: string) =>
                formGroup.controls[checkBoxKey]?.value === true);
            return checked ? null : { notAllCheckboxesChecked: true };
        };

const ValidateTimeInput = ([startHoursControl, startMinControl, endHoursControl, endMinControl]: TupleOf<AbstractControl, 4>):
    ValidatorFn => (): ValidationErrors | null => {
        // Get the numerical values from the controls
        const [startHours, startMin, endHours, endMin]: TupleOf<number, 4> =
            [getNumValue(startHoursControl), getNumValue(startMinControl), getNumValue(endHoursControl), getNumValue(endMinControl)];

        // Reset errors
        setInvalidTimeInputError(false, startMinControl, endMinControl, startHoursControl, endHoursControl);

        // If hours are equal and minutes are equal, set error on all four controls
        if (startHours === endHours && startMin === endMin) {
            setInvalidTimeInputError(true, startMinControl, endMinControl, startHoursControl, endHoursControl);
        }

        // If hours are equal and start minutes is greater than end minutes, set error on minute controls
        if (startHours === endHours && startMin > endMin) setInvalidTimeInputError(true, startMinControl, endMinControl);

        // If start hours are greater than end hours, set error on hour controls
        if (startHours > endHours) setInvalidTimeInputError(true, startHoursControl, endHoursControl);

        // If end hours is 24 and the minutes aren't zero, set error on end controls
        if (endHours === 24 && endMin !== 0) setInvalidTimeInputError(true, endHoursControl, endMinControl);

        // Return the error of one of the controls (or null if none)
        return startHoursControl.errors || startMinControl.errors || endHoursControl.errors || endMinControl.errors;
    };


export class CustomValidators {
    /**
     * Validates a string against the requirements of a full name
     */
    public static FullNameValidator: ValidatorFn = fullNameValidator;
    /**
     * Validates a string against the requirements of a password
     */
    public static PasswordValidator: ValidatorFn = passwordValidator;
    /**
     * Validates a string against the requirements of a VAT number
     */
    public static VATValidator: ValidatorFn = VATValidator;
    /**
     * Validates that a string starts with a letter and that it contains a space followed by a number
     */
    public static StreetValidator: ValidatorFn = StreetValidator;
    /**
     * Validates that a string starts with a letter
     */
    public static CityValidator: ValidatorFn = FirstCharIsLetter;
    /**
     * Validates that a string starts with a letter
     */
    public static CompanyNameValidator: ValidatorFn = FirstCharIsLetter;
    /**
     * Validates a string against the requirements of a Danish zip code
     */
    public static ZipValidator: ValidatorFn = Validators.pattern('^[0-9]{4}$');
    /**
     * Validates a string against the requirements of a Danish phone number
     */
    public static PhoneValidator: ValidatorFn = PhoneValidator;
    /**
     * Validates a string against the requirements of an amount of money
     * Accepts a positive number with zero, one or two decimals (comma-seperated)
     */
    public static MoneyValidator: ValidatorFn = MoneyValidator;
    /**
     * Validates that a string is a positive integer (0 is also accepted)
     */
    public static PositveIntegerValidator: ValidatorFn = PositiveIntegerValidator;
    /**
     * Validate that at least one checkbox is checked if the shouldCheck parameter returns true, otherwise anything is accepted
     */
    public static RequireCheckboxesCheckedIf: (shouldCheck: () => boolean) => ValidatorFn = RequireCheckboxesCheckedIf;
    /**
     * Validate that the start is before the end and that the end is max 24:00
     */
    public static ValidateTimeInput:
        ([startHoursControl, startMinControl, endHoursControl, endMinControl]: AbstractControl[]) => ValidatorFn = ValidateTimeInput;

}

/**
 * Returns an object with firstname and lastname based on the given name string
 * @param name a string containing a name to split into firstname and lastname
 */
export function splitName(name: string): { firstname: string, lastname: string } {
    const fullname: string = name.trim();
    const firstname: string = fullname.substring(0, fullname.indexOf(' '));
    const lastname: string = fullname.substring(fullname.indexOf(' ')).trim();
    return { firstname, lastname };
}
