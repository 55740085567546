<div class="container">
    <div *ngIf="noFreeShifts"
         class="warning-box">
        <i class="far fa-exclamation-circle"></i>
        {{ 'admin.calendar.fill-shifts-warning' | translate | capitalize }}
    </div>
    <h1 [class.warning]="noFreeShifts">
        {{ 'admin.calendar.fill-shifts' | translate | capitalize }}
    </h1>
    <p>
        {{ 'admin.calendar.fill-shifts-explanation' | translate | capitalize }}
    </p>
    <div class="bullets"
         [class.warning]="noFreeShifts">
        <div class="bullet">
            <i class="far fa-check"></i>
            <span>{{ 'admin.calendar.fill-shifts-bullet-1' | translate | capitalize }}.</span>
        </div>
        <div class="bullet">
            <i class="far fa-check"></i>
            <span>{{ 'admin.calendar.fill-shifts-bullet-2' | translate | capitalize }}.</span>
        </div>
        <div class="bullet">
            <i class="far fa-check"></i>
            <span>{{ 'admin.calendar.fill-shifts-bullet-3' | translate | capitalize }}.</span>
        </div>
    </div>
    <div class="obs">
        {{ 'admin.calendar.fill-shifts-obs' | translate }}
    </div>
    <ui-button-action class="auto-assign-btn"
                      matTooltip="{{ 'admin.calendar.fill-shifts-tooltip' | translate | capitalize }}"
                      [disabled]="noFreeShifts"
                      [loading]="fillingShifts"
                      [appProLabel]="Package.PRO"
                      (click)="fillShifts()">
        {{ 'admin.calendar.fill-shifts' | translate | capitalize }}
    </ui-button-action>
    <div class="bottom">
        <img alt="Illustration of auto assign"
             src="assets/images/auto-assign.svg">
    </div>
</div>
