import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { take } from 'rxjs/operators';
import { Employee, sortEmployeesByName } from '../../../../../../../../../../core/model';
import { DataloenEmployee } from '../../../../../../../../../../core/model/Integrations/Dataloen';
import { EmployeeMapping, SalaryIntegration } from '../../../../../../../../../../core/model/Integrations/Shared';
import { IntegrationSettingsService } from '../../../../../../../../../../core/services/Settings/IntegrationSettings/Firestore/integration-settings.service';
import { LazyLoadImageProviderFactory } from '../../../../../../../../../../shared/factories/LazyLoadImageHooks';
import { DataloenWhyMatchModalComponent } from '../dataloen-why-match-modal/dataloen-why-match-modal.component';

export interface DataloenIntegrationMatchingModalData {
    mappedEmployees: Map<string, string | null>;
    unmappedRelionEmployees: Employee[];
    unmappedDataloenEmployees: DataloenEmployee[];
    existingMap: EmployeeMapping[] | undefined;
}

@Component({
    templateUrl: './dataloen-integration-matching-modal.component.html',
    styleUrls: ['./dataloen-integration-matching-modal.component.scss'],
    providers: [LazyLoadImageProviderFactory(1)],
})
export class DataloenIntegrationMatchingModalComponent implements OnInit {
    public unmatchedRelionEmployees: Employee[];
    public unmatchedDataloenEmployees: DataloenEmployee[];
    public integration: SalaryIntegration = SalaryIntegration.DATALOEN;
    public allEmployeesMapped: boolean = false;
    private manuallyMappedEmployees: Map<string, string | null>;
    private autoMappedEmployees: Map<string, string | null>;

    constructor(
        public modalRef: MatDialogRef<DataloenIntegrationMatchingModalComponent>,
        @Inject(MAT_DIALOG_DATA) private data: DataloenIntegrationMatchingModalData,
        private integrationSettingsService: IntegrationSettingsService,
        private modal: MatDialog,
    ) {
        this.manuallyMappedEmployees = new Map();
    }

    public async ngOnInit(): Promise<void> {
        this.unmatchedDataloenEmployees = this.data.unmappedDataloenEmployees
            .filter((emp: DataloenEmployee) => !emp.isTerminated)
            .sort((a: DataloenEmployee, b: DataloenEmployee) => a.name.localeCompare(b.name));
        this.unmatchedRelionEmployees = this.data.unmappedRelionEmployees.sort(sortEmployeesByName);
        this.autoMappedEmployees = this.data.mappedEmployees;

        // Map the existing mappings to the manually mapped mappings
        this.unmatchedRelionEmployees.map((employee: Employee) => {
            const existingMapping = this.data.existingMap?.find((mapping: EmployeeMapping) => mapping[employee.id] !== undefined);
            if (existingMapping !== undefined) this.manuallyMappedEmployees.set(employee.id, existingMapping[employee.id]!);
        });

        this.checkEmployeeMapping();
    }

    public saveMapping(): void {
        const employeeMap: EmployeeMapping[] =
            [...this.autoMappedEmployees.entries(), ...this.manuallyMappedEmployees.entries()]
                .reduce((mapped: EmployeeMapping[], mapping: [string, string | null]) => {
                    mapped.push({ [mapping[0]]: mapping[1] });
                    return mapped;
                }, [] as EmployeeMapping[]);
        this.integrationSettingsService.saveSetting(SalaryIntegration.DATALOEN, { employeeMap }).pipe(take(1))
            .subscribe();
        this.modalRef.close();
    }

    public checkEmployeeMapping(): void {
        this.allEmployeesMapped = this.manuallyMappedEmployees.size === this.unmatchedRelionEmployees.length;
    }

    public openHelpModal(): void {
        this.modal.open(DataloenWhyMatchModalComponent, { autoFocus: false });
    }

    public selectMatch(employee: Employee, dataloenEmployee?: DataloenEmployee): void {
        const matchedEmployee = dataloenEmployee?.employeeId ?? null;
        this.manuallyMappedEmployees = this.manuallyMappedEmployees.set(employee.id, matchedEmployee);
        this.checkEmployeeMapping();
    }

    public getDataloenEmployee(employeeID: string): DataloenEmployee | null | undefined {
        const dataloenEmployeeID = this.manuallyMappedEmployees.get(employeeID);
        if (dataloenEmployeeID === null) return null;
        const foundDataloenEmployee = this.unmatchedDataloenEmployees
            .find((dataloenEmployee: DataloenEmployee) => dataloenEmployee.employeeId === dataloenEmployeeID);
        return foundDataloenEmployee;
    }
}
