import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable } from '@angular/core';
import * as Sentry from '@sentry/browser';
import { environment } from '../environments/environment.dev';


@Injectable()
export class SentryErrorHandler implements ErrorHandler {
    constructor() { }


    public extractError(inputError): string | Error | null {
        // Try to unwrap zone.js error.
        const error = (inputError && inputError.ngOriginalError) ? inputError.ngOriginalError : inputError;

        // We can handle messages and Error objects directly.
        if (typeof error === 'string' || error instanceof Error) return error;

        // If it's http module error, extract as much information from it as we can.
        if (error instanceof HttpErrorResponse) {
            // The `error` property of http exception can be either an `Error` object, which we can use directly...
            if (error.error instanceof Error) return error.error;

            // ... or an`ErrorEvent`, which can provide us with the message but no stack...
            if (error.error instanceof ErrorEvent) return error.error.message;

            // ...or the request body itself, which we can use as a message instead.
            if (typeof error.error === 'string') return `Server returned code ${ error.status } with body "${ error.error }"`;

            // If we don't have any detailed information, fallback to the request message itself.
            return error.message;
        }

        // Skip if there's no error, and let user decide what to do with it.
        return null;
    }


    public handleError(error: Error): void {
        const extractedError = this.extractError(error) || 'Handled unknown error';

        const chunkFailedMessage = /Loading chunk [\d]+ failed/;
        if (chunkFailedMessage.test(typeof extractedError === 'string' ? extractedError : extractedError.message)) {
            window.location.reload();
        }
        // Capture handled exception and send it to Sentry.
        if (environment.production) Sentry.captureException(extractedError);

        // When in development mode, log the error to console for immediate feedback.
        else console.error(extractedError);
    }
}
