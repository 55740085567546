import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { Employee, Shift } from '../../../core/model';
import { CompanySetting, CompanySettingsService, EmployeeService, ShiftService } from '../../../core/services';

@Component({
    selector: 'app-salary-swipe-label',
    templateUrl: './salary-swipe-label.component.html',
    styleUrls: ['salary-swipe-label.component.scss'],
})
export class SalarySwipeLabelComponent implements OnInit {
    @Input() public shift: Shift;
    @Input() public dayViewShift: boolean;
    @Input() public mobileVersion: boolean;
    @Input() public weekShiftCard: boolean;
    @Input() public dayShiftCard: boolean;
    public onlyIncludeShiftsMarkedWithSwipe$: Observable<boolean>;

    constructor(private companySettingsService: CompanySettingsService,
                private shiftService: ShiftService,
                private employeeService: EmployeeService,
                ) {
    }

    public ngOnInit(): void {
        this.onlyIncludeShiftsMarkedWithSwipe$ = this.companySettingsService
            .loadSetting(CompanySetting.ONLY_INCLUDE_SHIFTS_MARKED_WITH_SWIPE)
            .pipe(map((enabledOn: Date | null) => !!enabledOn));
    }

    public onClick(): void {
        if (this.weekShiftCard || this.dayShiftCard) return;
        let shiftCopy: Shift = { ...this.shift, salarySwipe: undefined };
        if (!this.shift.salarySwipe) {
            this.employeeService.getAuthenticatedEmployee()
                .pipe(take(1))
                .subscribe((employee: Employee) => {
                    shiftCopy = { ...shiftCopy, salarySwipe: { enabledOn: new Date(), enabledBy: employee } };
                });
        }
        this.shiftService.updateShift(shiftCopy)
            .pipe(take(1))
            .subscribe();
    }
}
