import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { combineLatest, Observable, of, Subscription, timer } from 'rxjs';
import { catchError, filter, map, pluck, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { CustomErrorCode } from '../../../../../../../../../functions/src/errorHandling/models';
import { Company } from '../../../../../../../core/model';
import { FenerumAccount } from '../../../../../../../core/model/Fenerum/FenerumAccount';
import { FenerumCard, FenerumSubscription } from '../../../../../../../core/model/Freemium';
import { Package } from '../../../../../../../core/model/Freemium/Package';
import { Subscriptions } from '../../../../../../../core/model/Freemium/Subscriptions';
import { SubscriptionStatus } from '../../../../../../../core/model/SubscriptionStatus';
import { CompanyService } from '../../../../../../../core/services';
import { CloudFunctionsService } from '../../../../../../../core/services/cloud-functions.service';
import { ConfigurationService } from '../../../../../../../core/services/Configuration/configuration-service.model';
import { ModalService } from '../../../../../../../core/services/ModalService.model';
import { SnackbarColor, SnackbarService } from '../../../../../../../core/services/snackbar/snackbar.service';
import { CardDetailsModalComponent } from '../../../../../../../shared/components/freemium/card-details-modal/card-details-modal.component';
import { isExpired } from '../../../../../../../shared/utilities/FenerumUtility';

interface Invoice {
    amount: number;
    due: Date;
}

export interface Card {
    brand: string;
    expiry: string;
    number: string;
    expired: boolean;
}

type ProSubscriptions = {
    [pack in Package]: FenerumSubscription | null;
};

type PaymentDetails = {
    nextInvoice?: Invoice;
    card?: Card;
} & ProSubscriptions;

@Component({
    selector: 'app-payment-settings',
    templateUrl: './payment-settings.component.html',
    styleUrls: ['./payment-settings.component.scss'],
})
export class PaymentSettingsComponent implements OnInit, OnDestroy {
    public locale: string;
    public localeSubscription: Subscription;

    public isPro$: Observable<boolean>;
    public freeTrialAvailable$: Observable<boolean>;
    public now: Date;

    public paymentDetails$: Observable<PaymentDetails>;

    public trialExpiration$: Observable<Date>;
    public updatingCard: boolean;
    public Package: typeof Package = Package;
    public SubscriptionStatus: typeof SubscriptionStatus = SubscriptionStatus;
    public status$: Observable<SubscriptionStatus>;

    constructor(
        private configurationService: ConfigurationService,
        private translateService: TranslateService,
        private cloudFunctions: CloudFunctionsService,
        private companyService: CompanyService,
        private modalService: ModalService,
        private functionService: CloudFunctionsService,
        private snackService: SnackbarService,
    ) { }

    public ngOnInit(): void {
        // Load and subscribe to current language
        this.locale = this.translateService.currentLang;
        this.localeSubscription = this.translateService.onLangChange.subscribe({
            next: (langChangeEvent: LangChangeEvent): string => this.locale = langChangeEvent.lang,
        });
        this.isPro$ = this.configurationService.isPro(Package.PRO);
        this.now = new Date();

        const firestoreSubscriptions$: Observable<Subscriptions> = this.configurationService.getSubscriptions();

        this.freeTrialAvailable$ = this.configurationService.freeTrialAvailable(Package.PRO);
        this.status$ = this.configurationService.getSubscriptionStatus(Package.PRO);

        this.trialExpiration$ = this.configurationService.trialExpiration(Package.PRO).pipe(
            map((date: Date | null) => date || this.now));

        const currentCompany$: Observable<Company> = this.companyService.getCurrentCompany();
        const account$: Observable<FenerumAccount | null> = currentCompany$.pipe(
            switchMap((company: Company) => this.cloudFunctions.getFenerumAccount({ companyID: company.id })),
            catchError((err: CustomErrorCode | Error) => {
                if (err === 'NoCompanyAccountFound') return of(null);
                throw err;
            }),
        );

        this.paymentDetails$ = combineLatest([firestoreSubscriptions$, account$, currentCompany$]).pipe(
            map(([firestoreSubscriptions, account, company]: [Subscriptions, FenerumAccount | null, Company]) => {
                const fenerumSubscriptions: ProSubscriptions = {
                    [Package.PRO]: account?.subscription_set.find((fenerumSubscription: FenerumSubscription) =>
                        fenerumSubscription.uuid === firestoreSubscriptions.schedule?.fenerumSubscriptionUUID &&
                        fenerumSubscription.status !== 'expired',
                    ) || null,
                };

                const registeredCard: FenerumCard | undefined =
                    account?.paymentcard_set.find((card: FenerumCard) => card.uuid === company.paymentCardUUID);
                return [registeredCard, fenerumSubscriptions];
            }),
            map(this.mapPaymentDetails, this),
        );
    }

    public upgrade(status: SubscriptionStatus): void {
        switch (status) {
            case SubscriptionStatus.TRIAL_AVAILABLE: return this.modalService.openProShopOverlay();
            case SubscriptionStatus.TRIAL_ACTIVE:
            case SubscriptionStatus.TRIAL_USED: return this.modalService.openProUpgradeOverlay();
        }
    }

    /**
     * Opens the Pro Shop modal
     */
    public openReferralLinkModal(): void {
        this.modalService.openReferralLinkModal();
    }

    public ngOnDestroy(): void {
        this.localeSubscription.unsubscribe();
    }

    /**
     * Opens the CardDetailsModal and updates the cards if the user added a new one
     */
    public async updateCard(): Promise<void> {
        this.updatingCard = true;
        const companyID: string = await this.companyService.getCurrentCompany().pipe(pluck('id'), take(1)).toPromise();
        const url: string = await this.functionService.getCardURL({ companyID });
        const ref: MatDialogRef<CardDetailsModalComponent, void> = this.modalService.openCardDetailsModal(url);
        await timer(5000, 2000).pipe(
            // We stop listening for a new card if the user cancels the CardDetailsModal
            takeUntil(ref.afterClosed()),
            switchMap(() => this.cloudFunctions.getFenerumAccount({ companyID })),
            // Forward if the user has added a new card
            filter(this.hasTwoActiveCards, this),
            take(1),
            // Close the CardDetailsModal
            tap(() => ref.close()),
            // Update the PaymentCards via Cloud Function
            switchMap(() => this.updateFenerumPaymentCards(companyID)),
        ).toPromise();
        this.updatingCard = false;
    }

    /**
     * Update the fenerum payment cards via the cloud function and display a snackbar of the result
     * @param companyID - The company of the account to update cards for
     */
    private updateFenerumPaymentCards(companyID: string): Promise<void> {
        const displaySnack = (key: string, color: SnackbarColor) => async () => {
            const message = await this.translateService.get(key).pipe(take(1)).toPromise();
            this.snackService.displaySnack(message, color);
        };
        return this.cloudFunctions.updateFenerumPaymentCards({ companyID })
            .then(displaySnack('freemium.card-update-success', SnackbarColor.success))
            .catch(displaySnack('error.card-update-failed', SnackbarColor.warn));
    }

    /**
     * Check if an account has two active cards
     * @param account The account to check
     */
    private hasTwoActiveCards(account: FenerumAccount): boolean {
        return account.paymentcard_set.filter((card: FenerumCard) => card.active).length === 2;
    }

    /**
     * Maps a FenerumCard and a FenerumSubscription to PaymentDetails used in the template
     */
    private mapPaymentDetails([activeCard, subscriptions]: [FenerumCard | undefined, ProSubscriptions]): PaymentDetails {
        const details: PaymentDetails = { ...subscriptions };

        if (activeCard) {
            details.card = {
                brand: activeCard.brand,
                expiry: `${ activeCard.month }/${ activeCard.year.toString().slice(-2) }`,
                number: `**** **** **** ${ activeCard.card_number.slice(-4) }`,
                expired: isExpired(activeCard),
            };
        }

        // Go through the subscriptions
        Object.keys(subscriptions).forEach((pack: Package) => {
            const subscription: FenerumSubscription | null = subscriptions[pack];

            // If there's no subscription for this package or if it's expired, skip it
            if (!subscription) return;

            // Get the next renewal of the subscription of this package
            const currentSubscriptionRenewal: Date = new Date(subscription.next_renewal_date);

            // If there's no current next invoice, or if it's due later than the subcription of this package, update it
            if (!details.nextInvoice || details.nextInvoice.due > currentSubscriptionRenewal) {
                details.nextInvoice = {
                    amount: Number(subscription.override_price || subscription.terms.price) * subscription.quantity,
                    due: currentSubscriptionRenewal,
                };
            }
        });
        return details;
    }
}
