<div class="bottom-nav"
     *ngIf="authUser$ | async as authUser">
    <app-next-shift></app-next-shift>
    <div class="navigation">
        <div class="icon"
             [routerLink]="['/' + (authUser!.admin ? routes.admin.home : routes.employee.home)]"
             [class.active]="active === 1">
            <i [ngClass]="['far', authUser!.admin ? 'fa-home' : 'fa-user']"></i>
        </div>
        <div class="icon"
             [routerLink]="['/' + (authUser!.admin ? routes.admin.schedule : routes.employee.wishes)]"
             [class.active]="active === 2">
            <i [ngClass]="['far', authUser!.admin ? 'fa-calendar-alt' : 'fa-heart']"></i>
        </div>
        <div class="icon"
             [routerLink]="['/' + (authUser!.admin ? routes.admin.salary : routes.employee.calendar )]"
             [class.active]="active === 3">
            <i [ngClass]="['far', authUser!.admin ? 'fa-coins' : 'fa-calendar-alt']"></i>
        </div>
        <div class="icon"
             [routerLink]="['/' + (authUser!.admin ? routes.admin.team : routes.employee.team )]"
             [class.active]="active === 4">
            <i [ngClass]="['far', 'fa-user-friends']"></i>
        </div>
        <div class="icon"
             [routerLink]="['/' + (authUser!.admin ? routes.admin.settings : routes.employee.settings )]"
             [class.active]="active === 5">
            <i [ngClass]="['far', 'fa-cog']"></i>
        </div>
    </div>
</div>