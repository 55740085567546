<app-upgrade-overlay-base (close)="close()">
    <div class="title">{{ ('modal.upgrade-overlay.' + subscriptionStatus + '.title') | translate }}</div>
    <div class="subtitle">{{ ('modal.upgrade-overlay.' + subscriptionStatus + '.subtitle') | translate }}</div>
    <div class="description">
        <div class="image">
            <img src="assets/images/week-view-example.svg"
                 alt="Illustration of the schedule plan">
        </div>
        <div class="features-and-cta">
            <div class="heading">{{ 'modal.upgrade-overlay.shared.with-pro-you-get' | translate }}</div>
            <ul class="bullets">
                <li>{{ 'modal.upgrade-overlay.shared.bullet-1' | translate }}</li>
                <li>{{ 'modal.upgrade-overlay.shared.bullet-2' | translate }}</li>
                <li>{{ 'modal.upgrade-overlay.shared.bullet-3' | translate }}</li>
            </ul>
            <div class="cta">
                <ui-button-action (click)="upgrade()">
                    {{ ('modal.upgrade-overlay.' + subscriptionStatus + '.cta') | translate }}
                </ui-button-action>
                <ui-button-standard *ngIf="subscriptionStatus === 'trial-expired'"
                                    (click)="close()">
                    {{ 'modal.upgrade-overlay.trial-expired.go-back-to-free' | translate }}
                </ui-button-standard>
            </div>
        </div>
    </div>
</app-upgrade-overlay-base>