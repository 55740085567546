import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { SalaryIntegration } from '../../../../../../../../../../core/model/Integrations/Shared';

@Component({
    templateUrl: './salary-dk-why-match-modal.component.html',
    styleUrls: ['./salary-dk-why-match-modal.component.scss'],
})
export class SalaryDkWhyMatchModalComponent {
    public integration: SalaryIntegration = SalaryIntegration.SALARYDK;

    constructor(public modalRef: MatDialogRef<SalaryDkWhyMatchModalComponent>) { }

}
