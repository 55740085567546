<div class="input-wrapper">
    <label *ngIf="!isWebview"
           for="from">
        <div class="display-date">
            <div class="year">
                {{ date | date:'yyyy' }}
            </div>
            <div class="month">
                {{ date | date:'MMMM' | capitalize }}
                <i class="fas fa-caret-down"></i>
            </div>
        </div>
        <input id="from"
               type="month"
               value="{{ date | date: 'yyyy-MM' }}"
               (change)="dateChange.emit($event.target.valueAsDate); date = $event">
    </label>
    <label *ngIf="isWebview"
           for="select">
        <div class="display-date">
            <div class="year">
                {{ date | date:'yyyy' }}
            </div>
            <div class="month">
                {{ date | date:'MMMM' | capitalize }}
                <i class="fas fa-caret-down"></i>
            </div>
        </div>
        <select id="select"
                value="{{ date | date: 'yyyy-MM' }}"
                (change)="dateSelected($event.target.value)">
            <option *ngFor="let month of months"
                    [value]="month"
                    [selected]="(date | date: 'yyyy-MM') === (month | date: 'yyyy-MM')">
                {{month | date: 'MMMM yyyy' }}
            </option>
        </select>
    </label>
</div>