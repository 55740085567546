import { Component, Input, NgZone, OnInit, ViewChild } from '@angular/core';
import { MapCircle } from '@angular/google-maps';
import firebase from 'firebase/app';
import { Observable } from 'rxjs';
import { GPSZone } from '../../../../../../../../core/model';
import { GeolocationService } from '../../../../../../../../core/services/geolocation.service';
import { SnackbarColor, SnackbarService } from '../../../../../../../../core/services/snackbar/snackbar.service';
import { GpsZoneService } from '../gps-zone.service';

@Component({
    selector: 'app-edit-gps-zone',
    templateUrl: './edit-gps-zone.component.html',
    styleUrls: ['./edit-gps-zone.component.scss'],
})
export class EditGpsZoneComponent implements OnInit {

    @Input() public gpsZone: Partial<GPSZone>;
    public loading: boolean;
    public name: string;
    public address: string;
    public apiLoaded$: Observable<boolean>;
    public center: google.maps.LatLngLiteral;
    public zoom: number;
    public radius: number;
    public circleOptions: google.maps.CircleOptions;

    @ViewChild(MapCircle) private circle?: MapCircle;

    private readonly MAX_ZOOM_LEVEL = 22;

    private readonly MIN_ZOOM_LEVEL = 0;
    private readonly MIN_RADIUS = 30;

    constructor(
        private geoLocationService: GeolocationService,
        private gpsZoneService: GpsZoneService,
        private ngZone: NgZone,
        private snackService: SnackbarService,
    ) {
        this.apiLoaded$ = gpsZoneService.apiLoaded$;
    }

    public ngOnInit(): void {
        this.loading = true;
        this.name = this.gpsZone.name || '';
        this.address = this.gpsZone.address || '';
        this.center = this.gpsZone.geopoint
            ? { lat: this.gpsZone.geopoint.latitude, lng: this.gpsZone.geopoint.longitude }
            : { lat: 56.164432, lng: 10.213682 };
        this.zoom = this.gpsZone.geopoint ? 16 : 6;
        this.radius = this.gpsZone.radius || 50;
        this.apiLoaded$.subscribe(() => this.loading = false);
        this.circleOptions = {
            strokeColor: '#5A8EFF',
            strokeOpacity: 0.8,
            strokeWeight: 1,
            fillColor: '#5A8EFF',
            fillOpacity: 0.35,
            editable: true,
            draggable: true,
        };
    }

    public updateCenter(event: google.maps.MapMouseEvent): void {
        this.circle!.circle?.setCenter(event.latLng);
    }

    public goBack(): void {
        this.gpsZoneService.cancelEdit();
    }

    public ensureMinimumRadius(): void {
        // Make sure that the radius doesn't go below the minimum radius when user changes the radius
        if (this.circle && this.circle.getRadius() < this.MIN_RADIUS) this.circle.radius = this.MIN_RADIUS;
    }

    public changeZoom(amount: number): number {
        // Avoid zooming further out or in than allowed by the map
        if (amount > 0 && this.zoom >= this.MAX_ZOOM_LEVEL) return this.zoom = this.MAX_ZOOM_LEVEL;
        if (amount < 0 && this.zoom <= this.MIN_ZOOM_LEVEL) return this.zoom = this.MIN_ZOOM_LEVEL;

        // If we're inside the allowed range, add the amount to zoom
        return this.zoom += amount;
    }

    public save(): void {
        const geopoint = this.circle!.getCenter();
        const newZone: Partial<GPSZone> = {
            name: this.name,
            address: this.address,
            radius: this.circle!.getRadius(),
            geopoint: new firebase.firestore.GeoPoint(geopoint.lat(), geopoint.lng()),
            imageURL: '',
        };
        this.gpsZoneService.saveZone(newZone);
    }

    /**
     * Update the map and adress with a given place from Google's Places autocomplete
     */
    public onPlaceSelected(place: google.maps.places.PlaceResult): void {
        if (place.geometry) {
            // If there's coordinates attached to the place, move the map to those coordinates
            this.goToLocation({ lat: place.geometry.location.lat(), lng: place.geometry.location.lng() });
            if (place.formatted_address) {
                // If there's an address as well, update the address input field
                this.address = place.formatted_address;
            }
        }
    }

    public async goToCurrentLocation(): Promise<void> {
        try {
            const coords = await this.geoLocationService.getUserLocation();
            this.center = { lat: coords.latitude, lng: coords.longitude };
            this.zoom = 16;
        } catch (error) {
            this.snackService.displaySnack({ translationKey: 'error.could-not-get-location' }, SnackbarColor.warn);
        }
    }

    private goToLocation(newCenter: google.maps.LatLngLiteral): void {
        this.ngZone.run(() => {
            this.center = newCenter;
            this.zoom = 16;
        });
    }
}
