<div [ngSwitch]="state"
     [ngClass]="state"
     class="salary-supplements-modal">

    <!-- LOADING STATE -->
    <div class="loading h-100 d-flex align-items-center justify-content-center"
         *ngSwitchCase="State.LOADING">
        <i *ngIf="supplements$ | loading"
           class="fad fa-spin fa-spinner-third"></i>
    </div>

    <!-- EMPTY STATE (no supplements) -->
    <div class="empty h-100 d-flex flex-column justify-content-center align-items-center"
         *ngSwitchCase="State.EMPTY">
        <img src="assets/images/loentillaeg.svg">
        <div class="title">{{ 'modal.pro-shop.salary-supplements' | translate }}</div>
        <div class="description">{{ 'modal.pro-shop.salary-supplements-description' | translate }}</div>
        <ui-button-action (click)="editSupplement()">
            {{ 'modal.salary-supplements.new-supplement' | translate }}
        </ui-button-action>
    </div>

    <!-- LIST STATE -->
    <div class="list"
         *ngSwitchCase="State.LIST">
        <div class="title">{{ 'salary-supplements.active-supplements' | translate }}</div>

        <overlay-scrollbars class="scroll-area">
            <!-- The weekday supplements -->
            <ng-container *ngTemplateOutlet="supplementRowTemplate, context: {
                                                 'daysTemplate': weekdaysTemplate,
                                                 'supplements': weekdaySupplements$ | async}"></ng-container>
            <ng-template #weekdaysTemplate
                         let-supplement="supplement">
                <div class="supplement-weekdays d-flex">
                    <div *ngFor="let weekday of weekdays"
                         class="supplement-weekday"
                         [class.active]="supplement.weekdays[weekday]">
                        {{ weekday | translate | capitalize | slice : 0 : 1 }}
                    </div>
                </div>
            </ng-template>

            <!-- The holiday supplements -->
            <ng-container *ngTemplateOutlet="supplementRowTemplate, context: {
                                                 'daysTemplate': holidaysTemplate, 
                                                 'supplements': holidaySupplements$ | async}"></ng-container>
            <ng-template #holidaysTemplate
                         let-supplement="supplement">
                <div class="supplement-holidays d-flex">
                    <div class="supplement-holiday active"> {{ 'shared.holidays' | translate }} </div>
                </div>
            </ng-template>
        </overlay-scrollbars>

        <!-- New supplement button -->
        <ui-button-standard (click)="editSupplement()"
                            color="primary"
                            [transparent]="true">
            {{ 'modal.salary-supplements.new-supplement' | translate }}
        </ui-button-standard>
    </div>

    <!-- EDIT STATE -->
    <div class="edit"
         *ngSwitchCase="State.EDIT">
        <app-edit-salary-supplement [supplement]="supplementUnderEdit"
                                    (done)="editDone()"></app-edit-salary-supplement>
    </div>
</div>

<!-- Supplement row template -->
<ng-template #supplementRowTemplate
             let-days="daysTemplate"
             let-supplements="supplements">
    <div class="supplement d-flex justify-content-between"
         *ngFor="let supplement of supplements"
         (click)="editSupplement(supplement)">

        <div class="supplement-title"> {{ supplement.name }} </div>

        <ng-container *ngTemplateOutlet="days, context: {'supplement': supplement}"></ng-container>

        <div class="supplement-time">
            {{ supplement.start | minutesToTime }} - {{ supplement.end | minutesToTime }}
        </div>

        <div class="supplement-amount">
            <i class="far fa-coins"></i>
            {{'freemium.dkk' | translate: { amount: supplement.amount | number:'1.2-2' } }}
            {{ 'shared.per-hour' | translate }}
        </div>
    </div>
</ng-template>