import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { catchError, shareReplay, take, tap } from 'rxjs/operators';
import { CustomErrorCode } from '../../../../../../../functions/src/errorHandling/models';
import { routes, Routes } from '../../../../../app-settings';
import { Company, Employee } from '../../../../../core/model';
import { mapEmployeeToFirestoreEmployee } from '../../../../../core/model/Firestore';
import { DEFAULT_LANGUAGE, isLanguage, Language } from '../../../../../core/model/UserSettings';
import { AuthService, AuthUser, CompanyService } from '../../../../../core/services';
import { CloudFunctionsService } from '../../../../../core/services/cloud-functions.service';
import { SnackbarColor, SnackbarService } from '../../../../../core/services/snackbar/snackbar.service';
import { CustomValidators, splitName } from '../../../../../shared/utilities/FormUtils';

@Component({
    selector: 'app-signup',
    templateUrl: './signup.component.html',
    styleUrls: ['./signup.component.scss'],
})
export class SignupComponent implements OnInit {
    public formGroup: FormGroup;
    public company$: Observable<Company>;
    public loading: boolean;
    public routes: Routes = routes;

    constructor(
        private route: ActivatedRoute,
        private companyService: CompanyService,
        private authService: AuthService,
        private cloudFunctionService: CloudFunctionsService,
        private snackService: SnackbarService,
        private router: Router,
        private translateService: TranslateService,
    ) {
    }

    public ngOnInit(): void {
        // If user is logged in, log out and reload
        this.authService.getUser().pipe(take(1)).toPromise().then((user: AuthUser | null) => {
            if (user) this.authService.logout().then(() => window.location.reload(true));
        });

        const { companyID, name, phone, email }: Params = this.route.snapshot.queryParams;
        if (!companyID) throw new Error('Trying to signup employee without a company id parameter');

        this.company$ = this.companyService.getCompany(companyID).pipe(
            catchError(() => { throw new Error('Trying to signup employee in non-existing company with id ' + companyID); }),
            shareReplay(1),
            tap((company: Company) => {
                const langParam = this.route.snapshot.queryParams.lang;
                if (isLanguage(langParam)) this.translateService.use(langParam);
                else if (!!company.language) this.translateService.use(company.language);
            }),
        );
        this.formGroup = this.buildFormGroup(name, phone, email);
    }

    /**
     * Handle the form submission
     */
    public async onSubmit(): Promise<void> {
        this.loading = true;
        const [employee, company, password]: [Employee, Company, string] = await this.getSignupData(this.formGroup);
        const firestoreEmployee = mapEmployeeToFirestoreEmployee(employee);
        try {
            const language: Language = this.translateService.currentLang as Language || DEFAULT_LANGUAGE;
            await this.cloudFunctionService.signupEmployee(firestoreEmployee, company, password, language);
            await this.authService.loginWithEmailPassword(employee.email, password);
            this.router.navigate([routes.employee.calendar]);
        } catch (error) {
            if (error === CustomErrorCode.InvalidRequest) {
                this.snackService.displaySnack({ translationKey: 'error.invalid-signup-data' }, SnackbarColor.warn, 10000);
            } else if (error === CustomErrorCode.UnableToCreateAuthUser) {
                this.snackService.displaySnack(
                    { translationKey: 'error.email-already-in-use', translationParams: { mail: employee.email } }
                    , SnackbarColor.warn, 10000);
            } else {
                this.snackService.displaySnack({ translationKey: 'error.unknown-signup-error' }, SnackbarColor.warn, 10000);
            }
        }
        this.loading = false;
    }

    /**
     * Builds and returns a form group, including form controls with validators.
     */
    private buildFormGroup(name?: string, phone?: string, email?: string): FormGroup {
        const NAME: FormControl = new FormControl(name || '', [Validators.required, CustomValidators.FullNameValidator]);
        const PHONE: FormControl = new FormControl(phone || '', [Validators.required, CustomValidators.PhoneValidator]);
        const EMAIL: FormControl = new FormControl(email || '', [Validators.required, Validators.email]);
        const PASSWORD: FormControl = new FormControl('', [Validators.required, CustomValidators.PasswordValidator]);

        return new FormGroup({ NAME, PHONE, EMAIL, PASSWORD });
    }

    /**
     * Extracts and prepares user input from the given formgroup
     */
    private async getSignupData(formGroup: FormGroup): Promise<[Employee, Company, string]> {
        const employee: Employee = {
            ...this.getEmptyEmployeeData(),
            ...splitName(formGroup.controls.NAME!.value),
            phone: formGroup.controls.PHONE!.value,
            email: formGroup.controls.EMAIL!.value,
        };

        const password: string = formGroup.controls.PASSWORD!.value;
        const company: Company = await this.company$.pipe(take(1)).toPromise();

        return [employee, company, password];
    }

    /**
     * Get a valid employee with no data
     */
    private getEmptyEmployeeData(): Employee {
        return {
            firstname: '', lastname: '', phone: '', email: '', id: '', CPR: '', street: '', city: '', zip: '', imageURL: '',
            isDeleted: false, roles: [], primaryDepartment: null, departments: [], hourlyWage: null, note: '',
        };
    }
}
