import { formatDate } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Injector, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { NgbDropdown, NgbPopover, NgbTypeahead, NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, merge, Observable, of, PartialObserver, ReplaySubject, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, shareReplay, startWith, switchAll, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
import { Department, Employee, getWishType, isValidShift, NewShift, Role, Shift, Wish, WishType } from '../../../../../../core/model';
import { Package } from '../../../../../../core/model/Freemium/Package';
import { UserSettings } from '../../../../../../core/model/UserSettings';
import { DateService, EmployeeService, MediaService, ShiftService, UserSettingsService } from '../../../../../../core/services';
import { DepartmentService } from '../../../../../../core/services/Department/DepartmentService.model';
import { ModalService } from '../../../../../../core/services/ModalService.model';
import { SnackbarColor, SnackbarService } from '../../../../../../core/services/snackbar/snackbar.service';
import { LazyLoadImageProviderFactory } from '../../../../../../shared/factories/LazyLoadImageHooks';
import { getFormattedShiftDuration } from '../../../../../../shared/utilities/ShiftUtils';
import { Divider, EmployeeCategory, EmployeeSource, EmployeeSourceType, EmployeeWish, FreeShift, NewEmployee } from './employee-source';

interface WishAndHours {
    wish: Wish;
    hoursThisWeek: number;
}

@Component({
    selector: 'app-day-view-shift',
    templateUrl: './day-view-shift.component.html',
    styleUrls: ['./day-view-shift.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LazyLoadImageProviderFactory(.5)],
})
export class DayViewShiftComponent implements OnInit, OnDestroy {
    get departmentsSize$(): Observable<number> {
        return this._departmentsSize$;
    }

    get activeDepartment$(): Observable<Department> {
        return this._activeDepartment$;
    }

    @Input() public shift: Shift | NewShift;
    @Input() public roles$: Observable<Role[]>;
    @Input() public wishes$: Observable<Wish[]>;
    @Input() public hoursThisWeek$?: Observable<Map<string, number>>;
    @Input() public mobileVersion: boolean;
    @Input() public weekViewPopover?: NgbPopover;
    @Input() public dismiss?: () => void;
    public wishesAndHours$: Observable<WishAndHours[]>;
    @Output() public shiftChange: EventEmitter<Shift>;
    public wishTypes: typeof WishType = WishType;
    public getWishType: (wishType: WishType) => string = getWishType;
    public isValid: (shift: Shift | NewShift) => shift is Omit<Shift, 'id'> = isValidShift;
    public commentExists: boolean;
    public durationText$: Observable<string>;
    public packages: typeof Package = Package;
    public focus$: Subject<string>;
    public click$: Subject<string>;
    public employeeSourceSubject$: Subject<Observable<EmployeeSource[]>>;
    public employeeSourceModel: EmployeeSource;
    public EmployeeSourceType: typeof EmployeeSourceType = EmployeeSourceType;
    @ViewChild('typeaheadInstance', { static: true }) public typeaheadInstance: NgbTypeahead;
    private _activeDepartment$: Observable<Department>;
    private _departmentsSize$: Observable<number>;
    @ViewChild('dropdown') private dropdown: NgbDropdown;
    @ViewChild('typeaheadInputField') private typeaheadInputFieldRef: ElementRef<HTMLInputElement>;

    constructor(
        private shiftService: ShiftService,
        private translateService: TranslateService,
        private snackService: SnackbarService,
        public employeeService: EmployeeService,
        public mediaService: MediaService,
        public dateService: DateService,
        private injector: Injector,
        private cdf: ChangeDetectorRef,
        private modalService: ModalService,
        private departmentService: DepartmentService,
        private userSettingsService: UserSettingsService,
    ) {
        this.shiftChange = new EventEmitter();
        this.focus$ = new Subject<string>();
        this.click$ = new Subject<string>();
    }
    public ngOnDestroy(): void {
        if (this.weekViewPopover) this.saveShift();
    }

    public ngOnInit(): void {
        this.setInitialRole();
        this.commentExists = !!this.shift.comment;
        this._activeDepartment$ = this.departmentService.getActiveDepartment();
        this._departmentsSize$ = this.departmentService.getDepartments().pipe(map((departments: Department[]) => departments.length));
        this.updateWishesAndHoursObservable();
        this.employeeSourceSubject$ = new ReplaySubject();
        this.updateEmployeeSource();

        this.durationText$ = this.getDurationText();
    }

    /**
     * Saves the shift
     */
    public async saveShift(): Promise<void> {
        const emitOrLogObserver: PartialObserver<Shift> = {
            next: (shiftResponse: Shift): void => this.shiftChange.emit(shiftResponse),
            error: (error: Error) => {
                this.snackService.displaySnack({ translationKey: 'error.unable-to-save-shift' }, SnackbarColor.warn);
                throw error;
            },
        };

        if (isValidShift(this.shift)) {
            if (!this.shift.id) {
                this.shiftService.createShift(this.shift)
                    .pipe(take(1))
                    .subscribe(emitOrLogObserver);
            } else {
                this.shiftService.updateShift(this.shift)
                    .pipe(take(1))
                    .subscribe(emitOrLogObserver);
            }
            this.userSettingsService.saveSetting(UserSettings.LAST_USED_TIMESPAN, {
                startHour: this.shift.start.getHours(),
                startMinute: this.shift.start.getMinutes(),
                endHour: this.shift.end.getHours(),
                endMinute: this.shift.end.getMinutes(),
            })
                .pipe(take(1))
                .subscribe();
        }
        MediaService.detectChanges(this.cdf);
    }

    /**
     * Duplicates the shift, removes the assigned employee and adds it to the permanent storage
     * @param shift The shift to duplicate
     */
    public duplicateShift(shift: Shift | NewShift): void {
        if (!isValidShift(shift)) return;
        const copy: Omit<Shift, 'id'> = {
            role: shift.role,
            start: shift.start,
            end: shift.end,
            isForSale: false,
            employee: null,
            approved: false,
            released: false,
            exportLogs: [],
        };
        this.shiftService.createShift(copy).pipe(take(1)).subscribe({
            error: async (error: Error): Promise<void> => {
                this.snackService.displaySnack({ translationKey: 'error.unable-to-duplicate-shift' }, SnackbarColor.warn);
                throw error;
            },
        });
        if (!this.mediaService.isBigScreen()) this.modalService.closeAll();
        if (this.dismiss) this.dismiss();
    }

    /**
     * Deletes the shift
     * @param shift The shift to delete
     */
    public deleteShift(shift: Shift | NewShift): void {
        if (this.isValid(shift)) {
            this.shiftService.deleteShift(shift)
                .pipe(take(1))
                .subscribe({
                    error: async (error: Error): Promise<void> => {
                        this.snackService.displaySnack({ translationKey: 'error.unable-to-delete-shift' }, SnackbarColor.warn);
                        throw error;
                    },
                });
        } else {
            this.shiftChange.emit();
        }
        if (!this.mediaService.isBigScreen()) this.modalService.closeAll();
        if (this.dismiss) this.dismiss();
    }

    /**
     * Sets the role of the shift based on the role param
     */
    public setRole(shift: Shift | NewShift, role: Role): void {
        shift.role = role;
        if (shift.employee && !shift.employee.roles.find((newEmpRole: Role) => newEmpRole.id === role.id)) shift.employee = null;
        this.userSettingsService.saveSetting(UserSettings.LATEST_SELECTED_ROLE, role.id)
            .pipe(take(1))
            .subscribe();
        this.saveShift();
        this.updateWishesAndHoursObservable();
        this.updateEmployeeSource();
    }

    /**
     * Sets the employee of the shift based on employee param
     */
    public async setEmployee(shift: Shift | NewShift, employee: Employee | null): Promise<void> {
        // If not changing the employee, return
        if (shift.employee?.id === employee?.id) return;

        // Update the employee locally
        shift.employee = employee;

        if (employee && isValidShift(shift)) {
            const overlappingShifts: boolean = !(await this.shiftService.noOverlappingShifts(shift, employee).pipe(take(1)).toPromise());
            if (overlappingShifts) {
                this.displayErrorSnack(employee, shift, 'error.double-shift');
            } else {
                // When looking for violations of the 11 hour rule, we need to look at the given shift ±35 hours
                const hourRangeForOverlapSearch = 35;
                const shiftsViolating11HoursRule: boolean = !(await this.shiftService.noOverlappingShifts(shift, employee,
                    hourRangeForOverlapSearch, ShiftService.violates11HoursRule).pipe(take(1)).toPromise());
                if (shiftsViolating11HoursRule) this.displayErrorSnack(employee, shift, 'error.exceeded-11-hour-rule');
            }
        }

        // Send the shift to db
        this.saveShift();
    }

    /**
     * Adds sender and time to the shift's comment and saves it
     * @param message The comment message
     */
    public saveComment(event: Event): void {
        const message = (event.target as HTMLTextAreaElement).value;
        this.employeeService.getAuthenticatedEmployee().pipe(take(1)).toPromise()
            .then((loggedInEmp: Employee) => {
                this.shift.comment = message ? {
                    message,
                    sender: loggedInEmp,
                    time: new Date(),
                } : undefined;
                this.saveShift();
            })
            .catch(async (error: Error) => {
                this.snackService.displaySnack({ translationKey: 'error.unable-to-save-comment' }, SnackbarColor.warn);
                throw error;
            });
    }

    public onInputComment(event: Event): void {
        this.commentExists = !!((event.target as HTMLTextAreaElement).value);
    }

    /**
     * Opens the modal for editing departments.
     */
    public async openEditDepartmentModal(): Promise<void> {
        this.dropdown?.close();
        this.weekViewPopover?.close();
        const department = await this.departmentService.getActiveDepartment().pipe(take(1)).toPromise();
        this.modalService.openEditDepartmentModal(department);
    }

    public search = (text$: Observable<string>): Observable<EmployeeSource[]> => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const clicksWithClosedPopup$ = this.click$.pipe(filter(() => !this.typeaheadInstance.isPopupOpen()));
        const inputFocus$ = this.focus$;

        return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$)
            .pipe(
                withLatestFrom(this.employeeSourceSubject$.asObservable().pipe(switchAll())),
                map(([term, sources]: [string, EmployeeSource[]]) => {
                    if (term === '') return sources;
                    sources = sources
                        .filter((source: EmployeeSource) =>
                            (
                                source.type === EmployeeSourceType.Wish
                                && !!source.name
                                && source.name.toLowerCase().indexOf(term.toLowerCase()) > -1
                            )
                            || source.type === EmployeeSourceType.Divider
                            || source.type === EmployeeSourceType.NewEmployee,
                        );

                    const secondaryEmployee: EmployeeSource | undefined = sources.find((source: EmployeeSource) => {
                        return source.employeeCategory === EmployeeCategory.SecondaryEmployee;
                    });

                    sources = !secondaryEmployee
                        ?
                        sources
                            .filter((source: EmployeeSource) => source.employeeCategory !== EmployeeCategory.SecondaryDivider)
                        : sources;

                    const withoutRoleInDepartmentEmployee: EmployeeSource | undefined = sources.find((source: EmployeeSource) => {
                        return source.employeeCategory === EmployeeCategory.WithoutRoleInDepartmentEmployee;
                    });

                    sources = !withoutRoleInDepartmentEmployee
                        ?
                        sources
                            .filter((source: EmployeeSource) => source.employeeCategory !== EmployeeCategory.WithoutRoleInDepartmentDivider)
                        : sources;

                    return sources;
                }),
            );
    }

    public formatter = (): string => '';

    public onSelectItem = async (event: NgbTypeaheadSelectItemEvent): Promise<void> => {
        const source: EmployeeSource = event.item as EmployeeSource;
        const type: string = source.type;
        let employee: Employee | null = null;
        switch (type) {
            case EmployeeSourceType.Wish:
                const data: WishAndHours = source.data as WishAndHours;
                employee = data.wish.employee;
                this.assignRole(employee);
                this.setEmployee(this.shift, employee);
                this.typeaheadInputFieldRef.nativeElement.blur();
                break;
            case EmployeeSourceType.FreeShift:
                this.setEmployee(this.shift, null);
                this.typeaheadInputFieldRef.nativeElement.blur();
                break;
            case EmployeeSourceType.NewEmployee:
                this.weekViewPopover?.close();
                this.modalService.openInviteModal();
        }
    }

    public onClickArrowDown = (): void => {
        this.typeaheadInputFieldRef.nativeElement.focus();
    }

    public onFocus = (event: Event): void => {
        this.focus$.next((event.target as HTMLInputElement).value);
    }

    public onClick = (event: Event): void => {
        this.click$.next((event.target as HTMLInputElement).value);
    }

    public onShiftTimeChange(): void {
        if (!this.weekViewPopover) this.saveShift();
        else this.durationText$ = this.getDurationText();
    }

    private getDurationText(): Observable<string> {
        return this.shiftChange.pipe(
            startWith(this.shift),
            switchMap(() => getFormattedShiftDuration(this.injector, this.shift)),
            tap(() => MediaService.detectChanges(this.cdf)));
    }

    /**
     *  Assigns the shift role to an employee and displays a snackbar
     */
    private assignRole = (employee: Employee): void => {
        const shiftRole: Role | undefined = this.shift.role;
        if (!!shiftRole && employee.roles.map((role: Role) => role.id).indexOf(shiftRole.id) === -1) {
            const rolesCopy: Role[] = employee.roles.slice();
            rolesCopy.push(shiftRole);
            const employeeCopy: Employee = { ...employee, roles: rolesCopy };
            combineLatest([
                this.employeeService.updateEmployee(employeeCopy), this.departmentsSize$])
                .pipe(take(1))
                .subscribe((
                    [, departmentsSize]: [Employee, number]) => {
                    const translation = {
                        translationKey: departmentsSize > 1 ? 'roles.role-assigned-in-department' : 'roles.role-assigned',
                        translationParams: {
                            employeeFirstname: employee.firstname,
                            roleName: shiftRole.name,
                            roleDepartmentName: shiftRole.department.name,
                        },
                    };
                    this.snackService.displaySnack(translation, SnackbarColor.primary);

                });
        }
    }

    /**
     * Updates employee sources for employees typeahead popover
     */
    private updateEmployeeSource = (): void => {
        const employeeSource$: Observable<EmployeeSource[]> = combineLatest(
            [
                this.getEmployeesWithRoleInDepartment(),
                this.getEmployeesWithoutRoleInDepartment(),
                this.getSecondaryEmployeesDivider(),
                this.getEmployeesWithoutRoleInDepartmentDivider(),
            ])
            .pipe(
                map((
                    [
                        employeesWithRole,
                        employeesWithoutRole,
                        secondaryEmployeesDivider,
                        employeesWithoutRoleInDepartmentDivider,
                    ]
                        :
                        [
                            EmployeeSource[][],
                            EmployeeSource[],
                            EmployeeSource,
                            EmployeeSource,
                        ],
                ) => {
                    const primaryEmployees: EmployeeSource[] | undefined = employeesWithRole[0];
                    const secondaryEmployees: EmployeeSource[] | undefined = employeesWithRole[1];
                    return [new FreeShift()]
                        .concat(primaryEmployees ?? [])
                        .concat(!!secondaryEmployees && secondaryEmployees.length > 0 ? [secondaryEmployeesDivider] : [])
                        .concat(secondaryEmployees ?? [])
                        .concat(employeesWithoutRole.length > 0 ? [employeesWithoutRoleInDepartmentDivider] : [])
                        .concat(employeesWithoutRole)
                        .concat([new NewEmployee()]);
                }),
            );

        this.employeeSourceSubject$.next(employeeSource$);
    }

    /**
     * Displays a snack warning the user of a violation of the 11 hour rule
     */
    private displayErrorSnack(employee: Employee, shift: Shift, translationKey: string): void {
        this.snackService.displaySnack({
            translationKey,
            translationParams: {
                name: `${ employee.firstname } ${ employee.lastname }`,
                date: formatDate(shift.start, 'mediumDate', this.translateService.currentLang),
            },
        }, SnackbarColor.warn, 7000);
    }

    /**
     * Updates the wishes observable and finds wishes that has the same role as the selected shift
     */
    private updateWishesAndHoursObservable(): void {
        const shiftRole: Role | undefined = this.shift.role;

        this.wishesAndHours$ = combineLatest([
            this.wishes$.pipe(
                map((wishes: Wish[]) => shiftRole
                    ? wishes.filter((wish: Wish) => wish.employee.roles.find((role: Role) => role.id === shiftRole.id))
                    : wishes),
                shareReplay({ bufferSize: 1, refCount: true }),
            ),
            this.hoursThisWeek$ || of(new Map()),
        ]).pipe(
            map(([wishes, hours]: [Wish[], Map<string, number>]) => wishes.map(
                (wish: Wish) => ({
                    wish,
                    hoursThisWeek: hours.get(wish.employee.id) || 0,
                }))),
            shareReplay({ bufferSize: 1, refCount: true }),
        );
    }

    /**
     *  Creates divider element for secondary employees with role in the department
     */
    private getSecondaryEmployeesDivider = async (): Promise<EmployeeSource> => {
        return new Divider({
            translationKey: 'shared.secondary-employees',
        }, EmployeeCategory.SecondaryDivider);
    }

    /**
     *  Creates divider element for employees without role in the department
     */
    private getEmployeesWithoutRoleInDepartmentDivider = (): Observable<EmployeeSource> => {
        return combineLatest([this.departmentsSize$, this.activeDepartment$])
            .pipe(
                map(([size, activeDepartment]: [number, Department]) =>
                    new Divider({
                        translationKey: (size === 1) ? 'shared.employees-without-role' : 'shared.employees-without-role-in',
                        translationParams: {
                            roleName: this.shift.role!.name,
                            departmentName: activeDepartment.name,
                        },
                    },
                        EmployeeCategory.WithoutRoleInDepartmentDivider),
                ),
            );
    }

    /**
     * Sets initial role for newly created shift
     */
    private setInitialRole(): void {
        if (!isValidShift(this.shift)) {
            combineLatest([
                this.roles$,
                this.userSettingsService.loadSetting(UserSettings.LATEST_SELECTED_ROLE),
            ]).pipe(take(1)).toPromise()
                .then(([roles, latestRoleId]: [Role[], string]) => {
                    const latestRole: Role | undefined = roles.find((role: Role) => role.id === latestRoleId);
                    this.setRole(this.shift, latestRole || roles[0]!);
                    MediaService.detectChanges(this.cdf);
                });
        }
    }

    /**
     * Gets employees who have the role in the department
     */
    private getEmployeesWithRoleInDepartment = (): Observable<EmployeeSource[][]> => {
        const employeeSources: EmployeeSource[][] = [];
        return combineLatest([this.activeDepartment$, this.wishesAndHours$])
            .pipe(
                map(([activeDepartment, wishesAndHours]: [Department, WishAndHours[]]) => {
                    const wishesAndHoursPrimary: WishAndHours[] = wishesAndHours.filter((item: WishAndHours) =>
                        item.wish.employee.primaryDepartment?.id === activeDepartment.id);
                    const wishesAndHoursSecondary: WishAndHours[] = wishesAndHours.filter((item: WishAndHours) =>
                        item.wish.employee.primaryDepartment?.id !== activeDepartment.id);
                    employeeSources.push(wishesAndHoursPrimary.map((item: WishAndHours) => this.mapWishAndHoursToEmployeeWish(item, EmployeeCategory.PrimaryEmployee)));
                    employeeSources.push(wishesAndHoursSecondary.map((item: WishAndHours) => this.mapWishAndHoursToEmployeeWish(item, EmployeeCategory.SecondaryEmployee)));
                    return employeeSources;
                }),
            );
    }

    /**
     * the rest of the employees in the company
     */
    private getEmployeesWithoutRoleInDepartment = (): Observable<EmployeeSource[]> => {
        const shiftRole: Role | undefined = this.shift.role;

        return combineLatest([
            this.wishes$.pipe(
                map((wishes: Wish[]) => shiftRole
                    ? wishes.filter((wish: Wish) => wish.employee.roles.map((role: Role) => role.id).indexOf(shiftRole.id) === -1)
                    : wishes,
                ),
                shareReplay({ bufferSize: 1, refCount: true }),
            ),
            this.hoursThisWeek$ || of(new Map()),
        ]).pipe(
            map(([wishes, hours]: [Wish[], Map<string, number>]) => wishes.map(
                (wish: Wish) => ({
                    wish,
                    hoursThisWeek: hours.get(wish.employee.id) || 0,
                }))),
            map((wishesAndHours: WishAndHours[]) => {
                return wishesAndHours.map((item: WishAndHours) =>
                    this.mapWishAndHoursToEmployeeWish(item, EmployeeCategory.WithoutRoleInDepartmentEmployee));
            }),
            shareReplay({ bufferSize: 1, refCount: true }),
        );
    }

    /**
     * maps a wishAndHours object to an EmployeeWish object
     * @param wishAndHours The wishAndHours object that we want to map to an EmployeeWish
     * @param category The category to which the employee wish belongs
     */
    private mapWishAndHoursToEmployeeWish = (wishAndHours: WishAndHours, category: EmployeeCategory): EmployeeWish => {
        return new EmployeeWish({ wish: wishAndHours.wish, hoursThisWeek: wishAndHours.hoursThisWeek }, category);
    }
}
