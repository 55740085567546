<div class="team-list">
    <app-header>
        <div class="center">
            <ui-search-field [placeholderText]="'shared.search-employee' | translate | capitalize"
                             [searchStringBuilder]="searchOn"
                             [searchList]="employees$ | async"
                             (filteredList)="filteredEmployees = $event">
            </ui-search-field>
        </div>
    </app-header>
    <overlay-scrollbars class="profile-card-wrapper"
                        *ngIf="user$ | async as user">
        <ng-container *ngIf="user?.admin; else employeeView">
            <ui-button-standard class="invite-team-button"
                                color="primary"
                                wide="true"
                                (click)="openInviteModal()">{{ 'shared.invite-team' | translate }}</ui-button-standard>
            <div class="profile-card-container"
                 *ngFor="let employee of filteredEmployees">
                <ui-profile-card [imageURL]="employee.imageURL"
                                 [title]="employee.firstname + ' ' + employee.lastname"
                                 [subtitle]="employee.primaryDepartment?.name || 'admin.department.no-primary-department' | translate | capitalize"
                                 [class.no-department]="!employee.primaryDepartment"
                                 (click)="employeeClicked.emit(employee)">

                    <i class="fas fa-phone"
                       *ngIf="employee.phone; else phoneDisabled"
                       (click)="openContactEmployeeModal(employee, $event)">
                    </i>

                    <ng-template #phoneDisabled>
                        <i class="fas fa-phone disabled"></i>
                    </ng-template>
                </ui-profile-card>
            </div>
        </ng-container>
    </overlay-scrollbars>

    <ng-template #employeeView>
        <div class="profile-card-container"
             *ngFor="let employee of filteredEmployees; trackBy: trackEmployee">
            <ui-profile-card [imageURL]="employee.imageURL"
                             [title]="employee.firstname + ' ' + employee.lastname"
                             [subtitle]="outputRoles(employee.roles) | async">

                <i class="fas fa-phone"
                   *ngIf="employee.phone; else phoneDisabled"
                   (click)="openContactEmployeeModal(employee, $event)">
                </i>
                <ng-template #phoneDisabled>
                    <i class="fas fa-phone disabled"></i>
                </ng-template>
            </ui-profile-card>
        </div>
    </ng-template>
</div>