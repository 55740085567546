import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    templateUrl: './danloen-integration-help-modal.component.html',
    styleUrls: ['./danloen-integration-help-modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DanloenIntegrationHelpModalComponent {

    constructor(public modalRef: MatDialogRef<DanloenIntegrationHelpModalComponent>) { }

}
