import { Component } from '@angular/core';
import { ButtonDirective } from '../button/button.directive';

@Component({
    selector: 'ui-button-pro',
    templateUrl: './button-pro.component.html',
    styleUrls: ['./button-pro.component.scss'],
})

export class ButtonProComponent extends ButtonDirective { }
