<div class="top-nav-container d-flex flex-row justify-content-between align-items-center">
    <div class="left d-flex flex-row align-items-center">
        <ng-container *ngIf="(selectedModule$ | async) as selectedModule">
            <div class="module-dropdown"
                 ngbDropdown>
                <div class="module-dropdown-button"
                     ngbDropdownToggle>
                    {{('modules.' + selectedModule) | translate}} <i class="fas fa-caret-down"></i>
                </div>
                <div class="module-dropdown-menu"
                     ngbDropdownMenu>
                    <div class="module-dropdown-item d-flex flex-row align-items-center"
                         ngbDropdownItem
                         [routerLink]="routes.admin.schedule">
                        <div class="icon-container d-flex flex-row align-items-center justify-content-center"><i
                               class="fad fa-calendar"></i></div>
                        {{'modules.' + RelionModule.SCHEDULE | translate }}
                    </div>
                    <div class="module-dropdown-item d-flex flex-row align-items-center"
                         ngbDropdownItem
                         [routerLink]="routes.admin.salary">
                        <div class="icon-container d-flex flex-row align-items-center justify-content-center"><i
                               class="fad fa-coins"></i></div>
                        {{'modules.' + RelionModule.SALARY | translate }}
                    </div>
                </div>
            </div>
            <ng-container [ngSwitch]="subscriptionStatus$ | async">
                <ui-button-text *ngSwitchCase="SubscriptionStatus.TRIAL_AVAILABLE"
                                (click)="modalService.openProShopOverlay()">

                    <app-upgrade-arrow [alwaysShow]="true"></app-upgrade-arrow>
                    <span>{{ 'freemium.try-free'| translate }}</span>
                </ui-button-text>
                <ui-button-text *ngSwitchCase="SubscriptionStatus.TRIAL_ACTIVE"
                                (click)="modalService.openProUpgradeOverlay()">
                    <app-upgrade-arrow [alwaysShow]="true"></app-upgrade-arrow>
                    <span *ngIf="(trialExpiration$ | async) as trialExpiration">
                        {{('freemium.remaining' | translate: {remaining: (trialExpiration | amTimeAgo: true)}) + ' - ' + ('freemium.upgrade-now' | translate) }}
                    </span>
                </ui-button-text>
                <ui-button-text *ngSwitchCase="SubscriptionStatus.TRIAL_USED"
                                (click)="modalService.openProShopOverlay()">
                    <app-upgrade-arrow [alwaysShow]="true"></app-upgrade-arrow>
                    <span>{{ 'freemium.upgrade-to-pro' | translate }}</span>
                </ui-button-text>
            </ng-container>
        </ng-container>
    </div>
    <div class="right d-flex flex-row align-items-center">
        <ui-button-icon [basic]="true"
                        [transparent]="true"
                        (click)="modalService.openTeamModal()"
                        [appHelpOverlay]="'tips.invite' | translate"
                        [appHelpOverlayPosition]="'left'"
                        [appHelpOverlayIcon]="'far fa-arrow-right'"
                        [appHelpOverlayEnabled]="showInviteTip$ | async"
                        [matTooltip]="'tool-tips.top-nav.employees' | translate">
            <i class="far fa-user-friends"></i></ui-button-icon>
        <ui-button-icon [basic]="true"
                        [transparent]="true"
                        (click)="openNotificationsDrawer()"
                        [matTooltip]="'tool-tips.top-nav.notifications' | translate">
            <i class="far fa-bell"></i>
            <div class="badge"
                 *ngIf="notifications$ | async"></div>
        </ui-button-icon>
        <ui-button-icon [basic]="true"
                        [transparent]="true"
                        (click)="openSettingsModal()"
                        [matTooltip]="'tool-tips.top-nav.settings' | translate"><i class="far fa-cog"></i></ui-button-icon>
        <div class="profile d-flex align-items-center justify-content-center"
             (click)="openSideDrawer()"
            [matTooltip]="'tool-tips.top-nav.profile' | translate">
            <div class="profile-border"></div>
            <img *ngIf="(employee$ | async) as employee"
                 [src]="employee.imageURL">
            <div class="placeholder"
                 *ngIf="!(employee$ | async)"> </div>
        </div>
    </div>
</div>
