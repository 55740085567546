import { Injectable } from '@angular/core';
import { QueryDocumentSnapshot, QuerySnapshot } from '@angular/fire/firestore';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import * as moment from 'moment';
import { combineLatest, Observable, of, timer } from 'rxjs';
import { debounceTime, map, pluck, shareReplay, startWith, switchMap, take, tap } from 'rxjs/operators';
import { subscriptionsCollectionPath as collectionPath } from '../../../model/Firestore/FirestoreConstants';
import { FirestoreSubscriptionDetails } from '../../../model/Firestore/FirestoreSubscriptionDetails';
import Timestamp from '../../../model/Firestore/FirestoreTimestamp';
import { TRIAL_DURATION_DAYS } from '../../../model/Freemium';
import { Package } from '../../../model/Freemium/Package';
import { SubscriptionDetails } from '../../../model/Freemium/SubscriptionDetails';
import { Subscriptions } from '../../../model/Freemium/Subscriptions';
import { SubscriptionStatus } from '../../../model/SubscriptionStatus';
import { Auth, FirestoreService } from '../../firestore.service';
import { ConfigurationService } from '../configuration-service.model';

type ProPackages = {
    [K in Package]?: boolean
};

@Injectable({
    providedIn: 'root',
})
export class FirestoreConfigurationService extends ConfigurationService {
    private cache: Observable<Subscriptions>;
    private OneHourInMilliseconds: number = 60 * 60 * 1000;

    constructor(
        private firestore: FirestoreService,
        private gtmService: GoogleTagManagerService,
    ) {
        super();
        // Get packages to fill the cache
        this.getProPackages().pipe(take(1)).toPromise();
    }

    /**
     * Returns an observable describing whether the given package is pro or not
     * If no package is supplied, returns an observable describing whether any package is pro
     */
    public isPro(packageType?: Package): Observable<boolean> {
        // If package param is supplied, return pro status of that package
        if (packageType !== undefined) {
            return combineLatest(
                timer(0, this.OneHourInMilliseconds),
                this.getExpireTimer(packageType))
                .pipe(switchMap(() => this.isProPackage(packageType)));
        }

        // Otherwise, return if company has any pro package
        return timer(0, this.OneHourInMilliseconds).pipe(
            switchMap(() => combineLatest(Object.values(Package).map((pack: Package) => this.isProPackage(pack)))),
            map((proPackages: boolean[]) => !!proPackages.find((pro: boolean) => pro)),
        );
    }

    public getSubscriptions(): Observable<Subscriptions> {
        if (this.cache) return this.cache;
        const subscriptions: Observable<Subscriptions> = this.firestore.authenticate<FirestoreSubscriptionDetails>(collectionPath)
            .pipe(switchMap((auth: Auth<FirestoreSubscriptionDetails>) =>
                this.firestore.observeOnSnapshot(auth.collection)),
                map(this.mapSubscriptions, this),
            );
        this.cache = subscriptions.pipe(shareReplay(1));
        return subscriptions;
    }

    /**
     * Returns an observable of the expiration date of the trial of the given package.
     * If no trial has been started, null is returned.
     */
    public trialExpiration(packageType: Package): Observable<Date | null> {
        return this.getSubscriptions().pipe(
            map((subscription: Subscriptions) => {
                const freeTrialStartedOn = subscription[packageType]?.freeTrialStartedOn;
                if (!freeTrialStartedOn) return null;
                const trialExpiration = moment(freeTrialStartedOn).add(TRIAL_DURATION_DAYS, 'days').toDate();
                return trialExpiration;
            }));
    }

    public async startTrial(packageType: Package): Promise<void> {
        const trialExpiration: Date | null = await this.trialExpiration(packageType).pipe(take(1)).toPromise();

        if (trialExpiration) throw Error('Trial has already been started');

        const trialStartedOn: Date = new Date();

        const activeUntil = moment(trialStartedOn).add(TRIAL_DURATION_DAYS, 'days').toDate();
        const freeTrialStartedOn = trialStartedOn;
        if (!activeUntil) throw Error('Falsy active until');

        const firestoreTrialData: Partial<FirestoreSubscriptionDetails> = {
            activeUntil: Timestamp.fromDate(activeUntil),
            freeTrialStartedOn: Timestamp.fromDate(freeTrialStartedOn),
        };

        return this.firestore.authenticate<FirestoreSubscriptionDetails>(collectionPath).pipe(
            take(1),
            tap((auth: Auth<FirestoreSubscriptionDetails>) =>
                this.gtmService.pushTag({
                    event: 'startTrial',
                    companyID: auth.user.companyID,
                })),
            switchMap((auth: Auth<FirestoreSubscriptionDetails>) =>
                auth.collection.doc(packageType).set(firestoreTrialData as FirestoreSubscriptionDetails, { merge: true })),
        ).toPromise();
    }

    /**
     * Get the subcription status of a given package
     * @param proPackage The package to get the status for
     */
    public getSubscriptionStatus(proPackage: Package): Observable<SubscriptionStatus> {
        return combineLatest([
            this.isPro(proPackage),
            this.freeTrialAvailable(proPackage),
            this.trialExpiration(proPackage),
            this.getSubscriptions().pipe(pluck(proPackage)),
            this.getExpireTimer(proPackage),
        ]).pipe(map(([isPro, trialAvailable, trialExpiration, subscriptionDetails, _]
            : [boolean, boolean, Date | null, SubscriptionDetails, number | null]) => {
            if (trialAvailable && !isPro) return SubscriptionStatus.TRIAL_AVAILABLE;
            if (!trialAvailable && !isPro) return SubscriptionStatus.TRIAL_USED;
            if (!trialAvailable && isPro
                && trialExpiration !== null && trialExpiration.getTime() > Date.now()
                && !subscriptionDetails.fenerumSubscriptionUUID) {
                return SubscriptionStatus.TRIAL_ACTIVE;
            }
            return SubscriptionStatus.PRO;
        }), debounceTime(500));
    }

    private getExpireTimer(proPackage: Package): Observable<number | null> {
        return this.trialExpiration(proPackage).pipe(
            switchMap((expiration: Date | null) => expiration ? timer(expiration) : of(null)),
            startWith(null));
    }

    /**
     * Returns a boolean describing whether the given package is pro or not
     * @param packageType The package to check for pro
     */
    private isProPackage(packageType: Package): Observable<boolean> {
        return this.getProPackages().pipe(
            map((proPackages: ProPackages) => !!proPackages[packageType]),
        );
    }

    /**
     * Gets all the subscriptions, and returns an observable of the current pro packages
     */
    private getProPackages(): Observable<ProPackages> {
        return this.getSubscriptions().pipe(map(this.mapProPackages, this));
    }

    private mapSubscriptions(snapshot: QuerySnapshot<FirestoreSubscriptionDetails>): Subscriptions {
        const convertOrReturnNull: (timestamp: Timestamp | null) =>
            Date | null = (timestamp: Timestamp | null): Date | null => timestamp ? timestamp.toDate() : null;
        return snapshot.docs.reduce((proPackages: Subscriptions, doc: QueryDocumentSnapshot<FirestoreSubscriptionDetails>) => ({
            ...proPackages,
            [doc.id]: {
                activeUntil: convertOrReturnNull(doc.data().activeUntil),
                freeTrialStartedOn: convertOrReturnNull(doc.data().freeTrialStartedOn),
                fenerumSubscriptionUUID: doc.data().fenerumSubscriptionUUID,
            },
        }), {});
    }

    /**
     * Maps the subscription details to a ProVersions object, describing the current existing subscription details in the database
     * @param subscriptions An object describing the subscriptions in the firestore
     */
    private mapProPackages(subscriptions: Subscriptions): ProPackages {
        const proPackages: ProPackages = {};
        for (const key in subscriptions) {
            if (subscriptions.hasOwnProperty(key)) {
                const element: SubscriptionDetails = subscriptions[key];
                proPackages[key] = this.isActiveSubscription(element.activeUntil);
            }
        }
        return proPackages;
    }

    /**
     * Returns a boolean describing whether the given date means it is an active subscription
     * @param activeUntil Describes the end of the current subscription
     */
    private isActiveSubscription(activeUntil?: Date | null): boolean {
        return !!activeUntil && activeUntil > new Date();
    }
}
