import { Injectable } from '@angular/core';
import * as isOnline from 'is-online';
import { Observable, timer } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class IsOnlineService {

    private isOnline$: Observable<boolean>;

    constructor() {
        this.isOnline$ = timer(10000, 5000).pipe(
            map(() => void 0),
            switchMap(isOnline),
        );
    }

    public getIsOnlineObservable(): Observable<boolean> {
        return this.isOnline$;
    }
}
