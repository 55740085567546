import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Package } from '../../model/Freemium/Package';
import { Subscriptions } from '../../model/Freemium/Subscriptions';
import { SubscriptionStatus } from '../../model/SubscriptionStatus';

export abstract class ConfigurationService {
    public abstract getSubscriptions(): Observable<Subscriptions>;
    public abstract getSubscriptionStatus(proPackage: Package): Observable<SubscriptionStatus>;
    public abstract isPro(packageType?: Package): Observable<boolean>;
    public abstract startTrial(packageType: Package): Promise<void>;
    public abstract trialExpiration(packageType: Package): Observable<Date | null>;
    public freeTrialAvailable(packageType: Package): Observable<boolean> {
        return combineLatest([
            this.isPro(packageType),
            this.getSubscriptions(),
        ]).pipe(
            map(([isPro, subscriptions]: [boolean, Subscriptions]) => !isPro && !subscriptions[packageType]?.freeTrialStartedOn),
        );
    }
}
