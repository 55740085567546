import { Component, Input, OnInit } from '@angular/core';
import { first, take } from 'rxjs/operators';
import { Employee } from '../../../../../core/model';
import { Package } from '../../../../../core/model/Freemium/Package';
import { AuthService, AuthUser, EmployeeFile, FileService } from '../../../../../core/services';
import { ConfigurationService } from '../../../../../core/services/Configuration/configuration-service.model';
import { ModalService } from '../../../../../core/services/ModalService.model';
import { ConfirmDialogType } from '../../../../ui/confirm-dialog/ConfirmDialogType';

@Component({
    selector: 'app-employee-files',
    templateUrl: './employee-files.component.html',
    styleUrls: ['./employee-files.component.scss'],
})
export class EmployeeFilesComponent implements OnInit {
    @Input() public employee: Employee;
    public Package: typeof Package = Package;
    public files: EmployeeFile[];
    public uploading: boolean;
    private path: string;

    constructor(
        private fileService: FileService,
        private authService: AuthService,
        private configurationService: ConfigurationService,
        private modalService: ModalService,
    ) { }

    public async ngOnInit(): Promise<void> {
        const companyID: string = (await this.authService.getUser().pipe(first((user: AuthUser | null) => !!user)).toPromise())!.companyID;
        this.path = companyID + '/employees/' + this.employee.id + '/files/';

        this.refreshFileList();
    }

    /**
     * Checks that user is pro and opens the specified file url in a new tab
     * @param url The url of the file to open
     */
    public async openFile(url: string): Promise<void> {
        // If not pro, close the modal and open the Pro Feature modal
        const pro: boolean = await this.configurationService.isPro(Package.PRO).pipe(take(1)).toPromise();
        if (!pro) return this.modalService.openProShopOverlay();

        window.open(url, '_blank');
    }

    /**
     * Ask for user's confirmation and upon it, delete the file
     * @param file The file to delete
     */
    public async deleteFile(event: MouseEvent, file: EmployeeFile): Promise<void> {
        // Stop propagation to avoid also opening the file
        event.stopPropagation();

        const didConfirm: boolean = await this.confirmDeleteFile();
        if (!didConfirm) return;

        file.loading = true;
        await file.delete();
        await this.refreshFileList();
        file.loading = false;
    }

    /**
     * Check that a user is pro and upload the file if so
     * @param file The file to upload
     */
    public async fileChangeEvent(file: File, input: HTMLInputElement): Promise<void> {
        // If not pro, close the modal and open the Pro Feature modal
        const pro: boolean = await this.configurationService.isPro(Package.PRO).pipe(take(1)).toPromise();
        if (!pro) return this.modalService.openProShopOverlay();

        // Check size. If too large, show warning and stop uploading
        const sizeInMegaBytes: number = this.getFileSize(file);
        if (sizeInMegaBytes > 50) return this.openFileTooLargeWarning(sizeInMegaBytes);

        this.uploading = true;
        // Reset the value of the input to allow for new upload tries on same file
        input.value = '';

        try {
            await this.fileService.upload(this.path, file);
            await this.refreshFileList();
        } catch (error) {
            throw error;
        } finally {
            this.uploading = false;
        }
    }

    /**
     * Get all files listed under the selected employee
     */
    private async refreshFileList(): Promise<void> {
        this.files = await this.fileService.getUploadedFiles(this.path);
    }

    /**
     * Open a confirm delete file dialog
     */
    private async confirmDeleteFile(): Promise<boolean> {
        return await this.modalService.openConfirmDialog({
            titleTranslationKey: 'modal.delete-file.title',
            subtitleTranslationKey: 'modal.delete-file.subtitle',
            closeButtonTranslationKey: 'modal.delete-file.closeButton',
            acceptButtonTranslationKey: 'modal.delete-file.acceptButton',
            confirmDialogType: ConfirmDialogType.WARNING,
        }).pipe(take(1)).toPromise();
    }

    /**
     * Get size in MB of a file
     */
    private getFileSize(file: File): number {
        const sizeInBytes: number = file.size;
        const bytesPerMegaByte: number = 0.000001;
        return Math.round(sizeInBytes * bytesPerMegaByte);
    }

    /**
     * Open a warning explaining that file is too large
     * @param sizeInMegaBytes The size of the file that's too large
     */
    private openFileTooLargeWarning(sizeInMegaBytes: number): void {
        this.modalService.openConfirmDialog({
            titleTranslationKey: 'modal.file-too-large.title',
            subtitleTranslationKey: 'modal.file-too-large.subtitle',
            subtitleTranslationParams: { megabytes: sizeInMegaBytes },
            closeButtonTranslationKey: 'modal.file-too-large.acceptButton',
            confirmDialogType: ConfirmDialogType.INFO,
        }).pipe(take(1)).toPromise();
    }
}
