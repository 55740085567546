<div class="time-input-container web"
     [class.hide-past-midnight]="!!punchclock"
     *ngIf="bigScreen; else mobileTimeInput">
    <div class="from-section">
        {{'shared.from' | translate}}
        <input type="text"
               value="{{ start | date: 'HH' }}"
               onClick="this.select()"
               (input)="input($event.target.value, 'hours', fromMM)"
               [appRegexMask]="regex.hoursFrom"
               (blur)="save()"
               #fromHH>
        :
        <input type="text"
               value="{{ start | date: 'mm' }}"
               onClick="this.select()"
               (input)="input($event.target.value, 'minutes', toHH)"
               [appRegexMask]="regex.minutes"
               (blur)="save()"
               #fromMM>
    </div>

    <div>
        {{'shared.to' | translate}}
        <input type="text"
               value="{{ end | date: 'HH' }}"
               onClick="this.select()"
               (input)="input($event.target.value, 'hours', toMM)"
               [appRegexMask]="regex.hoursTo"
               (blur)="save()"
               #toHH>
        :
        <input type="text"
               value="{{ end | date: 'mm' }}"
               onClick="this.select()"
               (input)="input($event.target.value, 'minutes')"
               [appRegexMask]="regex.minutes"
               (blur)="save()"
               #toMM>
    </div>
    <div class="past-midnight"
         [style.visibility]="start && start.toDateString() !== end.toDateString() ? 'visible' : 'hidden'">
        {{ 'shared.past-midnight' | translate | capitalize}}
    </div>
</div>

<ng-template #mobileTimeInput>
    <div class="mobile form-group d-flex flex-row justify-content-center">
        <label> {{ punchclock ? ('shared.in' | translate) : ('shared.from' | translate | capitalize) }} </label>
        <input type="time"
               required
               (blur)="save()"
               value="{{ start | date: 'HH:mm' }}"
               #from>

        <label class="toText"> {{ punchclock ? ('shared.out' | translate) : ('shared.to' | translate | capitalize) }}
        </label>
        <div class="d-flex flex-column">
            <input [class.midnightInputStyling]="!((start && start.toDateString() === end.toDateString() || start === undefined))"
                   type="time"
                   required
                   (blur)="save()"
                   value="{{ end | date: 'HH:mm' }}"
                   #to>

            <div *ngIf="!((start && start.toDateString() === end.toDateString() || start === undefined))"
                 class="past-midnight">
                {{ 'shared.past-midnight' | translate | capitalize}}
            </div>
        </div>
    </div>
</ng-template>