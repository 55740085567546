import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as moment from 'moment';
import { map, take } from 'rxjs/operators';
import { Package } from '../../../../core/model/Freemium/Package';
import { SubscriptionStatus } from '../../../../core/model/SubscriptionStatus';
import { UserActivity, UserActivityService } from '../../../../core/services';
import { ConfigurationService } from '../../../../core/services/Configuration/configuration-service.model';
import { ModalService } from '../../../../core/services/ModalService.model';

type TrialJustExpired = 'trial-expired';

@Component({
    templateUrl: './pro-shop-overlay.component.html',
    styleUrls: ['./pro-shop-overlay.component.scss'],
})
export class ProShopOverlayComponent {

    public SubscriptionStatus: typeof SubscriptionStatus = SubscriptionStatus;
    public subscriptionStatus: SubscriptionStatus | TrialJustExpired;

    constructor(
        @Inject(MAT_DIALOG_DATA) subscriptionStatus: SubscriptionStatus,
        private dialogRef: MatDialogRef<ProShopOverlayComponent>,
        private modalService: ModalService,
        private userActivityService: UserActivityService,
        private configurationService: ConfigurationService,
    ) {
        this.getSubscriptionStatus(subscriptionStatus).then(
            (status: SubscriptionStatus | TrialJustExpired) => this.subscriptionStatus = status);
    }

    public upgrade(): void {
        if (this.subscriptionStatus === SubscriptionStatus.TRIAL_AVAILABLE) return this.modalService.openStartTrial();
        this.modalService.openProUpgradeOverlay(true);
    }

    public close(): void {
        if (this.subscriptionStatus === 'trial-expired') this.modalService.openConfirmGoBackToFreeOverlay();
        this.dialogRef.close();
    }

    private async getSubscriptionStatus(subscriptionStatus: SubscriptionStatus): Promise<SubscriptionStatus | TrialJustExpired> {
        // If status is not trial_used, trial's not just expired
        if (subscriptionStatus !== SubscriptionStatus.TRIAL_USED) return subscriptionStatus;

        // Get trial expiration
        const trialExpiration: Date | null = await this.configurationService.trialExpiration(Package.PRO).pipe(take(1)).toPromise();
        if (!trialExpiration) throw Error('Could not find trial expiration');

        // If the trial has expired before 14 days ago, trial's not just expired
        if (moment(trialExpiration).isBefore(moment().subtract(14, 'days'))) return subscriptionStatus;

        // Load if user already dismissed the trial expiration overlay
        const dismissedTrial: boolean = await this.userActivityService.load(UserActivity.DISMISSED_TRIAL_EXPIRATION).pipe(
            take(1), map((dismissedOn: Date | null) => !!dismissedOn)).toPromise();

        // If user's dismissed the trial expiration overlay, trial's not just expired
        if (dismissedTrial) return subscriptionStatus;

        return 'trial-expired';
    }
}
