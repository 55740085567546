import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'app-card-details-modal',
    templateUrl: './card-details-modal.component.html',
    styleUrls: ['./card-details-modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CardDetailsModalComponent implements OnInit {

    constructor(
        @Inject(MAT_DIALOG_DATA) private url: string,
        private ref: MatDialogRef<CardDetailsModalComponent>,
    ) { }

    public ngOnInit(): void {
        this.openPaymentTab();
    }

    /**
     * Opens the given url the payment info input page
     */
    public openPaymentTab(): void {
        window.open(this.url, '_blank');
    }

    /**
     * Closes the dialog and cancels the payment.
     */
    public cancel(): void {
        this.ref.close();
    }

}
