import { ChangeDetectorRef, Component, OnInit, TrackByFunction } from '@angular/core';
import { from, Observable } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { routes, Routes } from '../../../../../../../app-settings';
import { Employee, Shift } from '../../../../../../../core/model';
import { EmployeeService, MediaService, ShiftService } from '../../../../../../../core/services';
import { ModalService } from '../../../../../../../core/services/ModalService.model';

@Component({
    selector: 'app-future-shifts-widget',
    templateUrl: './future-shifts-widget.component.html',
    styleUrls: ['./future-shifts-widget.component.scss'],
})
export class FutureShiftsWidgetComponent implements OnInit {

    public futureShifts$: Observable<Shift[]>;
    public isMobile$: Observable<boolean>;
    public routes: Routes = routes;

    constructor(
        private employeeService: EmployeeService,
        private shiftService: ShiftService,
        private cdr: ChangeDetectorRef,
        private mediaService: MediaService,
        private modalService: ModalService,
    ) { }

    public ngOnInit(): void {
        this.futureShifts$ = from(this.employeeService.getAuthenticatedEmployee()).pipe(
            switchMap((employee: Employee) => this.shiftService.getFutureShiftsForEmployee(employee)),
            tap(() => setTimeout(() => MediaService.detectChanges(this.cdr), 0)),
        );
        this.isMobile$ = this.mediaService.observeMediaChanges().pipe(map((bigScreen: boolean) => !bigScreen));
    }

    public openShiftDetails(shift: Shift) {
        this.modalService.openShiftDetailModal(shift as Shift);
    }

    public trackFutureShifts: TrackByFunction<Shift> = (_index: number, futureShift: Shift) => futureShift.id;
}
