import { Component, OnInit } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { CompanySetting, CompanySettingsService } from '../../../../../../../../core/services';
import { SnackbarColor, SnackbarService } from '../../../../../../../../core/services/snackbar/snackbar.service';

@Component({
    selector: 'app-punch-times-settings',
    templateUrl: './punch-times-settings.component.html',
    styleUrls: ['./punch-times-settings.component.scss'],
})
export class PunchTimesSettingsComponent implements OnInit {
    public bufferTimes$: Observable<{ checkinBefore: number | null; checkoutAfter: number | null; }>;
    public checkinBefore: string;
    public checkoutAfter: string;

    constructor(
        private companySettingsService: CompanySettingsService,
        private snackService: SnackbarService,
    ) { }

    public ngOnInit(): void {
        this.load();
        this.bufferTimes$ = combineLatest([
            this.companySettingsService.loadSetting(
                CompanySetting.CHECKIN_BEFORE),
            this.companySettingsService.loadSetting(
                CompanySetting.CHECKOUT_UNTIL),
        ]).pipe(
            map(([checkinBefore, checkoutAfter]: [number | null, number | null]) => ({ checkinBefore, checkoutAfter })),
        );
    }

    public save(): void {
        // Convert the input values to numbers
        const checkinBefore = Number(this.checkinBefore);
        const checkoutAfter = Number(this.checkoutAfter);

        // If any is NaN, report error
        if (isNaN(checkinBefore) || isNaN(checkoutAfter)) {
            this.snackService.displaySnack({ translationKey: 'error.invalid-times' }, SnackbarColor.warn);
            return;
        }

        // Save the new times
        Promise.all([
            this.companySettingsService.saveSetting(CompanySetting.CHECKIN_BEFORE, checkinBefore),
            this.companySettingsService.saveSetting(CompanySetting.CHECKOUT_UNTIL, checkoutAfter),
        ]).catch(
            // Display error if something went wrong
            () => this.snackService.displaySnack({ translationKey: 'error.could-not-save' }, SnackbarColor.warn));
    }

    private async load(): Promise<void> {
        this.checkinBefore = await this.companySettingsService.loadSetting(
            CompanySetting.CHECKIN_BEFORE).pipe(take(1), map(String)).toPromise();
        this.checkoutAfter = await this.companySettingsService.loadSetting(
            CompanySetting.CHECKOUT_UNTIL).pipe(take(1), map(String)).toPromise();
    }
}
