import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { UserActivity, UserActivityService } from '../../../../../../core/services';
import { ModalService } from '../../../../../../core/services/ModalService.model';

@Component({
    selector: 'app-employee-signed-up-modal',
    templateUrl: './employee-signed-up-modal.component.html',
    styleUrls: ['./employee-signed-up-modal.component.scss'],
})
export class EmployeeSignedUpModalComponent {

    constructor(
        private userActivityService: UserActivityService,
        private modalRef: MatDialogRef<EmployeeSignedUpModalComponent>,
        private modalService: ModalService,
    ) { }

    public goToEmployees(): void {
        this.userActivityService.save(UserActivity.SEEN_FIRST_EMPLOYEE).subscribe();
        this.modalRef.close();
        this.modalService.openTeamModal();
    }
}
