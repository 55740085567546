import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, Observable, timer } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { Employee } from '../../../../core/model';
import { Package } from '../../../../core/model/Freemium/Package';
import { AuthService, AuthUser, EmployeeService, MediaService } from '../../../../core/services';
import { CloudFunctionsService } from '../../../../core/services/cloud-functions.service';
import { ConfigurationService } from '../../../../core/services/Configuration/configuration-service.model';
import { ModalService } from '../../../../core/services/ModalService.model';
import { SnackbarColor, SnackbarService } from '../../../../core/services/snackbar/snackbar.service';

@Component({
    selector: 'app-edit-employee-info',
    templateUrl: './edit-employee-info.component.html',
    styleUrls: ['./edit-employee-info.component.scss'],
})
export class EditEmployeeInfoComponent implements OnInit {

    @Input() public isMobile: boolean;
    @Output() public employeeDeleted: EventEmitter<Employee>;
    public Package: typeof Package = Package;
    public editEmployeeFormGroup: FormGroup;
    public editingProfile: boolean = false;
    public isEditingLoggedInUser$: Observable<boolean>;
    public bigScreen$: Observable<boolean>;
    public selectedUserAdmin: boolean | null;
    public loadingAdminState: boolean;
    public isAdmin$: Observable<boolean>;

    @Input() public set selectedEmployee(val: Employee) {
        this._selectedEmployee = val;
        this.resetForm(val);
        this.selectedUserAdmin = null;
        this.cloudFunctionService.isAdmin(val).pipe(take(1)).toPromise()
            .then((isAdmin: boolean) => this.selectedUserAdmin = isAdmin);
    }
    public get selectedEmployee(): Employee {
        return this._selectedEmployee;
    }
    @ViewChild('input') private input: ElementRef<HTMLInputElement>;
    private _selectedEmployee: Employee;

    constructor(
        public translateService: TranslateService,
        private employeeService: EmployeeService,
        private snackService: SnackbarService,
        private modalService: ModalService,
        private mediaService: MediaService,
        private cloudFunctionService: CloudFunctionsService,
        private configurationService: ConfigurationService,
        private authService: AuthService,
    ) {
        this.resetForm();
        this.employeeDeleted = new EventEmitter();
    }

    public ngOnInit(): void {
        this.isEditingLoggedInUser$ = this.employeeService.getAuthenticatedEmployee().pipe(
            map((loggedInEmployee: Employee) => loggedInEmployee.id === this.selectedEmployee.id),
        );
        this.isAdmin$ = this.authService.getUser().pipe(map((user: AuthUser) => !!user?.admin));
        this.bigScreen$ = this.mediaService.observeMediaChanges();
    }

    public async setAdmin(employee: Employee, shouldBeAdmin: boolean): Promise<void> {
        const isPro: boolean = await this.configurationService.isPro().pipe(take(1)).toPromise();
        if (!isPro) return this.modalService.openProShopOverlay();

        this.loadingAdminState = true;

        // Set and get the new user admin state
        this.selectedUserAdmin = (await combineLatest([
            this.cloudFunctionService.setAdmin(employee, shouldBeAdmin),
            timer(300), // wait at least 300ms for the css opacity transition to finish
        ]).pipe(take(1)).toPromise())[0];

        this.loadingAdminState = false;
    }

    /**
     * Resets the form, with the values from the given employee
     * @param employee An optional employee, used to set the value of the input fields in the form
     */
    public resetForm(employee?: Employee): void {
        const newName: string = employee != null ? employee.firstname.trim() + ' ' + employee.lastname.trim() : '';
        const newPhone: string = employee != null ? employee.phone : '';
        const newEmail: string = employee != null ? employee.email : '';
        const newStreet: string = employee != null ? employee.street : '';
        const newZip: string = employee != null ? employee.zip : '';
        const newCity: string = employee != null ? employee.city : '';
        const newCPR: string = employee != null ? employee.CPR : '';
        const newHourlyWage: string = employee != null && employee.hourlyWage != null ? String(employee.hourlyWage).replace('.', ',') : '';

        this.editEmployeeFormGroup = new FormGroup({
            name: new FormControl(newName, Validators.required),
            phone: new FormControl(newPhone, [Validators.required, Validators.pattern('^[0-9]{8}')]),
            email: new FormControl(newEmail, [Validators.required, Validators.email]),
            street: new FormControl(newStreet),
            zip: new FormControl(newZip, Validators.pattern('^[0-9]{4}')),
            city: new FormControl(newCity),
            cpr: new FormControl(newCPR, Validators.pattern('^[0-9]{6}-[0-9]{4}')),
            hourlyWage: new FormControl(newHourlyWage, Validators.pattern('^[0-9]+(,[0-9]{1,2})?$')),
        });
    }

    /**
     * Opens the edit profile view
     */
    public editProfile(): void {
        this.resetForm(this.selectedEmployee);
        this.editingProfile = true;
    }

    /**
     * Cancels the current edit
     */
    public cancelEdit(): void {
        this.editingProfile = false;
        this.resetForm(this.selectedEmployee);
    }

    /**
     * Saves the current edit, if the form is valid
     */
    public saveEdit(): void {
        if (this.editEmployeeFormGroup.valid) {
            const names: string[] = this.editEmployeeFormGroup.controls.name!.value.split(' ');
            const firstname: string = names[0]!;
            const lastname: string = names.splice(1).reduce((acc: string, name: string) => acc + ' ' + name, '').trim();
            this.selectedEmployee.firstname = firstname;
            this.selectedEmployee.lastname = lastname;
            this.selectedEmployee.phone = this.editEmployeeFormGroup.controls.phone!.value;
            this.selectedEmployee.email = this.editEmployeeFormGroup.controls.email!.value;
            this.selectedEmployee.street = this.editEmployeeFormGroup.controls.street!.value;
            this.selectedEmployee.zip = this.editEmployeeFormGroup.controls.zip!.value;
            this.selectedEmployee.city = this.editEmployeeFormGroup.controls.city!.value;
            this.selectedEmployee.CPR = this.editEmployeeFormGroup.controls.cpr!.value;
            const hourlyWage: string = this.editEmployeeFormGroup.controls.hourlyWage!.value;
            if (hourlyWage !== '') {
                this.selectedEmployee.hourlyWage = Number(hourlyWage.replace(',', '.'));
            } else {
                this.selectedEmployee.hourlyWage = null;
            }
            this.employeeService.updateEmployee(this.selectedEmployee).pipe(take(1)).subscribe({
                next: (updatedEmployee: Employee): void => {
                    this.selectedEmployee = updatedEmployee;
                    this.editingProfile = false;
                },
                error: (err: Error) => {
                    this.snackService.displaySnack({ translationKey: 'error.unable-to-update-employee' }, SnackbarColor.warn);
                    throw err;
                },
            });
        }
    }

    /**
     * Deletes the currently selected employee
     */
    public deleteEmployee(): void {
        this.cancelEdit();
        this.employeeDeleted.emit(this.selectedEmployee);
        this.employeeService.deleteEmployee(this.selectedEmployee).pipe(take(1)).toPromise();
    }

    /**
     * Open the ProfilePictureModal on file inputs
     * @param event The input event with a base64 encoded string of the file
     */
    public fileChangeEvent(event: Event): void {
        const base64EncodedString: { base64: string } = event as unknown as { base64: string };
        this.modalService.openProfilePictureModal(base64EncodedString, this.input.nativeElement, this.selectedEmployee);
    }
}
