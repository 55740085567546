import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FloatingLabelDirective } from './floating-label.directive';
import { ProLabelDirective } from './freemium/pro-label.directive';
import { HelpOverlayDirective } from './help-overlay.directive';
import { IfNotProDirective } from './if-pro/if-not-pro.directive';
import { IfProDirective } from './if-pro/if-pro.directive';
import { NgTypeaheadScrollDirective } from './ng-typeahead-scroll.directive';
import { RegexMaskDirective } from './regex-mask.directive';
import { SnackbarDirective } from './snackbar.directive';
import { StopPropagationDirective } from './stop-propagation.directive';
import { TrapScrollDirective } from './trap-scroll.directive';



@NgModule({
    declarations: [
        FloatingLabelDirective,
        HelpOverlayDirective,
        IfNotProDirective,
        IfProDirective,
        NgTypeaheadScrollDirective,
        ProLabelDirective,
        RegexMaskDirective,
        SnackbarDirective,
        StopPropagationDirective,
        TrapScrollDirective,
    ],
    imports: [
        CommonModule,
    ],
    exports: [
        FloatingLabelDirective,
        HelpOverlayDirective,
        IfNotProDirective,
        IfProDirective,
        NgTypeaheadScrollDirective,
        ProLabelDirective,
        RegexMaskDirective,
        SnackbarDirective,
        StopPropagationDirective,
        TrapScrollDirective,
    ],
})
export class DirectivesModule { }
