import { Observable } from 'rxjs';
import { Role } from '../../model';
import { Department } from '../../model/Department/Department.model';
import { NewRole } from '../../model/Role/NewRole';

export abstract class RoleService {
    constructor() {

    }
    /**
     * Creates a role in storage
     * @param role Role that you want to create.
     */
    public abstract createRole(role: NewRole): Observable<Role>;

    /**
     * Gets a role from the storage with the specific id.
     * @param id
     */
    public abstract getRole(id: string): Observable<Role>;

    /**
     * Gets all the roles in storage.
     */
    public abstract getRoles(params?: RoleQueryParams): Observable<Role[]>;

    /**
     * Updates a role.
     * @param role The Role you want to update.
     */
    public abstract updateRole(role: Role): Observable<Role>;

    /**
     * Deletes a role.
     * @param role The Role you want to delete.
     */
    public abstract deleteRole(role: Role): Observable<Role>;

}

export interface RoleQueryParams {
    department?: Department;
    isDeleted?: boolean;
}
