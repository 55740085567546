import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Employee } from '../../../core/model';
import { MediaService } from '../../../core/services';
import { ModalService } from '../../../core/services/ModalService.model';

enum ModalState {
    TeamList,
    ProfileTabs,
}

@Component({
    selector: 'app-edit-employee-modal',
    templateUrl: './edit-employee-modal.component.html',
    styleUrls: ['./edit-employee-modal.component.scss'],
})
export class EditEmployeeModalComponent implements OnInit {

    public activeModalState: ModalState = ModalState.TeamList;
    public modalStates: typeof ModalState = ModalState;
    public selectedEmployee: Employee;
    public isMobile: boolean;

    constructor(
        private mediaService: MediaService,
        private modalService: ModalService,
        @Optional() @Inject(MAT_DIALOG_DATA) private employee?: Employee,
    ) { }

    public ngOnInit(): void {
        this.isMobile = !this.mediaService.isBigScreen();
        if (this.employee) this.selectEmployee(this.employee);
    }

    /**
     * Selects an employee, and opens the edit view of that employee
     * @param employee The employee to mark as selected
     */
    public selectEmployee(employee: Employee): void {
        this.selectedEmployee = employee;
        this.activeModalState = ModalState.ProfileTabs;
    }
    /**
     * Opens the modal which you can contact your employee from
     * @param employee you want to contact
     */
    public openContactEmployeeModal(employee: Employee): void {
        this.modalService.openContactEmployeeModal(employee);
    }

}
