import { ChangeDetectorRef, Component, EventEmitter, Inject, Injector, OnDestroy, OnInit, TrackByFunction } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { map, switchMap, take, tap } from 'rxjs/operators';
import { Shift } from '../../../../../../core/model';
import { Department } from '../../../../../../core/model/Department/Department.model';
import { CompanySetting, CompanySettingsService, DateService, MediaService, ShiftService } from '../../../../../../core/services';
import { SnackbarColor, SnackbarService } from '../../../../../../core/services/snackbar/snackbar.service';
import { createApproval } from '../../../../../../shared/utilities/ApprovalUtils';
import { validTimeInput } from '../../../../../../shared/utilities/PunchclockUtils';

export interface ShiftToApprove {
    shift: Shift;
    selectedStart: {
        time: Date,
        source: TimeSource,
    };
    selectedEnd: {
        time: Date,
        source: TimeSource,
    };
    selected: boolean;
    loading: boolean;
}
export enum TimeSource {
    ORIGINAL,
    PUNCHED,
    CUSTOM,
}

export interface ApproveShiftsModalData {
    start: Date;
    end: Date;
    department: Department | undefined;
    mobile: boolean | undefined;
}

@Component({
    selector: 'app-approve-shifts',
    templateUrl: './approve-shifts.component.html',
    styleUrls: ['./approve-shifts.component.scss'],
})
export class ApproveShiftsComponent implements OnInit, OnDestroy {

    public shiftsToApprove$: Observable<ShiftToApprove[]>;
    // All the shifts with more data
    public shiftsToApprove: ShiftToApprove[];
    // When one of the checkboxes are clicked an event is emitted.
    public selectedClicked: EventEmitter<void>;
    // Binding on the top checkbox
    public allShiftsSelected: boolean;
    // If the top checkbox is in intermediate state
    public indeterminateSelection: boolean;

    public selectedShifts: ShiftToApprove[];

    private subscriptions: Subscription[];

    constructor(
        public dateService: DateService,
        @Inject(MAT_DIALOG_DATA) public modalData: ApproveShiftsModalData,
        public modalRef: MatDialogRef<ApproveShiftsComponent>,
        private shiftService: ShiftService,
        private cdr: ChangeDetectorRef,
        private snackService: SnackbarService,
        private injector: Injector,
        private companySettingsService: CompanySettingsService,
    ) {
        this.selectedShifts = [];
        this.allShiftsSelected = false;
        this.indeterminateSelection = false;
        this.selectedClicked = new EventEmitter();
        this.subscriptions = [];
    }

    /**
     * Checks that the supplied custom input fields are valid
     * @param customTimeInputFields The input fields to check
     */
    public static checkCustomFieldsValid(customTimeInputFields: HTMLInputElement[]): boolean {
        // Go through the fields and return false if any is selected and has an invalid format
        for (const customTimeInputField of customTimeInputFields) {
            const invalidFormat: boolean = !validTimeInput.test(customTimeInputField.value);
            if (invalidFormat) return false;
        }
        return true;
    }

    public trackShiftToApprove: TrackByFunction<ShiftToApprove>
        = (_index: number, shiftToApprove: ShiftToApprove) => shiftToApprove.shift.id

    public ngOnInit(): void {
        this.subscriptions.push(
            this.selectedClicked.subscribe(() => {
                if (this.numberOfSelectedShifts() === 0) {
                    this.indeterminateSelection = false;
                    this.allShiftsSelected = false;

                } else {
                    this.indeterminateSelection = true;
                    this.allShiftsSelected = false;
                }
                MediaService.detectChanges(this.cdr);
            }));

        this.shiftsToApprove$ = combineLatest([
            this.companySettingsService.loadSetting(CompanySetting.CHECKOUT_UNTIL)
                .pipe(
                    switchMap((checkoutUntil: number | null) =>
                        this.shiftService.getUnapprovedShifts(
                            checkoutUntil,
                            this.modalData.start,
                            this.modalData.end,
                            this.modalData.department),
                    ),
                ),
            this.companySettingsService.loadSetting(CompanySetting.ROUND_CHECKIN),
            this.companySettingsService.loadSetting(CompanySetting.ROUND_CHECKOUT),
        ]).pipe(
            tap(() => this.selectedShifts = []),
            map(([shifts, roundCheckin, roundCheckout]: [Shift[], boolean, boolean]) => shifts
                .sort((a: Shift, b: Shift) => a.start.getTime() - b.start.getTime())
                .map((shift: Shift) => ({
                    shift,
                    selectedStart: this.initSelectedStart(shift, roundCheckin),
                    selectedEnd: this.initSelectedEnd(shift, roundCheckout),
                    selected: false,
                    loading: false,
                })),
            ),
        );

        this.subscriptions.push(
            this.shiftsToApprove$.subscribe({
                next: (shifts: ShiftToApprove[]): void => {
                    this.shiftsToApprove = shifts;
                    MediaService.detectChanges(this.cdr);
                },
            }));
    }

    /**
     * Selects all shifts for approval or unselects if all are selected
     */
    public toggleSelectAllShifts(event: MatCheckboxChange): void {

        if (this.indeterminateSelection) {
            this.setSelectAllShiftsToApprove(false);
            this.indeterminateSelection = false;
            this.allShiftsSelected = false;
            event.source.checked = false;

        } else {
            // See if all but the top select is selected
            if (this.numberOfSelectedShifts() === this.shiftsToApprove.length) {
                this.setSelectAllShiftsToApprove(false);
                this.allShiftsSelected = false;
                event.source.checked = false;
            } else {
                this.setSelectAllShiftsToApprove(true);
                this.allShiftsSelected = true;
            }
        }
    }

    public ngOnDestroy(): void {
        this.subscriptions.forEach((subs: Subscription) => subs.unsubscribe());
    }

    /**
     * Sends a list of selected shifts for approval via the ShiftService.
     */
    public async approveShifts(): Promise<void> {
        if (!this.checkShiftsValid()) {
            this.snackService.displaySnack({ translationKey: 'error.new-times-invalid' }, SnackbarColor.warn);
            return;
        }

        const approvedShifts: Shift[] = await Promise.all(this.shiftsToApprove
            .filter((sta: ShiftToApprove) => sta.selected)
            .map(async (shiftToApprove: ShiftToApprove) => {
                shiftToApprove.loading = true;
                return {
                    ...shiftToApprove.shift,
                    approved: true,
                    approval: await createApproval(shiftToApprove.shift, this.injector),
                    start: shiftToApprove.selectedStart.time,
                    end: shiftToApprove.selectedEnd.time,
                };
            }));

        this.shiftService.updateShifts(approvedShifts)
            .pipe(take(1))
            .subscribe({
                next: (): void => {
                    this.indeterminateSelection = false;
                    MediaService.detectChanges(this.cdr);
                },
            });
    }

    /**
     * Checks whether the chosen shifts are valid
     */
    private checkShiftsValid(): boolean {
        // Get all custom input fields
        let customTimeInputFields: HTMLInputElement[] =
            Array.from(<HTMLCollectionOf<HTMLInputElement>> document.getElementsByClassName('custom-input'));
        // Filter away the ones that aren't selected for approval
        customTimeInputFields =
            customTimeInputFields.filter((inputField: HTMLInputElement) => inputField.classList.contains('selectedForApproval'));
        // Return whether the fields are valid
        return ApproveShiftsComponent.checkCustomFieldsValid(customTimeInputFields);
    }

    /**
     * Initiates the provided shift's selectedStart with time and source.
     * @param shift The shift that is being initialized.
     */
    private initSelectedStart(shift: Shift, round: boolean): { time: Date, source: TimeSource } {
        const checkin = shift.checkin;
        if (!checkin) return { time: shift.start, source: TimeSource.ORIGINAL };

        return (round && checkin.time.getTime() < shift.start.getTime())
            ? { time: shift.start, source: TimeSource.ORIGINAL }
            : { time: checkin.time, source: TimeSource.PUNCHED };
    }

    /**
     * Initiates the provided shift's selectedEnd with time and source.
     * @param shift The shift that is being initialized.
     */
    private initSelectedEnd(shift: Shift, round: boolean): { time: Date, source: TimeSource } {
        const checkout = shift.checkout;
        if (!checkout) return { time: shift.end, source: TimeSource.ORIGINAL };

        return (round && checkout.time.getTime() > shift.end.getTime())
            ? { time: shift.end, source: TimeSource.ORIGINAL }
            : { time: checkout.time, source: TimeSource.PUNCHED };
    }

    /**
     * The number of shiftsToApprove that are selected.
     */
    private numberOfSelectedShifts(): number {
        return this.shiftsToApprove.filter((shift: ShiftToApprove) => shift.selected).length;
    }

    /**
     * Selects all or deselect all.
     * @param value true or false
     */
    private setSelectAllShiftsToApprove(value: boolean): void {
        this.shiftsToApprove.map((shift: ShiftToApprove) => shift.selected = value);
    }
}
