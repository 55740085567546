import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { take } from 'rxjs/operators';
import { Package } from '../../../../../core/model/Freemium/Package';
import { FillShiftsInput, PlannerService } from '../../../../../core/services';
import { ConfigurationService } from '../../../../../core/services/Configuration/configuration-service.model';
import { GoogleAnalyticsService } from '../../../../../core/services/google-analytics.service';
import { ModalService } from '../../../../../core/services/ModalService.model';
import { FillShiftsModalComponent } from '../fill-shifts-modal/fill-shifts-modal.component';

export interface AutoFillModalData {
    freeShifts: boolean;
    fillShiftsInput: FillShiftsInput;
}

@Component({
    selector: 'app-auto-fill-modal',
    templateUrl: './auto-fill-modal.component.html',
    styleUrls: ['./auto-fill-modal.component.scss'],
})
export class AutoFillModalComponent implements OnInit {

    public fillingShifts: boolean;
    public noFreeShifts: boolean;
    public Package: typeof Package = Package;

    constructor(
        @Inject(MAT_DIALOG_DATA) private modalData: AutoFillModalData,

        private configurationService: ConfigurationService,
        private modalService: ModalService,
        private gas: GoogleAnalyticsService,
        private plannerService: PlannerService,
        private modal: MatDialog,
        private modalRef: MatDialogRef<AutoFillModalComponent>,

    ) { }

    public ngOnInit(): void {
        this.noFreeShifts = !this.modalData.freeShifts;
    }

    public async fillShifts(): Promise<void> {
        // Load pro status
        const pro: boolean = await this.configurationService.isPro(Package.PRO).pipe(take(1)).toPromise();
        if (!pro) {
            // If not pro, close the modal and open the Pro Feature modal
            this.modalService.openProShopOverlay();
            return;
        }

        this.gas.trackButtonClick('fill-shift');

        this.fillingShifts = true;

        this.plannerService
            .fillShifts(this.modalData.fillShiftsInput)
            .then((msg: string) => {
                this.modalRef.close();
                this.modal.open(FillShiftsModalComponent, {
                    data: { msg, success: true },
                    width: '50%',
                });
            })
            .catch((msg: string) => {
                this.modalRef.close();
                this.modal.open(FillShiftsModalComponent, {
                    data: { msg, success: false },
                    width: '50%',
                });
            });
    }
}
