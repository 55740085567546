<ng-container *ngIf="nextShift$ | async as nextShift">
    <div class="next-shift"
         [class.ready-for-checkin]="nextShift.status === NextShiftStatus.READY_FOR_CHECKIN"
         (click)="modalService.openShiftDetailModal(nextShift.shift!)"
         *ngIf="nextShift.status !== NextShiftStatus.NO_LONGER_RELEVANT">

        <div class="progress-bar"
             *ngIf="nextShift.status === NextShiftStatus.OVER"></div>
        <div class="progress-bar"
             *ngIf="nextShift.status === NextShiftStatus.IN_PROGRESS"
             [style.width.%]="(dynamicDuration$ | async)?.progress"></div>

        <div class="main"
             [ngSwitch]="nextShift.status">

            <ng-container *ngSwitchCase="NextShiftStatus.FUTURE">
                <div class="heading">
                    {{ 'bottom-nav.your-next-shift' | translate }}
                </div>
                <div class="subheading">
                    {{ 'shared.starts' | translate }}
                    {{ nextShift.shift!.start | amTimeAgo }}
                </div>
            </ng-container>

            <ng-container *ngSwitchCase="NextShiftStatus.IN_PROGRESS">
                <div class="heading">
                    {{ 'bottom-nav.shift-in-progress' | translate }}
                </div>
                <div class="subheading">
                    {{ (dynamicDuration$ | async)?.text }}
                </div>
            </ng-container>

            <ng-container *ngSwitchCase="NextShiftStatus.OVER">
                <div class="heading">
                    {{ 'bottom-nav.shift-over' | translate }}
                </div>
                <div class="subheading over">
                    {{ staticDuration }}
                </div>
            </ng-container>

            <ng-container *ngSwitchCase="NextShiftStatus.READY_FOR_CHECKIN">
                <div class="heading">
                    {{ 'bottom-nav.check-in' | translate }}
                </div>
            </ng-container>

        </div>
        <i class="far fa-chevron-up"></i>
    </div>
</ng-container>