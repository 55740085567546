<div class="manage-shifts">
    <div class="manage-shifts-header">
        <app-stats-widget></app-stats-widget>
    </div>

    <mat-tab-group mat-stretch-tabs
                   disableRipple>
        <mat-tab [label]="'widget.next-shifts' | translate | capitalize">
            <app-future-shifts-widget></app-future-shifts-widget>
        </mat-tab>
        <mat-tab [label]="'widget.free-shifts' | translate | capitalize">
            <app-free-shifts-widget></app-free-shifts-widget>
        </mat-tab>
    </mat-tab-group>
</div>