import * as _ from 'lodash';
import * as moment from 'moment';
import { DateRange, extendMoment, MomentRange } from 'moment-range';
import { Employee, isShift, Shift, ShiftBreak } from '../../core/model';
import { FirestoreEmployee, FirestoreShift } from '../../core/model/Firestore';
import Timestamp from '../../core/model/Firestore/FirestoreTimestamp';
import { WithID } from '../../core/model/WithID';
import { isSameDate } from './DateUtils/isSameDate';
import { getShiftBreakDuration } from './ShiftUtils/durationUtils';
const momentRange: MomentRange = extendMoment(moment);

/**
 * Map Relion employees to integration employees
 * @param employees - The Relion employees
 * @param employeeMap - The integration employees
 */
export function mapEmployeesToIntegrationMapping(
    employees: (Employee | WithID<FirestoreEmployee>)[],
    employeeMap: { [key: string]: string | null }[],
): [Employee | WithID<FirestoreEmployee>, string][] {
    const mappedEmployees: [Employee | WithID<FirestoreEmployee>, string][] = [];

    // Go through each employee
    employees.forEach((employee: Employee | WithID<FirestoreEmployee>) => {
        // Look for a mapping and add it if found
        employeeMap.forEach((mapping: { [key: string]: string | null }) => {
            const relionID: string = Object.keys(mapping)[0]!;
            const integrationID: string | null = Object.values(mapping)[0]!;

            if (employee.id === relionID && !!integrationID) mappedEmployees.push([employee, integrationID]);
        });
    });

    return mappedEmployees;
}

/**
 * Splits a shift on shiftbreak and midnight
 * @param shift - The shift to split
 * @param shiftBreak - ShiftBreak to split on
 */
export function splitShift(shift: Shift | WithID<FirestoreShift>, shiftBreak?: ShiftBreak | null): (Shift | WithID<FirestoreShift>)[] {
    const shiftsSplitAtBreak: (Shift | WithID<FirestoreShift>)[] = splitShiftAtBreak(shift, shiftBreak);
    const shiftsSplitAtMidnight: ([Shift | WithID<FirestoreShift>] | [Shift, Shift] | [WithID<FirestoreShift>, WithID<FirestoreShift>])[] =
        shiftsSplitAtBreak.map(splitShiftAtMidnight);
    return _.flatten(shiftsSplitAtMidnight);
}

/**
 * If a shift is past midnight, split it in two, otherwise return it
 * @returns One or two shifts in a list
 */
function splitShiftAtMidnight(shift: Shift | WithID<FirestoreShift>):
    [Shift | WithID<FirestoreShift>] | [Shift, Shift] | [WithID<FirestoreShift>, WithID<FirestoreShift>] {
    if (isShift(shift)) {
        if (isSameDate(shift.start, shift.end) || (shift.end.getHours() === 0 && shift.end.getMinutes() === 0)) return [shift];
        const split: [Shift, Shift] = [{ ...shift }, { ...shift }];
        split[0].end = new Date(shift.start);
        split[0].end.setHours(24, 0, 0);

        split[1].start = new Date(shift.end);
        split[1].start.setHours(0, 0, 0);

        return split;
    } else {
        if (isSameDate(shift.start.toDate(), shift.end.toDate())
            || (shift.end.toDate().getHours() === 0 && shift.end.toDate().getMinutes() === 0)) {
            return [shift];
        }
        const split: [WithID<FirestoreShift>, WithID<FirestoreShift>] = [{ ...shift }, { ...shift }];
        const newEnd = shift.start.toDate();
        newEnd.setHours(24, 0, 0);
        split[0].end = Timestamp.fromDate(newEnd);

        const newStart = shift.end.toDate();
        newStart.setHours(0, 0, 0);
        split[1].start = Timestamp.fromDate(newStart);

        return split;
    }
}

/**
 * If a shift has a break, remove the break part from the middle of the shift and return the rest of the shift
 * @returns One or two shifts in a list
 */
function splitShiftAtBreak(shift: Shift | WithID<FirestoreShift>, shiftBreak?: ShiftBreak | null):
    [Shift | WithID<FirestoreShift>] | [Shift, Shift] | [WithID<FirestoreShift>, WithID<FirestoreShift>] {
    // If no break, return single shift
    if (!shiftBreak) return [shift];

    // Get shift break duration
    const shiftBreakDuration: number = getShiftBreakDuration(shift, shiftBreak);

    // If no break, return single shift
    if (shiftBreakDuration === 0) return [shift];

    let range: DateRange;
    if (isShift(shift)) {
        range = momentRange.range(shift.start, shift.end);
    } else {
        range = momentRange.range(shift.start.toDate(), shift.end.toDate());
    }
    // Init date range and center

    const breakStart: Date = range.center().subtract(shiftBreakDuration / 2, 'hours').toDate();
    const breakEnd: Date = range.center().add(shiftBreakDuration / 2, 'hours').toDate();
    if (isShift(shift)) {
        // Duplicate the shift
        const split: [Shift, Shift] = [{ ...shift }, { ...shift }];

        // Split the shifts on the break times
        split[0].end = breakStart;
        split[1].start = breakEnd;

        return split;
    } else {
        // Duplicate the shift
        const split: [WithID<FirestoreShift>, WithID<FirestoreShift>] = [{ ...shift }, { ...shift }];

        // Split the shifts on the break times
        split[0].end = Timestamp.fromDate(breakStart);
        split[1].start = Timestamp.fromDate(breakEnd);

        return split;
    }
}
