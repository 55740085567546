import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService, AuthUser } from './auth.service';
import { MediaService } from './media.service';

@Injectable()
export class SidebarService {

    private sidebarEnabledSubject: BehaviorSubject<boolean>;
    private readonly sidebarOpenSubject: BehaviorSubject<boolean>;
    constructor(
        private authService: AuthService,
        private mediaService: MediaService,
    ) {
        this.sidebarOpenSubject = new BehaviorSubject(false);
        this.sidebarEnabledSubject = new BehaviorSubject(false);
        this.setUpSidebarObserver();
    }

    /**
     * Enables the sidebar, so it can be opened and closed
     */
    public enableSidebar(): void {
        this.sidebarEnabledSubject.next(true);
    }

    /**
     * Disables the sidebar, so it can not be opened, until it is reenabled
     */
    public disableSidebar(): void {
        this.sidebarEnabledSubject.next(false);
    }

    /**
     * Returns an observable with the current sidebar state
     */
    public getSidebarObservable(): Observable<boolean> {
        return this.sidebarOpenSubject.asObservable();
    }

    /**
     * Toggles the sidebar open state
     */
    public toggle(): void {
        this.setSideBarOpenState(!this.sidebarOpenSubject.value && this.sidebarEnabledSubject.value);
    }

    /**
     * Sets the state of the sidebar
     * @param open The new state of the sidebar
     */
    public setSideBarOpenState(open: boolean): void {
        if (this.sidebarOpenSubject.value !== open) {
            this.sidebarOpenSubject.next(open && this.sidebarEnabledSubject.value);
        }
    }

    /**
     * Sets up the sidebar observer, to update sidebar state depending on the sidebar being enabled, screen size and user state
     */
    private setUpSidebarObserver(): void {
        combineLatest(
            this.sidebarEnabledSubject.asObservable(),
            this.mediaService.observeMediaChanges(),
            this.authService.getUser(),
        )
            .pipe(
                map(([sidebarEnabled, bigScreen, authUser]: [boolean, boolean, AuthUser]) =>
                    // If the sidebar is enabled, we are on a big screen and authUser is not null, we want to automatically open the sidebar
                    sidebarEnabled && bigScreen && authUser != null),
            )
            .subscribe({
                next: (openSidebar: boolean): void => this.setSideBarOpenState(openSidebar),
            });
    }
}
