<div class="input_dialog_modal_container">
    <div class="titel_container">
        <p class="titel_text">
            {{modalData.titleTranslationKey |translate: modalData.titleTranslationParams }}
        </p>
    </div>
    <div class="subtitle_container">
        <p class="subtitle_text">
            {{ modalData.subtitleTranslationKey || '' | translate: modalData.subtitleTranslationParams }}
        </p>
    </div>

    <input required
           #input
           appFloatingLabel="{{ 'shared.email' | translate }}"
           [errorMessage]="'error.input.mail' | translate">

    <div class="buttons_group_container">
        <ui-button-standard (click)="close()">
            {{modalData.closeButtonTranslationKey | translate: modalData.closeButtonTranslationParams }}
        </ui-button-standard>
        <ng-container *ngIf="modalData.acceptButtonTranslationKey">
            <ui-button-action class="button_input_dialog"
                              (click)="close(input.value)"
                              [disabled]="!(input?.value)">
                {{modalData.acceptButtonTranslationKey | translate: modalData.acceptButtonTranslationParams }}
            </ui-button-action>
        </ng-container>

    </div>
</div>