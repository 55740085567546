export interface DataloenExportData {
    collection: DataloenDateEntry[];
    shiftIDs?: string[];
}

export enum DataloenPayTypeCodes {
    NORMAL_HOURS = '0012',
    NORMAL_WAGE = '0013',
    SALARY_SUPPLEMENT_SUM = '0003',
    WORK_HOURS = '0001',
}

export interface DataloenEntry {
    employeeId: string;
    payTypeCode: DataloenPayTypeCodes;
    value: number;
    comment: string;
}
export interface DataloenDateEntry {
    date: string;
    reference: string;
    entries: DataloenEntry[];
}

export interface DataloenEmployee {
    employeeId: string;
    employeeNumber: string;
    salaryPeriodGroup: string;
    salaryPeriodCode: string;
    name: string;
    isTerminated: string;
    dateOfTermination: string | null;
    pinnedPayTypes: {
        payTypeCode: string,
        defaultText: string,
        defaultValue: string,
    }[];
}
