<div class="salary-dk-why-match-modal">
    <img alt="Salary.dk logo"
         class="logo"
         [src]="integration | getIntegrationLogo">
    <p>
        {{ 'integrations.salarydk.why-match' | translate }}
    </p>
    <ui-button-standard (click)="modalRef.close()">
        {{ 'shared.close' | translate }}
    </ui-button-standard>
</div>