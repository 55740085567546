<div class="settings-card"
     [class.no-subtitle]="!subtitle"
     [class.toggle-extension]="toggleExtension">
    <div class="setting-title-container">
        <div class="setting-title">{{ title }}</div>
        <div class="setting-subtitle"
             *ngIf="subtitle">{{ subtitle }}</div>
    </div>
    <ui-toggle [checked]="checked"
               (checkedChange)="onCheckChanged($event)"></ui-toggle>
</div>
