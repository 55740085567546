<div class="create-roles-modal">
    <h1>{{ 'onboarding.congratulations-on-your-first-department'| translate }}</h1>
    <p>
        {{ 'onboarding.before-you-can-lay-out-your-schedule' | translate }}
    </p>
    <form name="sign-up-form"
          class="create-roles-modal-form"
          #createRolesForm="ngForm"
          (submit)="onSubmit()">

        <div class="inputs">
            <input name="navn0"
                   [(ngModel)]="roleNames[0].value"
                   inputColor="grey"
                   appFloatingLabel="{{ 'roles.eg-bartender' | translate }}" />
            <input name="navn1"
                   [(ngModel)]="roleNames[1].value"
                   inputColor="grey"
                   appFloatingLabel="{{ 'roles.eg-waiter' | translate }}" />

            <input name="navn2"
                   [(ngModel)]="roleNames[2].value"
                   inputColor="grey"
                   appFloatingLabel="{{ 'roles.eg-dishwasher' | translate }}" />

            <ng-container *ngFor="let roleName of roleNames; let i = index;">
                <div *ngIf="i > 2">
                    <input [name]="'navn' + i"
                           inputColor="grey"
                           [(ngModel)]="roleNames[i]!.value"
                           appFloatingLabel="{{ 'roles.role-name' | translate }}" />
                </div>
            </ng-container>

            <ui-button-standard color="primary"
                                (click)="addRoleInput()">
                {{ 'roles.add-role' | translate | capitalize }}
            </ui-button-standard>
        </div>

        <ui-button-action [disabled]="!valid || loading"
                          class="create-profile">
            <span *ngIf="!loading">{{ 'shared.create' | translate }}</span>
            <i class="far fa-spin fa-spinner"
               *ngIf="loading"></i>
        </ui-button-action>
    </form>
</div>