import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { Role } from '../../../model/Role/Role.model';

export interface RoleState extends EntityState<Role, string> {
    roles: Role[];
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'role' })
export class RoleStore extends EntityStore<RoleState, Role, string> {

    constructor() {
        super();
    }

}
