import { Injectable, OnDestroy } from '@angular/core';
import { DocumentReference, QueryDocumentSnapshot, QuerySnapshot } from '@angular/fire/firestore';
import { combineLatest, Observable, ReplaySubject, Subscriber, Subscription } from 'rxjs';
import { map, shareReplay, switchMap, take } from 'rxjs/operators';
import { Auth, FirestoreService } from '../..';
import { Role } from '../../../model';
import { Department } from '../../../model';
import { FirestoreRole } from '../../../model/Firestore';
import { rolesCollectionPath } from '../../../model/Firestore';
import { NewRole } from '../../../model/Role/NewRole';
import { RoleColor } from '../../../model/Role/RoleColor';
import { RoleImpl } from '../../../model/Role/RoleImpl';
import { AuthUser } from '../../auth.service';
import { DepartmentService } from '../../Department/DepartmentService.model';
import { RoleQueryParams, RoleService } from '../RoleService.model';

type RoleColorCount = {
    [key in RoleColor]: number;
};

@Injectable()
export class FirestoreRoleService extends RoleService implements OnDestroy {
    private multipleRolesCache$: Observable<Role[]>;
    private colorCount$: ReplaySubject<RoleColorCount>;
    private colorCountSubscription: Subscription;

    constructor(
        private firestoreService: FirestoreService,
        private departmentService: DepartmentService,
    ) {
        super();
        this.colorCount$ = new ReplaySubject(1);
    }

    public ngOnDestroy(): void {
        this.colorCountSubscription.unsubscribe();
    }

    public createRole(role: NewRole): Observable<Role> {
        return this.firestoreService.authenticate(rolesCollectionPath)
            .pipe(
                switchMap(async (auth: Auth<FirestoreRole>) => {
                    const newRole = {
                        ...role,
                        isDeleted: false,
                    };
                    return auth.collection
                        .add(await this.mapNewRoleToFirestoreRole(newRole));
                }),
                switchMap((docRef: DocumentReference) => this.getRole(docRef.id)),
            );
    }

    public getRole(id: string): Observable<Role> {
        return this.getRoles({ isDeleted: true })
            .pipe(
                map((roles: Role[]) => {
                    const foundRole = roles.find((role: Role) => role.id === id);
                    if (!foundRole) throw Error(`No role with id ${ id }. Roles: ${ roles.map(role => role.id).toString() }`);
                    return foundRole;
                }),
            );
    }

    public getRoles(params?: RoleQueryParams): Observable<Role[]> {
        this.setupRolesCache();

        const filterRoles = (roles: Role[], filterParams?: RoleQueryParams) => {
            // Handle isDeleted query param
            if (!filterParams || !filterParams.isDeleted) roles = roles.filter((role: Role) => !role.isDeleted);
            if (filterParams?.department) roles = roles.filter((role: Role) => role.department.id === filterParams.department?.id);
            return roles;
        };
        return this.multipleRolesCache$.pipe(map((roles: Role[]) => filterRoles(roles, params)));
    }

    public updateRole(role: Role): Observable<Role> {
        return this.firestoreService.authenticate(rolesCollectionPath)
            .pipe(switchMap((auth: Auth<FirestoreRole>) =>
                auth.collection
                    .doc(role.id)
                    .update(FirestoreRoleService.mapRoleToFirestoreRole(role))),
                switchMap(() => this.getRole(role.id)));
    }

    public updateRoles(roles: Role[]): Observable<void> {
        return this.firestoreService.authenticate(rolesCollectionPath)
            .pipe(switchMap((auth: Auth<FirestoreRole>) => new Observable<void>((subscriber: Subscriber<void>): void => {
                this.firestoreService.commitUpdateBatch(roles, auth.collection, FirestoreRoleService.mapRoleToFirestoreRole)
                    .then(() => {
                        return subscriber.complete();
                    })
                    .catch((err: Error) => subscriber.error(err));
            })));
    }

    public deleteRole(role: Role): Observable<Role> {
        return this.firestoreService.authenticate(rolesCollectionPath)
            .pipe(
                switchMap((auth: Auth<FirestoreRole>) => auth.collection.doc(role.id).update({ isDeleted: true })),
                switchMap(() => this.getRole(role.id)),
            );
    }

    /**
     * Converts a role object into a firestore object.
     * @param role Role you want to convert
     */
    private static mapRoleToFirestoreRole(role: Role): FirestoreRole {
        return {
            name: role.name,
            department: role.department.id,
            color: role.color,
            isDeleted: role.isDeleted || false,
        };
    }

    /**
     * Converts a new role object into a firestore object.
     * @param role Role you want to convert
     */
    private async mapNewRoleToFirestoreRole(role: NewRole): Promise<FirestoreRole> {
        return {
            name: role.name,
            department: role.department.id,
            color: await this.getNextRoleColor(),
        };
    }

    private setupRolesCache(): void {
        if (this.multipleRolesCache$) return;
        this.multipleRolesCache$ = combineLatest(this.getFirestoreRoleDocuments(), this.firestoreService.getUser())
            .pipe(
                switchMap(async ([docs, user]: [QueryDocumentSnapshot<FirestoreRole>[], AuthUser]) => {
                    const roles = await Promise.all(docs.map(async (doc: QueryDocumentSnapshot<FirestoreRole>) =>
                        await this.mapFirestoreRoleToRole(doc.data(), doc.id)));
                    const roleDocsWithNoColor = docs.filter((doc: QueryDocumentSnapshot<FirestoreRole>) => !doc.data().color);

                    if (roleDocsWithNoColor.length > 0 && user.admin) {
                        this.updateRoles(roles).pipe(take(1)).toPromise();
                    }
                    return roles;
                }),
                shareReplay(1));
    }

    private getFirestoreRoleDocuments(): Observable<QueryDocumentSnapshot<FirestoreRole>[]> {
        return this.firestoreService.authenticate(rolesCollectionPath)
            .pipe(
                switchMap((auth: Auth<FirestoreRole>) =>
                    this.firestoreService.observeOnSnapshot(auth.collection)),
                map((snapshot: QuerySnapshot<FirestoreRole>) =>
                    snapshot.docs.sort((a: QueryDocumentSnapshot<FirestoreRole>, b: QueryDocumentSnapshot<FirestoreRole>) =>
                        a.data().name.localeCompare(b.data().name))),
            );
    }

    /**
     * Returns the next RoleColor to be used
     */
    private async getNextRoleColor(): Promise<RoleColor> {
        if (!this.colorCountSubscription) {
            this.colorCountSubscription = this.getFirestoreRoleDocuments()
                .pipe(
                    map((roles: QueryDocumentSnapshot<FirestoreRole>[]) =>
                        roles.map((role: QueryDocumentSnapshot<FirestoreRole>) => role.data())),
                    map((roles: FirestoreRole[]) => roles.filter((role: FirestoreRole) => !role.isDeleted)),
                    map(this.countColors, this),
                ).subscribe({ next: (newColorCount: RoleColorCount) => this.colorCount$.next(newColorCount) });
        }
        const colorCount: RoleColorCount = await this.colorCount$.pipe(take(1)).toPromise();
        let selectedColor: RoleColor = RoleColor.ROLE_COLOR_1;
        let selectedColorCount: number = colorCount[selectedColor];

        for (const key in RoleColor) {
            if (colorCount[RoleColor[key]] < selectedColorCount) {
                selectedColor = RoleColor[key] as RoleColor;
                selectedColorCount = colorCount[RoleColor[key]];
            }
        }

        // Increment and update the local colorcount, so we don't have to wait for the new roles to arrive
        colorCount[selectedColor] += 1;
        this.colorCount$.next(colorCount);

        return selectedColor;
    }

    /**
     * Counts the roles and returns a RoleColorCount
     * @param roles The roles you want to count
     */
    private countColors(roles: FirestoreRole[]): RoleColorCount {
        const colorCount = {
            [RoleColor.ROLE_COLOR_1]: 0,
            [RoleColor.ROLE_COLOR_2]: 0,
            [RoleColor.ROLE_COLOR_3]: 0,
            [RoleColor.ROLE_COLOR_4]: 0,
            [RoleColor.ROLE_COLOR_5]: 0,
            [RoleColor.ROLE_COLOR_6]: 0,
            [RoleColor.ROLE_COLOR_7]: 0,
            [RoleColor.ROLE_COLOR_8]: 0,
            [RoleColor.ROLE_COLOR_9]: 0,
            [RoleColor.ROLE_COLOR_10]: 0,
            [RoleColor.ROLE_COLOR_11]: 0,
            [RoleColor.ROLE_COLOR_12]: 0,
            [RoleColor.ROLE_COLOR_13]: 0,
            [RoleColor.ROLE_COLOR_14]: 0,
            [RoleColor.ROLE_COLOR_15]: 0,
            [RoleColor.ROLE_COLOR_16]: 0,
            [RoleColor.ROLE_COLOR_17]: 0,
            [RoleColor.ROLE_COLOR_18]: 0,
            [RoleColor.ROLE_COLOR_19]: 0,
            [RoleColor.ROLE_COLOR_20]: 0,
            [RoleColor.ROLE_COLOR_21]: 0,
        };

        roles.forEach((role: Role | FirestoreRole) => {
            if (!role.color) return;
            colorCount[role.color] += 1;
        });

        return colorCount;
    }

    /**
     * Converts a firestore database object to a Role object.
     * @param firestoreRole
     * @param id
     */
    private async mapFirestoreRoleToRole(firestoreRole: FirestoreRole, id: string): Promise<Role> {
        const department: Department = await this.departmentService.getDepartment(firestoreRole.department).pipe(take(1)).toPromise();
        return new RoleImpl(
            id,
            firestoreRole.name,
            department,
            firestoreRole.color || await this.getNextRoleColor(),
            firestoreRole.isDeleted || false,
        );
    }
}
