<div class="employee-set-hourly-wage-modal-container">
    <div class="wage-modal-header">
        <span>{{ 'salary.set-salary-for-employees' | translate }}</span>
    </div>
    <div class="wage-subtext">
        <span> {{ 'salary.set-salary-for-employees-description' | translate }} </span>
    </div>
    <app-set-hourly-wage-list></app-set-hourly-wage-list>
    <div class="wage-button-container">
        <ui-button-standard (click)="closeModal()">
            {{'shared.done' | translate}}
        </ui-button-standard>
    </div>
</div>