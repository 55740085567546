import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UserSettings, UserSettingsTypeMap } from '../../../model/UserSettings';

@Injectable({
    providedIn: 'root',
})

export abstract class UserSettingsService {
    /**
     * Loads a user setting
     * @param setting - The {UserSettings} to load
     * @param def - A default value to load if the setting is not set
     */
    public abstract loadSetting<T extends UserSettings>(setting: T, def?: UserSettingsTypeMap[T]): Observable<UserSettingsTypeMap[T]>;

    /**
     * Saves a user setting
     * @param setting - The setting to save
     * @param value - The value of the setting to save
     */
    public abstract saveSetting<T extends UserSettings>(setting: T, value: UserSettingsTypeMap[T]): Observable<UserSettingsTypeMap[T]>;
}

