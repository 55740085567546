import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage, AngularFireStorageReference } from '@angular/fire/storage';
import { ListResult, Reference, UploadMetadata, UploadTaskSnapshot } from '@angular/fire/storage/interfaces';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { EmployeeFile, FileService, FileUploadResult } from '../FileService.model';

@Injectable({
    providedIn: 'root',
})
export class FirebaseFileService extends FileService {

    constructor(private afStorage: AngularFireStorage, private afStore: AngularFirestore) {
        super();
    }

    public async upload(path: string, file: string | File, metadata?: UploadMetadata): Promise<FileUploadResult> {
        // Create unique ID or use the file name if present
        const uid: string = (typeof file === 'string') ? this.afStore.createId() : file.name;

        // Get the storage reference
        const storageRef: AngularFireStorageReference = this.afStorage.ref(path + uid);

        // Upload the file
        const uploadTaskSnapshot: UploadTaskSnapshot = (typeof file === 'string')
            ? await storageRef.putString(file, 'data_url', metadata)
            : await storageRef.put(file, metadata);

        // Get the download URL
        const downloadURL: string = await uploadTaskSnapshot.ref.getDownloadURL();

        // Return the FileUploadResult
        return { fileID: uid, downloadURL };
    }

    public getDownloadURL(path: string, id: string): Observable<string> {
        return this.afStorage.ref(path + id).getDownloadURL();
    }

    public async getUploadedFiles(path: string): Promise<EmployeeFile[]> {
        const listResult: ListResult = await this.afStorage.ref(path).listAll().pipe(take(1)).toPromise();

        return Promise.all(listResult.items.map(async (referenceRelion: Reference) => ({
            name: referenceRelion.name,
            url: await referenceRelion.getDownloadURL(),
            delete: referenceRelion.delete.bind(referenceRelion),
            loading: false,
        })));
    }
}
