import { ChangeDetectorRef, Injectable, ViewRef } from '@angular/core';
import * as OverlayScrollbars from 'overlayscrollbars';
import { BehaviorSubject, Observable } from 'rxjs';
@Injectable({
    providedIn: 'root',
})
export class MediaService {
    // The minimum screen size to have on a big screen
    private minScreenSize: number = 768;
    private maxIpadScreenSize: number = 1024;
    // Are we on a big screen?
    private bigScreen: BehaviorSubject<boolean> =
        new BehaviorSubject(matchMedia('only screen and (min-width: ' + this.minScreenSize + 'px)').matches);

    private ipadSize: BehaviorSubject<boolean> =
        new BehaviorSubject(matchMedia(`only screen and (min-width: ${ this.minScreenSize }px) and (max-width: ${ this.maxIpadScreenSize }px)
        `).matches);
    constructor() {
        const isIpad = (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);

        OverlayScrollbars.defaultOptions({
            scrollbars: { autoHide: (this.bigScreen.value && !isIpad) ? 'move' : 'scroll' },
        });
        // We hook up with the window.onresize function with is called, surprise surprise, whenever the windows is resized
        window.onresize = () => {
            const bigScreen = matchMedia('screen and (min-width: ' + this.minScreenSize + 'px)').matches;
            this.bigScreen.next(bigScreen);

            const ipadSize = matchMedia(`only screen and (min-width: ${ this.minScreenSize }px) and (max-width: ${ this.maxIpadScreenSize }px)
            `).matches;
            this.ipadSize.next(ipadSize);
        };
    }

    /**
     * Scrolls a certain element into view
     * @param id The id of the element to scroll to
     */
    public static scrollTo(id: string): void {
        const element: HTMLElement | null = document.getElementById(id);
        if (!element) return;
        setTimeout(() => element.scrollIntoView(), 0);
    }

    /**
     * Scrolls a certain element into view with an animation
     * @param elementId The id of the element to scroll to
     * @param position The horizontal position to scroll to
     * @param duration The duration of the scroll animation
     */
    public static async animateScrollTo(elementId: string, position: number, duration: number): Promise<void> {
        // Init the scroll element and return if not found
        const element: HTMLElement | null = document.getElementById(elementId);
        if (!element) return Promise.reject('Could not find scroll element');

        // Init the variables
        const start: number = element.scrollTop,
            change: number = position - start,
            increment: number = 20;
        let currentTime: number = 0;

        // Only scroll if not in the top
        if (start > 0) {
            while (currentTime < duration) {
                currentTime += increment;
                const currentScroll: number = MediaService.easeInOutQuad(currentTime, start, change, duration);
                element.scrollTop = currentScroll;
                await new Promise<void>((resolve: () => void): number => window.setTimeout(resolve, increment));
            }
        } else return Promise.resolve();
    }

    /**
     * Detect changes forcing angular to update the view
     * @param changeDetectorRef The change detector for which to detect changes
     */
    public static detectChanges(changeDetectorRef: ChangeDetectorRef): void {
        const viewRef: ViewRef = changeDetectorRef as ViewRef;
        if (!viewRef.destroyed) viewRef.detectChanges();
    }

    /**
     * Returns an observable which updates whenever the screen switches between small and large
     */
    public observeMediaChanges(): Observable<boolean> {
        return this.bigScreen.asObservable();
    }

    /**
     * Returns an observable which updates whenever the screen is ipad size
     */
    public observeIpadSize(): Observable<boolean> {
        return this.ipadSize.asObservable();
    }

    /**
     * Returns a boolean specifying whether the screen is big or not
     */
    public isBigScreen(): boolean {
        return this.bigScreen.getValue();
    }

    /**
     * Helper function for calculating an animation step
     */
    private static easeInOutQuad(time: number, curStart: number, curChange: number, curDuration: number): number {
        time /= curDuration / 2;
        if (time < 1) return curChange / 2 * time * time + curStart;
        time--;
        return -curChange / 2 * (time * (time - 2) - 1) + curStart;
    }
}
