import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-settings-header',
    templateUrl: './settings-header.component.html',
    styleUrls: ['./settings-header.component.scss'],
})
export class SettingsHeaderComponent {
    @Input() public title: string;
    @Input() public beta: boolean;
}
