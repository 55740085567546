import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { DirectivesModule } from '../../../shared/directives/directives.module';
import { PipeModule } from '../../../shared/pipes/pipe.module';
import { UIModule } from '../../../shared/ui/ui.module';
import { CompanySignupComponent } from './pages/company-signup/company-signup.component';
import { GetErrorStringPipe } from './pipes/get-error-string/get-error-string.pipe';

@NgModule({
    declarations: [
        CompanySignupComponent,
        GetErrorStringPipe,
    ],
    imports: [
        CommonModule,
        DirectivesModule,
        FormsModule,
        PipeModule,
        ReactiveFormsModule,
        TranslateModule,
        UIModule,
    ],
})
export class CompanySignupModule { }
