import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { Employee, Shift } from '../../../core/model';
import { Department } from '../../../core/model/Department/Department.model';
import { AuthService, AuthUser, DateService, EmployeeService, MediaService } from '../../../core/services';
import { DepartmentService } from '../../../core/services/Department/DepartmentService.model';
import { Color, SelectionStyle } from '../../ui/date-picker/date-picker.component';
import { StatusType } from '../../ui/profile-card/profile-card.component';

@Component({
    selector: 'app-mobile-shift-calendar',
    templateUrl: './mobile-shift-calendar.component.html',
    styleUrls: ['./mobile-shift-calendar.component.scss'],
})

export class MobileShiftCalendarComponent implements OnInit {
    public shiftColors$: Observable<Map<Date, Color>>;
    public statusTypes: typeof StatusType = StatusType; // Enum that sets the color of the dots on the profilecards
    public selectionStyles: typeof SelectionStyle = SelectionStyle;
    public departments$: Observable<Department[]>;
    public activeDepartment$: Observable<Department>;

    @Input()
    public get shifts$(): Observable<Shift[]> {
        return this._shifts$;
    }
    public set shifts$(value: Observable<Shift[]>) {
        this._shifts$ = value;
        this.shiftColors$ = this.setDotColors(value);
    }
    private _shifts$: Observable<Shift[]>;

    constructor(
        private authService: AuthService,
        private employeeService: EmployeeService,
        public dateService: DateService,
        private cd: ChangeDetectorRef,
        private departmentService: DepartmentService,
    ) { }

    public ngOnInit(): void {
        this.departments$ = combineLatest([
            this.authService.getUser(),
            this.employeeService.getAuthenticatedEmployee(),
        ]).pipe(
            switchMap(([user, employee]: [AuthUser | null, Employee]) => this.departmentService.getDepartments({
                employee: user?.admin ? undefined : employee,
            })),
        );
        this.activeDepartment$ = this.departmentService.getActiveDepartment();
    }

    /**
     * Changes the selected date
     * @param date the date that the user wishes to select
     */
    public onDateSelected(date: Date): void {
        this.dateService.changeDate(date);
    }

    /**
     * This method firstly gets the dates visible in the calendar,
     * secondly it traverses an array of Shifts and creates a Map of <string(dates turned into strings), Color>,
     * this map is finally made into another map of dates which is then returned
     */
    public setDotColors(shifts$: Observable<Shift[]>): Observable<Map<Date, Color>> {
        return this.authService.getUser().pipe(filter((user: AuthUser) => user !== null))
            .pipe(
                switchMap((user: AuthUser) => {
                    return shifts$.pipe(
                        map((shifts: Shift[]) => [shifts, user]));
                }),
                map(([shifts, user]: [Shift[], AuthUser]) => {
                    const dayMap: Map<string, Color> = new Map();
                    shifts.forEach((shift: Shift) => {
                        const day: string = shift.start.toDateString();
                        if (user.admin) {
                            if (!dayMap.get(day)) dayMap.set(day, Color.GREY);
                            if (!shift.employee && shift.released) {
                                dayMap.set(day, Color.RED);
                            }
                        } else {
                            if (shift.employee && shift.employee.id === user.user_id) {
                                dayMap.set(day, Color.PRIMARY);
                            }
                        }
                    });
                    return dayMap;
                }),
                map((dayMap: Map<string, Color>) => {
                    const dateMap: Map<Date, Color> = new Map();
                    for (const key of Array.from(dayMap.keys())) {
                        const date: Date = new Date(key);
                        dateMap.set(date, <Color> dayMap.get(key));
                    }
                    return dateMap;
                }), tap(() => setTimeout(() => MediaService.detectChanges(this.cd), 0)));
    }

    /**
     * Today sets the calendar to today
     */
    public today(): void {
        this.dateService.changeDate(DateService.today());
    }

    /**
     * Set the active department to the one with the given id
     * @param departments A list of all departments
     * @param selectedDepartmentId  The id of the department to set active
     */
    public setDepartment(departments: Department[], selectedDepartmentId: string): void {
        const selectedDepartment: Department | undefined = departments.find((dep: Department) => dep.id === selectedDepartmentId);
        if (selectedDepartment) this.departmentService.setActiveDepartment(selectedDepartment);
    }
}
