import { ChangeDetectorRef, Component, ErrorHandler, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { BehaviorSubject, combineLatest, from, Observable, Subscription } from 'rxjs';
import { distinctUntilChanged, map, shareReplay, switchMap, take, tap } from 'rxjs/operators';
import { Employee, Wish, WishType } from '../../../core/model';
import { CompanySetting, CompanySettingsService, DateService, EmployeeService, MediaService, WishService } from '../../../core/services';
import { Color, SelectionStyle } from '../../ui/date-picker/date-picker.component';

@Component({
    selector: 'app-mobile-wish-calendar',
    templateUrl: './mobile-wish-calendar.component.html',
    styleUrls: ['./mobile-wish-calendar.component.scss'],
})
export class MobileWishCalendarComponent implements OnInit, OnDestroy {

    // Fields used in the template
    public wishColors$: Observable<Map<Date, Color | undefined>>;
    public selectedWish$: Observable<Wish | undefined>;
    public selectedDate$: Observable<Date>;
    public customWishTime: boolean;
    public locale: string;

    // Enables use of the enums in the template
    public selectionStyles: typeof SelectionStyle = SelectionStyle;
    public wishTypes: typeof WishType = WishType;

    private currentEmployee$: Observable<Employee>;
    private selectedWishSubject: BehaviorSubject<Wish | undefined>;
    private subscriptions: Subscription[];
    private standardTimes$: Observable<{ from: Date, to: Date }>;
    private creatingWish: boolean;

    constructor(
        public dateService: DateService,
        private wishService: WishService,
        private employeeService: EmployeeService,
        private settingsService: CompanySettingsService,
        private cdr: ChangeDetectorRef,
        private translateService: TranslateService,
        private errorHandler: ErrorHandler,
    ) {
        this.selectedWishSubject = new BehaviorSubject(undefined);
        this.subscriptions = [];
        this.locale = this.translateService.currentLang;
    }

    public ngOnInit(): void {
        this.selectedWish$ = this.selectedWishSubject.asObservable();
        this.selectedDate$ = this.dateService.getDateObservable();
        this.currentEmployee$ = from(this.employeeService.getAuthenticatedEmployee());
        const wishes$: Observable<Wish[]> = this.getSelectedMonthsWishes();
        this.wishColors$ = this.getWishColorObservable(wishes$);
        this.standardTimes$ = this.getStandardTimes();
        this.listenForWishOnSelectedDay(wishes$);
    }

    public ngOnDestroy(): void {
        this.subscriptions.forEach((sub: Subscription) => sub.unsubscribe());
    }

    /**
     * Updates the type of the selected wish. If no wish is selected, a new one will be created with the given type, on the selected day
     * @param newType The new WishType to assign to the selected wish
     */
    public updateSelectedWishType(newType: WishType): void {
        const wish: Wish | undefined = this.selectedWishSubject.value;
        if (wish !== undefined && wish.id) {
            this.wishService.updateWish({ ...wish, type: newType });
        }
    }

    /**
     * Enables the wish time range dialog
     */
    public enableWishTimeRange(): void {
        this.customWishTime = true;
    }

    /**
     * Removes the custom wish time
     */
    public removeCustomTime(): void {
        this.customWishTime = false;
        this.standardTimes$.pipe(take(1)).subscribe({
            next: (times: { from: Date, to: Date }): void => this.updateWishTimes(times),
        });
    }

    /**
     * Updates the wish time range with the given dates
     * @param from The new from time as a Date object
     * @param to The new to time as a Date object
     */
    public updateWishTimes({ from, to }: { from?: Date, to?: Date }): void {
        const wish: Wish | undefined = this.selectedWishSubject.value;
        if (wish !== undefined && wish.id) {
            const oldFrom: Date = wish.from;
            const oldTo: Date = wish.to;
            this.wishService.updateWish({
                ...wish,
                from: from ? from : oldFrom,
                to: to ? to : oldTo,
            });
        }
    }

    /**
     * Marks a wish as selected
     * @param wish The wish to mark as selected
     */
    public async selectWish(wish: Wish): Promise<void> {
        const isStandardTimes: (from: Date, to: Date) => Promise<boolean> = async (from: Date, to: Date): Promise<boolean> => {
            try {
                const standardTimes: { from: Date, to: Date } = await this.standardTimes$.pipe(take(1)).toPromise();
                const fromIsStartOfDay: boolean = !moment(from).diff(moment(from).startOf('day'));
                const toIsStartOfDay: boolean = !moment(to).diff(moment(to).startOf('day'));
                const fromIsStandardTime: boolean = !moment(from).diff(standardTimes.from);
                const toIsStandardTime: boolean = !moment(to).diff(standardTimes.to);
                return (fromIsStandardTime && toIsStandardTime)
                    || (fromIsStartOfDay && toIsStartOfDay);
            } catch (error) {
                this.errorHandler.handleError(error);
                return false;
            }
        };
        if (wish
            && wish.from
            && wish.to
            && !(await isStandardTimes(wish.from, wish.to))
        ) {
            this.enableWishTimeRange();
        } else {
            this.customWishTime = false;
        }
        this.selectedWishSubject.next(wish);
    }


    /**
     * Returns an observable with from and to date objects, with the time set in standard time
     */
    private getStandardTimes(): Observable<{ from: Date, to: Date }> {
        return combineLatest([
            this.settingsService.loadSetting(CompanySetting.START_TIME_STANDARD_WISH),
            this.settingsService.loadSetting(CompanySetting.END_TIME_STANDARD_WISH),
            this.selectedDate$]).pipe(
                map(([fromNum, toNum, date]: [number, number, Date]) => {
                    const from = DateService.getDateObjectFromTimeAndDate(fromNum, date);
                    const to = DateService.getDateObjectFromTimeAndDate(toNum, date);
                    if (fromNum >= toNum) to.setDate(to.getDate() + 1);
                    return { from, to };
                }),
            );
    }

    /**
     * Creates a new wish with the given wish type
     * @param wishType The wishtype the new wish should have
     */
    private createNewWish(wishType: WishType): void {
        combineLatest([this.currentEmployee$, this.standardTimes$]).pipe(take(1)).subscribe(
            ([employee, { from, to }]: [Employee, { from: Date, to: Date }]) => {
                if (!this.creatingWish) {
                    this.creatingWish = true;
                    const wish: Omit<Wish, 'id'> = { from, to, type: wishType, employee };
                    this.wishService.createWish(wish).pipe(take(1)).subscribe({ next: () => this.creatingWish = false });
                }
            });
    }

    /**
     * Returns an observable of an array of Wishes, based on the current employee and the currently selected date
     */
    private getSelectedMonthsWishes(): Observable<Wish[]> {
        return combineLatest([this.currentEmployee$, this.selectedDate$]).pipe(
            map(([employee, date]: [Employee, Date]) => [employee, this.getMonthDateRange(date)]),
            distinctUntilChanged((
                [xemp, [xfirst, xlast]]: [Employee, [Date, Date]],
                [yemp, [yfirst, ylast]]: [Employee, [Date, Date]]) =>
                xemp.id === yemp.id && xfirst.toString() === yfirst.toString() && xlast.toString() === ylast.toString(),
            ),
            switchMap(([employee, [firstOfSelectedMonth, firstOfNextMonth]]: [Employee, [Date, Date]]) =>
                this.wishService.getWishes({ from: firstOfSelectedMonth, to: firstOfNextMonth, employee })),
            shareReplay(1),
        );
    }

    /**
     * Sets up a subscription to select the selected days wish in the wishes supplied by the given wish array observable
     * @param wishes$ An observable of wish arrays to search through
     */
    private listenForWishOnSelectedDay(wishes$: Observable<Wish[]>): void {
        this.subscriptions.push(
            this.selectedDate$.pipe(
                distinctUntilChanged((x, y) => x.toString() === y.toString()),
                switchMap((date: Date) =>
                    wishes$.pipe(
                        distinctUntilChanged(),
                        map((wishes: Wish[]) => wishes.find((wish: Wish) => wish.from.toDateString() === date.toDateString())))),
            ).subscribe({
                next: (wish?: Wish): void | Promise<void> => {
                    return wish ? this.selectWish(wish) : this.createNewWish(WishType.NO_WISH);
                },
            }),
        );
    }

    /**
     * Returns an observable of a Map<Date,Color>, based on the wishes supplied by the given observable
     */
    private getWishColorObservable(wishObservable: Observable<Wish[]>): Observable<Map<Date, Color | undefined>> {
        return wishObservable.pipe(
            map((wishes: Wish[]) => this.mapWishesToColors(wishes)),
            tap(() => setTimeout(() => MediaService.detectChanges(this.cdr), 0)),
        );
    }

    /**
     * Returns a Map<Date,Color> based on wish type
     * @param wishes Wishes to map to a Date -> Color map
     */
    private mapWishesToColors(wishes: Wish[]): Map<Date, Color | undefined> {
        return wishes.reduce((colorMap: Map<Date, Color | undefined>, wish: Wish) =>
            colorMap.set(wish.from, this.getColorFromWishType(wish.type)), new Map());
    }

    /**
     * Returns the first day of the month and the next month, based on the given date
     */
    private getMonthDateRange(date: Date): [Date, Date] {
        const firstOfSelectedMonth: Date = moment(date).startOf('month').toDate();
        const firstOfNextMonth: Date = moment(date).startOf('month').add(1, 'month').toDate();
        return [firstOfSelectedMonth, firstOfNextMonth];
    }

    /**
     * Maps WishType to the corresponding Color
     * @param type A WishType to map to a Color
     */
    private getColorFromWishType(type: WishType): Color | undefined {
        switch (type) {
            case WishType.NO_WORK:
                return Color.WARN;
            case WishType.WORK:
                return Color.SUCCESS;
        }
    }
}
