import { Injectable } from '@angular/core';
import { CollectionService } from 'akita-ng-fire';
import { Employee, Wish } from '../../../model';
import { companiesCollectionPath, FirestoreWish, wishesCollectionPath } from '../../../model/Firestore';
import Timestamp from '../../../model/Firestore/FirestoreTimestamp';
import { AuthQuery } from '../../auth/state/auth.query';
import { EmployeeQuery } from '../../Employee/state/employee.query';
import { WishState, WishStore } from './wish.store';

@Injectable({ providedIn: 'root' })
export class AkitaWishService extends CollectionService<WishState> {

    constructor(store: WishStore, private authQuery: AuthQuery, private employeeQuery: EmployeeQuery) {
        super(store);
    }

    get path(): string {
        const companyID = this.authQuery.getValue().roles.companyID;
        return `${ companiesCollectionPath }/${ companyID }/${ wishesCollectionPath }`;
    }

    public formatFromFirestore(wish: Readonly<FirestoreWish & { id: string }>): Wish {
        const employeeQuery = this.employeeQuery;
        return {
            ...wish,
            from: wish.from.toDate(),
            to: wish.to.toDate(),
            get employee(): Employee {
                const employee: Employee | undefined = employeeQuery.getEntity(wish.employeeID);
                if (!employee) throw Error(`No employee with id ${ wish.employeeID }`);
                return employee;
            },
        };
    }

    public formatToFirestore(wish: Readonly<Wish>): FirestoreWish {
        return {
            from: Timestamp.fromDate(wish.from),
            to: Timestamp.fromDate(wish.to),
            type: wish.type,
            employeeID: wish.employee.id,
        };
    }
}
