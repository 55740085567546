<div class="main">
    <app-department-list *ngIf="!selectedDepartment"
                         (departmentSelected)="setSelectedDepartment($event)"
                         [departments$]=departments$>
    </app-department-list>
    <app-edit-department *ngIf="selectedDepartment"
                         [department]=selectedDepartment
                         [departments$]=departments$
                         (deselectDepartment)=setSelectedDepartment($event)>
    </app-edit-department>
</div>