import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { Moment } from 'moment';
import { combineLatest, Observable, Subject, timer } from 'rxjs';
import { map, startWith, switchMap, take, tap } from 'rxjs/operators';
import { Employee, Shift } from '../../../../core/model';
import { CompanySettingsService, EmployeeService, ShiftService } from '../../../../core/services';
import { ModalService } from '../../../../core/services/ModalService.model';
import { isSameDate } from '../../../utilities/DateUtils/isSameDate';
import { getPunchClockSettings, PunchclockConfig } from '../../../utilities/PunchclockUtils';

enum NextShiftStatus {
    FUTURE = 1, // The shift is in the future, but not ready for check in
    IN_PROGRESS = 2, // The shift is currently in progress
    OVER = 3, // The shift's end has been passed
    READY_FOR_CHECKIN = 4, // The shift is ready for check in
    NO_LONGER_RELEVANT = 5, // If there's no next shift
}

interface ShiftAndStatus {
    status: NextShiftStatus;
    shift: Shift | null;
}

@Component({
    selector: 'app-next-shift',
    templateUrl: './next-shift.component.html',
    styleUrls: ['./next-shift.component.scss'],
})
export class NextShiftComponent implements OnInit {

    public NextShiftStatus: typeof NextShiftStatus = NextShiftStatus;
    public nextShift$: Observable<ShiftAndStatus>;
    public dynamicDuration$: Observable<{ text: string; progress: number | null; }>;
    public staticDuration: string;
    private updateNextShift$: Subject<void>;

    constructor(
        public modalService: ModalService,
        private employeeService: EmployeeService,
        private shiftService: ShiftService,
        private companySettingsService: CompanySettingsService,
    ) {
        this.updateNextShift$ = new Subject();
    }
    public ngOnInit(): void {
        // Setup the next shift observable
        this.nextShift$ = this.employeeService.getAuthenticatedEmployee().pipe(
            switchMap((employee: Employee) => combineLatest([
                // Update the next shift whenever we emit on the updater
                this.updateNextShift$.pipe(
                    startWith(void 0),
                    switchMap(() => this.shiftService.getPunchclockShifts(employee)),
                ),
                // Or when the punch clock settings change
                getPunchClockSettings(this.companySettingsService),
            ])),
            map(this.getRelevantShiftAndStatus, this),
            tap(this.setupRefresher.bind(this)),
            tap(this.setupDurationTexts.bind(this)),
        );

        this.nextShift$.pipe(take(1)).subscribe({
            next: (nextShift: ShiftAndStatus) => {
                if ((nextShift.status === NextShiftStatus.IN_PROGRESS || nextShift.status === NextShiftStatus.READY_FOR_CHECKIN)
                    && nextShift.shift) {
                    this.modalService.openShiftDetailModal(nextShift.shift);
                }
            },
        });
    }

    /**
     * Find the relevant shift and it's status (if any) to display to the user
     */
    private getRelevantShiftAndStatus([{ last, next }, punchclockConfig]:
        [{ last?: Shift; next?: Shift; }, PunchclockConfig]): ShiftAndStatus {

        const nextStatus: NextShiftStatus | null = this.getNextStatus(next, punchclockConfig);
        const lastStatus: NextShiftStatus | null = this.getLastStatus(last, punchclockConfig);

        if (!lastStatus || !nextStatus) {
            // If only last or next is available, return it
            if (lastStatus) return { status: lastStatus, shift: last! };
            if (nextStatus) return { status: nextStatus, shift: next! };
            // If none are, return null
            return { status: NextShiftStatus.NO_LONGER_RELEVANT, shift: null };
        } else {
            // If both are available, return next if the last is OVER and whether next is today
            const nextShiftIsToday = isSameDate(new Date(), next!.start);
            if (lastStatus === NextShiftStatus.OVER && nextShiftIsToday) return { status: nextStatus, shift: next! };
            // Otherwise, return last if it's still relevant
            if (lastStatus !== NextShiftStatus.NO_LONGER_RELEVANT) return { status: lastStatus, shift: last! };
            // Finally return the next
            return { status: nextStatus, shift: next! };
        }
    }

    /**
     * Helper method for getting the status of the next shift (with a start in the future)
     */
    private getNextStatus(next: Shift | undefined, punchclockConfig: PunchclockConfig): NextShiftStatus | null {
        if (!next) return null;
        const { autoPunchClockEnabled, checkinBefore }: PunchclockConfig = punchclockConfig;

        // The next shift is over if it's been checked out
        if (next.checkout) return NextShiftStatus.OVER;

        // The next shift is in progress if it's been check in
        if (next.checkin) return NextShiftStatus.IN_PROGRESS;

        // The next shift is ready for checkin if auto punchclock is enabled, and checkin is allowed at this moment
        const checkinAllowed: boolean = moment(next.start).subtract(checkinBefore || 0, 'minutes').isBefore(moment());
        if (autoPunchClockEnabled && checkinAllowed) return NextShiftStatus.READY_FOR_CHECKIN;

        // The next shift has status future, if none of the above
        return NextShiftStatus.FUTURE;
    }

    /**
     * Helper method for getting the status of the last shift (with a start in the past)
     */
    private getLastStatus(last: Shift | undefined, punchclockConfig: PunchclockConfig): NextShiftStatus | null {
        if (!last) return null;
        const { autoPunchClockEnabled, checkoutUntil }: PunchclockConfig = punchclockConfig;

        // If last shift is not today, it's no longer relevant
        const shiftIsToday = isSameDate(last.start, new Date()) || isSameDate(last.end, new Date());
        if (!shiftIsToday) return NextShiftStatus.NO_LONGER_RELEVANT;

        // If last shift has been checked out or if it has ended and checkout no longer allowed, it's over
        const shiftEndedAndCheckoutNotPossible = moment(last.end).add(checkoutUntil || 0, 'minutes').isBefore(moment());
        if (last.checkout || shiftEndedAndCheckoutNotPossible) return NextShiftStatus.OVER;

        // If last shift has been checked in or if punchclock's disabled, the shift is in progress
        if (last.checkin || !autoPunchClockEnabled) return NextShiftStatus.IN_PROGRESS;

        // If last shift is none of the above, it's ready for checkin
        return NextShiftStatus.READY_FOR_CHECKIN;
    }

    /**
     * Sets up the refresher (i.e. when to reload the punchclock shifts) based on the status of the shift
     */
    private async setupRefresher({ status, shift }: ShiftAndStatus): Promise<void> {
        if (!shift) return;
        const { checkoutUntil, checkinBefore }: PunchclockConfig =
            await getPunchClockSettings(this.companySettingsService).pipe(take(1)).toPromise();

        let refreshTime: Moment | undefined;
        switch (status) {
            // If shift's in progress or ready for checkin, refresh when the shift is over (and checkout no longer possible)
            case NextShiftStatus.IN_PROGRESS:
            case NextShiftStatus.READY_FOR_CHECKIN:
                refreshTime = moment(shift.end).add(checkoutUntil || 0, 'minutes');
                break;
            // If shift's in the future, refresh when shift (or checkin possibility) starts
            case NextShiftStatus.FUTURE:
                refreshTime = moment(shift.start).subtract(checkinBefore || 0, 'minutes');
                break;
        }
        // If we should refresh later than right now, set the timer
        if (refreshTime?.isAfter(moment())) {
            // Set the refresh time to a maximum of one hour to avoid integer overflows
            const cappedRefreshTime = moment.min(refreshTime, moment().add(1, 'hour')).toDate();
            timer(cappedRefreshTime).subscribe(() => this.updateNextShift$.next());
        }
    }

    /**
     * Setup the static and dynamic duration texts (and shift progress)
     */
    private setupDurationTexts({ shift }: ShiftAndStatus): void {
        if (!shift) return;
        const [start, end]: [Date, Date] = [shift.checkin?.time || shift.start, shift.checkout?.time || shift.end];

        this.staticDuration = this.getFormattedDuration(start, end);

        this.dynamicDuration$ = timer(0, 1000).pipe(map(() => ({
            text: this.getFormattedDuration(new Date(), start),
            // Progress is set to the percentage of the shift that's over
            progress: ((new Date().getTime() - start.getTime()) / (end.getTime() - start.getTime())) * 100,
        })));
    }

    /**
     * Get a duration string formatted as HH:mm:ss between two dates
     */
    private getFormattedDuration(a: Date, b: Date): string {
        const diffMillis: number = Math.abs(b.getTime() - a.getTime());

        const ss: number = (diffMillis / 1000) % 60;
        const mm: number = (diffMillis / (1000 * 60)) % 60;
        const hh: number = diffMillis / (1000 * 60 * 60);

        const pad = (x: number) => x < 10 ? '0' + x : x.toString();
        const floorPad = (x: number) => pad(Math.floor(x));

        return floorPad(hh) + ':' + floorPad(mm) + ':' + floorPad(ss);
    }
}
