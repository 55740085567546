import { Injectable } from '@angular/core';
import { AngularFirestoreDocument, DocumentReference, DocumentSnapshot, Query, QueryDocumentSnapshot, QuerySnapshot } from '@angular/fire/firestore';
import { AngularFireFunctions } from '@angular/fire/functions';
import firebase from 'firebase/app';
import { Observable, Subscriber } from 'rxjs';
import { take } from 'rxjs/operators';
import { EmployeeService, FirestoreService, ShiftForSaleService, ShiftService } from '../..';
import { Bid, Company, Employee, Shift, ShiftForSale, ShiftForSaleStatus } from '../../../model';
import { Department } from '../../../model/Department/Department.model';
import { FirestoreShiftForSale } from '../../../model/Firestore/FirestoreShiftForSale';
import Timestamp from '../../../model/Firestore/FirestoreTimestamp';
import Transaction = firebase.firestore.Transaction;

type DocSnap<T> = DocumentSnapshot<T> | QueryDocumentSnapshot<T>;

@Injectable({
    providedIn: 'root',
})
export class FirestoreShiftForSaleService extends ShiftForSaleService {
    private companyDoc: AngularFirestoreDocument<Company>;

    constructor(private firestoreService: FirestoreService,
        private employeeService: EmployeeService,
        private shiftService: ShiftService,
        private fns: AngularFireFunctions) {
        super();
    }

    /**
     * Returns an `Observable` of an `Array` of `ShiftsForSale` for a certain `Employee` and with a certain `Status`.
     * If no `Employee` is given this method will return all `ShiftsForSale` with the given status.
     * If no `Status` is given, this method will return all `ShiftsForSale`
     * @param employee??  The `Employee` whose `ShiftForSale` to return.
     * @param status?? The `Status` of the `ShiftForSale` objects to be returned
     * @param buyer?? The employee who wants to see `ShiftForSale` that he can take.
     * @param from?? The date where the Shift of the ShiftForSale needs to be after
     */
    public getShiftsForSale(employee?: Employee, status?: ShiftForSaleStatus, buyer?: Employee, from?: Date, department?: Department)
        : Observable<ShiftForSale[]> {
        return Observable.create((observer: Subscriber<ShiftForSale[]>) => {
            this.doWhenCompanyDocReady(() => {
                this.fns.httpsCallable('checkPendingShiftsForSale')({}).subscribe(_ => { }, err => console.error(err));

                let query: Query = this.companyDoc.collection('shiftsForSale').ref;
                query = employee ? query.where('sellerID', '==', employee.id) : query;
                query = typeof status === 'number' ? query.where('status', '==', status) : query;
                query.onSnapshot((querySnapshot: QuerySnapshot<FirestoreShiftForSale>) => {
                    Promise.all(querySnapshot.docs.map((queryDocumentSnapshot: QueryDocumentSnapshot<FirestoreShiftForSale>) =>
                        this.convertDocumentSnapshotToShiftForSale(queryDocumentSnapshot)))
                        .then((shiftsForSale: ShiftForSale[]) => {
                            shiftsForSale = from ? shiftsForSale.filter(shiftForSale => shiftForSale.shift.start >= from) : shiftsForSale;
                            shiftsForSale = department
                                ? shiftsForSale.filter(shiftForSale => shiftForSale.shift.role.department.id == department.id)
                                : shiftsForSale;
                            if (buyer) {
                                this.shiftService.getFutureShiftsForEmployee(buyer).subscribe({
                                    next: (workingShifts: Shift[]): void => {
                                        shiftsForSale = this.filterConflicting(shiftsForSale, workingShifts);
                                        observer.next(shiftsForSale.sort((sfs1, sfs2) => ShiftService.compare(sfs1.shift, sfs2.shift)));
                                    },
                                    error: observer.error.bind(observer),
                                });
                            } else observer.next(shiftsForSale.sort((sfs1, sfs2) => ShiftService.compare(sfs1.shift, sfs2.shift)));
                        })
                        .catch((err: Error): void => observer.error(err));
                });
            }).catch((err: Error): void => observer.error(err));
        });
    }

    /**
     * Adds a `ShiftForSale` to the database, as well as updating the `Shift` so it's for sale.
     * @param shift  The `Shift` to be put up for sale.
     */
    public createShiftForSale(shift: Shift): Promise<ShiftForSale> {
        return this.doWhenCompanyDocReady(() => {
            const shiftDocumentReference: DocumentReference = this.companyDoc.collection('shifts').doc(shift.id).ref;

            if (!shift.employee) throw Error('Cannot put shift up for sale without employee');
            return this.createShiftForSaleTransaction(shiftDocumentReference, shift.employee)
                .then((shiftForSale: ShiftForSale) => {
                    shiftForSale.shift = shift;
                    return shiftForSale;
                });
        });
    }

    /**
     * Updates a `ShiftForSale` in the database, to reflect frontend changes.
     * @param shiftForSale  The altered `ShiftForSale`.
     */
    public updateShiftForSale(shiftForSale: ShiftForSale): Promise<ShiftForSale> {
        return this.doWhenCompanyDocReady(() => {
            const firestoreShiftForSale: FirestoreShiftForSale = this.firestoreShiftForSaleFromShiftForSale(shiftForSale);
            return this.companyDoc.collection('shiftsForSale').doc(shiftForSale.id).update(firestoreShiftForSale)
                .then(() => this.companyDoc.collection('shiftsForSale').doc(shiftForSale.id).ref.get()
                    .then((firestoreShiftForSaleDocumentSnapshot: DocumentSnapshot<FirestoreShiftForSale>) =>
                        this.convertDocumentSnapshotToShiftForSale(firestoreShiftForSaleDocumentSnapshot),
                    ));
        });
    }

    /**
     * Revokes a `ShiftForSale`.
     * @param shiftForSale  The `ShiftForSale` to be revoked.
     */
    public revokeShiftForSale(shiftForSale: ShiftForSale): Promise<ShiftForSale> {
        shiftForSale.status = ShiftForSaleStatus.REVOKED;
        shiftForSale.closedOn = new Date();
        return this.updateShiftForSale(shiftForSale);
    }

    /**
     * Adds an `Employee` to a `ShiftForSale` as a bidder.
     * @param shiftForSale  The `ShiftForSale` on which to bid.
     * @param employee  The bidding `Employee`.
     */
    public bidOnShiftForSale(shiftForSale: ShiftForSale, employee: Employee): Promise<ShiftForSale> {
        return this.doWhenCompanyDocReady(() => {
            if (shiftForSale.bids.find((bid: Bid) => bid.bidder === employee)) {
                return Promise.reject('employee, is already bidding on the shiftForSale');
            }
            shiftForSale.bids.push({ bidder: employee, timeOfBid: new Date() });
            return this.updateShiftForSale(shiftForSale);
        });
    }

    /**
     * Returns a shiftForSale object, if the shift is for sale, otherwise returns null
     * @param shift The shift to get the shiftForSale object for
     */
    public getShiftForSaleByShift(shift: Shift): Observable<ShiftForSale | null> {
        return new Observable((observer: Subscriber<ShiftForSale | null>) => {
            this.doWhenCompanyDocReady(() => this.companyDoc.collection('shiftsForSale').ref
                .where('shiftID', '==', shift.id)
                .where('status', '==', 0)
                .onSnapshot({
                    next: async (fssfs: QuerySnapshot<FirestoreShiftForSale>) =>
                        observer.next(fssfs.docs[0]
                            ? await this.convertDocumentSnapshotToShiftForSale(fssfs.docs[0])
                            : null),
                    error: observer.error.bind(observer),
                    complete: observer.complete.bind(observer),
                }));
        });
    }

    /**
     * Closes a shift for sale by accepting a buyer.
     * @param shiftForSale
     * @param employee
     */
    public acceptShiftForSale(shiftForSale: ShiftForSale, employee: Employee): Promise<ShiftForSale> {
        shiftForSale.buyer = employee;
        shiftForSale.status = ShiftForSaleStatus.ACCEPTED;
        shiftForSale.closedOn = new Date();
        return this.updateShiftForSale(shiftForSale);
    }

    /**
     * Declines the pending `ShiftForSale`
     * @param shiftForSale
     */
    public rejectShiftForSale(shiftForSale: ShiftForSale): Promise<ShiftForSale> {
        shiftForSale.closedOn = new Date();
        shiftForSale.status = ShiftForSaleStatus.DECLINED;
        return this.updateShiftForSale(shiftForSale);
    }

    /**
     * Removes an employees bid on a `ShiftForSale`
     * @param shiftForSale The shift for sale to remove the bid from.
     * @param employee The employee for whom to remove the bid.
     */
    public removeBidOnShiftForSale(shiftForSale: ShiftForSale, employee: Employee): Promise<ShiftForSale> {
        const index: number = shiftForSale.bids.findIndex((bid: Bid) => bid.bidder.id === employee.id);
        shiftForSale.bids.splice(index, 1);
        return this.updateShiftForSale(shiftForSale);
    }

    /**
     * Delay an action on the collection, until the collection has been loaded (arrives).
     * @param cb  The callback to be executed.
     */
    private doWhenCompanyDocReady<T>(cb: () => T | Promise<T>): Promise<T> {
        return this.firestoreService.whenReady()
            .then((cd: AngularFirestoreDocument<Company>) => {
                this.companyDoc = cd;
                return cb();
            });
    }

    /**
     * Converts a `DocumentSnapshot` or a `QueryDocumentSnapshot` to a `ShiftForSale` and returns it as a `Promise`.
     * @param documentSnapshot  The `DocumentSnapshot` or `QueryDocumentSnapshot` to be converted.
     * @param shift  The `Shift` to be added to the `ShiftForSale`.
     */
    private convertDocumentSnapshotToShiftForSale(documentSnapshot: DocSnap<FirestoreShiftForSale>): Promise<ShiftForSale> {


        const docData = documentSnapshot.data();
        if (!docData) throw Error('Cannot convert to shiftforsale from empty doc with id' + documentSnapshot.id);

        const sellerID: string | null = docData.sellerID;

        const buyerID: string | null = docData.buyerID;

        const fsBids: Map<string, Timestamp> = new Map(Object.entries(docData.bids));

        const shiftID = docData.shiftID;

        const sfs: Omit<ShiftForSale, 'shift'> = this.addInfoToShiftForSale(documentSnapshot);
        return this.addShiftToShiftForSale(sfs, shiftID)
            .then((shiftForSale: ShiftForSale) => this.addSellerToShiftForSale(shiftForSale, sellerID))
            .then((shiftForSale: ShiftForSale) => this.addBuyerToShiftForSale(shiftForSale, buyerID))
            .then((shiftForSale: ShiftForSale) => this.addBidsToShiftForSale(shiftForSale, fsBids));
    }

    /**
     * Adds info to a `ShiftForSale`.
     * @param documentSnapshot  The `DocumentSnapshot` or `QueryDocumentSnapshot` to with the info.
     * @param shift  The `Shift` to be added to the `ShiftForSale`.
     */
    private addInfoToShiftForSale(documentSnapshot: DocSnap<FirestoreShiftForSale>): Omit<ShiftForSale, 'shift'> {
        const docData = documentSnapshot.data();
        if (!docData) throw Error('Cannot add info to shiftforsale from empty doc with id' + documentSnapshot.id);

        return {
            id: documentSnapshot.id,
            description: docData.description || undefined,
            bids: [],
            status: docData.status,
            createdOn: docData.createdOn.toDate(),
            closedOn: docData.closedOn?.toDate() || undefined,
        };
    }

    /**
     * Adds a `Shift` to a `ShiftForSale`.
     * @param shiftForSale  The `ShiftForSale` to add the Shift to.
     * @param shiftID  The id of the `Shift`.
     */
    private async addShiftToShiftForSale(shiftForSale: Omit<ShiftForSale, 'shift'>, shiftID: string): Promise<ShiftForSale> {
        const shift = await this.shiftService.getShift(shiftID).pipe(take(1)).toPromise();
        return ({ ...shiftForSale, shift });
    }

    /**
     * Adds an `Employee` as a seller to a `ShiftForSale`.
     * @param shiftForSale  The `ShiftForSale` to add the seller to.
     * @param sellerID  The id of the `Employee`.
     */
    private async addSellerToShiftForSale(shiftForSale: ShiftForSale, sellerID: string | null): Promise<ShiftForSale> {
        if (!sellerID) return shiftForSale;

        return this.employeeService.getEmployee(sellerID).pipe(take(1)).toPromise()
            .then((sellerResult: Employee) => {
                shiftForSale.seller = sellerResult;
                return shiftForSale;
            });
    }

    /**
     * Adds an `Employee` as a buyer to a `ShiftForSale`.
     * @param shiftForSale  The `ShiftForSale` to add the buyer to.
     * @param buyerID  The id of the `Employee`.
     */
    private async addBuyerToShiftForSale(shiftForSale: ShiftForSale, buyerID: string | null): Promise<ShiftForSale> {
        if (!buyerID) return shiftForSale;

        return this.employeeService.getEmployee(buyerID).pipe(take(1)).toPromise()
            .then((buyerResult: Employee) => {
                shiftForSale.buyer = buyerResult;
                return shiftForSale;
            });
    }

    /**
     * Converts an `Array` of `FirestoreBids` to `Bids` and adds them to a `ShiftForSale`.
     * @param shiftForSale  The `ShiftForSale` to add the `Bids` to.
     * @param fsBids  The `Array` of `FirestoreBids` to be converted and added to the given `ShiftForSale`.
     */
    private addBidsToShiftForSale(shiftForSale: ShiftForSale, fsBids: Map<string, Timestamp>): Promise<ShiftForSale> {
        const bidsPromise: Promise<Bid>[] = [];

        fsBids.forEach((ts: Timestamp, id: string) => bidsPromise.push(this.convertFirestoreBidToBid(id, ts)));
        return Promise.all(bidsPromise)
            .then((bids: Bid[]) => {
                shiftForSale.bids = bids.sort((a: Bid, b: Bid) => a.timeOfBid.getTime() - b.timeOfBid.getTime());
                return shiftForSale;
            });
    }

    /**
     * Converts an employee and Timestamp to Bid
     * @param `employeeID` the id of the employee
     * @param `timeOfBid` the timestamp when the bid was created
     **/
    private convertFirestoreBidToBid(employeeID: string, timeOfBid: Timestamp): Promise<Bid> {
        return this.employeeService.getEmployee(employeeID).pipe(take(1)).toPromise()
            .then((employee: Employee) => {
                return {
                    bidder: employee,
                    timeOfBid: timeOfBid.toDate(),
                };
            });
    }

    /**
     * Runs a `Transaction` to create a `ShiftForSale` and update it's respective `Shift`.
     * @param shiftDocRef  A `DocumentReference` to the `Shift`.
     * @param employee  The `Employee` selling the `Shift`.
     */
    private createShiftForSaleTransaction(shiftDocRef: DocumentReference, employee: Employee): Promise<Omit<ShiftForSale, 'shift'>> {
        const shiftForSaleDocRef: DocumentReference = this.companyDoc.collection('shiftsForSale').ref.doc();
        return this.companyDoc.ref.firestore.runTransaction((transaction: Transaction) =>
            transaction.get(shiftDocRef)
                .then((shiftSnap: DocumentSnapshot<Shift>) => {
                    if (shiftSnap.exists) {
                        const firestoreShiftForSale: FirestoreShiftForSale = this.newFirestoreShiftForSale(shiftSnap.id, employee.id);
                        transaction.set(shiftForSaleDocRef, firestoreShiftForSale)
                            .update(shiftDocRef, { shiftForSaleID: shiftForSaleDocRef.id });
                        return Promise.resolve(this.newShiftForSale(shiftForSaleDocRef.id, firestoreShiftForSale, employee));
                    }
                    return Promise.reject('Transaction Error:\nShift doesn\'t exist');
                }));
    }

    /**
     * Creates a new `FirestoreShiftForSale` to be added to the database.
     * @param shiftID  The id of the `Shift` being put up for sale.
     * @param employeeID  The `Employee` selling their `Shift`.
     */
    private newFirestoreShiftForSale(shiftID: string, employeeID: string): FirestoreShiftForSale {
        return {
            description: null,
            shiftID: shiftID,
            createdOn: Timestamp.now(),
            closedOn: null,
            sellerID: employeeID,
            buyerID: null,
            bids: {},
            status: ShiftForSaleStatus.PENDING,
        };
    }

    /**
     * Creates a new `ShiftForSale` from a `FirestoreShiftForSale`.
     * @param id  The id of the `ShiftForSale`.
     * @param firestoreShiftForSale  The `FirestoreShiftForSale` to model the `ShiftForSale` after.
     * @param employee  The `Employee` selling the `ShiftForSale`.
     */
    private newShiftForSale(id: string, firestoreShiftForSale: FirestoreShiftForSale, employee: Employee): Omit<ShiftForSale, 'shift'> {
        return {
            id: id,
            description: firestoreShiftForSale.description || undefined,
            createdOn: firestoreShiftForSale.createdOn.toDate(),
            seller: employee,
            bids: [],
            status: ShiftForSaleStatus.PENDING,
        };
    }

    /**
     * Creates a `FirestoreShiftForSale` Modelled after a `ShiftForSale`.
     * @param shiftForSale  The `ShiftForSale` to model the `FirestoreShiftForSale` after.
     */
    private firestoreShiftForSaleFromShiftForSale(shiftForSale: ShiftForSale): FirestoreShiftForSale {
        const bids = {};
        shiftForSale.bids.forEach(bid => bids[bid.bidder.id] = Timestamp.fromDate(bid.timeOfBid));
        return {
            description: (shiftForSale.description) ? shiftForSale.description : null,
            shiftID: shiftForSale.shift.id,
            createdOn: Timestamp.fromDate(shiftForSale.createdOn),
            closedOn: (shiftForSale.closedOn) ? Timestamp.fromDate(shiftForSale.closedOn) : null,
            sellerID: shiftForSale.seller?.id || null,
            buyerID: (shiftForSale.buyer) ? shiftForSale.buyer.id : null,
            bids: bids,
            status: shiftForSale.status,
        };
    }

    /**
     * Filter out the shifts for sale where the employee is already working.
     * @param shiftsForSale The array `ShiftForSale`
     * @param workingShifts The array of `Shift`
     */
    private filterConflicting(shiftsForSale: ShiftForSale[], workingShifts: Shift[]): ShiftForSale[] {
        return shiftsForSale.filter(
            sfs => !workingShifts.find(shift => ShiftService.conflictingShifts(shift, sfs.shift)),
        );
    }
}
