import { Employee, Shift } from '../model';

/**
 * The ShiftForSale interface, reflecting the structure of a ShiftForSale in the application
 */
export interface ShiftForSale {
    id?: string;
    description?: string;
    shift: Shift;
    createdOn: Date;
    closedOn?: Date;
    seller?: Employee; // Employee som sælger shift
    buyer?: Employee; // Employee som køber shift
    bids: Bid[]; // Alle bud på vagten
    status: ShiftForSaleStatus;
}

/**
 * The Bid interface, reflecting the structure of a bid in the application
 */
export interface Bid {
    bidder: Employee;
    timeOfBid: Date;
}

/**
 * The ShiftForSaleStatus enum, listing the statuses of a ShiftForSale
 */
export enum ShiftForSaleStatus {
    PENDING,
    ACCEPTED,
    DECLINED,
    REVOKED,
    EXPIRED,
}
