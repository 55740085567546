import { Employee, Shift, ShiftBreak } from '../../core/model';
import { DanloenExportData, DanloenPayPart } from '../../core/model/Integrations/Danloen';
import { SalarySupplement } from '../../core/model/SalarySupplement.model';
import { SalaryService } from '../../core/services/salary.service';
import { getShiftDuration } from './ShiftUtils/durationUtils';

type ShiftWithEmployee = Shift & { employee: Employee };

/**
 * Generates Danløn a payparts list with salary information of each employee
 * @param mappedEmployees - The employees (with Danløn id mapping) to generate payparts for
 * @param shifts - List of all shifts being exported
 * @param shiftBreak - The shiftBreak of the company (if any)
 * @param supplements - List of the company's supplements
 */
export function generateDanloenPayParts(
    mappedEmployees: [Employee, string][],
    shifts: Shift[],
    shiftBreak: ShiftBreak | null,
    supplements: SalarySupplement[],
): DanloenExportData {
    // List of payparts with one or two entries per employee (two if employee has supplements in the period)
    const payparts: DanloenPayPart[] = [];
    const shiftIDs: string[] = [];

    // Go through each of the employees
    mappedEmployees.forEach(([mappedEmployee, danloenID]: [Employee, string]) => {
        // Return if no hourly wage is set
        if (!mappedEmployee.hourlyWage) return;

        // Init the pay parts
        const hourlyWagePayPart: DanloenPayPart = initHourlyWagePart(danloenID, mappedEmployee);
        const supplementPayPart: DanloenPayPart = initSupplementPart(danloenID);

        // Go through each of the employee's shifts
        shifts.filter((shift: Shift) => shift.employee?.id === mappedEmployee.id).forEach(
            (shift: ShiftWithEmployee) => {
                // Increment the hourly wage units by the duration of the shift (breaks subtracted)
                hourlyWagePayPart.units += getShiftDuration(shift, shiftBreak);
                // Increment the supplement amount by the supplement of the shift
                supplementPayPart.amount += SalaryService.shiftToSupplement(shift, supplements, shiftBreak);
                // Add the shift id to the list
                shiftIDs.push(shift.id);
            });

        // Add the parts if they have a value > 0
        if (hourlyWagePayPart.units > 0) payparts.push(hourlyWagePayPart);
        if (supplementPayPart.amount > 0) payparts.push(supplementPayPart);
    });

    // Return the list of payparts
    return { payparts, shiftIDs };
}


// Init the hourly wage part with correct hourly wage and units = 0
const initHourlyWagePart = (danloenID: string, mappedEmployee: Employee) => ({
    'employee-id': danloenID,
    code: 't4',
    rate: mappedEmployee.hourlyWage!,
    units: 0,
});

// Init the supplement part with amount = 0
const initSupplementPart = (danloenID: string) => ({
    'employee-id': danloenID,
    code: 't5',
    amount: 0,
});
