import { Component, Input } from '@angular/core';
import { SalaryIntegration } from '../../../../core/model/Integrations/Shared';
import { SalaryExport } from '../../../../core/model/SalaryExport';

@Component({
  selector: 'app-export-log',
  templateUrl: './export-log.component.html',
  styleUrls: ['./export-log.component.scss'],
})
export class ExportLogComponent {
  public SalaryIntegration: typeof SalaryIntegration = SalaryIntegration;
  @Input() public salaryExport: SalaryExport;
  @Input() public iconClass: string;

}
