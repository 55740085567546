<div id="topArea"
     [ngClass]="{'hide-bottom-border': hideBottomBorder}">

    <div class="d-flex flex-row w-100">
        <div id="headerDivider"
             class="d-flex flex-row w-100">
            <ng-container [ngTemplateOutlet]="content"></ng-container>
        </div>
    </div>
</div>
<ng-template #content>
    <ng-content select=".left"></ng-content>
    <ng-content select=".center"></ng-content>
    <ng-content select=".right"></ng-content>
</ng-template>