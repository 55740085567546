<div class="matching-container d-flex flex-column">
    <div class="logo-container d-flex justify-content-between align-items-end">
        <img alt="Danløn Logo"
             [src]="integration | getIntegrationLogo">
        <ui-button-text (click)="openHelpModal()"><span>{{ 'integrations.danloen.matching-help' | translate }}</span>
        </ui-button-text>
    </div>
    <overlay-scrollbars [options]="{scrollbars: {autoHide: 'never'}}"
                        class="employee-list">
        <div class="employee-card d-flex flex-row align-items-center"
             *ngFor="let employee of ignorableEmployees">
            <mat-checkbox [disableRipple]="true"
                          [(ngModel)]="employee.notIgnored"
                          (ngModelChange)="checkEmployeeMapping()"></mat-checkbox>
            <img [lazyLoad]="employee.imageURL">
            <span>{{ employee.firstname + ' ' + employee.lastname }}</span>
            <input [(ngModel)]="employee.mapping[employee.id]"
                   (ngModelChange)="checkEmployeeMapping()"
                   [appFloatingLabel]="'integrations.danloen.enter-employee-number' | translate"
                   inputColor="gray">
        </div>
    </overlay-scrollbars>
    <div class="button-container d-flex justify-content-end">
        <ui-button-standard (click)="modalRef.close()">{{'shared.cancel' | translate}}</ui-button-standard>
        <ui-button-action *ngIf="allEmployeesMapped"
                          (click)="saveMapping()">{{'shared.complete' | translate}}</ui-button-action>
        <ui-button-standard *ngIf="!allEmployeesMapped"
                            (click)="saveMapping()">{{'shared.complete' | translate}}</ui-button-standard>
    </div>
</div>