import { Component, Input, OnInit } from '@angular/core';
import { MediaService } from '../../../core/services';
import { BackgroundColor } from '../colors/BackgroundColor';

@Component({
    selector: 'ui-widget',
    templateUrl: './widget.component.html',
    styleUrls: ['./widget.component.scss'],
})
export class WidgetComponent implements OnInit {
    @Input() public title: string; // Title of the widget
    @Input() public iconClass: string; // The icon in the topleft corner e.g. ('fa fa-clock')
    @Input() public iconBgClass: BackgroundColor; // Background color of the icon to the top left
    @Input() public totalItems: number; // Number of entries in total
    // Attribute to be set if the widget should not be expandable, e.g. <ui-widget nonExpandable></ui-widget>
    @Input() public nonExpandable: string | undefined;
    public isBigScreen: boolean;
    public isFullScreen: boolean;

    constructor(private mediaService: MediaService) { }

    public ngOnInit(): void {
        this.mediaService.observeMediaChanges().subscribe({ next: (bigScreen: boolean): boolean => this.isBigScreen = bigScreen });
    }
}
