import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { combineLatest } from 'rxjs';
import { map, pluck, take } from 'rxjs/operators';
import { Employee } from '../../../../../../../../../../core/model';
import { EmployeeMapping, SalaryIntegration } from '../../../../../../../../../../core/model/Integrations/Shared';
import { EmployeeService } from '../../../../../../../../../../core/services';
import { IntegrationSettingsService } from '../../../../../../../../../../core/services/Settings/IntegrationSettings/Firestore/integration-settings.service';
import { LazyLoadImageProviderFactory } from '../../../../../../../../../../shared/factories/LazyLoadImageHooks';
import { BackgroundColor } from '../../../../../../../../../../shared/ui/colors/BackgroundColor';
import { SalaryDkWhyMatchModalComponent } from '../salary-dk-why-match-modal/salary-dk-why-match-modal.component';

type IgnorableEmployee = Employee & { ignored: boolean };
@Component({
    templateUrl: './salary-dk-integration-ignore-modal.component.html',
    styleUrls: ['./salary-dk-integration-ignore-modal.component.scss'],
    providers: [LazyLoadImageProviderFactory(1)],
})
export class SalaryDkIntegrationIgnoreModalComponent implements OnInit {
    public BackgroundColor: typeof BackgroundColor = BackgroundColor;
    public ignorableEmployees: IgnorableEmployee[] = [];
    public integration: SalaryIntegration = SalaryIntegration.SALARYDK;

    constructor(
        @Inject(MAT_DIALOG_DATA) private newEmployeeMappings: EmployeeMapping[],
        private employeeService: EmployeeService,
        private integrationSettingsService: IntegrationSettingsService,
        private modal: MatDialog,
        private modalRef: MatDialogRef<SalaryDkIntegrationIgnoreModalComponent>,
    ) { }

    public async ngOnInit(): Promise<void> {
        this.ignorableEmployees = await combineLatest([
            this.employeeService.getEmployees().pipe(
                // Find unmapped employees
                map((employees: Employee[]) => employees.filter((employee: Employee) =>
                    !this.newEmployeeMappings.find((mapping: EmployeeMapping) => mapping[employee.id])))),
            // Load saved employee mappings
            this.integrationSettingsService.loadSetting(this.integration).pipe(pluck('employeeMap')),
        ]).pipe(
            // Add ignored field on employees
            map(this.addIgnoredFieldOnEmployees, this),
            take(1),
        ).toPromise();
    }

    public saveMatches(): void {
        const employeesToSave = this.newEmployeeMappings.filter((mapping: EmployeeMapping) => {
            return this.employeeIsMapped(mapping)
                || this.employeeIsIgnored(mapping);
        });
        this.integrationSettingsService.saveSetting(this.integration, { employeeMap: employeesToSave })
            .pipe(take(1)).subscribe();
        this.close();
    }

    public toggleIgnoredEmployee(employee: IgnorableEmployee): void {
        employee.ignored = !employee.ignored;
    }

    public openHelpModal(): void {
        this.modal.open(SalaryDkWhyMatchModalComponent);
    }

    public close(): void {
        this.modalRef.close();
    }

    /**
     * Adds an "ignored" field on the given employees indicating whether or not they have been ignored
     * @param unmappedEmployees The employees that have not been mapped automatically
     * @param loadedEmployeeMappings The previous mappings from settings
     */
    private addIgnoredFieldOnEmployees([unmappedEmployees, loadedEmployeeMappings]: [Employee[], EmployeeMapping[] | undefined])
        : IgnorableEmployee[] {
        return unmappedEmployees.map((employee: Employee) => ({
            ...employee,
            ignored: !!loadedEmployeeMappings
                && !!loadedEmployeeMappings.find((mapping: EmployeeMapping) => this.relionIDFromMapping(mapping) === employee.id),
        }));
    }

    /**
     * Returns a boolean indicating whether or not the mapped employee is ignored
     */
    private employeeIsIgnored(mapping: EmployeeMapping): boolean {
        return !!this.ignorableEmployees.find((employee: IgnorableEmployee) =>
            this.relionIDFromMapping(mapping) === employee.id && employee.ignored);
    }

    private employeeIsMapped(mapping: EmployeeMapping): boolean {
        return !!Object.values(mapping)[0];
    }

    private relionIDFromMapping(mapping: EmployeeMapping): string {
        return Object.keys(mapping)[0]!;
    }

}
