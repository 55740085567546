import { Component } from '@angular/core';



@Component({
    selector: 'app-salary-settings',
    templateUrl: './salary-settings.component.html',
    styleUrls: ['./salary-settings.component.scss'],
})
export class SalarySettingsComponent {


}
