import { Injectable, Injector } from '@angular/core';
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';
import { DepartmentQuery } from './Department/state/department.query';
import { AkitaDepartmentService } from './Department/state/department.service';
import { AkitaEmployeeService } from './Employee/state/employee.service';
import { RoleQuery } from './Role/state/role.query';
import { AkitaRoleService } from './Role/state/role.service';

@Injectable({
    providedIn: 'root',
})
export class AkitaSyncServiceService {
    public collectionSyncSubs: Subscription[];

    constructor(private injector: Injector) { }

    public async startCollectionSync(): Promise<void> {
        this.collectionSyncSubs = [];
        // Sync departments
        this.collectionSyncSubs.push(this.injector.get(AkitaDepartmentService).syncCollection().subscribe());
        // Sync roles after loading departments
        await this.injector.get(DepartmentQuery).selectLoading().pipe(first((isLoading: boolean) => !isLoading)).toPromise();
        this.collectionSyncSubs.push(this.injector.get(AkitaRoleService).syncCollection().subscribe());
        // Sync employees after loading roles
        await this.injector.get(RoleQuery).selectLoading().pipe(first((isLoading: boolean) => !isLoading)).toPromise();
        this.collectionSyncSubs.push(this.injector.get(AkitaEmployeeService).syncCollection().subscribe());
    }

    public stopCollectionSync(): void {
        this.collectionSyncSubs.map((sub: Subscription) => sub.unsubscribe());
    }

}
