// CRUD is the way to build services

import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import * as Moment from 'moment';
import { DateRange, extendMoment, MomentRange } from 'moment-range';
import { combineLatest, Observable, of } from 'rxjs';
import { map, pluck } from 'rxjs/operators';
import { WeekdayShift } from '../../../modules/admin/week-view/web/pages/week-view/week-view.component';
import { Department, Employee, filterByDateRange, filterByDepartment, filterEnded, isShift, Role, Shift, sortShiftsByRoleName, sortShiftsByTime } from '../../model';
import { Holiday } from '../../model/Holiday';
import { GroupShiftsBy } from '../../model/UserSettings';
import { Weekday } from '../../model/Weekday';
import { DateService } from '../date.service';
const moment: MomentRange = extendMoment(Moment);

/**
 * Used with ShiftQueryParams to specify how to order the shifts in a database query.
 */
export enum OrderBy {
    ASCENDING = 'asc',
    DESCENDING = 'desc',
}

@Injectable({
    providedIn: 'root',
})
export abstract class ShiftService {

    constructor() { }

    /**
     * Checks if two shifts have overlapping work hours
     * @param shift1 First shift
     * @param shift2 Second shift
     */
    public static conflictingShifts(shift1: Shift, shift2: Shift): boolean {
        const range1: DateRange = moment.range(shift1.start, shift1.end);
        const range2: DateRange = moment.range(shift2.start, shift2.end);

        return range1.overlaps(range2);
    }

    /**
     * Checks if two shifts violate 11 hours rule
     * @param shift1
     * @param shift2
     */
    public static violates11HoursRule(shift1: Shift, shift2: Shift): boolean {
        const range1: DateRange = moment.range(shift1.start, moment(shift1.end).add(11, 'hours').toDate());
        const range2: DateRange = moment.range(shift2.start, shift2.end);
        const range3: DateRange = moment.range(shift1.start, shift1.end);
        const range4: DateRange = moment.range(shift2.start, moment(shift2.end).add(11, 'hours').toDate());

        return range1.overlaps(range2) || range3.overlaps(range4);
    }

    /**
     * Returns true if the two given shifts are the same, otherwise returns false
     * @param shift1 The first shift to compare
     * @param shift2 The second shift to compare
     */
    public static equals(shift1: Shift | Omit<Shift, 'id'>, shift2: Shift | Omit<Shift, 'id'>): boolean {
        if (isShift(shift1) && isShift(shift2)) {
            return shift1.id === shift2.id;
        }

        return _.isEqual(_.omit(shift1, 'id'), _.omit(shift2, 'id'));
    }

    /**
     * Compares two shifts. Sorts them by role id and then start and then end
     * @param shift1 - The first shift to compare
     * @param shift2 - The second shift to compare
     */
    public static compare(shift1: Shift, shift2: Shift): number {
        if (shift1.role.id === shift2.role.id) {
            if (shift1.start.getTime() === shift2.start.getTime()) {
                return shift1.end.getTime() - shift2.end.getTime();
            } else {
                return shift1.start.getTime() - shift2.start.getTime();
            }
        } else {
            return shift1.role.id.localeCompare(shift2.role.id);
        }
    }

    public static groupShifts<T extends Shift | WeekdayShift>(groupShiftsBy: GroupShiftsBy, shifts: T[])
        : { groupShiftsBy: GroupShiftsBy, shiftGroups: T[][] } {
        const shiftAccessor: (val: Shift | WeekdayShift) => Shift = (val: Shift | WeekdayShift) => isShift(val) ? val : val.shift;

        const groupBy = (shift: T) => groupShiftsBy === GroupShiftsBy.ROLE
            ? shiftAccessor(shift).role.id
            : shiftAccessor(shift).start.toTimeString() + shiftAccessor(shift).end.toTimeString();
        // Sort the groups by the first shift in them based on the shift grouping type
        const sortGroupsBy = (a: T[], b: T[]) => groupShiftsBy === GroupShiftsBy.ROLE
            ? sortShiftsByRoleName(shiftAccessor(a[0]!), shiftAccessor(b[0]!))
            : sortShiftsByTime(shiftAccessor(a[0]!), shiftAccessor(b[0]!));
        // Sort the shifts in the groups based on the shift grouping type
        const sortShiftsBy = (a: T, b: T) => groupShiftsBy === GroupShiftsBy.ROLE
            ? sortShiftsByTime(shiftAccessor(a), shiftAccessor(b))
            : sortShiftsByRoleName(shiftAccessor(a), shiftAccessor(b));
        // Group and sort the shift groups
        const shiftGroups: T[][] = Object.values(_.groupBy(shifts, groupBy)).sort(sortGroupsBy);
        // Sort the shifts in the groups
        shiftGroups.map((shiftGroup: T[]) => shiftGroup.sort(sortShiftsBy));
        return { groupShiftsBy, shiftGroups };
    }

    public static shiftToHoliday(shift: Shift): Holiday | undefined {
        return DateService.dateToHoliday(shift.start);
    }

    public static shiftToWeekday(shift: Shift): Weekday {
        return Object.values(Weekday)[moment(shift.start).isoWeekday() - 1]!;
    }

    /**
     * Get an observable for all the shifts for a month
     * @param {Date} date the date that you want to get all the shifts for the same month
     * @param {Department} department the department that you want to look for shifts in
     * @returns {Observable<Shift[]>} An observable shifts array for the month
     */
    public getShiftsByMonth(date: Date, department?: Department, employee?: Employee, released?: boolean): Observable<Shift[]> {
        return this.getShifts({
            employee,
            department,
            startDateFrom: new Date(date.getFullYear(), date.getMonth(), 1, 0, 0),
            startDateTo: new Date(date.getFullYear(), date.getMonth() + 1, 1, 0, 0),
            released: released,
        });
    }

    /**
     * Retrieves an observable of shifts for a given date
     * @param {Date} date The date of which to find shifts
     * @param {boolean} released Optional boolean indicating whether you want only released or only non-released shifts
     * @param {String} roleId Optional string indicating that you want only shifts with the given role
     * @param {Employee} employee Optional employee indication that you want only shifts of this employee
     * @returns {Observable<Shift[]>} An observable Shift array based on the given parameters
     */
    public getShiftsByDate(date: Date, released?: boolean, role?: Role, employee?: Employee): Observable<Shift[]> {
        return this.getShifts({
            startDateFrom: new Date(date.getFullYear(), date.getMonth(), date.getDate()),
            startDateTo: new Date(date.getFullYear(), date.getMonth(), date.getDate() + 1),
            released,
            role,
            employee,
        });
    }

    /**
     * Returns all released shifts that have no employee that a given employee can take.
     * An employee can take the shift if the employee has the same role as the shift
     * and if the employee is not working on the day of the shift.
     * @param employee the employee
     */
    public getUnassignedReleasedShiftsWithRolesForEmployee(employee: Employee): Observable<Shift[]> {
        // If employee has no roles - return an observable of the empty list
        if (employee.roles.length === 0) return of([]);

        // Get the unassigned released future shifts for the employee
        const futureShifts$: Observable<Shift[]> = combineLatest(employee.roles.map((role: Role) =>
            this.getShifts({ startDateFrom: new Date(), released: true, role: role, employee: null })))
            .pipe(map((dayShifts: Shift[][]) => dayShifts.reduce((acc: Shift[], shifts: Shift[]) => acc.concat(shifts), [])));

        const workingShifts$: Observable<Shift[]> = this.getFutureShiftsForEmployee(employee);

        return combineLatest(futureShifts$, workingShifts$)
            .pipe(map(([futureShifts, workingShifts]: [Shift[], Shift[]]) =>
                // Filter out the shifts where the employee cant work
                futureShifts.filter(
                    (futureShift: Shift) => !workingShifts.some((shift: Shift) => ShiftService.conflictingShifts(shift, futureShift))),
            ));
    }

    /**
     * Returns an observable of all the shifts in the future where the employee has works
     * @param {Employee} employee The employee to get the shifts for
     * @returns {Observable<Date[]>} An observable returning all the shifts
     */
    public getFutureShiftsForEmployee(employee: Employee): Observable<Shift[]> {
        const date: Date = DateService.today();
        return this.getShifts({ startDateFrom: date, released: true, employee: employee, notCheckedOut: true });
    }

    /**
     * Returns an observable of the latest shift (can be a currently live shift) and the next shift
     * @param {Employee} employee The employee to get the shifts for
     */
    public getPunchclockShifts(employee: Employee): Observable<{ last?: Shift, next?: Shift }> {
        return combineLatest([
            // Last
            this.getShifts({
                released: true, employee, startDateTo: new Date(),
                orderByStartDate: OrderBy.DESCENDING, resultLimit: 1,
            }).pipe(pluck(0)),
            // Next
            this.getShifts({
                released: true, employee, startDateFrom: new Date(),
                orderByStartDate: OrderBy.ASCENDING, resultLimit: 1,
            }).pipe(pluck(0)),
        ]).pipe(map(([last, next]: (Shift | undefined)[]) => ({ last, next })));
    }

    /**
     * Gets all not punched in shifts that have ended for a specific department
     * @param checkoutUntil - Timespan in minutes, employees can check out after the shift ends
     * @param start - Only show shifts after this date (inclusive)
     * @param end - Only show shifts before this date (exclusive)
     * @param department - Supply a department to only get shifts for this department
     */
    public getNotPunchedInEndedShifts = (
        checkoutUntil: number | null,
        start?: Date,
        end?: Date,
        department?: Department): Observable<Shift[]> => {
        if (!checkoutUntil) return of([]);
        return this.getShifts({
            approved: false,
            department,
            released: true,
        }).pipe(
            map((shifts: Shift[]) => {
                shifts = filterByDateRange(shifts, start, end);
                shifts = filterEnded(shifts, checkoutUntil);
                return filterByDepartment(shifts, department);
            }),
        );
    }

    /**
     * Gets all punched in shifts for all or a specific department
     * @param start - Only show shifts for approval after this date (inclusive)
     * @param end - Only show shifts for approval before this date (exclusive)
     * @param department supply a department to only get unapproved shifts for this department
     */
    public getPunchedInShifts = (start?: Date, end?: Date, department?: Department): Observable<Shift[]> => {
        return this.getShifts({
            approved: false,
            checkedIn: true,
            department,
            released: true,
        }).pipe(
            map((shifts: Shift[]) => {
                shifts = filterByDateRange(shifts, start, end);
                return filterByDepartment(shifts, department);
            }),
        );
    }

    /**
     * Gets all unapproved shifts for all or a specific department
     * @param checkoutUntil - Timespan in minutes, employees can check out after the shift ends
     * @param start - Only show shifts for approval after this date (inclusive)
     * @param end - Only show shifts for approval before this date (exclusive)
     * @param department supply a department to only get unapproved shifts for this department
     */
    public getUnapprovedShifts = (
        checkoutUntil: number | null,
        start?: Date,
        end?: Date,
        department?: Department): Observable<Shift[]> => {
        return combineLatest([
            this.getPunchedInShifts(start, end, department),
            this.getNotPunchedInEndedShifts(checkoutUntil, start, end, department),
        ])
            .pipe(
                map(([punchedInShifts, notPunchedInEndedShifts]: [Shift[], Shift[]]) =>
                    punchedInShifts.concat(notPunchedInEndedShifts),
                ),
            );
    }

    /**
     * Returns an observable of whether the specified employee has another shift in the same time span as the given shift
     * @param shift The shift to check for overlaps
     * @param employee The employee to check for
     */
    public noOverlappingShifts(
        shift: Shift,
        employee: Employee,
        hours: number = 25,
        predicateFunc: Function = ShiftService.conflictingShifts): Observable<boolean> {
        return this.getShifts({
            startDateFrom: moment(shift.start).subtract(hours, 'hours').toDate(),
            startDateTo: moment(shift.start).add(hours, 'hours').toDate(),
            employee,
        }).pipe(
            map((possiblyOverlapping: Shift[]) =>
                possiblyOverlapping.every((candidate: Shift) => !predicateFunc(candidate, shift)),
            ));
    }

    /**
     * Create a shift in persistent storage
     * @param shift The shift to create
     */
    public abstract createShift(shift: Omit<Shift, 'id'>): Observable<Shift>;
    /**
     * Createan array of shifts in persistent storrage
     * @param shifts The array of shifts to create
     */
    public abstract createShifts(shifts: Omit<Shift, 'id'>[]): Observable<Shift[]>;
    /**
     * Get a shift from persistent storrage with a specific id
     * @param id The id af the shift
     */
    public abstract getShift(id: string): Observable<Shift>;

    /**
     * Gets the shifts from persistent storrage that match the serachparameters.
     * @param params The parameters to match for shifts
     */
    public abstract getShifts(params: ShiftQueryParams): Observable<Shift[]>;

    /**
     * Update a shift
     * @param shift The shift to update
     */
    public abstract updateShift(shift: Shift): Observable<Shift>;

    /**
     * Update multiple shifts
     * @param shifts The array of shifts to update
     */
    public abstract updateShifts(shifts: Shift[]): Observable<Shift[]>;

    /**
     * Delete a shift
     * @param shift The shift to delete
     */
    public abstract deleteShift(shift: Shift): Observable<Shift>;

    /**
     * Delete multiple shifts
     * @param shifts The shifts to delete
     */
    public abstract deleteShifts(shifts: Shift[]): Observable<Shift[]>;
}
export interface ShiftQueryParams {
    role?: Role;
    department?: Department;
    startDateFrom?: Date;
    startDateTo?: Date;
    endDateFrom?: Date;
    endDateTo?: Date;
    released?: boolean;
    employee?: Employee | null;
    approved?: boolean;
    orderByStartDate?: OrderBy;
    resultLimit?: number;
    notCheckedOut?: boolean;
    checkedIn?: boolean;
    isDeleted?: boolean;
}
