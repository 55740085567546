import { Component, Input } from '@angular/core';
import { Bid, Employee, ShiftForSale } from '../../../../core/model';
import { ShiftForSaleService } from '../../../../core/services';

@Component({
    selector: 'app-shift-detail-buy-shift-action',
    templateUrl: './shift-detail-buy-shift-action.component.html',
    styleUrls: ['./shift-detail-buy-shift-action.component.scss'],
})
export class ShiftDetailBuyShiftActionComponent {
    @Input()
    public set shiftForSale(value: ShiftForSale) {
        this._shiftForSale = value;
        this.hasMyBid = !!value?.bids.find((bid: Bid) => bid.bidder.id === this.employee.id);
    }
    public get shiftForSale(): ShiftForSale {
        return this._shiftForSale;
    }

    @Input() public employee: Employee;
    @Input() public potentialGain: number;
    public hasMyBid: boolean;
    private _shiftForSale: ShiftForSale;

    constructor(
        private shiftForSaleService: ShiftForSaleService,
    ) { }

    public async bidOnShift(): Promise<void> {
        if (!this.shiftForSale) return;
        this.shiftForSaleService.bidOnShiftForSale(this.shiftForSale, this.employee);
    }

    public async cancelBidOnShift(): Promise<void> {
        if (!this.shiftForSale) return;
        this.shiftForSaleService.removeBidOnShiftForSale(this.shiftForSale, this.employee);
    }
}
