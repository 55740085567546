<div *ngIf="(punchclockEnabled$ | async) === false"
     class="punchclock-overlay">
    <div class="toggle d-flex align-items-center"
         [class.activated]="punchclockEnabled$ | async"
         *ngIf="isPro$ |async">
        {{ (punchclockEnabled$ | async) ? ('shared.activated' | translate) : ('shared.deactivated' | translate) }}
        <ui-toggle [checked]="punchclockEnabled$ | async"
                   (checkedChange)="setPunchclockEnabled($event)"></ui-toggle>
    </div>
    <div class="overlay-container d-flex flex-column justify-content-center h-100">
        <span class="title">{{ 'settings.punch-clock.welcome' | translate }}</span>
        <div class="list-item">
            <i class="far fa-map-pin"></i>
            <span>{{ 'settings.punch-clock.set-up-gps-zones' | translate }}</span>
        </div>
        <div class="list-item">
            <i class="fas fa-street-view"></i>
            <span>{{ 'settings.punch-clock.follow-real-time' | translate }}</span>
        </div>
        <div class="list-item">
            <i class="far fa-check"></i>
            <span>{{'settings.punch-clock.approve-on-the-fly-or-through-salary' | translate }}</span>
        </div>
        <div class="button-container">
            <ui-button-action *ngIf="(isPro$ | async) === false"
                              (click)="openProShop()"
                              class="try-pro">{{(trialAvailable$ | async) === true ? ('freemium.try-pro-free-14' |
                translate) : ('freemium.upgrade-now' | translate) }}</ui-button-action>
            <ui-button-action *ngIf="(isPro$ | async) === true"
                              color="primary"
                              (click)="setPunchclockEnabled(true)">{{ 'shared.activate' | translate }}
            </ui-button-action>
        </div>
    </div>
</div>
<ng-container *ngIf="(punchclockEnabled$ | async) === true">
    <div *ngIf="!(zoneUnderEdit$ | async)"
         class="settings-page">

        <!-- HEADER -->
        <div class="d-flex justify-content-between align-items-center flex-shrink-0">
            <app-settings-header title="{{ 'shared.punch-clock' | translate | capitalize }}"></app-settings-header>
            <div class="toggle d-flex align-items-center"
                 [class.activated]="punchclockEnabled$ | async">
                {{ (punchclockEnabled$ | async) ? ('shared.activated' | translate) : ('shared.deactivated' | translate)
                }}
                <ui-toggle [checked]="punchclockEnabled$ | async"
                           (checkedChange)="setPunchclockEnabled($event)"></ui-toggle>
            </div>
        </div>

        <!-- CONTENT -->
        <div class="settings-content">

            <ng-container *ngIf="(punchclockEnabled$ | async) !== false; else punchclockDisabled">

                <app-settings-divider title="{{ 'settings.punch-clock.gps-zones' | translate }}"></app-settings-divider>

                <app-gps-zone-settings></app-gps-zone-settings>

                <app-settings-divider title=" {{ 'tool-tips.top-nav.settings' | translate }}"></app-settings-divider>

                <app-punch-times-settings></app-punch-times-settings>

                <app-settings-toggle [title]="'settings.punch-times.round-to-shift-start' | translate"
                                     [checked]="roundCheckin$ | async"
                                     (checkedChange)="setEnabled($event, CompanySetting.ROUND_CHECKIN)">
                </app-settings-toggle>

                <app-settings-toggle [title]="'settings.punch-times.round-to-shift-end' | translate"
                                     [checked]="roundCheckout$ | async"
                                     (checkedChange)="setEnabled($event, CompanySetting.ROUND_CHECKOUT)">
                </app-settings-toggle>

                <app-auto-punch-approval-settings></app-auto-punch-approval-settings>
            </ng-container>

            <ng-template #punchclockDisabled>
                <div class="activate-punchclock-btn-container">
                    <ui-button-action (click)="setPunchclockEnabled(true)">
                        {{ 'punch-clock.activate-punchclock' | translate }}
                    </ui-button-action>
                </div>
            </ng-template>
        </div>
    </div>
    <div *ngIf="zoneUnderEdit$ | async as zone">
        <app-edit-gps-zone [gpsZone]="zone"></app-edit-gps-zone>
    </div>
</ng-container>