import { Component, EventEmitter, Injector, Input, OnInit, Optional, Output } from '@angular/core';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { combineLatest, Observable } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';
import { Employee, isShift, isValidShift, NewShift, Role, Shift, Wish } from '../../../../core/model';
import { Department } from '../../../../core/model/Department/Department.model';
import { DateService, EmployeeService, RoleService, ShiftService, WishService } from '../../../../core/services';
import { DepartmentService } from '../../../../core/services/Department/DepartmentService.model';
import { notifyEmployeesOnShiftRelease } from '../../../utilities/NotificationUtils';

@Component({
    selector: 'app-shift-edit',
    templateUrl: './shift-edit.component.html',
    styleUrls: ['./shift-edit.component.scss'],
})
export class ShiftEditComponent implements OnInit {
    @Input() public shift: Shift | NewShift;
    @Input() public dismiss: () => void;
    @Output() public shiftChange: EventEmitter<Shift>;
    public roles$: Observable<Role[]>;
    public wishes$: Observable<Wish[]>;
    public isValidShift: (shift: unknown) => shift is Omit<Shift, 'id'> = isValidShift;
    public isShift: (shift: unknown) => shift is Shift = isShift;

    constructor(
        @Optional() public dialogRef: MatBottomSheetRef | null,
        private injector: Injector,
        private shiftService: ShiftService,
        private departmentService: DepartmentService,
        private roleService: RoleService,
        private dateService: DateService,
        private employeeService: EmployeeService,
        private wishService: WishService,
    ) {
        this.shiftChange = new EventEmitter();
    }

    public ngOnInit(): void {
        this.roles$ = this.departmentService.getActiveDepartment().pipe(
            switchMap((activeDepartment: Department) => this.roleService.getRoles({ department: activeDepartment })));

        this.wishes$ = combineLatest([
            this.dateService.getDateObservable(),
            this.employeeService.getEmployees(),
        ]).pipe(
            switchMap(([date, employees]: [Date, Employee[]]) =>
                combineLatest(employees.map((emp: Employee) => this.wishService.getWishOnDate(emp, date)))),
            map((wishes: Wish[]) => wishes.sort(WishService.compare)),
        );
    }

    /**
     * Publishes the shift
     */
    public publish(): void {
        // Run this inside timeout to allow for app-day-view-shift to emit the changed shift before updating
        if (!this.shift.released) {
            setTimeout(async () => {
                this.shiftChange.emit(this.shift as Shift);
                this.shift.released = true;
                if (this.isShift(this.shift)) {
                    await this.shiftService.updateShift(this.shift).pipe(take(1)).toPromise();
                    notifyEmployeesOnShiftRelease([this.shift], this.injector);
                }
            }, 0);
        }
        this.dismiss();
    }
}
