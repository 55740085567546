<div class="header">
    <div class="date header-row">
        <ui-month-picker [date]="dateService.getDateObservable() | async"
                         (dateChange)="dateService.changeDate($event)">
        </ui-month-picker>
        <ui-button-text (click)="today()">
            {{'shared.today' | translate | capitalize}}
        </ui-button-text>
    </div>
    <ng-container *ngIf="departments$ | async as departments">
        <ng-container *ngIf="activeDepartment$ | async as activeDepartment">
            <div class="department header-row"
                 *ngIf="departments.length > 1">
                <label for="department-selector">
                    <div class="department-name">
                        <div> {{ activeDepartment.name }} </div>
                        <i class="fas fa-caret-down"></i>
                    </div>
                    <select id="department-selector"
                            (change)="setDepartment(departments, $event.target.value)">
                        <option *ngFor="let department of departments"
                                [value]="department.id"
                                [selected]="department.id === activeDepartment.id">
                            {{department.name}}
                        </option>
                    </select>
                </label>
            </div>
        </ng-container>
    </ng-container>
</div>

<div class="ui-date-picker-container">
    <ui-date-picker [startDate]="dateService.getDateObservable() | async"
                    (dateSelect)="onDateSelected($event)"
                    [colorDateMap]="shiftColors$ | async"
                    [selectionStyle]="selectionStyles.DOT">
    </ui-date-picker>
</div>