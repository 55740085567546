import { Component, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';
import { GPSZone, Shift } from '../../../../core/model';
import { Punch, PunchSource } from '../../../../core/model/Punch/Punch.model';
import { CompanySetting, CompanySettingsService, ShiftService } from '../../../../core/services';
import { GeolocationService } from '../../../../core/services/geolocation.service';
import { ModalService } from '../../../../core/services/ModalService.model';
import { isWithinRange } from '../../../utilities/GeoUtils';

@Component({
    selector: 'app-shift-detail-punch-action',
    templateUrl: './shift-detail-punch-action.component.html',
    styleUrls: ['./shift-detail-punch-action.component.scss'],
})
export class ShiftDetailPunchActionComponent {

    @Input() public shift: Shift;
    @Input() public isCheckIn: boolean;
    public messageInput: string;
    public punchAllowed$: Observable<boolean>;

    constructor(
        public modalService: ModalService,
        public shiftService: ShiftService,
        settingsService: CompanySettingsService,
        geolocationService: GeolocationService,
    ) {
        this.punchAllowed$ = settingsService.loadSetting(CompanySetting.LOCATIONS).pipe(
            switchMap(async (locations: GPSZone[]) => {
                // If no locations, always allow checkin
                if (locations.length === 0) return true;

                try {
                    // Get the user location
                    const userLocation = await geolocationService.getUserLocation();
                    // Return whether the user's location is within range of a GPS zone
                    return !!locations.find((location: GPSZone) => isWithinRange(location, userLocation));
                } catch (error) {
                    // If the user location could not be found, return false
                    return false;
                }
            }),
        );
    }

    public punch(): void {
        this.modalService.closeAll();
        const punchedShift = { ...this.shift };
        const punch: Punch = { source: PunchSource.AUTOMATIC, time: new Date(), message: this.messageInput };
        if (this.isCheckIn) punchedShift.checkin = punch;
        else punchedShift.checkout = punch;
        this.shiftService.updateShift(punchedShift).pipe(take(1)).subscribe();
    }
}
