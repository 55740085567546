import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'app-settings-toggle',
    templateUrl: './settings-toggle.component.html',
    styleUrls: ['./settings-toggle.component.scss'],
})
export class SettingsToggleComponent {
    @Input() public title: string;
    @Input() public subtitle?: string;
    @Input() public checked: boolean;
    @Input() public toggleExtension: boolean;
    @Output() public checkedChange: EventEmitter<boolean>;

    constructor() {
        this.checkedChange = new EventEmitter();
    }

    public onCheckChanged(checked: boolean): void {
        this.checkedChange.emit(checked);
    }
}
