<div id="wish-legend"
     [class.mobile]="!bigScreen">
    <div class="wish-legends d-flex flex-row style-10 justify-content-center">
        <div class="legend d-flex flex-row align-items-center">
            <div class="legend-dot legend-green"></div>
            <span>
                {{ (bigScreen ? 'wish-legend.work-long' : 'wish-legend.work') | translate | capitalize }}
            </span>
        </div>
        <div class="legend d-flex flex-row align-items-center">
            <div class="legend-dot legend-white"></div>
            <span>
                {{ (bigScreen ? 'wish-legend.available-long' : 'wish-legend.available') | translate | capitalize }}
            </span>
        </div>
        <div class="legend d-flex flex-row align-items-center">
            <div class="legend-dot legend-red"></div>
            <span>
                {{ (bigScreen ? 'wish-legend.no-work-long' : 'wish-legend.no-work') | translate | capitalize }}
            </span>
        </div>
    </div>
</div>