import { Component, Input } from '@angular/core';
import { Shift } from '../../../../core/model';
import { compare, SalaryExport } from '../../../../core/model/SalaryExport';

@Component({
    selector: 'app-export-logs',
    templateUrl: './export-logs.component.html',
})
export class ExportLogsComponent {

    public exportLogs: SalaryExport[];
    @Input() public set shift(shift: Shift) {
        this.exportLogs = shift.exportLogs.sort(compare);
    }
    @Input() public iconClass: string;
}
