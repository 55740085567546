/**
 * Production environtment file exporting production specific environment variables
 */

/**
 * Firebase config
 */
export const firebase = {
    apiKey: 'AIzaSyCl-wbmGCu9tZF3ZnF5hKF-ny8tuMfade4',
    authDomain: 'smalender-relion.firebaseapp.com',
    databaseURL: 'https://smalender-relion.firebaseio.com',
    projectId: 'smalender-relion',
    storageBucket: 'smalender-relion.appspot.com',
    messagingSenderId: '909098683398',
    appId: '1:909098683398:web:c859dbc7788c5771bf8787',
};

export const environment = {
    production: true,
    firebase: firebase,
    googleAnalyticsTrackingId: 'UA-148365127-3',
    googleMapsAPIKey: 'AIzaSyCCemGnygmEtuc3__EraGhD0A7n6dXsDGg',
    useEmulator: false,
};



