import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgSelectComponent } from '@ng-select/ng-select';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Employee, Role } from '../../../../../core/model';
import { Department } from '../../../../../core/model/Department/Department.model';

@Component({
    selector: 'app-edit-employee-department-item',
    templateUrl: './edit-employee-department-item.component.html',
    styleUrls: ['./edit-employee-department-item.component.scss'],
})
export class EditEmployeeDepartmentItemComponent implements OnInit {

    @Output() public selectedEmployeeChange: EventEmitter<Employee>;
    @Input() public department: Department;
    @Input() public departments$: Observable<Department[]>;
    @Input() public roles$: Observable<Role[]>;
    @Input() set selectedEmployee(employee: Employee) {
        this._selectedEmployee = employee;
        // Reset the UI on selectedEmployee change
        if (this.roles$) this.setDepartmentRoles();
    }
    get selectedEmployee(): Employee {
        return this._selectedEmployee;
    }
    public departmentRoles$: Observable<Role[]>;
    public selectedDepartmentRoles$: Observable<Role[]>;
    @ViewChild('roleSelect') public select: NgSelectComponent;
    private _selectedEmployee: Employee;

    constructor() {
        this.selectedEmployeeChange = new EventEmitter();
    }

    public ngOnInit(): void {
        this.setDepartmentRoles();
    }

    /**
     * Updates the selected roles on an employee for the department
     * @param selectedRoles An array of roles given as the selected roles
     */
    public updateRoles(selectedRoles: Role[]): void {
        const otherRoles: Role[] = this.selectedEmployee.roles.filter((role: Role) => role.department.id !== this.department.id);
        this.selectedEmployee.roles = [...otherRoles, ...selectedRoles];
        this.emitEmployee();
    }

    /**
     * Adds or removes a department from an employee, depending on the current status of that employees departments
     * @param department The department to add or remove from an employee
     */
    public toggleDepartment(): void {
        if (this.includesDepartment()) {
            const departments: Department[] = this.selectedEmployee.departments.filter((dep: Department) => dep.id !== this.department.id);
            this.selectedEmployee.departments = departments;
            this.resetEmployeeDepartmentRelation();
        } else {
            this.selectedEmployee.departments = [...this.selectedEmployee.departments, this.department];
            setTimeout(() => this.select.open(), 0);
        }
        this.emitEmployee();
    }

    /**
     * Returns a boolean that describes if an employee currently belongs to the given department
     * @param department The department to check
     */
    public includesDepartment(): boolean {
        return this.selectedEmployee.departments.some((dep: Department) => dep.id === this.department.id);
    }

    /**
     * Resets the relationship between a department and an employee
     * @param department The department to remove from the employee
     */
    private resetEmployeeDepartmentRelation(): void {
        // Removes any selected roles of the department from the employee
        this.updateRoles([]);
        if (this.selectedEmployee.primaryDepartment && this.selectedEmployee.primaryDepartment.id === this.department.id) {
            this.selectedEmployee.primaryDepartment = null;
        }
    }

    /**
     * Emits the employee to the parent component
     */
    private emitEmployee(): void {
        this.selectedEmployeeChange.emit(this.selectedEmployee);
    }

    /**
     * Sets all and the selected department roles
     */
    private setDepartmentRoles(): void {
        this.departmentRoles$ = this.roles$
            .pipe(map((roles: Role[]) => roles.filter((role: Role) => role.department.id === this.department.id)));

        this.selectedDepartmentRoles$ = this.departmentRoles$.pipe(
            map((roles: Role[]) => roles.filter((depRole: Role) =>
                this.selectedEmployee.roles.some((role: Role) => role.id === depRole.id))));
    }
}
