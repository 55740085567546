import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GPSZone, ShiftBreak } from '../../../model';

@Injectable({
    providedIn: 'root',
})

export abstract class CompanySettingsService {

    /**
     * Save a setting in database
     * @param setting The setting to save
     * @param value The value to set the given setting to
     */
    public abstract saveSetting<T extends CompanySetting>(setting: T, value: CompanySettingsTypeMap[T]): Promise<void>;


    /**
     * Load a setting from the db, an Observable of null is returned if the setting does not exist
     * @param setting The setting to load
     */
    public abstract loadSetting<T extends CompanySetting>(setting: T): Observable<CompanySettingsTypeMap[T]>;

    protected getSettingDefault<T extends CompanySetting>(setting: T): CompanySettingsTypeMap[T] {
        switch (setting) {
            case CompanySetting.LOCATIONS: return [] as GPSZone[] as CompanySettingsTypeMap[T];
            case CompanySetting.START_TIME: return 6 as CompanySettingsTypeMap[T];
            case CompanySetting.END_TIME: return 24 as CompanySettingsTypeMap[T];
            case CompanySetting.START_TIME_STANDARD_WISH: return 6 as CompanySettingsTypeMap[T];
            case CompanySetting.END_TIME_STANDARD_WISH: return 24 as CompanySettingsTypeMap[T];
            case CompanySetting.PUNCHCLOCK_ENABLED_ON: return null as CompanySettingsTypeMap[T];
            case CompanySetting.CHECKIN_BEFORE: return 60 as CompanySettingsTypeMap[T];
            case CompanySetting.CHECKOUT_UNTIL: return 60 as CompanySettingsTypeMap[T];
            case CompanySetting.MANUAL_PUNCHCLOCK: return false as CompanySettingsTypeMap[T];
            case CompanySetting.SHIFT_TRADE: return true as CompanySettingsTypeMap[T];
            case CompanySetting.AUTO_SHIFT_TRADE: return false as CompanySettingsTypeMap[T];
            case CompanySetting.SALARY_PERIOD_START_DATE: return 20 as CompanySettingsTypeMap[T];
            case CompanySetting.SALARY_SUPPLEMENTS_ENABLED_ON: return null as CompanySettingsTypeMap[T];
            case CompanySetting.SHIFT_BREAK: return null as CompanySettingsTypeMap[T];
            case CompanySetting.AUTO_APPROVE_AVAILABLE_SHIFT_ENABLED_ON: return null as CompanySettingsTypeMap[T];
            case CompanySetting.ROUND_CHECKIN: return false as CompanySettingsTypeMap[T];
            case CompanySetting.ROUND_CHECKOUT: return false as CompanySettingsTypeMap[T];
            case CompanySetting.AUTO_APPROVE_PUNCH_TIMES: return null as CompanySettingsTypeMap[T];
            case CompanySetting.EMPLOYEE_DISALLOW_EDIT_PROFILE_PICTURE_ENABLED: return false as CompanySettingsTypeMap[T];
            case CompanySetting.AUTOMATICALLY_EXPORT_APPROVED_PUNCH_TIMES_TO_SALARY_DK_ENABLED_ON: return null as CompanySettingsTypeMap[T];
            case CompanySetting.ONLY_INCLUDE_SHIFTS_MARKED_WITH_SWIPE: return null as CompanySettingsTypeMap[T];
        }
        throw Error('No default found for setting ' + setting);
    }
}


export enum CompanySetting {
    LOCATIONS = 'LOCATIONS/LOCATIONS',
    START_TIME = 'DAY_VIEW_TIME/START',
    END_TIME = 'DAY_VIEW_TIME/END',
    START_TIME_STANDARD_WISH = 'STANDARD_WISH_TIME/START',
    END_TIME_STANDARD_WISH = 'STANDARD_WISH_TIME/END',
    PUNCHCLOCK_ENABLED_ON = 'PUNCHCLOCK/ENABLED_ON',
    CHECKIN_BEFORE = 'PUNCHCLOCK/CHECKIN_BEFORE',
    CHECKOUT_UNTIL = 'PUNCHCLOCK/CHECKOUT_BEFORE',
    MANUAL_PUNCHCLOCK = 'PUNCHCLOCK/MANUAL',
    ROUND_CHECKIN = 'PUNCHCLOCK/ROUND_CHECKIN',
    ROUND_CHECKOUT = 'PUNCHCLOCK/ROUND_CHECKOUT',
    AUTO_APPROVE_PUNCH_TIMES = 'PUNCHCLOCK/AUTO_APPROVE',
    SHIFT_TRADE = 'SHIFT_TRADE/ENABLED',
    AUTO_SHIFT_TRADE = 'AUTO_SHIFT_TRADE/ENABLED',
    SALARY_PERIOD_START_DATE = 'SALARY/SALARY_PERIOD_START_DATE',
    SALARY_SUPPLEMENTS_ENABLED_ON = 'SALARY_SUPPLEMENTS/SALARY_SUPPLEMENTS_ENABLED_ON',
    SHIFT_BREAK = 'SHIFT_BREAK/BREAK_SETTINGS',
    AUTO_APPROVE_AVAILABLE_SHIFT_ENABLED_ON = 'AUTO_APPROVE_AVAILABLE_SHIFT/ENABLED_ON',
    EMPLOYEE_DISALLOW_EDIT_PROFILE_PICTURE_ENABLED = 'EMPLOYEE/EMPLOYEE_DISALLOW_EDIT_PROFILE_PICTURE_ENABLED',
    AUTOMATICALLY_EXPORT_APPROVED_PUNCH_TIMES_TO_SALARY_DK_ENABLED_ON = 'SALARY/AUTOMATICALLY_EXPORT_APPROVED_PUNCH_TIMES_TO_SALARY_DK_ENABLED_ON',
    ONLY_INCLUDE_SHIFTS_MARKED_WITH_SWIPE = 'SALARY/ONLY_INCLUDE_SHIFTS_MARKED_WITH_SWIPE',
}

export interface CompanySettingsTypeMap {
    [CompanySetting.LOCATIONS]: GPSZone[];
    [CompanySetting.SHIFT_TRADE]: boolean;
    [CompanySetting.AUTO_SHIFT_TRADE]: boolean;
    [CompanySetting.CHECKIN_BEFORE]: number | null;
    [CompanySetting.CHECKOUT_UNTIL]: number | null;
    [CompanySetting.END_TIME]: number;
    [CompanySetting.END_TIME_STANDARD_WISH]: number;
    [CompanySetting.PUNCHCLOCK_ENABLED_ON]: Date | null;
    [CompanySetting.START_TIME]: number;
    [CompanySetting.START_TIME_STANDARD_WISH]: number;
    [CompanySetting.MANUAL_PUNCHCLOCK]: boolean;
    [CompanySetting.SALARY_PERIOD_START_DATE]: number;
    [CompanySetting.SHIFT_BREAK]: ShiftBreak | null;
    [CompanySetting.SALARY_SUPPLEMENTS_ENABLED_ON]: Date | null;
    [CompanySetting.AUTO_APPROVE_AVAILABLE_SHIFT_ENABLED_ON]: Date | null;
    [CompanySetting.ROUND_CHECKIN]: boolean;
    [CompanySetting.ROUND_CHECKOUT]: boolean;
    [CompanySetting.AUTO_APPROVE_PUNCH_TIMES]: number | null;
    [CompanySetting.EMPLOYEE_DISALLOW_EDIT_PROFILE_PICTURE_ENABLED]: boolean;
    [CompanySetting.AUTOMATICALLY_EXPORT_APPROVED_PUNCH_TIMES_TO_SALARY_DK_ENABLED_ON]: Date | null;
    [CompanySetting.ONLY_INCLUDE_SHIFTS_MARKED_WITH_SWIPE]: Date | null;
}
