<div class="item-container">
    <div class="name"
         [class.disabled]="!includesDepartment()">{{department.name}}</div>
    <div class="role-selector"
         *ngIf="departmentRoles$ | async as roles">
        <ng-select *ngIf="includesDepartment()"
                   class="role-selector-input"
                   [items]="roles"
                   bindLabel="name"
                   [closeOnSelect]="false"
                   [hideSelected]="true"
                   [multiple]="true"
                   [ngModel]="selectedDepartmentRoles$ | async"
                   (change)="updateRoles($event)"
                   appendTo="body"
                   notFoundText="admin.department.no-roles | translate | capitalize"
                   #roleSelect></ng-select>
    </div>
    <div class="switcher">
        <ui-toggle [checked]="includesDepartment()"
                   (checkedChange)="toggleDepartment()">
        </ui-toggle>
    </div>
</div>