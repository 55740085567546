<div class="matching-container d-flex flex-column">
    <div class="logo-container d-flex justify-content-between align-items-end">
        <img alt="DataLøn Logo"
             [src]="integration | getIntegrationLogo">
        <ui-button-text (click)="openHelpModal()"><span>{{ 'integrations.dataloen.matching-help' | translate }}</span>
        </ui-button-text>
    </div>
    <overlay-scrollbars [options]="{scrollbars: {autoHide: 'never'}}"
                        class="employee-list">
        <div class="employee-card d-flex flex-row align-items-center"
             *ngFor="let employee of unmatchedRelionEmployees">
            <img [lazyLoad]="employee.imageURL">
            <span>{{ employee.firstname + ' ' + employee.lastname }}</span>
            <div ngbDropdown
                 [container]="'body'">
                <div class="match-dropdown"
                     ngbDropdownToggle>
                    <div class="d-flex flex-row justify-content-between align-items-center"
                         [ngSwitch]="getDataloenEmployee(employee.id) === undefined ? 'undefined' : (getDataloenEmployee(employee.id) === null ? 'null' : '')">
                        <div class="name"
                             *ngSwitchCase="'undefined'">{{'salary.no-match' | translate}}</div>
                        <div class="name"
                             *ngSwitchCase="'null'">{{'shared.ignored' | translate}}</div>
                        <div class="name"
                             *ngSwitchDefault>{{getDataloenEmployee(employee.id)!.name}}</div>
                        <i class="fa fa-chevron-down"></i>
                    </div>
                </div>
                <div ngbDropdownMenu
                     class="dataloen-match-dropdown">
                    <overlay-scrollbars class="menu-scrolling-container">
                        <div class="d-flex flex-column w-100 justify-content-center"
                             ngbDropdownItem
                             (click)="selectMatch(employee)">
                            <div class="w-100">{{'shared.ignore' | translate}}</div>
                        </div>
                        <div class="d-flex flex-column w-100 justify-content-center"
                             *ngFor="let dataloenEmployee of unmatchedDataloenEmployees"
                             ngbDropdownItem
                             (click)="selectMatch(employee, dataloenEmployee)">
                            <div class="name w-100">{{dataloenEmployee.name}}</div>
                            <div class="d-flex flex-row w-100 justify-content-around">
                                <div class="employee-number-container d-flex flex-column align-items-start">
                                    <div class="subtext">{{ 'integrations.dataloen.employee-number' | translate }}
                                    </div>
                                    <div class="subtext">{{dataloenEmployee.employeeNumber}}
                                    </div>
                                </div>
                                <div class="d-flex flex-column align-items-start">
                                    <div class="subtext">{{ 'integrations.dataloen.salary-period-group' | translate }}
                                    </div>
                                    <div class="subtext">{{dataloenEmployee.salaryPeriodGroup}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </overlay-scrollbars>
                </div>
            </div>
        </div>
    </overlay-scrollbars>
    <div class="help-text-container">
        <span class="text">{{ 'integrations.dataloen.manual-matching-description' | translate }}</span>
    </div>
    <div class="button-container d-flex justify-content-end">
        <ui-button-standard (click)="modalRef.close()">{{'shared.cancel' | translate}}</ui-button-standard>
        <ui-button-action *ngIf="allEmployeesMapped"
                          (click)="saveMapping()">{{'shared.complete' | translate}}</ui-button-action>
        <ui-button-standard *ngIf="!allEmployeesMapped"
                            (click)="saveMapping()">{{'shared.complete' | translate}}</ui-button-standard>
    </div>
</div>