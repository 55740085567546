import { Injectable } from '@angular/core';
import { CollectionService } from 'akita-ng-fire';
import * as _ from 'lodash';
import { Department, Employee, Role } from '../../../model';
import { companiesCollectionPath, employeesCollectionPath, FirestoreEmployee } from '../../../model/Firestore';
import { AuthQuery } from '../../auth/state/auth.query';
import { DepartmentQuery } from '../../Department/state/department.query';
import { RoleQuery } from '../../Role/state/role.query';
import { EmployeeState, EmployeeStore } from './employee.store';

@Injectable({ providedIn: 'root' })
export class AkitaEmployeeService extends CollectionService<EmployeeState> {

    constructor(
        store: EmployeeStore,
        private authQuery: AuthQuery,
        private departmentQuery: DepartmentQuery,
        private roleQuery: RoleQuery,
    ) {
        super(store);
    }

    get path(): string {
        const companyID = this.authQuery.getValue().roles.companyID;
        return `${ companiesCollectionPath }/${ companyID }/${ employeesCollectionPath }`;
    }

    public formatFromFirestore(firestoreEmployee: Readonly<FirestoreEmployee & { id: string }>): Employee {
        const departmentQuery = this.departmentQuery;
        const roleQuery = this.roleQuery;

        return {
            ...firestoreEmployee,
            get departments(): Department[] {
                return departmentQuery.getAll({
                    filterBy: (department: Department) =>
                        firestoreEmployee.departments.includes(department.id),
                });
            },
            get roles(): Role[] {
                return roleQuery.getAll({ filterBy: (role: Role) => firestoreEmployee.roles.includes(role.id) });
            },
            get primaryDepartment(): Department | null {
                const primaryDepartment = (firestoreEmployee.primaryDepartment !== null && firestoreEmployee.primaryDepartment !== 'null')
                    ? departmentQuery.getEntity(firestoreEmployee.primaryDepartment)
                    : null;
                if (primaryDepartment === undefined) throw new Error(`No department with id ${ firestoreEmployee.primaryDepartment }`);
                return primaryDepartment;
            },
            imageURL: '',
            isDeleted: !!firestoreEmployee.isDeleted,
            note: firestoreEmployee.note || '',
        };
    }

    public formatToFirestore(employee: Employee): FirestoreEmployee {
        const firestoreEmployee = {
            ...employee,
            roles: employee.roles.map((role: Role) => role.id),
            departments: employee.departments ? employee.departments.map((department: Department) => department.id) : [],
            primaryDepartment: employee.primaryDepartment ? employee.primaryDepartment.id : null,
            hourlyWage: employee.hourlyWage != null ? employee.hourlyWage : null,
        };

        return _.omit(firestoreEmployee, 'id', 'imageURL');
    }

}
