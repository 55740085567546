import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'changeDate' })
export class ChangeDatePipe implements PipeTransform {

    /**
     * Transform a date to another by adding a number of dates
     * @param date The date to transform
     * @param days The number of days to add
     */
    public transform(date: Date, days: number): Date {
        const intermediate: Date = new Date(date);
        intermediate.setDate(date.getDate() + days);
        return intermediate;
    }
}
