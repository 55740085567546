<div class="settings-page">
    <div class="header d-flex justify-content-between">
        <app-settings-header title="{{ 'shared.profile' | translate | capitalize }}"></app-settings-header>
        <ui-button-standard (click)="logout(); gas.trackButtonClick('logout')"
                            class="logout-btn"
                            color="warn">
            {{"settings.log-out" | translate | capitalize}}
        </ui-button-standard>
    </div>

    <app-edit-employee-info *ngIf="employee$ | async"
                            [selectedEmployee]="employee$ | async"></app-edit-employee-info>
</div>