import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { Role } from '../../../../../../../../core/model';
import { Department } from '../../../../../../../../core/model/Department/Department.model';
import { RoleService } from '../../../../../../../../core/services';
import { SnackbarColor, SnackbarService } from '../../../../../../../../core/services/snackbar/snackbar.service';

@Component({
    selector: 'app-edit-role-popover',
    templateUrl: './edit-role-popover.component.html',
    styleUrls: ['./edit-role-popover.component.scss'],
})
export class EditRolePopoverComponent implements OnInit {
    @Input() public partialRole: Partial<Role>;
    @Output() public closeClicked: EventEmitter<void> = new EventEmitter();
    public saving: boolean;
    public departmentRoles$: Observable<Role[]>;
    @Input() private department: Department;

    constructor(
        public roleService: RoleService,
        private translateService: TranslateService,
        private snackService: SnackbarService,
    ) { }

    public ngOnInit(): void {
        this.departmentRoles$ = this.roleService.getRoles({ department: this.department });
        if (!this.partialRole) this.partialRole = { name: '', department: this.department };
    }

    /**
     * Emit that the component wants to be closed
     */
    public close(): void {
        this.closeClicked.emit();
    }
    /**
     * Save the role if it is an existing one or create it otherwise
     */
    public save(): void {
        this.saving = true;

        const roleObservable: Observable<Role> = this.partialRole.id
            ? this.roleService.updateRole(this.partialRole as Role)
            : this.roleService.createRole(this.partialRole as Omit<Role, 'id'>);

        roleObservable.pipe(take(1)).subscribe(() => this.close());
    }

    public remove(): void {
        this.saving = true;

        this.roleService.deleteRole(this.partialRole as Role).pipe(take(1)).subscribe({
            next: (): void => this.close(),
            error: async (error: Error): Promise<void> => {
                const errorMsg: string = await this.translateService.get('error.unable-to-delete-role').pipe(take(1)).toPromise();
                this.snackService.displaySnack(errorMsg, SnackbarColor.warn);
                this.saving = false;
                throw error;
            },
        });
    }
}
