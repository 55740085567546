import { Employee } from './Employee.model';
import { SalaryIntegration } from './Integrations/Shared';

export interface SalaryExport {
    time: Date;
    salaryIntegration: SalaryIntegration;
    exportedBy: Employee | 'automatic'; // If 'automatic', it was an automatic export
}

/**
 * Sorts exports by time in ascending order
 * @param export1
 * @param export2
 */
export function compare(export1: SalaryExport, export2: SalaryExport): number {
    return export1.time.getTime() - export2.time.getTime();
}
