import { Component, EventEmitter, Input, OnInit, Output, TrackByFunction } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';
import { Employee } from '../../../../../../core/model';
import { Department } from '../../../../../../core/model/Department/Department.model';
import { EmployeeService } from '../../../../../../core/services';
import { DepartmentService } from '../../../../../../core/services/Department/DepartmentService.model';

interface DepartmentData {
    department: Department;
    employeeCount: number;
}


@Component({
    selector: 'app-department-list',
    templateUrl: './department-list.component.html',
    styleUrls: ['./department-list.component.scss'],
})
export class DepartmentListComponent implements OnInit {
    @Output() public departmentSelected: EventEmitter<Department> = new EventEmitter();
    public departmentDataList$: Observable<DepartmentData[]>;
    @Input() public departments$: Observable<Department[]>;
    constructor(private departmentService: DepartmentService,
        private employeeService: EmployeeService) { }

    public ngOnInit(): void {
        this.departmentDataList$ = combineLatest(this.departments$, this.employeeService.getEmployees())
            .pipe(map(([departments, employees]: [Department[], Employee[]]) =>
                departments.map((department: Department) => {
                    const employeeCount: number = employees.filter((employee: Employee) =>
                        employee.departments.map((employeeDepartment: Department) =>
                            employeeDepartment.id).includes(department.id)).length;
                    return { department, employeeCount };
                }),
            ));
    }

    public trackDepartmentData: TrackByFunction<DepartmentData> = (_index: number, departmentData: DepartmentData) => departmentData.department.id;

    /**
     * Emit that a given department has been selected
     * @param department The department
     */
    public selectDepartment(department: Department): void {
        this.departmentSelected.emit(department);
    }

    /**
     * Create a new department and emit it
     */
    public createDepartment(): void {
        this.departments$
            .pipe(
                take(1),
                switchMap(
                    (departments: Department[]) => {
                        const name: string = this.getUniqueNewDepartmentName(departments);
                        return this.departmentService.createDepartment({ name });
                    }))
            .pipe(take(1))
            .subscribe((newDepartment: Department) => this.selectDepartment(newDepartment), console.error);
    }


    /**
     * Create a name for a new department that starts with the string 'Ny afdeling' and ends with a number uniquely
     * @param departments The departments to scan for names
     */
    private getUniqueNewDepartmentName(departments: Department[]): string {
        const possibleNames: string[] = departments.map((_: Department, index: number) => 'Ny afdeling ' + (index + 1));
        const departmentNames: string[] = departments.map((department: Department) => department.name);
        let newName: string = '';
        possibleNames.forEach((possibleName: string) => {
            const nameExists: boolean = !!departmentNames.find((existingName: string) => existingName === possibleName);
            if (!nameExists) {
                newName = possibleName;
                return;
            }
        });

        return newName ? newName : 'Ny afdeling ' + (departments.length + 1);
    }
}
