import { Injectable } from '@angular/core';
import { ActivationEnd, Data, Event, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, map, shareReplay, switchMap } from 'rxjs/operators';
import { Package } from '../model/Freemium/Package';
import { RelionModule } from '../model/RelionModule';
import { ConfigurationService } from './Configuration/configuration-service.model';

@Injectable({
    providedIn: 'root',
})
export class ModuleService {

    public get currentModule$(): Observable<RelionModule> { return this._currentModule$; }
    public get currentModuleIsPro$(): Observable<boolean> { return this._currentModuleIsPro$; }

    private readonly _currentModule$: Observable<RelionModule>;
    private readonly _currentModuleIsPro$: Observable<boolean>;

    constructor(
        private router: Router,
        private configurationService: ConfigurationService,
    ) {
        this._currentModule$ = this.router.events.pipe(
            filter((event: Event) => event instanceof ActivationEnd),
            switchMap(() => {
                let route = this.router.routerState.root;
                while (route.firstChild) route = route.firstChild;
                return route.data;
            }),
            map((data: Data) => data.module),
            shareReplay(1),
        );

        this._currentModuleIsPro$ = this.configurationService.isPro(Package.PRO);
    }
}
