
export function addGoogleAnalytics(googleAnalyticsTrackingId) {
    const head = document.getElementsByTagName('head')[0];

    const googleAnalyticScript = document.createElement('script');

    googleAnalyticScript.innerHTML =
        '(function (i, s, o, g, r, a, m) {\n' +
        '   i[\'GoogleAnalyticsObject\'] = r; i[r] = i[r] || function () {\n' +
        '       (i[r].q = i[r].q || []).push(arguments)\n' +
        '   }, i[r].l = 1 * new Date(); a = s.createElement(o),\n' +
        '       m = s.getElementsByTagName(o)[0]; a.async = 1; a.src = g; m.parentNode.insertBefore(a, m)\n' +
        '})(window, document, \'script\', \'https://www.google-analytics.com/analytics.js\', \'ga\');\n' +
        'ga(\'create\', \'' + googleAnalyticsTrackingId + '\', \'auto\');'

    head.insertBefore(googleAnalyticScript, head.firstChild);
}