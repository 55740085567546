<div class="settings-header">
    <div class="subtitle">{{ 'sidebar.settings' | translate | capitalize }}:</div>
    <div class="title-and-beta">
        <div class="title">
            {{ title }}
        </div>
        <div class="beta"
             *ngIf="beta">
            {{ 'settings.free-beta' | translate }}
        </div>
    </div>
</div>