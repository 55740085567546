<ngb-datepicker #ngbDatepicker
                [(ngModel)]="model"
                [startDate]="model"
                [showWeekNumbers]="true"
                [dayTemplate]="customDay"
                (dateSelect)="onDateSelect($event)"
                (navigate)="updateWeekNumberColour()"></ngb-datepicker>

<ng-template #customDay
             let-date
             let-currentMonth="currentMonth"
             let-selected="selected"
             let-today="today"
             let-disabled="disabled"
             let-focused="focused">
    <span class="custom-day"
          [class.dot]="selectionStyle === selectionStyles.DOT"
          [class.circle]="selectionStyle === selectionStyles.CIRCLE"
          [class.focused]="focused"
          [class.today]="today"
          [class.primary]="getColor(date) === color.PRIMARY"
          [class.red]="getColor(date) === color.RED"
          [class.success]="getColor(date) === color.SUCCESS"
          [class.warn]="getColor(date) === color.WARN"
          [class.grey]="getColor(date) === color.GREY"
          [class.text-muted]="disabled"
          [class.selected]="selected"
          [class.otherMonth]="date.month !== currentMonth">
        <span>{{ date.day }}</span>
    </span>
</ng-template>