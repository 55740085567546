import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { Employee } from '../../../core/model';
import { CompanySetting, CompanySettingsService, EmployeeService } from '../../../core/services';
import { ModalService } from '../../../core/services/ModalService.model';

@Component({
    selector: 'app-profile-picture-uploader',
    templateUrl: './profile-picture-uploader.component.html',
    styleUrls: ['./profile-picture-uploader.component.scss'],
})
export class ProfilePictureUploaderComponent implements OnInit {

    public employee$: Observable<Employee>;
    public disallowEditProfilePicture$: Observable<boolean>;
    @ViewChild('input') public input: ElementRef<HTMLInputElement>;

    constructor(
        private modalService: ModalService,
        private employeeService: EmployeeService,
        private companySettingsService: CompanySettingsService,
    ) { }

    public ngOnInit(): void {
        this.employee$ = this.employeeService.getAuthenticatedEmployee();
        this.disallowEditProfilePicture$ = this.companySettingsService
            .loadSetting(CompanySetting.EMPLOYEE_DISALLOW_EDIT_PROFILE_PICTURE_ENABLED);
    }

    /**
     * Open the ProfilePictureModal on file inputs
     * @param event The input event with a base64 encoded string of the file
     */
    public fileChangeEvent(event: Event): void {
        const base64EncodedString: { base64: string } = event as unknown as { base64: string };
        this.modalService.openProfilePictureModal(base64EncodedString, this.input.nativeElement);
    }
}
