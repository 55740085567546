import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { merge, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

/**
 * Get an observable initially emitting the current locale and afterwards on locale changes
 */
export function getCurrentLang(translateService: TranslateService): Observable<string> {
    return merge(
        // Emitting the current lang
        of(translateService.currentLang),
        // Everytime the current lang *changes*, emit it
        translateService.onLangChange.pipe(map(({ lang }: LangChangeEvent) => lang)),
    );
}
