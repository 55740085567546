import { Injectable } from '@angular/core';
import { Coordinates } from '../model/Coordinates';
declare var RequestLocationChannel: undefined | { postMessage: (message: string) => void; };

@Injectable({
    providedIn: 'root',
})
export class GeolocationService {
    private readonly geolocationKey: string = 'GEOLOCATION';
    private readonly noLocationValue: string = 'NO_LOCATION';

    /**
     * Gets the location of the user
     * @returns a Promise that resolves with the location or rejects if no location data is found
     */
    public async getUserLocation(): Promise<Coordinates> {
        // We need to check with "typeof" as the browser fails if we try to look at it directly
        return (typeof RequestLocationChannel !== 'undefined')
            ? this.getLocationFromApp()
            : this.getLocationFromBrowser();
    }

    /**
     * Gets the user location through the Geolocation Web API
     */
    private getLocationFromBrowser(): Promise<Coordinates> {
        // Return a promise resolving with the found location or rejecting if no location
        return new Promise((resolve: (value: Coordinates) => void, reject: () => void) =>
            navigator.geolocation.getCurrentPosition(
                ({ coords: c }) => resolve({ accuracy: c.accuracy, latitude: c.latitude, longitude: c.longitude }),
                reject,
                { enableHighAccuracy: true, timeout: 10000 },
            ));
    }

    /**
     * Gets the user location through through our custom flutter app implementation
     */
    private getLocationFromApp(): Promise<Coordinates> {
        // Empty the geolocation local storage
        localStorage.removeItem(this.geolocationKey);

        // Request user location from the app
        RequestLocationChannel?.postMessage('');

        // Return a promise resolving once the app has put something in the localstorage
        return new Promise((resolve: (value: Coordinates) => void, reject: () => void) => {
            const waitForLocation = () => {
                // Get the current location from ls
                const location = this.getLocationFromLocalStorage();

                // If found but no location was there, reject the promise
                if (location === null) reject();

                // If found with location, resolve it
                else if (location !== undefined) resolve(location);

                // Otherwise look again in 100 ms
                else setTimeout(waitForLocation.bind(this), 100);
            };
            // Start looking for the location
            waitForLocation();
        });
    }

    /**
     * Pull location data from localstorage
     */
    private getLocationFromLocalStorage(): Coordinates | null | undefined {
        // Get the location string from localstorage
        const locationString: string | null = localStorage.getItem(this.geolocationKey);

        // If nothing is there, return undefined
        if (locationString === null) return undefined;

        // If the no location value is there, return null
        if (locationString === this.noLocationValue) return null;

        // Otherwise parse and return the coordinates
        return JSON.parse(locationString);
    }
}

