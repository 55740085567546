import { Injectable, Injector } from '@angular/core';
import { AngularFirestoreCollection, DocumentReference, Query, QueryDocumentSnapshot, QuerySnapshot } from '@angular/fire/firestore';
import { WriteBatch } from '@firebase/firestore-types';
import * as _ from 'lodash';
import { combineLatest, from, Observable, of, Subscriber } from 'rxjs';
import { map, shareReplay, switchMap, take } from 'rxjs/operators';
import { EmployeeService, RoleService, ShiftQueryParams, ShiftService } from '../..';
import { mapFirestoreApprovalToApproval } from '../../../../shared/utilities/ApprovalUtils';
import { mapShiftToFirestoreshift } from '../../../../shared/utilities/ShiftUtils/mapShiftToFirestoreshift';
import { Employee, PunchSource, Role, Shift } from '../../../model';
import { CustomBreak } from '../../../model/CustomBreak';
import { FirestoreShift } from '../../../model/Firestore';
import { FirestoreSalarySwipe } from '../../../model/Firestore/firestore-salary-swipe.model';
import { FirestoreCustomBreak } from '../../../model/Firestore/FirestoreCustomBreak';
import { mapFirestorePunchToPunch } from '../../../model/Firestore/FirestorePunch';
import { FirestoreSalaryExport } from '../../../model/Firestore/FirestoreSalaryExport';
import Timestamp from '../../../model/Firestore/FirestoreTimestamp';
import { SalarySwipe } from '../../../model/salary-swipe.model';
import { SalaryExport } from '../../../model/SalaryExport';
import { Auth, FirestoreService } from '../../firestore.service';

@Injectable({
    providedIn: 'root',
})
export class FirestoreShiftService extends ShiftService {
    private readonly collectionPath: string = 'shifts';
    private cache: Map<string, Observable<Shift>>;
    private employeeCache: Map<string, Employee>;

    constructor(
        private firestoreService: FirestoreService,
        private injector: Injector,
        private roleService: RoleService,
        private employeeService: EmployeeService,
    ) {
        super();
        this.cache = new Map();
        this.employeeCache = new Map();

        this.subscribeToEmployeeChanges();
    }

    /**
     * Creates a shift object in the database
     * @param shift The shift to create
     */
    public createShift(shift: Omit<Shift, 'id'>): Observable<Shift> {
        return this.firestoreService.authenticate(this.collectionPath)
            .pipe(
                switchMap((auth: Auth<FirestoreShift>) => auth.collection
                    .add(FirestoreShiftService.mapNewShiftToNewFirestoreShift(shift))),
                switchMap((docRef: DocumentReference) => this.getShift(docRef.id)));
    }

    /**
     * Creates shifts in the database
     * @param shifts The shifts to create
     */
    public createShifts(shifts: Omit<Shift, 'id'>[]): Observable<Shift[]> {
        return this.firestoreService.authenticate(this.collectionPath)
            .pipe(
                switchMap((auth: Auth<FirestoreShift>) =>
                    from(this.commitCreateBatch(shifts, auth.collection, FirestoreShiftService.mapNewShiftToNewFirestoreShift))),
                switchMap((ids: string[]) => combineLatest(ids.map((id: string) => this.getShift(id)))),
            );
    }

    /**
     * Deletes a Shift in the database
     * @param shift The Shift to delete
     * @returns An observable of the Shift that was deleted. The stream is completed after the deletion.
     */
    public deleteShift(shift: Shift): Observable<Shift> {
        return this.firestoreService.authenticate(this.collectionPath)
            .pipe(
                switchMap((auth: Auth<FirestoreShift>) => auth.collection.doc(shift.id).delete()),
                map((_: void) => shift));
    }

    /**
     * Delete shifts in the database
     * @param shifts THe shifts to delete
     */
    public deleteShifts(shifts: Shift[]): Observable<Shift[]> {
        return this.firestoreService.authenticate(this.collectionPath)
            .pipe(
                switchMap((auth: Auth<FirestoreShift>) => this.commitDeleteBatch(shifts, auth.collection)),
                switchMap(() => of(shifts)));
    }

    /**
     * Gets a shift with the given id from the database
     * @param id The id of the shift
     */
    public getShift(id: string): Observable<Shift> {
        const cache: Observable<Shift> | undefined = this.cache.get(id);
        if (cache) return cache;

        const shiftObservable: Observable<Shift> = this.getFirestoreShift(id)
            .pipe(
                switchMap((firestoreShift: FirestoreShift) => this.mapFirestoreShiftToShift(firestoreShift, id)),
                shareReplay(1),
            );
        this.cache.set(id, shiftObservable);
        return shiftObservable;
    }

    /**
     * Get shifts from the database that satisfies the given parameters
     * @param startDateFrom When the shift {startDate} will be from
     * @param startDateTo When the shift {startDate} will be to
     * @param released If the queried shifts are released or not
     * @param roleId The roleId of the shifts
     * @param employeeId The employee of the shift
     */
    public getShifts(params: ShiftQueryParams): Observable<Shift[]> {
        return this.firestoreService.authenticate(this.collectionPath).pipe(
            switchMap((auth: Auth<FirestoreShift>) =>
                this.firestoreService.observeOnSnapshot(
                    auth.collection,
                    this.constructShiftQueryFromSearchParams(params, auth.collection))),
            switchMap(async (snapshot: QuerySnapshot<FirestoreShift>) => Promise.all(
                snapshot.docs.map(async (doc: QueryDocumentSnapshot<FirestoreShift>) => await this.mapDocumentSnapshotToShift(doc)))),
            map((shifts: Shift[]) =>
                // Handle isDeleted query param
                (!params || !params.isDeleted)
                    ? shifts.filter((shift: Shift) => !shift.role.isDeleted && !shift.role.department.isDeleted)
                    : shifts),
        );
    }

    /**
     * Updates the shift in the database
     * @param shift
     */
    public updateShift(shift: Shift): Observable<Shift> {
        return this.firestoreService.authenticate(this.collectionPath)
            .pipe(switchMap((auth: Auth<FirestoreShift>) =>
                auth.collection
                    .doc(shift.id)
                    .update(mapShiftToFirestoreshift(shift))),
                switchMap(() => this.getShift(shift.id)));
    }

    /**
     * Updates shifts in the database
     * @param shifts
     * @returns An observable of all the updated shifts
     */
    public updateShifts(shifts: Shift[]): Observable<Shift[]> {
        return this.firestoreService.authenticate(this.collectionPath)
            .pipe(switchMap((auth: Auth<FirestoreShift>) => new Observable<void>((subscriber: Subscriber<void>): void => {
                this.firestoreService.commitUpdateBatch(shifts, auth.collection, mapShiftToFirestoreshift)
                    .then(() => {
                        subscriber.next();
                        return subscriber.complete();
                    })
                    .catch((err: Error) => subscriber.error(err));
            })),
                switchMap(() => combineLatest(shifts.map((shift: Shift) => this.getShift(shift.id)))));
    }

    /**
     * Maps a new `Shift` object to a new `FirestoreShift` object. This means that the shiftForSaleID field will be null
     * @param shift
     */
    private static mapNewShiftToNewFirestoreShift(shift: Omit<Shift, 'id'>): FirestoreShift {
        const firestoreShift: FirestoreShift = {
            role: shift.role.id,
            department: shift.role.department.id,
            start: Timestamp.fromDate(shift.start),
            end: Timestamp.fromDate(shift.end),
            approved: shift.approved,
            shiftForSaleID: null,
            employeeID: shift.employee ? shift.employee.id : null,
            released: shift.released,
            checkIn: null,
            checkOut: null,
            checkInMsg: null,
            checkOutMsg: null,
            checkInSource: null,
            checkOutSource: null,
            comment: shift.comment
                ? { senderId: shift.comment.sender.id, message: shift.comment.message, time: Timestamp.fromDate(shift.comment.time) }
                : null,
        };
        return firestoreShift;
    }

    /**
     * Update the employee cache whenever the employee service returns new employees
     */
    private subscribeToEmployeeChanges(): void {
        this.employeeService.getEmployees({ isDeleted: true }).subscribe({
            next: (employees: Employee[]) => employees.forEach((employee: Employee) => {
                const cachedEmployee: Employee | undefined = this.employeeCache.get(employee.id);
                if (cachedEmployee) _.assign(cachedEmployee, employee);
                else this.employeeCache.set(employee.id, employee);
            }),
        });
    }

    /**
     * Gets an employee from the cache
     * If it's not in the cache, put it in the cache
     * @param employeeID The ID of the employee to retrieve
     */
    private async getEmployee(employeeID: string): Promise<Employee> {
        const employee: Employee | undefined = this.employeeCache.get(employeeID);

        if (employee) return employee;

        const retrievedEmployee = await this.employeeService.getEmployee(employeeID).pipe(take(1)).toPromise();
        this.employeeCache.set(employeeID, retrievedEmployee);
        return retrievedEmployee;
    }

    /**
     * Takes a QueryDocumentSnapshot and maps its into a Shift object
     * @param snapshot  The snapshot to map to a Shift object
     * @returns The Shift created from the DocumentChange
     */
    private mapDocumentSnapshotToShift(snapshot: QueryDocumentSnapshot<FirestoreShift>): Promise<Shift> {
        const firestoreShift: FirestoreShift = snapshot.data();
        return this.mapFirestoreShiftToShift(firestoreShift, snapshot.id);
    }

    /**
     * Maps a FirestoreShift to a Shift
     * @param fss The FirestoreShift. This has no ´id´
     * @param id The id of the shift.
     */
    private async mapFirestoreShiftToShift(fss: FirestoreShift, id: string): Promise<Shift> {
        try {
            const role: Role = await this.roleService.getRole(fss.role).pipe(take(1)).toPromise();
            const employee: Employee | null = fss.employeeID && await this.getEmployee(fss.employeeID) || null;

            const shift: Shift = {
                id,
                role,
                employee,
                start: fss.start.toDate(),
                end: fss.end.toDate(),
                approved: !!fss.approved,
                released: !!fss.released,
                isForSale: !!fss.shiftForSaleID,
                exportLogs: fss.exportLogs ? await this.mapFirestoreSalaryExportLogsToExportLogs(fss.exportLogs) : [],
                salarySwipe: !!fss.salarySwipe ? await this.mapFirestoreSalarySwipeToSalarySwipe(fss.salarySwipe) : undefined,
            };
            if (fss.approval) {
                shift.approval = await mapFirestoreApprovalToApproval(fss.approval, this.injector);
            }
            if (fss.checkIn) {
                shift.checkin = {
                    source: fss.checkInSource || PunchSource.AUTOMATIC,
                    time: fss.checkIn.toDate(),
                    message: fss.checkInMsg || undefined,
                    overwriting: fss.overwritingCheckinPunch ? mapFirestorePunchToPunch(fss.overwritingCheckinPunch) : undefined,
                };
            }
            if (fss.checkOut) {
                shift.checkout = {
                    source: fss.checkInSource || PunchSource.AUTOMATIC,
                    time: fss.checkOut.toDate(),
                    message: fss.checkOutMsg || undefined,
                    overwriting: fss.overwritingCheckoutPunch ? mapFirestorePunchToPunch(fss.overwritingCheckoutPunch) : undefined,
                };
            }

            if (fss.comment) {
                shift.comment = {
                    sender: await this.getEmployee(fss.comment.senderId),
                    message: fss.comment.message,
                    time: fss.comment.time.toDate(),
                };
            }
            if (fss.customBreak) {
                shift.customBreak = await this.mapFirestoreCustomBreakToCustomBreak(fss.customBreak);
            }

            return Promise.resolve(shift);
        } catch (e) {
            return Promise.reject(e);
        }
    }

    private async mapFirestoreSalaryExportLogsToExportLogs(fsSalaryExports: FirestoreSalaryExport[]): Promise<SalaryExport[]> {
        const salaryExports = await Promise.all(
            fsSalaryExports.map(async (fsSalaryExport: FirestoreSalaryExport) => {
                const exportedBy: Employee | 'automatic' = fsSalaryExport.exportedBy !== 'automatic'
                    ? await this.getEmployee(fsSalaryExport.exportedBy)
                    : 'automatic';
                const salaryIntegration = fsSalaryExport.salaryIntegration;
                const time = fsSalaryExport.time.toDate();
                return { exportedBy, salaryIntegration, time };
            }),
        );
        return _.sortBy(salaryExports, 'time');
    }

    private async mapFirestoreSalarySwipeToSalarySwipe(fsSalarySwipe: FirestoreSalarySwipe): Promise<SalarySwipe> {
        return {
            enabledBy: fsSalarySwipe.enabledBy === 'automatic' ? 'automatic' : await this.getEmployee(fsSalarySwipe.enabledBy),
            enabledOn: fsSalarySwipe.enabledOn.toDate(),
        };
    }

    private async mapFirestoreCustomBreakToCustomBreak(fsCustomBreak: FirestoreCustomBreak): Promise<CustomBreak> {
        return {
            createdOn: fsCustomBreak.createdOn.toDate(),
            duration: fsCustomBreak.duration,
            creator: fsCustomBreak.creator !== 'automatic'
                ? await this.getEmployee(fsCustomBreak.creator)
                : 'automatic',
        };
    }

    /**
     * Adds a batch of data to the firestore database
     * @param values The values to add
     * @param collection The collection to add the values to
     * @param mapper The function for mapping an application type to a database type objsect
     */
    private commitCreateBatch<T, E>(
        values: T[], collection: AngularFirestoreCollection, mapper: (data: T) => E): Promise<string[]> {
        // If no values to commit, resolve with empty array
        if (values.length === 0) return Promise.resolve([]);

        return new Promise((resolve: (ids: string[]) => void, reject: (err: string) => void): Promise<void> => {
            // Init the batch and splice it (limit is 500)
            const batch: WriteBatch = this.firestoreService.createBatch();
            let nextBatch: T[] = [];
            if (values.length > 500) nextBatch = values.splice(500);

            // Init id array
            const ids: string[] = [];
            values.forEach((value: T) => {
                // Create id and add it to list
                const id: string = this.firestoreService.createId();
                ids.push(id);
                // Create document reference and add it to batch
                const docRef: DocumentReference = collection.doc(id).ref;
                batch.set(docRef, mapper(value), { merge: true });
            });

            // Return a promise resolving on succesful upload with the ids of the added shifts
            return batch.commit()
                .then(async () => {
                    const nextIds: string[] = await this.commitCreateBatch(nextBatch, collection, mapper);
                    return resolve(ids.concat(nextIds));
                })
                .catch(reject);
        });
    }

    /**
     * Deletes a batch of data in the firestore database
     * @param values The values to delete
     * @param collection The collection where the values are to be deleted from
     * @param mapper The function for mapping an application type to a database type objsect
     */
    private commitDeleteBatch<T extends { id?: string }>(
        values: T[], collection: AngularFirestoreCollection): Promise<void> {
        if (values.length === 0) return Promise.resolve();
        const batch: WriteBatch = this.firestoreService.createBatch();

        let nextBatch: T[] = [];

        if (values.length > 500) {
            nextBatch = values.splice(500);
        }

        values.forEach((value: T) => {
            const docRef: DocumentReference = collection.doc(value.id).ref;
            batch.delete(docRef);
        });

        return batch
            .commit()
            .then(() => this.commitDeleteBatch(nextBatch, collection));
    }


    /**
     * Gets a FirestoreShift from the database with a given id
     * @param id
     */
    private getFirestoreShift(id: string): Observable<FirestoreShift> {
        return this.firestoreService.authenticate(this.collectionPath)
            .pipe(switchMap((auth: Auth<FirestoreShift>) => {
                return new Observable<FirestoreShift>((subscriber: Subscriber<FirestoreShift>): void => {
                    auth.collection
                        .doc(id)
                        .ref
                        .onSnapshot(
                            (snapshot) => {
                                if (!snapshot.data()) subscriber.error(`No shift with id ${ id }`);
                                return subscriber.next(snapshot.data());
                            },
                            (error: Error) => subscriber.error(error),
                            () => subscriber.complete());
                });
            }));
    }

    /**
     * Construct a query for shifts from search params
     * @param params The search params
     * @param collection The collection
     */
    private constructShiftQueryFromSearchParams(params: ShiftQueryParams, collection: AngularFirestoreCollection<FirestoreShift>): Query {
        let query: Query = collection.ref;
        if (!!params.employee) query = query.where('employeeID', '==', params.employee.id);
        if (params.employee === null) query = query.where('employeeID', '==', null);
        if (params.released !== undefined) query = query.where('released', '==', params.released);
        if (params.role) query = query.where('role', '==', params.role.id);
        if (params.department) query = query.where('department', '==', params.department.id);
        if (params.startDateFrom) query = query.where('start', '>=', params.startDateFrom);
        if (params.startDateTo) query = query.where('start', '<', params.startDateTo);
        if (params.endDateFrom) query = query.where('end', '>=', params.endDateFrom);
        if (params.endDateTo) query = query.where('end', '<', params.endDateTo);
        if (params.orderByStartDate) query = query.orderBy('start', params.orderByStartDate);
        if (params.approved !== undefined) query = query.where('approved', '==', params.approved);
        if (params.resultLimit) query = query.limit(params.resultLimit);
        if (params.notCheckedOut) query = query.where('checkOut', '==', null);
        if (params.checkedIn) query = query.where('checkIn', '>', new Date(1900, 0));
        return query;
    }
}
