<div class="day-view-container">
    <div class="day-view-top-bars"
         *ngIf="dateService.getDateObservable() | async as date">
        <div class="day-view-info d-flex flex-row align-items-center">
            <div class="day-view-info-date flex-grow-1">
                <h1>
                    {{date | date: 'EEEE d. MMMM ' : '' : translateService.currentLang | capitalize}}<span>{{date |
                        date: 'yyyy'}}</span>
                </h1>
            </div>
            <div class="day-view-info-hours style-10 mr-5 d-flex align-items-center flex-row">
                <i class="far fa-clock mr-2"></i>
                {{ 'shared.total-hours' | translate | capitalize}}
                <span class="font-bold ml-1">{{(((hours$ | async) | number:'1.0-2') || '0')}}</span>
            </div>
            <div class="day-view-info-nav d-flex flex-row">
                <ui-date-switcher range="day">
                </ui-date-switcher>
            </div>
        </div>

        <div class="day-view-actions d-flex flex-row align-items-center">
            <ui-button-standard *ngIf="(shifts$ | async)?.length > 0;"
                                (click)="newShift()"
                                matTooltip="{{ 'admin.calendar.new-shift-tooltip' | translate | capitalize }}"
                                color="primary"
                                [appHelpOverlay]="'tips.create-first-shift' | translate"
                                [appHelpOverlayPosition]="'right'"
                                [appHelpOverlayIcon]="'far fa-arrow-left'"
                                [appHelpOverlayEnabled]="showCreateShiftTip$ | async">
                {{ 'admin.calendar.new-shift' | translate | capitalize }}
            </ui-button-standard>
            <ui-button-action *ngIf="(shifts$ | async)?.length === 0"
                              (click)="newShift()"
                              matTooltip="{{ 'admin.calendar.new-shift-tooltip' | translate | capitalize }}"
                              [appHelpOverlay]="'tips.create-first-shift' | translate"
                              [appHelpOverlayPosition]="'right'"
                              [appHelpOverlayIcon]="'far fa-arrow-left'"
                              [appHelpOverlayEnabled]="showCreateShiftTip$ | async">
                {{ 'admin.calendar.new-shift' | translate | capitalize }}
            </ui-button-action>

            <span class="spacer d-flex flex-grow-1"></span>

            <ui-button-icon [class.contains-comment]="dailyNoteExists"
                            matTooltip="{{ 'admin.calendar.add-note-to-day' | translate }}"
                            [ngbPopover]="dailyNotePopover"
                            popoverClass="comment-popover"
                            [placement]="['bottom', 'top']"
                            container="body"
                            autoClose="outside">
                <i class="far fa-comment"></i>

                <ng-template #dailyNotePopover>
                    <textarea *appIfPro="Packages.PRO"
                              placeholder="{{ 'admin.calendar.add-daily-note-here' | translate }}..."
                              class="comment-textarea"
                              (blur)="saveDailyNote(day$, $event.target.value)"
                              (input)="dailyNoteExists = !!$event.target.value">{{ (day$ | async)?.note?.message }}</textarea>

                    <div class="pro-feature"
                         *appIfNotPro="Packages.PRO">
                        <div class="title">
                            {{ 'modal.pro-feature.title' | translate }}
                        </div>
                        <div class="description">
                            {{ 'modal.pro-feature.descriptions.day-comment' | translate }}
                        </div>
                        <app-upgrade-button></app-upgrade-button>
                    </div>
                </ng-template>
            </ui-button-icon>

            <div class="divider"></div>

            <ui-button-icon [disabled]="!(canPasteDay$ | async)"
                            matTooltip="{{ 'admin.calendar.insert-day-copy-tooltip' | translate | capitalize }}"
                            (click)="pasteDay(date);"
                            class="mr-2">
                <i class="far fa-clipboard"></i>
            </ui-button-icon>
            <ui-button-icon [disabled]="!((shifts$ | async)?.length > 0)"
                            (click)="copyDay()"
                            matTooltip="{{ 'admin.calendar.copy-day' | translate }}"
                            appSnackMessage="{{ 'admin.calendar.copy-day-snack' | translate }}"
                            appSnackColor="success">
                <i class="far fa-copy"></i>
            </ui-button-icon>
        </div>
    </div>

    <div id="shifts-container">
        <ng-container *ngIf="shifts">
            <overlay-scrollbars class="animation-wrapper"
                                [options]="{overflowBehavior: {x:'hidden'}}"
                                *ngIf="shifts.length">
                <app-day-view-shift *ngFor="let shift of shifts; trackBy: track"
                                    [id]="shift.id"
                                    class="list-item"
                                    [shift]="shift"
                                    [roles$]="roles$"
                                    [hoursThisWeek$]="hoursThisWeek$"
                                    [wishes$]="wishes$">
                </app-day-view-shift>
            </overlay-scrollbars>
            <div *ngIf="shifts.length === 0"
                 class="no-shifts text-faded">
                <img src="assets/images/no-shifts.svg">
                {{ 'admin.calendar.no-shifts' | translate | capitalize }}
            </div>
        </ng-container>

        <ng-container *ngIf="shifts$ | loading">
            <div class="spinner">
                <i class="fad fa-spin fa-2x fa-spinner-third"></i>
            </div>
        </ng-container>
    </div>
</div>