import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { combineLatest, Observable, of } from 'rxjs';
import { distinctUntilChanged, map, shareReplay, switchMap, take } from 'rxjs/operators';
import { salarySupplementsCollectionPath } from '../model/Firestore';
import { HolidaySalarySupplement, SalarySupplement, WeekdaySalarySupplement } from '../model/SalarySupplement.model';
import { Auth, FirestoreService } from './firestore.service';
import { CompanySetting, CompanySettingsService } from './Settings/CompanySettings/CompanySettingsService.model';

type FirestoreSalarySupplement = Omit<SalarySupplement, 'id'>;
export type NewSalarySupplement = Omit<WeekdaySalarySupplement, 'id'> | Omit<HolidaySalarySupplement, 'id'>;

export function isExistingSupplement(supplement: SalarySupplement | NewSalarySupplement): supplement is SalarySupplement {
    const casted: SalarySupplement = <SalarySupplement> supplement;
    return !!casted.id;
}

@Injectable({
    providedIn: 'root',
})
export class SalarySupplementService {
    private cachedSupplements$: Observable<SalarySupplement[]>;

    constructor(
        private firestoreService: FirestoreService,
        private companySettingsService: CompanySettingsService,
    ) {
        this.setupCache();
    }

    public async createSalarySupplement(supplement: NewSalarySupplement): Promise<void> {
        await (await this.getAuth()).collection.add(supplement);
    }

    public async updateSalarySupplement(supplement: SalarySupplement): Promise<void> {
        return (await this.getAuth()).collection.doc<FirestoreSalarySupplement>(supplement.id).set(_.omit(supplement, 'id'));
    }

    public async deleteSalarySupplement(supplement: SalarySupplement): Promise<void> {
        await (await this.getAuth()).collection.doc(supplement.id).delete();
    }

    public getSalarySupplements(): Observable<SalarySupplement[]> {
        return this.cachedSupplements$;
    }

    public getSalarySupplement(id: string): Observable<SalarySupplement> {
        return this.cachedSupplements$.pipe(
            map((supplements: SalarySupplement[]) => {
                const matchedSupplement: SalarySupplement | undefined = supplements.find((current: SalarySupplement) => current.id === id);
                if (!matchedSupplement) throw new Error(`No supplement with id ${ id }`);
                return matchedSupplement;
            }),
            distinctUntilChanged(_.isEqual),
        );
    }

    private getAuth(): Promise<Auth<FirestoreSalarySupplement>> {
        return this.firestoreService.authenticate<FirestoreSalarySupplement>(salarySupplementsCollectionPath).pipe(take(1)).toPromise();
    }

    private setupCache(): void {
        this.cachedSupplements$ = combineLatest([
            this.companySettingsService.loadSetting(CompanySetting.SALARY_SUPPLEMENTS_ENABLED_ON),
            this.firestoreService.authenticate<FirestoreSalarySupplement>(salarySupplementsCollectionPath),
        ]).pipe(
            // If supplements are enabled, return an observable of the supplements, otherwise return an observable of an empty list
            switchMap(([supplementsEnabled, auth]: [Date | null, Auth<SalarySupplement>]) => !!supplementsEnabled
                ? auth.collection.valueChanges<'id'>({ idField: 'id' })
                : of([])),
            shareReplay(1),
        );
    }
}
