import * as _ from 'lodash';
import * as moment from 'moment';
import { Employee, Shift, ShiftBreak } from '../../core/model';
import { DataloenDateEntry, DataloenEntry, DataloenExportData, DataloenPayTypeCodes } from '../../core/model/Integrations/Dataloen';
import { SalarySupplement } from '../../core/model/SalarySupplement.model';
import { SalaryService } from '../../core/services/salary.service';
import { getShiftDuration } from './ShiftUtils/durationUtils';

export function generateDataloenExportData(
    mappedEmployees: [Employee, string][],
    shifts: Shift[],
    shiftBreak: ShiftBreak | null,
    supplements: SalarySupplement[],
    multipleDepartments: boolean): DataloenExportData {

    // Filter away the shifts where there is no employee mapping
    const shiftsWithMappedEmployees = shifts.filter((shift: Shift) =>
        mappedEmployees.find((mappedEmployee: [Employee, string]) => mappedEmployee[0].id === shift.employee?.id && mappedEmployee[1]));
    // Find all dates where there is at least one shift
    const dates = shiftsWithMappedEmployees.reduce((dateStrings: string[], shift: Shift) => {
        const date = shift.start.toDateString();
        if (!dateStrings.includes(date)) dateStrings.push(date);
        return dateStrings;
    }, [] as string[]);
    const collection: DataloenDateEntry[] = dates.map((date: string) => {
        const entries = _.flatten(shiftsWithMappedEmployees.filter((shift: Shift) => shift.start.toDateString() === date)
            .map((shift: Shift) => {
                const foundEmployeeMapping: [Employee, string] | undefined =
                    mappedEmployees.find((mappedEmployee: [Employee, string]) => shift.employee?.id === mappedEmployee[0].id);
                if (foundEmployeeMapping) return generateEntries(foundEmployeeMapping, shift, shiftBreak, supplements, multipleDepartments);
                return [];
            }));

        return {
            date: moment(new Date(date)).format('yyyy-MM-DD'),
            reference: '',
            entries,
        };
    });
    const shiftIDs = shiftsWithMappedEmployees.map((shift: Shift) => shift.id);
    return { collection, shiftIDs };
}

function generateEntries(
    [employee, dataloenID]: [Employee, string],
    shift: Shift,
    shiftBreak: ShiftBreak | null,
    supplements: SalarySupplement[],
    multipleDepartments: boolean): DataloenEntry[] {
    const createEntry = (payTypeCode: DataloenPayTypeCodes, value: number, comment: string): DataloenEntry => ({
        employeeId: dataloenID,
        payTypeCode,
        value,
        comment,
    });

    const entries = [
        createEntry(DataloenPayTypeCodes.NORMAL_WAGE,
            employee.hourlyWage ? employee.hourlyWage * 100 : 0,
            'Timeløn'),
        createEntry(DataloenPayTypeCodes.NORMAL_HOURS,
            getShiftDuration(shift, shiftBreak) * 100,
            `${ shift.role.name } ${ multipleDepartments ? '- ' + shift.role.department.name : '' }`),
        createEntry(DataloenPayTypeCodes.WORK_HOURS,
            getShiftDuration(shift, shiftBreak) * 100,
            `${ shift.role.name } ${ multipleDepartments ? '- ' + shift.role.department.name : '' }`),
    ];

    if (SalaryService.shiftToSupplement(shift, supplements, shiftBreak) > 0) {
        entries.push(createEntry(
            DataloenPayTypeCodes.SALARY_SUPPLEMENT_SUM,
            SalaryService.shiftToSupplement(shift, supplements, shiftBreak) * 100,
            'Tillæg',
        ));
    }

    return entries;
}
