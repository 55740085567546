import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { Language, UserSettings } from '../../../core/model/UserSettings';
import { UserSettingsService } from '../../../core/services';

@Component({
    selector: 'app-language-dropdown',
    templateUrl: './language-dropdown.component.html',
    styleUrls: ['./language-dropdown.component.scss'],
})
export class LanguageDropdownComponent implements OnInit {
    public selectedLanguage: Observable<string>;
    public languages: { display: string, value: Language }[];

    constructor(
        private userSettingsService: UserSettingsService,
        private translateService: TranslateService,
    ) {
        this.languages = [
            { display: 'Dansk', value: Language.DANISH },
            { display: 'English', value: Language.ENGLISH },
        ];
    }

    public ngOnInit(): void {
        // Get the selected language mapped to human readable format as an observable
        this.selectedLanguage = this.userSettingsService.loadSetting(UserSettings.LANGUAGE).pipe(
            map((lang: Language) => {
                switch (lang) {
                    case Language.DANISH: return 'Dansk';
                    case Language.ENGLISH: return 'English';
                }
            }),
        );
    }

    /**
     * Save the new language to settings and change the UI language
     * @param language - The new language
     */
    public setLanguage(language: Language): void {
        this.userSettingsService.saveSetting(UserSettings.LANGUAGE, language).pipe(take(1)).subscribe();
        this.translateService.use(language);
    }
}
