import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { combineLatest, Observable } from 'rxjs';
import { map, shareReplay, switchMap, take } from 'rxjs/operators';
import { ShiftForSale, ShiftForSaleStatus } from '../../../core/model';
import { ShiftForSaleService } from '../../../core/services';
import { SideDrawerService } from '../../../core/services/side-drawer.service';
import { getCurrentLang } from '../../utilities/TranslateUtility';
import { ShiftDetailModalComponent } from '../shift-detail-modal/shift-detail-modal.component';
import { NotificationData } from './notification/notification.component';

@Component({
    selector: 'app-notification-center',
    templateUrl: './notification-center.component.html',
    styleUrls: ['./notification-center.component.scss'],
})
export class NotificationCenterComponent implements OnInit {
    public notifications$: Observable<NotificationData[]>;

    constructor(
        private shiftForSaleService: ShiftForSaleService,
        private translateService: TranslateService,
        private sideDrawerService: SideDrawerService,
    ) { }

    public ngOnInit(): void {
        this.notifications$ = combineLatest([
            this.shiftForSaleService.getRelevantShiftsForSale().pipe(shareReplay(1)),
            getCurrentLang(this.translateService),
        ]).pipe(
            switchMap(this.shiftForSaleToNotification.bind(this)),
            map((notifications: NotificationData[]) => _.orderBy(notifications, 'time', 'desc')),
            shareReplay(1),
        );
    }

    /**
     * Filter away irrelevant shifts for sale and convert the rest to notifications
     * @param shiftsForSale All shifts for sale
     * @param locale The current locale
     */
    private shiftForSaleToNotification([shiftsForSale, locale]: [ShiftForSale[], string]): Promise<NotificationData[]> {
        return Promise.all(shiftsForSale
            // Map shifts for sale to notifications
            .map(async (shiftForSale: ShiftForSale) => {
                const shift = shiftForSale.shift;
                const noti = ({
                    clickAction: () => this.sideDrawerService.open(ShiftDetailModalComponent, { shiftDetailData: { shift } }),
                    time: shiftForSale.closedOn || shiftForSale.createdOn,
                    read: !!shiftForSale.closedOn,
                    ...await this.getNotificationText(shiftForSale, locale),
                });

                const imageURL = shiftForSale.buyer?.imageURL || shiftForSale.seller?.imageURL;

                if (imageURL) return { imageSrc: imageURL, ...noti };
                else return { iconClass: 'far fa-sync-alt', ...noti };
            }));
    }

    /**
     * Get the title and subtitle of a ShiftForSale notification
     * @param shiftForSale The ShiftForSale to use
     * @param locale The current locale
     */
    private async getNotificationText(shiftForSale: ShiftForSale, locale: string): Promise<{ title: string, subtitle: string }> {
        const seller: string | undefined = shiftForSale.seller?.firstname;
        const buyer: string | undefined = shiftForSale.buyer?.firstname;
        const date: string = shiftForSale.shift.start.toLocaleString(locale, { month: 'short', day: 'numeric' });

        return this.getText(shiftForSale, date, seller, buyer);
    }

    private async getText(shiftForSale: ShiftForSale, date: string, seller?: string, buyer?: string):
        Promise<{ title: string; subtitle: string; }> {
        const translate = (key: string, params?: Object) => this.translateService.get(key, params).pipe(take(1)).toPromise();

        switch (shiftForSale.status) {
            case ShiftForSaleStatus.PENDING: return {
                title: await translate(seller ? 'notification.shift-cover.awaiting' : 'notification.transfer.approve-transfer'),
                subtitle: await translate(seller
                    ? 'notification.shift-cover.is-selling-shift'
                    : 'notification.transfer.free-shift-on', { seller, date }),
            };
            case ShiftForSaleStatus.ACCEPTED: return {
                title: await translate(seller ? 'notification.shift-cover.accepted' : 'notification.transfer.transfer-approved'),
                subtitle: await translate(seller ? 'notification.shift-cover.took-over-shift-on' : 'notification.transfer.took-over-unassigned-shift', { seller, buyer, date }),
            };
            case ShiftForSaleStatus.DECLINED: return {
                title: await translate(seller ? 'notification.shift-cover.declined' : 'notification.transfer.transfer-declined'),
                subtitle: await translate(seller
                    ? 'notification.shift-cover.is-selling-shift'
                    : 'notification.transfer.free-shift-on', { seller, date }),
            };
            default: throw new Error('Getting notification text for ShiftForSale with unexpected status');
        }
    }
}
