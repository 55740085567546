import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { MediaService } from '../../../../../../core/services';

@Component({
    selector: 'app-wish-legend',
    templateUrl: './wish-legend.component.html',
    styleUrls: ['./wish-legend.component.scss'],
})

export class WishLegendComponent implements OnInit, OnDestroy {
    public bigScreen: boolean;
    public subscription: Subscription;
    constructor(private mediaService: MediaService) { }

    public ngOnInit(): void {
        this.subscription = this.mediaService.observeMediaChanges().subscribe({
            next: (big: boolean): boolean => this.bigScreen = big,
        });
    }

    public ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }
}
