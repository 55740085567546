import { Component, Input } from '@angular/core';
import { ButtonDirective } from '../button/button.directive';

@Component({
    selector: 'ui-button-icon',
    templateUrl: './button-icon.component.html',
    styleUrls: ['./button-icon.component.scss'],
})

export class ButtonIconComponent extends ButtonDirective {
    @Input() public basic: boolean;
    @Input() public round: boolean;
}
