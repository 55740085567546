import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface CalendarModalData {
    msg: string;
    success: boolean;
}

@Component({
    selector: 'app-fill-shifts-modal',
    templateUrl: './fill-shifts-modal.component.html',
    styleUrls: ['./fill-shifts-modal.component.scss'],
})

export class FillShiftsModalComponent implements OnInit {

    public showConflicts: boolean;

    constructor(
        public dialogRef: MatDialogRef<FillShiftsModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: CalendarModalData,
    ) { }

    public ngOnInit(): void {
        const elem: Element | null = document.getElementsByTagName('app-fill-shifts-dialog')[0] || null;
        const dialogElem: Element | null = elem && elem.parentElement;
        if (dialogElem) (<HTMLElement> dialogElem).style.padding = '0';
    }

    /**
     * Closes the fill shifts dialog.
     */
    public closeDialog(): void {
        this.dialogRef.close();
    }
}
