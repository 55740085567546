<div class="profile-card">
    <div class="d-flex flex-row">
        <div class="profile-card-info-group flex-grow-1  d-flex flex-row">
            <div class="status-dot"
                 [ngClass]="{'status-warn': status === statusTypes.WARN, 'status-success': status === statusTypes.SUCCESS, 'status-none': status === undefined}">
            </div>
            <img class="profile-picture"
                 [lazyLoad]="imageURL">
            <div class="info-text d-flex flex-column">
                <span class="info-title dot-dot-dot"
                      [ngClass]="{'status-none': status === undefined}">{{title}}</span>
                <span class="info-subtitle dot-dot-dot"
                      [ngClass]="{'status-none': status === undefined}">{{subtitle}}</span>
            </div>
        </div>
        <div class="profile-card-content d-flex ">
            <ng-content></ng-content>
        </div>
    </div>
</div>