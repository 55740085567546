import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { Department } from '../../../core/model/Department/Department.model';
import { DepartmentService } from '../../../core/services/Department/DepartmentService.model';
import { ModalService } from '../../../core/services/ModalService.model';

@Component({
    selector: 'app-department-selector',
    templateUrl: './department-selector.component.html',
    styleUrls: ['./department-selector.component.scss'],
})
export class DepartmentSelectorComponent implements OnInit {
    @Input() public department?: Department;
    @Output() public departmentChange: EventEmitter<Department | undefined>;
    @Input() public disableAllDepartments: '' | undefined;
    public availableDepartments$: Observable<Department[]>;

    constructor(
        private departmentService: DepartmentService,
        private modalService: ModalService
    ) {
        this.departmentChange = new EventEmitter();
    }

    public ngOnInit(): void {
        this.availableDepartments$ = this.departmentService.getDepartments();
    }

    public selectDepartment(department?: Department): void {
        this.department = department;
        this.departmentChange.emit(department);
    }

    /**
     * Opens the modal for editing departments.
     */
    public openEditDepartmentModal(): void {
        this.modalService.openEditDepartmentModal();
    }
}
