import { Injectable } from '@angular/core';
import { DocumentReference, QueryDocumentSnapshot, QuerySnapshot } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { map, shareReplay, switchMap } from 'rxjs/operators';
import { Department } from '../../../model/Department/Department.model';
import { DepartmentImpl } from '../../../model/Department/DepartmentImpl';
import { departmentsCollectionPath as collectionPath } from '../../../model/Firestore';
import { FirestoreDepartment } from '../../../model/Firestore/FirestoreDepartment';
import { Auth, FirestoreService } from '../../firestore.service';
import { UserSettingsService } from '../../Settings/UserSettings/UserSettingsService.model';
import { DepartmentQueryParams, DepartmentService } from '../DepartmentService.model';

@Injectable({
    providedIn: 'root',
})
export class FirestoreDepartmentService extends DepartmentService {
    private cache$?: Observable<Department[]>;

    constructor(
        private firestoreService: FirestoreService,
        userSettingsService: UserSettingsService,
    ) {
        super(userSettingsService);
    }

    public createDepartment(department: Omit<Department, 'id'>): Observable<Department> {
        return this.firestoreService.authenticate(collectionPath)
            .pipe(
                switchMap((auth: Auth<FirestoreDepartment>) => auth.collection
                    .add(FirestoreDepartmentService.mapDepartmentToFirestoreDepartment(department))),
                switchMap((docRef: DocumentReference) => this.getDepartment(docRef.id)));
    }
    public getDepartment(id: string): Observable<Department> {
        if (!this.cache$) this.setupCache();

        return this.cache$!.pipe(map((departments: Department[]) => {
            const foundDepartment = departments.find((department: Department) => department.id === id);
            if (!foundDepartment) {
                throw Error(`No department with id ${ id }. Departments: ${ departments.map((department: Department) => department.id).toString() }`);
            }
            return foundDepartment;
        }));
    }

    public getDepartments(params?: DepartmentQueryParams): Observable<Department[]> {
        if (!this.cache$) this.setupCache();
        return this.cache$!.pipe(
            map((departments: Department[]) => {
                // Handle isDeleted query param
                if (!params || !params.isDeleted) {
                    departments = departments.filter((department: Department) => !department.isDeleted);
                }

                if (params?.employee) {
                    departments = departments.filter((department: Department) =>
                        params.employee?.departments.find((employeeDepartment: Department) =>
                            department.id === employeeDepartment.id));
                }
                return departments;
            }),
            map((departments: Department[]) => departments.sort(DepartmentImpl.sortByName)),
        );
    }

    public updateDepartment(department: Department): Observable<Department> {
        return this.firestoreService.authenticate(collectionPath)
            .pipe(switchMap((auth: Auth<FirestoreDepartment>) =>
                auth.collection
                    .doc(department.id)
                    .update(FirestoreDepartmentService.mapDepartmentToFirestoreDepartment(department))),
                switchMap(() => this.getDepartment(department.id)));
    }

    public deleteDepartment(department: Department): Observable<Department> {
        return this.firestoreService.authenticate(collectionPath)
            .pipe(
                switchMap((auth: Auth<FirestoreDepartment>) => auth.collection.doc(department.id).update({ isDeleted: true })),
                switchMap(() => this.getDepartment(department.id)),
            );
    }

    /**
     * Maps a firestore department to an application department
     * @param firestoreDepartment The firestore department to map to a application department
     * @param id The id of the firestore department
     */
    private static mapFirestoreDepartmentToDepartment(firestoreDepartment: FirestoreDepartment, id: string): Department {
        const department: Department = {
            id: id,
            name: firestoreDepartment.name,
            street: firestoreDepartment.street,
            city: firestoreDepartment.city,
            zip: firestoreDepartment.zip,
            isDeleted: firestoreDepartment.isDeleted || false,
            forecastingModel: firestoreDepartment.forecastingModel,
        };
        return department;
    }

    /**
     * Maps an application department to a firestore department, ready to be sent to the firestore
     * @param department The department to map to a firestore department
     */
    private static mapDepartmentToFirestoreDepartment(department: Omit<Department, 'id'>): FirestoreDepartment {
        const firestoreDepartment: FirestoreDepartment = {
            name: department.name,
        };
        if (department.street) firestoreDepartment.street = department.street;
        if (department.city) firestoreDepartment.city = department.city;
        if (department.zip) firestoreDepartment.zip = department.zip;
        if (department.isDeleted) firestoreDepartment.isDeleted = department.isDeleted;
        if (department.forecastingModel) firestoreDepartment.forecastingModel = department.forecastingModel;

        return firestoreDepartment;
    }

    private setupCache(): void {
        this.cache$ = this.firestoreService.authenticate<FirestoreDepartment>(collectionPath)
            .pipe(
                switchMap((auth: Auth<FirestoreDepartment>) => this.firestoreService.observeOnSnapshot(auth.collection)),
                map((snapshot: QuerySnapshot<FirestoreDepartment>) =>
                    snapshot.docs.map((doc: QueryDocumentSnapshot<FirestoreDepartment>) =>
                        FirestoreDepartmentService.mapFirestoreDepartmentToDepartment(doc.data(), doc.id))),
                shareReplay(1));
    }
}
