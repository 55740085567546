import { Pipe, PipeTransform } from '@angular/core';
import { EmployeeSource } from '../../modules/admin/day-view/web/components/day-view-shift/employee-source';

@Pipe({
  name: 'employeeSource'
})
export class EmployeeSourcePipe implements PipeTransform {

  transform(value: unknown): EmployeeSource {
    const source = value as EmployeeSource;
    if (source.type === undefined || source.employeeCategory === undefined) throw new Error(`${value} cannot be type casted to EmployeeSource`);
    return value as EmployeeSource;
  }

}
