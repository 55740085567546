<div class="overlay-container">
    <div class="content">
        <span>{{ 'onboarding.welcome-to-relion' | translate }}</span>
        <img alt="Welcome-image"
             src="assets/images/welcome.svg">
        <ui-button-action class="save-button"
                          (click)="closeClicked()">
            {{ 'onboarding.create-my-first-department' | translate }}
        </ui-button-action>
    </div>
</div>
