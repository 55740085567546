import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { take } from 'rxjs/operators';
import { UserActivity, UserActivityService } from '../../../../core/services';
import { ModalService } from '../../../../core/services/ModalService.model';

@Component({
    selector: 'app-confirm-go-back-to-free',
    templateUrl: './confirm-go-back-to-free.component.html',
    styleUrls: ['./confirm-go-back-to-free.component.scss'],
})
export class ConfirmGoBackToFreeComponent implements OnInit {

    constructor(
        private dialogRef: MatDialogRef<ConfirmGoBackToFreeComponent>,
        private modalService: ModalService,
        private userActivityService: UserActivityService,
    ) { }

    public ngOnInit(): void {
    }

    public upgrade(): void {
        this.modalService.openProUpgradeOverlay(true);
    }

    public close(): void {
        this.dialogRef.close();
        this.userActivityService.save(UserActivity.DISMISSED_TRIAL_EXPIRATION).pipe(take(1)).toPromise();
    }
}
