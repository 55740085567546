import { Directive, ElementRef, Input, OnInit } from '@angular/core';

@Directive({
    selector: '[appHelpOverlay]',
})
export class HelpOverlayDirective implements OnInit {
    @Input()
    public get appHelpOverlay(): string {
        return this._appHelpOverlay;
    }
    public set appHelpOverlay(value: string) {
        this._appHelpOverlay = value;
        this.updateText(value);
    }
    @Input() public appHelpOverlayIcon: string;
    @Input() public appHelpOverlayPosition: 'right' | 'left' | 'top' | 'bottom' | 'center';
    @Input()
    public get appHelpOverlayEnabled(): boolean {
        return this._appHelpOverlayEnabled;
    }
    public set appHelpOverlayEnabled(value: boolean) {
        this._appHelpOverlayEnabled = value;
        this.setEnabledState(value);
    }

    private _appHelpOverlay: string;
    private _appHelpOverlayEnabled: boolean;
    private tipContainer: HTMLDivElement;
    private textElement: HTMLDivElement;
    private el: HTMLElement;
    private margin: number = 16;

    constructor(private _el: ElementRef) {
        this.el = <HTMLElement> this._el.nativeElement;
    }

    public ngOnInit(): void {
        // The wrapping container
        this.tipContainer = document.createElement('div');
        this.tipContainer.classList.add('help-overlay');

        // Set the position style of the parent element to relative, so we can position the overlay with absolute
        this.el.style.position = 'relative';

        // The icon element
        const icon: HTMLElement = document.createElement('i');
        if (this.appHelpOverlayIcon) icon.classList.add(...this.appHelpOverlayIcon.split(' '), 'icon');
        // The text element
        this.textElement = document.createElement('div');
        this.textElement.classList.add('text');
        this.textElement.innerText = this.appHelpOverlay;

        // The space between icon and text
        const space: HTMLDivElement = document.createElement('div');
        space.style.width = this.margin / 2 + 'px';

        // Combine the elements
        if (this.appHelpOverlayPosition === 'left') {
            this.tipContainer.appendChild(this.textElement);
            this.tipContainer.appendChild(space);
            this.tipContainer.appendChild(icon);
        } else {
            this.tipContainer.appendChild(icon);
            this.tipContainer.appendChild(space);
            this.tipContainer.appendChild(this.textElement);
        }
        this.el.appendChild(this.tipContainer);
        this.setEnabledState(this.appHelpOverlayEnabled);
        // Set the position of the container - timeout is needed since we have to wait for the offset width
        this.setPosition();
    }

    private updateText(newText): void {
        if (this.textElement) this.textElement.innerText = newText;
    }

    private setEnabledState(enabled: boolean) {
        if (this.tipContainer) {
            if (!enabled && !this.tipContainer.classList.contains('disabled')) this.tipContainer.classList.add('disabled');
            if (enabled && this.tipContainer.classList.contains('disabled')) {
                this.setPosition();
                this.tipContainer.classList.remove('disabled');
            }
        }
    }

    private setPosition() {
        setTimeout(() => {
            switch (this.appHelpOverlayPosition) {
                case 'right':
                    this.tipContainer.style.left = this.el.offsetWidth + this.margin + 'px';
                    this.tipContainer.style.top = (this.el.offsetHeight - this.tipContainer.offsetHeight) / 2 + 'px';
                    break;
                case 'top':
                    this.tipContainer.style.left = '50%';
                    this.tipContainer.style.bottom = this.el.offsetHeight + this.margin + 'px';
                    this.tipContainer.style.transform = 'translate(-50%)';
                    break;
                case 'bottom':
                    this.tipContainer.style.left = '50%';
                    this.tipContainer.style.top = this.el.offsetHeight + this.margin + 'px';
                    this.tipContainer.style.transform = 'translate(-50%)';
                    break;
                case 'left':
                    this.tipContainer.style.right = this.el.offsetWidth + this.margin + 'px';
                    this.tipContainer.style.top = (this.el.offsetHeight - this.tipContainer.offsetHeight) / 2 + 'px';
                    break;
                case 'center':
                    this.tipContainer.style.left = '50%';
                    this.tipContainer.style.top = '50%';
                    this.tipContainer.style.transform = 'translate(-50%, -50%)';
                    break;
            }
        }, 0);
    }
}
