import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { Employee, Role } from '../../../../../../../core/model';
import { Department } from '../../../../../../../core/model/Department/Department.model';
import { EmployeeService, RoleService } from '../../../../../../../core/services';
import { DepartmentService } from '../../../../../../../core/services/Department/DepartmentService.model';
import { SnackbarColor, SnackbarService } from '../../../../../../../core/services/snackbar/snackbar.service';

@Component({
    selector: 'app-delete-department-modal',
    templateUrl: './delete-department-modal.component.html',
    styleUrls: ['./delete-department-modal.component.scss'],
})
export class DeleteDepartmentModalComponent {

    public loading: boolean = false;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: Department,
        private departmentService: DepartmentService,
        private roleService: RoleService,
        private employeeService: EmployeeService,
        private translateService: TranslateService,
        private snackService: SnackbarService,
        private modalRef: MatDialogRef<DeleteDepartmentModalComponent>,
    ) { }

    public async deleteDepartment(): Promise<void> {
        this.loading = true;

        // Delete roles and remove the department from primary
        await Promise.all([
            this.deleteRoles(),
            this.removeEmployeePrimaries(),
        ]);

        // Delete the department service
        this.departmentService.deleteDepartment(this.data).pipe(take(1)).subscribe({
            next: async (): Promise<void> => {
                // Change the active department if it's set to the one we're deleting
                const activeDepartment: Department = await this.departmentService.getActiveDepartment().pipe(take(1)).toPromise();
                if (activeDepartment.id === this.data.id) {
                    const newActiveDepartment: Department =
                        (await this.departmentService.getDepartments().pipe(take(1)).toPromise())[0]!;
                    this.departmentService.setActiveDepartment(newActiveDepartment);
                }
                this.close(true);
            },
            error: async (error: Error): Promise<void> => {
                const errorMsg: string = await this.translateService.get('error.unable-to-delete-department').pipe(take(1)).toPromise();
                this.snackService.displaySnack(errorMsg, SnackbarColor.warn);
                this.loading = false;
                throw error;
            },
        });
    }

    /**
     * Close the modal
     */
    public close(didDeleteDepartment: boolean): void {
        this.modalRef.close(didDeleteDepartment);
    }

    /**
     * Mark the roles of the department as deleted
     */
    private async deleteRoles(): Promise<void> {
        const roles: Role[] = await this.roleService.getRoles({ department: this.data }).pipe(take(1)).toPromise();
        if (roles.length === 0) return Promise.resolve();

        roles.forEach((role: Role) => role.isDeleted = true);
        const promises: Promise<Role>[] = roles.map((role: Role) => this.roleService.updateRole(role).pipe(take(1)).toPromise());
        return Promise.all(promises).then(() => void 0);
    }

    /**
     * Remove the department from the primaryDepartment for all employees who has it
     */
    private async removeEmployeePrimaries(): Promise<void> {
        const employees: Employee[] = await this.employeeService.getEmployees({ primaryDepartment: this.data }).pipe(take(1)).toPromise();
        if (employees.length === 0) return Promise.resolve();

        employees.forEach((employee: Employee) => employee.primaryDepartment = null);
        return this.employeeService.updateEmployees(employees).pipe(take(1)).toPromise().then(() => void 0);
    }
}
