import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Employee, ShiftForSale } from '../../../../core/model';
import { Package } from '../../../../core/model/Freemium/Package';
import { ShiftForSaleService } from '../../../../core/services';
import { ConfigurationService } from '../../../../core/services/Configuration/configuration-service.model';

@Component({
    selector: 'app-shift-detail-approve-trade-action',
    templateUrl: './shift-detail-approve-trade-action.component.html',
    styleUrls: ['./shift-detail-approve-trade-action.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShiftDetailApproveTradeActionComponent implements OnInit {
    @Input() public shiftForSale: ShiftForSale;
    public isPro$: Observable<boolean>;
    public selectedBuyer: Employee;

    constructor(
        private configurationService: ConfigurationService,
        private shiftForSaleService: ShiftForSaleService,
    ) { }

    public ngOnInit(): void {
        this.isPro$ = this.configurationService.isPro(Package.PRO);
        this.selectedBuyer = this.shiftForSale.bids[0]!.bidder;
    }

    public acceptTrade(shiftForSale: ShiftForSale): void {
        if (!this.selectedBuyer) return;
        this.shiftForSaleService.acceptShiftForSale(shiftForSale, this.selectedBuyer);
    }

    public rejectTrade(shiftForSale: ShiftForSale): void {
        this.shiftForSaleService.rejectShiftForSale(shiftForSale);
    }
}
