<div class="settings-page">
    <app-settings-header title="{{ 'shared.payment' | translate | capitalize }}"></app-settings-header>

    <overlay-scrollbars class="settings-content">
        <app-settings-divider title="{{ 'shared.overview' | translate }}"></app-settings-divider>
        <div class="overview">
            <div class="status"
                 *ngIf="status$ | async as status">
                <div class="title">{{ ('payment-settings.' + status + '.title') | translate }}</div>
                <div class="subtitle">{{ ('payment-settings.' + status + '.subtitle') | translate }}</div>
                <div class="illustration">
                    <img src="assets/images/upgrade-rocket.svg"
                         alt="Illustration of upgrade">
                </div>
                <div class="cta">
                    <ui-button-action *ngIf="status !== SubscriptionStatus.PRO"
                                      (click)="upgrade(status)">
                        {{ ('payment-settings.' + status + '.cta') | translate }}
                    </ui-button-action>
                </div>
            </div>

            <ng-container>
                <div class="subscription-details">
                    <ng-container *ngIf="paymentDetails$ | async as paymentDetails; else loadingPayment">
                        <div class="subscription">
                            <div class="header">
                                {{ 'freemium.subscription' | translate }}
                            </div>
                            <div class="content">
                                {{ 'shared.relion' | translate }}
                                -
                                <ng-container *ngIf="trialExpiration$ | async as trialExpiration">
                                    <ng-container *ngIf="trialExpiration <= now; else onTrial">
                                        {{ paymentDetails[Package.PRO] ? ('freemium.pro' | translate) : ('freemium.free-version' | translate) }}:
                                        <ng-container *ngIf="paymentDetails[Package.PRO] as details">
                                            {{details.terms.interval_type === 'month' ? ('freemium.monthly' | translate) : ('freemium.yearly' | translate) }}
                                        </ng-container>
                                        {{ 'freemium.dkk' | translate: { 'amount': (paymentDetails[Package.PRO]?.override_price || paymentDetails[Package.PRO]?.terms.price || 0) } }}
                                    </ng-container>
                                    <br>
                                    <ng-template #onTrial>{{'freemium.free-trial' | translate }}</ng-template>
                                </ng-container>
                            </div>
                        </div>
                        <div class="payment-and-card"
                             *ngIf="paymentDetails.card">
                            <div class="header">
                                {{ 'freemium.payment' | translate }}
                            </div>
                            <div class="content">
                                <ng-container *ngIf="paymentDetails.nextInvoice">
                                    {{ 'freemium.your-next-invoice-of' | translate }}
                                    {{ 'freemium.dkk' | translate: { 'amount': paymentDetails.nextInvoice!.amount } }}
                                    {{ 'freemium.is-due-on' | translate }}
                                    {{ paymentDetails.nextInvoice!.due | date: 'longDate': '' : locale }}
                                </ng-container>
                                <ng-container *ngIf="!paymentDetails.nextInvoice">
                                    {{ 'freemium.no-active-subsscription' | translate }}
                                </ng-container>
                            </div>
                            <div class="header">
                                {{ 'freemium.card' | translate }}
                            </div>
                            <div class="content d-flex align-items-center">
                                <ng-container *ngIf="!updatingCard">
                                    <div>
                                        {{ paymentDetails.card!.brand | capitalize}}:
                                        {{ paymentDetails.card!.number }}
                                        <br>
                                        {{ 'freemium.expiry' | translate }}:
                                        <span class="expired"
                                              *ngIf="paymentDetails.card!.expired; else expiry">
                                            {{ 'freemium.expired' | translate }}
                                        </span>
                                        <ng-template #expiry>
                                            {{ paymentDetails.card!.expiry }}
                                        </ng-template>
                                    </div>
                                    <div class="update-card-btn">
                                        <ui-button-standard color="primary"
                                                            (click)="updateCard()"
                                                            [transparent]="true">
                                            {{ 'shared.update' | translate | capitalize }}
                                        </ui-button-standard>
                                    </div>
                                </ng-container>
                                <div *ngIf="updatingCard">
                                    <i class="fad fa-spin fa-spinner-third"></i>
                                    <span class="updating-card-text">
                                        {{ 'freemium.loading-new-card' | translate }}
                                    </span>
                                </div>
                            </div>

                        </div>
                    </ng-container>
                </div>
                <div class="questions">
                    {{ 'freemium.subscription-questions' | translate }}
                </div>
            </ng-container>

            <div class="referral-container">
                <img class="pro-rocket"
                     src="assets/images/referral-rocket.svg">
                <ui-button-action (click)="openReferralLinkModal()"
                                  class="referral-button">{{ 'referral.button' | translate }}</ui-button-action>
                <div class="referral-description">{{ 'referral.description' | translate }}</div>
            </div>

            <ng-template #loadingPayment>
                <div class="payment-loader">
                    <i class="fad fa-spinner-third fa-spin"></i>
                </div>
            </ng-template>
        </div>
    </overlay-scrollbars>
</div>