import { Observable } from 'rxjs';
import * as uiAvatar from 'ui-avatars';
import { Company, Employee, Role } from '../../model';
import { Department } from '../../model/Department/Department.model';

export abstract class EmployeeService {
    constructor() { }

    /**
     * Filters a list of employees according to search keywords
     * @param employees - The employees to filter
     * @param searchString - The search string
     */
    public static filterEmployees(employees: Employee[], searchKeywords: string[]): Employee[] {
        const result: Employee[] = [];

        for (const employee of employees) {
            let numberOfMatches = 0;

            for (const searchKeyword of searchKeywords) {
                let employeeRolesString = '';

                // Construct roles string to search through
                for (const employeeRole of employee.roles) {
                    employeeRolesString += employeeRole.name + ' ';
                }

                // Construct a string of to search through
                let employeeString = employee.firstname + ' '
                    + employee.lastname + ' ' + employee.CPR + ' '
                    + employee.street + ' ' + employee.city + ' '
                    + employee.email + ' ' + employee.phone + ' '
                    + employee.zip + ' ' + employeeRolesString;
                employeeString = employeeString.toLowerCase();

                if (employeeString.indexOf(searchKeyword) !== -1) {
                    numberOfMatches += 1;
                }
            }

            // We need exact number of matches to match number of keywords
            if (numberOfMatches === searchKeywords.length) {
                result.push(employee);
            }
        }
        return result;
    }

    /**
     * Sets the image of an employee
     * @param employee - The employee to set the image on
     * @param img - A base64 encoding of the image to set
     */
    public abstract setEmployeeImage(employee: Employee, img: string): Observable<Employee>;

    /**
     * Creates an employee in storage
     * @param employee Employee that you want to create.
     * @param company The company of the employee to create.
     */
    public abstract createEmployee(employee: Omit<Employee, 'id'> | Employee, company: Company): Observable<Employee>;

    /**
     * Gets a employee from the storage with the specific id.
     * @param id
     */
    public abstract getEmployee(id: string): Observable<Employee>;

    /**
     * Gets all the employees in storage.
     */
    public abstract getEmployees(employeeQueryParams?: EmployeeQueryParams): Observable<Employee[]>;

    /**
     * Updates an employee.
     * @param employee The employee you want to update.
     */
    public abstract updateEmployee(employee: Employee): Observable<Employee>;

    /**
     * Updates a list of employees.
     * @param employees The employees you want to update.
     */
    public abstract updateEmployees(employees: Employee[]): Observable<Employee[]>;

    /**
     * Deletes an employee.
     * @param employee The employee you want to delete.
     */
    public abstract deleteEmployee(employee: Employee): Observable<Employee>;

    /**
     * Get the employee logged in
     */
    public abstract getAuthenticatedEmployee(): Observable<Employee>;

    /**
     * Generates an avatar with an `employee`'s initials.
     */
    protected generateInitialAvatar(name: string): string {
        const avatarURL = uiAvatar.generateAvatar({
            uppercase: true,
            bold: true,
            rounded: true,
            name,
            color: '7C8CB6',
            background: 'E5E8F2',
        });
        return avatarURL + '&format=svg';
    }
}

export interface EmployeeQueryParams {
    isDeleted?: boolean;
    role?: Role;
    department?: Department;
    primaryDepartment?: Department;
}
