<div class="confirm_dialog_modal_container">
    <div class="titel_container">
        <p class="titel_text"
           [class.warn]="modalData.confirmDialogType === ConfirmDialogType.WARNING">
            {{modalData.titleTranslationKey |translate: modalData.titleTranslationParams }}
        </p>
    </div>
    <div class="subtitle_container">
        <p class="subtitle_text">
            {{ modalData.subtitleTranslationKey || '' | translate: modalData.subtitleTranslationParams }}
        </p>
    </div>

    <div class="buttons_group_container">
        <ui-button-standard (click)="close(false)">
            {{modalData.closeButtonTranslationKey | translate: modalData.closeButtonTranslationParams }}
        </ui-button-standard>
        <ng-container *ngIf="modalData.acceptButtonTranslationKey">
            <ui-button-standard *ngIf="modalData.confirmDialogType === ConfirmDialogType.WARNING"
                                class="button_confirm_dialog"
                                color="warn"
                                (click)="close(true)">
                {{modalData.acceptButtonTranslationKey | translate: modalData.acceptButtonTranslationParams }}
            </ui-button-standard>
            <ui-button-action *ngIf="modalData.confirmDialogType === ConfirmDialogType.INFO"
                              class="button_confirm_dialog"
                              (click)="close(true)">
                {{modalData.acceptButtonTranslationKey | translate: modalData.acceptButtonTranslationParams }}
            </ui-button-action>
        </ng-container>

    </div>
</div>