import { HttpClient } from '@angular/common/http';
import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, NavigationEnd, Router, RouterEvent } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as Sentry from '@sentry/browser';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import * as _ from 'lodash';
import * as moment from 'moment';
import { Intercom } from 'ng-intercom';
import { VersionCheckService } from 'ngx-version-check';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { filter, map, switchMap, take, takeWhile, tap } from 'rxjs/operators';
import { version } from '../../package.json';
import { environment } from '../environments/environment.dev';
import { routes, Routes, sidebarCategories } from './app-settings';
import { Company, Employee, Role, SideBarCategory } from './core/model';
import { Department } from './core/model/Department/Department.model';
import { DepartmentImpl } from './core/model/Department/DepartmentImpl';
import { FenerumAccount } from './core/model/Fenerum/FenerumAccount';
import { FenerumSubscriptionPlanTerms } from './core/model/Fenerum/FenerumSubscriptionPlanTerms';
import { BillingPeriod, FenerumSubscription, TRIAL_DURATION_DAYS } from './core/model/Freemium';
import { Package } from './core/model/Freemium/Package';
import { SubscriptionDetails } from './core/model/Freemium/SubscriptionDetails';
import { Subscriptions } from './core/model/Freemium/Subscriptions';
import { SalaryIntegration } from './core/model/Integrations/Shared';
import { SubscriptionStatus } from './core/model/SubscriptionStatus';
import { isLanguage, Language, UserSettings } from './core/model/UserSettings';
import { AuthService, AuthUser, CompanyService, EmployeeService, MediaService, SidebarService, UserActivity, UserActivityService, UserSettingsService } from './core/services';
import { AkitaSyncServiceService } from './core/services/akita-sync-service.service';
import { AuthQuery } from './core/services/auth/state/auth.query';
import { AuthState } from './core/services/auth/state/auth.store';
import { CloudFunctionsService } from './core/services/cloud-functions.service';
import { ConfigurationService } from './core/services/Configuration/configuration-service.model';
import { DepartmentService } from './core/services/Department/DepartmentService.model';
import { GoogleAnalyticsService } from './core/services/google-analytics.service';
import { IsOnlineService } from './core/services/is-online/is-online.service';
import { ModalService } from './core/services/ModalService.model';
import { IntegrationSettingsService } from './core/services/Settings/IntegrationSettings/Firestore/integration-settings.service';
import { SideDrawerService } from './core/services/side-drawer.service';
import { CalendarStepperCompletedModalComponent } from './modules/admin/onboarding/web/components/calendar-stepper-completed-modal/calendar-stepper-completed-modal.component';
import { CreateRolesModalComponent } from './modules/admin/onboarding/web/components/create-roles-modal/create-roles-modal.component';
import { EmployeeSignedUpModalComponent } from './modules/admin/onboarding/web/components/employee-signed-up-modal/employee-signed-up-modal.component';
import { OnboardingService } from './modules/admin/onboarding/web/services/Onboarding/onboarding.service';
import { ManageShiftsWidgetComponent } from './modules/employee/home/responsive/components/manage-shifts-widget/manage-shifts-widget.component';
import { isSameDate } from './shared/utilities/DateUtils/isSameDate';


@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
    public bigScreen: boolean;
    public employee: Employee;

    // This is the sidebar categories.
    public sideBarCategories: SideBarCategory[];
    public routes: Routes;
    public homeRoute$: Observable<string>;
    public departments$: Observable<Department[]>;
    public online: boolean;
    public newVersionStatus: 'no-new-version' | 'new-version-available' | 'new-version-postponed';
    public authUser$: Observable<AuthUser | null | undefined>;
    public preloadedImages: HTMLImageElement[];
    private subscriptions: Subscription[];
    private intercomBooted: boolean;
    private msPerMinute: number = 60000;

    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
        public sidebarService: SidebarService,
        public gas: GoogleAnalyticsService,
        public sideDrawerService: SideDrawerService,
        private authService: AuthService,
        private mediaService: MediaService,
        private intercom: Intercom,
        private modal: MatDialog,
        private companyService: CompanyService,
        public departmentService: DepartmentService,
        private userSettingsService: UserSettingsService,
        private translateService: TranslateService,
        private isOnlineService: IsOnlineService,
        private userActivityService: UserActivityService,
        private modalService: ModalService,
        private injector: Injector,
        private cloudFunctionsService: CloudFunctionsService,
        private versionCheckService: VersionCheckService,
        private http: HttpClient,
        private gtmService: GoogleTagManagerService,
    ) {
        this.subscriptions = [];
        this.routes = routes;
        this.online = true;

        this.subscriptions.push(
            this.router.events.subscribe((e: RouterEvent): void => {
                if (e instanceof NavigationEnd) {
                    const langParam = this.activatedRoute.snapshot.queryParams.lang;
                    // If the language param is supplied and it's different from current lang, update interface and user lang
                    if (isLanguage(langParam) && this.translateService.currentLang !== langParam) {
                        this.translateService.use(langParam);
                        this.userSettingsService.saveSetting(UserSettings.LANGUAGE, langParam).pipe(take(1)).subscribe();
                    }

                    this.gas.setPage(e);
                    this.gas.eventEmitter('Router', 'Redirect', e.urlAfterRedirects);

                    const gtmTag = { event: 'page', pageName: e.url };
                    this.gtmService.pushTag(gtmTag);
                }
            }),
        );

        this.newVersionStatus = 'no-new-version';
    }

    public ngOnInit(): void {

        // Add a resize listener, to update our vh variable (needed because iOS doesn't correctly update the built in vh)
        window.addEventListener('resize', () => document.documentElement.style.setProperty('--vh', `${ window.innerHeight }px`));

        // Make sure the vh variable is reset
        window.dispatchEvent(new Event('resize'));

        this.subscriptions.push(
            this.isOnlineService.getIsOnlineObservable().subscribe({
                next: (online: boolean): boolean => this.online = online,
            }),
        );

        // Start listening for new versions
        this.versionCheckService.startVersionChecking({
            frequency: 5 * this.msPerMinute, // Check every five minutes
            notification: () => {
                if (this.newVersionStatus !== 'new-version-postponed') this.newVersionStatus = 'new-version-available';
            },
        });

        this.authUser$ = this.authService.getUser();
        this.homeRoute$ = this.authUser$.pipe(
            filter((authUser: AuthUser) => authUser !== undefined && authUser !== null),
            map((authUser: AuthUser) => authUser.admin ? routes.admin.path : routes.employee.path));

        this.subscriptions.push(
            this.mediaService.observeMediaChanges().subscribe((big: boolean) => this.bigScreen = big),
            combineLatest([
                this.injector.get(AuthQuery).select().pipe(filter((state: AuthState) => !state.loading)),
                this.authUser$,
            ]).subscribe(([authState, user]: [AuthState, AuthUser]) => {
                if (authState.uid) {
                    this.preloadImages();

                    if (authState.roles?.companyID) {
                        this.injector.get(AkitaSyncServiceService).startCollectionSync();
                    }

                    this.injector.get(EmployeeService).getAuthenticatedEmployee().pipe(
                        switchMap((employee: Employee) => combineLatest([
                            this.userSettingsService.loadSetting(UserSettings.LATEST_SELECTED_DEPARTMENT),
                            this.departmentService.getDepartments({ employee: user.admin ? undefined : employee }),
                        ])),
                        take(1),
                    ).subscribe({
                        next: ([latestSelectedId, departments]: [string, Department[]]) => {
                            const latestDepartment: Department =
                                departments.find((department: Department) => department.id === latestSelectedId) || departments[0]!;
                            this.departmentService.setActiveDepartment(latestDepartment);
                        },
                    });

                    combineLatest([
                        this.injector.get(EmployeeService).getEmployee(user.user_id),
                        this.injector.get(CompanyService).getCompany(user.companyID),
                    ]).pipe(take(1)).toPromise().then(
                        ([emp, comp]: [Employee, Company]) => Sentry.setTags({
                            name: emp.firstname + ' ' + emp.lastname,
                            company: comp.name,
                        }));

                    if (user.admin) {
                        // Pre-load integration statuses by loading one of them
                        this.injector.get(IntegrationSettingsService)
                            .getIntegrationStatus(SalaryIntegration.SALARYDK).pipe(take(1)).toPromise();

                        if (this.mediaService.isBigScreen()) {
                            this.showOnboarding();
                            this.subscriptions.push(
                                combineLatest([
                                    this.injector.get(ConfigurationService).getSubscriptionStatus(Package.PRO)
                                        .pipe(filter(status => status === SubscriptionStatus.TRIAL_USED), take(1)),
                                    this.userActivityService.load(UserActivity.DISMISSED_TRIAL_EXPIRATION),
                                ]).pipe(take(1)).subscribe({
                                    next: ([status, trialDismissedOn]) => {
                                        if (status === SubscriptionStatus.TRIAL_USED && !trialDismissedOn) {
                                            this.modalService.openProShopOverlay();
                                        }
                                    },
                                }),
                            );
                        }
                    }

                    this.subscriptions.push(
                        // Reload the language every time a (new) user logs in
                        this.userSettingsService.loadSetting(UserSettings.LANGUAGE).subscribe(
                            (language: Language) => this.translateService.use(language)),
                    );

                    // Init the departments (sorted by name)
                    this.departments$ = this.departmentService.getDepartments()
                        .pipe(map((deps: Department[]) => deps.sort(DepartmentImpl.sortByName)));

                    this.subscriptions.push(
                        this.injector.get(EmployeeService).getEmployee(user.user_id).pipe(
                            tap((emp: Employee) => {
                                this.employee = emp;
                                this.bootIntercom(emp, user);
                            })).subscribe(),
                    );

                    this.setSideBarCategories(user);
                } else if (this.intercomBooted) {
                    this.intercomBooted = false;
                    this.intercom.shutdown();
                    this.injector.get(AkitaSyncServiceService).stopCollectionSync();
                }

                // Return if user has no sidebarcategories (e.g. in case of no user)
                if (!this.sideBarCategories) return;
            }),
        );
    }

    /**
     * Shows the different global onboarding elements
     */
    public showOnboarding(): void {
        this.subscriptions.push(this.setupOnboardingCompletedModal());
        this.subscriptions.push(this.setupNewEmployeeOnboardingModal());
        this.subscriptions.push(this.setupWelcomeAndRoleCreationModal());
    }

    public ngOnDestroy(): void {
        this.subscriptions.forEach((subs: Subscription) => subs.unsubscribe());
    }

    /**
     * Sets the categories of the sidebar depending on the login privileges.
     * @param user - The logged in user
     */
    public setSideBarCategories(user: AuthUser): void {
        // If no user is logged in, show no categories in the sidebar
        if (!user) {
            this.sideBarCategories = [];
        } else if (user.admin) {
            // If admin is logged in, set admin categories
            this.sideBarCategories = sidebarCategories.admin;
        } else {
            // Else if employee logged in, set employee categories
            this.sideBarCategories = sidebarCategories.employee;
        }
    }

    /**
     * Changes the currently selected category
     * @param sideBarCategory - The category to select
     */
    public onNavLinkClicked(): void {
        if (!this.mediaService.isBigScreen()) this.sidebarService.setSideBarOpenState(false);
    }

    /**
     * Opens the role onboarding modal
     */
    public openRoleOnboardingModal(): void {
        this.modal.open(CreateRolesModalComponent, {
            autoFocus: false,
            width: '448px',
            maxWidth: '95vw',
            disableClose: true,
        });
    }

    /**
     * Opens the employee signed up modal
     */
    public openEmployeeSignedUpModalComponent(): void {
        this.modal.open(EmployeeSignedUpModalComponent, {
            disableClose: true,
        });
    }

    /**
     * Handle a click on the profile icon
     */
    public async profileClicked(): Promise<void | boolean> {
        const admin: boolean = await this.authService.userIsAdmin().pipe(take(1)).toPromise();

        // If not admin, go to employee settings page and return
        if (!admin) return this.router.navigate(['/' + routes.employee.settings]);

        // If admin and bigscreen, open the settings modal, otherwise go to admin settings page
        if (this.bigScreen) this.sideDrawerService.open(ManageShiftsWidgetComponent);
        else this.router.navigate(['/' + routes.admin.settings]);

        // Tell the sidebar that it was clicked
        this.onNavLinkClicked();
    }

    public refreshPage(): void {
        window.location.reload();
    }

    public postponeNewVersionBanner(): void {
        this.newVersionStatus = 'new-version-postponed';
        setTimeout(() => {
            this.newVersionStatus = 'new-version-available';
        }, 30 * this.msPerMinute);
    }

    private preloadImages(): void {
        // Delay the preloading for ten seconds to speed up initial page load
        const preloadingDelay = 10 * 1000;
        setTimeout(() =>
            this.http.get<{ urls: string[] }>('assets/preloadedImages.json').toPromise()
                .then((res: { urls: string[]; }) => {
                    const imageUrls = res.urls;
                    if (!imageUrls) return;
                    this.preloadedImages = imageUrls.map((url: string) => {
                        const img = new Image();
                        img.src = url;
                        return img;
                    });
                }),
            preloadingDelay);
    }

    /**
     * Boots intercom with all the user and company data we want
     */
    private async bootIntercom(emp: Employee, user: AuthUser): Promise<void> {
        if (!this.intercomBooted) {
            // Direct the Test company to Intercom's test environment with id otyu9er4, otherwise direct to production
            const app_id: string = environment.production ? 'rfhqq4qn' : 'otyu9er4';

            // Get the company
            const company: Company = await this.companyService.getCompany(user.companyID).pipe(take(1)).toPromise();

            // Get the number of departments
            const departments: number = (await this.departmentService.getDepartments().pipe(take(1)).toPromise()).length;

            // Get the subscriptions
            const subscriptions: Subscriptions = await this.injector.get(ConfigurationService).getSubscriptions().pipe(take(1)).toPromise();

            // Get any subscriptions from Fenerum
            const fenerumSubscriptions: FenerumSubscription[] = [];
            if (!_.isEmpty(subscriptions)) {
                try {
                    const fenerumAccount: FenerumAccount = await this.cloudFunctionsService.getFenerumAccount({ companyID: company.id });
                    fenerumSubscriptions.push(...fenerumAccount.subscription_set);
                } catch (error) {
                    // No fenerum account found. Probably because there's a subscription but no Fenerum account. This cansafely be ignored.
                }
            }

            // Get the pro subscription info
            const { plan, scheduled_monthly_spend, monthly_spend, expiry_at }:
                { plan: string; scheduled_monthly_spend: number; monthly_spend: number; expiry_at: string | null } =
                this.getSubscriptionText(subscriptions.schedule, fenerumSubscriptions, departments);

            const referringCompany =
                company.referrer ? await this.companyService.getCompany(company.referrer).pipe(take(1)).toPromise() : undefined;

            // Init the company data to send to intercom
            const intercomCompany = {
                company_id: user.companyID,
                name: company ? company.name : 'Unknown company',
                created_at: company.createdOn,
                departments,
                VAT: company.VAT,
                street: company.street,
                zip: company.zip,
                city: company.city,
                country: company.country,
                plan,
                expiry_at,
                scheduled_monthly_spend,
                monthly_spend,
                invoice_mail: company.invoiceMail,
                referred_by: referringCompany ? `${ referringCompany.name } (${ referringCompany.id })` : undefined,
            };
            // Boot intercom with the desired data
            this.intercom.boot({
                action_color: '#838487',
                app_id,
                user_id: user.user_id,
                hide_default_launcher: !this.bigScreen,
                email: user.email,
                phone: emp.phone,
                name: emp.firstname + ' ' + emp.lastname,
                company: intercomCompany,
                avatar: {
                    type: 'avatar',
                    image_url: emp.imageURL,
                },
                roles: emp.roles[0]
                    ? emp.roles.reduce((roleString: string, currentRole: Role) =>
                        `${ roleString }, ${ currentRole.name }`, '').substring(2)
                    : '< Ingen roller >',
                primary_department: emp.primaryDepartment?.name || '< Ingen primær afdeling >',
                departments: emp.departments[0]
                    ? emp.departments.reduce((departmentString: string, currentDepartment: Department) =>
                        `${ departmentString }, ${ currentDepartment.name }`, '').substring(2)
                    : '< Ingen afdelinger >',
                language_override: await this.userSettingsService.loadSetting(UserSettings.LANGUAGE).pipe(take(1)).toPromise(),
                admin: user.admin,
                app_version: version,
            });
            this.intercomBooted = true;
        }
    }

    /**
     * Get the status, scheduled monthly spend and actual monthly spend for a given subscription
     * @param subscription The Relion subscription to use
     * @param fenerumSubscriptions A list of Fenerum subscriptions for the company's account
     * @param departments The number of departments
     */
    private getSubscriptionText(
        subscription: SubscriptionDetails | undefined,
        fenerumSubscriptions: FenerumSubscription[],
        departments: number): {
            plan: string;
            expiry_at: string | null;
            scheduled_monthly_spend: number;
            monthly_spend: number;
        } {

        // Get active until for this package
        const activeUntil: Date | null | undefined = subscription?.activeUntil;
        // Get the trial expiry date for this package
        const trialExpires: Date | null | undefined = subscription?.freeTrialStartedOn
            && moment(subscription.freeTrialStartedOn).add(TRIAL_DURATION_DAYS, 'days').toDate();
        // Company's on trial if the trial expires later than right now
        const trial = trialExpires && trialExpires > new Date();
        // Company's trial's expired if trialExpires and active until are the same date
        const trialExpired: boolean = !!trialExpires && !!activeUntil && isSameDate(trialExpires, activeUntil);
        // Company is pro (or on trial) if active until is set and is after right now
        const pro: boolean = !!activeUntil && activeUntil > new Date();

        // Method for correctly rendering dates as strings in intercom
        const toDateString = (date: Date | null | undefined) => date?.toISOString() ?? null;

        // Set the plan string based on the subscription
        let plan: string, expiry_at: string | null;
        if (!subscription) [plan, expiry_at] = ['Free', null];
        else if (trial) [plan, expiry_at] = ['Trial', toDateString(trialExpires)];
        else if (pro) [plan, expiry_at] = ['Pro', toDateString(activeUntil)];
        else if (trialExpired) [plan, expiry_at] = ['Trial expired', toDateString(trialExpires)];
        else[plan, expiry_at] = ['Free', toDateString(activeUntil)];

        // Init the monthly price as 0 and set it correctly, if the company is on pro
        let price: number = 0;
        if (pro) {
            // Look for the corresponding Fenerum subscription
            const fenerumSubscription: FenerumSubscription | undefined =
                fenerumSubscriptions.find((sub: FenerumSubscription) => sub.uuid === subscription?.fenerumSubscriptionUUID);

            // Get the terms of the subscription (if present)
            const subscriptionTerms: FenerumSubscriptionPlanTerms | undefined = fenerumSubscription?.terms;

            // Get the subscription price or the overridden price (fallback to 0 if subscription not found)
            const totalPrice: number = fenerumSubscription?.override_price ?? Number(subscriptionTerms?.price || 0);

            // Update the price based on the intercal type of the plan
            price = subscriptionTerms
                ? subscriptionTerms.interval_type === BillingPeriod.MONTH
                    ? Number(totalPrice)
                    : Number(totalPrice) / 12
                : 0;
        }

        // Set the scheduled and actual spend
        const scheduled_monthly_spend = departments * price;
        const monthly_spend = (!trial && pro) ? scheduled_monthly_spend : 0;

        return { plan, expiry_at, scheduled_monthly_spend, monthly_spend };
    }

    private setupWelcomeAndRoleCreationModal(): Subscription {
        return combineLatest(
            this.userActivityService.load(UserActivity.CREATED_FIRST_DEPARTMENT),
            this.injector.get(OnboardingService).companyHasRoles(),
        ).pipe(take(1)).subscribe({
            next: ([activityTime, hasRoles]: [Date | null, boolean]): void => {
                if (!hasRoles) {
                    this.openRoleOnboardingModal();
                    if (!activityTime) this.modalService.openWelcomeOverlay();
                }
            },
        });
    }

    private setupNewEmployeeOnboardingModal(): Subscription {
        return this.userActivityService.load(UserActivity.SEEN_FIRST_EMPLOYEE).pipe(
            filter((seenFirstEmployee: Date | null) => !seenFirstEmployee),
            take(1),
            switchMap(() => this.injector.get(OnboardingService).companyHasUnconfiguredEmployee()),
            filter((unconfiguredEmployees: boolean) => unconfiguredEmployees),
            take(1),
        ).subscribe({
            next: (): void => this.openEmployeeSignedUpModalComponent(),
        });
    }

    private setupOnboardingCompletedModal(): Subscription {
        return combineLatest(
            this.userActivityService.load(UserActivity.COMPLETED_CALENDAR_STEPPER),
            this.allStepperConditionsSatisified(),
        ).pipe(
            takeWhile(([activityTime, _hasCompletedAllStepperSteps]: [Date | null, boolean]) => !activityTime, true),
            map(([activityTime, hasCompletedAllStepperSteps]: [Date | null, boolean]) => !activityTime && hasCompletedAllStepperSteps),
            filter((show: boolean) => show),
            take(1),
            tap(() => this.modal.afterAllClosed.pipe(take(1)).toPromise().then(() => {
                if (!this.modal.openDialogs.find((modal: MatDialogRef<unknown, unknown>) =>
                    modal.componentInstance instanceof CalendarStepperCompletedModalComponent)) {
                    this.modal.open(CalendarStepperCompletedModalComponent, { disableClose: true });
                }
            })),
        ).subscribe();
    }

    private allStepperConditionsSatisified(): Observable<boolean> {
        return combineLatest(
            this.injector.get(OnboardingService).companyHasShift(),
            this.injector.get(OnboardingService).companyHasTemplate(),
            this.injector.get(OnboardingService).companyHasConfiguredEmployee())
            .pipe(
                map(
                    ([hasShift, hasTemplate, hasConfiguredEmployee]: [boolean, boolean, boolean]) =>
                        hasShift && hasTemplate && hasConfiguredEmployee));
    }

}
