import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument, DocumentReference, Query, QuerySnapshot } from '@angular/fire/firestore';
import { WriteBatch } from '@firebase/firestore-types';
import { BehaviorSubject, Observable, Observer, Subject } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';
import { Company } from '../model';
import { companiesCollectionPath as rootCollectionPath } from '../model/Firestore/FirestoreConstants';
import { AuthService, AuthUser } from './auth.service';

@Injectable()
export class FirestoreService {
    private afsRoot: AngularFirestore;

    private companyDocument: Subject<AngularFirestoreDocument<Company> | undefined>;

    private companyDocumentLoaded: Promise<AngularFirestoreDocument<Company>>;

    constructor(public angularFireStore: AngularFirestore,
        private authService: AuthService) {
        this.companyDocument = new BehaviorSubject(undefined);

        this.companyDocumentLoaded = new Promise<AngularFirestoreDocument<Company>>(resolve => {
            this.authService.getUser().subscribe((user: AuthUser) => {
                // ignore when not logged in, or that the user has no CompanyID.
                if (!user || !user.companyID) {
                    return;
                }

                // Load the document, and sent it to observers
                this.companyDocument.next(
                    angularFireStore.collection('Virksomheder').doc(user.companyID),
                );
                resolve(angularFireStore.collection('Virksomheder').doc(user.companyID));
            },
            );
        });

        this.afsRoot = angularFireStore;
    }

    /**
     * This returns the root of the database, can be used to access database when not logged in.
     * (as companyDocument is only loaded when logged in)
     * @returns {AngularFirestore}
     */
    public getAfsRoot(): AngularFirestore {
        return this.afsRoot;
    }

    /**
     * Returns a new firestore WriteBatch
     */
    public createBatch(): WriteBatch {
        return this.angularFireStore.firestore.batch();
    }

    /**
     * Creates a new ID for use in the firestore db
     */
    public createId(): string {
        return this.angularFireStore.createId();
    }

    /**
     * Returns an observable with the current user.
     * The user is undefined when not logged in, which should be handled.
     * @returns {Observable<AuthUser>} The observable
     */
    public getUser(): Observable<AuthUser | null | undefined> {
        return this.authService.getUser();
    }

    /**
     * This returns an observer on the document (database) of the company of the currently logged in user.
     * The document can be undefined! (e.x. if not logged in)
     * @returns {Observable<AngularFirestoreDocument<any>>}
     */
    public getCompanyDocument(): Observable<AngularFirestoreDocument<Company> | undefined> {
        return this.companyDocument.asObservable();
    }

    public whenReady(): Promise<AngularFirestoreDocument<Company>> {
        return this.companyDocumentLoaded;
    }

    /**
     * Updates a batch of data in the firestore database
     * @param values The values to update
     * @param collection The collection where the values are to be updated
     * @param mapper The function for mapping an application type to a database type objsect
     */
    public commitUpdateBatch<T extends { id?: string }, E>(
        values: T[], collection: AngularFirestoreCollection, mapper: (data: T) => E): Promise<void> {
        if (values.length === 0) return Promise.resolve();
        const batch: WriteBatch = this.afsRoot.firestore.batch();

        let nextBatch: T[] = [];

        if (values.length > 500) {
            nextBatch = values.splice(500);
        }

        values.forEach((value: T) => {
            const docRef: DocumentReference = collection.doc(value.id).ref;
            batch.set(docRef, mapper(value), { merge: true });
        });

        return batch
            .commit()
            .then(() => this.commitUpdateBatch(nextBatch, collection, mapper));
    }

    /**
     * Returns the AuthUser of a user that logs in and the collection of the data. Returns null if the user is not logged in.
     */
    public authenticate<T>(collectionPath: string): Observable<Auth<T>> {
        const authUser$ = this.authService.getUser();
        return authUser$
            .pipe(
                takeUntil(authUser$.pipe(filter((user: AuthUser | null) => !user))),
                filter((user: AuthUser) => user !== undefined),
                map((user: AuthUser) => {
                    if (user) {
                        return user;
                    } else {
                        throw new Error('not authenticated');
                    }
                }),
                map((user: AuthUser) => {
                    let collection: AngularFirestoreCollection<T> =
                        this.angularFireStore.collection(rootCollectionPath);

                    if (collectionPath !== rootCollectionPath) collection = collection.doc(user.companyID).collection(collectionPath);

                    return { user, collection };
                }));
    }

    public observeOnSnapshot<T>(collection: AngularFirestoreCollection<T>, query?: Query)
        : Observable<QuerySnapshot<T>> {
        return new Observable((observer: Observer<QuerySnapshot<T>>): Function =>
            (query ? query : collection.ref).onSnapshot({ includeMetadataChanges: true }, observer))
            .pipe(filter((snap: QuerySnapshot<T>) => !snap.metadata.fromCache));
    }
}

export interface Auth<T> {
    user: AuthUser;
    collection: AngularFirestoreCollection<T>;
}
