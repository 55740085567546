import { Pipe, PipeTransform } from '@angular/core';
import { Employee, Shift } from '../../core/model';
import { Department } from '../../core/model/Department/Department.model';

@Pipe({
    name: 'employeeInDepartment',
})
export class EmployeeRelationPipe implements PipeTransform {

    /**
     * Determine whether an employee has a relation to a department
     * @param employee The employee to check
     * @param department The department to check
     * @param shifts The shifts of the employee
     *
     * @returns (1) true, if the employee is in the department
     *          (2) true, if the employee has shifts in the department
     *          (3) true, if if the department is undefined
     *          (4) false, otherwise
     */
    public transform(employee: Employee, department?: Department | null, shifts?: Shift[]): boolean {
        // If no department is supplied, show all employees
        if (!department) return true;

        // If the employee has the department, show the employee
        if (employee.departments.some((employeeDepartment: Department) => employeeDepartment.id === department.id)) return true;

        // If the employee has shifts in the department, show the employee
        if (shifts && shifts.some((shift: Shift) => shift.role.department.id === department.id)) return true;

        // Otherwise, hide the employee
        return false;
    }

}
