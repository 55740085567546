import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'sort',
})
export class SortArrayPipe implements PipeTransform {
    public transform<T>(array: T[], sortBy: (a: T, b: T) => number): T[] {
        return array.sort(sortBy);
    }
}
