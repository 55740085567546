import { Injectable } from '@angular/core';
import { ActiveState, EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { Employee } from '../../../model/Employee.model';

export interface EmployeeState extends EntityState<Employee, string>, ActiveState<string> { }

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'employee' })
export class EmployeeStore extends EntityStore<EmployeeState> {

    constructor() {
        super();
    }

}
