import { Punch, PunchSource } from '../Punch/Punch.model';
import Timestamp from './FirestoreTimestamp';


export interface FirestorePunch {
    source: PunchSource;
    time: Timestamp;
    message?: string;
    overwriting?: FirestorePunch;
}

export function mapFirestorePunchToPunch(fsp: FirestorePunch): Punch {
    const punch: Punch = {
        source: fsp.source,
        time: fsp.time.toDate(),
    };
    if (fsp.message) punch.message = fsp.message;
    if (fsp.overwriting) punch.overwriting = mapFirestorePunchToPunch(fsp.overwriting);
    return punch;
}

export function mapPunchToFirestorePunch(punch: Punch): FirestorePunch {
    const firestorePunch: FirestorePunch = {
        source: punch.source,
        time: Timestamp.fromDate(punch.time),
    };
    if (punch.message) firestorePunch.message = punch.message;
    if (punch.overwriting) firestorePunch.overwriting = mapPunchToFirestorePunch(punch.overwriting);
    return firestorePunch;
}
