import { ChangeDetectorRef, Component, NgZone, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import * as moment from 'moment';
import { from, Observable } from 'rxjs';
import { map, switchMap, take, tap } from 'rxjs/operators';
import { Employee, Shift } from '../../../../../../../core/model';
import { AuthService, AuthUser, EmployeeService, MediaService, ShiftService } from '../../../../../../../core/services';
import { ModalService } from '../../../../../../../core/services/ModalService.model';
import { getShiftDuration } from '../../../../../../../shared/utilities/ShiftUtils/durationUtils';
import { NavbarSettings } from '../../../../../../admin/shared/components/settings-modal/settings-modal.component';
import { Range, StatsDialogComponent } from './stats-dialog/stats-dialog.component';

@Component({
    selector: 'app-stats-widget',
    templateUrl: './stats-widget.component.html',
    styleUrls: ['./stats-widget.component.scss'],
})
export class StatsWidgetComponent implements OnInit {
    public employee$: Observable<Employee>;
    // The shifts used in stats
    public shifts$: Observable<Shift[]>;
    public includeFutureShifts: boolean = false;
    public includeRange: Range;
    public prevShiftsLength: number;
    public prevShiftsDuration: number;

    // All the shifts for the employee in the selected range
    private allShifts$: Observable<Shift[]>;
    // Alle the shifts for the employee in the selected range
    private pastShifts$: Observable<Shift[]>;

    constructor(
        private authService: AuthService,
        public dialog: MatDialog,
        public mediaService: MediaService,
        private employeeService: EmployeeService,
        private shiftService: ShiftService,
        private cdr: ChangeDetectorRef,
        private zone: NgZone,
        private modalService: ModalService,
    ) {
        this.includeRange = Range.THIS_MONTH;
    }

    public ngOnInit(): void {
        // Retrieve AuthUser and then retrieve the current employee
        this.employee$ = from(this.employeeService.getAuthenticatedEmployee());
        this.setShifts();
    }

    /**
     * Calculates the total number of hours worked.
     * @param shifts
     */
    public getShiftsDuration(shifts: Shift[]): number {
        return shifts.reduce((acc: number, curr: Shift) => acc += getShiftDuration(curr), 0);
    }

    /**
     * Opens a dialog and passes the includeFutureShifts boolean as data.
     */
    public openDialog(): void {
        this.zone.run(() => {
            const dialogRef: MatDialogRef<StatsDialogComponent> = this.dialog.open(StatsDialogComponent, {
                data: { includeFutureShifts: this.includeFutureShifts, includeRange: this.includeRange },
            });

            // If includeFutureShifts have been changed in the dialog, then retrieve stats for the new time range.
            dialogRef.afterClosed().subscribe((result: { includeFutureShifts: boolean, includeRange: Range }) => {
                if (!result) return;
                if (result.includeRange) this.includeRange = result.includeRange;
                if (typeof result.includeFutureShifts !== 'undefined') this.includeFutureShifts = result.includeFutureShifts;
                this.setShifts();
            });
        });
    }

    public async openSettings(): Promise<void> {
        if (!this.mediaService.isBigScreen()) return;

        const isAdmin = await this.authService.getUser().pipe(take(1), map((user: AuthUser | null) => !!user?.admin)).toPromise();
        if (!isAdmin) return;

        this.modalService.openSettingsModal(NavbarSettings.PROFILE);
    }

    /**
     * Set allShifts depennding on the range
     * @param range The range
     */
    private setAllShifts(range: Range): void {
        const { startDateFrom, startDateTo }: { startDateFrom: Date | undefined, startDateTo: Date | undefined }
            = this.getDatesFromRange(range);

        // Get all shifts for this range for the employee.
        this.allShifts$ = this.employee$
            .pipe(switchMap((employee: Employee) => this.shiftService.getShifts({ employee, startDateFrom, startDateTo, released: true })));
    }

    /**
     * Get dates depending on the range
     * @param range The range
     */
    private getDatesFromRange(range: Range): { startDateFrom: Date | undefined, startDateTo: Date | undefined } {
        let startDateFrom: Date | undefined;
        let startDateTo: Date | undefined;
        switch (range) {
            case Range.TOTAL:
                break;
            case Range.THIS_WEEK:
                startDateFrom = moment().startOf('isoWeek').toDate();
                startDateFrom.setHours(0, 0, 0, 0);
                startDateTo = moment().endOf('isoWeek').toDate();
                startDateTo.setDate(startDateTo.getDate() + 1);
                startDateTo.setHours(0, 0, 0, 0);
                break;
            case Range.THIS_MONTH:
                startDateFrom = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
                startDateTo = new Date(new Date().getFullYear(), new Date().getMonth() + 1, 1, 0, 0, 0);
                break;
            default:
                throw new Error('Unknown range used to set shifts in StatsWidget. ');
        }
        return { startDateFrom, startDateTo };
    }

    /**
     * Set all the shifts that are in the past depending on all shifts
     */
    private setPastShifts(): void {
        // Filter out alle the shifts that has not ended yet.
        this.pastShifts$ = this.allShifts$
            .pipe(map((shifts: Shift[]) => shifts.filter((shift: Shift) => shift.end.getTime() < new Date().getTime())));
    }

    /**
     * Set the shifts that are used for the statistics, based on the selected range.
     */
    private setShifts(): void {
        this.setAllShifts(this.includeRange);
        this.setPastShifts();
        this.shifts$ = this.includeFutureShifts ? this.allShifts$ : this.pastShifts$;
        this.shifts$ = this.shifts$.pipe(tap((shifts: Shift[]) => setTimeout(() => {
            this.prevShiftsLength = shifts.length;
            this.prevShiftsDuration = this.getShiftsDuration(shifts);
            MediaService.detectChanges(this.cdr);
        }, 0)));
    }
}
