import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Package } from '../../../../core/model/Freemium/Package';
import { ConfigurationService } from '../../../../core/services/Configuration/configuration-service.model';
import { ModalService } from '../../../../core/services/ModalService.model';

@Component({
    selector: 'app-upgrade-button',
    templateUrl: './upgrade-button.component.html',
    styleUrls: ['./upgrade-button.component.scss'],
})
export class UpgradeButtonComponent implements OnInit {

    public freeTrialAvailable$: Observable<boolean>;

    constructor(
        public modalService: ModalService,
        private configurationService: ConfigurationService,
    ) { }

    public ngOnInit(): void {
        this.freeTrialAvailable$ = this.configurationService.freeTrialAvailable(Package.PRO);
    }
}
