<div class="container h-100 d-flex flex-column justify-content-center align-items-center">
    <h1>
        {{ 'widget.waiting-approval' | translate | capitalize }}
    </h1>
    <p>
        {{ 'pending.awaiting-departments-message' | translate }}
    </p>
    <img src="/assets/images/pending.svg">
    <ui-button-standard (click)="logout()"
                        color="warn">
        {{"settings.log-out" | translate | capitalize}}
    </ui-button-standard>
</div>