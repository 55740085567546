import { Injectable, Injector } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { ApproveShiftsComponent, ApproveShiftsModalData } from '../../modules/admin/approve-shifts/responsive/pages/approve-shifts/approve-shifts.component';
import { DayViewComponent } from '../../modules/admin/day-view/web/pages/day-view/day-view.component';
import { WelcomeOverlayComponent } from '../../modules/admin/onboarding/web/components/welcome-overlay/welcome-overlay.component';
import { AutoFillModalComponent, AutoFillModalData } from '../../modules/admin/shared/components/auto-fill-modal/auto-fill-modal.component';
import { EditDepartmentsModalComponent } from '../../modules/admin/shared/components/edit-departments-modal/edit-departments-modal.component';
import { HourlyWageModalComponent } from '../../modules/admin/shared/components/hourly-wage-modal/hourly-wage-modal.component';
import { InviteModalComponent } from '../../modules/admin/shared/components/invite-modal/invite-modal.component';
import { ReferralLinkModalComponent } from '../../modules/admin/shared/components/referral-link-modal/referral-link-modal.component';
import { SalaryExportData, SalaryExportModalComponent } from '../../modules/admin/shared/components/salary-export-modal/salary-export-modal.component';
import { SalarySupplementsModalComponent } from '../../modules/admin/shared/components/salary-supplements-modal/salary-supplements-modal.component';
import { IntegrationModalComponent } from '../../modules/admin/shared/components/settings-modal/components/salary/integrations/integration-modal/integration-modal.component';
import { NavbarSettings, SettingsModalComponent, SettingsModalData } from '../../modules/admin/shared/components/settings-modal/settings-modal.component';
import { EditEmployeeModalComponent } from '../../shared/components/edit-employee-modal/edit-employee-modal.component';
import { EmployeeContactModalComponent } from '../../shared/components/employee-contact-modal/employee-contact-modal.component';
import { CardDetailsModalComponent } from '../../shared/components/freemium/card-details-modal/card-details-modal.component';
import { ConfirmGoBackToFreeComponent } from '../../shared/components/freemium/confirm-go-back-to-free/confirm-go-back-to-free.component';
import { ProShopOverlayComponent } from '../../shared/components/freemium/pro-shop-overlay/pro-shop-overlay.component';
import { ProUpgradeOverlayComponent } from '../../shared/components/freemium/pro-upgrade-overlay/pro-upgrade-overlay.component';
import { ReadyForProModalComponent } from '../../shared/components/freemium/ready-for-pro-modal/ready-for-pro-modal.component';
import { StartTrialComponent } from '../../shared/components/freemium/start-trial/start-trial.component';
import { ProfilePictureModalComponent, ProfilePictureModalData } from '../../shared/components/profile-picture-uploader/profile-picture-modal/profile-picture-modal.component';
import { ShiftDetailModalComponent } from '../../shared/components/shift-detail-modal/shift-detail-modal.component';
import { ConfirmDialogComponent, ConfirmDialogData } from '../../shared/ui/confirm-dialog/confirm-dialog.component';
import { InputDialogComponent, InputDialogData } from '../../shared/ui/input-dialog/input-dialog.component';
import { Employee, NewShift, Shift } from '../model';
import { Department } from '../model/Department/Department.model';
import { Package } from '../model/Freemium/Package';
import { SalaryIntegration } from '../model/Integrations/Shared';
import { RelionModule } from '../model/RelionModule';
import { SubscriptionStatus } from '../model/SubscriptionStatus';
import { SalaryRange } from '../model/UserSettings';
import { ConfigurationService } from './Configuration/configuration-service.model';
import { ModalService } from './ModalService.model';


@Injectable()
export class MatDialogModalService extends ModalService {

    constructor(
        private injector: Injector,
        private modal: MatDialog,
        private bottomDrawer: MatBottomSheet,
    ) {
        super();
    }

    /**
     * Opens the modal for editing departments.
     */
    public openEditDepartmentModal(department?: Department | undefined): void {
        this.modal.open<EditDepartmentsModalComponent, Department>(EditDepartmentsModalComponent, {
            data: department,
        });
    }

    /**
     * Trigger for when an image is chosen by the user. This opens the cropper modal
     * @param event The input event with a base64 encoded string of the file
     * @param input The input field
     * @param employee The employee, the base64 encoded string of the file will be uploaded to
     */
    public openProfilePictureModal(event: { base64: string; }, input: HTMLInputElement, employee?: Employee): void {
        this.modal.open<ProfilePictureModalComponent, ProfilePictureModalData>(ProfilePictureModalComponent, {
            data: { imageChangedEvent: event, employee },
            width: '450px',
            maxWidth: '95vw',
        })
            // Reset the file picker upon modal close
            .afterClosed().pipe(take(1)).subscribe(() => input.value = '');
    }

    public openSettingsModal(setting?: NavbarSettings): void {
        this.modal.open<SettingsModalComponent, SettingsModalData>(SettingsModalComponent, {
            width: '864px',
            height: '514px',
            data: setting !== undefined ? {
                selectedSetting: setting,
            } : null,
            autoFocus: false,
        });
    }

    public openReadyForProModal(module: RelionModule): void {
        switch (module) {
            case RelionModule.SALARY:
                this.modal.open<ReadyForProModalComponent>(ReadyForProModalComponent, { disableClose: true });
                break;
            default: throw Error(`No ready for pro modal exists for the ${ module } module`);
        }
    }

    public openWelcomeOverlay(): void {
        this.modal.open<WelcomeOverlayComponent>(WelcomeOverlayComponent, {
            disableClose: true,
            minWidth: '100vw',
            minHeight: '100vh',
            panelClass: 'welcome-overlay-modal',
            hasBackdrop: false,
        });
    }

    public openShiftDetailModal(shift: Shift | NewShift, openInEditMode?: boolean): void {
        this.bottomDrawer.open(ShiftDetailModalComponent, {
            autoFocus: false,
            panelClass: 'shift-detail-modal-container',
            data: { shift, openInEditMode },
        });
    }

    public openCardDetailsModal(url: string): MatDialogRef<CardDetailsModalComponent> {
        return this.modal.open(CardDetailsModalComponent, {
            autoFocus: false,
            disableClose: true,
            data: url,
        });
    }

    public openDayView(day: Date): void {
        this.modal.open(DayViewComponent, {
            data: day,
            minWidth: '1024px',
            maxWidth: '1536px',
            width: '85vw',
            height: '85vh',
            autoFocus: false,
        });
    }

    public openAutoFill(modalData: AutoFillModalData): void {
        this.modal.open<AutoFillModalComponent, AutoFillModalData>(AutoFillModalComponent, { data: modalData });
    }

    public openConfirmDialog(modalData: ConfirmDialogData, disableClose: boolean = false): Observable<boolean> {
        return this.modal.open<ConfirmDialogComponent, ConfirmDialogData, boolean>(ConfirmDialogComponent, {
            data: modalData,
            disableClose,
        })
            .afterClosed().pipe(
                take(1),
                map((didConfirm: boolean | undefined) => !!didConfirm),
            );
    }

    public openInputDialog(modalData: InputDialogData, disableClose: boolean = false): Observable<string | undefined> {
        return this.modal.open<InputDialogComponent, InputDialogData, string>(InputDialogComponent, {
            data: modalData,
            disableClose,
        }).afterClosed().pipe(take(1));
    }

    public openReferralLinkModal(): void {
        this.modal.open<ReferralLinkModalComponent>(ReferralLinkModalComponent, { autoFocus: false });
    }

    public openInviteModal(): void {
        this.modal.open(InviteModalComponent, { maxWidth: '580px' });
    }

    public openEmployeeHourlyWageModal(): void {
        this.modal.open(HourlyWageModalComponent, { maxWidth: '677px', maxHeight: '609px', autoFocus: false });
    }

    public openApproveShiftsModal(start: Date, end: Date, department?: Department, mobile?: boolean): void {
        // Init common config
        let config: MatDialogConfig<ApproveShiftsModalData> = { autoFocus: false, data: { start, end, department, mobile } };

        // Set mobile / web specific config
        if (mobile) config = { ...config, width: '100vw', height: '100vh', panelClass: 'mobile-approve-shifts-modal' };
        else config = { ...config, minWidth: '1100px', minHeight: '400px', maxHeight: '90vh' };

        this.modal.open<ApproveShiftsComponent, ApproveShiftsModalData>(ApproveShiftsComponent, config);
    }

    public openExportSalaryModal(range: SalaryRange, selectedDepartment: Department | undefined, shifts: Shift[]): void {
        this.modal.open<SalaryExportModalComponent, SalaryExportData>(SalaryExportModalComponent, {
            minWidth: '660px',
            data: { range, selectedDepartment, shifts },
        });
    }

    public openContactEmployeeModal(employee: Employee): void {
        this.modal.open(EmployeeContactModalComponent, {
            height: '190px',
            width: '272px',
            autoFocus: false,
            data: employee,
        });
    }

    public openSalarySupplementSettingsModal(): MatDialogRef<SalarySupplementsModalComponent> {
        return this.modal.open(SalarySupplementsModalComponent);
    }

    public openIntegrationModal(integrationSetting: SalaryIntegration): void {
        this.modal.open(IntegrationModalComponent, { data: { integrationSetting }, autoFocus: false, width: '448px', height: '248px' });
    }

    public openTeamModal(employee?: Employee | undefined): void {
        this.modal.open<EditEmployeeModalComponent, Employee>(EditEmployeeModalComponent, {
            width: '736px',
            height: '488px',
            data: employee,
        });
    }

    public async openProShopOverlay(): Promise<void> {
        this.closeAll();
        const subscriptionStatus = await this.injector.get(ConfigurationService)
            .getSubscriptionStatus(Package.PRO).pipe(take(1)).toPromise();

        this.modal.open<ProShopOverlayComponent, SubscriptionStatus>(ProShopOverlayComponent, {
            disableClose: true,
            minWidth: '100vw',
            minHeight: '100vh',
            panelClass: 'upgrade-overlay-modal',
            hasBackdrop: false,
            data: subscriptionStatus,
        });
    }

    public openConfirmGoBackToFreeOverlay(): void {
        this.modal.open(ConfirmGoBackToFreeComponent, {
            disableClose: true,
            minWidth: '100vw',
            minHeight: '100vh',
            hasBackdrop: false,
            panelClass: 'disable-animations',
        });
    }

    public openProUpgradeOverlay(disableAnimation: boolean = false): void {
        const panelClass = disableAnimation ? 'disable-animations' : '';
        this.modal.open<ProUpgradeOverlayComponent, void>(ProUpgradeOverlayComponent, {
            disableClose: true,
            minWidth: '100vw',
            minHeight: '100vh',
            panelClass,
            hasBackdrop: false,
        });
    }
    public openStartTrial(): void {
        this.modal.open(StartTrialComponent, {
            disableClose: true,
            minWidth: '100vw',
            minHeight: '100vh',
            hasBackdrop: false,
            panelClass: 'disable-animations',
        });
    }

    /**
     * Close all open modals
     */
    public closeAll(): void {
        this.modal.closeAll();
        this.bottomDrawer.dismiss();
    }

    public openEditShift(shift: Shift | NewShift): void {
        this.openShiftDetailModal(shift, true);
    }
}
