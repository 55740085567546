import { Observable, ReplaySubject, Subject } from 'rxjs';
import { take } from 'rxjs/operators';
import { Employee } from '../../model';
import { Department } from '../../model/Department/Department.model';
import { UserSettings } from '../../model/UserSettings';
import { UserSettingsService } from '../Settings/UserSettings/UserSettingsService.model';

export abstract class DepartmentService {

    private activeDepartmentSubject: Subject<Department>;
    private activeDepartment$: Observable<Department>;

    constructor(private userSettingsService: UserSettingsService) {
        this.activeDepartmentSubject = new ReplaySubject(1);
        this.activeDepartment$ = this.activeDepartmentSubject.asObservable();
    }

    /**
     * Returns an observable of the active department
     */
    public getActiveDepartment(): Observable<Department> {
        return this.activeDepartment$;
    }

    /**
     * Sets the active department
     * @param department The department to set as the active department
     */
    public setActiveDepartment(department: Department): void {
        this.activeDepartmentSubject.next(department);
        this.userSettingsService.saveSetting(UserSettings.LATEST_SELECTED_DEPARTMENT, department.id).pipe(take(1)).toPromise();
    }

    /**
     * Creates a department in storage
     * @param department department that you want to create.
     */
    public abstract createDepartment(department: Omit<Department, 'id' | 'isDeleted'>): Observable<Department>;

    /**
     * Gets a department from the storage with the specific id.
     * @param id
     */
    public abstract getDepartment(id: string): Observable<Department>;

    /**
     * Gets all the departments in storage.
     */
    public abstract getDepartments(params?: DepartmentQueryParams): Observable<Department[]>;

    /**
     * Updates a department.
     * @param department The department you want to update.
     */
    public abstract updateDepartment(department: Department): Observable<Department>;

    /**
     * Deletes a department.
     * @param department The department you want to delete.
     */
    public abstract deleteDepartment(department: Department): Observable<Department>;

}

export interface DepartmentQueryParams {
    isDeleted?: boolean;
    employee?: Employee;
}
