import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { Package } from '../../../core/model/Freemium/Package';
import { SalaryIntegration } from '../../../core/model/Integrations/Shared';
import { ConfigurationService } from '../../../core/services/Configuration/configuration-service.model';
import { ModalService } from '../../../core/services/ModalService.model';
import { BackgroundColor } from '../colors/BackgroundColor';
import { BorderColor } from '../colors/BorderColor';

export interface IntegrationStatusInfo {
    labelText: string;
    labelColor: BackgroundColor;
    borderColor: BorderColor;
    integration: SalaryIntegration;
}

@Component({
    selector: 'app-integration-panel',
    templateUrl: './integration-panel.component.html',
    styleUrls: ['./integration-panel.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IntegrationPanelComponent implements OnInit {
    @Input() public integrationStatusInfo$: Observable<IntegrationStatusInfo | undefined>;
    @Input() public contextMenuText: string;
    @Input() public proPackage: Package;
    @Input() public noBottomRadius: boolean;

    @Output() public integrateClicked: EventEmitter<void>;
    @Output() public removeIntegrationClicked: EventEmitter<void>;

    public isPro$: Observable<boolean>;
    public freeTrialAvailable$: Observable<boolean>;

    constructor(
        public modalService: ModalService,
        private configurationService: ConfigurationService,
    ) {
        this.integrateClicked = new EventEmitter();
        this.removeIntegrationClicked = new EventEmitter();
    }

    public ngOnInit(): void {
        this.isPro$ = this.configurationService.isPro(this.proPackage);
        this.freeTrialAvailable$ = this.configurationService.freeTrialAvailable(this.proPackage);
    }
}
