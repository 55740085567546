<div class="main"
     *ngIf="departmentRoles$ | async as departmentRoles">
    <div class="header">
        {{ 'admin.department.create-roles' | translate }}
    </div>
    <div class="body">
        <div class="no-roles"
             ngbDropdown
             *ngIf="departmentRoles.length === 0">
            <div class="body-title">{{ 'admin.department.create-roles' | translate }}</div>
            <div class="body-sub-title">{{ 'admin.department.create-roles-for-department' | translate }}</div>

            <ui-button-standard class="openDropdown"
                                ngbDropdownToggle>
                {{ 'admin.department.copy-roles' | translate }}
            </ui-button-standard>


            <div ngbDropdownMenu
                 container="no-roles"
                 class="department-dropdown">
                <overlay-scrollbars class="department-dropdown-container os-theme-light">
                    <div ngbDropdownItem
                         (click)="copyRoles(department)"
                         class="item"
                         *ngFor="let department of departments$ | async">
                        {{department.name}}
                    </div>
                </overlay-scrollbars>
            </div>
        </div>

        <overlay-scrollbars class="roles"
                            *ngIf="departmentRoles.length !== 0">
            <div class="role"
                 *ngFor="let role of departmentRoles">
                <div class="left">
                    <div class="role-name">{{role.name}}</div>
                </div>

                <div [ngbPopover]="editRole"
                     #popoverEdit=ngbPopover
                     container='body'
                     [autoClose]="'outside'"
                     popoverClass='edit-role-popover'>
                </div>

                <div class="right">
                    <div class="edit-role"
                         (click)="popoverEdit.toggle()">
                        {{ 'shared.edit' | translate }}
                    </div>
                    <ng-template #editRole>
                        <app-edit-role-popover (closeClicked)="popoverEdit.close()"
                                               [partialRole]="role" [department]="department">
                        </app-edit-role-popover>
                    </ng-template>
                </div>
            </div>
        </overlay-scrollbars>
    </div>

    <div class="popover-container"
         [ngbPopover]="addRole"
         #popoverAdd=ngbPopover
         [autoClose]="'outside'"
         popoverClass='edit-role-popover'>
    </div>

    <ui-button-action class="new-role-button"
                      *ngIf="departmentRoles.length === 0"
                      (click)="popoverAdd.open()">
        {{ 'admin.department.new-role' | translate }}
    </ui-button-action>

    <ui-button-standard class="new-role-button"
                        *ngIf="departmentRoles.length !== 0"
                        color="primary"
                        (click)="popoverAdd.open()">
        {{ 'admin.department.new-role' | translate }}
    </ui-button-standard>

    <ng-template #addRole>
        <app-edit-role-popover (closeClicked)="popoverAdd.close()"
                               [department]="department">
        </app-edit-role-popover>
    </ng-template>

</div>
