import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Employee } from '../../../../../core/model';
import { AuthService, EmployeeService } from '../../../../../core/services';
import { GoogleAnalyticsService } from '../../../../../core/services/google-analytics.service';

@Component({
    selector: 'app-pending-approval',
    templateUrl: './pending-approval.component.html',
    styleUrls: ['./pending-approval.component.scss'],
})
export class PendingApprovalComponent implements OnInit, OnDestroy {

    private authenticatedEmployeeSub: Subscription;

    constructor(
        private router: Router,
        private employeeService: EmployeeService,
        private authService: AuthService,
        private gas: GoogleAnalyticsService,
    ) { }

    public async ngOnInit(): Promise<void> {
        this.authenticatedEmployeeSub = this.employeeService.getAuthenticatedEmployee().subscribe({
            next: (emp: Employee) => {
                if (emp.departments.length > 0) this.router.navigate(['/']);
            },
        });
    }

    public ngOnDestroy(): void {
        this.authenticatedEmployeeSub.unsubscribe();
    }

    /**
     * Logs the user out of the application, using the Auth Service.
     */
    public logout(): void {
        this.authService.logout();
        this.gas.trackButtonClick('logout');
    }
}
