import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'ui-text-input',
    templateUrl: './text-input.component.html',
    styleUrls: ['./text-input.component.scss'],
})
export class TextInputComponent implements OnInit {

    @Input() public required: boolean;
    @Input() public pattern: string;
    @Input() public type: string;
    @Input() public grey: boolean;
    @Input() public title: string;
    @Input() public value: string;
    @Output() public valueChange: EventEmitter<string>;
    public touched: boolean;
    public empty: boolean;

    constructor() {
        this.valueChange = new EventEmitter();
        this.type = 'text';
    }

    public ngOnInit(): void {
        this.empty = !this.value;
    }

    /**
     * Handles user input on the input field
     * @param newValue The new content of the input field
     */
    public textInput(newValue: string): void {
        this.empty = !newValue;
        this.valueChange.emit(newValue);
    }
}
