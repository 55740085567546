<div class="styled-input"
     (click)="touched = true">
    <input type="text"
           value="{{value ? value : undefined}}"
           [attr.pattern]="pattern"
           [attr.required]="required"
           [class.touched]="touched"
           [class.empty]="empty"
           [class.grey]="grey"
           [(value)]="value"
           (input)="textInput($event.target.value)" />
    <label>{{ title }}</label>
    <div class="error">
        {{ 'error.this-field-does-not-meet-the-rules' | translate }}
    </div>
</div>
