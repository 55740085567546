import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { HolidaySalarySupplement, isHolidaySalarySupplement, isWeekdaySalarySupplement, SalarySupplement, WeekdaySalarySupplement } from '../../../../../core/model/SalarySupplement.model';
import { Weekday } from '../../../../../core/model/Weekday';
import { SalarySupplementService } from '../../../../../core/services/salary-supplement.service';

// The state of the SalarySupplementsModal
enum State {
    LOADING = 'loading-state',
    EMPTY = 'empty-state',
    LIST = 'list-state',
    EDIT = 'edit-state',
}

@Component({
    selector: 'app-salary-supplements-modal',
    templateUrl: './salary-supplements-modal.component.html',
    styleUrls: ['./salary-supplements-modal.component.scss'],
})
export class SalarySupplementsModalComponent implements OnInit, OnDestroy {
    public state: State = State.LOADING;
    public State: typeof State = State;
    public Weekday: typeof Weekday = Weekday;
    public supplements$: Observable<SalarySupplement[]>; // All supplements
    public weekdaySupplements$: Observable<WeekdaySalarySupplement[]>; // All weekday supplements
    public holidaySupplements$: Observable<HolidaySalarySupplement[]>; // All holiday supplements
    public weekdays: string[]; // List of all weekdays
    public supplementUnderEdit?: SalarySupplement;
    private supplementSubscription: Subscription;

    constructor(private salarySupplementService: SalarySupplementService) { }

    public ngOnInit(): void {
        this.supplements$ = this.salarySupplementService.getSalarySupplements();
        this.supplementSubscription = this.supplements$.subscribe({
            next: (supplements: SalarySupplement[]) => {
                // If we're on loading state, update to list or empty state when supplements arrive
                if (this.state === State.LOADING || this.state === State.EMPTY) {
                    setTimeout(() => this.state = (supplements.length > 0) ? State.LIST : State.EMPTY, 0);
                }
            },
        });
        this.weekdaySupplements$ = this.supplements$.pipe(map(this.supplementToSpecificSupplement(isWeekdaySalarySupplement)));
        this.holidaySupplements$ = this.supplements$.pipe(map(this.supplementToSpecificSupplement(isHolidaySalarySupplement)));
        this.weekdays = Object.values(Weekday);
    }

    public ngOnDestroy(): void {
        this.supplementSubscription.unsubscribe();
    }

    /**
     * Edit or create a supplement. If no param is supplied, a new supplement is created
     * The optional supplement is forwarded to the EditSalarySupplementComponent.
     */
    public editSupplement(supplement?: SalarySupplement): void {
        this.supplementUnderEdit = supplement;
        this.state = State.EDIT;
    }

    /**
     * When an edit is done, check if we should go to LIST or EMPTY state
     */
    public async editDone(): Promise<void> {
        const supplements: SalarySupplement[] = await this.salarySupplementService.getSalarySupplements().pipe(take(1)).toPromise();
        this.state = supplements.length > 0 ? State.LIST : State.EMPTY;
    }

    /**
     * Returns a mapper function which maps a SalarySupplement list to a list of the type given by the type checker function
     * @param typeChecker The type checker function to filter SalarySupplements on
     */
    private supplementToSpecificSupplement<U extends SalarySupplement>(typeChecker: (supplement: SalarySupplement) => supplement is U):
        (supplements: SalarySupplement[]) => U[] {
        return (supplements: SalarySupplement[]) => {
            const specificSupplement: U[] = [];
            supplements.forEach((supplement: SalarySupplement) => typeChecker(supplement) && specificSupplement.push(supplement));
            return specificSupplement.sort((a: U, b: U) => a.name.localeCompare(b.name));
        };
    }
}
