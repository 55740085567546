<app-upgrade-overlay-base (close)="close()">

    <ng-container *ngIf="!trialStarted">
        <img src="assets/images/park.svg"
             alt="Illustration of a walk in the park">
        <div class="title">{{ 'modal.start-trial.not-started-yet.title' | translate }}</div>
        <div class="subtitle">{{ 'modal.upgrade-overlay.trial-available.subtitle' | translate }}</div>
        <ui-button-action (click)="startTrial()"
                          [loading]="startingTrial">
            {{ 'modal.start-trial.not-started-yet.cta' | translate }}
        </ui-button-action>
    </ng-container>

    <ng-container *ngIf="trialStarted">
        <img src="assets/images/check.svg"
             alt="Illustration of a checkmark">
        <div class="title">{{ 'modal.start-trial.started.title' | translate }}</div>
        <div class="subtitle">{{ 'modal.start-trial.started.subtitle' | translate }}</div>
        <ui-button-action (click)="close()">
            {{ 'modal.start-trial.started.cta' | translate }}
        </ui-button-action>
    </ng-container>

</app-upgrade-overlay-base>