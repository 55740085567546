import { Department } from '../Department/Department.model';
import { Employee } from '../Employee.model';
import { Role } from '../Role/Role.model';

export interface FirestoreEmployee {
    firstname: string;
    lastname: string;
    CPR: string;
    street: string;
    city: string;
    zip: string;
    phone: string;
    email: string;
    imageId?: string;
    roles: string[];
    isDeleted?: boolean;
    primaryDepartment: string | null;
    departments: string[];
    hourlyWage: number | null;
    note?: string;
}

/**
 * Maps an Employee to a FirestoreEmployee
 * @param employee - The employee to map
 */
export function mapEmployeeToFirestoreEmployee(employee: Employee | Omit<Employee, 'id'>): FirestoreEmployee {
    return {
        firstname: employee.firstname,
        lastname: employee.lastname,
        CPR: employee.CPR,
        street: employee.street,
        city: employee.city,
        zip: employee.zip,
        phone: employee.phone,
        email: employee.email,
        roles: employee.roles.map((role: Role) => role.id),
        departments: employee.departments ? employee.departments.map((department: Department) => department.id) : [],
        primaryDepartment: employee.primaryDepartment ? employee.primaryDepartment.id : null,
        hourlyWage: employee.hourlyWage != null ? employee.hourlyWage : null,
        note: employee.note,
    };
}
