import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { combineLatest, Observable, of } from 'rxjs';
import { map, shareReplay, switchMap } from 'rxjs/operators';
import { Employee, Role, Shift, ShiftForSale } from '../../../../core/model';
import { AuthService, AuthUser, CompanySettingsService, EmployeeService, ShiftForSaleService, ShiftService } from '../../../../core/services';
import { SalaryService } from '../../../../core/services/salary.service';
import { getPunchClockSettings, PunchclockConfig } from '../../../utilities/PunchclockUtils';

enum ShiftAction {
    None,
    Sell,
    Buy,
    CheckIn,
    CheckOut,
    EditShiftTime,
    ApproveTrade,
    ApprovePunch,
}

@Component({
    selector: 'app-shift-detail-action',
    templateUrl: './shift-detail-action-controller.component.html',
    styleUrls: ['./shift-detail-action-controller.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShiftDetailActionControllerComponent implements OnInit {
    @Input() public shift: Shift;
    public ShiftAction: typeof ShiftAction = ShiftAction;
    public shiftAction$: Observable<ShiftAction>;
    public shiftForSale$: Observable<ShiftForSale | null>;
    public employee$: Observable<Employee>;
    public potentialGain$: Observable<number>;

    constructor(
        private authService: AuthService,
        private companySettingsService: CompanySettingsService,
        private employeeService: EmployeeService,
        private salaryService: SalaryService,
        private shiftForSaleService: ShiftForSaleService,
        private shiftService: ShiftService,
    ) { }

    public ngOnInit(): void {

        this.shiftForSale$ = this.shiftForSaleService.getShiftForSaleByShift(this.shift)
            .pipe(shareReplay({ bufferSize: 1, refCount: true }));

        this.employee$ = this.employeeService.getAuthenticatedEmployee().pipe(shareReplay({ bufferSize: 1, refCount: true }));

        this.potentialGain$ = this.employee$.pipe(switchMap((employee: Employee) =>
            this.salaryService.shiftToSalary(this.shift, employee)));

        const shift$ = this.shiftService.getShift(this.shift.id).pipe(shareReplay({ bufferSize: 1, refCount: true }));

        const shiftAvailable$: Observable<boolean> = combineLatest([shift$, this.employee$])
            .pipe(switchMap(this.isShiftAvailable.bind(this)));

        this.shiftAction$ = combineLatest([
            shift$,
            shiftAvailable$,
            this.shiftForSale$,
            this.authService.getUser(),
            getPunchClockSettings(this.companySettingsService),
        ]).pipe(
            map(this.getActiveShiftAction, this),
        );
    }

    public getActiveShiftAction(
        [shift, shiftAvailable, shiftForSale, user, { checkinBefore, checkoutUntil, autoPunchClockEnabled, manualPunchclock }]:
            [Shift, boolean, ShiftForSale | null, AuthUser, PunchclockConfig]): ShiftAction {

        // CheckIn,
        const isMyShift = !!(shift.employee?.id === user.user_id);
        const millisPerMinute = 60000;
        const readyForCheckIn = shift.start.getTime() - (checkinBefore ?? 0) * millisPerMinute < Date.now()
            && !shift.checkin && autoPunchClockEnabled;
        const started = shift.start.getTime() <= Date.now() || shift.checkin;
        const ended = shift.checkout || shift.end.getTime() + (checkoutUntil ?? 0) * millisPerMinute < Date.now();
        const punchedIn = !!shift.checkin?.time;

        if (readyForCheckIn && isMyShift && !ended) return ShiftAction.CheckIn;

        if (!started) {
            // ApproveTrade
            if (shiftForSale?.bids.length && user.admin) return ShiftAction.ApproveTrade;
            if (isMyShift) return ShiftAction.Sell;
            if (shiftAvailable) {
                if (shiftForSale) return ShiftAction.Buy;
            }
        }

        if (shift.checkin && isMyShift && !ended) return ShiftAction.CheckOut;
        // ApprovePunch
        if ((shift.checkin || (!punchedIn && !!checkoutUntil))
            && (!shift.approved && user.admin && ended && !shift.approval)) return ShiftAction.ApprovePunch;
        // EditShiftTime,
        if (manualPunchclock && !shift.approved && isMyShift && started) return ShiftAction.EditShiftTime;

        return ShiftAction.None;
    }

    private isShiftAvailable([shift, employee]: [Shift, Employee]): Observable<boolean> {
        const employeeHasRole = !!employee.roles.find((role: Role) => role.id === shift.role.id);
        if (!employeeHasRole) return of(false);

        return this.shiftService.noOverlappingShifts(shift, employee);
    }
}
