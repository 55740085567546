import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { Employee } from '../../../../../core/model';
import { Package } from '../../../../../core/model/Freemium/Package';
import { EmployeeService } from '../../../../../core/services';
import { ConfigurationService } from '../../../../../core/services/Configuration/configuration-service.model';
import { ModalService } from '../../../../../core/services/ModalService.model';
import { SnackbarColor, SnackbarService } from '../../../../../core/services/snackbar/snackbar.service';

@Component({
    selector: 'app-employee-notes',
    templateUrl: './employee-notes.component.html',
    styleUrls: ['./employee-notes.component.scss'],
})
export class EmployeeNotesComponent {

    @Input() public employee: Employee;
    public noteEditMode: boolean;
    public Package: typeof Package = Package;

    constructor(
        public translateService: TranslateService,
        private employeeService: EmployeeService,
        private snackService: SnackbarService,
        private modalService: ModalService,
        private configurationService: ConfigurationService,
    ) { }

    public async editNote(): Promise<void> {
        // If not pro, close the modal and open the Pro Feature modal
        const pro: boolean = await this.configurationService.isPro(Package.PRO).pipe(take(1)).toPromise();
        if (!pro) return this.modalService.openProShopOverlay();

        this.noteEditMode = true;
    }

    public saveNote(note: string): void {
        try {
            this.employee.note = note || '';
            this.employeeService.updateEmployee(this.employee).pipe(take(1)).toPromise();
        } catch (error) {
            this.snackService.displaySnack({ translationKey: 'error.unable-to-save-note' }, SnackbarColor.warn);
            throw error;
        }
    }

}
