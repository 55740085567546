<div class="ready-for-pro-modal">
    <div class="title">
        {{ 'modal.ready-for-pro.ready-for' | translate }}?
    </div>

    <ul>
        <li class="bullet">
            {{ 'modal.ready-for-pro.bullet-2' | translate }}
        </li>
        <li class="bullet">
            {{ 'modal.ready-for-pro.bullet-1' | translate }}
        </li>
        <li class="bullet">
            {{ 'modal.ready-for-pro.bullet-3' | translate }}
        </li>
        <li class="bullet">
            {{ 'modal.ready-for-pro.bullet-4' | translate }}
        </li>
        <li class="bullet">
            {{ 'modal.ready-for-pro.bullet-5' | translate }}
        </li>
    </ul>

    <ui-button-pro (click)="openProShop()"></ui-button-pro>

    <ui-button-standard (click)="close()">
        {{ 'modal.ready-for-pro.stay-on-free' | translate }}
    </ui-button-standard>
</div>