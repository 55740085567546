import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Observable } from 'rxjs';
import { GPSZone } from '../../../../../../../../core/model/GPSZone.model';
import { CompanySetting, CompanySettingsService } from '../../../../../../../../core/services';
import { GpsZoneService } from '../gps-zone.service';

@Component({
    selector: 'app-gps-zone-settings',
    templateUrl: './gps-zone-settings.component.html',
    styleUrls: ['./gps-zone-settings.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GpsZoneSettingsComponent {

    public gpsZones$: Observable<GPSZone[]>;

    constructor(
        settingsService: CompanySettingsService,
        private gpsZoneService: GpsZoneService,
    ) {
        this.gpsZones$ = settingsService.loadSetting(CompanySetting.LOCATIONS);
    }

    public startEditZone(location: GPSZone): void {
        this.gpsZoneService.editZone(location);
    }

    public createNewZone(): void {
        this.gpsZoneService.createNewZone();
    }

    public deleteZone(zone: GPSZone): void {
        this.gpsZoneService.deleteZone(zone);
    }

}
