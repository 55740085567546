<div class="stats-widget d-flex flex-row"
     *ngIf="employee$ | async as emp">

    <!-- Avatar -->
    <div class="avatar mr-3"
         (click)="openSettings()">
        <img [src]="emp.imageURL"
             alt="profile image">
    </div>

    <div class="emp-info d-flex flex-column">
        <div class="emp-name text-left">{{ emp.firstname }} {{ emp.lastname }}</div>
        <div class="emp-roles text-left text-small">
            <!-- Keep this this span line formating to avoid unintended whitespace in roles output -->
            <span *ngFor="let role of emp.roles; let i = index">{{ role.name }}<span *ngIf="i < emp.roles.length - 2">,
                </span><span *ngIf="i === emp.roles.length - 2"> {{'shared.and'| translate}} </span></span>
        </div>

    </div>
</div>