<div class="edit-gps-zone-container">
    <ng-container *ngIf="!loading">
        <google-map id="map"
                    [height]="512"
                    [width]="690"
                    [center]="center"
                    (mapClick)="updateCenter($event)"
                    [zoom]="zoom"
                    [options]="{disableDefaultUI: true}">
            <map-circle [center]="center"
                        [(radius)]="radius"
                        (radiusChanged)="ensureMinimumRadius()"
                        [options]="circleOptions"></map-circle>
        </google-map>
    </ng-container>
    <div class="button-overlay">
        <div class="top d-flex justify-content-between">
            <div class="left d-flex flex-row">
                <ui-button-icon class="back"
                                (click)="goBack()"><i class="far fa-arrow-left"></i></ui-button-icon>
                <ui-button-icon class="get-current-location"
                                (click)="goToCurrentLocation()"><i class="far fa-location-arrow"></i></ui-button-icon>
            </div>
            <div class="right d-flex flex-column">
                <ui-button-icon class="zoom-in"
                                (click)="changeZoom(1)"
                                [disabled]="loading"><span>+</span></ui-button-icon>
                <ui-button-icon class="zoom-out"
                                (click)="changeZoom(-1)"
                                [disabled]="loading"><span>-</span></ui-button-icon>
            </div>
        </div>
        <div class="bottom d-flex align-items-center justify-content-between">
            <div class="input-area">
                <input class="name"
                       [appFloatingLabel]="'settings.punch-clock.name-of-zone' | translate"
                       inputColor="grey"
                       required
                       [(ngModel)]="name">
            </div>
            <div class="input-area">
                <input id="autocomplete"
                       appGooglePlace
                       [apiLoaded$]="apiLoaded$"
                       (setAddress)="onPlaceSelected($event)"
                       placeholder=""
                       title=""
                       class="address"
                       [appFloatingLabel]="'settings.punch-clock.address' | translate"
                       inputColor="grey"
                       required
                       [(ngModel)]="address">
            </div>
            <ui-button-action [disabled]="loading"
                              (click)="save()"
                              [tall]="true">{{ 'settings.punch-clock.save-zone' | translate }}</ui-button-action>
        </div>
    </div>
</div>