import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';
import { routes } from '../../../../../../app-settings';
import { Department } from '../../../../../../core/model/Department/Department.model';
import { RoleService } from '../../../../../../core/services';
import { DepartmentService } from '../../../../../../core/services/Department/DepartmentService.model';
import { SnackbarColor, SnackbarService } from '../../../../../../core/services/snackbar/snackbar.service';

@Component({
    selector: 'app-create-roles-modal',
    templateUrl: './create-roles-modal.component.html',
    styleUrls: ['./create-roles-modal.component.scss'],
})
export class CreateRolesModalComponent implements AfterViewInit {
    public roleNames: [{ value: string }, { value: string }, { value: string }, ...{ value: string }[]];
    public loading: boolean;
    public valid: boolean;

    @ViewChild('createRolesForm') public form: NgForm;

    constructor(
        private roleService: RoleService,
        private departmentService: DepartmentService,
        private modalRef: MatDialogRef<CreateRolesModalComponent>,
        private router: Router,
        private snackbarService: SnackbarService,
    ) {
        this.roleNames = [{ value: '' }, { value: '' }, { value: '' }];
    }

    public ngAfterViewInit(): void {
        // Subscribe to valuechanges in the form and check the validity on updates
        const valueChanges: Observable<unknown> = <Observable<unknown>> this.form.valueChanges;
        valueChanges.subscribe((form: { [formControl: string]: string }) => this.checkValid(form));
    }

    /**
     * Attempts to send a request to the server, to create a new company with user input in the form
     */
    public async onSubmit(): Promise<void> {
        this.setLoadingState(true);

        const rolesToCreate: string[] = this.roleNames
            .map((roleName: { value: string }) => roleName.value.trim())
            .filter((roleName: string) => roleName !== '');

        this.departmentService.getDepartments().pipe(take(1), switchMap(([firstDepartment]: [Department]) => {
            return Promise.all(
                rolesToCreate.map((roleToCreate: string) =>
                    this.roleService.createRole({ name: roleToCreate, department: firstDepartment }).pipe(take(1)).toPromise(),
                ),
            );
        })).toPromise()
            .then(() => {
                this.modalRef.close();
                this.router.navigate([routes.admin.schedule]);
            })
            .catch((error: Error) => {
                this.snackbarService.displaySnack('Kunne ikke oprette roller. Prøv igen.', SnackbarColor.warn);
                throw error;
            });
    }

    public addRoleInput(): void {
        this.roleNames.push({ value: '' });
    }

    /**
     * Loops through the form and sets this.valid to if the form contains values !== ''
     */
    public checkValid(form: { [formControl: string]: string }): boolean {
        for (const formControl in form) {
            if (form.hasOwnProperty(formControl)) {
                if (form[formControl]!.trim() !== '') return this.valid = true;
            }
        }
        return this.valid = false;
    }

    /**
     * Sets the loading state of the component to the given state
     * @param newState the new loading state
     */
    private setLoadingState(newState: boolean): void {
        this.loading = newState;
    }
}
