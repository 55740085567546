import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { Holiday } from '../../../../../../core/model/Holiday';
import { isWeekdaySalarySupplement, SalarySupplement } from '../../../../../../core/model/SalarySupplement.model';
import { Weekday } from '../../../../../../core/model/Weekday';
import { MediaService } from '../../../../../../core/services';
import { isExistingSupplement, NewSalarySupplement, SalarySupplementService } from '../../../../../../core/services/salary-supplement.service';
import { SnackbarColor, SnackbarService } from '../../../../../../core/services/snackbar/snackbar.service';
import { CustomValidators } from '../../../../../../shared/utilities/FormUtils';

enum SalarySupplementType {
    WEEKDAY = 'weekday',
    HOLIDAY = 'holiday',
}

interface EditSalarySupplementModel {
    id?: string;
    name: string;
    amount: string;
    startHour: string;
    startMin: string;
    endHour: string;
    endMin: string;
    type: SalarySupplementType;
    weekdays: { [key in Weekday]: boolean; };
    holidays: { [key in Holiday]: boolean; };
}

@Component({
    selector: 'app-edit-salary-supplement',
    templateUrl: './edit-salary-supplement.component.html',
    styleUrls: ['./edit-salary-supplement.component.scss'],
})
export class EditSalarySupplementComponent implements OnInit, OnDestroy {

    @Input() public supplement?: SalarySupplement; // The input supplement to edit, or undefined if new supplement
    @Output() public done: EventEmitter<void>;
    public Weekday: typeof Weekday = Weekday;
    public SalarySupplementType: typeof SalarySupplementType = SalarySupplementType;
    public holidayTranslations: string[];
    public model: EditSalarySupplementModel;
    public formGroup: FormGroup;
    public saving: boolean;
    public deleting: boolean;
    private statusChangeSubscription: Subscription;

    constructor(
        private cdr: ChangeDetectorRef,
        private salarySupplementService: SalarySupplementService,
        private snackService: SnackbarService,
    ) {
        this.done = new EventEmitter();
    }

    public ngOnInit(): void {
        this.model = this.initializeModel(this.supplement);
        this.formGroup = this.buildFormGroup();
        // We need to detect changes on status updates, as they don't always trigger change
        // detection and thereby throws ExpressionChangedAfterItHasBeenCheckedError
        this.statusChangeSubscription = this.formGroup.statusChanges.subscribe({ next: () => MediaService.detectChanges(this.cdr) });
        this.holidayTranslations = Object.values(Holiday);
    }

    /**
     * Emit that editing is done without saving to db
     */
    public cancel(): void {
        this.done.emit();
    }

    /**
     * Delete the supplement under edit
     */
    public async deleteSupplement(): Promise<void> {
        this.deleting = true;
        try {
            await this.salarySupplementService.deleteSalarySupplement(this.supplement!);
            this.done.emit();
        } catch (error) {
            this.snackService.displaySnack({ translationKey: 'error.could-not-delete-supplement' }, SnackbarColor.warn);
        }
        this.deleting = false;
    }

    /**
     * Convert the form model to a SalarySupplement and update / create it in db
     */
    public async save(): Promise<void> {
        if (!this.formGroup.valid) return;

        this.saving = true;

        const supplement: SalarySupplement | NewSalarySupplement = this.modelToSupplement(this.model);
        try {
            if (isExistingSupplement(supplement)) {
                await this.salarySupplementService.updateSalarySupplement(supplement);
            } else {
                await this.salarySupplementService.createSalarySupplement(supplement);
            }
            this.done.emit();
        } catch (error) {
            this.snackService.displaySnack({ translationKey: this.model.id ? 'error.could-not-update-supplement' : 'error.could-not-save-supplement' }, SnackbarColor.warn);
        }
        this.saving = false;
    }

    /**
     * When the SalarySupplementType is changed in the form, update the validity of the weekday controls as they might no longer be required
     */
    public typeChanged(): void {
        // When the type changes, update the weekday validity as week days are no longer required
        setTimeout(() => this.formGroup.get('SUPPLEMENT_WEEKDAY')?.updateValueAndValidity(), 0);
    }

    /**
     * Whenever something is input to a time input, move on to next time input if the new value allows it
     * @param newValue The new value of the time input
     * @param type Whether the input is an HOUR or a MINUTE input field
     * @param tabTarget An element to move focus to, when input is done. If none is provided, blur the source input field.
     */
    public timeInputChange(newValue: string, type: 'HOUR' | 'MIN', tabTarget?: HTMLInputElement): void {
        // If nothing is typed do nothing
        if (newValue.length === 0) return;

        // If one char is typed, act based on whether it is hour or minute input
        if (newValue.length === 1) {
            // If hours is l.e. 2 input might not be done
            if (type === 'HOUR' && Number(newValue) <= 2) return;
            // If minutes is l.e. 5 input might not be done
            if (type === 'MIN' && Number(newValue) <= 5) return;
        }

        // Otherwise jump to tab target or blur field
        if (tabTarget) {
            tabTarget.focus();
            tabTarget.select();
        } else {
            (<HTMLInputElement> document.activeElement).blur();
        }
    }

    public ngOnDestroy(): void {
        this.statusChangeSubscription.unsubscribe();
    }

    /**
     * Convert the form model to a SalarySupplement or a NewSalarySupplement
     */
    private modelToSupplement(model: EditSalarySupplementModel): SalarySupplement | NewSalarySupplement {
        // Set the days of the supplement based on the Supplement's type
        const days = this.model.type === SalarySupplementType.HOLIDAY
            ? { holidays: this.model.holidays }
            : { weekdays: this.model.weekdays };

        // Convert the model to a supplement
        const supplement = {
            id: model.id,
            amount: Number(model.amount.replace(',', '.')),
            start: Number(model.startHour) * 60 + Number(model.startMin),
            end: Number(model.endHour) * 60 + Number(model.endMin),
            name: model.name,
            ...days,
        };

        // Remove the id property, if it's undefined
        if (!model.id) delete supplement.id;

        return supplement;
    }

    /**
     * Initialize the form model optionally from a supplied, existing SalarySupplement
     */
    private initializeModel(suppliedSupplement?: SalarySupplement): EditSalarySupplementModel {
        if (suppliedSupplement) {
            return {
                id: suppliedSupplement.id,
                name: suppliedSupplement.name,
                amount: Number(suppliedSupplement.amount).toString().replace('.', ','),
                startHour: Math.floor(suppliedSupplement.start / 60).toString(),
                startMin: (suppliedSupplement.start % 60).toString(),
                endHour: Math.floor(suppliedSupplement.end / 60).toString(),
                endMin: (suppliedSupplement.end % 60).toString(),
                type: isWeekdaySalarySupplement(suppliedSupplement) ? SalarySupplementType.WEEKDAY : SalarySupplementType.HOLIDAY,
                weekdays: isWeekdaySalarySupplement(suppliedSupplement) ? {
                    [Weekday.MONDAY]: suppliedSupplement.weekdays[Weekday.MONDAY],
                    [Weekday.TUESDAY]: suppliedSupplement.weekdays[Weekday.TUESDAY],
                    [Weekday.WEDNESDAY]: suppliedSupplement.weekdays[Weekday.WEDNESDAY],
                    [Weekday.THURSDAY]: suppliedSupplement.weekdays[Weekday.THURSDAY],
                    [Weekday.FRIDAY]: suppliedSupplement.weekdays[Weekday.FRIDAY],
                    [Weekday.SATURDAY]: suppliedSupplement.weekdays[Weekday.SATURDAY],
                    [Weekday.SUNDAY]: suppliedSupplement.weekdays[Weekday.SUNDAY],
                } : this.getAllWeekdaysFalse(),
                holidays: this.getAllHolidaysTrue(),
            };
        }
        return {
            name: '', amount: '', startHour: '', startMin: '', endHour: '', endMin: '',
            type: SalarySupplementType.WEEKDAY,
            weekdays: this.getAllWeekdaysFalse(),
            holidays: this.getAllHolidaysTrue(),
        };
    }

    /**
     * Build the form group which consists of name, amount, a form group for time inputs, type, and a form group for weekdays
     */
    private buildFormGroup(): FormGroup {
        const SUPPLEMENT_NAME: FormControl = this.createFormConrol(Validators.required);
        const SUPPLEMENT_AMOUNT: FormControl = this.createFormConrol(Validators.required, CustomValidators.MoneyValidator);

        const SUPPLEMENT_START_HOUR = this.createTimeFormControl(23);
        const SUPPLEMENT_END_HOUR = this.createTimeFormControl(24);
        const SUPPLEMENT_START_MIN = this.createTimeFormControl(59);
        const SUPPLEMENT_END_MIN = this.createTimeFormControl(59);
        const SUPPLEMENT_TIME_SPAN: FormGroup = new FormGroup({
            SUPPLEMENT_START_HOUR, SUPPLEMENT_END_HOUR, SUPPLEMENT_START_MIN, SUPPLEMENT_END_MIN,
        }, CustomValidators.ValidateTimeInput([SUPPLEMENT_START_HOUR, SUPPLEMENT_START_MIN, SUPPLEMENT_END_HOUR, SUPPLEMENT_END_MIN]));

        // Re-validate the SUPPLEMENT_TIME_SPAN as the model changes the values after the form is initialized
        setTimeout(() => SUPPLEMENT_TIME_SPAN.updateValueAndValidity(), 0);

        const SUPPLEMENT_TYPE: FormControl = this.createFormConrol(Validators.required);
        const SUPPLEMENT_WEEKDAY: FormGroup = new FormGroup({
            SUPPLEMENT_MONDAY: this.createFormConrol(), SUPPLEMENT_TUESDAY: this.createFormConrol(),
            SUPPLEMENT_WEDNESDAY: this.createFormConrol(), SUPPLEMENT_THURSDAY: this.createFormConrol(),
            SUPPLEMENT_FRIDAY: this.createFormConrol(), SUPPLEMENT_SATURDAY: this.createFormConrol(),
            SUPPLEMENT_SUNDAY: this.createFormConrol(),
        }, CustomValidators.RequireCheckboxesCheckedIf(() => SUPPLEMENT_TYPE.value === SalarySupplementType.WEEKDAY));

        return new FormGroup({ SUPPLEMENT_NAME, SUPPLEMENT_AMOUNT, SUPPLEMENT_TIME_SPAN, SUPPLEMENT_TYPE, SUPPLEMENT_WEEKDAY });
    }

    private createTimeFormControl(maxValue: number): FormControl {
        return this.createFormConrol(Validators.required, CustomValidators.PositveIntegerValidator, Validators.max(maxValue));
    }

    private createFormConrol(...validators: ValidatorFn[]): FormControl {
        return new FormControl(undefined, validators);
    }

    private getAllWeekdaysFalse(): { [key in Weekday]: boolean } {
        return {
            [Weekday.MONDAY]: false,
            [Weekday.TUESDAY]: false,
            [Weekday.WEDNESDAY]: false,
            [Weekday.THURSDAY]: false,
            [Weekday.FRIDAY]: false,
            [Weekday.SATURDAY]: false,
            [Weekday.SUNDAY]: false,
        };
    }

    private getAllHolidaysTrue(): { [key in Holiday]: boolean } {
        return {
            [Holiday.NEW_YEARS_DAY]: true,
            [Holiday.MAUNDY_THURSDAY]: true,
            [Holiday.GOOD_FRIDAY]: true,
            [Holiday.EASTER_SUNDAY]: true,
            [Holiday.EASTER_MONDAY]: true,
            [Holiday.GREAT_PRAYER_DAY]: true,
            [Holiday.ASCENSION_DAY]: true,
            [Holiday.WHIT_SUNDAY]: true,
            [Holiday.WHIT_MONDAY]: true,
            [Holiday.CHRISTMAS_DAY]: true,
            [Holiday.BOXING_DAY]: true,
        };
    }
}
