import { Injectable } from '@angular/core';
import { isArray } from 'lodash';
import * as moment from 'moment';
import { BehaviorSubject, Observable } from 'rxjs';
import { isSameDate } from '../../shared/utilities/DateUtils/isSameDate';
import { Holiday } from '../model/Holiday';
import { SpecialDay } from '../model/SpecialDay';

export enum ShiftDateType {
    START,
    END,
}

@Injectable()
/**
 * This Service is used in the dayView and its subcomponents. It keeps track of the currently selected day.
 */
export class DateService {
    private static cachedHolidays: Map<number, { [key in Holiday]: Date }>;

    private static cachedSpecialDays: Map<number, { [key in SpecialDay]: Date | Date[] }>;
    private dateSource: BehaviorSubject<Date>;

    constructor() {
        this.dateSource = new BehaviorSubject(new Date());
    }

    public static isToday(date: Date): boolean {
        const today: Date = new Date();
        return date.getMonth() === today.getMonth() && date.getDate() === today.getDate() && date.getFullYear() === today.getFullYear();
    }

    /**
     * Makes a list of all dates in a week from a given day
     * @param date  The day where you want all the days in the same week.
     */
    public static getWeekDates(date: Date): Date[] {
        // We change the weekday number of sunday to 7 instead of 0
        const weekDayNumber: number = (date.getDay() === 0) ? 7 : date.getDay();

        // Get the first day of the week
        const monday: Date = new Date(date.getFullYear(), date.getMonth(), date.getDate() - weekDayNumber + 1);

        const week: Date[] = [];
        // Add every day from monday
        for (let i = 0; i < 7; i++) {
            week.push(new Date(monday.getFullYear(), monday.getMonth(), monday.getDate() + i));
        }
        return week;
    }


    /**
     * Get a date from today at 00.00.00
     */
    public static today(): Date {
        const date: Date = new Date();
        return new Date(
            date.getFullYear(),
            date.getMonth(),
            date.getDate(),
        );
    }

    public static getSameTimeOnDifferentDay(daySource: Date, timeSource: Date): Date {
        return new Date(
            daySource.getFullYear(),
            daySource.getMonth(),
            daySource.getDate(),
            timeSource.getHours(),
            timeSource.getMinutes(),
        );
    }

    /**
     * Handles moving a shift to a week that starts on Monday specified by daySource
     * @param daySource
     * @param timeSourceStart
     * @param timeSourceEnd
     * @param dateType
     */
    public static getSameTimeOnDifferentWeekDay(daySource: Date, timeSourceStart: Date, timeSourceEnd: Date, dateType: ShiftDateType): Date {
        // handle shift start date/time
        if (dateType === ShiftDateType.START) {
            return DateService.getSameTimeOnDifferentWorkday(daySource, timeSourceStart);
        }

        // handle shift end date/time
        const pastMidnight: boolean = DateService.isPastMidnight(timeSourceStart, timeSourceEnd);

        // if shift does not start on a Sunday or the shift is not past midnight
        if (timeSourceStart.getDay() !== 0 || !pastMidnight) {
            return DateService.getSameTimeOnDifferentWorkday(daySource, timeSourceEnd);
        } else {
            return DateService.getSameTimeOnDifferentWorkday(daySource, timeSourceEnd, 7);
        }
    }

    public static getDateObjectFromTimeAndDate(time: number, date: Date): Date {
        const hours: number = Math.floor(time);
        const minutes: number = Math.round((time - hours) * 60);
        const dateWithTime: Date = new Date(date);
        dateWithTime.setHours(hours, minutes, 0, 0);
        return dateWithTime;
    }

    public static getTheDayAfter(date: Date): Date {
        const day = new Date(date);
        day.setDate(day.getDate() + 1);
        return day;
    }

    /**
     * Calculates the decimal value for an hour at a given date.
     * If the optional startDate is given the time is calculated from that date at 00.00,
     * to the given date, returned in decimal.
     * @param date The date object to calculate the hours in decimal from.
     * @param startDate The start date to calculate how many hours until date.
     */
    public static getHourDecimalsFromDate(date: Date, startDate?: Date): number {
        const from: number = new Date(startDate ? startDate : date).setHours(0, 0, 0, 0);
        const to: number = date.getTime();
        const oneHourMs = 1000 * 60 * 60; // Milliseconds in one hour
        return (to - from) / oneHourMs; //
    }

    /**
     * Transforms a decimal time value into a formatted string (e.g. 1.5 to 1:30 or 22.25 to 22:15)
     * @param value - The decimal time value to format
     */
    public static getFormattedTimeStringFromDecimals(value: number) {
        /**
         * Returns an at least two digit number representing the amount of minutes of the input value.
         * @param number - the amount of hours (in decimal) to convert to minutes
         */
        const hoursToMinutes = (number: number) => {
            const minutes = Math.round(number * 60);
            if (minutes < 10) {
                return '0' + minutes;
            }
            return minutes;
        };

        if (value != null) {
            // Round numbers to the nearest fraction of a billion (to handle input precision errors)
            value = Number(value.toPrecision(9));
            const hours = Math.floor(value);
            const minutes = hoursToMinutes(value % 1);
            return hours + ':' + minutes;
        } else {
            return '';
        }
    }

    /**
     * Returns the week number of the given date
     * @param date - The date to find the week number for
     * @return The number of the week that contains the given date
     */
    public static getWeekNumber(date: Date): number {
        // Hentet fra https://stackoverflow.com/questions/6117814/get-week-of-year-in-javascript-like-in-php
        date = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
        date.setUTCDate(date.getUTCDate() + 4 - (date.getUTCDay() || 7));
        const yearStart: Date = new Date(Date.UTC(date.getUTCFullYear(), 0, 1));
        return Math.ceil((((date.getTime() - yearStart.getTime()) / 86400000) + 1) / 7);
    }

    /**
     * Gets the number of weeks that the given days span over
     * @param days - The days to get the number of weeks for
     * @return The number of weeks that the given days span over
     */
    public static getWeekCount(days: Date[]): number {
        const weeks: number[] = [];
        days.forEach((day) => {
            const weekNumber: number = DateService.getWeekNumber(day);
            if (!weeks.includes(weekNumber)) weeks.push(weekNumber);
        });
        return weeks.length;
    }

    /**
     * Formats a date as: 'dd/mm/yyyy' regardless of incorrect locale setting
     * @param date - date to format
     */
    public static getDate(date: Date): string {
        const dayOfMonth: string = (date.getDate() < 10)
            ? '0' + date.getDate()
            : date.getDate().toString();
        const mnth: number = date.getMonth() + 1;
        const month: string = (mnth < 10)
            ? '0' + mnth
            : mnth.toString();

        return dayOfMonth + '/' + month + '/' + date.getFullYear();
    }
    /**
     * Returns a TimeInterval array with the hours of the day, split up into minute segments from 00.00 to 24.00
     * @param {number} minuteSegments The amount of segments 60 minutes should be split into
     */
    public static setUpTimeIntervals(minuteSegments: number): TimeInterval[] {
        const timeIntervals: TimeInterval[] = [];
        for (let i = 0; i < 24; i++) { // For every hour in the day
            for (let j = 0; j < minuteSegments; j++) { // For every quarter of an hour
                let minuteDisplay: string = (60 / minuteSegments * j).toString();
                if (minuteDisplay.length < 2) minuteDisplay = '0' + minuteDisplay; // Prepends a 0 if the minutes is one digit.
                timeIntervals.push({
                    value: i + (1 / minuteSegments) * j,
                    display: i + ':' + minuteDisplay,
                    toString: function () {
                        // Return the display value of the time interval
                        return this.display;
                    },
                },
                );
            }
        }
        timeIntervals.push({
            value: 24,
            display: '24:00',
            toString: function () {
                // Return the display value of the time interval
                return this.display;
            },
        });
        return timeIntervals;
    }

    /**
     * Returns a new date at midnight of the given date
     * @param date - The date to get midnight from
     * @param plusDays - Optional addition of days (e.g. if it's 1, then midnight on the following day is returned)
     */
    public static midnight(date: Date, plusDays?: number): Date {
        // Init the date delta to itself or 0 if not supplied
        plusDays = plusDays || 0;

        const midnight = new Date(date);

        // Set the hours to midnight plus the date delta
        midnight.setHours(24 * plusDays, 0, 0, 0);

        return midnight;
    }


    /**
     * Get Danish national holidays calculated from easterday
     */
    public static getHolidays(year: number): { [key in Holiday]: Date } {
        if (!this.cachedHolidays) this.cachedHolidays = new Map();
        const yearCache = this.cachedHolidays.get(year);
        if (yearCache) return yearCache;

        const easterday: Date = this.getEasterDay(year);

        this.cachedHolidays.set(year, {
            [Holiday.NEW_YEARS_DAY]: new Date(year, 0, 1),
            [Holiday.MAUNDY_THURSDAY]: new Date(year, easterday.getMonth(), easterday.getDate() - 3),
            [Holiday.GOOD_FRIDAY]: new Date(year, easterday.getMonth(), easterday.getDate() - 2),
            [Holiday.EASTER_SUNDAY]: easterday,
            [Holiday.EASTER_MONDAY]: new Date(year, easterday.getMonth(), easterday.getDate() + 1),
            [Holiday.GREAT_PRAYER_DAY]: new Date(year, easterday.getMonth(), easterday.getDate() + 26),
            [Holiday.ASCENSION_DAY]: new Date(year, easterday.getMonth(), easterday.getDate() + 39),
            [Holiday.WHIT_SUNDAY]: new Date(year, easterday.getMonth(), easterday.getDate() + 49),
            [Holiday.WHIT_MONDAY]: new Date(year, easterday.getMonth(), easterday.getDate() + 50),
            [Holiday.CHRISTMAS_DAY]: new Date(year, 11, 25),
            [Holiday.BOXING_DAY]: new Date(year, 11, 26),
        });
        return this.getHolidays(year);
    }

    /**
     * Get Danish special days
     */
    public static getSpecialDays(year: number): { [key in SpecialDay]: Date | Date[] } {
        if (!this.cachedSpecialDays) this.cachedSpecialDays = new Map();
        const yearCache = this.cachedSpecialDays.get(year);
        if (yearCache) return yearCache;

        // Init easter and Christmas day for use in calculations
        const easterday: Date = this.getEasterDay(year);
        const christmasEve = new Date(year, 11, 24);

        // Fourth advent is the Sunday before Christmas eve (unless Christmas eve is a sunday itself)
        const fourthAdvent: Date = moment(christmasEve).isoWeekday() === 7
            ? moment(christmasEve).toDate()
            : moment(christmasEve).subtract(moment(christmasEve).isoWeekday(), 'day').toDate();

        this.cachedSpecialDays.set(year, {
            [SpecialDay.ADVENT_1]: moment(fourthAdvent).subtract(3, 'week').toDate(),
            [SpecialDay.ADVENT_2]: moment(fourthAdvent).subtract(2, 'week').toDate(),
            [SpecialDay.ADVENT_3]: moment(fourthAdvent).subtract(1, 'week').toDate(),
            [SpecialDay.ADVENT_4]: fourthAdvent,
            [SpecialDay.AUTUMN_HOLIDAY]:
                this.getFollowingDays(moment(new Date(year, 9)).isoWeek(42).startOf('isoWeek').subtract(2, 'days'), 9),
            [SpecialDay.BLACK_FRIDAY]: moment(this.nthDay(4, 4, 10, year)).add(1, 'day').toDate(),
            [SpecialDay.CHRISTMAS_EVE]: christmasEve,
            [SpecialDay.DECEMBER_23RD]: new Date(year, 11, 23),
            [SpecialDay.EASTER_HOLIDAY]: this.getFollowingDays(moment(easterday).subtract(8, 'days'), 10),
            [SpecialDay.FATHERS_DAY]: new Date(year, 5, 5),
            [SpecialDay.HALLOWEEN]: new Date(year, 9, 31),
            [SpecialDay.J_DAY]: this.nthDay(1, 5, 10, year),
            [SpecialDay.MOTHERS_DAY]: this.nthDay(2, 7, 4, year),
            [SpecialDay.SAINT_JOHNS_EVE]: new Date(year, 5, 23),
            [SpecialDay.SAINT_MARTINS_EVE]: new Date(year, 10, 10),
            [SpecialDay.SAINT_PATRICKS_DAY]: new Date(year, 2, 17),
            [SpecialDay.SHROVETIDE]: moment(easterday).subtract(49, 'days').toDate(),
            [SpecialDay.VALENTINES_DAY]: new Date(year, 1, 14),
            [SpecialDay.WOMENS_DAY]: new Date(year, 2, 8),
            [SpecialDay.DST_START]: this.nthDay(0, 7, 2, year),
            [SpecialDay.DST_END]: this.nthDay(0, 7, 9, year),
            [SpecialDay.NEW_YEARS_EVE]: new Date(year, 11, 31),
        });
        return this.getSpecialDays(year);
    }

    /**
     * Get any Holiday of a date, or undefined if none
     */
    public static dateToHoliday(date: Date): Holiday | undefined {
        const holidayDates = DateService.getHolidays(date.getFullYear());
        return Object.values(Holiday).find((holiday: Holiday) => isSameDate(holidayDates[holiday], date));
    }

    /**
     * Get all Holidays of a date
     */
    public static dateToHolidays(date: Date): Holiday[] {
        const holidayDates = DateService.getHolidays(date.getFullYear());
        return Object.values(Holiday).filter((holiday: Holiday) => isSameDate(holidayDates[holiday], date));
    }

    /**
     * Get all Special days of a date
     */
    public static dateToSpecialDays(date: Date): SpecialDay[] {
        // Get special days of the year
        const specialDays = DateService.getSpecialDays(date.getFullYear());

        // Go through each SpecialDay and check if the date (or one of the dates) matches the parameter date
        return Object.values(SpecialDay).filter((specialDay: SpecialDay) => {
            const specialDayDates = specialDays[specialDay];
            if (!isArray(specialDayDates)) return isSameDate(specialDayDates, date);
            return specialDayDates.find((specialDayDate: Date) => isSameDate(specialDayDate, date));
        });
    }

    /**
     * Returns an observable on the currentDate
     * @returns {Observable<Date>}
     */
    public getDateObservable(): Observable<Date> {
        return this.dateSource.asObservable();
    }

    /**
     * Changes the stored date, updating the observable.
     * @param {Date} date: the new date
     */
    public changeDate(date: Date): void {
        this.dateSource.next(date);
    }

    private static getWeekdayNumber(date: Date): number {
        return (date.getDay() === 0) ? 7 : date.getDay();
    }

    /**
     * Adds days to a date
     * @param date
     * @param days
     * @private
     */
    private static addDays(date: Date, days: number): void {
        date.setDate(date.getDate() + days);
    }

    /**
     * Gets same shift start / end time on a different workday
     * @param daySource
     * @param timeSource
     * @param days
     * @private
     */
    private static getSameTimeOnDifferentWorkday(daySource: Date, timeSource: Date, days?: number): Date {
        const weekDayNumber = DateService.getWeekdayNumber(timeSource);
        const date: Date = DateService.getSameTimeOnDifferentDay(daySource, timeSource);
        DateService.addDays(date, (days === undefined) ? weekDayNumber - 1 : days);
        return date;
    }

    /**
     * Determines if a shift is past midnight
     * @param timeSourceStart
     * @param timeSourceEnd
     * @private
     */
    private static isPastMidnight(timeSourceStart: Date, timeSourceEnd: Date): boolean {
        return timeSourceStart.getDate() !== timeSourceEnd.getDate();
    }

    /**
     * Get the n'th weekday of a given month in a given year.
     *
     * e.g. nthDay(2,5,10,2020) is the second friday of November 2020
     *
     * @param nth 1 = first, 2 = second and so forth (0 is last)
     * @param weekday The weekday to find
     * @param month The month to look in
     * @param year The year to find for
     */
    private static nthDay(nth: number, weekday: number, month: number, year: number): Date {
        const day = moment(new Date(year, month, 1));
        if (nth === 0) day.add(1, 'month');
        const daysFromLastWeekdayOfPrevMonth = (day.isoWeekday() - weekday + 7) % 7 || 7;
        day.subtract(daysFromLastWeekdayOfPrevMonth, 'days');
        day.add(nth * 7, 'days');
        return day.toDate();
    }

    /**
     * Return an array of dates following the given date and with a length of the given parameter.
     * @param date The date to start from
     * @param length The number of days to go through
     */
    private static getFollowingDays(date: moment.Moment, length: number): Date[] {
        const dates = [date.toDate()];
        for (let index = 1; index < length; index++) dates.push(date.add(1, 'day').toDate());
        return dates;
    }

    /**
     * calculates easterday(always a sunday), based on the year. (Equation based on denstoredanske)
     * @param year year you wnat to know the danish national Holidays on
     */
    private static getEasterDay(year: number): Date {
        let month: number = 0;
        let date: number = 0;
        const m: number = 24;
        const n: number = 5;

        const a: number = year % 19;
        const b: number = year % 4;
        const c: number = year % 7;
        const d: number = (19 * a + m) % 30;
        const e: number = (2 * b + 4 * c + 6 * d + n) % 7;

        if (d === 29 && e === 6) {
            date = 19;
            month = 4;
            return new Date(year, month, date);

        } else if (d === 28 && e === 6 && a > 10) {
            date = 18;
            month = 4;
            return new Date(year, month, date);
        }
        if (22 + d + e < 31) {
            date = 22 + d + e;
            month = 2;
            return new Date(year, month, date);
        } else {
            date = d + e - 9;
            month = 3;
            return new Date(year, month, date);
        }
    }


}

export interface TimeInterval {
    value: number;
    display: string;
    toString: () => string;
}
