import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { distinctUntilChanged, map, switchMap, take } from 'rxjs/operators';
import { Auth, FirestoreService, UserSettingsService } from '../../../';
import { NotificationType } from '../../../../../../../functions/src/notifications/model/NotificationType';
import { Employee } from '../../../../model';
import Timestamp from '../../../../model/Firestore/FirestoreTimestamp';
import { Package } from '../../../../model/Freemium/Package';
import { DEFAULT_DISABLED_NOTIFICATIONS, DEFAULT_GROUP_SHIFTS_BY, DEFAULT_LANGUAGE, DEFAULT_LAST_USED_TIMESPAN, DEFAULT_SHOW_HOURS_IN_WEEK_VIEW, DEFAULT_SHOW_LABOR_PERCENTAGE_IN_WEEK_VIEW, DEFAULT_SHOW_PROFILE_PICTURES_IN_WEEK_VIEW, DEFAULT_SHOW_REVENUE_IN_WEEK_VIEW, DEFAULT_SHOW_SALARY_IN_WEEK_VIEW, DEFAULT_WEEK_VIEW_RANGE, GroupShiftsBy, Language, LastUsedTimeSpan, UserSettings, UserSettingsTypeMap as TypeMap, UserSettingsTypeMap } from '../../../../model/UserSettings';
import { ConfigurationService } from '../../../Configuration/configuration-service.model';

interface FSSettings {
    [UserSettings.LANGUAGE]?: Language;
    [UserSettings.GROUP_SHIFTS_BY]?: GroupShiftsBy;
    [UserSettings.SALARY_RANGE]?: { start: Timestamp, end: Timestamp };
    [UserSettings.WEEK_VIEW_RANGE]?: { start: Timestamp, end: Timestamp };
    [UserSettings.LATEST_INSERTED_TEMPLATE]?: string;
    [UserSettings.LATEST_SELECTED_DEPARTMENT]?: string;
    [UserSettings.LATEST_SELECTED_ROLE]?: string;
    [UserSettings.DISABLED_NOTIFICATIONS]?: NotificationType[];
    [UserSettings.SHOW_PROFILE_PICTURES_IN_WEEK_VIEW]?: boolean;
    [UserSettings.SHOW_HOURS_IN_WEEK_VIEW]?: boolean;
    [UserSettings.SHOW_SALARY_IN_WEEK_VIEW]?: boolean;
    [UserSettings.SHOW_REVENUE_IN_WEEK_VIEW]?: boolean;
    [UserSettings.SHOW_LABOR_PERCENTAGE_IN_WEEK_VIEW]?: boolean;
    [UserSettings.LAST_USED_TIMESPAN]?: LastUsedTimeSpan;
}

type Restricted<T extends UserSettings> = {
    restricted: true;
    restrictedValue: UserSettingsTypeMap[T];
} | {
    restricted: false;
};

@Injectable({
    providedIn: 'root',
})
export class FirestoreUserSettingsService extends UserSettingsService {
    private readonly collectionPath: string = 'employees';

    constructor(
        private firestoreService: FirestoreService,
        private injector: Injector,
    ) {
        super();
    }

    public loadSetting<T extends UserSettings>(setting: T, defaultValue?: TypeMap[T]): Observable<TypeMap[T]> {
        return this.firestoreService.authenticate<Employee>(this.collectionPath).pipe(
            // Get the user settings collection
            switchMap((auth: Auth<Employee>) =>
                auth.collection.doc<{ settings: FSSettings }>(auth.user.user_id).valueChanges()),
            // Get the specific setting (optionally fallback to default)
            map((loadedSettings?: { settings: FSSettings }) =>
                this.retrieveSetting(setting, defaultValue, loadedSettings && loadedSettings.settings)),
            // Switch to the isRestricted observable to handle changing pro status
            switchMap((retrievedSetting: UserSettingsTypeMap[T]) =>
                this.isRestrictedSetting(setting).pipe(
                    map((isRestrictedSetting: Restricted<UserSettings>) => {
                        if (isRestrictedSetting.restricted) return isRestrictedSetting.restrictedValue as UserSettingsTypeMap[T];
                        return retrievedSetting;
                    }),
                ),
            ),
            distinctUntilChanged(),
        );
    }

    public saveSetting<T extends UserSettings>(setting: T, value: TypeMap[T]): Observable<TypeMap[T]> {
        return this.firestoreService.authenticate<Employee>(this.collectionPath).pipe(
            switchMap((auth: Auth<Employee>) =>
                auth.collection.doc<{ settings: FSSettings }>(auth.user.user_id).set(
                    { settings: this.mapSettingToFirestoreSettings(setting, value) },
                    { merge: true },
                ),
            ),
            switchMap(() => this.loadSetting(setting)),
        );
    }

    /**
     * Maps a setting and its value to a Firestore Setting
     * @param setting - The setting to map
     * @param value - The value of the setting to map
     */
    public mapSettingToFirestoreSettings<T extends UserSettings>(setting: T, value: TypeMap[T]): FSSettings {
        const convertToTimestamp =
            (range) => ({ start: Timestamp.fromDate(range.start), end: Timestamp.fromDate(range.end) });

        switch (setting) {
            // Switch over the different settings and return the corresponding firestore setting
            case UserSettings.LANGUAGE: return { language: value } as FSSettings;
            case UserSettings.GROUP_SHIFTS_BY: return { groupShiftsBy: value } as FSSettings;
            case UserSettings.SALARY_RANGE: return { salaryRange: convertToTimestamp(value) };
            case UserSettings.WEEK_VIEW_RANGE: return { weekViewRange: convertToTimestamp(value) };
            case UserSettings.LATEST_INSERTED_TEMPLATE: return { latestInsertedTemplate: value } as FSSettings;
            case UserSettings.LATEST_SELECTED_DEPARTMENT: return { latestSelectedDepartment: value } as FSSettings;
            case UserSettings.LATEST_SELECTED_ROLE: return { latestSelectedRole: value } as FSSettings;
            case UserSettings.DISABLED_NOTIFICATIONS: return { disabledNotifications: value } as FSSettings;
            case UserSettings.SHOW_PROFILE_PICTURES_IN_WEEK_VIEW: return { showProfilePicturesInWeekView: value } as FSSettings;
            case UserSettings.SHOW_HOURS_IN_WEEK_VIEW: return { showHoursInWeekView: value } as FSSettings;
            case UserSettings.SHOW_SALARY_IN_WEEK_VIEW: return { showSalaryInWeekView: value } as FSSettings;
            case UserSettings.SHOW_REVENUE_IN_WEEK_VIEW: return { showRevenueInWeekView: value } as FSSettings;
            case UserSettings.SHOW_LABOR_PERCENTAGE_IN_WEEK_VIEW: return { showLaborPercentageInWeekView: value } as FSSettings;
            case UserSettings.LAST_USED_TIMESPAN: return { lastUsedTimeSpan: value } as FSSettings;
            default: throw new Error('Trying to save unsupported type');
        }
    }

    private mapFirestoreSettingsToSettings(fssettings: FSSettings): Partial<TypeMap> {
        const settings = {
            [UserSettings.GROUP_SHIFTS_BY]: fssettings.groupShiftsBy,
            [UserSettings.LANGUAGE]: fssettings.language,
            [UserSettings.SALARY_RANGE]:
                fssettings.salaryRange
                    ? {
                        start: fssettings.salaryRange.start.toDate(),
                        end: fssettings.salaryRange.end.toDate(),
                    } : undefined,
            [UserSettings.WEEK_VIEW_RANGE]:
                fssettings.weekViewRange
                    ? {
                        start: fssettings.weekViewRange.start.toDate(),
                        end: fssettings.weekViewRange.end.toDate(),
                    } : undefined,
            [UserSettings.LATEST_INSERTED_TEMPLATE]: fssettings.latestInsertedTemplate,
            [UserSettings.LATEST_SELECTED_DEPARTMENT]: fssettings.latestSelectedDepartment,
            [UserSettings.LATEST_SELECTED_ROLE]: fssettings.latestSelectedRole,
            [UserSettings.DISABLED_NOTIFICATIONS]: fssettings.disabledNotifications,
            [UserSettings.SHOW_PROFILE_PICTURES_IN_WEEK_VIEW]: fssettings.showProfilePicturesInWeekView,
            [UserSettings.SHOW_HOURS_IN_WEEK_VIEW]: fssettings.showHoursInWeekView,
            [UserSettings.SHOW_SALARY_IN_WEEK_VIEW]: fssettings.showSalaryInWeekView,
            [UserSettings.SHOW_REVENUE_IN_WEEK_VIEW]: fssettings.showRevenueInWeekView,
            [UserSettings.SHOW_LABOR_PERCENTAGE_IN_WEEK_VIEW]: fssettings.showLaborPercentageInWeekView,
            [UserSettings.LAST_USED_TIMESPAN]: fssettings.lastUsedTimeSpan,
        };
        return settings;
    }

    /**
     * Retrieve a setting from a Firestore Settings object
     * @param setting - The setting to retrieve
     * @param def - The optional default value of the setting (if no setting is found)
     * @param loadedSettings - The loaded firestore settings object
     */
    private retrieveSetting<T extends UserSettings>(setting: T, def?: TypeMap[T], loadedSettings?: FSSettings): TypeMap[T] {
        // Get the default value from the parameter or the hardcoded default if default param is absent
        const defaultValue: TypeMap[T] = def || this.getDefaultValue(setting);

        // If user has no settings return the default value
        if (!loadedSettings) return defaultValue;

        const loadedUserSettings = this.mapFirestoreSettingsToSettings(loadedSettings);

        const getLoadedOrDefault = (setting: T, loadedSetting): TypeMap[T] => (loadedSetting && loadedSetting[setting]) || defaultValue;

        // Switch over the different settings and return setting or the default value if the setting is absent
        return getLoadedOrDefault(setting, loadedUserSettings);
    }

    /**
     * Gets the default value for a given setting
     * @param setting - The setting for which to get the default value
     */
    private getDefaultValue<T extends UserSettings>(setting: T): TypeMap[T] {
        switch (setting) {
            // Switch over the different settings and return its default value
            case UserSettings.LANGUAGE: return DEFAULT_LANGUAGE as TypeMap[T];
            case UserSettings.GROUP_SHIFTS_BY: return DEFAULT_GROUP_SHIFTS_BY as TypeMap[T];
            case UserSettings.SALARY_RANGE: return ({ start: new Date(), end: new Date() }) as TypeMap[T];
            case UserSettings.WEEK_VIEW_RANGE: return DEFAULT_WEEK_VIEW_RANGE as TypeMap[T];
            case UserSettings.LATEST_INSERTED_TEMPLATE: return '' as TypeMap[T];
            case UserSettings.LATEST_SELECTED_DEPARTMENT: return '' as TypeMap[T];
            case UserSettings.LATEST_SELECTED_ROLE: return '' as TypeMap[T];
            case UserSettings.DISABLED_NOTIFICATIONS: return DEFAULT_DISABLED_NOTIFICATIONS as TypeMap[T];
            case UserSettings.SHOW_PROFILE_PICTURES_IN_WEEK_VIEW: return DEFAULT_SHOW_PROFILE_PICTURES_IN_WEEK_VIEW as TypeMap[T];
            case UserSettings.SHOW_HOURS_IN_WEEK_VIEW: return DEFAULT_SHOW_HOURS_IN_WEEK_VIEW as TypeMap[T];
            case UserSettings.SHOW_SALARY_IN_WEEK_VIEW: return DEFAULT_SHOW_SALARY_IN_WEEK_VIEW as TypeMap[T];
            case UserSettings.SHOW_REVENUE_IN_WEEK_VIEW: return DEFAULT_SHOW_REVENUE_IN_WEEK_VIEW as TypeMap[T];
            case UserSettings.SHOW_LABOR_PERCENTAGE_IN_WEEK_VIEW: return DEFAULT_SHOW_LABOR_PERCENTAGE_IN_WEEK_VIEW as TypeMap[T];
            case UserSettings.LAST_USED_TIMESPAN: return DEFAULT_LAST_USED_TIMESPAN as TypeMap[T];
            default: throw new Error('Trying to load unsupported type');
        }
    }

    /**
     * Check whether a setting is restricted
     * @param setting - The setting to check
     */
    private isRestrictedSetting<T extends UserSettings>(setting: UserSettings): Observable<Restricted<T>> {
        return this.injector.get(ConfigurationService).isPro(Package.PRO).pipe(
            switchMap(async (pro: boolean): Promise<Restricted<T>> => {
                if (!pro) {
                    if (setting === UserSettings.SHOW_PROFILE_PICTURES_IN_WEEK_VIEW ||
                        setting === UserSettings.SHOW_HOURS_IN_WEEK_VIEW ||
                        setting === UserSettings.SHOW_SALARY_IN_WEEK_VIEW ||
                        setting === UserSettings.SHOW_REVENUE_IN_WEEK_VIEW ||
                        setting === UserSettings.SHOW_LABOR_PERCENTAGE_IN_WEEK_VIEW
                    ) {
                        // If company is not pro, return restricted and show = false
                        return { restricted: true, restrictedValue: false as UserSettingsTypeMap[T] };
                    }
                }
                if (setting === UserSettings.SHOW_LABOR_PERCENTAGE_IN_WEEK_VIEW) {
                    const showRevenue: boolean = await this.loadSetting(UserSettings.SHOW_REVENUE_IN_WEEK_VIEW).pipe(take(1)).toPromise();
                    if (!showRevenue) {
                        return { restricted: true, restrictedValue: false as UserSettingsTypeMap[T] };
                    }
                }
                // Otherwise return false
                return { restricted: false };
            }),
        );
    }
}
