import { Injectable } from '@angular/core';
import { NavigationEnd } from '@angular/router';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { environment } from '../../../environments/environment.dev';
import { AuthService, AuthUser } from './auth.service';

export declare let ga: Function;

@Injectable()
export class GoogleAnalyticsService {

    constructor(private authService: AuthService) { }

    /**
     * Track a view of a page
     * @param navigationEnd Navigation
     */
    public setPage(navigationEnd: NavigationEnd): void {
        this.ownUser().pipe(take(1)).subscribe((ownUser: boolean) => {
            if (!ownUser || !environment.production) {
                ga('set', 'page', navigationEnd.urlAfterRedirects);
                ga('send', 'pageview');
            }
        });
    }

    /**
     * Track a button click
     * @param name The name of the button
     */
    public trackButtonClick(name: string): void {
        this.eventEmitter('button', name, 'click');
    }

    /**
     * Track a given event in google analytics
     * @param eventCategory The category of the event
     * @param eventAction The action of the event
     * @param eventLabel The label of the event
     * @param eventValue The value of the event
     */
    public eventEmitter(eventCategory: string,
        eventAction: string,
        eventLabel: string | null = null,
        eventValue: number | null = null): void {
        this.ownUser().pipe(take(1)).subscribe((ownUser: boolean) => {
            if (!ownUser || !environment.production) ga('send', 'event', { eventCategory, eventLabel, eventAction, eventValue });
        });
    }

    /**
     * Tells if the user logged in is our own team
     */
    private ownUser(): Observable<boolean> {
        return this.authService.getUser()
            .pipe(
                take(1),
                map((user: AuthUser) => user ? user.email.includes('relion') : false,
                ));
    }
}
