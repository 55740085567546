import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import * as moment from 'moment';
import { extendMoment, MomentRange } from 'moment-range';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ShiftService } from '.';
import { splitShift } from '../../shared/utilities/SalaryUtility';
import { getShiftBreakDuration, getShiftDuration } from '../../shared/utilities/ShiftUtils/durationUtils';
import { Employee, Shift, ShiftBreak } from '../model';
import { Holiday } from '../model/Holiday';
import { isHolidaySalarySupplement, isWeekdaySalarySupplement, SalarySupplement } from '../model/SalarySupplement.model';
import { SalarySupplementService } from './salary-supplement.service';
import { CompanySetting, CompanySettingsService } from './Settings/CompanySettings/CompanySettingsService.model';
const momentRange: MomentRange = extendMoment(moment);

export type EmployeeWithSalary = Employee & { salaryInPeriod: number, hours: number, breaks: number };

@Injectable({
    providedIn: 'root',
})
export class SalaryService {

    constructor(
        private salarySupplementService: SalarySupplementService,
        private companySettingsService: CompanySettingsService,
    ) { }


    /** Returns the number of employees without an hourly wage set, returns -1 if no employees have an hourly wage set */
    public static employeesWithoutSalary = (employees: Employee[]) => {
        let employeesWithoutSalary = employees.filter((employee: Employee) => employee.hourlyWage === null).length;
        if (employees.length === employeesWithoutSalary) {
            employeesWithoutSalary = -1;
        }
        return employeesWithoutSalary;
    }

    public static shiftToSupplement(shift: Shift, supplements: SalarySupplement[], shiftBreak?: ShiftBreak | null): number {
        const getSupplementForShift = (splittedShift: Shift) => {
            const shiftHoliday: Holiday | undefined = ShiftService.shiftToHoliday(splittedShift);
            return _.sum(supplements
                .filter(shiftHoliday ? isHolidaySalarySupplement : isWeekdaySalarySupplement)
                .map((supplement: SalarySupplement) =>
                    isHolidaySalarySupplement(supplement)
                        ? supplement.holidays[shiftHoliday!]
                            ? this.getSalarySupplementDuration(splittedShift, supplement) * supplement.amount
                            : 0
                        : supplement.weekdays[ShiftService.shiftToWeekday(splittedShift)]
                            ? this.getSalarySupplementDuration(splittedShift, supplement) * supplement.amount
                            : 0,
                ));
        };

        return _.sum(splitShift(shift, shiftBreak).map(getSupplementForShift));
    }

    public static calculateShiftSalary(
        employee: Employee | undefined,
        shift: Shift,
        shiftBreak: ShiftBreak | null,
        supplements: SalarySupplement[],
    ): number {
        const wage = (employee ? employee.hourlyWage : shift.employee?.hourlyWage) || 0;
        const shiftDuration = getShiftDuration(shift, shiftBreak);

        return wage !== 0 ? shiftDuration * (wage) + SalaryService.shiftToSupplement(shift, supplements, shiftBreak) : 0;
    }

    /**
     * Takes the given Shift and calculates its salary. You can optionally add a employee to check his specific salary for a shift
     * @param shift you want to calculate the salary from
     * @param employee you want to calculate the salary for on a given shift
     */
    public shiftToSalary(shift: Shift, employee?: Employee): Observable<number> {
        return combineLatest([
            this.companySettingsService.loadSetting(CompanySetting.SHIFT_BREAK),
            this.salarySupplementService.getSalarySupplements(),
        ]).pipe(map(([shiftBreak, supplements]: [ShiftBreak | null, SalarySupplement[]]) =>
            SalaryService.calculateShiftSalary(employee, shift, shiftBreak, supplements)));
    }

    public addSalaryAndHoursToEmployees(
        [employees, shifts]: [Employee[], Shift[]]): Observable<EmployeeWithSalary[]> {
        return combineLatest(
            this.companySettingsService.loadSetting(CompanySetting.SHIFT_BREAK),
            this.salarySupplementService.getSalarySupplements(),
        ).pipe(map(([shiftBreak, supplements]: [ShiftBreak | null, SalarySupplement[]]) => employees.map(
            (employee: Employee) => {
                const employeesShifts = shifts.filter((shift: Shift) => shift.employee?.id === employee.id);
                // Count up all the hours of the employee
                const hours = employeesShifts
                    .reduce((hourSum: number, shift: Shift) =>
                        hourSum + getShiftDuration(shift), 0);
                const breaks = shiftBreak
                    ? employeesShifts
                        .reduce((breakSum: number, shift: Shift) =>
                            breakSum + getShiftBreakDuration(shift, shiftBreak), 0)
                    : 0;
                const totalSupplement = supplements.length && employee.hourlyWage ?
                    _.sum(employeesShifts
                        .map((shift: Shift) => SalaryService.shiftToSupplement(shift, supplements, shiftBreak)))
                    : 0;
                return {
                    ...employee,
                    hours,
                    // The wage can be null, so we check if it is set, if not, we multiply by 0, so no money for you my friend
                    salaryInPeriod: (hours - breaks) * (employee.hourlyWage || 0) + totalSupplement,
                    breaks,
                };
            })));
    }

    /**
     * Calculates the duration a supplement overlaps with a shift and returns the amount in hours
     */
    private static getSalarySupplementDuration(shift: Shift, salarySupplement: SalarySupplement): number {
        const supStart: Date = moment(shift.start).startOf('day').add(salarySupplement.start, 'minutes').toDate();
        const supEnd: Date = moment(shift.start).startOf('day').add(salarySupplement.end, 'minutes').toDate();

        // The overlap between the shift and the supplement
        const supRange = (momentRange.range(moment(supStart), moment(supEnd)));
        const shiftRange = (momentRange.range(moment(shift.start), moment(shift.end)));
        const supplementPeriod = supRange.intersect(shiftRange);

        if (!supplementPeriod) return 0;
        return supplementPeriod.duration('minutes') / 60;
    }
}
