<div class="overlay-container">
    <div class="logo">
        <img src="assets/images/blacklion-nospace.png">
    </div>
    <div class="close">
        <ui-button-icon [basic]="true"
                        (click)="this.close.emit()">
            <i class="far fa-times"></i>
        </ui-button-icon>
    </div>
    <div class="main">
        <ng-content></ng-content>
    </div>
    <div *ngIf="!hideQuote"
         class="bottom">
        <div>
            <img src="assets/images/maria-olsen-bryg-coffee.png"
                 alt="Picture of Maria Theressa Olsen from BRYG Coffee House">
        </div>
        <div class="quoted">
            <div class="name">
                Maria Theressa Olsen
            </div>
            <div class="position">
                Manager @ BRYG Coffee House ApS
            </div>
        </div>
        <div class="quote">
            "{{ 'modal.upgrade-overlay.shared.quote' | translate }}"
        </div>
    </div>
</div>