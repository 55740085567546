import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, Inject, Injector, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BehaviorSubject, fromEvent, Subscription } from 'rxjs';
import { debounceTime, map, take, tap } from 'rxjs/operators';
import { SalaryIntegration, SalaryIntegrationSettings } from '../../../../../../../../../core/model/Integrations/Shared';
import { EmployeeService } from '../../../../../../../../../core/services';
import { CloudFunctionsService } from '../../../../../../../../../core/services/cloud-functions.service';
import { IntegrationSettingsService } from '../../../../../../../../../core/services/Settings/IntegrationSettings/Firestore/integration-settings.service';
import { DanloenIntegrationHelpModalComponent } from '../danloen/danloen-integration-help-modal/danloen-integration-help-modal.component';
import { DanloenIntegrationMatchingModalComponent } from '../danloen/danloen-integration-matching-modal/danloen-integration-matching-modal.component';
import { DataloenIntegrationHelpModalComponent } from '../dataloen/dataloen-integration-help-modal/dataloen-integration-help-modal.component';
import { mapDataloenEmployees } from '../dataloen/dataloen-matching-utils';
import { SalaryDkIntegrationHelpModalComponent } from '../salary-dk/salary-dk-integration-help-modal/salary-dk-integration-help-modal.component';
import { mapSalaryDKEmployees } from '../salary-dk/salary-dk-matching-utils';

export enum ValidatorStatus {
    EMPTY = 1,
    LOADING = 2,
    VALID = 3,
    INVALID = 4,
}

enum IntegrationStep {
    API_KEY = 'api_key',
    MATCHING = 'matching',
}

@Component({
    templateUrl: './integration-modal.component.html',
    styleUrls: ['./integration-modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IntegrationModalComponent implements AfterViewInit, OnDestroy, OnInit {
    public get integrationStep(): IntegrationStep {
        return this._integrationStep;
    }
    public set integrationStep(value: IntegrationStep) {
        this._integrationStep = value;
        if (value === IntegrationStep.MATCHING) this.startMatching();
    }
    @ViewChild('apiInput') public input: ElementRef<HTMLInputElement>;
    public IntegrationStep: typeof IntegrationStep = IntegrationStep;
    public integration: SalaryIntegration;
    public ValidatorStatus: typeof ValidatorStatus = ValidatorStatus;
    public status$: BehaviorSubject<ValidatorStatus>;
    private _integrationStep: IntegrationStep = IntegrationStep.API_KEY;
    private inputFieldChangeSub?: Subscription;
    private validationSub?: Subscription;
    private matchingSub?: Subscription;

    constructor(
        public modalRef: MatDialogRef<IntegrationModalComponent>,
        @Inject(MAT_DIALOG_DATA) public modalData: { integrationSetting: SalaryIntegration },
        private cloudFunctionService: CloudFunctionsService,
        private employeeService: EmployeeService,
        private injector: Injector,
        private integrationSettingsService: IntegrationSettingsService,
        private modal: MatDialog,
    ) {
        this.status$ = new BehaviorSubject(ValidatorStatus.EMPTY);
        this.integration = modalData.integrationSetting;
    }

    public ngOnInit(): void {
        this.integrationSettingsService.loadSetting(this.integration)
            .pipe(take(1))
            .subscribe({
                next: (settings?: SalaryIntegrationSettings) => {
                    this.integrationStep = settings && settings.apiKey && settings.apiKey !== ''
                        ? IntegrationStep.MATCHING
                        : IntegrationStep.API_KEY;
                },
            });
    }

    public ngAfterViewInit(): void {
        // Listen to changes from the input field
        if (this.integrationStep === IntegrationStep.API_KEY) {
            // Listen to changes from the input field
            this.inputFieldChangeSub = fromEvent(this.input.nativeElement, 'input').pipe(
                // Map event to string value of the input field
                map((event: Event) => (<HTMLInputElement> event.target).value),

                // Start loading and cancel the current validation request
                tap((apiKey: string) => {
                    this.status$.next(apiKey === '' ? ValidatorStatus.EMPTY : ValidatorStatus.LOADING);
                    this.validationSub?.unsubscribe();
                }),

                // Send validation request after 2s of no input
                debounceTime(1000),
            ).subscribe({
                next: this.sendValidationRequest.bind(this),
            });
        }
    }

    public ngOnDestroy(): void {
        this.inputFieldChangeSub?.unsubscribe();
        this.matchingSub?.unsubscribe();
    }

    public cancelMatching(): void {
        this.matchingSub?.unsubscribe();
        this.modalRef.close();
    }

    public saveAPIKey(apiKey: string): void {
        this.integrationSettingsService.saveSetting(this.integration, { apiKey })
            .pipe(take(1)).subscribe();
        this.integrationStep = IntegrationStep.MATCHING;
    }

    public whereIsMyKey(): void {
        let component;
        switch (this.integration) {
            case SalaryIntegration.DATALOEN: component = DataloenIntegrationHelpModalComponent;
                break;
            case SalaryIntegration.DANLOEN: component = DanloenIntegrationHelpModalComponent;
                break;
            case SalaryIntegration.SALARYDK: component = SalaryDkIntegrationHelpModalComponent;
                break;
            default: throw Error(`No integration help modal set for ${ this.integration }`);
        }
        this.modal.open(component, { maxWidth: '460px', autoFocus: false });
    }

    private async startMatching(): Promise<void> {
        switch (this.integration) {
            case SalaryIntegration.DATALOEN:
                this.matchingSub = mapDataloenEmployees(this.injector, this.modalRef);
                break;
            case SalaryIntegration.DANLOEN:
                setTimeout(() => this.modalRef.close(), 0);
                this.modal.open(DanloenIntegrationMatchingModalComponent, {
                    width: '808px', maxHeight: '80vh',
                });
                break;
            case SalaryIntegration.SALARYDK:
                this.matchingSub = mapSalaryDKEmployees(
                    this.cloudFunctionService,
                    this.employeeService,
                    this.integrationSettingsService,
                    this.modal,
                    this.modalRef);
                break;
            default: throw Error(`No integration help modal set for ${ this.integration }`);
        }
    }

    /**
     * Send a validation request and update the status$ accordingly
     * @param apiKey - The API key for which to check validity
     */
    private async sendValidationRequest(apiKey: string): Promise<void> {
        // If apiKey is empty, don't send request but emit EMPTY status
        if (apiKey === '') return this.status$.next(ValidatorStatus.EMPTY);

        // Send validation request and update upon response
        const valid = await this.checkIntegrationAPIKey(apiKey);
        this.status$.next(valid ? ValidatorStatus.VALID : ValidatorStatus.INVALID);
    }

    private async checkIntegrationAPIKey(apiKey: string): Promise<boolean> {
        switch (this.integration) {
            case SalaryIntegration.DANLOEN: return this.cloudFunctionService.checkDanloenApiKey(apiKey);
            case SalaryIntegration.DATALOEN: return this.cloudFunctionService.checkDataloenAPIKey(apiKey);
            case SalaryIntegration.SALARYDK: return this.cloudFunctionService.checkSalaryApiKey(apiKey);
            default: throw Error(`No api key check for the given integration: ${ this.integration }`);
        }
    }
}
