import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface InputDialogData {
    titleTranslationKey: string;
    titleTranslationParams?: {};
    subtitleTranslationKey?: string;
    subtitleTranslationParams?: {};
    closeButtonTranslationKey: string;
    closeButtonTranslationParams?: {};
    acceptButtonTranslationKey?: string;
    acceptButtonTranslationParams?: {};
}

@Component({
    selector: 'app-input-dialog',
    templateUrl: './input-dialog.component.html',
    styleUrls: ['./input-dialog.component.scss'],
})
export class InputDialogComponent {

    constructor(
        @Inject(MAT_DIALOG_DATA) public modalData: InputDialogData,
        private modalRef: MatDialogRef<InputDialogComponent>,
    ) { }

    /**
     * Close the modal
     * @param inputText whether you inputed the action in the modal
     */
    public close(inputText?: string): void {
        this.modalRef.close(inputText);
    }
}
