<button mat-icon-button
        [color]="color"
        [class.round]="round"
        [disabled]="disabled"
        [disableRipple]="disableRipple">
    <div>
        <ng-content></ng-content>
    </div>
    <app-spinner [loading]="loading"></app-spinner>
</button>
