import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, TrackByFunction } from '@angular/core';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';
import { Observable, Subscription } from 'rxjs';
import { Employee, Role } from '../../../../../../../core/model';
import { Department } from '../../../../../../../core/model/Department/Department.model';
import { DepartmentImpl } from '../../../../../../../core/model/Department/DepartmentImpl';
import { LazyLoadImageProviderFactory } from '../../../../../../../shared/factories/LazyLoadImageHooks';
import { SearchfieldComponent } from '../../../../../../../shared/ui/searchfield/searchfield.component';
import { includes, removeElement } from '../../../../../../../shared/utilities/ArrayUtils';

interface EmployeesOfDepartmentGroup {
    department: Department | null;
    employees: Employee[];
}

@Component({
    selector: 'app-edit-department-employees',
    templateUrl: './edit-department-employees.component.html',
    styleUrls: ['./edit-department-employees.component.scss'],
    providers: [LazyLoadImageProviderFactory(1)],
})
export class EditDepartmentEmployeesComponent implements OnInit, OnDestroy {
    @Input() public department: Department;
    @Input() public primary: boolean;
    @Input() public employees$: Observable<Employee[]>;
    @Input() public roles$: Observable<Role[]>;
    @Output() public updatedEmployee: EventEmitter<Employee>;

    public menuOpen: boolean = false;
    // Maps the employee id to the previous department and previous roles.
    public previousDepartmentForEmployee: Map<string, { department: Department | null, roles: Role[] }>;
    // The employees of this department
    public departmentEmployees: Employee[];
    // Employees grouped by primary department
    public groupedEmployees: EmployeesOfDepartmentGroup[];
    public searchResultEmployees: Employee[];
    private subscriptions: Subscription[];

    constructor(
    ) {
        this.groupedEmployees = [];
        this.searchResultEmployees = [];
        this.previousDepartmentForEmployee = new Map();
        this.updatedEmployee = new EventEmitter;
    }

    public ngOnInit(): void {
        this.subscriptions = [
            this.employees$.subscribe({
                next: (emps: Employee[]): void => {
                    this.departmentEmployees = this.primary ?
                        emps.filter((emp: Employee) => emp.primaryDepartment && emp.primaryDepartment.id === this.department.id) :
                        emps.filter((emp: Employee) => (!emp.primaryDepartment || emp.primaryDepartment.id !== this.department.id)
                            && emp.departments.find((dep: Department) => this.department.id === dep.id));
                    this.updateEmployeeSearchList(emps);
                },
            }),
        ];
    }

    public ngOnDestroy(): void {
        this.subscriptions.map((sub: Subscription) => sub.unsubscribe());
    }

    public trackByEmployee: TrackByFunction<Employee> = (_index: number, employee: Employee) => employee.id;
    public trackByEmployeesOfDepartmentGroup: TrackByFunction<EmployeesOfDepartmentGroup> = (_index: number, group: EmployeesOfDepartmentGroup) => group.department?.id + '--' + group.employees.map(employee => employee.id).join('-');

    /**
     * Focuses the searchbar and opens the dropdown
     * @param dropdown The dropdown to open
     * @param searchField The searchfield to focus
     */
    public focusSearchbar(dropdown: NgbDropdown, searchField: SearchfieldComponent<Employee>): void {
        dropdown.open();
        searchField.focusInput();
    }

    /**
     * Adds the departments to the employee and saves it
     * @param emp The employee to add to the department
     * @param primary A boolean describing whether we are adding the department as the primary or secondary
     */
    public addToDepartment(emp: Employee, department: Department, primary: boolean): Employee {
        const newEmp: Employee = _.clone(emp);
        if (!includes(newEmp.departments, department)) newEmp.departments = [...newEmp.departments, department];
        if (primary) newEmp.primaryDepartment = department;
        return newEmp;
    }

    /**
     * Returns a copy of the given employee without the given department
     * @param emp The employee to remove from the department
     * @param department The department to remove the employee from
     */
    public removeFromDepartment(emp: Employee, department: Department): Employee {
        let newEmp: Employee = _.clone(emp);
        if (newEmp.primaryDepartment && newEmp.primaryDepartment.id === department.id) newEmp.primaryDepartment = null;
        newEmp.departments = removeElement(newEmp.departments, department);
        newEmp = this.updateRoles(newEmp, department, []);
        return newEmp;
    }
    /**
     * Returns a copy of the given employee, where it has been moved to the given department
     * @param emp The employee to move to the given department
     * @param newDepartment The department to move the given employee to
     */
    public moveToDepartment(emp: Employee, newDepartment: Department): Employee {
        const newEmp: Employee = _.clone(emp);
        if (!includes(newEmp.departments, newDepartment)) newEmp.departments = [...newEmp.departments, newDepartment];
        if (newEmp.primaryDepartment) {
            this.previousDepartmentForEmployee.set(
                newEmp.id,
                {
                    department: newEmp.primaryDepartment,
                    roles: newEmp.roles.filter((role: Role) =>
                        newEmp.primaryDepartment && newEmp.primaryDepartment.id === role.department.id),
                });
        }
        newEmp.primaryDepartment = newDepartment;
        return newEmp;
    }

    /**
     * Returns a copy of the employee, where it has been moved back to the previous department, if any
     * @param emp The employee to move back to the previous department
     */
    public moveToPreviousDepartment(emp: Employee): Employee {
        let newEmp: Employee = _.clone(emp);
        const prev: { department: Department | null, roles: Role[] } | undefined = this.previousDepartmentForEmployee.get(newEmp.id);
        if (prev !== undefined) {
            if (newEmp.primaryDepartment) newEmp.departments = removeElement(newEmp.departments, newEmp.primaryDepartment);
            newEmp.primaryDepartment = prev.department;
            if (prev.department) newEmp = this.updateRoles(newEmp, prev.department, prev.roles);
            this.previousDepartmentForEmployee.delete(newEmp.id);
        }
        return newEmp;
    }

    /**
     * Combines a list of variables into a single string to allow searching in
     * one of the provided fields.
     *
     * roleString: Maps the list of roles into being the names of each role, and reduces them all into
     * a single string with an empty space between names.
     */
    public getSearchString: (emp: Employee) => string = emp => {
        const roleString: string = emp.roles.reduce((roleNames: string, role: Role) => roleNames + ' ' + role.name, '');

        return emp.firstname + ' '
            + emp.lastname + ' '
            + roleString + ' '
            + emp.phone;
    }

    /**
     * Updates the selected roles on an employee for the department
     * @param selectedRoles An array of roles given as the selected roles
     */
    public updateRoles(emp: Employee, department: Department, selectedRoles?: Role[]): Employee {
        if (!selectedRoles) return emp;
        const newEmp: Employee = _.clone(emp);
        const otherRoles: Role[] = newEmp.roles.filter((role: Role) => role.department.id !== department.id);
        newEmp.roles = [...otherRoles, ...selectedRoles];
        return newEmp;
    }

    /**
     * Saves the employee and emits it to the parent element
     * @param emp The employee to save
     */
    public saveEmployee(emp: Employee): void {
        this.updatedEmployee.emit(emp);
    }

    /**
     * Builds a map with employees split into groups depending on primary department
     * @param employees The employees to split into groups
     */
    private buildEmployeeGroups(employees: Employee[], selectedDepartment: Department): EmployeesOfDepartmentGroup[] {
        const departmentEmployeeMap: Map<Department | null, Employee[]> = new Map();
        employees.forEach(((emp: Employee): void => {
            const primaryDepartment: Department | null = emp.primaryDepartment;

            const employeesOfDepartment: Employee[] = departmentEmployeeMap.get(primaryDepartment) || [];

            departmentEmployeeMap.set(primaryDepartment, [...employeesOfDepartment, emp]);
        }));

        const employeeGroups: EmployeesOfDepartmentGroup[] = [];

        departmentEmployeeMap.forEach((value: Employee[], key: Department) =>
            (employeeGroups.push({ department: key, employees: value })),
        );

        return this.sortEmployeeGroups(employeeGroups, selectedDepartment);
    }

    /**
     * This sorts the employee groups depending on department, where
     * @param employeeGroups The employee groups to sort
     * @param selectedDepartment The currently selected department
     */
    private sortEmployeeGroups(employeeGroups: EmployeesOfDepartmentGroup[], selectedDepartment: Department): EmployeesOfDepartmentGroup[] {
        const newGroups: EmployeesOfDepartmentGroup[] = _.clone(employeeGroups);
        newGroups.sort((
            a: EmployeesOfDepartmentGroup,
            b: EmployeesOfDepartmentGroup) => {
            if (a.department === null || a.department.id === selectedDepartment.id) return -1;
            else if (b.department) return DepartmentImpl.sortByName(a.department, b.department);
            else return 1;
        });

        return newGroups;
    }

    /**
     * Updates the employee search list
     * @param emps The updated employees to distribute
     */
    private updateEmployeeSearchList(emps: Employee[]): void {
        this.searchResultEmployees = _.clone(emps);
        this.groupedEmployees = this.groupedEmployees.length === 0
            ? this.buildEmployeeGroups(emps, this.department)
            : this.updateEmployeeGroups(emps, this.groupedEmployees);
    }


    /**
     * Updates the grouped employees with new employees
     * @param newEmps The updated employees to distribute
     */
    private updateEmployeeGroups(newEmps: Employee[], empGroups: EmployeesOfDepartmentGroup[]): EmployeesOfDepartmentGroup[] {
        const newGroups: EmployeesOfDepartmentGroup[] = _.clone(empGroups);
        newGroups.forEach((group: EmployeesOfDepartmentGroup) =>
            group.employees.forEach((emp: Employee, index: number) => {
                const newEmp: Employee | undefined = newEmps.find((val: Employee) => val.id === emp.id);
                if (newEmp) group.employees[index] = newEmp;
            }),
        );
        return newGroups;
    }
}
