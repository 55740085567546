import { Injectable } from '@angular/core';
import { take } from 'rxjs/operators';
import { AuthService, AuthUser } from '.';
import { GoogleAnalyticsService } from './google-analytics.service';

@Injectable({
    providedIn: 'root',
})
export class CalendarSyncService {

    constructor(
        private authService: AuthService,
        private gas: GoogleAnalyticsService,
    ) { }

    public async openCalendarSync(): Promise<void> {
        const authUser: AuthUser | null = await this.authService.getUser().pipe(take(1)).toPromise();
        if (!authUser) throw Error('Trying to get calendar link for unauthenticated user');

        const userId: string = authUser.user_id;
        const iCalFeed: string = 'webcal://europe-west1-smalender-relion.cloudfunctions.net/calendar?employeeID=' + userId;

        const iOS: boolean = /iPad|iPhone|iPod/.test(navigator.userAgent);
        const macOS: boolean = navigator.platform.indexOf('Mac') > -1;

        if (iOS || macOS) window.location.href = iCalFeed;
        else window.open('https://calendar.google.com/calendar/render?cid=' + iCalFeed, '_blank');
        this.gas.trackButtonClick('calendar-sync');
    }
}
