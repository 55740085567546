import { Pipe, PipeTransform } from '@angular/core';
import { ValidationErrors } from '@angular/forms';

@Pipe({
    name: 'getErrorString',
})
export class GetErrorStringPipe implements PipeTransform {

    /**
     * Returns a validation error message based on the given errors
     * @param errors validation errors that specifies which error string to return
     */
    public transform(errors: ValidationErrors | null): string {
        if (errors) {
            if (errors.required) return 'Dette felt er krævet';
            if (errors.notFullName) return 'Indtast både fornavn og efternavn';
            if (errors.noMatch) return 'Dit password skal være det samme som foroven';
            if (errors.badPassword) return 'Min. 8 tegn, herunder et tal, et lille bogstav og et stort bogstav';
            if (errors.invalidVAT) return 'Indtast et gyldigt CVR-nummer bestående af 8 tal';
            if (errors.invalidPhone) return 'Indtast et gyldigt telefonnr. bestående af 8 tal';
            if (errors.email) return 'Indtast en gyldig email';
        }
        return '';
    }
}
