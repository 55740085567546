import { Component, Injector, Input, OnInit } from '@angular/core';
import { combineLatest, Observable, of, ReplaySubject } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { Day, Employee, Role, Shift, ShiftForSale } from '../../../core/model';
import { Package } from '../../../core/model/Freemium/Package';
import { GroupShiftsBy } from '../../../core/model/UserSettings';
import { AuthService, EmployeeService, ShiftForSaleService, ShiftService } from '../../../core/services';
import { ConfigurationService } from '../../../core/services/Configuration/configuration-service.model';
import { DayService } from '../../../core/services/day.service';
import { SalaryService } from '../../../core/services/salary.service';
import { shouldBeApproved } from '../../utilities/PunchclockUtils';

@Component({
    selector: 'app-day-shift-card',
    templateUrl: './day-shift-card.component.html',
    styleUrls: ['./day-shift-card.component.scss'],
})
export class DayShiftCardComponent implements OnInit {
    @Input()
    public get shift(): Shift {
        return this._shift;
    }
    public set shift(value: Shift) {
        this._shift = value;
        this.shiftSubject.next(value);
    }
    @Input() public groupShiftsBy: GroupShiftsBy;
    @Input() public hoverable: boolean;
    @Input() public smallFont: boolean;
    @Input() public faded: boolean;
    @Input() public active: boolean;

    public GroupShiftsBy: typeof GroupShiftsBy = GroupShiftsBy;
    public shiftForSale$: Observable<ShiftForSale | null>;
    public potentialSalary$: Observable<number>;
    public shouldBeApproved$: Observable<boolean>;
    public day$: Observable<Day>;

    public admin$: Observable<boolean>;
    public availableShift$: Observable<boolean>;
    public isPro$: Observable<boolean>;
    public shouldShowComment$: Observable<boolean>;

    private authEmployee$: Observable<Employee>;
    private _shift: Shift;
    private shiftSubject: ReplaySubject<Shift> = new ReplaySubject();

    constructor(
        private authService: AuthService,
        private configurationService: ConfigurationService,
        private dayService: DayService,
        private employeeService: EmployeeService,
        private salaryService: SalaryService,
        private injector: Injector,
        private shiftForSaleService: ShiftForSaleService,
        private shiftService: ShiftService,
    ) { }

    public ngOnInit(): void {
        this.authEmployee$ = this.employeeService.getAuthenticatedEmployee();
        this.potentialSalary$ = this.authEmployee$.pipe(
            switchMap((employee: Employee) => this.salaryService.shiftToSalary(this.shift, employee)));

        this.shiftForSale$ = this.shiftForSaleService.getShiftForSaleByShift(this.shift);

        this.day$ = this.dayService.getDay(this.shift.start, this.shift.role.department);

        this.admin$ = this.authService.userIsAdmin();
        this.availableShift$ = combineLatest([
            this.admin$,
            this.authEmployee$,
        ]).pipe(switchMap(this.isShiftAvailable.bind(this)));

        this.isPro$ = this.configurationService.isPro(Package.PRO);

        this.shouldBeApproved$ = shouldBeApproved(this.shiftSubject.asObservable(), this.injector);

        this.shouldShowComment$ = combineLatest([this.authEmployee$, this.admin$]).pipe(
            map(([employee, isAdmin]: [Employee, boolean]) => !this.shift.employee || this.shift.employee?.id === employee.id || isAdmin));

        if (!this.groupShiftsBy) throw Error('GroupShiftsBy property must be set');
    }

    private isShiftAvailable([admin, employee]: [boolean, Employee]): Observable<boolean> {
        // Check if logged in user has the right role
        const employeeHasRole = !!employee.roles.find((role: Role) => role.id === this.shift.role.id);

        // If user is admin or doesn't have the right role, return false
        if (this.shift.employee || admin || !employeeHasRole) return of(false);

        // Otherwise, check if there are overlapping shifts for this employee and return accordingly
        return this.shiftService.noOverlappingShifts(this.shift, employee);
    }
}
