import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { CompanySetting, CompanySettingsService } from '../../../../../../../../core/services';

@Component({
    selector: 'app-auto-punch-approval-settings',
    templateUrl: './auto-punch-approval-settings.component.html',
    styleUrls: ['./auto-punch-approval-settings.component.scss'],
})
export class AutoPunchApprovalSettingsComponent implements OnInit {

    @ViewChild('settingPopover') public settingPopover: NgbPopover;
    public toggleEnabled: boolean;
    public autoApproveRange: string;
    public autoApproveRange$: Observable<number | null>;
    private defaultAutoApproveRange: string = '10';

    constructor(
        private companySettingsService: CompanySettingsService,
    ) { }

    public ngOnInit(): void {
        this.autoApproveRange$ = this.companySettingsService.loadSetting(CompanySetting.AUTO_APPROVE_PUNCH_TIMES);

        this.autoApproveRange$.pipe(take(1)).subscribe({
            next: (autoApproveRange: number | null) => {
                if (autoApproveRange === null) {
                    this.autoApproveRange = this.defaultAutoApproveRange;
                } else {
                    this.autoApproveRange = String(autoApproveRange);
                    this.toggleEnabled = true;
                }
            },
        });
    }

    public changeToggleState(newState: boolean): void {
        if (newState !== this.toggleEnabled && newState) {
            this.settingPopover.open();
        } else if (!newState) {
            this.disableAutoApprove();
        }

        this.toggleEnabled = newState;
    }

    public save(): void {
        const autoApproveRange: number = Number(this.autoApproveRange);
        if (autoApproveRange > 0) {
            this.companySettingsService.saveSetting(CompanySetting.AUTO_APPROVE_PUNCH_TIMES, autoApproveRange);
            this.toggleEnabled = true;
        } else this.changeToggleState(false);
    }

    private disableAutoApprove(): void {
        this.companySettingsService.saveSetting(CompanySetting.AUTO_APPROVE_PUNCH_TIMES, null);
    }
}
