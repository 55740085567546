import { Injector } from '@angular/core';
import { take } from 'rxjs/operators';
import { Shift } from '../../../core/model/Shift.model';
import { CompanyService } from '../../../core/services';
import { CloudFunctionsService } from '../../../core/services/cloud-functions.service';
import { ModalService } from '../../../core/services/ModalService.model';
import { ConfirmDialogType } from '../../ui/confirm-dialog/ConfirmDialogType';
import { mapShiftToFirestoreshift } from '../ShiftUtils/mapShiftToFirestoreshift';


export async function notifyEmployeesOnShiftRelease(shifts: Shift[], injector: Injector): Promise<void> {
    const cloudFunctionsService = injector.get(CloudFunctionsService);
    const firestoreShifts = shifts.map(mapShiftToFirestoreshift);
    const companyID = (await injector.get(CompanyService).getCurrentCompany().pipe(take(1)).toPromise()).id;
    try {
        await cloudFunctionsService.notifyEmployeesOnShiftRelease(firestoreShifts, companyID);
    } catch (error) {
        const tryAgain = await injector.get(ModalService).openConfirmDialog({
            confirmDialogType: ConfirmDialogType.WARNING,
            titleTranslationKey: 'error.unable-to-send-notification',
            subtitleTranslationKey: 'error.unable-to-send-notification-subtitle',
            closeButtonTranslationKey: 'shared.avoid-sending',
            acceptButtonTranslationKey: 'shared.try-again',
        }).pipe(take(1)).toPromise();
        if (tryAgain) notifyEmployeesOnShiftRelease(shifts, injector);
    }
}
