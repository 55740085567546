import { Injector } from '@angular/core';
import * as moment from 'moment';
import { combineLatest, Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { Employee, Shift } from '../../core/model';
import { AuthService, CompanySetting, CompanySettingsService, EmployeeService } from '../../core/services';
export interface PunchclockConfig {
    autoPunchClockEnabled: boolean;
    checkinBefore: number | null;
    checkoutUntil: number | null;
    manualPunchclock: boolean;
}

/**
 * Validates strings in format HH:mm from 00:00 to 23:59
 */
export const validTimeInput = new RegExp('^([0-1][0-9]|2[0-3]):[0-5][0-9]$');

/**
 * Get the punchclock settings from the settingsservice
 */
export function getPunchClockSettings(companySettingsService: CompanySettingsService): Observable<PunchclockConfig> {
    return combineLatest([
        companySettingsService.loadSetting(CompanySetting.PUNCHCLOCK_ENABLED_ON),
        companySettingsService.loadSetting(CompanySetting.MANUAL_PUNCHCLOCK),
        companySettingsService.loadSetting(CompanySetting.CHECKIN_BEFORE),
        companySettingsService.loadSetting(CompanySetting.CHECKOUT_UNTIL),
    ]).pipe(
        map(([enabledOn, manual, checkinBefore, checkoutUntil]: [Date | null, boolean | null, number | null, number | null]) => ({
            autoPunchClockEnabled: !!enabledOn,
            checkinBefore,
            checkoutUntil,
            manualPunchclock: !!manual,
        })),
        shareReplay(1),
    );
}

export function shouldBeApproved(shift$: Observable<Shift>, injector: Injector): Observable<boolean> {
    return combineLatest([
        shift$,
        injector.get(CompanySettingsService)
            .loadSetting(CompanySetting.PUNCHCLOCK_ENABLED_ON),
        injector.get(CompanySettingsService)
            .loadSetting(CompanySetting.CHECKOUT_UNTIL),
        injector.get(CompanySettingsService)
            .loadSetting(CompanySetting.MANUAL_PUNCHCLOCK),
        injector.get(EmployeeService).getAuthenticatedEmployee(),
        injector.get(AuthService).userIsAdmin(),
    ]).pipe(
        map(([shift, punchclockEnabled, checkoutUntil, manualPunchclock, employee, admin]
            : [Shift, Date | null, number | null, boolean | null, Employee, boolean]) => {
            if (!shift.released) return false;
            if (shift.approval || shift.approved) return true;
            const actualShiftEndTime = moment(shift.end)
                .add((!!punchclockEnabled && checkoutUntil) || 0, 'minutes').toDate();
            return (!!punchclockEnabled || !!manualPunchclock)
                && (actualShiftEndTime.getTime() < Date.now() || !!shift.checkout)
                && !!(employee.id === shift.employee?.id || admin);
        }));
}
