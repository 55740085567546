import { AfterViewInit, ChangeDetectionStrategy, Component, Input } from '@angular/core';

export interface Step {
    title: string;
    description: string;
    clickAction?: () => unknown;
}

@Component({
    selector: 'ui-stepper',
    templateUrl: './stepper.component.html',
    styleUrls: ['./stepper.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StepperComponent implements AfterViewInit {

    @Input() public steps: Step[];
    @Input() public activeStep: number;

    constructor() {
        this.steps = [];
    }

    public ngAfterViewInit(): void {
        if (this.steps.length === 0) throw Error('No steps were given to the stepper component');
        this.setStepsMaxWidth();
    }

    public onStepClick(action?: () => unknown): void {
        if (!!action) action();
    }

    /**
     * Sets the max width of the step elements, based on the amount of steps.
     */
    private setStepsMaxWidth(): void {
        const stepElements: HTMLCollectionOf<Element> = document.getElementsByClassName('step');
        const numberOfSteps: number = this.steps.length;

        const stepMaxWidth: number = 1 / numberOfSteps;

        for (let index: number = 0; index < stepElements.length; index++) {
            (<HTMLDivElement> stepElements[index]).style.maxWidth = stepMaxWidth * 100 + '%';
        }
    }
}
