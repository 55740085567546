<div class="shift-detail-modal"
     *ngIf="shift$ | async as shift">
    <ng-container *ngIf="state === State.EDIT">
        <app-shift-edit [shift]="shift"
                        [dismiss]="dismissFunction"
                        (shiftChange)="shiftEdited($event)"></app-shift-edit>
    </ng-container>
    <ng-container *ngIf="state === State.VIEW">
        <div class="d-flex flex-column h-100">
            <div class="detail-header">
                <div class="top d-flex flex-row align-items-center">
                    <i (click)="dismiss()"
                       class="close-icon far"
                       [ngClass]="dialogRef ? 'fa-chevron-down' : 'fa-arrow-left'"></i>
                    <div class="d-flex flex-row align-items-center name-container"
                         *ngIf="shift.employee">
                        <img [lazyLoad]="shift.employee!.imageURL">
                        <div class="dot-dot-dot">{{shift.employee!.firstname + ' ' + shift.employee!.lastname}}</div>
                    </div>
                    <div *ngIf="!shift.employee">
                        {{'admin.calendar.free-shift' | translate}}
                    </div>
                </div>
                <app-full-shift-card (click)="goToDaySchedule(shift)"
                                     [shift]="shift"></app-full-shift-card>
            </div>
            <overlay-scrollbars class="detail-content"
                                [options]="{scrollbars: {visibility: 'hidden'}}">
                <div *ngIf="isAdmin$ | async"
                     class="edit-button-container">
                    <ui-button-standard [wide]="true"
                                        (click)="state = State.EDIT">{{ 'modal.shift-detail.edit-shift' | translate }}
                    </ui-button-standard>
                </div>
                <overlay-scrollbars [options]="{scrollbars: {visibility: 'hidden'}}">
                    <ng-container *ngIf="(otherShiftsOnDay$ | async) as otherShiftsOnDay">
                        <div *ngIf="otherShiftsOnDay.length > 0"
                             class="other-shifts-container d-flex flex-row">
                            <div *ngFor="let shift of otherShiftsOnDay"
                                 class="shift-on-day d-flex flex-column justify-content-center align-items-center"
                                 (click)="openShiftDetail(shift)">
                                <img [lazyLoad]="shift.employee!.imageURL">
                                <div class="name-container d-flex flex-row align-items-center">
                                    <div class="role-dot"
                                         [class]="shift.role.color"></div>
                                    <div class="name dot-dot-dot">
                                        {{ shift.employee!.id === (user$ | async)?.user_id
                                        ? ('shared.yourself' | translate)
                                        : shift.employee!.firstname }}
                                    </div>
                                </div>
                                <div class="status"
                                     [ngSwitch]="shift | shiftStatus : (punchclockEnabled$ | async) || false">
                                    <div class="active-shift"
                                         *ngSwitchCase="ShiftStatus.CHECKED_IN">
                                        {{ 'modal.shift-detail.checked-in' | translate }}
                                    </div>
                                    <div class="active-shift"
                                         *ngSwitchCase="ShiftStatus.WORKING">
                                        {{'modal.shift-detail.working' | translate }}
                                    </div>
                                    <div class="inactive-shift"
                                         *ngSwitchCase="ShiftStatus.NOT_WORKING">
                                        {{shift.start | date:'H:mm'}} - {{shift.end | date:'H:mm' | convertMidnight}}
                                    </div>
                                    <div class="inactive-shift"
                                         *ngSwitchCase="ShiftStatus.CHECKED_OUT">
                                        {{ 'modal.shift-detail.checked-out' | translate }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </overlay-scrollbars>
                <ng-container *ngIf="(isAdmin$ | async) || (isMyShift$ | async) || (isShiftAvailable$ | async)">
                    <div *ngIf="shift.comment as comment"
                         class="detail-shift-comment d-flex flex-row">
                        <img [lazyLoad]="comment!.sender.imageURL">
                        <div>
                            <div class="name">{{comment!.sender.firstname + ' ' + comment!.sender.lastname}}</div>
                            <div class="subtext">{{'modal.shift-detail.shift-comment' | translate }} ·
                                {{comment!.time | amTimeAgo}}
                            </div>
                            <div class="text respect-newlines">{{comment!.message}}</div>
                        </div>
                    </div>
                </ng-container>
                <div *ngIf="(day$ | async)?.note as comment"
                     class="detail-day-comment d-flex flex-row">
                    <img [lazyLoad]="comment!.sender.imageURL">
                    <div>
                        <div class="name">{{comment!.sender.firstname + ' ' + comment!.sender.lastname}}</div>
                        <div class="subtext">{{ 'modal.shift-detail.day-comment' | translate }} ·
                            {{comment!.time | amTimeAgo}}
                        </div>
                        <div class="text respect-newlines">{{comment!.message}}</div>
                    </div>
                </div>
                <app-export-logs
                        *ngIf="shift.exportLogs.length > 0"
                        [shift]="shift"
                        iconClass="fas fa-check">
                </app-export-logs>
                <ng-container *ngIf="(user$ | async) as user">
                    <ng-container *ngIf="shift.checkin?.source === PunchSource.MANUAL">
                        <ng-container [ngTemplateOutlet]="autoPunchTemplate"
                                      [ngTemplateOutletContext]="{checkin: shift.checkin!.overwriting, checkout: shift.checkout!.overwriting}">
                        </ng-container>
                        <div *ngIf="(shift.employee)"
                             class="detail-checkin d-flex flex-row">
                            <img [lazyLoad]="shift.employee!.imageURL">
                            <div>
                                <div class="name">{{shift.employee!.firstname + ' ' + shift.employee!.lastname}}</div>
                                <div class="subtext">{{ 'punch-clock.changed-their-times-to' | translate }}
                                    {{shift.checkin!.time | date: 'H:mm'}} -
                                    {{shift.checkout!.time | date: 'H:mm'}}
                                </div>
                                <div *ngIf="shift.employee?.id === user!.user_id || user!.admin"
                                     class="text respect-newlines">{{shift.checkout?.message}}</div>
                            </div>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="shift.checkin?.source === PunchSource.AUTOMATIC"
                                  [ngTemplateOutlet]="autoPunchTemplate"
                                  [ngTemplateOutletContext]="{checkin: shift.checkin, checkout: shift.checkout}">
                    </ng-container>
                    <ng-template #autoPunchTemplate
                                 let-checkin="checkin"
                                 let-checkout="checkout">
                        <div *ngIf="(shift.employee && checkin)"
                             class="detail-checkin d-flex flex-row">
                            <img [lazyLoad]="shift.employee!.imageURL">
                            <div>
                                <div class="name">{{shift.employee!.firstname + ' ' + shift.employee!.lastname}}</div>
                                <div class="subtext">{{ 'modal.shift-detail.checkin' | translate }} ·
                                    {{checkin!.time | date: 'H:mm'}}
                                </div>
                                <div *ngIf="shift.employee?.id === user!.user_id || user!.admin"
                                     class="text respect-newlines">{{checkin?.message}}</div>
                            </div>
                        </div>
                        <div *ngIf="(shift.employee && checkout)"
                             class="detail-checkout d-flex flex-row">
                            <img [lazyLoad]="shift.employee!.imageURL">
                            <div>
                                <div class="name">{{shift.employee!.firstname + ' ' + shift.employee!.lastname}}</div>
                                <div class="subtext">{{ 'modal.shift-detail.checkout' | translate }} ·
                                    {{checkout!.time | date: 'H:mm'}}
                                </div>
                                <div *ngIf="shift.employee?.id === user!.user_id || user!.admin"
                                     class="text respect-newlines">{{checkout?.message}}</div>
                            </div>
                        </div>
                    </ng-template>
                </ng-container>
            </overlay-scrollbars>
        </div>
        <app-shift-detail-action [shift]="shift"></app-shift-detail-action>
    </ng-container>
</div>