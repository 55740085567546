import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Employee } from '../../../core/model';

@Component({
    selector: 'app-employee-contact-modal',
    templateUrl: './employee-contact-modal.component.html',
    styleUrls: ['./employee-contact-modal.component.scss'],
})
export class EmployeeContactModalComponent {
    public smsLink: SafeUrl;
    public phoneLink: SafeUrl;

    constructor(
        @Inject(MAT_DIALOG_DATA) public employee: Employee,
        private sanitizer: DomSanitizer,
    ) {
        this.smsLink = this.sanitizer.bypassSecurityTrustUrl('sms:' + employee.phone);
        this.phoneLink = this.sanitizer.bypassSecurityTrustUrl('tel:' + employee.phone);
    }
}
