import { Injectable } from '@angular/core';
import { ActiveState, EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { Department } from '../../../model/Department/Department.model';

export interface DepartmentState extends EntityState<Department, string>, ActiveState<string> { }

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'department' })
export class DepartmentStore extends EntityStore<DepartmentState> {

    constructor() {
        super();
    }

}
