import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import { Shift } from '../../core/model';
import { SalaryService } from '../../core/services/salary.service';

@Pipe({
    name: 'shiftToSalary',
})
export class ShiftToSalaryPipe implements PipeTransform {

    constructor(
        private salaryService: SalaryService,
    ) { }

    public transform(shift: Shift): Observable<number> {
        return this.salaryService.shiftToSalary(shift);
    }
}
