
export enum Weekday {
    MONDAY = 'shared.monday',
    TUESDAY = 'shared.tuesday',
    WEDNESDAY = 'shared.wednesday',
    THURSDAY = 'shared.thursday',
    FRIDAY = 'shared.friday',
    SATURDAY = 'shared.saturday',
    SUNDAY = 'shared.sunday',
}
