<div class="widget-container"
     [class.fullscreen]="isFullScreen"
     [class.smallscreen]="!isFullScreen">
    <div class="widget-header">
        <div class="widget-title-icon">
            <i *ngIf="iconClass"
               class="{{iconClass}} {{iconBgClass}}"></i>
            <span class="widget-title">{{title}}</span>
        </div>


        <div class="top-right-element"
             *ngIf="nonExpandable == null">
            <ng-content *ngIf="isBigScreen"
                        select=".top-right">
            </ng-content>
            <ui-button-text *ngIf="!isBigScreen"
                            color='primary'
                            (click)="isFullScreen = !isFullScreen">
                {{ isFullScreen ? 'Luk' : 'Vis alle (' + totalItems + ')'}}
            </ui-button-text>
        </div>

    </div>

    <div class="widget-content">
        <ng-content *ngIf="!isBigScreen && !isFullScreen"
                    select=".collapsed"> </ng-content>

        <ng-content *ngIf="isBigScreen || isFullScreen"
                    select=".expanded"> </ng-content>
    </div>
</div>