<div class="shift-divider"
     [class.faded]="faded"
     [class.role-divider]="groupShiftsBy === GroupShiftsBy.ROLE">
    <ng-container *ngIf="groupShiftsBy === GroupShiftsBy.ROLE">
        <div class="role"
             [ngClass]="firstShift.role.color"></div>
        {{ firstShift.role.name }}
    </ng-container>
    <ng-container *ngIf="groupShiftsBy === GroupShiftsBy.TIME">
        {{ firstShift.start | date: 'H:mm' }} - {{ firstShift.end | date: 'H:mm' | convertMidnight }}
    </ng-container>
</div>