import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { combineLatest, from, Observable, of, ReplaySubject, Subject, timer } from 'rxjs';
import { catchError, filter, map, switchMap, take, takeUntil } from 'rxjs/operators';
import { CustomErrorCode } from '../../../../../../functions/src/errorHandling/models';
import { Company, Employee } from '../../../../core/model';
import { FenerumAccount } from '../../../../core/model/Fenerum/FenerumAccount';
import { FenerumSubscriptionTerms } from '../../../../core/model/Fenerum/FenerumSubscriptionTerms';
import { BillingPeriod } from '../../../../core/model/Freemium';
import { Package } from '../../../../core/model/Freemium/Package';
import { CompanyService, EmployeeService } from '../../../../core/services';
import { CloudFunctionsService } from '../../../../core/services/cloud-functions.service';
import { ModalService } from '../../../../core/services/ModalService.model';
import { CreateFenerumAccountRequest } from '../../../utilities/CloudFunctionUtils';
import { getActiveCard, hasActiveCard } from '../../../utilities/FenerumUtility';
import { CustomValidators, splitName } from '../../../utilities/FormUtils';

enum PaymentState {
    INFO,
    ADDING_CARD,
    CREATING_SUBSCRIPTION,
    DONE,
}

@Component({
    templateUrl: './pro-upgrade-overlay.component.html',
    styleUrls: ['./pro-upgrade-overlay.component.scss'],
})
export class ProUpgradeOverlayComponent implements OnInit {
    public loadingCardUrl: boolean;
    public terms: FenerumSubscriptionTerms;

    public get yearlySubscription(): boolean {
        return this._yearlySubscription;
    }
    public set yearlySubscription(value: boolean) {
        this._yearlySubscription = value;
        this.pricePerMonthSubject.next(this.pricesPerMonth[value ? BillingPeriod.YEAR : BillingPeriod.MONTH]);
    }
    public PaymentState: typeof PaymentState = PaymentState;
    public paymentState: PaymentState = PaymentState.INFO;

    public formGroup: FormGroup;
    public employeeName: string;
    public company: Company;
    public loading: boolean;
    public hideInputForm: boolean = true;
    public goingToPayment: boolean;
    public pricePerMonth$: Observable<number>;
    public totalPrice$: Observable<number>;
    public employeeCount$: Observable<number>;

    private cardUrl: string;
    private fenerumAccount: FenerumAccount | null;
    private pricePerMonthSubject: Subject<number>;
    private pricesPerMonth: { [key in BillingPeriod]: number };
    private paymentCancelled$: Observable<void>;
    private paymentCancelledSubject: Subject<void>;
    private _yearlySubscription: boolean = true;

    constructor(
        private employeeService: EmployeeService,
        private companyService: CompanyService,
        private cdr: ChangeDetectorRef,
        private cloudFunctionService: CloudFunctionsService,
        private gtmService: GoogleTagManagerService,
        private dialogRef: MatDialogRef<ProUpgradeOverlayComponent>,
        private modalService: ModalService,
    ) {
        this.pricePerMonthSubject = new ReplaySubject(1);
        this.pricePerMonth$ = this.pricePerMonthSubject.asObservable();
        this.paymentCancelledSubject = new Subject();
        this.paymentCancelled$ = this.paymentCancelledSubject.asObservable();
        // Init dummy company for ngModel template two-way binding
        this.company = { id: '', name: '' };
    }

    public async ngOnInit(): Promise<void> {
        this.loading = true;

        // Get the name and the company of the authenticated user
        const [name, company]: [string, Company] = await combineLatest([
            this.employeeService.getAuthenticatedEmployee().pipe(
                map((employee: Employee) => employee.firstname + ' ' + employee.lastname),
            ),
            this.companyService.getCurrentCompany(),
        ]).pipe(take(1)).toPromise();

        this.initializeInputForm(name, company);

        [this.fenerumAccount] = await Promise.all([
            this.getAccount(company),
            this.initializePrices(),
        ]);

        if (!this.fenerumAccount) {
            this.hideInputForm = false;
        }

        this.loading = false;
    }

    public close(): void {
        this.paymentCancelledSubject.next();
        this.paymentCancelledSubject.complete();
        this.paymentState === PaymentState.DONE ? this.modalService.closeAll() : this.dialogRef.close();
    }

    /**
     * Updates the employee name, the company info and open the payment window
     */
    public async goToPayment(): Promise<void> {
        this.goingToPayment = true;

        if (!this.hideInputForm) {
            if (this.formGroup.invalid) {
                this.goingToPayment = false;
                return;
            }
            // Get the authenticated employee
            await this.employeeService.getAuthenticatedEmployee().pipe(
                // Set the name of the employee
                map((employee: Employee) => ({ ...employee, ...splitName(this.employeeName) })),
                // Update the employee and the company
                switchMap((employee: Employee) => combineLatest([
                    this.employeeService.updateEmployee(employee),
                    this.companyService.updateCompany(this.company),
                ])),
                take(1),
            ).toPromise();

            const createAccountRequest: CreateFenerumAccountRequest = {
                companyAddress: this.formGroup.controls.COMPANY_STREET!.value,
                companyCity: this.formGroup.controls.COMPANY_CITY!.value,
                companyCountryCode: 'DK',
                companyID: this.company.id,
                companyName: this.formGroup.controls.COMPANY_NAME!.value,
                companyVAT: this.formGroup.controls.COMPANY_VAT!.value,
                companyZip: this.formGroup.controls.COMPANY_ZIP!.value,
                invoiceEmail: this.formGroup.controls.COMPANY_INVOICE_MAIL!.value,
                invoiceRecipientName: this.formGroup.controls.EMPLOYEE_NAME!.value,
            };

            await this.cloudFunctionService.createFenerumAccount(createAccountRequest);
        }
        this.paymentState = PaymentState.ADDING_CARD;

        if (!hasActiveCard(this.fenerumAccount)) {
            this.loadingCardUrl = true;
            this.cardUrl = await this.cloudFunctionService.getCardURL({ companyID: this.company.id });
            this.loadingCardUrl = false;
            this.openPaymentTab();
            await this.listenForCard();
        }

        if (hasActiveCard(this.fenerumAccount)) {
            this.paymentState = PaymentState.CREATING_SUBSCRIPTION;
            const employeeCount = await this.employeeCount$.pipe(take(1)).toPromise();
            await this.cloudFunctionService.createSubscription({
                company: {
                    ...this.company,
                    paymentCardUUID: getActiveCard(this.fenerumAccount)?.uuid,
                },
                employeeCount,
                package: Package.PRO,
                termsID: this.terms[this.yearlySubscription ? BillingPeriod.YEAR : BillingPeriod.MONTH].uuid,
            });
            this.gtmService.pushTag({
                event: 'upgradeToPro',
                mrr: employeeCount * await this.pricePerMonth$.pipe(take(1)).toPromise(),
                billingPeriod: this.yearlySubscription ? BillingPeriod.YEAR : BillingPeriod.MONTH,
                companyID: this.company.id,
            });
            this.paymentState = this.PaymentState.DONE;
        }
    }

    /**
     * Trims whitespace on name and company properties
     */
    public trimWhitespace(): void {
        this.employeeName = this.employeeName.trim();
        for (const key in this.company) {
            if (['name', 'invoiceMail', 'VAT', 'street', 'zip', 'city'].find((companyKey: string) => companyKey === key)) {
                this.company[key] = this.company[key].trim();
            }
        }
    }

    /**
     * Opens the given url the payment info input page
     */
    public openPaymentTab(): void {
        window.open(this.cardUrl, '_blank');
    }

    /**
     * Opens the card details modal and starts waiting for either an added card on the account,
     * or for the payment to be cancelled manually in the dialog.
     *
     */
    private async listenForCard(): Promise<void> {
        await timer(5000, 2000).pipe(
            // We stop listening for a new card if the payment was cancelled
            takeUntil(this.paymentCancelled$),
            switchMap(() => this.getAccount(this.company)),
            filter(((account: FenerumAccount): boolean => {
                this.fenerumAccount = account;
                return hasActiveCard(account);
            })),
            take(1),
        ).toPromise();
    }

    private getAccount(company: Company): Promise<FenerumAccount | null> {
        return from(this.cloudFunctionService.getFenerumAccount({ companyID: company.id })).pipe(
            catchError((err: CustomErrorCode | Error) => {
                if (err === 'NoCompanyAccountFound') return of(null);
                throw err;
            })).toPromise();
    }

    private initializeInputForm(name: string, company: Company): void {
        this.employeeName = name;
        this.company = { ...company };
        this.cdr.detectChanges();

        this.formGroup = this.buildFormGroup();

        // If a field is invalid, mark it as touched to show the error message
        for (const key in this.formGroup.controls) {
            if (this.formGroup.controls.hasOwnProperty(key)) {
                const control: AbstractControl = this.formGroup.controls[key]!;
                if (control.status !== 'VALID') {
                    control.markAsTouched();
                }
            }
        }
    }

    private async initializePrices(): Promise<void> {
        const proPrices: FenerumSubscriptionTerms = (await this.cloudFunctionService.getSubscriptionPlans())[Package.PRO];
        this.terms = proPrices;
        this.pricesPerMonth = {
            [BillingPeriod.MONTH]: Number(proPrices.month.price),
            [BillingPeriod.YEAR]: Number(proPrices.year.price) / 12,
        };

        this.employeeCount$ = this.employeeService.getEmployees().pipe(map((employees: Employee[]) => Math.ceil(employees.length / 5) * 5));

        this.totalPrice$ = combineLatest(
            this.pricePerMonth$,
            this.employeeCount$,
        ).pipe(map(([price, employeeCount]: [number, number]) => (this.yearlySubscription ? price * 12 : price) * employeeCount));

        this.yearlySubscription = true;
    }

    /**
     * Builds and returns a form group, including form controls with validators.
     */
    private buildFormGroup(): FormGroup {
        const EMPLOYEE_NAME: FormControl = new FormControl('', [Validators.required, CustomValidators.FullNameValidator]);
        const COMPANY_NAME: FormControl = new FormControl('', [Validators.required, CustomValidators.CompanyNameValidator]);
        const COMPANY_INVOICE_MAIL: FormControl = new FormControl('', [Validators.required, Validators.email]);
        const COMPANY_VAT: FormControl = new FormControl('', [Validators.required, CustomValidators.VATValidator]);
        const COMPANY_STREET: FormControl = new FormControl('', [Validators.required, CustomValidators.StreetValidator]);
        const COMPANY_ZIP: FormControl = new FormControl('', [Validators.required, CustomValidators.ZipValidator]);
        const COMPANY_CITY: FormControl = new FormControl('', [Validators.required, CustomValidators.CityValidator]);

        return new FormGroup({ EMPLOYEE_NAME, COMPANY_NAME, COMPANY_INVOICE_MAIL, COMPANY_VAT, COMPANY_STREET, COMPANY_ZIP, COMPANY_CITY });
    }
}
