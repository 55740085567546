<div class="login d-flex justify-content-center">
    <!-- This is the login form, containing the fields and button and displays connected to logging in -->
    <div class="login-form d-flex flex-column space-evenly">
        <div class="top-logo text-center">
            <img alt="Relion Logo"
                 src="assets/images/logo.svg">
        </div>

        <form #loginForm="ngForm">
            <input id="email"
                   required
                   ngModel
                   type="email"
                   name="email"
                   (input)="onInput(password, loginForm)"
                   class="form-item w-100 gray"
                   appFloatingLabel="{{ 'shared.email' | translate }}">

            <input type="password"
                   #password
                   ngModel
                   required
                   id="pass"
                   type="password"
                   name="password"
                   class="form-item w-100 gray"
                   appFloatingLabel="{{ 'login.password' | translate | capitalize }}">

            <ui-button-action [disabled]="loading || loginForm.invalid"
                              (click)="onSubmit(loginForm)"
                              class="form-item">
                {{ 'login.log-in' | translate | capitalize }}
            </ui-button-action>

            <div class="error-message form-item text-warn text-center style-9"
                 [hidden]="!errorMsg">
                {{ errorMsg | translate | capitalize }}
            </div>
        </form>

        <div class="login-actions">
            <ui-button-text id="forgotBtn"
                            color="primary"
                            (click)="forgotPassword()">
                {{ 'login.forgot-password' | translate | capitalize }}
            </ui-button-text>
            <br>
            <ui-button-text color="primary">
                <a href="https://relion.dk/kontakt/"
                   target="_blank">
                    {{ 'shared.contact-relion' | translate }}
                </a>
            </ui-button-text>
        </div>
    </div>
</div>