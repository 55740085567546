import { SideBarCategory } from './core/model';

export interface Routes {
    admin: {
        path: string;
        home: string;
        schedule: string;
        salary: string
        settings: string,
        team: string
    };
    employee: {
        path: string,
        home: string,
        calendar: string;
        settings: string;
        shifts: string;
        team: string;
        wishes: string;
    };
    login: {
        path: string,
    };
    signup: {
        path: string,
    };
    companySignup: {
        path: string,
    };
    pendingApproval: {
        path: string,
    };
}

export const routes: Routes = {
    admin: {
        path: 'admin',
        home: 'admin/home',
        schedule: 'admin/schedule',
        salary: 'admin/salary',
        settings: 'admin/settings',
        team: 'admin/team',
    },
    employee: {
        path: 'employee',
        home: 'employee/home',
        calendar: 'employee/calendar',
        settings: 'employee/settings',
        shifts: 'employee/shifts',
        team: 'employee/team',
        wishes: 'employee/wishes',
    },
    login: {
        path: 'login',
    },
    signup: {
        path: 'signup',
    },
    companySignup: {
        path: 'company-signup',
    },
    pendingApproval: {
        path: 'pending',
    },
};

export const sidebarCategories: { admin: SideBarCategory[], employee: SideBarCategory[] } = {
    admin: [
        {
            title: 'shared.mainpage',
            routerLink: routes.admin.path,
            iconClass: 'fa-home',
        }, {
            title: 'shared.shift-schedule',
            routerLink: routes.admin.schedule,
            iconClass: 'fa-calendar-alt',
        }, {
            title: 'sidebar.salary',
            routerLink: routes.admin.salary,
            iconClass: 'fa-coins',
        },
    ],
    employee: [
        {
            title: 'shared.mainpage',
            routerLink: routes.employee.home,
            iconClass: 'fa-home',

        }, {
            title: 'shared.shift-schedule',
            routerLink: routes.employee.calendar,
            iconClass: 'fa-calendar-alt',
        }, {
            title: 'sidebar.team',
            routerLink: routes.employee.team,
            iconClass: 'fa-user-friends',
        }],
};
