export enum Holiday {
    NEW_YEARS_DAY = 'holidays.new-years-day',
    MAUNDY_THURSDAY = 'holidays.maundy-thursday',
    GOOD_FRIDAY = 'holidays.good-friday',
    EASTER_SUNDAY = 'holidays.easter-sunday',
    EASTER_MONDAY = 'holidays.easter-monday',
    GREAT_PRAYER_DAY = 'holidays.great-prayer-day',
    ASCENSION_DAY = 'holidays.ascension-day',
    WHIT_SUNDAY = 'holidays.whit-sunday',
    WHIT_MONDAY = 'holidays.whit-monday',
    CHRISTMAS_DAY = 'holidays.christmas-day',
    BOXING_DAY = 'holidays.boxing-day',
}
