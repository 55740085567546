<div class="shift-card"
     [class.available-shift]="availableShift$ | async"
     [class.admin-unassigned-shift]="(admin$ | async) && !shift.employee"
     [class.not-released]="!shift.released"
     [class.hoverable]="hoverable"
     [class.small-font]="smallFont"
     [class.faded]="faded"
     [class.active]="active"
     matTooltip="{{ (shouldShowComment$ | async) ? shift.comment?.message : ''}}">
    <app-salary-swipe-label
            *ngIf="!!shift.salarySwipe"
            [shift]="shift"
            [dayShiftCard]="true"></app-salary-swipe-label>
    <div class="released-bar"
         [class.not-released]="!shift.released"></div>
    <img class="profile-picture"
         src="{{ shift.employee?.imageURL || '/assets/images/no-employee.svg' }}">
    <div class="text">
        <div class="name-container">
            <div class="d-flex flex-row align-items-center">
                <span class="name employee-name">
                    {{ shift.employee
                    ? shift.employee!.firstname + ' ' + shift.employee!.lastname
                    : 'admin.calendar.free-shift' | translate}}</span>
                <i *ngIf="shift.comment || (day$ | async)?.note"
                   class="far fa-comment comment-icon"></i>
            </div>
            <div class="time"
                 *ngIf="groupShiftsBy === GroupShiftsBy.ROLE">
                {{ shift.start | date: 'H:mm' }} - {{shift.end | date: 'H:mm' | convertMidnight}}
            </div>
            <div class="role"
                 *ngIf="groupShiftsBy === GroupShiftsBy.TIME">
                <div class="dot"
                     [ngClass]="shift.role.color"></div>
                <div class="name">
                    {{shift.role.name}}
                </div>
            </div>
        </div>
        <div *ngIf="(availableShift$ | async) && (potentialSalary$ | async) && !shift.employee && (isPro$ | async)"
             class="salary">
            +
            {{'freemium.dkk' | translate: { amount: (potentialSalary$ | async | number:'1.0-0') } }}
        </div>
        <div class="logo-container">
            <i *ngIf="(shiftForSale$ | async) as shiftForSale"
               [class.hasBids]="shiftForSale?.bids.length > 0"
               class="far fa-sync-alt for-sale"></i>
            <i *ngIf="shouldBeApproved$ | async"
               [class.approved]="shift.approval || shift.approved"
               class="fas fa-check-circle checked-out"></i>
            <i *ngIf="(availableShift$ | async) && (potentialSalary$ | async) && (isPro$ | async)"
               class="far fa-coins salary-icon"></i>
        </div>
    </div>
</div>
