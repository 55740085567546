<div class="no-shifts mx-auto my-5"
     *ngIf="(shiftsForSale$ | async)?.length === 0">
    <p class="text-center text-faded font-weight-500"
       *ngIf="employee$ | async as employee">
        <span *ngIf="employee.roles.length !== 0">
            {{ 'shared.no-available-shifts' | translate }}
        </span>
        <span *ngIf="employee.roles.length === 0"
              [innerHTML]="'shared.employee-has-no-roles' | translate"></span>
    </p>
</div>

<div class="free-shifts"
     *ngIf="(shiftsForSale$ | async) as shiftsForSale; else loading">

    <div class="flip-container"
         *ngFor="let shiftForSale of shiftsForSale; let index = index">
        <app-employee-shift-for-sale-item (click)="modalService.openShiftDetailModal(shiftForSale.shift)"
                                          *ngIf="index < shiftsShown"
                                          [shiftForSale]="shiftForSale"
                                          [employee$]="employee$">
        </app-employee-shift-for-sale-item>
    </div>
    <ui-button-standard class="load-more-button"
                        *ngIf="shiftsShown < shiftsForSale.length"
                        (click)="increaseShownShifts()">{{ 'shared.load-more' | translate }}</ui-button-standard>
</div>

<ng-template #loading>
    <span>
        <i class="fa fa-spin fa-spinner"></i>
    </span>
</ng-template>