import { Injectable } from '@angular/core';
import { CollectionService } from 'akita-ng-fire';
import { Department } from '../../../model/Department/Department.model';
import { companiesCollectionPath, FirestoreRole } from '../../../model/Firestore';
import { Role } from '../../../model/Role/Role.model';
import { AuthQuery } from '../../auth/state/auth.query';
import { DepartmentQuery } from '../../Department/state/department.query';
import { RoleState, RoleStore } from './role.store';

@Injectable({ providedIn: 'root' })
export class AkitaRoleService extends CollectionService<RoleState> {

    constructor(store: RoleStore, private authQuery: AuthQuery, private departmentQuery: DepartmentQuery) {
        super(store);
    }

    get path(): string {
        const companyID = this.authQuery.getValue().roles.companyID;
        return `${ companiesCollectionPath }/${ companyID }/roles`;
    }

    public formatFromFirestore(firestoreRole: Readonly<FirestoreRole & { id: string }>): Role {
        const departmentQuery = this.departmentQuery;
        return {
            ...firestoreRole,
            isDeleted: !!firestoreRole.isDeleted,
            get department(): Department {
                const department = departmentQuery.getEntity(firestoreRole.department);
                if (!department) throw new Error(`No department with id ${ firestoreRole.department }`);
                return department;
            },
        };
    }

    public formatToFirestore(role: Readonly<Role>): FirestoreRole {
        const firestoreRole: FirestoreRole = {
            color: role.color,
            name: role.name,
            department: role.department.id,
        };
        if (role.isDeleted) firestoreRole.isDeleted = role.isDeleted;
        return firestoreRole;
    }

}
