import { Department } from './Department.model';

export class DepartmentImpl implements Department {
    private _id: string;
    private _name: string;
    private _street?: string;
    private _zip?: string;
    private _city?: string;
    private _isDeleted: boolean;

    constructor({ id, name, street, city, zip, isDeleted }: Department) {
        this._id = id;
        this._name = name;
        this._street = street;
        this._city = city;
        this._zip = zip;
        this._isDeleted = isDeleted;
    }

    public get id(): string {
        return this._id;
    }

    public get name(): string {
        return this._name;
    }
    public set name(value: string) {
        this._name = value;
    }

    public get street(): string {
        return this._street || '';
    }
    public set street(value: string) {
        this._street = value;
    }

    public get city(): string {
        return this._city || '';
    }
    public set city(value: string) {
        this._city = value;
    }

    public get zip(): string {
        return this._zip || '';
    }
    public set zip(value: string) {
        this._zip = value;
    }

    public get isDeleted(): boolean {
        return this._isDeleted;
    }
    public set isDeleted(value: boolean) {
        this._isDeleted = value;
    }

    public static sortByName(d1: Department, d2: Department): number {
        return d1.name.localeCompare(d2.name);
    }
}
