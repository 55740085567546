import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { take } from 'rxjs/operators';
import { Company } from '../../../../../core/model';
import { CompanyService } from '../../../../../core/services';
import { SnackbarColor, SnackbarService } from '../../../../../core/services/snackbar/snackbar.service';

@Component({
    templateUrl: './referral-link-modal.component.html',
    styleUrls: ['./referral-link-modal.component.scss'],
})
export class ReferralLinkModalComponent implements OnInit {
    @ViewChild('linkInput') public linkInputRef: ElementRef;
    public referralURL: string = 'https://app.relion.dk/company-signup/?referrer=';

    constructor(
        private companyService: CompanyService,
        private snackbarService: SnackbarService,
    ) { }

    public ngOnInit(): void {
        this.companyService.getCurrentCompany().pipe(take(1)).toPromise()
            .then((company: Company) => this.referralURL = `https://app.relion.dk/company-signup/?referrer=${ company.id }`);
    }

    /**
     * Copies the invite link to the user's clipboard and animates the input border.
     */
    public copyLink(): void {
        const fsize: string = this.linkInputRef.nativeElement.style.fontSize;

        this.linkInputRef.nativeElement.setAttribute('contenteditable', 'true');
        this.linkInputRef.nativeElement.setAttribute('readonly', 'false');
        this.linkInputRef.nativeElement.style.fontSize = '16pt';
        this.linkInputRef.nativeElement.focus();
        this.linkInputRef.nativeElement.style.fontSize = fsize;
        this.linkInputRef.nativeElement.setSelectionRange(0, this.linkInputRef.nativeElement.value.length);
        document.execCommand('copy');
        this.linkInputRef.nativeElement.setSelectionRange(0, 0);
        this.linkInputRef.nativeElement.blur();
        this.linkInputRef.nativeElement.setAttribute('contenteditable', 'false');
        this.linkInputRef.nativeElement.setAttribute('readonly', 'true');

        this.snackbarService.displaySnack({ translationKey: 'modal.referral-link.snackbar' }, SnackbarColor.primary);
    }
}
