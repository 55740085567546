import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Wish, WishType } from '../../../../core/model';

@Component({
    selector: 'app-wish-toggle',
    templateUrl: './wish-toggle.component.html',
    styleUrls: ['./wish-toggle.component.scss'],
})
export class WishToggleComponent {
    @Input()
    public set wish(value: Wish | undefined) {
        this._wishType = value?.type || WishType.NO_WISH;
    }

    public get wishType(): WishType {
        return this._wishType;
    }

    @Output() public wishTypeChange: EventEmitter<WishType>;
    public wishTypes: typeof WishType = WishType;

    private _wishType: WishType;
    constructor() {
        this.wishTypeChange = new EventEmitter();
    }

    /**
     * Sets the active WishType and emits a change event
     * @param wishType The WishType that will be set to the active one
     */
    public setActive(wishType: WishType): void {
        this._wishType = this._wishType === wishType ? WishType.NO_WISH : wishType;
        this.wishTypeChange.emit(this._wishType);
    }
}
