import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import firebase from 'firebase/app';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { routes } from '../../../../../app-settings';
import { AuthService, AuthUser } from '../../../../../core/services';
import { GoogleAnalyticsService } from '../../../../../core/services/google-analytics.service';
import { ModalService } from '../../../../../core/services/ModalService.model';
import { SnackbarColor, SnackbarService } from '../../../../../core/services/snackbar/snackbar.service';
import { claimNotificationToken } from '../../../../../shared/utilities/NotificationUtils';
import FirebaseError = firebase.FirebaseError;

enum LoginState {
    Loading,
    Login,
    ForgotPassword,
}

@Component({
    selector: 'app-login-controller',
    templateUrl: './login-controller.component.html',
})
export class LoginControllerComponent implements OnInit, OnDestroy {

    public loginState: LoginState;
    public LoginState: typeof LoginState = LoginState;
    public errorMsg: string;

    private subscriptions: Subscription[];

    constructor(
        private authService: AuthService,
        private gas: GoogleAnalyticsService,
        private injector: Injector,
        private route: ActivatedRoute,
        private router: Router,
        private snackService: SnackbarService,
        private modalService: ModalService,
    ) {
        this.subscriptions = [];
        this.loginState = LoginState.Loading;
    }

    public ngOnInit(): void {
        setTimeout(() => {
            // If login state is still loading after 5 seconds, manually set it to login
            if (this.loginState === LoginState.Loading) this.loginState = LoginState.Login;
        }, 3 * 1000);

        this.subscriptions.push(
            this.authService.getUser().subscribe({
                next: (user: AuthUser) => {
                    if (!user) return this.loginState = LoginState.Login;

                    // Get the path param if any
                    const path: string | undefined = this.route.snapshot.queryParams.path;

                    if (path && !path.includes('signup')) {
                        // If employee is trying to go to admin page or vice versa, redirect to their home page
                        if (user.admin && path.includes('employee')) return this.router.navigate([routes.admin.schedule]);
                        if (!user.admin && path.includes('admin')) return this.router.navigate([routes.employee.path]);

                        // Otherwise navigate to the requested path
                        return this.router.navigate([path]);
                    }

                    if (user.admin) this.router.navigate([routes.admin.schedule]);
                    else this.router.navigate([routes.employee.path]);
                },
                error: () => this.loginState = LoginState.Login,
            }));
    }

    public ngOnDestroy(): void {
        this.subscriptions.map((subscription: Subscription) => subscription.unsubscribe());
    }

    /**
     * Called by the login form in the html, submits login request based on the forms values.
     * @param {NgForm} form: contains the entered information from the user.
     */
    public onSubmit(form: NgForm): void {
        this.gas.trackButtonClick('login');
        // Update UI
        this.loginState = LoginState.Loading;

        // Retrieve form values
        const formValue: { email: string; password: string } = form.form.value;

        this.authService.loginWithEmailPassword(formValue.email, formValue.password)
            .then(() => {
                // Successful sign in - update UI
                this.errorMsg = '';
                claimNotificationToken(this.injector);
            }).catch((reason: FirebaseError) => {
                // Unsuccessful sign in - update errorMsg and UI
                switch (reason.code) {
                    case 'auth/invalid-email':
                        this.errorMsg = 'error.invalid-email';
                        break;
                    case 'auth/user-disabled':
                        this.errorMsg = 'error.user-disabled';
                        break;
                    case 'auth/user-not-found':
                        this.errorMsg = 'error.user-not-found';
                        break;
                    case 'auth/wrong-password':
                        this.errorMsg = 'error.wrong-password';
                        break;
                }
                this.loginState = LoginState.Login;
            });
    }

    /**
     * Triggers a popup asking the user for a mail adress. Then sends a password reset request to firebase for the given email
     */
    public async forgotPassword(): Promise<void> {
        this.gas.trackButtonClick('forgot-password');

        const forgottenMail = await this.modalService.openInputDialog({
            titleTranslationKey: 'modal.reset-password.title',
            subtitleTranslationKey: 'modal.reset-password.subtitle',
            closeButtonTranslationKey: 'modal.reset-password.closeButton',
            acceptButtonTranslationKey: 'modal.reset-password.acceptButton',
        }).pipe(take(1)).toPromise();

        if (!forgottenMail) return;

        try {
            await this.authService.forgotPassword(forgottenMail);
            this.snackService.displaySnack({ translationKey: 'login.reset-link-sent' }, SnackbarColor.success);
        } catch (error) {
            const err: FirebaseError = error;
            switch (err.code) {
                case 'auth/invalid-email':
                    return this.snackService.displaySnack({ translationKey: 'error.invalid-email' }, SnackbarColor.warn);
                case 'auth/user-not-found':
                    return this.snackService.displaySnack({ translationKey: 'error.user-not-found' }, SnackbarColor.warn);
            }
        }
    }
}
