import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { combineLatest, Subscription, timer } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { Employee } from '../../../../../../../../../core/model';
import { Employee as SalaryEmployee } from '../../../../../../../../../core/model/Integrations/SalaryDK';
import { EmployeeMapping, SalaryIntegration } from '../../../../../../../../../core/model/Integrations/Shared';
import { EmployeeService } from '../../../../../../../../../core/services';
import { CloudFunctionsService } from '../../../../../../../../../core/services/cloud-functions.service';
import { IntegrationSettingsService } from '../../../../../../../../../core/services/Settings/IntegrationSettings/Firestore/integration-settings.service';
import { IntegrationModalComponent } from '../integration-modal/integration-modal.component';
import { SalaryDkIntegrationIgnoreModalComponent } from './salary-dk-integration-ignore-modal/salary-dk-integration-ignore-modal.component';

const matchSalaryDKEmployees = (relionEmployee: Employee, salaryEmployee: SalaryEmployee): boolean =>
    !!(relionEmployee.CPR.replace('-', '') === salaryEmployee.nationalID || relionEmployee.email === salaryEmployee.email);

const buildSalaryDKMappings = ([salaryEmployees, relionEmployees]: [SalaryEmployee[], Employee[]]) =>
    relionEmployees.reduce((mapping: EmployeeMapping[], relionEmployee: Employee) => {
        mapping.push({
            [relionEmployee.id]:
                salaryEmployees.find((salaryEmployee: SalaryEmployee) =>
                    matchSalaryDKEmployees(relionEmployee, salaryEmployee))?.id || null,
        });
        return mapping;
    }, []);

export function mapSalaryDKEmployees(
    cloudFunctionService: CloudFunctionsService,
    employeeService: EmployeeService,
    integrationSettingsService: IntegrationSettingsService,
    modal: MatDialog,
    modalRef: MatDialogRef<IntegrationModalComponent>): Subscription {
    const employeeMappings = combineLatest(
        cloudFunctionService.listSalaryEmployees(),
        employeeService.getEmployees())
        .pipe(map(buildSalaryDKMappings));

    return combineLatest(timer(3500), employeeMappings).pipe(take(1))
        .subscribe({
            next: ([_num, employeeMap]: [number, EmployeeMapping[]]) => {
                const unmatchedEmployees = employeeMap.filter((mapping: EmployeeMapping) => !mapping[Object.keys(mapping)[0]!]);
                if (unmatchedEmployees.length > 0) {
                    modal.open(SalaryDkIntegrationIgnoreModalComponent, {
                        maxHeight: '80vh',
                        width: '812px',
                        data: employeeMap,
                        disableClose: true,
                    });
                } else {
                    integrationSettingsService.saveSetting(SalaryIntegration.SALARYDK, { employeeMap }).pipe(take(1)).subscribe();
                }
                return modalRef.close();
            },
        });
}
