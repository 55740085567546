<div class="settings-page">
    <app-settings-header title="{{ 'salary.salary' | translate }}"></app-settings-header>

    <overlay-scrollbars class="settings-content" [options]="{sizeAutoCapable: false}">
        <app-settings-divider title="{{ 'salary.divider' | translate }}"></app-settings-divider>
        <app-salary-period></app-salary-period>
        <app-salary-employee-wage></app-salary-employee-wage>
        <app-settings-divider title="{{ 'settings.salary.breaks-and-supplements' | translate }}"></app-settings-divider>
        <app-shift-break-settings></app-shift-break-settings>
        <app-salary-supplement-settings></app-salary-supplement-settings>
        <app-settings-divider title="{{ 'shared.integrations' | translate }}"></app-settings-divider>
        <app-salary-dk-integration-panel></app-salary-dk-integration-panel>
        <app-danloen-integration-panel></app-danloen-integration-panel>
        <app-dataloen-integration-panel></app-dataloen-integration-panel>
        <div class="spacing"></div>
    </overlay-scrollbars>
</div>
