import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Employee } from '../../../../../../../core/model';
import { AuthService, EmployeeService } from '../../../../../../../core/services';
import { GoogleAnalyticsService } from '../../../../../../../core/services/google-analytics.service';

@Component({
    selector: 'app-profile-settings',
    templateUrl: './profile-settings.component.html',
    styleUrls: ['./profile-settings.component.scss'],
})
export class ProfileSettingsComponent implements OnInit {
    public employee$: Observable<Employee>;
    constructor(
        private employeeService: EmployeeService,
        private authService: AuthService,
        public gas: GoogleAnalyticsService,
    ) { }

    public ngOnInit(): void {
        this.employee$ = this.employeeService.getAuthenticatedEmployee();
    }

    public logout(): void {
        this.authService.logout();
    }
}
