import { Component, Injector, Input, OnInit } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { Day, Shift, shiftEnded, ShiftForSale } from '../../../core/model';
import { CompanySetting, CompanySettingsService, ShiftForSaleService } from '../../../core/services';
import { DayService } from '../../../core/services/day.service';
import { shouldBeApproved } from '../../utilities/PunchclockUtils';

@Component({
    selector: 'app-full-shift-card',
    templateUrl: './full-shift-card.component.html',
    styleUrls: ['./full-shift-card.component.scss'],
})
export class FullShiftCardComponent implements OnInit {
    @Input()
    public get shift(): Shift {
        return this._shift;
    }
    public set shift(value: Shift) {
        this._shift = value;
        this.shiftSubject.next(value);
    }
    public shiftForSale$: Observable<ShiftForSale | null>;
    public shiftIsLive$: Observable<boolean>;
    public shiftShouldBePunchedIn$: Observable<boolean>;
    public shouldBeApproved$: Observable<boolean>;
    public day$: Observable<Day>;
    private _shift: Shift;
    private shiftSubject: ReplaySubject<Shift> = new ReplaySubject();

    constructor(
        private injector: Injector,
        private dayService: DayService,
        private settingsService: CompanySettingsService,
        private shiftForSaleService: ShiftForSaleService,
    ) { }

    public ngOnInit(): void {
        const punchclockEnabled$ =
            this.settingsService.loadSetting(CompanySetting.PUNCHCLOCK_ENABLED_ON)
                .pipe(map((enabledOn: Date | null) => !!enabledOn));
        const checkoutUntil$: Observable<number | null> = this.settingsService.loadSetting(CompanySetting.CHECKOUT_UNTIL);

        const shiftHasStarted = this.shift.start.getTime() < Date.now() || !!this.shift.checkin;

        this.shiftIsLive$ = checkoutUntil$.pipe(
            map((checkoutUntil: number | null) => shiftHasStarted && !(shiftEnded(this.shift, checkoutUntil) || !!this.shift.checkout)));

        this.shiftForSale$ = this.shiftForSaleService.getShiftForSaleByShift(this.shift);

        this.shiftShouldBePunchedIn$ =
            punchclockEnabled$.pipe(map((punchClockEnabled: boolean) => punchClockEnabled && !this.shift.checkin));

        this.shouldBeApproved$ = shouldBeApproved(this.shiftSubject.asObservable(), this.injector);

        this.day$ = this.dayService.getDay(this.shift.start, this.shift.role.department);
    }
}
