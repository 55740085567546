import { Injectable } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { AutoFillModalData } from '../../modules/admin/shared/components/auto-fill-modal/auto-fill-modal.component';
import { SalarySupplementsModalComponent } from '../../modules/admin/shared/components/salary-supplements-modal/salary-supplements-modal.component';
import { NavbarSettings } from '../../modules/admin/shared/components/settings-modal/settings-modal.component';
import { CardDetailsModalComponent } from '../../shared/components/freemium/card-details-modal/card-details-modal.component';
import { ConfirmDialogData } from '../../shared/ui/confirm-dialog/confirm-dialog.component';
import { InputDialogData } from '../../shared/ui/input-dialog/input-dialog.component';
import { Employee, NewShift, Shift } from '../model';
import { Department } from '../model/Department/Department.model';
import { SalaryIntegration } from '../model/Integrations/Shared';
import { RelionModule } from '../model/RelionModule';
import { SalaryRange } from '../model/UserSettings';

@Injectable()
export abstract class ModalService {

    /**
     * Close all open modals
     */
    public abstract closeAll(): void;

    /**
     * Opens the modal for editing departments.
     */
    public abstract openEditDepartmentModal(department?: Department): void;

    /**
     * Opens the card details modal
     * @param url The payment url to open from the modal
     */
    public abstract openCardDetailsModal(url: string): MatDialogRef<CardDetailsModalComponent>;

    /**
     * Trigger for when an image is chosen by the user. This opens the cropper modal
     * @param event The input event with a base64 encoded string of the file
     * @param input The input field
     * @param employee The employee, the base64 encoded string of the file will be uploaded to
     */
    public abstract openProfilePictureModal(event: { base64: string; }, input: HTMLInputElement, employee?: Employee): void;

    /**
     * Open the settings modal, optionally on a specific page
     * @param setting - The settings page on which to open the modal
     */
    public abstract openSettingsModal(setting?: NavbarSettings): void;

    /**
     * Open the ready for pro modal for the given package
     */
    public abstract openReadyForProModal(module: RelionModule): void;

    /**
     * Open the fullscreen welcome overlay
     */
    public abstract openWelcomeOverlay(): void;

    /**
     * Open the shift detail modal
     * @param shift - The shift for which the modal should display data
     */
    public abstract openShiftDetailModal(shift: Shift): void;

    /**
     * Open DayView in a modal on a given date
     * @param day The date to start the DayView on
     */
    public abstract openDayView(day: Date): void;

    /**
     * Open an auto fill modal
     * @param modalData - The auto fill modal data
     */
    public abstract openAutoFill(modalData: AutoFillModalData): void;

    /**
     * Opens a confirm dialog modal
     * @param modalData - The confirm dialog data
     * @param disableClose - Whether you can close the modal by pressing outside the modal
     */
    public abstract openConfirmDialog(modalData: ConfirmDialogData, disableClose?: boolean): Observable<boolean>;

    /**
     * Opens an input dialog modal
     * @param modalData - The input dialog data
     * @param disableClose - Whether you can close the modal by pressing outside the modal
     */
    public abstract openInputDialog(modalData: InputDialogData, disableClose?: boolean): Observable<string | undefined>;

    /**
     * Opens a referral link modal
     */
    public abstract openReferralLinkModal(): void;

    /**
     * Open the invite employees modal
     */
    public abstract openInviteModal(): void;

    /**
     * Open the set hourly wage on employees modal
     */
    public abstract openEmployeeHourlyWageModal(): void;

    /**
     * Opens the approve shifts modal
     * @param start - Only show shifts for approval after this date (inclusive)
     * @param end - Only show shifts for approval before this date (exclusive)
     * @param department - Optionally only show shifts to approve for this departent
     * @param mobile - Open in mobile view
     */
    public abstract openApproveShiftsModal(start: Date, end: Date, department?: Department, mobile?: boolean): void;

    /**
     * Opens the salary export modal
     * @param range - The range for which to export data
     * @param selectedDepartment - Optionally a department, if exporting salary for only one
     * @param shifts - The shifts to export
     */
    public abstract openExportSalaryModal(range: SalaryRange, selectedDepartment: Department | undefined, shifts: Shift[]): void;

    /**
     * Opens the salary supplement settings modal
     */
    public abstract openSalarySupplementSettingsModal(): MatDialogRef<SalarySupplementsModalComponent>;

    /**
     * Opens the modal for either sending a text, or call the wanted employee.
     * @param employee Is the one you want to contact
     */
    public abstract openContactEmployeeModal(employee: Employee): void;

    /**
     * Opens the integration modal of the chosen integration
     * @param integrationSetting - The integration for which to open the modal
     */
    public abstract openIntegrationModal(integrationSetting: SalaryIntegration): void;

    /**
     * Open the team modal
     * @param employee Optional employee parameter to open the profile of this employee directly
     */
    public abstract openTeamModal(employee?: Employee): void;

    /**
     * Open the pro shop upgrade overlay
     */
    public abstract openProShopOverlay(): void;

    /**
     * Open the overlay where the user confirms they'll go back to free
     */
    public abstract openConfirmGoBackToFreeOverlay(): void;

    /**
     * Open the start trial modal
     */
    public abstract openStartTrial(): void;

    /**
     *
     * Open the pro upgrade overlay
     */
    public abstract openProUpgradeOverlay(disableAnimation?: boolean): void;

    /**
     * Open the edit shift overlay
     * @param shift The shift to edit
     */
    public abstract openEditShift(shift: Shift | NewShift, disableAnimation?: boolean): void;
}
