<div class="run-salary-export-modal">

    <div class="exporting"
         [ngClass]="integrationStatus!.integration"
         *ngIf="export">
        <i *ngIf="export === Export.IN_PROGRESS"
           class="fad fa-spin fa-spinner-third"></i>
        <i *ngIf="export === Export.DONE"
           class="far fa-check"></i>
        <img [src]="integrationStatus!.integration! | getIntegrationLogo">
        <div class="openSalarySystemWebsite"
             *ngIf="export === Export.DONE">
            <ui-button-standard (click)="openSalarySystemWebsite()">
                {{ 'shared.open' | translate }}
                {{ ('integrations.' + integrationStatus!.integration!.toString() + '.name') | translate }}
            </ui-button-standard>
        </div>
    </div>

    <div class="range">
        {{ modalData.range.start | date }}
        -
        {{ modalData.range.end | date }}
    </div>

    <div class="departments"
         *ngIf="multipleDepartments$ | async">
        {{ modalData.selectedDepartment?.name || ('salary.all-departments' | translate) }}
    </div>

    <div class="issue-cards">
        <div class="issue-card"
             [class.warning]="!rangeIsInPast">
            <div class="icon-and-text">
                <i class="far fa-check"
                   *ngIf="rangeIsInPast"></i>
                <i class="far fa-exclamation-circle"
                   *ngIf="!rangeIsInPast"></i>
                {{ ( rangeIsInPast
                ? 'salary.period-ended'
                : 'salary.period-not-ended'
               ) | translate }}
            </div>
        </div>
        <div class="issue-card"
             *ngIf="integrationStatus?.integration"
             [class.warning]="integrationStatus?.error">
            <div class="icon-and-text">
                <i class="far fa-check"
                   *ngIf="!integrationStatus!.error"></i>
                <i class="far fa-exclamation-circle"
                   *ngIf="integrationStatus!.error"></i>
                {{  (integrationStatus!.error! | translate) ||
                    ('integrations.integrated-correctly-with' | translate: { integration: (('integrations.' + integrationStatus!.integration!.toString() + '.name') | translate) }) }}
            </div>
            <ui-button-text *ngIf="integrationStatus?.error"
                            (click)="openIntegrationSettings()"
                            color="primary">
                {{ 'integrations.review-integration' | translate }}
            </ui-button-text>
        </div>
        <div class="issue-card"
             [class.warning]="employeesWithoutSalary">
            <div class="icon-and-text">
                <i class="far fa-check"
                   *ngIf="!employeesWithoutSalary"></i>
                <i class="far fa-exclamation-circle"
                   *ngIf="employeesWithoutSalary"></i>
                {{  employeesWithoutSalary === 0
                        ? ('shared.noone' | translate) + ('salary.missing-hourly-wage' | translate: { amount: '' })
                        : employeesWithoutSalary < 0
                            ? ('shared.everyone' | translate) + ('salary.missing-hourly-wage' | translate: { amount: '' })
                            : ('salary.missing-hourly-wage' | translate: { amount: employeesWithoutSalary })
                   }}
            </div>
            <ui-button-text *ngIf="employeesWithoutSalary"
                            (click)="openEmployeeHourlyWageModal()"
                            color="primary">
                {{ 'salary.set-hourly-wage' | translate }}
            </ui-button-text>
        </div>
        <div class="issue-card"
             [class.warning]="unapprovedShifts">
            <div class="icon-and-text">
                <i class="far fa-check"
                   *ngIf="!unapprovedShifts"></i>
                <i class="far fa-exclamation-circle"
                   *ngIf="unapprovedShifts"></i>
                {{ unapprovedShifts || ('shared.none' | translate) }}
                {{ unapprovedShifts === 1
                   ? ('punch-clock.change-awaiting-approval' | translate )
                   : ('punch-clock.changes-awaiting-approval' | translate )
               }}
            </div>
            <ui-button-text *ngIf="unapprovedShifts"
                            (click)="openApproveShiftsModal()"
                            color="primary">
                {{ 'shared.review' | translate }}
            </ui-button-text>
        </div>
    </div>

    <div class="bottom">
        <div class="left">
            <ng-container *ngIf="isPro$ | async">
                <div class="counter">
                    <div class="text">
                        {{ 'modal.salary-detail.salary-total' | translate }}:
                    </div>
                    <div class="count">
                        <i class="far fa-coins"></i>
                        {{'freemium.dkk' | translate: { amount: (totalSalary | number:'1.2-2')} }}
                    </div>
                </div>
                <div class="counter">
                    <div class="text">
                        {{ 'modal.salary-detail.hours-total' | translate }}:
                    </div>
                    <div class="count">
                        <i class="far fa-clock"></i>
                        {{ totalHours | number:'1.0-2' }}
                        {{ 'time.hours' | translate }}
                    </div>
                </div>
            </ng-container>
            <div class="d-flex flex-column"
                 *ngIf="!(isPro$ | async)">
                <div class="counter">
                    <div class="text">
                        {{ 'modal.salary-detail.hours-and-salary-total' | translate }}
                    </div>
                </div>
                <div class="pro-total">
                    <i class="fa fa-arrow-alt-circle-up"></i>
                    <span (click)="openSalaryProShopModal()">
                        {{ 'shared.upgrade' | translate }}
                    </span>
                </div>
            </div>
        </div>
        <div class="right">
            <ui-button-standard (click)="exportToCSV()"
                                [appProLabel]="Package.PRO">
                {{ 'salary.export-csv' | translate }}
            </ui-button-standard>
            <ui-button-action [appProLabel]="Package.PRO"
                              class="export-to-integration-btn"
                              (click)="exportOrIntegrate()"
                              *ngIf="highlightExportBtn">
                {{ !!integrationStatus?.integration
                ? ('integrations.send-to' | translate: { integration: (('integrations.' +
                integrationStatus!.integration!.toString() + '.name') | translate) }) + ' ' + noShiftsNonExported
                : ('integrations.integrate-with-salary-system' | translate) }}
            </ui-button-action>
            <ui-button-standard [appProLabel]="Package.PRO"
                                class="export-to-integration-btn"
                                (click)="exportOrIntegrate()"
                                *ngIf="!highlightExportBtn">
                {{ !!integrationStatus?.integration
                ? ('integrations.send-to' | translate: { integration: (('integrations.' +
                integrationStatus!.integration!.toString() + '.name') | translate) }) + ' ' + noShiftsNonExported
                : ('integrations.integrate-with-salary-system' | translate) }}
            </ui-button-standard>

        </div>

    </div>
    <div class="status"
         *ngIf="!!integrationStatus?.integration">
        {{
        noShiftsExported + '/' + modalData.shifts.length
        + ' '
        + ('integrations.already-exported' | translate)
        }} <i class="far fa-check"></i>
    </div>
</div>