import { Department } from '../Department/Department.model';
import { RoleColor } from './RoleColor';

/**
 * The role interface reflecting the structure in the application
 */
export interface Role {
    id: string;
    name: string;
    department: Department;
    isDeleted: boolean;
    color: RoleColor;
}

/**
 * Type checking method returning whether a given object is a Role
 * @param obj - The object to type check
 */
export function isRole(obj: unknown): obj is Role {
    const castedRole: Role = obj as Role;

    return castedRole.id != null
        && castedRole.name != null
        && castedRole.department != null
        && castedRole.isDeleted != null
        && castedRole.color != null;
}
