import { Platform } from '@angular/cdk/platform';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgForm } from '@angular/forms';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
})

export class LoginComponent {
    // Used to disable button while logging in (and visual things)
    @Input() public loading: boolean;
    // Is displayed on the page, if login fails.
    @Input() public errorMsg: string;

    @Output() public validLoginFormSubmitted: EventEmitter<NgForm>;
    @Output() public forgotPasswordClicked: EventEmitter<void>;


    constructor(private platform: Platform) {
        this.validLoginFormSubmitted = new EventEmitter();
        this.forgotPasswordClicked = new EventEmitter();
    }

    /**
     * Check for autofill validity. When browsers autofill,
     * no change event is fired, so we have to look for it ourselves
     */
    public onInput(passwordInput: HTMLInputElement, loginForm: NgForm): void {
        if (!this.platform.IOS) return;
        setTimeout(() => {
            if (passwordInput.value) loginForm.controls.password?.setValue(passwordInput.value);
        }, 100);
    }

    public onSubmit(form: NgForm): void {
        this.validLoginFormSubmitted.emit(form);
    }

    public forgotPassword(): void {
        this.forgotPasswordClicked.emit();
    }
}
