<div class="settings-page">
    <app-settings-header title="{{ 'shared.general' | translate | capitalize }}"></app-settings-header>

    <overlay-scrollbars class="settings-content">

        <!-- Company information section -->
        <app-settings-divider title="{{ 'shared.company-information' | translate }}"></app-settings-divider>

        <form class="d-flex flex-column"
              [formGroup]="formGroup">
            <!-- COMPANY NAME -->
            <input formControlName="COMPANY_NAME"
                   required
                   [appFloatingLabel]="'settings.company-information.name' | translate"
                   [errorMessage]="'error.input.company-name' | translate"
                   [(ngModel)]="company.name" />

            <!-- COMPANY INVOICE EMAIL -->
            <input formControlName="COMPANY_INVOICE_MAIL"
                   [appFloatingLabel]="'settings.company-information.invoice-mail' | translate"
                   [errorMessage]="'error.input.invoice-mail' | translate"
                   [(ngModel)]="company.invoiceMail" />

            <!-- COMPANY VAT -->
            <input formControlName="COMPANY_VAT"
                   [appFloatingLabel]="'settings.company-information.vat' | translate"
                   [errorMessage]="'error.input.vat' | translate"
                   [(ngModel)]="company.VAT" />

            <!-- COMPANY STREET -->
            <input formControlName="COMPANY_STREET"
                   [appFloatingLabel]="'settings.company-information.address' | translate"
                   [errorMessage]="'error.input.street' | translate"
                   [(ngModel)]="company.street" />
            <div class="d-flex">

                <!-- COMPANY ZIP -->
                <input formControlName="COMPANY_ZIP"
                       [appFloatingLabel]="'settings.company-information.zip' | translate"
                       [errorMessage]="'error.input.zip' | translate"
                       [(ngModel)]="company.zip" />

                <!-- COMPANY CITY -->
                <input formControlName="COMPANY_CITY"
                       [appFloatingLabel]="'settings.company-information.city' | translate"
                       [errorMessage]="'error.input.city' | translate"
                       [(ngModel)]="company.city" />
            </div>
        </form>
    </overlay-scrollbars>
</div>