import { Department } from '../Department/Department.model';
import { Role } from './Role.model';
import { RoleColor } from './RoleColor';

export class RoleImpl implements Role {
    private _id: string;
    private _name: string;
    private _department: Department;
    private _color: RoleColor;
    private _deleted: boolean;

    constructor(id: string, name: string, department: Department, color: RoleColor, isDeleted?: boolean) {
        this._id = id;
        this._name = name;
        this._department = department;
        this._color = color;
        this._deleted = isDeleted || false;
    }
    public get id(): string {
        return this._id;
    }
    public get name(): string {
        return this._name;
    }
    public set name(value: string) {
        this._name = value;
    }
    public get department(): Department {
        return this._department;
    }
    public set department(value: Department) {
        this._department = value;
    }
    public get isDeleted(): boolean {
        return this._deleted;
    }
    public set isDeleted(value: boolean) {
        this._deleted = value;
    }
    public get color(): RoleColor {
        return this._color;
    }
    public set color(value: RoleColor) {
        this._color = value;
    }
}
