import { Pipe, PipeTransform } from '@angular/core';
import { DateService } from '../../core/services';

@Pipe({
    name: 'dateToWeekNumber',
})
export class DateToWeekNumberPipe implements PipeTransform {

    /**
     * Transform the given date object into it's week number
     * @param {Date} date, the date to be transformed
     * @return {number} the week number of the given day
     */
    public transform(date: Date): number {
        return DateService.getWeekNumber(date);
    }

}
