import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Package } from '../../../../core/model/Freemium/Package';
import { ConfigurationService } from '../../../../core/services/Configuration/configuration-service.model';
import { ModalService } from '../../../../core/services/ModalService.model';
import { ConfirmGoBackToFreeComponent } from '../confirm-go-back-to-free/confirm-go-back-to-free.component';

@Component({
    selector: 'app-start-trial',
    templateUrl: './start-trial.component.html',
    styleUrls: ['./start-trial.component.scss'],
})
export class StartTrialComponent {
    public trialStarted: boolean;
    public startingTrial: boolean;

    constructor(
        private dialogRef: MatDialogRef<ConfirmGoBackToFreeComponent>,
        private modalService: ModalService,
        private configurationService: ConfigurationService,
    ) { }

    public async startTrial(): Promise<void> {
        this.startingTrial = true;
        await this.configurationService.startTrial(Package.PRO);
        this.startingTrial = false;

        this.trialStarted = true;
    }

    public close(): void {
        if (this.trialStarted) this.modalService.closeAll();
        this.dialogRef.close();
    }
}
