<div class="department-select"
     ngbDropdown
     placement="bottom-left">
    <ui-button-standard ngbDropdownToggle>
        <div class="d-flex flex-row button-content">
            <div class="dot-dot-dot"
                 style="margin-right: 4px;">
                {{ department?.name || ('salary.all-departments' | translate) }}</div>
            <span class="arrow"> <i class="fa fa-chevron-down"></i> </span>
        </div>
    </ui-button-standard>
    <div ngbDropdownMenu>
        <button ngbDropdownItem
                *ngIf="disableAllDepartments === undefined"
                (click)="selectDepartment()">
            {{ 'salary.all-departments' | translate }}
        </button>
        <button ngbDropdownItem
                *ngFor="let department of availableDepartments$ | async"
                (click)="selectDepartment(department)">
            {{ department.name }}
        </button>
        <div class="line"></div>
        <button ngbDropdownItem
                (click)="openEditDepartmentModal()">
            {{ 'admin.department.manage-departments' | translate }}
        </button>
    </div>
</div>