<button mat-stroked-button
        [color]="color"
        [disabled]="disabled"
        [disableRipple]="disableRipple"
        [type]="type">
    <div>
        <ng-content></ng-content>
    </div>
    <app-spinner [loading]="loading"></app-spinner>
</button>
