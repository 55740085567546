import { Employee } from '../model';

/**
 * Wish
 */
export interface Wish {
    id: string;
    employee: Employee;
    // The from time of the wish (inclusive)
    from: Date;
    // The to time of the wish (exclusive)
    to: Date;
    // The wish type
    type: WishType;
}

export enum WishType {
    NO_WISH,
    WORK,
    NO_WORK,
}

export const numberOfEnumsToSupport: number = 3;

/**
 * Given a wishType, this returns the next (or the first, if the last is given)
 * @param {number} wishType, the current wish type
 * @returns {number | number} the next wish type
 */
export function cycleToNextType(wishType: WishType): WishType {
    return (wishType + 1) % numberOfEnumsToSupport;
}

/**
 * This function maps a number into the string key of this wishType
 * @param {number} wishType, the number of the wish, This is the type field of a wish.
 * @returns {string} a string key mapping to an explanation in the given language
 */
export function getWishType(wishType: WishType): string {
    switch (wishType) {
        case WishType.WORK:
            return 'shared.wish-to-work';
        case WishType.NO_WORK:
            return 'shared.wish-no-work';
        case WishType.NO_WISH:
            return 'shared.no-wishes';
    }
}

/**
 * Get the color of a shift based on its type
 * @param wishType - The shift type
 * @returns The color of the shift in a human readable format (e.g. green)
 */
export function getWishTypeColor(wishType: number): string {
    switch (wishType) {
        case WishType.WORK:
            return '#CFF1D0';
        case WishType.NO_WORK:
            return '#F1CACC';
        default:
            return '';
    }
}
