import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, shareReplay, switchMap } from 'rxjs/operators';
import { Auth, FirestoreService } from '../..';
import { Company } from '../../../model';
import { companiesCollectionPath as rootCollectionPath, FirestoreCompany } from '../../../model/Firestore';
import { AuthService } from '../../auth.service';
import { CompanyService } from '../CompanyService.model';

@Injectable({
    providedIn: 'root',
})
export class FirestoreCompanyService extends CompanyService {
    private cache: Map<string, Observable<Company>>;

    constructor(
        private firestoreService: FirestoreService,
        authService: AuthService,
    ) {
        super(authService);
        this.cache = new Map();
    }

    public getCompany(id: string): Observable<Company> {
        const cache: Observable<Company> | undefined = this.cache.get(id);
        if (cache) return cache;

        const company$: Observable<Company> = this.firestoreService.angularFireStore.collection(rootCollectionPath)
            .doc<FirestoreCompany>(id).valueChanges().pipe(
                // Throw error if not found
                map((fsCompany: FirestoreCompany | undefined) => fsCompany || this.throw('No company with id ' + id)),
                // Add id to fsCompany
                map((fsCompany: FirestoreCompany) => ({ id, ...fsCompany })),
                // Map fsCompany to Company
                map(this.mapFirestoreCompanyToCompany),
                shareReplay(1));

        this.cache.set(id, company$);
        return company$;
    }

    public getCompanies(): Observable<Company[]> {
        // Return an observable on the Virksomheder collection (the idField 'id' is automatically added)
        return this.firestoreService.angularFireStore.collection<FirestoreCompany>(rootCollectionPath)
            .valueChanges({ idField: 'id' }).pipe(
                // Map the fsCompanies to Companies
                map((fsCompanies: FirestoreCompany[]) => fsCompanies.map(this.mapFirestoreCompanyToCompany)),
            );
    }

    public updateCompany(company: Company): Observable<Company> {
        return this.firestoreService.authenticate(rootCollectionPath)
            .pipe(switchMap((auth: Auth<FirestoreCompany>) => auth.collection
                .doc(company.id)
                .update(this.mapCompanyToFirestoreCompany(company))),
                switchMap(() => this.getCompany(company.id)));
    }
    /**
     * Maps a FirestoreCompany to a Company
     * @param fsCompany - The FirestoreCompany to map
     */
    private mapFirestoreCompanyToCompany(fsCompany: FirestoreCompany & { id: string }): Company {
        // Create a company with the required fields
        const company: Company = {
            id: fsCompany.id,
            name: fsCompany.name,
        };
        // Add the optional fields if present in the fsCompany
        if (fsCompany.createdOn) company.createdOn = fsCompany.createdOn.toDate();
        if (fsCompany.invoiceMail) company.invoiceMail = fsCompany.invoiceMail;
        if (fsCompany.paymentCardUUID) company.paymentCardUUID = fsCompany.paymentCardUUID;
        if (fsCompany.VAT) company.VAT = fsCompany.VAT;
        if (fsCompany.street) company.street = fsCompany.street;
        if (fsCompany.zip) company.zip = fsCompany.zip;
        if (fsCompany.city) company.city = fsCompany.city;
        if (fsCompany.country) company.country = fsCompany.country;
        if (fsCompany.referrer) company.referrer = fsCompany.referrer;
        if (fsCompany.language) company.language = fsCompany.language;
        return company;

    }

    /**
     * Maps a Company to a FirestoreCompany
     * @param company - The Company to map
     */
    private mapCompanyToFirestoreCompany(company: Company): FirestoreCompany {
        const fsCompany: FirestoreCompany = { name: company.name };

        if (company.invoiceMail !== undefined) fsCompany.invoiceMail = company.invoiceMail;
        if (company.paymentCardUUID !== undefined) fsCompany.paymentCardUUID = company.paymentCardUUID;
        if (company.VAT !== undefined) fsCompany.VAT = company.VAT;
        if (company.street !== undefined) fsCompany.street = company.street;
        if (company.city !== undefined) fsCompany.city = company.city;
        if (company.zip !== undefined) fsCompany.zip = company.zip;
        if (company.country !== undefined) fsCompany.country = company.country;
        if (company.language !== undefined) fsCompany.language = company.language;

        return fsCompany;
    }

    /**
     * Throws an error
     * @param errorMessage - The error message to be thrown
     */
    private throw(errorMessage: string): void {
        throw new Error(errorMessage);
    }
}
