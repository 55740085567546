import { Component } from '@angular/core';
import { ButtonDirective } from '../button/button.directive';

@Component({
    selector: 'ui-button-text',
    templateUrl: './button-text.component.html',
    styleUrls: ['./button-text.component.scss'],
})

export class ButtonTextComponent extends ButtonDirective { }
