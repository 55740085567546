import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MomentModule } from 'ngx-moment';
import { NgPipesModule } from 'ngx-pipes';
import { CapitalizePipe } from './capitalize.pipe';
import { ChangeDatePipe } from './change-date.pipe';
import { ConvertMidnightPipe } from './convertMidnight.pipe';
import { CustomDatePipe } from './custom-date.pipe';
import { DateToDecimalsPipe } from './date-to-decimals.pipe';
import { DateToWeekNumberPipe } from './date-to-week-number.pipe';
import { DecimalToTimePipe } from './decimalToTime.pipe';
import { EmployeeRelationPipe } from './employee-relation.pipe';
import { EmployeeRelationsPipe } from './employee-relations.pipe';
import { GetIntegrationLogoPipe } from './get-integration-logo.pipe';
import { LoadingPipe } from './loading.pipe';
import { MinutesToTimePipe } from './minutes-to-time.pipe';
import { NameShortenPipe } from './name-shorten.pipe';
import { PluralPipe } from './plural.pipe';
import { SearchFilterPipe } from './search-filter.pipe';
import { ShiftBreakPipe } from './shift-break.pipe';
import { ShiftDurationPipe } from './shift-duration.pipe';
import { ShiftToSalaryPipe } from './shift-to-salary.pipe';
import { SortArrayPipe } from './sort-array.pipe';
import { SumShiftDurationPipe } from './sum-shift-duration.pipe';
import { TemplateHasEmployeesPipe } from './template-has-employees.pipe';
import { ToTitleCasePipe } from './toTitleCase.pipe';
import { WishTimeToTextPipe } from './wish-time-to-text.pipe';
import { EmployeeSourcePipe } from './employee-source.pipe';

@NgModule({
    declarations: [
        CapitalizePipe,
        ChangeDatePipe,
        ConvertMidnightPipe,
        CustomDatePipe,
        DateToDecimalsPipe,
        DateToWeekNumberPipe,
        DecimalToTimePipe,
        EmployeeRelationPipe,
        EmployeeRelationsPipe,
        EmployeeSourcePipe,
        GetIntegrationLogoPipe,
        LoadingPipe,
        MinutesToTimePipe,
        NameShortenPipe,
        PluralPipe,
        SearchFilterPipe,
        ShiftBreakPipe,
        ShiftDurationPipe,
        ShiftToSalaryPipe,
        SortArrayPipe,
        SumShiftDurationPipe,
        TemplateHasEmployeesPipe,
        ToTitleCasePipe,
        WishTimeToTextPipe,
    ],
    imports: [
        CommonModule,
        MomentModule,
        NgPipesModule,
    ],
    exports: [
        CapitalizePipe,
        ChangeDatePipe,
        ConvertMidnightPipe,
        CustomDatePipe,
        DateToDecimalsPipe,
        DateToWeekNumberPipe,
        DecimalToTimePipe,
        EmployeeRelationPipe,
        EmployeeRelationsPipe,
        EmployeeSourcePipe,
        GetIntegrationLogoPipe,
        LoadingPipe,
        MinutesToTimePipe,
        MomentModule,
        NameShortenPipe,
        NgPipesModule,
        PluralPipe,
        SearchFilterPipe,
        ShiftBreakPipe,
        ShiftDurationPipe,
        ShiftToSalaryPipe,
        SortArrayPipe,
        SumShiftDurationPipe,
        TemplateHasEmployeesPipe,
        ToTitleCasePipe,
        WishTimeToTextPipe,
    ],
    providers: [
        EmployeeRelationPipe,
        EmployeeRelationsPipe,
    ],
})
export class PipeModule { }
