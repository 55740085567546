import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { map, switchMap, take, tap } from 'rxjs/operators';
import { Bid, Employee, ShiftForSale } from '../../../../../../../../core/model';
import { Package } from '../../../../../../../../core/model/Freemium/Package';
import { MediaService, ShiftForSaleService } from '../../../../../../../../core/services';
import { ConfigurationService } from '../../../../../../../../core/services/Configuration/configuration-service.model';
import { GoogleAnalyticsService } from '../../../../../../../../core/services/google-analytics.service';
import { SalaryService } from '../../../../../../../../core/services/salary.service';

@Component({
    selector: 'app-employee-shift-for-sale-item',
    templateUrl: './employee-shift-for-sale-item.component.html',
    styleUrls: ['./employee-shift-for-sale-item.component.scss'],
})
export class EmployeeShiftForSaleItemComponent implements OnInit {

    @Input() public employee$: Observable<Employee>;
    @Input() public shiftForSale: ShiftForSale;
    public selected: boolean;
    // Determines whether or not the current user has already bid on the ShiftForSale.
    public hasBidOnShiftForSale$: Observable<boolean>;
    public loading: boolean;
    public locale: string;
    public potentialGain$: Observable<number>;
    public isPro$: Observable<boolean>;

    constructor(
        public gas: GoogleAnalyticsService,
        private cdr: ChangeDetectorRef,
        private configurationService: ConfigurationService,
        private salaryService: SalaryService,
        private shiftForSaleService: ShiftForSaleService,
        private translateService: TranslateService,
    ) {
        this.loading = true;
    }

    public ngOnInit(): void {
        this.locale = this.translateService.currentLang;
        this.hasBidOnShiftForSale$ = this.employee$.pipe(
            map((employee: Employee) =>
                this.shiftForSale.bids.some((bid: Bid) => bid.bidder.id === employee.id)),
            tap(() => setTimeout(() => this.loading = false)),
            tap(() => setTimeout(() => MediaService.detectChanges(this.cdr), 0)));

        this.potentialGain$ = this.employee$.pipe(
            switchMap((employee: Employee) =>
                this.salaryService.shiftToSalary(this.shiftForSale.shift, employee),
            ));

        this.isPro$ = this.configurationService.isPro(Package.PRO);
    }

    /**
     * Makes a bid on a `ShiftForSale` for the employee.
     * @param sfs
     */
    public bidOnShift(sfs: ShiftForSale, event: Event): void {
        event.cancelBubble = true;
        this.employee$
            .pipe(
                switchMap((employee: Employee) => this.shiftForSaleService.bidOnShiftForSale(sfs, employee)),
                take(1))
            .subscribe();
    }

    /**
     * Removes a bid on a `ShiftForSale` for the employee.
     *  @param sfs
     */
    public removeBidOnShift(sfs: ShiftForSale, event: Event): void {
        event.cancelBubble = true;
        this.employee$
            .pipe(
                switchMap((employee: Employee) => this.shiftForSaleService.removeBidOnShiftForSale(sfs, employee)),
                take(1))
            .subscribe();
    }

    /**
     * Set the value of selected
     * @param value The value to set
     */
    public setSelected(value: boolean, event: Event): void {
        event.cancelBubble = true;
        this.selected = value;
        MediaService.detectChanges(this.cdr);
    }
}
