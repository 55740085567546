import { NgModule, NgZone } from '@angular/core';
import { Router, RouterModule, Routes } from '@angular/router';
import { Subject } from 'rxjs';
import { routes } from './app-settings';
import { AdminGuard, LoginGuard } from './core/guards';
import { CompanySignupComponent } from './modules/company-signup/responsive/pages/company-signup/company-signup.component';
import { SignupComponent } from './modules/employee-signup/responsive/pages/signup/signup.component';
import { PendingApprovalComponent } from './modules/employee/pending-approval/pages/pending-approval/pending-approval.component';
import { LoginControllerComponent } from './modules/login/responsive/pages/login-controller/login-controller.component';

export function getLeaf(path: string): string {
    const pathParts: string[] = path.split('/');
    return pathParts[pathParts.length - 1]!;
}

const appRoutes: Routes = [
    {
        path: routes.admin.path,
        loadChildren: () => import('./modules/admin/admin.module').then(m => m.AdminModule),
        canLoad: [LoginGuard, AdminGuard],
    },
    {
        path: routes.employee.path,
        loadChildren: () => import('./modules/employee/employee.module').then(m => m.EmployeeModule),
        canLoad: [LoginGuard],
    }, {
        path: routes.login.path,
        component: LoginControllerComponent,
    }, {
        path: routes.signup.path,
        component: SignupComponent,
    }, {
        path: routes.companySignup.path,
        component: CompanySignupComponent,
    }, {
        path: routes.pendingApproval.path,
        component: PendingApprovalComponent,
    }, {
        path: '**',
        redirectTo: 'login',
    },
];

@NgModule({
    imports: [RouterModule.forRoot(appRoutes, { relativeLinkResolution: 'legacy' })],
    exports: [RouterModule],
})

export class AppRoutingModule {
    constructor(router: Router, zone: NgZone) {
        // Init routerSubject on window to allow routing from outside of Angular via js
        window.routerSubject = new Subject<string>();

        // Programmatically navigate whenever a new url gets emitted.
        window.routerSubject.subscribe((url: string) => zone.run(() => router.navigate([`/${ url }`])));
    }
}

declare var window: Window & { routerSubject: Subject<string> };
