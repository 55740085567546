import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { combineLatest, from, Observable } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { Employee, Role, Shift, ShiftForSale, ShiftForSaleStatus } from '../../../../../../../core/model';
import { CompanySetting, CompanySettingsService, EmployeeService, MediaService, ShiftForSaleService, ShiftService } from '../../../../../../../core/services';
import { ModalService } from '../../../../../../../core/services/ModalService.model';

@Component({
    selector: 'app-free-shifts-widget',
    templateUrl: './free-shifts-widget.component.html',
    styleUrls: ['./free-shifts-widget.component.scss'],
})
export class FreeShiftsWidgetComponent implements OnInit {

    public employee$: Observable<Employee>;
    // All the shifts that the employee can take, both the ones for sale and the free ones
    public shiftsForSale$: Observable<ShiftForSale[]>;

    public selectedReciveableShift: ShiftForSale;

    public shiftsShown: number = 10;
    public shiftsShownIncrease: number = 10;

    constructor(
        public modalService: ModalService,
        private employeeService: EmployeeService,
        private shiftService: ShiftService,
        private shiftForSaleService: ShiftForSaleService,
        private cdr: ChangeDetectorRef,
        private companySettingsService: CompanySettingsService,
    ) { }

    public ngOnInit(): void {
        this.employee$ = from(this.employeeService.getAuthenticatedEmployee());

        this.shiftsForSale$ = this.employee$.pipe(
            switchMap((employee: Employee) => this.setupReceivableShiftObservable(employee)),
            map(([futureShiftsForEmployee, shiftsForSale]: [Shift[], ShiftForSale[]]) =>
                this.constructReceivableShiftList(futureShiftsForEmployee, shiftsForSale),
            ), tap(() => {
                return setTimeout(() => MediaService.detectChanges(this.cdr), 0);
            }));
    }

    /**
     * Setup an observable of future shifts, free shifts and shifts for sale relative to an employee
     * @param employee The employee
     */
    public setupReceivableShiftObservable(employee: Employee): Observable<[Shift[], ShiftForSale[]]> {
        // Get the shifts for the employee to calculate conflicts
        const futureShiftsForEmployee$: Observable<Shift[]> = this.shiftService.getFutureShiftsForEmployee(employee);
        // Get all pending shifts for sale from now (or an empty array, if shift trade is disabled)
        const shiftsForSale$: Observable<ShiftForSale[]> =
            combineLatest([
                this.companySettingsService.loadSetting(CompanySetting.SHIFT_TRADE),
                this.shiftForSaleService.getShiftsForSale(
                    undefined,
                    ShiftForSaleStatus.PENDING,
                    undefined,
                    new Date())]).pipe(
                        map(([shiftTradeEnabled, shiftsForSale]: [boolean, ShiftForSale[]]) =>
                            shiftsForSale
                                .filter((shiftForSale: ShiftForSale) => shiftTradeEnabled || !shiftForSale.seller)
                                .filter((shiftForSale: ShiftForSale) => employee.roles
                                    .find((role: Role) => role.id === shiftForSale.shift.role.id))),
                    );
        return combineLatest([futureShiftsForEmployee$, shiftsForSale$]);
    }


    /**
     * Remove all shifts conclicting with the input shift
     * @param shift The shift to remove
     */
    public removeShifts(shift: Shift): void {
        this.shiftsForSale$ = this.shiftsForSale$.pipe(
            map((shifts: ShiftForSale[]) => shifts.filter((s: ShiftForSale) => {
                return !ShiftService.conflictingShifts(shift, s.shift);
            })),
        );
    }

    /**
     * Increases the amount of shifts shown
     */
    public increaseShownShifts(): void {
        this.shiftsShown += this.shiftsShownIncrease;
    }

    /**
     * Construct a list of ReceivableShifts by merging the list of free shifts with the shifts for sale.
     *  The ReceivableShifts that are conflicting with the future shifts of the employee are removed.
     * @param futureShiftsForEmployee all future shifts for an employee
     * @param shiftsForSale all pending shifts for sale
     */
    private constructReceivableShiftList(
        futureShiftsForEmployee: Shift[],
        shiftsForSale: ShiftForSale[]): ShiftForSale[] {

        // Filter out the shifts for sale where the employee already works
        const shiftsForSaleForEmployee: ShiftForSale[] = shiftsForSale.filter((shiftForSale: ShiftForSale) =>
            !futureShiftsForEmployee.some((shift: Shift) => ShiftService.conflictingShifts(shift, shiftForSale.shift)),
        );

        const reciveableShifts: ShiftForSale[] = shiftsForSaleForEmployee;

        reciveableShifts.sort((a: ShiftForSale, b: ShiftForSale) => {
            const astart: Date = a.shift.start;
            const bstart: Date = b.shift.start;
            return astart.getTime() - bstart.getTime();
        });

        return reciveableShifts;
    }
}
