<div class="profile-picture-modal text-center">
    <h4>{{'profile-picture.crop-image' | translate | capitalize}}</h4>
    <i class="fa fa-spin fa-spinner my-5"
       *ngIf="loading"></i>
    <div class="cropper-container">
        <image-cropper [imageChangedEvent]="data.imageChangedEvent"
                       [maintainAspectRatio]="true"
                       format="jpg"
                       resizeToWidth="300"
                       [roundCropper]="true"
                       [onlyScaleDown]="true"
                       (cropperReady)="loading = false"
                       (imageCropped)="imageCropped($event)"
                       #cropper>
        </image-cropper>
    </div>
    <ng-container *ngIf="!loading">
        <div class="save">
            <ui-button-action (click)="upload()" [loading]="saving">
                {{'profile-picture.save-image' | translate | capitalize}}
            </ui-button-action>
        </div>
        <div class="cancel">
            <ui-button-text (click)="closeModal()">
                {{'shared.cancel' | translate }}
            </ui-button-text>
        </div>
    </ng-container>
</div>
