import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';

@Component({
    selector: 'ui-toggle',
    templateUrl: './toggle.component.html',
    styleUrls: ['./toggle.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class ToggleComponent {
    @Input() public checked: boolean;
    @Input() public disabled: boolean;
    @Input() public labelPosition: 'before' | 'after';
    @Output() public checkedChange: EventEmitter<boolean>;

    constructor() {
        this.checkedChange = new EventEmitter();
    }

    /**
     * Emits the change event coming from the internal module
     *
     * @param {MatSlideToggleChange} change
     * @memberof ToggleComponent
     */
    public onChange(change: MatSlideToggleChange): void {
        this.checked = change.checked;
        this.checkedChange.emit(change.checked);
    }

}
