import { ChangeDetectorRef, Component, Injector, Input, OnInit } from '@angular/core';
import { combineLatest } from 'rxjs';
import { take } from 'rxjs/operators';
import { PunchSource, Shift } from '../../../../core/model';
import { CompanySetting, CompanySettingsService, ShiftService } from '../../../../core/services';
import { createApproval } from '../../../utilities/ApprovalUtils';

@Component({
    selector: 'app-shift-detail-approve-punch-action',
    templateUrl: './shift-detail-approve-punch-action.component.html',
    styleUrls: ['./shift-detail-approve-punch-action.component.scss'],
})
export class ShiftDetailApprovePunchActionComponent implements OnInit {

    @Input() public shift: Shift;
    public start?: Date;
    public end?: Date;

    constructor(
        private injector: Injector,
        private shiftService: ShiftService,
        private companySettingsService: CompanySettingsService,
        private cdr: ChangeDetectorRef,
    ) { }

    public ngOnInit(): void {
        combineLatest([
            this.companySettingsService.loadSetting(CompanySetting.ROUND_CHECKIN),
            this.companySettingsService.loadSetting(CompanySetting.ROUND_CHECKOUT),
        ]).pipe(take(1)).toPromise().then(([roundCheckin, roundCheckout]: [boolean, boolean]) => {
            this.start = this.getStart(this.shift, roundCheckin);
            this.end = this.getEnd(this.shift, roundCheckout);
            this.cdr.detectChanges();
        });
    }

    public async approve(): Promise<void> {
        const approvedShift: Shift = {
            ...this.shift,
            approved: true,
            approval: await createApproval(this.shift, this.injector),
            start: this.start!,
            end: this.end!,
        };
        this.shiftService.updateShift(approvedShift).pipe(take(1)).subscribe();
    }

    private getStart(shift: Shift, roundCheckin: boolean): Date {
        // If we're dealing with a manually edited time, use that
        if (shift.checkin?.source === PunchSource.MANUAL) return new Date(shift.checkin.time);
        // If there's no checkin, use the shift start
        if (!shift.checkin) return new Date(shift.start);
        // If we should round checkin to shift start use shift start 
        if (roundCheckin && shift.checkin.time.getTime() < shift.start.getTime()) return new Date(shift.start);
        // Otherwise return the checkin time
        return new Date(shift.checkin.time);
    }

    private getEnd(shift: Shift, roundCheckout: boolean): Date {
        // If we're dealing with a manually edited time, use that
        if (shift.checkout?.source === PunchSource.MANUAL) return new Date(shift.checkout.time);
        // If there's no checkout, use the shift end
        if (!shift.checkout) return new Date(shift.end);
        // If we should round checkout to shift end use shift end
        if (roundCheckout && shift.checkout.time.getTime() > shift.end.getTime()) return new Date(shift.end);
        // Otherwise return the checkout time
        return new Date(shift.checkout.time);
    }
}
