import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'app-upgrade-overlay-base',
    templateUrl: './upgrade-overlay-base.component.html',
    styleUrls: ['./upgrade-overlay-base.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UpgradeOverlayBaseComponent {

    @Input() public hideQuote: boolean;

    @Output() public close: EventEmitter<void>;

    constructor(
    ) {
        this.close = new EventEmitter();
    }
}
