<div class="salary-dk-ignore-container">
    <div class="logo-container">
        <img [src]="integration | getIntegrationLogo">
        <ui-button-text (click)="openHelpModal()"
                        color="primary">
            <span>{{ 'salary.salary-dk-ignore-help-button' | translate }}</span>
        </ui-button-text>
    </div>
    <overlay-scrollbars class="employee-card-container">
        <div *ngFor="let employee of ignorableEmployees"
             class="employee-card">
            <div class="employee-details">
                <img [lazyLoad]="employee.imageURL">
                <div>
                    <div class="name-container">
                        <div class="name">{{employee.firstname + ' ' + employee.lastname}}</div>
                        <app-label [color]="employee.ignored ? BackgroundColor.ORANGE : BackgroundColor.WARN"
                                   [text]="employee.ignored ? ('shared.ignored' | translate) : ('salary.no-match' | translate)">
                        </app-label>
                    </div>
                    <div class="details">
                        <ng-container *ngIf="employee.CPR">
                            CPR: {{employee.CPR}}
                        </ng-container>
                        {{employee.email}}
                    </div>
                </div>
            </div>
            <ui-button-standard (click)="toggleIgnoredEmployee(employee)">
                {{ employee.ignored ? ('salary.do-not-ignore' | translate) : ('shared.ignore' | translate)}}
            </ui-button-standard>
        </div>
    </overlay-scrollbars>
    <div class="help-text-container">
        <span class="text">{{ 'salary.salary-dk-ignore-description' | translate }}</span>
    </div>
    <div class="bottom-buttons">
        <ui-button-standard (click)="close()">{{'shared.cancel' | translate }}</ui-button-standard>
        <ui-button-action (click)="saveMatches()">{{'shared.finish' | translate}}</ui-button-action>
    </div>
</div>