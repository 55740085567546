import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { UserActivity, UserActivityService } from '../../../../../../core/services';

@Component({
    selector: 'app-welcome-overlay',
    templateUrl: './welcome-overlay.component.html',
    styleUrls: ['./welcome-overlay.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WelcomeOverlayComponent {

    constructor(
        private userActivityService: UserActivityService,
        private dialogRef: MatDialogRef<WelcomeOverlayComponent>) { }

    public closeClicked(): void {
        this.userActivityService.save(UserActivity.CREATED_FIRST_DEPARTMENT).subscribe();
        this.dialogRef.close();
    }
}
