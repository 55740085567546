import { Component, EventEmitter, OnInit, Output, TrackByFunction } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { Employee, Role, sortEmployeesByName } from '../../../core/model';
import { AuthService, AuthUser, EmployeeService } from '../../../core/services';
import { ModalService } from '../../../core/services/ModalService.model';

@Component({
    selector: 'app-team-list',
    templateUrl: './team-list.component.html',
    styleUrls: ['./team-list.component.scss'],
})
export class TeamListComponent implements OnInit {
    public employees$: Observable<Employee[]>;
    public filteredEmployees: Employee[] = [];
    public user$: Observable<AuthUser | null | undefined>;
    @Output() public employeeClicked: EventEmitter<Employee>;

    constructor(
        private employeeService: EmployeeService,
        private modalService: ModalService,
        private authService: AuthService,
        private translateService: TranslateService,
    ) {
        this.employeeClicked = new EventEmitter();
    }

    public ngOnInit(): void {
        this.user$ = this.authService.getUser();
        this.employees$ = this.employeeService.getEmployees({ isDeleted: false })
            .pipe(map((employees: Employee[]) => employees.sort(sortEmployeesByName)));
    }

    public trackEmployee: TrackByFunction<Employee> = (_index: number, employee: Employee) => employee.id;

    /**
     * This method adds all the roles to a single string, so that it can be inserted in the standard component.
     * The roles are divided by a ', ' expect the last
     */
    public outputRoles(roles: Role[]): Observable<string> {
        return this.translateService.get('shared.and')
            .pipe(
                take(1),
                map((word: string) => {
                    let roleString: string = '';
                    roles.forEach((role: Role, index: number) => {
                        if (index < roles.length - 2) roleString += role.name + ', ';
                        else if (index === roles.length - 2) roleString += role.name + ' ' + word + ' ';
                        else roleString += role.name;
                    });
                    return roleString;
                }));
    }

    /**
     * Combines a list of variables into a single string to allow searching in
     * one of the provided fields.
     *
     * roleString: Maps the list of roles into being the names of each role, and reduces them all into
     * a single string with an empty space between names.
     */
    public searchOn: (emp: Employee) => string = emp => {
        const roleString: string = emp.roles.reduce((roleNames: string, role: Role) => roleNames + ' ' + role.name, '');

        return emp.firstname + ' '
            + emp.lastname + ' '
            + roleString + ' '
            + emp.phone;
    }

    public openInviteModal(): void {
        this.modalService.openInviteModal();
    }
    /**
     * Opens the modal which you can contact your employee from
     * @param employee you want to contact
     */
    public openContactEmployeeModal(employee: Employee, event: Event): void {
        event.cancelBubble = true;
        this.modalService.openContactEmployeeModal(employee);
    }
}
