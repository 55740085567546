import { Department } from '../Department/Department.model';

export const companiesCollectionPath: string = 'Virksomheder';
export const daysCollectionPath: string = 'days';
export const departmentsCollectionPath: string = 'departments';
export const employeesCollectionPath: string = 'employees';
export const integrationsCollectionPath: string = 'integrations';
export const rolesCollectionPath: string = 'roles';
export const salarySupplementsCollectionPath: string = 'salarySupplements';
export const shiftsCollectionPath: string = 'shifts';
export const shiftsForSaleCollectionPath: string = 'shiftsForSale';
export const subscriptionsCollectionPath: string = 'subscriptions';
export const wishesCollectionPath: string = 'wishes';
export function getDaysCollectionPath(department: Department): string {
    return departmentsCollectionPath + '/' + department.id + '/' + daysCollectionPath;
}
