import { Injectable } from '@angular/core';
import { CanLoad, Route, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { AuthService, AuthUser } from '../services';

@Injectable()
export class AdminGuard implements CanLoad {
    constructor(private authService: AuthService, private router: Router) { }

    /**
     * This method is called through the CanActivate
     * @param route The route that is attempted
     * @param state The current state of the router
     */
    public canLoad(route: Route): Observable<boolean | UrlTree> {
        return this.authService.getUser().pipe(
            // Only look at the user if it's defined
            filter((user: AuthUser) => !!user),

            // Do the access check
            map((user: AuthUser) => {
                if (user.admin || user.companyID === route.data?.allowAccessWithCompanyID) {
                    // If user is admin or if employees are allowed access in the user's company, let the user proceed
                    return true;
                } else {
                    // Otherwise, redirect to the employee page
                    return this.router.createUrlTree(['/employee']);
                }
            }),
        );
    }
}
