<div class="shiftCard day-view-shift"
     [class.web]="!mobileVersion"
     [class.mobile]="mobileVersion">
    <app-salary-swipe-label [shift]="shift"
                            [dayViewShift]="true"
                            *ngIf="mediaService.isBigScreen() && !mobileVersion">
    </app-salary-swipe-label>
    <div class="info-container">
        <div class="pic-role-employee-container">
            <img src="{{ shift.employee?.imageURL || '/assets/images/no-employee.svg' }}"
                 class="profile-picture">
            <div class="role-employee-container">
                <div ngbDropdown
                     [placement]="['bottom-left', 'top-left', 'bottom-right']"
                     [container]="weekViewPopover ? '' : 'body'"
                     class="role-container">
                    <div ngbDropdownToggle
                         class="dropdown-toggle role-dropdown-toggle">
                        <div class="role-text">
                            <div class="role-color"
                                 [ngClass]="shift.role?.color"></div>
                            {{ shift.role?.name || ('admin.calendar.choose-role' | translate | capitalize) }}
                        </div>
                        <i class="fa fa-angle-down"></i>
                    </div>
                    <div ngbDropdownMenu
                         [class.mobile]="mobileVersion"
                         class="role-menu day-view-menu">
                        <div class="role-menu-container"
                             appTrapScroll>
                            <div ngbDropdownItem
                                 class="role-menu-item"
                                 *ngFor="let role of roles$ | async"
                                 (click)="setRole(shift, role)">
                                <div class="role-color"
                                     [ngClass]="role?.color"></div>
                                {{ role?.name }}
                            </div>
                            <ng-container *ngIf="!mobileVersion">
                                <div class="line"></div>
                                <button ngbDropdownItem
                                        class="role-menu-item"
                                        (click)="openEditDepartmentModal()">
                                    {{ 'roles.manage-roles' | translate }}
                                </button>
                            </ng-container>
                        </div>
                    </div>
                </div>

                <ng-template #rt
                             let-r="result">
                    <ng-container *ngIf="r | employeeSource as source">
                        <ng-container *ngIf="source.type === EmployeeSourceType.FreeShift">
                            <div class="employee-card"
                                 [class.mobile]="mobileVersion">
                                <div class="employee-pic greyed-out"></div>
                                <div class="no-employee-text">{{'admin.calendar.free-shift' | translate }}</div>
                            </div>
                            <div *ngIf="!(wishesAndHours$ | async)"
                                 class="loading-wishes">
                                <div class="spinner-container">
                                    <i class="fad fa-spin fa-spinner-third"></i>
                                </div>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="source.type === EmployeeSourceType.Divider">
                            <div class="divider">
                                <div class="employee-text">
                                    {{ source.data.translationKey | translate: source.data.translationParams }}
                                </div>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="source.type === EmployeeSourceType.Wish">
                            <div class="employee-card"
                                 [class.mobile]="mobileVersion">
                                <img class="employee-pic"
                                     [lazyLoad]="source.data.wish.employee.imageURL">
                                <div class="column flex-grow-1">
                                    <div class="employee-text">
                                        {{ source.name }}
                                    </div>
                                    <div class="employee-availability-container">
                                        <div class="employee-availability-dot"
                                             [class.wish-work]="source.data.wish.type === wishTypes.WORK"
                                             [class.wish-no-wish]="source.data.wish.type === wishTypes.NO_WISH">
                                        </div>
                                        <div class="employee-availability-text">
                                            {{ getWishType(source.data.wish.type) | translate | capitalize}}
                                            <span *ngIf="source.data.wish.type !== wishTypes.NO_WISH">
                                                {{ source.data.wish | wishTimeToText}}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="column hours-this-week">
                                    <div> {{ 'widget.this-week' | translate }} </div>
                                    <div class="hours">
                                        {{ source.data.hoursThisWeek | number: '1.0-2' }}
                                        {{ source.data.hoursThisWeek | number: '1.0-2' | plural : ('time.hour' |
                                        translate) : ('time.hours' | translate) }}
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="source.type === EmployeeSourceType.NewEmployee">
                            <div class="employee-card"
                                 [class.mobile]="mobileVersion">
                                <div class="employee-pic greyed-out"></div>
                                <div class="no-employee-text">{{'shared.new-employee' | translate }}</div>
                            </div>
                        </ng-container>
                    </ng-container>
                </ng-template>

                <div class="employee-dropdown-container">
                    <div class="dropdown-toggle employee-dropdown-toggle">
                        <input appNgTypeaheadScroll
                               [isPopupOpen]="typeaheadInstance.isPopupOpen()"
                               [popupId]="typeaheadInstance.popupId"
                               (selectItem)="onSelectItem($event)"
                               type="text"
                               [placement]="mobileVersion ? ['bottom-right'] : ['bottom-left', 'top-left']"
                               container="body"
                               [(ngModel)]="employeeSourceModel"
                               [ngbTypeahead]="search"
                               [resultTemplate]="rt"
                               [inputFormatter]="formatter"
                               (focus)="onFocus($event)"
                               (click)="onClick($event)"
                               #typeaheadInstance="ngbTypeahead"
                               class="employee-text"
                               #typeaheadInputField
                               [class.released-and-unassigned]="shift.released && !shift.employee"
                               placeholder="{{ shift.employee ? shift.employee?.firstname + ' ' + shift.employee?.lastname : 'admin.calendar.free-shift' | translate }}" />
                        <i class="fa fa-angle-down"
                           (click)="onClickArrowDown()"></i>
                    </div>
                </div>
            </div>
        </div>

        <div class="info-container">
            <div class="released-status">
                <span *ngIf="shift.released">{{ 'shared.released' | translate | capitalize }}</span>
                <span *ngIf="!shift.released">{{ 'shared.not-released' | translate | capitalize}}</span>
            </div>

            <ui-time-input class="time-input"
                           [(start)]="shift.start"
                           [(end)]="shift.end"
                           (startChange)="onShiftTimeChange()"
                           (endChange)="onShiftTimeChange()"
                           [bigScreen]="!mobileVersion">
            </ui-time-input>
        </div>

        <div class="duration">
            <span class="duration-text">{{ 'shared.total' | translate | capitalize}}</span>
            {{ durationText$ | async }}
        </div>

        <ng-template #commentPopover>
            <textarea *appIfPro="packages.PRO"
                      class="comment-textarea"
                      placeholder="{{ 'admin.calendar.comment-placeholder' | translate | capitalize }}..."
                      (blur)="saveComment($event)"
                      (input)="onInputComment($event)">{{shift.comment?.message}}</textarea>

            <ng-container *ngIf="mediaService.observeMediaChanges() | async">
                <div class="pro-feature"
                     *appIfNotPro="packages.PRO">
                    <div class="title">
                        {{ 'modal.pro-feature.title' | translate }}
                    </div>
                    <div class="description">
                        {{ 'modal.pro-feature.descriptions.shift-comment' | translate }}
                    </div>
                    <app-upgrade-button></app-upgrade-button>
                </div>
            </ng-container>
        </ng-template>

        <ng-container *ngIf="!(mediaService.observeMediaChanges() | async)"
                      [ngTemplateOutlet]="commentPopover">
        </ng-container>

        <div class="buttons">
            <ui-button-icon *ngIf="mediaService.observeMediaChanges() | async"
                            [class.hidden]="!isValid(shift)"
                            [class.contains-comment]="commentExists"
                            matTooltip="{{ 'admin.calendar.comment-shift-tooltip' | translate | capitalize}}"
                            [ngbPopover]="commentPopover"
                            [placement]="['bottom', 'top']"
                            popoverClass="comment-popover"
                            [container]="weekViewPopover ? '' : 'body'"
                            autoClose="outside">
                <i class="far fa-comment"></i>
            </ui-button-icon>
            <ui-button-icon [class.hidden]="!isValid(shift)"
                            matTooltip="{{ 'admin.calendar.duplicate-shift' | translate | capitalize}}"
                            (click)="duplicateShift(shift)">
                <i class="far fa-clone"></i>
            </ui-button-icon>

            <ui-button-icon class="button-trash"
                            matTooltip="{{ 'admin.calendar.delete-shift' | translate | capitalize}}"
                            (click)="deleteShift(shift)">
                <i class="far fa-trash-alt"></i>
            </ui-button-icon>
        </div>
    </div>
</div>