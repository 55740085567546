<div class="api-key-guide-container">
    <div class="list">
        <div class="list-item">
            <div class="step-number">1</div> <a href="https://dataloen.bluegarden.dk"
               target="_blank">{{ 'integrations.dataloen.help-step-1' | translate }}</a>
        </div>
        <div class="list-item long-text">
            <div class="step-number">2</div>{{ 'integrations.dataloen.help-step-2' | translate }}
        </div>
        <div class="list-item long-text">
            <div class="step-number">3</div>{{ 'integrations.dataloen.help-step-3' | translate }}
        </div>
        <div class="list-item">
            <div class="step-number">4</div>{{ 'integrations.dataloen.help-step-4' | translate }}
        </div>
    </div>
    <ui-button-standard (click)="modalRef.close()">{{'shared.close' | translate}}</ui-button-standard>
</div>