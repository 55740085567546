export enum SpecialDay {
    VALENTINES_DAY = 'special-days.valentines_day',
    SHROVETIDE = 'special-days.shrovetide', // Fastelavn
    SAINT_PATRICKS_DAY = 'special-days.saint_patricks_day',
    MOTHERS_DAY = 'special-days.mothers_day',
    FATHERS_DAY = 'special-days.fathers_day',
    WOMENS_DAY = 'special-days.womens_day', // Kvindernes internationale kampdag
    EASTER_HOLIDAY = 'special-days.easter_holiday',
    SAINT_JOHNS_EVE = 'special-days.saint_johns_eve', // Sankt Hans aften
    AUTUMN_HOLIDAY = 'special-days.autumn_holiday',
    HALLOWEEN = 'special-days.halloween',
    J_DAY = 'special-days.j_day',
    SAINT_MARTINS_EVE = 'special-days.saint_martins_eve', // Mortens aften
    BLACK_FRIDAY = 'special-days.black_friday',
    ADVENT_1 = 'special-days.advent_1',
    ADVENT_2 = 'special-days.advent_2',
    ADVENT_3 = 'special-days.advent_3',
    ADVENT_4 = 'special-days.advent_4',
    DECEMBER_23RD = 'special-days.december_23rd',
    CHRISTMAS_EVE = 'special-days.christmas_eve',
    DST_END = 'special-days.dst-end',
    DST_START = 'special-days.dst-start',
    NEW_YEARS_EVE = 'special-days.new-years-eve',
}
