<div class="mobile-wish-container">
    <app-header [hideBottomBorder]="true">
        <div class="center">
            <ui-month-picker [date]="selectedDate$ | async"
                             (dateChange)="dateService.changeDate($event)"></ui-month-picker>
        </div>
    </app-header>
    <div class="wish-calendar-container">
        <ui-date-picker [startDate]="selectedDate$ | async"
                        (dateSelect)="dateService.changeDate($event);"
                        [selectionStyle]="selectionStyles.CIRCLE"
                        [colorDateMap]="wishColors$ | async">
        </ui-date-picker>
        <div class="selected-date-text text-center">
            {{ selectedDate$ | async | date: 'EEEE d. MMMM ':'':locale | capitalize }}
        </div>
        <app-wish-toggle [wish]="selectedWish$ | async"
                         (wishTypeChange)="updateSelectedWishType($event)"></app-wish-toggle>
        <app-wish-legend></app-wish-legend>
    </div>
    <div class="custom-wish-container">
        <div class="custom-wish-card">
            <ui-button-text *ngIf="!customWishTime"
                            (click)="enableWishTimeRange()">
                {{'employee.calendar.press-to-add-time' | translate | capitalize}}
            </ui-button-text>
            <div class="custom-wish-item"
                 *ngIf="customWishTime">
                <ui-time-input [start]="(selectedWish$ | async)?.from"
                               [end]="(selectedWish$ | async)?.to"
                               (startChange)="updateWishTimes({from: $event})"
                               (endChange)="updateWishTimes({to: $event})"></ui-time-input>
                <ui-button-text class="remove-button"
                                color="warn"
                                (click)="removeCustomTime()">{{'shared.remove' | translate | capitalize }}
                </ui-button-text>
            </div>
        </div>
    </div>
</div>