<div class="integration-container"
     [class.no-bottom-radius]="noBottomRadius"
     [ngClass]="[ (integrationStatusInfo$ | async)?.borderColor || '', (integrationStatusInfo$ | async)?.integration || '' ]"
     [appProLabel]="proPackage"
     [wide]="true">
    <div class="logo-and-label">
        <div class="logo">
            <ng-content></ng-content>
        </div>
        <app-label *ngIf="integrationStatusInfo$ | async as statusInfo"
                   [text]="statusInfo!.labelText"
                   [color]="statusInfo!.labelColor">
        </app-label>
    </div>

    <div class="integration-button"
         *ngIf="isPro$ | async">
        <ui-button-text *ngIf="!(integrationStatusInfo$ | async)"
                        color="primary"
                        (click)="integrateClicked.emit()">
            <span>
                {{ 'shared.begin-integration' | translate | capitalize }}
            </span>
        </ui-button-text>

        <div ngbDropdown
             placement="bottom-left"
             container="body"
             *ngIf="integrationStatusInfo$ | async">
            <ui-button-text ngbDropdownToggle
                            color="primary">
                <span>
                    {{ 'sidebar.settings' | translate | capitalize }}
                </span>
            </ui-button-text>
            <div ngbDropdownMenu>
                <div ngbDropdownItem
                     (click)="integrateClicked.emit()">{{ contextMenuText }}</div>
                <div ngbDropdownItem
                     (click)="removeIntegrationClicked.emit()">{{ 'salary.remove-integration' | translate }}</div>
            </div>
        </div>
    </div>
    <div class="upgrade-button"
         *ngIf="!(isPro$ | async)">
        <ui-button-text color="primary"
                        (click)="modalService.openProShopOverlay()">
            <span>
                {{(freeTrialAvailable$ | async) ? ('freemium.try-30-days-free' | translate) : ('freemium.upgrade-to-pro' | translate)}}
            </span>
        </ui-button-text>
    </div>
</div>
