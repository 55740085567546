import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'capitalize' })
export class CapitalizePipe implements PipeTransform {

    /**
     * Capitalizes a string value
     * @param value - the string to be capitalized
     */
    public transform(value: string): string {
        if (value) {
            return value.charAt(0).toUpperCase() + value.toLowerCase().slice(1);
        }
        return value;
    }
}
