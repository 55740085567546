import * as moment from 'moment';
import { NotificationType } from '../../../../functions/src/notifications/model/NotificationType';


export enum Language {
    ENGLISH = 'en',
    DANISH = 'da',
}

export function isLanguage(maybeLang: unknown): maybeLang is Language {
    return !!Object.values(Language).find(((lang: Language) => lang === maybeLang));
}

export enum GroupShiftsBy {
    ROLE = 'role',
    TIME = 'time',
}

export interface SalaryRange {
    start: Date;
    end: Date;
}

export interface WeekViewRange {
    start: Date;
    end: Date;
}

export interface LastUsedTimeSpan {
    startHour: number;
    startMinute: number;
    endHour: number;
    endMinute: number;
}


export enum UserSettings {
    LANGUAGE = 'language',
    GROUP_SHIFTS_BY = 'groupShiftsBy',
    SALARY_RANGE = 'salaryRange',
    WEEK_VIEW_RANGE = 'weekViewRange',
    LATEST_INSERTED_TEMPLATE = 'latestInsertedTemplate',
    LATEST_SELECTED_DEPARTMENT = 'latestSelectedDepartment',
    LATEST_SELECTED_ROLE = 'latestSelectedRole',
    DISABLED_NOTIFICATIONS = 'disabledNotifications',
    SHOW_PROFILE_PICTURES_IN_WEEK_VIEW = 'showProfilePicturesInWeekView',
    SHOW_HOURS_IN_WEEK_VIEW = 'showHoursInWeekView',
    SHOW_SALARY_IN_WEEK_VIEW = 'showSalaryInWeekView',
    SHOW_REVENUE_IN_WEEK_VIEW = 'showRevenueInWeekView',
    SHOW_LABOR_PERCENTAGE_IN_WEEK_VIEW = 'showLaborPercentageInWeekView',
    LAST_USED_TIMESPAN = 'lastUsedTimeSpan',
}

export interface UserSettingsTypeMap {
    [UserSettings.LANGUAGE]: Language;
    [UserSettings.GROUP_SHIFTS_BY]: GroupShiftsBy;
    [UserSettings.SALARY_RANGE]: SalaryRange;
    [UserSettings.WEEK_VIEW_RANGE]: WeekViewRange;
    [UserSettings.LATEST_INSERTED_TEMPLATE]: string;
    [UserSettings.LATEST_SELECTED_DEPARTMENT]: string;
    [UserSettings.LATEST_SELECTED_ROLE]: string;
    [UserSettings.DISABLED_NOTIFICATIONS]: NotificationType[];
    [UserSettings.SHOW_PROFILE_PICTURES_IN_WEEK_VIEW]: boolean;
    [UserSettings.SHOW_HOURS_IN_WEEK_VIEW]: boolean;
    [UserSettings.SHOW_SALARY_IN_WEEK_VIEW]: boolean;
    [UserSettings.SHOW_REVENUE_IN_WEEK_VIEW]: boolean;
    [UserSettings.SHOW_LABOR_PERCENTAGE_IN_WEEK_VIEW]: boolean;
    [UserSettings.LAST_USED_TIMESPAN]: LastUsedTimeSpan;
}

export const DEFAULT_LANGUAGE: Language = Language.DANISH;
export const DEFAULT_WEEK_VIEW_RANGE: WeekViewRange = {
    // Default range is current month
    start: moment().startOf('isoWeek').toDate(),
    end: moment().endOf('isoWeek').add(3, 'weeks').toDate(),
};

export const DEFAULT_LAST_USED_TIMESPAN: LastUsedTimeSpan = {
    startHour: 9,
    startMinute: 0,
    endHour: 17,
    endMinute: 0,
};

export const DEFAULT_GROUP_SHIFTS_BY: GroupShiftsBy = GroupShiftsBy.TIME;

export const DEFAULT_DISABLED_NOTIFICATIONS: NotificationType[] = [];
export const DEFAULT_SHOW_PROFILE_PICTURES_IN_WEEK_VIEW: boolean = false;
export const DEFAULT_SHOW_HOURS_IN_WEEK_VIEW: boolean = false;
export const DEFAULT_SHOW_SALARY_IN_WEEK_VIEW: boolean = false;
export const DEFAULT_SHOW_REVENUE_IN_WEEK_VIEW: boolean = false;
export const DEFAULT_SHOW_LABOR_PERCENTAGE_IN_WEEK_VIEW: boolean = false;
