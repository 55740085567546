import { Component, OnInit, TrackByFunction } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import * as _ from 'lodash';
import { map, take } from 'rxjs/operators';
import { Employee, sortEmployeesByName } from '../../../../../core/model';
import { EmployeeService } from '../../../../../core/services';
import { SnackbarColor, SnackbarService } from '../../../../../core/services/snackbar/snackbar.service';
import { LazyLoadImageProviderFactory } from '../../../../../shared/factories/LazyLoadImageHooks';

@Component({
    selector: 'app-set-hourly-wage-list',
    templateUrl: './set-hourly-wage-list.component.html',
    styleUrls: ['./set-hourly-wage-list.component.scss'],
    providers: [LazyLoadImageProviderFactory(1)],
})
export class SetHourlyWageListComponent implements OnInit {
    public employees: Employee[];
    public hourlyWageFormGroup: FormGroup;
    constructor(
        private employeeService: EmployeeService,
        private snackbarService: SnackbarService,
    ) { }

    public async ngOnInit(): Promise<void> {
        this.employees = await this.employeeService.getEmployees().pipe(take(1), map((employees: Employee[]) => {
            // Sort by hourlyWage ≠ null and then name
            const [empsWithoutWage, empsWithWage]: Employee[][] = _.partition(employees, { hourlyWage: null });
            return empsWithoutWage.sort(sortEmployeesByName).concat(empsWithWage.sort(sortEmployeesByName));
        })).toPromise();
        this.setForm(this.employees);
    }

    public trackEmployee: TrackByFunction<Employee> = (_index: number, employee: Employee) => employee.id;

    /**
     * Sets a hourly wage on a given employee
     * @param event is the value we get from the input fields
     * @param employee is the one you set the hourly wage on
     */
    public setEmployeeHourlyWage(employee: Employee): void {
        const oldHourlyWage = employee.hourlyWage;
        const empId = employee.id;
        const hourlyWage: string = this.hourlyWageFormGroup.controls[empId]!.value;
        if (hourlyWage !== '') {
            employee.hourlyWage = Number(hourlyWage.replace(',', '.'));
        } else {
            employee.hourlyWage = null;
        }
        this.employeeService.updateEmployee(employee).pipe(
            take(1),
        ).subscribe({
            error: (error: Error) => {
                this.hourlyWageFormGroup.controls[empId]!.setValue(String(oldHourlyWage));
                this.snackbarService.displaySnack({
                    translationKey: 'error.unable-to-update-salary',
                    translationParams: {
                        name: employee.firstname + ' ' + employee.lastname,
                    },
                }, SnackbarColor.warn);
                throw error;
            },
        });
    }
    /**
     * Instanciates the form, and sets the controlnames to be the id's of the employees
     * @param employees that you want to use the id's from
     */
    public setForm(employees: Employee[]): void {
        this.hourlyWageFormGroup = new FormGroup({});
        employees.forEach((emp: Employee) => this.hourlyWageFormGroup.addControl(
            emp.id, new FormControl(emp != null && emp.hourlyWage != null ? String(emp.hourlyWage).replace('.', ',') : '',
                [Validators.pattern('^[0-9]+(,[0-9]{1,2})?$')]),
        ),
        );
    }
}
