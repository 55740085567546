import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NotificationType } from '../../../../../functions/src/notifications/model/NotificationType';

@Component({
    selector: 'app-notification-settings-toggle',
    templateUrl: './notification-settings-toggle.component.html',
    styleUrls: ['./notification-settings-toggle.component.scss'],
})
export class NotificationSettingsToggleComponent {
    @Input() public title: string;
    @Input() public checked: boolean;
    @Output() public checkedChange: EventEmitter<{ checked: boolean, notificationType: NotificationType }>;
    @Input() public notificationType: NotificationType;

    constructor() {
        this.checkedChange = new EventEmitter();
    }

    public onCheckChanged(checked: boolean, notificationType: NotificationType): void {
        this.checkedChange.emit({ checked, notificationType });
    }

}
