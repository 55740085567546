import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Department } from '../../../../../core/model/Department/Department.model';
import { DepartmentImpl } from '../../../../../core/model/Department/DepartmentImpl';
import { DepartmentService } from '../../../../../core/services/Department/DepartmentService.model';

@Component({
    selector: 'app-edit-departments-modal',
    templateUrl: './edit-departments-modal.component.html',
    styleUrls: ['./edit-departments-modal.component.scss'],
})
export class EditDepartmentsModalComponent implements OnInit {
    public selectedDepartment: Department;
    public departments$: Observable<Department[]>;

    constructor(private departmentService: DepartmentService,
        @Inject(MAT_DIALOG_DATA) private department?: Department) { }

    ngOnInit() {
        if (this.department) {
            this.selectedDepartment = this.department;
        }
        this.departments$ = this.departmentService.getDepartments()
            .pipe(map((departments: Department[]) =>
                departments.sort(DepartmentImpl.sortByName)));
    }

    public setSelectedDepartment(department: Department) {
        this.selectedDepartment = department;
    }

}
