import { Injectable } from '@angular/core';
import { QueryDocumentSnapshot, QuerySnapshot } from '@angular/fire/firestore';
import { combineLatest, Observable } from 'rxjs';
import { distinctUntilChanged, map, shareReplay, switchMap, take } from 'rxjs/operators';
import { Auth, CompanySetting, CompanySettingsService, CompanySettingsTypeMap, FirestoreService } from '../../..';
import { IntegrationStatusInfo } from '../../../../../shared/ui/integration-panel/integration-panel.component';
import { GPSZone, ShiftBreak } from '../../../../model';
import { FirestoreCompanySettings, FirestoreCompanySettingsCategories } from '../../../../model/Firestore/FirestoreCompanySettings';
import Timestamp from '../../../../model/Firestore/FirestoreTimestamp';
import { Package } from '../../../../model/Freemium/Package';
import { SalaryIntegration } from '../../../../model/Integrations/Shared';
import { ConfigurationService } from '../../../Configuration/configuration-service.model';
import { IntegrationSettingsService } from '../../IntegrationSettings/Firestore/integration-settings.service';

type Restricted<T extends CompanySetting> = {
    restricted: true;
    restrictedValue: CompanySettingsTypeMap[T];
} | {
    restricted: false;
};

@Injectable({
    providedIn: 'root',
})
export class FirestoreCompanySettingsService extends CompanySettingsService {
    private cachedSettings$: Observable<FirestoreCompanySettings>;

    constructor(
        private firestoreService: FirestoreService,
        private configurationService: ConfigurationService,
        private integrationSettingsService: IntegrationSettingsService,
    ) {
        super();
        this.cachedSettings$ = this.firestoreService.authenticate<FirestoreCompanySettingsCategories>('settings').pipe(
            switchMap((auth: Auth<FirestoreCompanySettingsCategories>) => this.firestoreService.observeOnSnapshot(auth.collection)),
            map(this.buildSettingsObject),
            shareReplay(1));
    }

    /**
     * Load a setting
     * @param setting The name of the setting
     */
    public loadSetting<T extends CompanySetting>(setting: T):
        Observable<CompanySettingsTypeMap[T]> {
        const { docId: categoryDocID, field }: { docId: string, field: string } = this.getDocIdAndField(setting);
        return combineLatest([
            this.cachedSettings$,
            // Combine with the conf service to trigger the load setting observable if there's a package change
            this.configurationService.isPro(Package.PRO),
        ]).pipe(
            switchMap(async ([settings, pro]: [FirestoreCompanySettings, boolean]) => {
                const isRestrictedSetting = await this.isRestrictedSetting(setting, pro);
                if (isRestrictedSetting.restricted) {
                    return isRestrictedSetting.restrictedValue as CompanySettingsTypeMap[T];
                }
                const firebaseSettingsCategory: FirestoreCompanySettingsCategories = settings[categoryDocID];
                if (firebaseSettingsCategory) {
                    const firebaseSetting: FirestoreCompanySettingsTypeMap[T] | undefined = firebaseSettingsCategory[field];
                    if (firebaseSetting !== undefined) return this.convertData(setting, firebaseSetting);
                }
                return this.getSettingDefault(setting);
            }),
            distinctUntilChanged(),
        );
    }

    /**
     * Save a setting
     * @param setting The name of the setting
     * @param value
     */
    public async saveSetting<T extends CompanySetting>(setting: T, value: CompanySettingsTypeMap[T]): Promise<void> {
        const { docId, field }: { docId: string, field: string } = this.getDocIdAndField(setting);

        const settings = {};
        settings[field] = value;
        const auth: Auth<FirestoreCompanySettingsCategories> =
            await this.firestoreService.authenticate<FirestoreCompanySettingsCategories>('settings').pipe(take(1)).toPromise();
        return auth.collection.doc(docId).set(settings as FirestoreCompanySettingsCategories, { merge: true });
    }

    private buildSettingsObject(snapshot: QuerySnapshot<FirestoreCompanySettingsCategories>): FirestoreCompanySettings {
        return snapshot.docs.reduce((acc: FirestoreCompanySettings, doc: QueryDocumentSnapshot<FirestoreCompanySettingsCategories>) => ({
            ...acc,
            [doc.id]: doc.data(),
        }), {});
    }

    /**
     * Check whether a setting is restricted
     * @param setting - The setting to check
     */
    private async isRestrictedSetting<T extends CompanySetting>(setting: CompanySetting, pro: boolean): Promise<Restricted<T>> {
        // If the setting is SHIFT_TRADE, check if Schedule pro
        if (setting === CompanySetting.SHIFT_TRADE) {
            // If company is not pro, return restricted and shift change enabled=true
            if (!pro) return { restricted: true, restrictedValue: true as CompanySettingsTypeMap[T] };
        }
        // If the setting is AUTO_APPROVE_AVAILABLE_SHIFT_ENABLED_ON, check if Schedule pro
        if (setting === CompanySetting.AUTO_APPROVE_AVAILABLE_SHIFT_ENABLED_ON) {
            // If company is not pro, return restricted and auto approve = null
            if (!pro) return { restricted: true, restrictedValue: null as CompanySettingsTypeMap[T] };
        }

        // If the setting is PUNCHCLOCK_ENABLED_ON, check if Schedule pro
        if (setting === CompanySetting.PUNCHCLOCK_ENABLED_ON) {
            // Check if company is pro
            if (!pro) {
                return { restricted: true, restrictedValue: null as CompanySettingsTypeMap[T] };
            }
        }

        // If the setting is MANUAL_PUNCHCLOCK, check if Schedule pro
        if (setting === CompanySetting.MANUAL_PUNCHCLOCK) {
            // Check if company is pro
            if (!pro) {
                return { restricted: true, restrictedValue: false as CompanySettingsTypeMap[T] };
            }
        }

        if (setting === CompanySetting.CHECKIN_BEFORE ||
            setting === CompanySetting.CHECKOUT_UNTIL) {
            // Check if punchclock is enabled
            const punchClockEnabled: boolean =
                await this.loadSetting(CompanySetting.PUNCHCLOCK_ENABLED_ON).pipe(
                    map((enabledOn: Date | null) => !!enabledOn), take(1)).toPromise();

            // If punchclock is not enabled return restricted
            if (!punchClockEnabled) {
                return { restricted: true, restrictedValue: null as CompanySettingsTypeMap[T] };
            }
        }

        if (setting === CompanySetting.AUTOMATICALLY_EXPORT_APPROVED_PUNCH_TIMES_TO_SALARY_DK_ENABLED_ON) {
            if (!pro) return { restricted: true, restrictedValue: false as CompanySettingsTypeMap[T] };
            const integrationStatus: boolean = await this.integrationSettingsService
                .getIntegrationStatusInfo(SalaryIntegration.SALARYDK).pipe(
                    map((status: IntegrationStatusInfo | undefined) => !!status), take(1)).toPromise();
            if (!integrationStatus) return { restricted: true, restrictedValue: false as CompanySettingsTypeMap[T] };
        }

        if (setting === CompanySetting.ONLY_INCLUDE_SHIFTS_MARKED_WITH_SWIPE) {
            const automaticallyExportApprovedPunchTimesEnabled: boolean =
                await this.loadSetting(CompanySetting.AUTOMATICALLY_EXPORT_APPROVED_PUNCH_TIMES_TO_SALARY_DK_ENABLED_ON).pipe(
                    map((enabledOn: Date | null) => !!enabledOn), take(1)).toPromise();
            if (!automaticallyExportApprovedPunchTimesEnabled) {
                return { restricted: true, restrictedValue: null as CompanySettingsTypeMap[T] };
            }
        }

        // If the setting is SHIFT_BREAK, check if Salary pro
        if (setting === CompanySetting.SHIFT_BREAK) {
            // If company is not pro, return restricted
            if (!pro) return { restricted: true, restrictedValue: null as CompanySettingsTypeMap[T] };
        }
        // If the setting is SALARY_SUPPLEMENTS_ENABLED_ON, check if Salary pro
        if (setting === CompanySetting.SALARY_SUPPLEMENTS_ENABLED_ON) {
            // If company is not pro, return restricted
            if (!pro) return { restricted: true, restrictedValue: null as CompanySettingsTypeMap[T] };
        }

        if (setting === CompanySetting.LOCATIONS) {
            // If company is not pro, return restricted
            if (!pro) return { restricted: true, restrictedValue: [] as GPSZone[] as CompanySettingsTypeMap[T] };
        }

        if (setting === CompanySetting.ROUND_CHECKIN || setting === CompanySetting.ROUND_CHECKOUT) {
            // If company is not pro, return restricted
            if (!pro) return { restricted: true, restrictedValue: false as CompanySettingsTypeMap[T] };
        }

        // Otherwise return false
        return { restricted: false };
    }

    /**
     * Convert data from the database for a specific setting to application data.
     * @param setting The specific setting
     * @param data The data from the database
     */
    private convertData<T extends CompanySetting>(setting: T, data: FirestoreCompanySettingsTypeMap[T]): CompanySettingsTypeMap[T] {
        if (setting === CompanySetting.PUNCHCLOCK_ENABLED_ON
            || setting === CompanySetting.SALARY_SUPPLEMENTS_ENABLED_ON
            || setting === CompanySetting.AUTO_APPROVE_AVAILABLE_SHIFT_ENABLED_ON) {
            return (data ? (data as Timestamp).toDate() : null) as CompanySettingsTypeMap[T];
        }
        return data as CompanySettingsTypeMap[T];
    }

    /**
     * Get the documentId and the field for a specific setting
     * @param setting The setting to read from or write to in the database
     */
    private getDocIdAndField(setting: CompanySetting): { docId: string, field: string } {
        const path: string[] = setting.split('/');
        return { docId: path[0]!, field: path[1]! };
    }
}

interface FirestoreCompanySettingsTypeMap {
    [CompanySetting.LOCATIONS]: GPSZone[];
    [CompanySetting.SHIFT_TRADE]: boolean;
    [CompanySetting.AUTO_SHIFT_TRADE]: boolean;
    [CompanySetting.CHECKIN_BEFORE]: number;
    [CompanySetting.CHECKOUT_UNTIL]: number;
    [CompanySetting.END_TIME]: number;
    [CompanySetting.END_TIME_STANDARD_WISH]: number;
    [CompanySetting.PUNCHCLOCK_ENABLED_ON]: Timestamp | null;
    [CompanySetting.MANUAL_PUNCHCLOCK]: boolean;
    [CompanySetting.START_TIME]: number;
    [CompanySetting.START_TIME_STANDARD_WISH]: number;
    [CompanySetting.SALARY_PERIOD_START_DATE]: number;
    [CompanySetting.SHIFT_BREAK]: ShiftBreak;
    [CompanySetting.SALARY_SUPPLEMENTS_ENABLED_ON]: Timestamp | null;
    [CompanySetting.AUTO_APPROVE_AVAILABLE_SHIFT_ENABLED_ON]: Timestamp | null;
    [CompanySetting.ROUND_CHECKIN]: boolean;
    [CompanySetting.ROUND_CHECKOUT]: boolean;
    [CompanySetting.AUTO_APPROVE_PUNCH_TIMES]: number | null;
    [CompanySetting.EMPLOYEE_DISALLOW_EDIT_PROFILE_PICTURE_ENABLED]: boolean;
    [CompanySetting.AUTOMATICALLY_EXPORT_APPROVED_PUNCH_TIMES_TO_SALARY_DK_ENABLED_ON]: Timestamp | null;
    [CompanySetting.ONLY_INCLUDE_SHIFTS_MARKED_WITH_SWIPE]: Timestamp | null;
}
