import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Employee, sortEmployeesByName, Wish, WishType } from '../../model';

export abstract class WishService {

    /**
     * Compares to wishes by their type. WishType.WORK comes first, WishType.NO_WORK comes last, and all other types are sorted the same
     * @param a The first wish
     * @param b The second wish
     */
    public static compare(a: Wish, b: Wish): number {
        // If types are equal, sort them the same
        if (a.type === b.type) return sortEmployeesByName(a.employee, b.employee);

        // If a's type is WORK, bump it and vice versa
        if (a.type === WishType.WORK) return -1;
        if (b.type === WishType.WORK) return 1;

        // If a's type is NO_WORK, send it to bottom and vice versa
        if (a.type === WishType.NO_WORK) return 1;
        if (b.type === WishType.NO_WORK) return -1;

        // All other cases go in the middle of the sorting
        return 0;
    }

    /**
     * Returns the wish for a given employee on a given day. If no wish is found, a new all day NO_WISH is returned
     * @param employee The employee for which to get the wish
     * @param date The date on which the wish would be
     */
    public getWishOnDate(employee: Employee, date: Date): Observable<Wish | Omit<Wish, 'id'>> {
        const from: Date = new Date(date.toDateString());
        const to: Date = new Date(date.toDateString());
        to.setDate(from.getDate() + 1);
        return this.getWishes({ employee, from, to }).pipe(
            map((wishes: Wish[]) => wishes[0] || ({ employee, from, to, type: WishType.NO_WISH })),
        );
    }

    /**
     * Returns the wish for the given employees on a given day. If no wish is found, a new all day NO_WISH is returned for that employee
     * @param employees The employees for which to get wishes
     * @param date The date on which the wishes would be
     */
    public getWishesOnDate(employees: Employee[], date: Date): Observable<(Wish | Omit<Wish, 'id'>)[]> {
        const from: Date = new Date(date.toDateString());
        const to: Date = new Date(date.toDateString());
        to.setDate(from.getDate() + 1);
        return this.getWishes({ from, to }).pipe(
            map((wishes: Wish[]) =>
                employees.map((employee: Employee) =>
                    wishes.find((wish: Wish) => wish.employee.id === employee.id)
                    || ({ employee, from, to, type: WishType.NO_WISH }),
                ),
            ),
        );
    }

    /**
     * Create a wish in persistent storage
     * @param wish The wish to create
     */
    public abstract createWish(wish: Omit<Wish, 'id'>): Observable<Wish>;

    /**
     * Create an array of wishes in presistent storage
     * @param wishes The array of wishes to create
     */
    public abstract createWishes(wishes: Wish[]): Observable<Wish[]>;
    /**
     * Get a wish from persistent storage with a given id for a given employee
     * @param employee The employee
     * @param wishID Id of the wish
     */
    public abstract getWish(employee: Employee, wishID: string): Observable<Wish>;

    /**
     * Get the wishes from persistent storage for an employee
     * @param employee The employee
     */
    public abstract getWishes(queryParams: WishQueryParams): Observable<Wish[]>;

    /**
     * Update a wish
     * @param wish The wish
     */
    public abstract updateWish(wish: Wish): Observable<Wish>;

    /**
     * Update multiple wishes
     * @param wishes The wishes
     */
    public abstract updateWishes(wishes: Wish[]): Observable<Wish[]>;
}

export interface WishQueryParams {
    employee?: Employee;
    from?: Date;
    to?: Date;
}
