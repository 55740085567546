<ng-container *ngIf="(shiftAction$ | async) as shiftAction">
    <div class="detail-action"
         [ngSwitch]="shiftAction"
         [class.none]="shiftAction === ShiftAction.None">
        <app-shift-detail-sell-shift-action *ngSwitchCase="ShiftAction.Sell"
                                            [shift]="shift"
                                            [shiftForSale]="shiftForSale$ | async">
        </app-shift-detail-sell-shift-action>
        <app-shift-detail-buy-shift-action *ngSwitchCase="ShiftAction.Buy"
                                           [employee]="employee$ | async"
                                           [shiftForSale]="shiftForSale$ | async"
                                           [potentialGain]="potentialGain$ | async">
        </app-shift-detail-buy-shift-action>
        <app-shift-detail-punch-action *ngSwitchCase="ShiftAction.CheckIn"
                                       [isCheckIn]="true"
                                       [shift]="shift">
        </app-shift-detail-punch-action>
        <app-shift-detail-punch-action *ngSwitchCase="ShiftAction.CheckOut"
                                       [isCheckIn]="false"
                                       [shift]="shift">
        </app-shift-detail-punch-action>
        <app-shift-detail-edit-shift-time-action *ngSwitchCase="ShiftAction.EditShiftTime"
                                                 [shift]="shift">
        </app-shift-detail-edit-shift-time-action>
        <app-shift-detail-approve-trade-action [shiftForSale]="shiftForSale$ | async"
                                               *ngSwitchCase="ShiftAction.ApproveTrade">
        </app-shift-detail-approve-trade-action>
        <app-shift-detail-approve-punch-action *ngSwitchCase="ShiftAction.ApprovePunch"
                                               [shift]="shift">
        </app-shift-detail-approve-punch-action>
    </div>
</ng-container>