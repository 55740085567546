import { Injectable } from '@angular/core';
import { UploadMetadata } from '@angular/fire/storage/interfaces';
import { Observable } from 'rxjs';

export interface FileUploadResult {
    fileID: string;
    downloadURL: string;
}

export interface EmployeeFile {
    name: string;
    url: string;
    delete: () => Promise<void>;
    loading: boolean;
}

@Injectable({
    providedIn: 'root',
})

export abstract class FileService {
    constructor() { }

    /**
     * A method used to upload a file (profile picture) to Storage
     * @param path being the location for the file on the doc based database for files
     * @param file being the file that is being uploaded to Storage in base64 format (string) or File format
     */
    public abstract upload(path: string, file: string | File, metadata?: UploadMetadata): Promise<FileUploadResult>;

    /**
     * A method that gets a file from the location on Storage
     * @param path being the location for the file on the doc based database for files
     * @param id being the id of the file that is being retrieved
     */
    public abstract getDownloadURL(path: string, id: string): Observable<string>;

    /**
     * Gets all uploaded files under a specified path
     */
    public abstract getUploadedFiles(path: string): Promise<EmployeeFile[]>;
}
