import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'convertMidnight' })
export class ConvertMidnightPipe implements PipeTransform {

    /**
     * Transforms the text "00:00" to "24:00". If the text is not "00:00" then it is simply returned.
     * @param value - the text to be transformed
     */
    public transform(value: string): string {
        if (value && (value === '00:00' || value === '0:00')) {
            return '24:00';
        }
        return value;
    }
}
