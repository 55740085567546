import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import * as _ from 'lodash';
import { Observable, Subject } from 'rxjs';
import { map, shareReplay, take, takeUntil } from 'rxjs/operators';
import { Employee, Role, sortEmployeesByName } from '../../../../../../core/model';
import { Department } from '../../../../../../core/model/Department/Department.model';
import { EmployeeService, RoleService } from '../../../../../../core/services';
import { DepartmentService } from '../../../../../../core/services/Department/DepartmentService.model';
import { DeleteDepartmentModalComponent } from './delete-department-modal/delete-department-modal.component';

@Component({
    selector: 'app-edit-department',
    templateUrl: './edit-department.component.html',
    styleUrls: ['./edit-department.component.scss'],
})
export class EditDepartmentComponent implements OnInit, OnDestroy {
    @Input() public department: Department;
    @Input() public departments$: Observable<Department[]>;
    @Output() public deselectDepartment: EventEmitter<null> = new EventEmitter();
    public employees$: Observable<Employee[]>;
    public roles$: Observable<Role[]>;
    public departmentName: string;
    public isDepartmentNameValid: boolean;
    public updatedEmployees: Subject<Employee[]>;
    /**
     * Used to store changes in employees, to be saved.
     * Maps the employee id to an employee object, to only keep the latest changes set to the employee object.
     */
    private saveQueue: Map<string, Employee>;
    /**
     * SaveNotifier is used to start a save, by calling next on it.
     * Subscribe to it to know when something has requested a save.
     */
    private saveNotifier: Subject<void>;
    /**
     * Used to complete the observable subscription listening for save requests.
     */
    private unsubscribeNotifier: Subject<void>;
    constructor(
        private departmentService: DepartmentService,
        private employeeService: EmployeeService,
        private roleService: RoleService,
        private modal: MatDialog,
    ) { }

    public ngOnInit(): void {
        this.departmentName = this.department.name;
        this.isDepartmentNameValid = true;
        this.updatedEmployees = new Subject();
        this.saveQueue = new Map();
        this.saveNotifier = new Subject();
        this.unsubscribeNotifier = new Subject();
        this.setUpEmployeeSaving(this.saveNotifier, this.unsubscribeNotifier);

        this.employees$ =
            this.employeeService.getEmployees({ isDeleted: false })
                .pipe(map((emps: Employee[]) => emps.sort(sortEmployeesByName)));
        this.roles$ = this.roleService.getRoles({ department: this.department })
            .pipe(
                map((roles: Role[]) => _.sortBy(roles, 'name')),
                shareReplay());
    }

    public ngOnDestroy(): void {
        this.unsubscribeNotifier.next();
    }

    /**
     * Queues an employee to be saved, and notifies through the saveNotifier that there are employees to save
     * @param emp The employee to queue for saving
     */
    public queueEmployeeForSaving(emp: Employee): void {
        this.saveQueue.set(emp.id, emp);
        this.saveNotifier.next();
    }

    /**
     * Go back to the department list
     */
    public goBack(): void {
        this.deselectDepartment.emit(null);
    }

    /**
     * Update the department
     */
    public updateDepartment(): void {
        this.departmentService.updateDepartment(this.department)
            .pipe(take(1))
            .subscribe();
    }

    /**
     * Update departmentname if the input is valid
     */
    public updateNameIfValid(): void {
        if (this.departmentNameValid()) {
            this.department.name = this.departmentName;
            this.isDepartmentNameValid = true;
            this.updateDepartment();
        } else {
            this.isDepartmentNameValid = false;
        }
    }

    public openDeleteDepartmentModal(): void {
        this.modal.open<DeleteDepartmentModalComponent, Department, boolean>(DeleteDepartmentModalComponent, {
            data: this.department,
        }).afterClosed().pipe(take(1)).subscribe({
            next: (deletedDepartment: boolean | undefined): void => {
                if (deletedDepartment) this.goBack();
            },
        });
    }

    /**
     * Starts a subscription, listening on the saveNotifier, saving after a 3 second delay after receiving the latest notify.
     * Saves the remaining employees queued, if any, when component is destroyed.
     */
    private setUpEmployeeSaving(startSave$: Observable<void>, unsubscribe$: Observable<void>): void {
        startSave$.pipe(takeUntil(unsubscribe$)).subscribe({
            next: (): void => this.save(this.employeeService),
            complete: (): void => this.save(this.employeeService),
        });
    }

    /**
     * Saves the current queue, by updating the employees through the employee service, and emptying the queue.
     * If the update fails it resets the queue to the state before attempting the update, with any new queued changes on top.
     */
    private save(empService: EmployeeService): void {
        const queueBackup: Map<string, Employee> = this.saveQueue;
        empService.updateEmployees([...this.saveQueue.values()]).pipe(take(1)).subscribe({
            error: (error: Error): void => {
                // On error, we add any new updates onto the backup of the changes from before the update
                // Old queue + any new updates, overwriting any outdated updates
                this.saveQueue = [...this.saveQueue.entries()].reduce(
                    (empMap: Map<string, Employee>, val: [string, Employee]) => empMap.set(...val), queueBackup,
                );
                throw error;
            },
        });
        this.saveQueue = new Map();
    }

    /**
     * Return if the departmentName is valid
     */
    private departmentNameValid(): boolean {
        return !this.departmentName.includes('%');
    }

}
