<div class="main">
    <div class="header">
        <div class="filler"></div>
        <div class="title">
            {{ 'admin.department.manage-departments' | translate }}
        </div>
        <div class="link">
            <span class="text"
                  (click)="createDepartment()">{{ 'admin.department.new-department' | translate }}</span>
        </div>
    </div>
    <overlay-scrollbars class="department-list">
        <div class="department"
             *ngFor="let departmentData of departmentDataList$ | async; trackBy: trackDepartmentData"
             (click)="selectDepartment(departmentData.department)">
            <div class="left">
                <span class="department-name">{{departmentData.department.name}}</span>
            </div>
            <div class="right">
                <span
                      class="department-employees">{{departmentData.employeeCount + ' ' + ((departmentData.employeeCount !== 1 ? 'shared.employees' : 'shared.employee') | translate)}}
                </span>
            </div>
        </div>
    </overlay-scrollbars>
</div>