import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { DirectivesModule } from '../../shared/directives/directives.module';
import { PipeModule } from '../../shared/pipes/pipe.module';
import { UIModule } from '../../shared/ui/ui.module';
import { LoadingScreenComponent } from './responsive/components/loading-screen/loading-screen.component';
import { LoginComponent } from './responsive/components/login/login.component';
import { LoginControllerComponent } from './responsive/pages/login-controller/login-controller.component';


@NgModule({
    declarations: [
        LoadingScreenComponent,
        LoginComponent,
        LoginControllerComponent,
    ],
    imports: [
        CommonModule,
        DirectivesModule,
        FormsModule,
        PipeModule,
        TranslateModule,
        UIModule,
    ],
})
export class LoginModule { }
