<div class="auto-punch-approval-settings-container d-flex justify-content-between align-items-center">
    <div class="title">{{ 'settings.punch-clock.auto-approve-punch-times' | translate }}</div>
    <div class="buttons d-flex align-items-center">
        <div class="button"
             (click)="settingPopover.open()"
             [ngbPopover]="popover"
             #settingPopover="ngbPopover"
             container="body"
             triggers="manual"
             autoClose="outside"
             placement="bottom-right"
             popoverClass="auto-punch-approval-settings-popover"
             (hidden)="save()">
            <ui-button-text>
                <span>{{ 'shared.set' | translate }}</span>
            </ui-button-text>
            <div class="current-range"
                 *ngIf="autoApproveRange$ | async as autoApproveRange">
                {{ 'shared.within' | translate }}
                {{ autoApproveRange }}{{ 'shared.minute-short' | translate }}
            </div>
        </div>
        <ui-toggle [checked]="toggleEnabled"
                   (checkedChange)="changeToggleState($event)"></ui-toggle>
    </div>
    <ng-template #popover>
        <div class="auto-punch-approval-duration">
            {{ 'settings.punch-clock.auto-approve-description-1' | translate }}<input [value]="autoApproveRange"
                   [appRegexMask]="'^[0-9]+$'"
                   (change)="autoApproveRange = $event.target.value"
                   onclick="this.select()">
            {{ 'shared.minutes-short' | translate }}
            {{ 'settings.punch-clock.auto-approve-description-2' | translate }}
        </div>
    </ng-template>
</div>