import { take } from 'rxjs/operators';
import { NotificationType } from '../../../../../functions/src/notifications/model/NotificationType';
import { UserSettings } from '../../../core/model/UserSettings';
import { UserSettingsService } from '../../../core/services';

export function changeNotificationSettings(
    checked: boolean,
    notificationType: NotificationType,
    disabledNotifications: NotificationType[],
    userSettingsService: UserSettingsService): void {

    if (checked) {
        disabledNotifications.splice(disabledNotifications.indexOf(notificationType), 1);
    } else {
        disabledNotifications.push(notificationType);
    }

    userSettingsService.saveSetting(UserSettings.DISABLED_NOTIFICATIONS, disabledNotifications)
        .pipe(take(1))
        .subscribe();
}
