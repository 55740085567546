<div class="invite-modal">
    <h4 class="invite-modal-title">{{ 'modal.invite-employees.title' | translate }}</h4>
    <div class="invite-modal-content">
        <p>{{ 'modal.invite-employees.share-link-text' | translate }}</p>
        <input value="{{ modalURL }}"
               #modalInvite
               contenteditable="false"
               readonly
               [class.copied]="isCopied"
               [@pulse]="isAnimating ? 'on' : 'off'">
    </div>
    <div class="modal-actions">
        <div class="copy-button">
            <ui-button-action [color]="isCopied ? 'success' : 'primary'"
                              (click)="copyInviteLink()">
                {{ (isCopied ? 'shared.copied' : 'shared.copy') | translate | capitalize }}
                <i class="fa fa-check"
                   *ngIf="isCopied"></i>
            </ui-button-action>
        </div>
    </div>
    <div class="modal-tip">
        {{ 'modal.invite-employees.tip' | translate }}
    </div>
</div>