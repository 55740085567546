import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfirmDialogType } from './ConfirmDialogType';


export interface ConfirmDialogData {
    titleTranslationKey: string;
    titleTranslationParams?: {};
    subtitleTranslationKey?: string;
    subtitleTranslationParams?: {};
    closeButtonTranslationKey: string;
    closeButtonTranslationParams?: {};
    acceptButtonTranslationKey?: string;
    acceptButtonTranslationParams?: {};
    confirmDialogType: ConfirmDialogType;
}

@Component({
    selector: 'app-confirm-dialog',
    templateUrl: './confirm-dialog.component.html',
    styleUrls: ['./confirm-dialog.component.scss'],
})
export class ConfirmDialogComponent {

    public ConfirmDialogType: typeof ConfirmDialogType = ConfirmDialogType;

    constructor(
        @Inject(MAT_DIALOG_DATA) public modalData: ConfirmDialogData,
        private modalRef: MatDialogRef<ConfirmDialogComponent>,
    ) { }

    /**
     * Close the modal
     * @param didConfirm whether you confirmed the action in the modal
     */
    public close(didConfirm: boolean): void {
        this.modalRef.close(didConfirm);
    }

}
