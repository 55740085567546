import { Injectable } from '@angular/core';
import { AngularFirestoreCollection, DocumentData, QueryDocumentSnapshot, QuerySnapshot } from '@angular/fire/firestore';
import { AngularFireFunctions } from '@angular/fire/functions';
import { UploadMetadata } from '@angular/fire/storage/interfaces';
import { DocumentReference } from '@firebase/firestore-types';
import { combineLatest, from, Observable } from 'rxjs';
import { filter, map, shareReplay, switchMap, take } from 'rxjs/operators';
import { Auth, AuthUser, FileService, FileUploadResult, FirestoreService, RoleService } from '../..';
import { Company, Employee, Role } from '../../../model';
import { Department } from '../../../model/Department/Department.model';
import { employeesCollectionPath as collectionPath, FirestoreEmployee, mapEmployeeToFirestoreEmployee } from '../../../model/Firestore';
import { DepartmentService } from '../../Department/DepartmentService.model';
import { EmployeeQueryParams, EmployeeService } from '../EmployeeService.model';

@Injectable({
    providedIn: 'root',
})
export class FirestoreEmployeeService extends EmployeeService {
    private cache$?: Observable<Employee[]>;
    private roles$: Observable<Role[]>;
    private authUser$: Observable<AuthUser | null>;

    constructor(private firestoreService: FirestoreService,
        private fns: AngularFireFunctions,
        private roleService: RoleService,
        private fileService: FileService,
        private departmentService: DepartmentService,
    ) {
        super();
        this.roles$ = this.roleService.getRoles();
        this.authUser$ = this.firestoreService.getUser().pipe(filter((authUser: AuthUser) => authUser !== undefined));
    }

    public createEmployee(employee: Omit<Employee, 'id'> | Employee, company: Company): Observable<Employee> {
        const collection: AngularFirestoreCollection<FirestoreEmployee> =
            this.firestoreService.angularFireStore.collection('Virksomheder').doc(company.id).collection(collectionPath);

        const fsEmployee: FirestoreEmployee = mapEmployeeToFirestoreEmployee(employee);

        if (this.isEmployee(employee)) {
            return from(collection.doc(employee.id).set(fsEmployee)).pipe(take(1), map(() => employee));
        } else {
            return from(collection.add(fsEmployee)).pipe(take(1), map((docRef: DocumentReference) => ({ id: docRef.id, ...employee })));
        }
    }

    public getEmployee(id: string): Observable<Employee> {
        if (!this.cache$) this.setupCache();

        return this.cache$!.pipe(
            map((employees: Employee[]) => {
                const foundEmployee: Employee | undefined = employees.find((employee: Employee) => employee.id === id);
                if (!foundEmployee) throw new Error(`No employee with id ${ id }. Employees: ${ employees.map(emp => emp.id).toString() }`);
                return foundEmployee;
            }),
        );
    }

    public getEmployees(params?: EmployeeQueryParams): Observable<Employee[]> {
        if (!this.cache$) this.setupCache();

        return this.cache$!.pipe(
            map((employees: Employee[]) => {
                const filterEmps = (filterOn: ((employee: Employee) => boolean)) => employees = employees.filter(filterOn);

                if (params) {
                    const { role, primaryDepartment, department }: EmployeeQueryParams = params;
                    if (role) filterEmps((emp: Employee) => !!emp.roles.find((rol: Role) => rol.id === role.id));
                    if (primaryDepartment) filterEmps((emp: Employee) => emp.primaryDepartment?.id === primaryDepartment.id);
                    if (department) filterEmps((emp: Employee) => !!emp.departments.find((dep: Department) => dep.id === department.id));
                }

                // Only return deleted employees if the isDeleted param is true
                if (!params || !params.isDeleted) filterEmps((emp: Employee) => !emp.isDeleted);

                return employees;
            }),
        );
    }

    public updateEmployee(employee: Employee): Observable<Employee> {
        return this.firestoreService.authenticate(collectionPath)
            .pipe(switchMap((auth: Auth<FirestoreEmployee>) =>
                auth.collection
                    .doc(employee.id)
                    .update(mapEmployeeToFirestoreEmployee(employee))),
                switchMap(() => this.getEmployee(employee.id)));
    }

    public updateEmployees(employees: Employee[]): Observable<Employee[]> {
        return this.firestoreService.authenticate(collectionPath)
            .pipe(switchMap((auth: Auth<FirestoreEmployee>) =>
                this.firestoreService.commitUpdateBatch<Employee, DocumentData>(
                    employees,
                    auth.collection,
                    mapEmployeeToFirestoreEmployee)),
                switchMap(() => combineLatest(employees.map((employee: Employee) => this.getEmployee(employee.id)))));
    }

    public deleteEmployee(employee: Employee): Observable<Employee> {
        return this.fns.httpsCallable('handleUserDeletion')({ uid: employee.id })
            .pipe(
                take(1),
                map(() => employee));
    }

    public getAuthenticatedEmployee(): Observable<Employee> {
        return this.authUser$.pipe(
            filter((authUser: AuthUser | null) => !!authUser),
            switchMap((authUser: AuthUser) => this.getEmployee(authUser.user_id)),
        );
    }

    public setEmployeeImage(employee: Employee, img: string): Observable<Employee> {
        return this.authUser$.pipe(
            take(1),
            switchMap((authUser: AuthUser) => {
                const metadata: UploadMetadata = { cacheControl: 'public,max-age=3600', customMetadata: { 'employeeId': employee.id } };
                return this.fileService.upload(
                    authUser.companyID + '/employees/' + employee.id + '/profile-pictures/',
                    img,
                    metadata);
            }),
            map((result: FileUploadResult) => {
                const emp: FirestoreEmployee = mapEmployeeToFirestoreEmployee(employee);
                emp.imageId = result.fileID;
                return emp;
            }),
            switchMap((emp: FirestoreEmployee) => this.firestoreService.authenticate(collectionPath).pipe(
                switchMap((auth: Auth<FirestoreEmployee>) => auth.collection.doc(employee.id).set(emp, { merge: true })),
            )),
            switchMap(() => this.getEmployee(employee.id)),
        );
    }

    private setupCache(): void {
        this.cache$ = this.firestoreService.authenticate(collectionPath).pipe(
            switchMap((auth: Auth<FirestoreEmployee>) => this.firestoreService.observeOnSnapshot(auth.collection)),
            switchMap((querysnap: QuerySnapshot<FirestoreEmployee>) => {
                return Promise.all(querysnap.docs.map(
                    (doc: QueryDocumentSnapshot<FirestoreEmployee>) => this.mapFirestoreEmployeeToEmployee(doc.data(), doc.id)));
            },
            ),
            shareReplay(1),
        );
    }

    /**
     * Type checking method returning whether a campaign is a Campain (an existing campaign)
     * @param campaign - The campaign to check
     */
    private isEmployee(employee: Omit<Employee, 'id'> | Employee): employee is Employee {
        return !!(employee as Employee).id;
    }

    private async getImageURL(employeeId: string, imageId: string): Promise<string> {
        const companyId: string = await this.authUser$.pipe(
            map((authUser: AuthUser) => authUser.companyID),
            take(1))
            .toPromise();
        const path: string = companyId + '/employees/' + employeeId + '/profile-pictures/';
        return await this.fileService.getDownloadURL(path, imageId).pipe(take(1)).toPromise();
    }

    /**
     * Maps a FirestoreEmployee to an Employee
     * @param fsEmployee - The FirestoreEmployee to map
     * @param id - The id of the employee
     */
    private async mapFirestoreEmployeeToEmployee(fsEmployee: FirestoreEmployee, id: string): Promise<Employee> {
        const departments: Department[] = fsEmployee.departments.length ? await combineLatest(fsEmployee.departments.map((dep: string) =>
            this.departmentService.getDepartment(dep))).pipe(take(1)).toPromise() : [];
        return {
            id: id,
            firstname: fsEmployee.firstname,
            lastname: fsEmployee.lastname,
            CPR: fsEmployee.CPR,
            street: fsEmployee.street,
            city: fsEmployee.city,
            zip: fsEmployee.zip,
            phone: fsEmployee.phone,
            email: fsEmployee.email,
            imageURL: fsEmployee.imageId
                ? await this.getImageURL(id, fsEmployee.imageId)
                : this.generateInitialAvatar(fsEmployee.firstname + ' ' + fsEmployee.lastname),
            roles: await this.roles$.pipe(
                take(1),
                map((roles: Role[]) => roles.filter((role: Role) => fsEmployee.roles.includes(role.id)))).toPromise(),
            isDeleted: fsEmployee.isDeleted ? fsEmployee.isDeleted : false,
            departments: departments.filter((department: Department) => !department.isDeleted),
            primaryDepartment: fsEmployee.primaryDepartment
                ? departments.find((dep: Department) => dep.id === fsEmployee.primaryDepartment) || null
                : null,
            hourlyWage: fsEmployee.hourlyWage != null ? fsEmployee.hourlyWage : null,
            note: fsEmployee.note || '',
        };
    }
}
