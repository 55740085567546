import { Component } from '@angular/core';
import { ButtonDirective } from '../button/button.directive';

@Component({
    selector: 'ui-button-standard',
    templateUrl: './button-standard.component.html',
    styleUrls: ['./button-standard.component.scss'],
})

export class ButtonStandardComponent extends ButtonDirective { }
