import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { NgbDatepickerModule, NgbDropdownModule, NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { DlDateTimeDateModule, DlDateTimePickerModule } from 'angular-bootstrap-datetimepicker';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { DirectivesModule } from '../directives/directives.module';
import { PipeModule } from '../pipes/pipe.module';
import { ButtonActionComponent } from './buttons/button-action/button-action.component';
import { ButtonIconRaisedComponent } from './buttons/button-icon-raised/button-icon-raised.component';
import { ButtonIconComponent } from './buttons/button-icon/button-icon.component';
import { ButtonProComponent } from './buttons/button-pro/button-pro.component';
import { ButtonStandardComponent } from './buttons/button-standard/button-standard.component';
import { ButtonTextComponent } from './buttons/button-text/button-text.component';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { DatePickerComponent } from './date-picker/date-picker.component';
import { DateRangePickerComponent } from './date-range-picker/date-range-picker.component';
import { DateSwitcherComponent } from './date-switcher/date-switcher.component';
import { InputDialogComponent } from './input-dialog/input-dialog.component';
import { IntegrationPanelComponent } from './integration-panel/integration-panel.component';
import { LabelComponent } from './label/label.component';
import { MonthPickerComponent } from './month-picker/month-picker.component';
import { ProfileCardComponent } from './profile-card/profile-card.component';
import { SearchfieldComponent } from './searchfield/searchfield.component';
import { SpinnerComponent } from './spinner/spinner.component';
import { StepperComponent } from './stepper/stepper.component';
import { TextInputComponent } from './text-input/text-input.component';
import { TimeInputComponent } from './time-input/time-input.component';
import { ToggleComponent } from './toggle/toggle.component';
import { WidgetComponent } from './widget/widget.component';


@NgModule({
    declarations: [
        ButtonActionComponent,
        ButtonIconComponent,
        ButtonIconRaisedComponent,
        ButtonProComponent,
        ButtonStandardComponent,
        ButtonTextComponent,
        ConfirmDialogComponent,
        DatePickerComponent,
        DateRangePickerComponent,
        DateSwitcherComponent,
        InputDialogComponent,
        IntegrationPanelComponent,
        LabelComponent,
        MonthPickerComponent,
        ProfileCardComponent,
        SearchfieldComponent,
        StepperComponent,
        TextInputComponent,
        TimeInputComponent,
        ToggleComponent,
        WidgetComponent,
        SpinnerComponent,
    ],
    imports: [
        CommonModule,
        DirectivesModule,
        DlDateTimeDateModule,
        DlDateTimePickerModule,
        FormsModule,
        LazyLoadImageModule,
        MatButtonModule,
        MatSlideToggleModule,
        NgbDatepickerModule,
        NgbDropdownModule,
        NgbPopoverModule,
        PipeModule,
        TranslateModule,
    ],
    exports: [
        ButtonActionComponent,
        ButtonIconComponent,
        ButtonIconRaisedComponent,
        ButtonProComponent,
        ButtonStandardComponent,
        ButtonTextComponent,
        ConfirmDialogComponent,
        DatePickerComponent,
        DateRangePickerComponent,
        DateSwitcherComponent,
        InputDialogComponent,
        IntegrationPanelComponent,
        LabelComponent,
        MonthPickerComponent,
        ProfileCardComponent,
        SearchfieldComponent,
        StepperComponent,
        TextInputComponent,
        TimeInputComponent,
        ToggleComponent,
        WidgetComponent,
    ],
    entryComponents: [ConfirmDialogComponent],
})
export class UIModule { }
