import { Directive, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[appNgTypeaheadScroll]',
})
export class NgTypeaheadScrollDirective {
  @Input() public isPopupOpen: boolean;
  @Input() public popupId: string;

  constructor() { }

  @HostListener('keydown') public keydown(): void {
    if (!this.isPopupOpen) {
      return;
    }

    setTimeout(() => {
      const popup: HTMLElement | null = document.getElementById(this.popupId);
      if (!!popup) {
        const activeElements: HTMLCollectionOf<Element> = popup.getElementsByClassName('active');
        if (activeElements?.length === 1) {
          const elem: HTMLElement = activeElements[0] as HTMLElement;
          elem.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
          });
        }
      }

    });
  }
}
