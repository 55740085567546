import { AnimationPlayer } from '@angular/animations';
import { AnimationDriver, ɵNoopAnimationDriver, ɵWebAnimationsDriver } from '@angular/animations/browser';
import { registerLocaleData } from '@angular/common';
import { HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';
import localeDa from '@angular/common/locales/da';
import { ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireFunctionsModule, REGION } from '@angular/fire/functions';
import { AngularFirePerformanceModule, PerformanceMonitoringService } from '@angular/fire/performance';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatTooltipDefaultOptions, MAT_TOOLTIP_DEFAULT_OPTIONS } from '@angular/material/tooltip';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AkitaNgDevtools } from '@datorama/akita-ngdevtools';
import { NgbModule, NgbPopoverModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { LangChangeEvent, TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import * as Sentry from '@sentry/browser';
import { DlDateTimeDateModule, DlDateTimePickerModule } from 'angular-bootstrap-datetimepicker';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { ModalModule } from 'angular-custom-modal';
import { GoogleTagManagerModule } from 'angular-google-tag-manager';
import { locale, updateLocale } from 'moment';
import { DynamicIoModule, DynamicModule } from 'ng-dynamic-component';
import { IntercomModule } from 'ng-intercom';
import { ImageCropperModule } from 'ngx-image-cropper';
import { OverlayscrollbarsModule } from 'overlayscrollbars-ngx';
import { version } from '../../package.json';
import { environment } from '../environments/environment.dev';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AdminGuard, LoginGuard } from './core/guards';
import { DEFAULT_LANGUAGE } from './core/model/UserSettings';
import { AuthService, CompanyService, CompanySettingsService, DateService, EmployeeService, FileService, FirestoreService, MediaService, PlannerService, RoleService, ShiftForSaleService, ShiftService, SidebarService, TemplateService, UserActivityService, UserSettingsService, WishService } from './core/services';
import { AnimationService } from './core/services/animation/animation.service';
import { FirestoreCompanyService } from './core/services/Company/Firestore/FirestoreCompany.service';
import { ConfigurationService } from './core/services/Configuration/configuration-service.model';
import { FirestoreConfigurationService } from './core/services/Configuration/Firestore/configuration-service.service';
import { DepartmentService } from './core/services/Department/DepartmentService.model';
import { FirestoreDepartmentService } from './core/services/Department/Firestore/FirestoreDepartment.service';
import { FirestoreEmployeeService } from './core/services/Employee/Firestore/FirestoreEmployee.service';
import { FirebaseFileService } from './core/services/File/Firestore/firebase-file.service';
import { FirestoreForecastService } from './core/services/ForecastService/Firestore/FirestoreForecast.service';
import { ForecastService } from './core/services/ForecastService/ForecastService.model';
import { GoogleAnalyticsService } from './core/services/google-analytics.service';
import { MatDialogModalService } from './core/services/modal.service';
import { ModalService } from './core/services/ModalService.model';
import { FirestoreRoleService } from './core/services/Role/Firestore/FirestoreRole.service';
import { SalaryService } from './core/services/salary.service';
import { FirestoreCompanySettingsService } from './core/services/Settings/CompanySettings/Firestore/FirestoreCompanySettings.service';
import { IntegrationSettingsService } from './core/services/Settings/IntegrationSettings/Firestore/integration-settings.service';
import { FirestoreUserSettingsService } from './core/services/Settings/UserSettings/Firestore/FirestoreUserSettings.service';
import { FirestoreShiftService } from './core/services/Shift/Firestore/FirestoreShift.service';
import { FirestoreShiftForSaleService } from './core/services/ShiftForSale/Firestore/FirestoreShiftForSale.service';
import { FirestoreUserActivityService } from './core/services/UserActivity/Firestore/FirestoreUserActivity.service';
import { AkitaWishFacadeService } from './core/services/Wish/state/akita-wish-facade.service';
import { WebpackTranslateLoader } from './core/webpack-translate-loader';
import { MaterialModule } from './material-module';
import { CompanySignupModule } from './modules/company-signup/responsive/company-signup.shared.module';
import { LoginModule } from './modules/login/login.module';
import { SentryErrorHandler } from './SentryErrorHandler';
import { BottomNavComponent } from './shared/components/bottom-nav/bottom-nav.component';
import { NextShiftComponent } from './shared/components/bottom-nav/next-shift/next-shift.component';
import { TopNavComponent } from './shared/components/top-nav/top-nav.component';
import { DirectivesModule } from './shared/directives/directives.module';
import { PipeModule } from './shared/pipes/pipe.module';
import { SharedModule } from './shared/shared.module';
import { UIModule } from './shared/ui/ui.module';

// Opsæt locale til vores dato pipes
registerLocaleData(localeDa); // TODO: [GO-1791] Sæt dette via TranslateService

const googleTagManagerId = 'GTM-WLJ7VQ2';

export class DynamicLocale extends String {
    constructor(protected translateService: TranslateService) { super(); }
    public toString(): string {
        return this.translateService.currentLang || 'da';
    }
}

export const CustomTooltipDefaults: MatTooltipDefaultOptions = {
    position: 'above',
    showDelay: 500,
    hideDelay: 100,
    touchendHideDelay: 100,
};

/**
 * Custom animation factory to allow us to disable animations on certain elements
 */
export function animationFactory(): AnimationDriver {
    // Create a new animations driver
    const driver = new ɵWebAnimationsDriver();

    // Get the animate and no animate functions
    const animateFunc = driver.animate.bind(driver);
    const noAnimateFunc = new ɵNoopAnimationDriver().animate;

    // Use the no animate function, if the element's parent has the disable-animations class (set in ModalService)
    driver.animate = (element: HTMLElement, keyframes: { [key: string]: string | number; }[], duration: number, delay: number,
        easing: string, previousPlayers?: AnimationPlayer[], scrubberAccessRequested?: boolean,
    ) => element.parentElement?.classList.contains('disable-animations')
            ? noAnimateFunc(element, keyframes, duration, delay, easing, previousPlayers, scrubberAccessRequested)
            : animateFunc(element, keyframes, duration, delay, easing, previousPlayers, scrubberAccessRequested);

    return driver;
}

Sentry.init({
    dsn: 'https://281c7f2cb1a244bb9efeb73e61f49c4d@o405189.ingest.sentry.io/5270403',
    integrations: [
        new Sentry.Integrations.TryCatch({ XMLHttpRequest: false }),
        new Sentry.Integrations.Breadcrumbs({ console: false }),
    ],
    ignoreErrors: [
        'ResizeObserver',
    ],
    allowUrls: [
        /^https:\/\/app.relion.dk/,
    ],
    release: version,
});

@NgModule({
    declarations: [
        AppComponent,
        BottomNavComponent,
        NextShiftComponent,
        TopNavComponent,
    ],
    imports: [
        AngularFireAuthModule,
        AngularFireFunctionsModule,
        AngularFireModule.initializeApp(environment.firebase),
        AngularFirePerformanceModule,
        AngularFireStorageModule,
        AngularFirestoreModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        BrowserModule,
        CalendarModule.forRoot({ provide: DateAdapter, useFactory: adapterFactory }),
        CompanySignupModule,
        DirectivesModule,
        DlDateTimeDateModule,
        DlDateTimePickerModule,
        DynamicIoModule,
        DynamicModule,
        FormsModule,
        GoogleTagManagerModule.forRoot({ id: googleTagManagerId }),
        HttpClientJsonpModule,
        HttpClientModule,
        ImageCropperModule,
        IntercomModule.forRoot({ appId: 'rfhqq4qn', updateOnRouterChange: true }),
        LoginModule,
        MatButtonModule,
        MatButtonToggleModule,
        MaterialModule,
        ModalModule,
        NgbModule,
        NgbPopoverModule,
        NgbTooltipModule,
        NgSelectModule,
        OverlayscrollbarsModule,
        PipeModule,
        ReactiveFormsModule,
        SharedModule,
        TranslateModule.forRoot({
            defaultLanguage: DEFAULT_LANGUAGE,
            loader: {
                provide: TranslateLoader, useClass: WebpackTranslateLoader,
            },
        }),
        UIModule,
        // If we are not in production, we provide akitas devtools.
        // This allows us to see the store and actions using ReduxDevtools in chrome during development.
        environment.production ? [] : AkitaNgDevtools.forRoot(),
    ],
    providers: [
        AdminGuard,
        AnimationService,
        AuthService,
        DateService,
        FirestoreService,
        GoogleAnalyticsService,
        IntegrationSettingsService,
        LoginGuard,
        MediaService,
        PerformanceMonitoringService,
        PlannerService,
        SalaryService,
        SidebarService,
        TemplateService,
        { provide: 'googleTagManagerId', useValue: googleTagManagerId },
        { provide: AnimationDriver, useFactory: animationFactory },
        { provide: CompanyService, useClass: FirestoreCompanyService },
        { provide: CompanySettingsService, useClass: FirestoreCompanySettingsService },
        { provide: ConfigurationService, useClass: FirestoreConfigurationService },
        { provide: DepartmentService, useClass: FirestoreDepartmentService },
        { provide: EmployeeService, useClass: FirestoreEmployeeService },
        { provide: ErrorHandler, useClass: SentryErrorHandler },
        { provide: FileService, useClass: FirebaseFileService },
        { provide: ForecastService, useClass: FirestoreForecastService },
        // TODO: [GO-1801] Remove all places where we supply locale to pipes in template, since this is the superior solution 😎
        { provide: LOCALE_ID, deps: [TranslateService], useClass: DynamicLocale },
        { provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: CustomTooltipDefaults },
        { provide: ModalService, useClass: MatDialogModalService },
        { provide: REGION, useValue: 'europe-west1' }, // Sets the firebase cloud functions region
        { provide: RoleService, useClass: FirestoreRoleService },
        { provide: ShiftForSaleService, useClass: FirestoreShiftForSaleService },
        { provide: ShiftService, useClass: FirestoreShiftService },
        { provide: UserActivityService, useClass: FirestoreUserActivityService },
        { provide: UserSettingsService, useClass: FirestoreUserSettingsService },
        { provide: WishService, useClass: AkitaWishFacadeService },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {
    constructor(translateService: TranslateService) {
        // We overwrite the english moment locale, to keep the same week structure as we have in the danish locale (ISO-8601)
        // Taken from moments documentation: https://momentjs.com/docs/#/customization/dow-doy/
        updateLocale('en', {
            week: {
                dow: 1, // First day of week is Monday
                doy: 4,  // First week of year must contain 4 January (7 - dow - dateInJanuary = 7 + 1 - 4 = 4)
            },
        });

        locale(translateService.currentLang);

        translateService.onLangChange.subscribe({
            next: (lang: LangChangeEvent): void => {
                locale(lang.lang);
            },
        });

    }
}
