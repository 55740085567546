import { Directive, ElementRef, HostListener, Input, NgZone, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { first } from 'rxjs/operators';
import { ValidatorStatus } from '../../modules/admin/shared/components/settings-modal/components/salary/integrations/integration-modal/integration-modal.component';

@Directive({
    selector: '[appFloatingLabel]',
})
export class FloatingLabelDirective implements OnInit {
    @Input() public set errorMessage(val: string) {
        this.errorElement.innerText = val;
    }
    @Input('appFloatingLabel') public set label(val: string) {
        this.labelElement.innerText = val;
    }
    @Input('inputColor')
    public set color(val: string) {
        this.element.nativeElement.classList.remove(this._color);
        this.element.nativeElement.classList.add(val);
        this._color = val;
    }

    @Input() public set validatorStatus(newValidatorStatus: ValidatorStatus | undefined) {
        this.updateValidatorStatus(newValidatorStatus);
    }
    private _color: string;
    private _validatorStatus?: ValidatorStatus;
    private containerElement: HTMLDivElement;
    private errorElement: HTMLDivElement;
    private labelElement: HTMLLabelElement;
    private checkmarkElement: HTMLElement;
    private spinnerElement: HTMLElement;

    constructor(
        private element: ElementRef<HTMLInputElement>,
        private zone: NgZone,
        private translateService: TranslateService,
    ) {
        this.labelElement = document.createElement('label');
        this.containerElement = document.createElement('div');
        this.errorElement = document.createElement('div');
        this.errorElement.classList.add('error');
        this.setupErrorMessage();
        this.containerElement.classList.add('styled-input');
        if (element.nativeElement && element.nativeElement.parentElement) {
            element.nativeElement.parentElement.appendChild(this.containerElement);
            this.containerElement.appendChild(this.element.nativeElement);
            this.containerElement.appendChild(this.labelElement);
            this.containerElement.appendChild(this.errorElement);
        }
        this.checkmarkElement = document.createElement('i');
        this.checkmarkElement.classList.add('icon', 'far', 'fa-check');
        this.spinnerElement = document.createElement('i');
        this.spinnerElement.classList.add('icon', 'fad', 'fa-spin', 'fa-spinner-third');
    }

    public ngOnInit(): void {
        this.zone.run(() => setTimeout(() => this.updateLabelStatus(), 0));
    }

    @HostListener('ngModelChange')
    @HostListener('input')
    public updateLabelStatus(): void {
        this.element.nativeElement.value
            ? this.element.nativeElement.classList.remove('empty')
            : this.element.nativeElement.classList.add('empty');
    }

    private async setupErrorMessage(): Promise<void> {
        this.errorElement.innerHTML =
            this.errorMessage || await this.translateService.get('error.this-field-does-not-meet-the-rules').pipe(first()).toPromise();
    }


    private updateValidatorStatus(newValidatorStatus?: ValidatorStatus): void {
        // If status has not changed, do nothing
        if (_.isEqual(newValidatorStatus, this._validatorStatus)) return;

        // Update the validator status reference
        this._validatorStatus = newValidatorStatus;

        // Remove old styling
        this.element.nativeElement.classList.remove('relion-border-success');
        this.element.nativeElement.classList.remove('relion-border-warn');
        this.errorElement.style.visibility = 'hidden';
        this.checkmarkElement.remove();
        this.spinnerElement.remove();

        switch (newValidatorStatus) {
            // Do no more if status removed
            case undefined: return;
            case ValidatorStatus.VALID:
                this.element.nativeElement.classList.add('relion-border-success');
                this.containerElement.insertBefore(this.checkmarkElement, this.element.nativeElement);
                break;
            case ValidatorStatus.INVALID:
                this.element.nativeElement.classList.add('relion-border-warn');
                this.errorElement.style.visibility = 'visible';
                break;
            case ValidatorStatus.LOADING:
                this.containerElement.insertBefore(this.spinnerElement, this.element.nativeElement);
                break;
        }
    }
}
