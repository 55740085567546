

export enum SalaryIntegration {
    DANLOEN = 'danloen',
    DATALOEN = 'dataloen',
    SALARYDK = 'salarydk',
}

export interface IntegrationStatus {
    integration: SalaryIntegration | null;
    error?: string;
}

export function getIntegrationWebsite(integration: SalaryIntegration): string {
    switch (integration) {
        case SalaryIntegration.DANLOEN: return 'https://www.danlon.dk/log-ind/';
        case SalaryIntegration.DATALOEN: return 'https://dataloen.bluegarden.dk';
        case SalaryIntegration.SALARYDK: return 'https://app.salary.dk/';
    }
}

export interface EmployeeMapping {
    [key: string]: string | null;
}


export interface SalaryIntegrationSettings {
    apiKey?: string;
    employeeMap?: EmployeeMapping[];
}
