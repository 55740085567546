import { Component, Input } from '@angular/core';
import { isShift, Shift } from '../../../core/model';
import { GroupShiftsBy } from '../../../core/model/UserSettings';
import { WeekdayShift } from '../../../modules/admin/week-view/web/pages/week-view/week-view.component';

@Component({
    selector: 'app-shift-divider',
    templateUrl: './shift-divider.component.html',
    styleUrls: ['./shift-divider.component.scss'],
})
export class ShiftDividerComponent {

    @Input() public groupShiftsBy: GroupShiftsBy;
    @Input() public set weekdayShifts(shifts: WeekdayShift[] | Shift[]) {
        if (!shifts[0]) throw Error('No shifts was sent to shift divider');
        if (isShift(shifts[0])) {
            this.firstShift = shifts[0];
            this.faded = false;
        } else {
            this.firstShift = shifts[0].shift;
            const weekdayShifts = shifts as WeekdayShift[];
            // Fade the divider if all its shifts are faded
            this.faded = !weekdayShifts.find((weekdayShift: WeekdayShift) => !weekdayShift.faded);
        }
    }
    public GroupShiftsBy: typeof GroupShiftsBy = GroupShiftsBy;
    public faded: boolean;
    public firstShift: Shift;
}
