import { FenerumAccount } from '../../core/model/Fenerum/FenerumAccount';
import { FenerumCard } from '../../core/model/Freemium';

/**
 * Checks if a card is expired
 * @param activeCard The card to check
 */
export function isExpired(activeCard: FenerumCard): boolean {
    // FenerumCard.month is 1-indexed (so December is 12, not 11)
    // However, to get the right expiry date, we init a date without
    // subtracting one such that a card expiring 07-23 has a Date of
    // August 1, 2023. That corresponds to the exact expiry point in time
    return new Date(Number(activeCard.year), Number(activeCard.month)) <= new Date();
}

export function getActiveCard(account: FenerumAccount | null): FenerumCard | undefined {
    return account ? account.paymentcard_set.find((card: FenerumCard) => card.active && !isExpired(card)) : undefined;
}

export function hasActiveCard(account: FenerumAccount | null): boolean {
    return !!account && !!getActiveCard(account);
}
