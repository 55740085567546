<div class="dataloen-why-match-modal">
    <img alt="Dataloen logo"
         class="logo"
         [src]="integration | getIntegrationLogo">
    <p>
        {{ 'integrations.dataloen.why-match' | translate }}
    </p>
    <ui-button-standard (click)="modalRef.close()">
        {{ 'shared.close' | translate }}
    </ui-button-standard>
</div>