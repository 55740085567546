<div class="integration-modal"
     *ngIf="status$ | async as status">
    <ng-container [ngSwitch]="integrationStep">
        <div *ngSwitchCase="IntegrationStep.API_KEY"
             class="h-100 d-flex flex-column justify-content-between">
            <div class="logo-container d-flex
         justify-content-between">
                <img [alt]="(('integrations.'+integration+ '.name') | translate) +' logo'"
                     class="logo"
                     [src]="integration | getIntegrationLogo">
                <ui-button-text (click)="whereIsMyKey()"
                                color="primary">
                    <span>{{ ('integrations.' + integration + '.where-is-my-key') | translate }}</span>
                </ui-button-text>
            </div>

            <input #apiInput
                   inputColor="grey"
                   [validatorStatus]="status"
                   [errorMessage]="'salary.api-key-does-not-exist' | translate"
                   [appFloatingLabel]="('integrations.'+integration+'.enter-your-api-key') | translate" />

            <div class="d-flex justify-content-end">
                <ui-button-standard (click)="modalRef.close()">
                    {{ 'shared.cancel' | translate }}
                </ui-button-standard>
                <ui-button-action (click)="saveAPIKey(input.nativeElement.value)"
                                  [disabled]="status !== ValidatorStatus.VALID">
                    {{ 'shared.integrate' | translate }}
                </ui-button-action>
            </div>
        </div>
        <div *ngSwitchCase="IntegrationStep.MATCHING"
             class="matching-container">
            <div class="matching-content">
                <div class="logo-container"
                     [ngClass]="integration">
                    <i class="fad fa-spin fa-spinner-third"></i>
                    <img [alt]="(('integrations.'+integration+ '.name') | translate) +' logo'"
                         class="logo"
                         [src]="integration | getIntegrationLogo">
                </div>
                {{ ('integrations.'+ integration + '.matching-users') | translate }}
            </div>
            <ui-button-standard (click)="cancelMatching()">{{'shared.cancel' | translate}}
            </ui-button-standard>
        </div>
    </ng-container>
</div>