import * as _ from 'lodash';
import * as moment from 'moment';
import { Department, Employee, isShift, Role, Shift, ShiftBreak } from '../../core/model';
import { FirestoreDepartment, FirestoreEmployee, FirestoreRole } from '../../core/model/Firestore';
import { FirestoreShift } from '../../core/model/Firestore/FirestoreShift';
import { TimeRegistration as TimeRegistrationWithoutClass } from '../../core/model/Integrations/SalaryDK';
import { WithID } from '../../core/model/WithID';
import { splitShift } from './SalaryUtility';
import { getShiftDuration } from './ShiftUtils/durationUtils';
import ClassEnum = TimeRegistrationWithoutClass.ClassEnum;

// We need to add the class property as it is incorrectly defined as _class in Salary's API
type TimeRegistration = TimeRegistrationWithoutClass & { class: ClassEnum };

type EmployeeMapping = [Employee | WithID<FirestoreEmployee>, string | null];

export function getTimeRegistrations(
    shifts: (Shift | WithID<FirestoreShift>)[],
    mappedEmployees: EmployeeMapping[],
    multipleDepartmentsInCompany: boolean,
    shiftBreak: ShiftBreak | null,
    role?: FirestoreRole,
    department?: FirestoreDepartment,
): { timeRegistrations: TimeRegistration[], shiftIDs: string[] } {

    // Init a list of shifts and the ID of their mapped Salary employee
    const mappedShiftsToExport: [Shift | WithID<FirestoreShift>, string][] = [];
    shifts
        // Filter away shifts without employees
        .filter((shift: Shift | WithID<FirestoreShift>) => isShift(shift) ? !!shift.employee : !!shift.employeeID)
        // Split shifts if they're past midnight
        .reduce(((acc: (Shift | WithID<FirestoreShift>)[], shift: Shift | WithID<FirestoreShift>) =>
            acc.concat(splitShift(shift, shiftBreak))), [])
        // Populate the export list with shifts that have an employee with a mapping and an hourly wage > 0
        .forEach((shift: Shift | WithID<FirestoreShift>) => {
            // Look for a mapping of the shift's employee
            const mapping: EmployeeMapping | undefined = mappedEmployees.find(
                ([mappedEmployee, __]: EmployeeMapping) =>
                    isShift(shift) ? shift.employee?.id === mappedEmployee.id : shift.employeeID === mappedEmployee.id);

            // If there's a mapping and the hourlyWage of the employee is truthy, add the mapped shift to the export list
            if (mapping && !!mapping[0].hourlyWage && mapping[1]) mappedShiftsToExport.push([shift, mapping[1]]);
        });

    const timeRegistrations = mappedShiftsToExport.map(([shift, salaryEmployeeId]: [Shift | WithID<FirestoreShift>, string]) =>
        mappingToTimeRegistration(shift, salaryEmployeeId, multipleDepartmentsInCompany, role, department));
    const shiftIDs = _.uniq(mappedShiftsToExport.map(([shift, _salaryEmployeeId]: [Shift | WithID<FirestoreShift>, string]) => shift.id));
    return { timeRegistrations, shiftIDs };
}

/**
 * Map a shift and the Salary.dk id of the employee to a Salary.dk TimeRegistration
 * @param shift - The shift to map
 * @param salaryEmployeeId - The Salary.dk employee id of the shift's employee
 * @param multipleDepartmentsInCompany - Whether or not there are multiple departments in the company
 */
function mappingToTimeRegistration(
    shift: Shift | WithID<FirestoreShift>,
    salaryEmployeeId: string,
    multipleDepartmentsInCompany: boolean,
    role?: FirestoreRole,
    department?: FirestoreDepartment): TimeRegistration {
    if (isShift(shift)) {
        return ({
            class: ClassEnum.Hours,
            date: getSalaryDkDateString(shift.start),
            employeeID: salaryEmployeeId,
            hours: getShiftDuration(shift),
            start: getMinutesSinceMidnight(shift.start),
            note: getSalaryDkNote(multipleDepartmentsInCompany, shift.role, shift.role.department),
        });
    } else {
        return ({
            class: ClassEnum.Hours,
            date: getSalaryDkDateString(shift.start.toDate()),
            employeeID: salaryEmployeeId,
            hours: getShiftDuration(shift),
            start: getMinutesSinceMidnight(shift.start.toDate()),
            note: getSalaryDkNote(multipleDepartmentsInCompany, role!, department!),
        });
    }
}

/**
 * Convert a Shift to Salary.dk's date string format
 */
function getSalaryDkDateString(date: Date): string {
    return moment(date).format('YYYY-MM-DD');
}

/**
 * Extract the minutes since midnight from a shift as that is Salary.dk's time format
 */
function getMinutesSinceMidnight(date: Date): number {
    return moment(date).diff(moment(date).startOf('day'), 'minutes');
}

/**
 * Create a note for a Salary.dk time registration.
 * If company has one department, return the role name, otherwise return the role and department name
 */
function getSalaryDkNote(
    multipleDepartmentsInCompany: boolean,
    role: Role | FirestoreRole,
    department: Department | FirestoreDepartment): string {
    return multipleDepartmentsInCompany
        ? role.name + ', ' + department.name
        : role.name;
}

