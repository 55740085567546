import { Comment } from './Comment.model';

export interface Day {
    date: Date;
    note?: Comment;
    manualRevenue?: number;
}

export function isDay(day: unknown): day is Day {
    const castedDay: Day = day as Day;
    return !!castedDay.date;
}
