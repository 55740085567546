<div *ngIf="bigScreen || showSearchField"
     class="search-field-container-big-screen">
    <i class="far fa-search search-icon">
    </i>
    <input #searchInput
           type="text"
           class="searchFieldInput"
           (input)="filterContent($event.target.value)"
           placeholder="{{placeholderText}}"
           searchStringBuilder
           searchList />
    <ui-button-text *ngIf="showSearchField"
                    id="cancel-button"
                    color="primary"
                    (click)="toggleSearchField()">
        {{ 'shared.cancel' | translate }}
    </ui-button-text>
</div>
<div *ngIf="!bigScreen && !showSearchField"
     class="search-field-container-small-screen">
    <i class="far fa-search search-icon-toggle"
       (click)="toggleSearchField()">
    </i>

</div>
