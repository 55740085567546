/**
 * Returns a copy of the given array without the id of the element
 * @param array The array to remove the element from
 * @param element The element to remove from the array
 */
export function removeElement<T extends { id: string }>(array: T[], element: T): T[] {
    return array.filter((e: T) => e.id !== element.id);
}

/**
 * Returns a boolean describing whether the array given contains the element given.
 * Compares on id
 * @param a The array to look through
 * @param e The element to look for
 */
export function includes<T extends { id: string }>(a: T[], e: T): boolean {
    return !!a.find((val: T) => val.id === e.id);
}