import { Directive, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Package } from '../../../core/model/Freemium/Package';
import { ConfigurationService } from '../../../core/services/Configuration/configuration-service.model';

@Directive({
    selector: '[appProLabel]',
})
export class ProLabelDirective implements OnInit, OnDestroy {
    @Input() public set appProLabel(pack: Package) {
        this.label.classList.remove('schedule');
        this.label.classList.add(pack);
        this._proPackage = pack;
    }

    @Input() public set wide(wide: boolean) {
        this.label.classList.remove('wide');
        if (wide) this.label.classList.add('wide');
    }

    private _proPackage: Package;
    private isPro: Subscription;
    private label: HTMLDivElement;

    constructor(
        private element: ElementRef<HTMLElement>,
        private configurationService: ConfigurationService,
    ) {
        this.label = this.buildLabel();
    }

    public ngOnInit(): void {
        // Subscribe to the pro observable and add / remove the label accordingly
        this.isPro = this.configurationService.isPro(this._proPackage).subscribe({
            next: ((isPro: boolean): void => isPro ? this.removeLabel() : this.addLabel()),
        });
    }

    public ngOnDestroy(): void {
        this.isPro.unsubscribe();
    }

    /**
     * Builds the label
     */
    private buildLabel(): HTMLDivElement {
        // Create the label div and set its class for styling
        const label: HTMLDivElement = document.createElement('div');
        label.classList.add('appProLabel');

        // Create the icon and append it to the label div
        const icon: HTMLElement = document.createElement('i');
        icon.classList.add('fas', 'fa-arrow-alt-up');
        label.appendChild(icon);

        // Create the text node and append it to the label
        const proSpan = document.createElement('span');
        const proText = document.createTextNode('Pro');
        proSpan.appendChild(proText);
        label.appendChild(proSpan);

        return label;
    }

    /**
     * Removes the label from wherever it's placed
     */
    private removeLabel(): void {
        this.label.remove();
    }

    /**
     * Adds the label to the element on which this directive is put.
     * Does not add the label twice if called twice.
     */
    private addLabel(): void {
        // Set parent position relative to ensure correct placement of this label
        this.element.nativeElement.style.position = 'relative';

        // Append the label to the element on which this directive is placed
        this.element.nativeElement.appendChild(this.label);
    }
}
