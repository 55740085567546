import { Injectable, Provider } from '@angular/core';
import { Attributes, IntersectionObserverHooks, LAZYLOAD_IMAGE_HOOKS } from 'ng-lazyload-image';
import { debounceTime } from 'rxjs/operators';

/**
 * Creates a lazy loading factory
 * @param loadingDelay - Amount of seconds to delay the loading
 */
export const LazyLoadImageProviderFactory = (loadingDelay: number): Provider => ({
    provide: LAZYLOAD_IMAGE_HOOKS,
    useClass: (() => {
        @Injectable()
        class LazyLoadImageHooks extends IntersectionObserverHooks {

            /**
             * Delay the loading of images until they've been in view a specified number of seconds
             */
            public getObservable = (attributes: Attributes<{ isIntersecting: boolean; }>) =>
                super.getObservable(attributes).pipe(debounceTime(loadingDelay * 1000))
        }
        return LazyLoadImageHooks;
    })(),
});
