<div class="api-key-guide-container">
    <div class="list">
        <div class="list-item">
            <div class="step-number">1</div> <a href="https://app.salary.dk/login/"
               target="_blank">{{ 'salary.salary-dk-help-step-1' | translate }}</a>
        </div>
        <div class="list-item">
            <div class="step-number">2</div>{{ 'salary.salary-dk-help-step-2' | translate }}
        </div>
        <div class="list-item">
            <div class="step-number">3</div>{{ 'salary.salary-dk-help-step-3' | translate }}
        </div>
        <div class="list-item">
            <div class="step-number">4</div>{{ 'salary.salary-dk-help-step-4' | translate }}
        </div>
    </div>
    <ui-button-standard (click)="modalRef.close()">{{'shared.close' | translate}}</ui-button-standard>
</div>