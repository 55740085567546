import { Injectable } from '@angular/core';
import { Shift } from '../model';

@Injectable({
    providedIn: 'root',
})
export class SessionStorageService {
    private storage: Map<SupportedStorageKeys, StorageTypeMap[SupportedStorageKeys]>;

    constructor() {
        this.storage = new Map();
    }

    /**
     * Gets a value corresponding to the given key.
     * @param key The key
     */
    public get<T extends SupportedStorageKeys>(key: SupportedStorageKeys): StorageTypeMap[T] | undefined {
        return this.storage.get(key);
    }

    /**
     * Set a key, value pair
     * @param key The key
     * @param value The value
     */
    public set<T extends SupportedStorageKeys>(key: SupportedStorageKeys, value: StorageTypeMap[T]): void {
        this.storage.set(key, value);
    }

    /**
     * Remove a key, value pair from storage
     */
    public remove(key: SupportedStorageKeys): void {
        this.storage.delete(key);
    }
}

export enum SupportedStorageKeys {
    COPY_DAY_SHIFTS,
    COPY_WEEK_SHIFTS,
}


export interface StorageTypeMap {
    [SupportedStorageKeys.COPY_DAY_SHIFTS]: Omit<Shift, 'id'>[];
    [SupportedStorageKeys.COPY_WEEK_SHIFTS]: Omit<Shift, 'id'>[];
}
