import { Component, Input, OnInit } from '@angular/core';
import { forkJoin, Observable, throwError } from 'rxjs';
import { catchError, map, switchMap, take } from 'rxjs/operators';
import { Role } from '../../../../../../../core/model';
import { Department } from '../../../../../../../core/model/Department/Department.model';
import { RoleService } from '../../../../../../../core/services';

@Component({
    selector: 'app-edit-department-roles',
    templateUrl: './edit-department-roles.component.html',
    styleUrls: ['./edit-department-roles.component.scss'],
})
export class EditDepartmentRolesComponent implements OnInit {
    @Input() public departments$: Observable<Department[]>;
    @Input() public department: Department;
    public departmentRoles$: Observable<Role[]>;

    constructor(private roleService: RoleService) {

    }

    public ngOnInit(): void {
        this.departmentRoles$ = this.roleService.getRoles({ department: this.department });
        this.departments$ = this.departments$
            .pipe(
                map((departments: Department[]) => departments.filter((department: Department) => department.id !== this.department.id)),
            );
    }

    /**
     * Copy all roles from a department to this department
     * @param deaprtment The department to copy roles from
     */
    public copyRoles(department: Department): void {
        this.roleService.getRoles({ department })
            .pipe(
                take(1),
                switchMap((roles: Role[]) => {
                    const newRoles: Partial<Role>[] = roles.map((roleToCopy: Role) =>
                        ({ name: roleToCopy.name, department: this.department }));
                    const createObs: Observable<Role>[] = newRoles.map((roleToCreate: Role) =>
                        this.roleService.createRole(roleToCreate)
                            .pipe(
                                take(1),
                                catchError((error: Error) => {
                                    console.error('Failed to create role with name: ' + roleToCreate.name);
                                    return throwError('An error occured when creating roles: ' + error);
                                })));
                    return forkJoin(createObs);
                }),
            ).subscribe();
    }
}
