import { Component, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { Shift } from '../../../../core/model';
import { Punch, PunchSource } from '../../../../core/model/Punch/Punch.model';
import { CompanySetting, CompanySettingsService, ShiftService } from '../../../../core/services';

@Component({
    selector: 'app-shift-detail-edit-shift-time-action',
    templateUrl: './shift-detail-edit-shift-time-action.component.html',
    styleUrls: ['./shift-detail-edit-shift-time-action.component.scss'],
})
export class ShiftDetailEditShiftTimeActionComponent {

    @Input() public shift: Shift;
    public message: string;
    public editing: boolean;
    public start: Date;
    public end: Date;
    public autoPunchclockEnabled$: Observable<boolean>;

    constructor(
        companySettingsService: CompanySettingsService,
        private shiftService: ShiftService,
    ) {
        this.autoPunchclockEnabled$ = companySettingsService.loadSetting(CompanySetting.PUNCHCLOCK_ENABLED_ON)
            .pipe(map(enabledOn => !!enabledOn));
    }

    public edit(): void {
        this.editing = true;
        this.start = this.shift.checkin?.time ? new Date(this.shift.checkin.time) : new Date(this.shift.start);
        this.end = this.shift.checkout?.time ? new Date(this.shift.checkout.time) : new Date(this.shift.end);
        this.message = this.shift.checkout?.message || '';
    }

    public save(): void {
        // If we have punches, and they are automatic punches, we save those in the overwriting punches.
        // If they are manual punches, we use the punches that we have already overwritten,
        // if they exist, otherwise they are undefined, and that's fine
        const checkinOverwrite =
            this.shift.checkin?.source === PunchSource.AUTOMATIC ? this.shift.checkin : this.shift.checkin?.overwriting;
        const checkoutOverwrite =
            this.shift.checkout?.source === PunchSource.AUTOMATIC ? this.shift.checkout : this.shift.checkout?.overwriting;

        const checkin: Punch = { source: PunchSource.MANUAL, time: this.start, overwriting: checkinOverwrite };
        const checkout: Punch = { source: PunchSource.MANUAL, time: this.end, message: this.message, overwriting: checkoutOverwrite };
        const editedShift: Shift = { ...this.shift, checkin, checkout };
        this.shiftService.updateShift(editedShift).pipe(take(1)).subscribe();
        this.editing = false;
    }

    public cancel(): void {
        this.editing = false;
    }
}
