import { Injector } from '@angular/core';
import * as moment from 'moment';
import { combineLatest, Observable } from 'rxjs';
import { map, shareReplay, switchMap } from 'rxjs/operators';
import { Employee, Role, Shift, ShiftBreak, Wish } from '../../core/model';
import { Department } from '../../core/model/Department/Department.model';
import { CompanySetting, CompanySettingsService, EmployeeService, RoleService, ShiftService, WishService } from '../../core/services';
import { DepartmentService } from '../../core/services/Department/DepartmentService.model';
import { getShiftDuration } from './ShiftUtils/durationUtils';

export function getRoles(injector: Injector): Observable<Role[]> {
    const departmentService: DepartmentService = injector.get(DepartmentService);
    const roleService: RoleService = injector.get(RoleService);

    return departmentService.getActiveDepartment().pipe(
        switchMap((activeDepartment: Department) => roleService.getRoles({ department: activeDepartment })));
}

export function getWishes(date$: Observable<Date>, injector: Injector): Observable<Wish[]> {
    const employeeService: EmployeeService = injector.get(EmployeeService);
    const wishService: WishService = injector.get(WishService);
    return combineLatest([date$, employeeService.getEmployees()]).pipe(
        switchMap(([date, employees]: [Date, Employee[]]) => wishService.getWishesOnDate(employees, date)),
        map((wishes: Wish[]) => wishes.sort(WishService.compare)),
        shareReplay({ bufferSize: 1, refCount: true }),
    );
}

export function getHoursThisWeek(injector: Injector, date$: Observable<Date>):
    Observable<Map<string, number>> {
    const shiftService: ShiftService = injector.get(ShiftService);
    const settingsService: CompanySettingsService = injector.get(CompanySettingsService);

    return combineLatest([
        settingsService.loadSetting(CompanySetting.SHIFT_BREAK),
        date$,
    ]).pipe(
        switchMap(([shiftBreak, date]: [ShiftBreak | null, Date]) =>
            shiftService.getShifts({
                startDateFrom: moment(date).startOf('isoWeek').toDate(),
                startDateTo: moment(date).startOf('isoWeek').add(7, 'days').toDate(),
            }).pipe(map((shifts: Shift[]) =>
                shifts.filter(shift => !!shift.employee)
                    .reduce((hoursMap, shift) =>
                        hoursMap.set(
                            shift.employee!.id,
                            (hoursMap.get(shift.employee!.id) || 0) + getShiftDuration(shift, shiftBreak),
                        ), new Map<string, number>(),
                    ),
            ))),
        shareReplay(1),
    );
}

