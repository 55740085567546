import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { Employee, Role } from '../../../../core/model';
import { Department } from '../../../../core/model/Department/Department.model';
import { DepartmentImpl } from '../../../../core/model/Department/DepartmentImpl';
import { EmployeeService, RoleService } from '../../../../core/services';
import { DepartmentService } from '../../../../core/services/Department/DepartmentService.model';
import { ModalService } from '../../../../core/services/ModalService.model';
import { SnackbarColor, SnackbarService } from '../../../../core/services/snackbar/snackbar.service';

@Component({
    selector: 'app-edit-employee-department',
    templateUrl: './edit-employee-department.component.html',
    styleUrls: ['./edit-employee-department.component.scss'],
})
export class EditEmployeeDepartmentComponent implements OnInit {
    public departments$: Observable<Department[]>;
    public availableDepartments: Department[];
    public roles$: Observable<Role[]>;

    @Input()
    public set selectedEmployee(value: Employee) {
        this._selectedEmployee = value;
        this.availableDepartments = [this.nullDepartment, ...value.departments.sort(DepartmentImpl.sortByName)];
    }
    public get selectedEmployee(): Employee {
        if (!this._selectedEmployee.primaryDepartment) {
            const emp: Employee = this._selectedEmployee;
            emp.primaryDepartment = this.nullDepartment;
            return emp;
        }
        return this._selectedEmployee;
    }
    private _selectedEmployee: Employee;
    private nullDepartment: Department = { id: 'null', name: 'Ingen afdeling', isDeleted: false };

    constructor(
        private departmentService: DepartmentService,
        private employeeService: EmployeeService,
        private roleService: RoleService,
        private modal: ModalService,
        private snackService: SnackbarService,
        private translateService: TranslateService,
    ) { }

    public ngOnInit(): void {
        this.translateService.get('admin.department.no-department').pipe(take(1)).toPromise().then((translatedString: string) => {
            this.nullDepartment.name = translatedString;
            this.availableDepartments = [this.nullDepartment, ...this.selectedEmployee.departments.sort(DepartmentImpl.sortByName)];
        });
        this.roles$ = this.roleService.getRoles();
        this.departments$ = this.departmentService.getDepartments().pipe(
            map((departments: Department[]) => departments.sort(DepartmentImpl.sortByName)));
    }

    /**
     * Saves the employee in its current state
     */
    public saveEmployee(): void {
        const savedEmployee = { ...this.selectedEmployee };
        if (savedEmployee.primaryDepartment?.id === 'null') savedEmployee.primaryDepartment = null;
        this.employeeService.updateEmployee(savedEmployee).pipe(take(1)).subscribe({
            error: async (err: Error): Promise<void> => {
                this.snackService.displaySnack({ translationKey: 'error.unable-to-update-employee' }, SnackbarColor.warn);
                this.selectedEmployee = await this.employeeService.getEmployee(this.selectedEmployee.id).pipe(take(1)).toPromise();
                throw err;
            },
        });
    }

    /**
     * Opens the department admin modal
     */
    public openDepartmentAdmin(): void {
        this.modal.openEditDepartmentModal();
    }
}
