<div class="steps-container">
    <div *ngFor="let step of steps; let index=index"
         class="step"
         [class.active]="index === activeStep"
         [class.done]="index < activeStep">
        <div class="step-number style-4">
            {{index < activeStep ? '' : index + 1}}
            <i *ngIf="index < activeStep"
               class="far fa-check"></i>
        </div>
        <div class="step-text-container"
             [class.clickable]="!!step.clickAction"
             (click)="onStepClick(step.clickAction)">
            <div class="step-title style-5">
                {{step.title}}
            </div>
            <div class="step-description style-9">
                {{step.description}}
            </div>
        </div>
    </div>
</div>
