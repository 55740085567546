import { isShift, Shift, ShiftBreak } from '../../../core/model';
import { FirestoreShift } from '../../../core/model/Firestore';

/**
 * Returns the duration of the break a shift in hours
 * @param shift The shift to find the break duration for
 * @param shiftBreak The shift break settings
 */
export function getShiftBreakDuration(shift: Shift | FirestoreShift, shiftBreak: ShiftBreak): number {
    // Get the number of breaks
    const breaks = Math.floor(getShiftDuration(shift) / shiftBreak.shiftLengthInterval);

    // Return the number of breaks multiplied with the duration of the breaks
    return breaks * shiftBreak.breakDuration;
}


export function getShiftDuration(shift: Shift | FirestoreShift, shiftBreak?: ShiftBreak | null): number {
    // 60*60*1000 = 3600000 -> The amount of milliseconds in an hour
    let shiftDuration: number;
    if (isShift(shift)) {
        shiftDuration = (shift.end.getTime() - shift.start.getTime()) / 3600000;
    } else {
        shiftDuration = (shift.end.toDate().getTime() - shift.start.toDate().getTime()) / 3600000;
    }
    return shiftBreak
        ? shiftDuration - getShiftBreakDuration(shift, shiftBreak)
        : shiftDuration;
}
