import { Pipe, PipeTransform } from '@angular/core';
import { SalaryIntegration } from '../../core/model/Integrations/Shared';

@Pipe({
    name: 'getIntegrationLogo',
})
export class GetIntegrationLogoPipe implements PipeTransform {

    private danloen_logo_url: string = 'assets/images/danloen-logo.svg';
    private dataloen_logo_url: string = 'assets/images/dataloen-logo.svg';
    private salary_logo_url: string = 'assets/images/salary-dk-logo.svg';

    public transform(integration: SalaryIntegration): string {
        switch (integration) {
            case SalaryIntegration.DANLOEN: return this.danloen_logo_url;
            case SalaryIntegration.DATALOEN: return this.dataloen_logo_url;
            case SalaryIntegration.SALARYDK: return this.salary_logo_url;
        }
    }
}
