import { Component, Input } from '@angular/core';
import { Employee } from '../../../../../core/model';
import { SalaryIntegration } from '../../../../../core/model/Integrations/Shared';

@Component({
    selector: 'app-export-log-info',
    templateUrl: './export-log-info.component.html',
    styleUrls: ['./export-log-info.component.scss'],
})
export class ExportLogInfoComponent {
    @Input() public exportedBy: Employee | 'automatic';
    @Input() public salaryIntegration: SalaryIntegration;
    @Input() public time: Date;

}
