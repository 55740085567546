<div class="files">

    <!-- THE UPLOADED FILES -->
    <div class="button-container"
         *ngFor="let file of files">
        <ui-button-standard (click)="openFile(file.url)"
                            [loading]="file.loading"
                            [appProLabel]="Package.PRO"
                            wide>
            <div class="file-name">
                <div class="dot-dot-dot">{{ file.name }}</div>
                <i class="far fa-times"
                   (click)="deleteFile($event, file)"></i>
            </div>
        </ui-button-standard>
    </div>

    <!-- UPLOAD A NEW FILE BUTTON -->
    <div class="button-container">
        <ui-button-standard color="primary"
                            [loading]="uploading"
                            [appProLabel]="Package.PRO"
                            wide>

            <!-- THE FILE INPUT FIELD -->
            <label for="employee-file-input">
                <input id="employee-file-input"
                       #input
                       type="file"
                       (change)="fileChangeEvent($event.target.files[0], input)" />
                {{ 'modal.team.upload-a-file' | translate }}
            </label>

            <!-- HIDDEN SPAN ONLY USED FOR SETTING THE WIDTH OF THE BUTTON -->
            <span class="hidden"> {{ 'modal.team.upload-a-file' | translate }}</span>
        </ui-button-standard>
    </div>
</div>