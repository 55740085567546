import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Shift, ShiftBreak } from '../../core/model';
import { CompanySetting, CompanySettingsService } from '../../core/services';
import { getShiftBreakDuration } from '../utilities/ShiftUtils/durationUtils';

@Pipe({
    name: 'shiftBreak',
})
export class ShiftBreakPipe implements PipeTransform {

    constructor(
        private companySettingsService: CompanySettingsService,
    ) { }

    /**
     * Returns the break duration of a given shift in minutes
     * @param shift The shift for which to get the break duration
     * @param shiftBreak The break settings object
     */
    public transform(shift: Shift): Observable<number> {
        return this.companySettingsService.loadSetting(CompanySetting.SHIFT_BREAK)
            .pipe(map((shiftBreak: ShiftBreak | null) => shiftBreak ? getShiftBreakDuration(shift, shiftBreak) * 60 : 0));
    }
}
