import { Injectable } from '@angular/core';
import { AngularFirestoreCollection, QuerySnapshot } from '@angular/fire/firestore';
import * as moment from 'moment';
import { Observable, Subscriber } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { Department } from '../../../model/Department/Department.model';
import { departmentsCollectionPath } from '../../../model/Firestore';
import { FirestorePrediction } from '../../../model/Firestore/FirestorePrediction';
import { Prediction } from '../../../model/Prediction.model';
import { Auth, FirestoreService } from '../../firestore.service';
import { ForecastService } from '../ForecastService.model';


@Injectable({
    providedIn: 'root',
})
export class FirestoreForecastService extends ForecastService {

    constructor(private firestoreService: FirestoreService) {
        super();
    }

    public getDailyForecast(date: Date, department: Department): Observable<Prediction | null> {
        return this.getFirestoreDailyForecast(date, department).pipe(
            map((forecast: FirestorePrediction | undefined) => forecast ? this.mapFirestorePredictionToPrediction(forecast) : null),
        );
    }

    private getFirestoreDailyForecast(date: Date, department: Department): Observable<FirestorePrediction | undefined> {
        return this.getDailyRevenueForecastsCollection(date, department).pipe(
            switchMap((collection: AngularFirestoreCollection<FirestorePrediction>) =>
                new Observable((subscriber: Subscriber<FirestorePrediction | undefined>) => {
                    collection.ref.limit(1).orderBy('predictionTime', 'desc')
                        .onSnapshot(
                            (snapshot: QuerySnapshot<FirestorePrediction>) => subscriber.next(snapshot.docs[0]?.data()),
                            (error: Error) => subscriber.error(error),
                            () => subscriber.complete());
                })));
    }

    private getDailyRevenueForecastsCollection(date: Date, department: Department):
        Observable<AngularFirestoreCollection<FirestorePrediction>> {
        const dayID = moment(date).format('YYYY-MM-DD');
        return this.firestoreService.authenticate(departmentsCollectionPath)
            .pipe(map((auth: Auth<Department>) =>
                auth.collection.doc(department.id)
                    .collection('days').doc(dayID)
                    .collection('dailyRevenueForecasts')));
    }

    private mapFirestorePredictionToPrediction(firestorePrediction: FirestorePrediction): Prediction {
        return ({ ...firestorePrediction, predictionTime: firestorePrediction.predictionTime.toDate() });
    }
}
