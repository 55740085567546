import { Injector } from '@angular/core';
import { take } from 'rxjs/operators';
import { Approval } from '../../core/model/Approval.model';
import { Employee } from '../../core/model/Employee.model';
import { FirestoreApproval } from '../../core/model/Firestore/FirestoreApproval';
import Timestamp from '../../core/model/Firestore/FirestoreTimestamp';
import { Shift } from '../../core/model/Shift.model';
import { EmployeeService } from '../../core/services/Employee/EmployeeService.model';

export async function mapFirestoreApprovalToApproval(firestoreApproval: FirestoreApproval, injector: Injector): Promise<Approval> {
    const approver = firestoreApproval.approver === 'automatic'
        ? 'automatic'
        : await injector.get(EmployeeService).getEmployee(firestoreApproval.approver).pipe(take(1)).toPromise();
    return {
        approver,
        date: firestoreApproval.date.toDate(),
        originalStart: firestoreApproval.originalStart.toDate(),
        originalEnd: firestoreApproval.originalEnd.toDate(),
    };
}

export function mapApprovalToFirestoreApproval(approval: Approval): FirestoreApproval {
    const approver = isEmployee(approval.approver) ? approval.approver.id : 'automatic';
    return {
        approver,
        date: Timestamp.fromDate(approval.date),
        originalStart: Timestamp.fromDate(approval.originalStart),
        originalEnd: Timestamp.fromDate(approval.originalEnd),
    };
}


export async function createApproval(originalShift: Shift, injector: Injector): Promise<Approval> {
    return {
        approver: await injector.get(EmployeeService).getAuthenticatedEmployee().pipe(take(1)).toPromise(),
        date: new Date(),
        originalStart: new Date(originalShift.start),
        originalEnd: new Date(originalShift.end),
    };
}


function isEmployee(maybeEmployee: Employee | 'automatic'): maybeEmployee is Employee {
    if (maybeEmployee === 'automatic') return false;
    return true;
}
