import { Injectable } from '@angular/core';
import { CollectionService } from 'akita-ng-fire';
import { companiesCollectionPath, departmentsCollectionPath } from '../../../model/Firestore';
import { AuthQuery } from '../../auth/state/auth.query';
import { DepartmentState, DepartmentStore } from './department.store';

@Injectable({ providedIn: 'root' })
export class AkitaDepartmentService extends CollectionService<DepartmentState> {

    constructor(store: DepartmentStore, private authQuery: AuthQuery) {
        super(store);
    }

    get path(): string {
        const companyID = this.authQuery.getValue().roles.companyID;
        return `${ companiesCollectionPath }/${ companyID }/${ departmentsCollectionPath }`;
    }

}
