import { Observable, throwError } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { Company } from '../../model';
import { AuthService, AuthUser } from '../auth.service';

export abstract class CompanyService {

    constructor(private authService: AuthService) { }
    public getCurrentCompany(): Observable<Company> {
        return this.authService.getUser().pipe(
            switchMap((user: AuthUser | null) => {
                if (user) return this.getCompany(user.companyID);
                else return throwError('Not authenticated');
            }),
        );
    }

    /**
     * Gets a company from the storage with the specific id.
     * @param id
     */
    public abstract getCompany(id: string): Observable<Company>;

    /**
     * Gets all the companies in storage.
     */
    public abstract getCompanies(): Observable<Company[]>;

    /**
     * Updates a company.
     * @param company The company you want to update.
     */
    public abstract updateCompany(company: Company): Observable<Company>;

}
