import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Pipe, PipeTransform } from '@angular/core';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';
import { Wish } from '../../core/model';

@Pipe({
    name: 'wishTimeToText',
})
export class WishTimeToTextPipe implements PipeTransform {
    constructor(
        private translateService: TranslateService,
        private cdf: ChangeDetectorRef,
    ) { }
    /**
     * Retreive a string textually representing the time span of a wish
     * @param wish The wish to get the text for
     */
    public transform(wish: Wish): string {
        const translatePipe: TranslatePipe = new TranslatePipe(this.translateService, this.cdf);
        // Check whether the wish from (to) times are midnight
        const fromIsMidnight: boolean = wish.from.getHours() === 0 && wish.from.getMinutes() === 0;
        const toIsMidnight: boolean = wish.to.getHours() === 0 && wish.to.getMinutes() === 0;

        // If both are midnight, return nothing (so it just becomes e.g. 'Ønsker fri')
        if (fromIsMidnight && toIsMidnight) return '';

        // If one is not midnight, initialize the date pipe for formatting
        const datePipe: DatePipe = new DatePipe('da-DK');

        // If from is midnight, return e.g. 'indtil 9:05'
        if (fromIsMidnight) return translatePipe.transform('employee.calendar.until-time', { time: datePipe.transform(wish.to, 'H:mm') });

        // If to is midnight, return e.g. 'fra 9:08'
        if (toIsMidnight) return translatePipe.transform('employee.calendar.from-time', { time: datePipe.transform(wish.from, 'H:mm') });

        // If none are midnight, just return the formatted times, e.g. '9:05-18:30'
        return datePipe.transform(wish.from, 'H:mm') + '-' + datePipe.transform(wish.to, 'H:mm');
    }
}
