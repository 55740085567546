<div class="notification"
     (click)="notification.clickAction()"
     [class.read]="notification.read">
    <div class="picture">
        <img *ngIf="notification.imageSrc"
             class="picture"
             [lazyLoad]="notification.imageSrc">
        <i *ngIf="notification.iconClass"
           [class]="notification.iconClass"></i>
    </div>
    <div class="main-content">
        <div class="title">
            {{ notification.title }}
        </div>
        <div class="subtitle">
            {{ notification.subtitle }}
        </div>
        <div class="time">
            {{ notification.time | amTimeAgo }}
        </div>
    </div>
    <div class="unread-badge"></div>
</div>