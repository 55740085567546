<div class="detail-shift-export-log">
    <div class="picture">
        <img *ngIf="salaryExport.exportedBy !== 'automatic'"
             class="picture"
             [src]="salaryExport.exportedBy.imageURL">
        <i *ngIf="salaryExport.exportedBy === 'automatic'"
           [class]="iconClass"></i>
    </div>
    <div class="main-content">
        <div class="name">
            {{ salaryExport.exportedBy !== 'automatic'
            ? salaryExport.exportedBy.firstname + ' ' + salaryExport.exportedBy.lastname
            : ('shared.relion' | translate)
            }}
        </div>
        <app-export-log-info
                [exportedBy]="salaryExport.exportedBy"
                [salaryIntegration]="salaryExport.salaryIntegration"
                [time]="salaryExport.time">
        </app-export-log-info>
    </div>
</div>
