<div class="text-center profile-pic-container">
    <img *ngIf="employee$ | async as employee"
         [src]="employee.imageURL">
    <div class="img"
         *ngIf="!(employee$ | async)">
        <div> <i class="fa fa-spin fa-spinner"></i> </div>
    </div>
    <div *ngIf="!(disallowEditProfilePicture$ | async)">
        <label for="fileChooser">{{"settings.change-profile-picture" | translate | capitalize}}</label>
        <input id="fileChooser"
               #input
               type="file"
               accept="image/*"
               (change)="fileChangeEvent($event)" />
    </div>
</div>
