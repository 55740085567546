<overlay-scrollbars class="main">
    <div class="header">
        <ui-button-icon [basic]="true"
                        class="back"
                        (click)="goBack()">
            <i class="fas fa-chevron-left"></i>
        </ui-button-icon>
        <span class="department-name">{{department.name}}</span>

    </div>
    <div class="body">
        <div class="input-title">{{ 'shared.name' | translate }}</div>
        <input class="department-name-input"
               [ngClass]="{'department-name-input-error': !isDepartmentNameValid}"
               [(ngModel)]="departmentName"
               (blur)="updateNameIfValid()">
        <div class="department-name-input-error-message"
             *ngIf="!isDepartmentNameValid">{{ 'shared.invalid-name' | translate }}</div>
        <app-edit-department-roles [departments$]="departments$"
                                   [department]=department>
        </app-edit-department-roles>
        <div class="primary-employee-title">{{ 'shared.primary-employees' | translate }}</div>
        <app-edit-department-employees [department]="department"
                                       [employees$]="employees$"
                                       [roles$]="roles$"
                                       [primary]="true"
                                       (updatedEmployee)="queueEmployeeForSaving($event)">
        </app-edit-department-employees>
        <div class="primary-employee-subtitle">{{ 'admin.department.primary-employee-description' | translate }}</div>
        <div class="secondary-employee-title">{{ 'shared.secondary-employees' | translate }}</div>
        <app-edit-department-employees [department]="department"
                                       [employees$]="employees$"
                                       [roles$]="roles$"
                                       [primary]="false"
                                       (updatedEmployee)="queueEmployeeForSaving($event)">
        </app-edit-department-employees>
        <div class="secondary-employee-subtitle">{{ 'admin.department.secondary-employee-description' | translate }}
        </div>

        <ui-button-standard color="warn"
                            class="delete-department-button"
                            *ngIf="(departments$ | async)?.length > 1"
                            (click)="openDeleteDepartmentModal()">
            {{ 'admin.department.delete-department' | translate | capitalize }}
        </ui-button-standard>
    </div>

</overlay-scrollbars>