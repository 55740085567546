<overlay-scrollbars class="wage-container">
    <form *ngIf="hourlyWageFormGroup"
          [formGroup]="hourlyWageFormGroup">
        <div *ngFor="let employee of employees; trackBy: trackEmployee"
             class="wage-employees">
            <div class="wage-profile">
                <div class="wage-profile-image">
                    <img lazyLoad="{{employee.imageURL}}">
                </div>
                <div class="wage-profile-name">
                    {{employee.firstname}} {{employee.lastname}}
                </div>
            </div>
            <div class="input-wage">
                <input [formControlName]="employee.id"
                       type="text"
                       [appFloatingLabel]="'salary.salary-in-kr' | translate "
                       (blur)="setEmployeeHourlyWage(employee)"
                       [errorMessage]=" 'error.hourly-wage-requirements' | translate " />
            </div>
        </div>
    </form>
</overlay-scrollbars>