import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { GPSZone } from '../../../../../../../core/model';
import { Package } from '../../../../../../../core/model/Freemium/Package';
import { CompanySetting, CompanySettingsService, CompanySettingsTypeMap } from '../../../../../../../core/services';
import { ConfigurationService } from '../../../../../../../core/services/Configuration/configuration-service.model';
import { ModalService } from '../../../../../../../core/services/ModalService.model';
import { GpsZoneService } from '../../components/punchclock/gps-zone.service';

@Component({
    selector: 'app-punchclock-settings',
    templateUrl: './punchclock-settings.component.html',
    styleUrls: ['./punchclock-settings.component.scss'],
})
export class PunchclockSettingsComponent implements OnInit, OnDestroy {
    public punchclockEnabled$: Observable<boolean>;
    public roundCheckin$: Observable<boolean>;
    public roundCheckout$: Observable<boolean>;
    public CompanySetting: typeof CompanySetting = CompanySetting;
    public isPro$: Observable<boolean>;
    public trialAvailable$: Observable<boolean>;

    public zoneUnderEdit$: Observable<Partial<GPSZone> | undefined>;

    constructor(
        private companySettingsService: CompanySettingsService,
        private configurationService: ConfigurationService,
        private gpsZoneService: GpsZoneService,
        private modalService: ModalService,
    ) { }

    public ngOnInit(): void {
        this.punchclockEnabled$ = this.getSetting(CompanySetting.PUNCHCLOCK_ENABLED_ON)
            .pipe(map((enabledOn: Date | null) => !!enabledOn), shareReplay({ bufferSize: 1, refCount: true }));
        this.roundCheckin$ = this.getSetting(CompanySetting.ROUND_CHECKIN);
        this.roundCheckout$ = this.getSetting(CompanySetting.ROUND_CHECKOUT);
        this.isPro$ = this.configurationService.isPro(Package.PRO).pipe(shareReplay({ bufferSize: 1, refCount: false }));
        this.zoneUnderEdit$ = this.gpsZoneService.getZoneUnderEdit();
        this.trialAvailable$ = this.configurationService.freeTrialAvailable(Package.PRO);
    }

    /**
     * Save the PUNCHCLOCK_ENABLED_ON setting to the db (eiter a new date or null)
     * @param enabled - boolean indicating whether the setting should be turned on or off
     */
    public setPunchclockEnabled(enabled: boolean): void {
        this.companySettingsService.saveSetting(CompanySetting.PUNCHCLOCK_ENABLED_ON, enabled ? new Date() : null);
    }

    /**
     * Save a boolean setting to the db
     * @param enabled - boolean indicating whether the setting should be turned on or off
     * @param setting - the setting to save
     */
    public setEnabled(enabled: boolean, setting: CompanySetting): void {
        this.companySettingsService.saveSetting(setting, enabled);
    }

    public ngOnDestroy(): void {
        this.gpsZoneService.cancelEdit();
    }

    public openProShop(): void {
        this.modalService.openProShopOverlay();
    }

    private getSetting<T extends CompanySetting>(setting: T): Observable<CompanySettingsTypeMap[T]> {
        return this.companySettingsService.loadSetting(setting);
    }
}

