import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { DateService } from '../../../core/services';

export enum DateSwitchType {
    BACK,
    CURRENT,
    NEXT,
}

@Component({
    selector: 'ui-date-switcher',
    templateUrl: './date-switcher.component.html',
    styleUrls: ['./date-switcher.component.scss'],
})

export class DateSwitcherComponent implements OnInit, OnDestroy {

    @Input() public range: 'month' | 'day';
    @Output() public onSwitchDate: EventEmitter<DateSwitchType>;
    public currentlyShownDate: Date;
    public sub: Subscription;

    constructor(private dateService: DateService) {
        this.onSwitchDate = new EventEmitter();
    }

    /**
     * Subscription gets set to the "currentlyShownDate"
     */
    public ngOnInit(): void {
        this.sub = this.dateService.getDateObservable().subscribe((date: Date) => this.currentlyShownDate = date);
    }

    /**
     * unsubscribes from observable
     */
    public ngOnDestroy(): void {
        this.sub.unsubscribe();
    }

    /**
     * Changes the month or day to either the next or previous depending on the button pressed,
     * which is send with the magnitude param
     */
    public onDateChangePressed(magnitude: number): void {
        const newDate: Date = new Date(this.currentlyShownDate);
        if (this.range === 'month') {
            newDate.setDate(1);
            newDate.setMonth(newDate.getMonth() + magnitude);
        } else if (this.range === 'day') {
            newDate.setDate(newDate.getDate() + magnitude);
        }
        this.dateService.changeDate(newDate);

        if (magnitude < 0) this.onSwitchDate.emit(DateSwitchType.BACK);
        else this.onSwitchDate.emit(DateSwitchType.NEXT);
    }

    /**
     * Gets the current day
     */
    public onCurrentPressed(): void {
        this.dateService.changeDate(new Date());
        this.onSwitchDate.emit(DateSwitchType.CURRENT);
    }
}
