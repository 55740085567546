import { Shift } from '../../../core/model';
import { mapPunchToFirestorePunch } from '../../../core/model/Firestore/FirestorePunch';
import { FirestoreShift } from '../../../core/model/Firestore/FirestoreShift';
import Timestamp from '../../../core/model/Firestore/FirestoreTimestamp';
import { mapApprovalToFirestoreApproval } from '../ApprovalUtils';

/**
 * Map a Shift to a partial FirestoreShift for a patch update.
 * @param shift The updated shift
 * @returns A new partial FirestoreShift object, with the updated fields
 */

export function mapShiftToFirestoreshift(shift: Shift): Omit<FirestoreShift, 'shiftForSaleID'> {
    const firestoreShift: Omit<FirestoreShift, 'shiftForSaleID'> = {
        role: shift.role.id,
        department: shift.role.department.id,
        start: Timestamp.fromDate(shift.start),
        end: Timestamp.fromDate(shift.end),
        approved: shift.approved,
        employeeID: shift.employee ? shift.employee.id : null,
        released: shift.released,
        comment: shift.comment
            ? { senderId: shift.comment.sender.id, message: shift.comment.message, time: Timestamp.fromDate(shift.comment.time) }
            : null,
        checkIn: null,
        checkInMsg: null,
        checkInSource: null,
        checkOut: null,
        checkOutMsg: null,
        checkOutSource: null,
        exportLogs: shift.exportLogs.map((exportLog => ({
            exportedBy: exportLog.exportedBy !== 'automatic' ? exportLog.exportedBy?.id : 'automatic',
            salaryIntegration: exportLog.salaryIntegration,
            time: Timestamp.fromDate(exportLog.time),
        }))),
        salarySwipe: !shift.salarySwipe ? null : {
            enabledOn: Timestamp.fromDate(shift.salarySwipe.enabledOn),
            enabledBy: shift.salarySwipe.enabledBy === 'automatic' ? 'automatic' : shift.salarySwipe.enabledBy.id,
        },
    };

    if (shift.approval) {
        firestoreShift.approval = mapApprovalToFirestoreApproval(shift.approval);
    }

    if (shift.checkin) {
        firestoreShift.checkInSource = shift.checkin.source;
        firestoreShift.checkIn = Timestamp.fromDate(shift.checkin.time);
        if (shift.checkin.message) {
            firestoreShift.checkInMsg = shift.checkin.message;
        } else {
            firestoreShift.checkInMsg = null;
        }
        if (shift.checkin.overwriting) {
            firestoreShift.overwritingCheckinPunch = mapPunchToFirestorePunch(shift.checkin.overwriting);
        }
    }

    if (shift.checkout) {
        firestoreShift.checkOutSource = shift.checkout.source;
        firestoreShift.checkOut = Timestamp.fromDate(shift.checkout.time);
        if (shift.checkout.message) {
            firestoreShift.checkOutMsg = shift.checkout.message;
        } else {
            firestoreShift.checkOutMsg = null;
        }
        if (shift.checkout.overwriting) {
            firestoreShift.overwritingCheckoutPunch = mapPunchToFirestorePunch(shift.checkout.overwriting);
        }
    }

    if (shift.customBreak) {
        firestoreShift.customBreak = {
            createdOn: Timestamp.fromDate(shift.customBreak.createdOn),
            creator: shift.customBreak.creator !== 'automatic' ? shift.customBreak.creator.id : 'automatic',
            duration: shift.customBreak.duration,
        }
    }

    return firestoreShift;
}
