<div class="employee-modal-profile"
     [class.isMobile]="isMobile">
    <form #editEmployeeProfileForm="ngForm"
          [formGroup]="editEmployeeFormGroup"
          (ngSubmit)="saveEdit()">
        <div class="body-container">
            <div class="body-left-column"
                 [class.editing-profile]="editingProfile">
                <label class="image-overlay"
                       [class.edit-mode]="editingProfile && ((isEditingLoggedInUser$ | async) || (isAdmin$ | async))">
                    <img class="body-image"
                         [src]="selectedEmployee?.imageURL">
                    <input *ngIf="editingProfile && ((isEditingLoggedInUser$ | async) || (isAdmin$ | async))"
                           #input
                           type="file"
                           accept="image/*"
                           (change)="fileChangeEvent($event)" />
                </label>
                <ui-button-standard *ngIf="!editingProfile"
                                    color="primary"
                                    (click)="editProfile()">{{'shared.edit' | translate | capitalize}}
                </ui-button-standard>
                <ui-button-standard *ngIf="editingProfile"
                                    color="primary"
                                    [disabled]="editEmployeeProfileForm.invalid"
                                    [type]="'submit'">{{ 'shared.done' | translate }}</ui-button-standard>
                <ui-button-text *ngIf="editingProfile"
                                class="cancel-button"
                                (click)="cancelEdit()">{{'shared.cancel' | translate }}</ui-button-text>
            </div>
            <div class="body-right-column"
                 [class.not-editing-profile]="!editingProfile">
                <ng-container *ngIf="editingProfile; else showEmployeeProfile">
                    <div class="employee-modal-profile-info-edit"
                         [formGroup]="editEmployeeFormGroup">
                        <input [appFloatingLabel]="'shared.name' | translate | capitalize"
                               inputColor="grey"
                               required
                               formControlName="name" />
                        <input [appFloatingLabel]="'shared.phone-number' | translate"
                               inputColor="grey"
                               required
                               type="tel"
                               formControlName="phone" />
                        <input [appFloatingLabel]="'shared.email' | translate"
                               inputColor="grey"
                               required
                               formControlName="email" />
                        <input [appFloatingLabel]="'shared.street-and-number' | translate | capitalize"
                               inputColor="grey"
                               formControlName="street" />
                        <input [appFloatingLabel]="'shared.zip-code' | translate | capitalize"
                               inputColor="grey"
                               type="number"
                               formControlName="zip" />
                        <input [appFloatingLabel]="'shared.city' | translate | capitalize"
                               inputColor="grey"
                               formControlName="city" />
                        <input [appFloatingLabel]="'shared.cpr-number' | translate | capitalize"
                               inputColor="grey"
                               formControlName="cpr"
                               [errorMessage]=" 'error.cpr-requirements' | translate " />
                        <input [appFloatingLabel]="'salary.hourly-wage' | translate"
                               inputColor="grey"
                               formControlName="hourlyWage"
                               [errorMessage]=" 'error.hourly-wage-requirements' | translate " />
                        <ui-button-standard *ngIf="!(isEditingLoggedInUser$ | async)"
                                            class="delete-employee-button"
                                            color="warn"
                                            [ngbPopover]="confirmDeleteEmployeeDialog"
                                            popoverClass="confirm-delete-dialog">
                            {{ 'shared.delete-employee' | translate |capitalize }}
                        </ui-button-standard>
                    </div>
                </ng-container>
            </div>
        </div>
    </form>
</div>

<ng-template #showEmployeeProfile>
    <div class="employee-modal-profile-info">
        <span>{{selectedEmployee?.firstname + ' ' + selectedEmployee?.lastname}}</span>
        <div ngbDropdown
             *ngIf="bigScreen$ | async"
             class="employee-type"
             [class.hide-employee-type]="selectedUserAdmin === null || loadingAdminState"
             [class.employee-type-admin]="selectedUserAdmin === true">
            <span ngbDropdownToggle>
                {{ selectedUserAdmin ? ('shared.administrator' | translate) : ('shared.employee' | translate | capitalize) }}
                <i class="fas fa-caret-down"></i>

                <div ngbDropdownMenu>
                    <button ngbDropdownItem
                            *ngIf="!(isEditingLoggedInUser$ | async)"
                            (click)="setAdmin(selectedEmployee, !selectedUserAdmin)">
                        {{ selectedUserAdmin
                        ? ('admin.employees.set-admin-false' | translate: { user: selectedEmployee.firstname })
                        : ('admin.employees.set-admin-true' | translate: { user: selectedEmployee.firstname }) }}
                        <app-upgrade-arrow></app-upgrade-arrow>
                    </button>
                    <button class="remove-self-warning"
                            ngbDropdownItem
                            disabled
                            *ngIf="isEditingLoggedInUser$ | async">
                        {{'admin.employees.set-admin-self-warning' | translate }}
                    </button>
                </div>
            </span>
        </div>

        <div class="info-column-row">
            <div class="info-column-left">{{'shared.phone-number' | translate }}</div>
            <div class="info-column-right">{{selectedEmployee?.phone}}</div>
        </div>
        <div class="info-column-row">
            <div class="info-column-left">{{'shared.email' | translate }}</div>
            <div class="info-column-right">{{selectedEmployee?.email}}</div>
        </div>
        <div class="info-column-row">
            <div class="info-column-left">{{'shared.street-and-number' | translate | capitalize}}</div>
            <div class="info-column-right">{{selectedEmployee?.street}}</div>
        </div>
        <div class="info-column-row">
            <div class="info-column-left">{{'shared.zip-code' | translate | capitalize}}</div>
            <div class="info-column-right">{{selectedEmployee?.zip}}</div>
        </div>
        <div class="info-column-row">
            <div class="info-column-left">{{'shared.city' | translate | capitalize}}</div>
            <div class="info-column-right">{{selectedEmployee?.city}}</div>
        </div>
        <div class="info-column-row">
            <div class="info-column-left">{{'shared.cpr-number' | translate | capitalize}}</div>
            <div class="info-column-right">{{selectedEmployee?.CPR}}</div>
        </div>
        <div class="info-column-row">
            <div class="info-column-left">{{'salary.hourly-wage' | translate }}</div>
            <div class="info-column-right"> {{ selectedEmployee?.hourlyWage != null
                    ? ('freemium.dkk' | translate: { 'amount': ((selectedEmployee?.hourlyWage | number: '1.2' : translateService.currentLang )) })
                    : '-' }}
            </div>
        </div>

        <ng-container *ngIf="!isMobile">
            <div class="heading"> {{ 'shared.files' | translate }} </div>
            <app-employee-files [employee]="selectedEmployee"></app-employee-files>

            <div class="heading"> {{ 'shared.notes' | translate }} </div>
            <app-employee-notes [employee]="selectedEmployee"></app-employee-notes>
        </ng-container>

    </div>
</ng-template>

<ng-template #confirmDeleteEmployeeDialog>
    <ui-button-standard color="warn"
                        (click)="deleteEmployee()">{{ 'shared.delete-employee-confirm' | translate }}
    </ui-button-standard>
    <ui-button-standard class="cancel-button">{{'shared.keep' | translate | capitalize}}</ui-button-standard>
</ng-template>
