<div class="container">
    <h1>
        {{ 'admin.new-employee-modal.you-have-a-new-employee' | translate }}
    </h1>
    <p>
        {{ 'admin.new-employee-modal.assign-them-department-and-roles' | translate }}
    </p>

    <img alt="Illustration of how to assign roles"
         src="assets/images/assign-role.svg">

    <div class="btn-wrapper">
        <ui-button-action class="create-profile"
                          (click)="goToEmployees()">
            {{ 'admin.new-employee-modal.go-to-employees' | translate }}
        </ui-button-action>
    </div>
</div>