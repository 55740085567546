import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'minutesToTime',
})
export class MinutesToTimePipe implements PipeTransform {

    public transform(minutesSinceMidnight: number): string {
        const pad = (num: number): string => num < 10 ? '0' + num : num.toString();

        const hours = Math.floor(minutesSinceMidnight / 60);
        const minutes = minutesSinceMidnight % 60;

        return pad(hours) + ':' + pad(minutes);
    }
}
