<div class="d-flex delete-department-modal">
    <ng-container *ngIf="!loading">
        <ui-button-standard color="warn"
                            class="delete-department-button"
                            (click)="deleteDepartment()">
            {{ 'admin.department.delete-department' | translate | capitalize }}
        </ui-button-standard>
        <ui-button-standard (click)="close(false)">
            {{ 'admin.department.keep-department' | translate | capitalize }}
        </ui-button-standard>
    </ng-container>
    <i *ngIf="loading"
       class="fa fa-spin fa-spinner"></i>
</div>