import { Injectable } from '@angular/core';
import { ActiveState, EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { Wish } from '../../../model/Wish.model';

export interface WishState extends EntityState<Wish, string>, ActiveState<string> { }

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'wish' })
export class WishStore extends EntityStore<WishState> {

    constructor() {
        super();
    }

}
