import { Injectable, Injector } from '@angular/core';
import { UserCredential } from '@firebase/auth-types';
import * as Sentry from '@sentry/browser';
import { Scope } from '@sentry/browser';
import firebase from 'firebase/app';
import { Observable, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { revokeNotificationToken } from '../../shared/utilities/NotificationUtils/revokeNotificationToken';
import { AkitaAuthService } from './auth/state/auth.service';

export interface AuthUser {
    admin: boolean;
    approved: boolean;
    companyID: string;
    email: string;
    user_id: string;
}
// TODO: [GO-2472] Retire this service, transitioning in to using the akita service instead
@Injectable()
export class AuthService {
    private userObservable: ReplaySubject<AuthUser | null>;
    private user: AuthUser | null;

    constructor(
        private injector: Injector,
        private akitaAuthService: AkitaAuthService,
    ) {
        this.userObservable = new ReplaySubject(1);
        // Subscribe to idToken change events (e.g. login and logout)
        this.akitaAuthService.auth.idToken.subscribe({
            next: (token: string): void => {
                if (token) {
                    // Logging in - get the user info from the web token
                    this.user = AuthService.parseJwt(token);
                    this.userObservable.next(this.user);
                } else {
                    // Logging out - clear the user info
                    this.user = null;
                    this.userObservable.next(null);
                }
                Sentry.configureScope((scope: Scope) => scope.setUser(this.user && {
                    id: this.user.user_id,
                    email: this.user.email,
                }));
                Sentry.setTags({
                    admin: this.user ? String(this.user.admin) : 'Not logged in',
                    companyID: this.user ? String(this.user.companyID) : 'Not logged in',
                });
            },
        });
    }

    /**
     * Log in into firebase using the given email and password.
     * @param {string} email: the email to be logged in with
     * @param {string} password: the password to be logged in with.
     * @returns {Promise<>} Promise that is fulfilled when logged in (and should be caught if login fails)
     */
    public async loginWithEmailPassword(email: string, password: string): Promise<UserCredential> {
        // Verify that user exists and reject if not
        const signinMethods: string[] = await this.akitaAuthService.auth.fetchSignInMethodsForEmail(email);
        if (!signinMethods.length) return Promise.reject({ code: 'auth/user-not-found' });

        return this.akitaAuthService.signin(email, password);

    }

    /**
     * Log in into firebase using the given email and password.
     * @param {string} email: the email to be logged in with
     * @param {string} password: the password to be logged in with.
     * @returns {Promise<>} Promise that is fulfilled when logged in (and should be caught if login fails)
     */
    public async loginWithEmailLink(): Promise<void> {
        if (await this.akitaAuthService.auth.isSignInWithEmailLink(window.location.href)) {
            // Get the email if available. This should be available if the user completes
            // the flow on the same device where they started it.
            let email = window.localStorage.getItem('emailForSignIn');
            if (!email) {
                // User opened the link on a different device. To prevent session fixation
                // attacks, ask the user to provide the associated email again. For example:
                email = window.prompt('Please provide your email for confirmation');
            }
            if (!email) throw Error('No email was given for logging in');
            this.akitaAuthService.auth.signInWithEmailLink(email, window.location.href)
                .then(() => {
                    // Clear email from storage.
                    window.localStorage.removeItem('emailForSignIn');
                });
        }
    }

    /**
     * Send a log in link to an email
     * @param {string} email: the email to send the mail to
     * @returns {Promise<void>} Promise that resolves if the mail was sent succesfully
     */
    public async sendEmailLoginLink(email: string): Promise<void> {
        const userExists = (await this.availableLoginMethods(email)).length > 0;
        if (userExists) {
            const settings: firebase.auth.ActionCodeSettings = {
                url: 'https://app.relion.dk/login',
                handleCodeInApp: true,
                android: {
                    packageName: 'com.relion.dk',
                },
                iOS: { bundleId: 'dk.relion.app' },
                dynamicLinkDomain: 'relion.page.link',
            };
            await this.akitaAuthService.auth.sendSignInLinkToEmail(email, settings);
            localStorage.setItem('emailForSignIn', email);
        } else {
            throw Error('User not found');
        }
    }

    public isLoginWithEmailLink(url: string): Promise<boolean> {
        return this.akitaAuthService.auth.isSignInWithEmailLink(url);
    }

    /**
     * Log out of firebase
     * @returns {Promise<any>} Fulfilled when logged out (fails if something goes wrong)
     */
    public async logout(): Promise<void> {
        try {
            revokeNotificationToken(this.injector);
            await this.akitaAuthService.signOut();
        } finally {
            setTimeout(() => window.location.assign('/'), 0);
        }
    }

    /**
     * Returns an observable with the current user.
     * The user is null when not logged in, which should be handled.
     * @returns {Observable<AuthUser>} The observable
     */
    public getUser(): Observable<AuthUser | null> {
        return this.userObservable.asObservable();
    }

    /**
     * Returns an observable with the admin state of the current user
     */
    public userIsAdmin(): Observable<boolean> {
        return this.getUser().pipe(map((user: AuthUser | null) => !!user?.admin));
    }

    /**
     * Adds a user to the firebase user database with the specified email and password
     * @param {string} email
     * @param {string} password
     */
    public addUser(email: string, password: string): Promise<UserCredential> {
        return this.akitaAuthService.auth.createUserWithEmailAndPassword(email, password);
    }

    /**
     * Send a forgotten password request to firebase and returns the promise from fb
     * @param {string} forgottenMail
     */
    public forgotPassword(forgottenMail: string): Promise<void> {
        return this.akitaAuthService.auth.sendPasswordResetEmail(forgottenMail);
    }

    /**
     * Returns an array of the available signin methods for the auth user associated with the given email
     * @param email
     */
    public availableLoginMethods(email: string): Promise<string[]> {
        return this.akitaAuthService.auth.fetchSignInMethodsForEmail(email);
    }

    /**
     * Convert a JSON Web Token sent from firebase to an AuthUser
     * @param {string} jwt: JSON Web Token to be converted
     */
    private static parseJwt(jwt: string): AuthUser {
        const base64Url: string = jwt.split('.')[1]!;
        const base64: string = base64Url.replace('-', '+').replace('_', '/');
        return JSON.parse(window.atob(base64));
    }
}
