<ng-container *ngIf="!noteEditMode">
    <ng-container *ngIf="employee.note">

        <!-- THE NOTE ITSELF -->
        <div class="employee-note respect-newlines">
            <ng-container *appIfPro>
                {{ employee.note }}
            </ng-container>
            <ng-container *appIfNotPro>
                {{ employee.note | slice:0:10 }}...
            </ng-container>
        </div>

        <!-- EDIT / SHOW NOTE BUTTON -->
        <ui-button-text color="primary"
                        (click)="editNote()">
            <div class="d-flex align-items-center">
                <ng-container *appIfPro>
                    {{ 'modal.team.edit-note' | translate }}
                </ng-container>
                <ng-container *appIfNotPro>
                    {{ 'modal.team.show-note' | translate }}
                </ng-container>
                <app-upgrade-arrow></app-upgrade-arrow>
            </div>
        </ui-button-text>
    </ng-container>

    <!-- ADD NOTE BUTTON (IF NO NOTE) -->
    <div class="add-note-btn-container"
         *ngIf="!employee.note">
        <ui-button-standard wide
                            color="primary"
                            (click)="editNote()"
                            [appProLabel]="Package.PRO">
            {{ 'shared.add-note' | translate }}
        </ui-button-standard>
    </div>
</ng-container>

<!-- EDIT NOTE MODE -->
<div *ngIf="noteEditMode"
     class="edit-note">

    <!-- NOTE TEXT AREA -->
    <textarea #noteArea
              [placeholder]="'modal.team.add-note-placeholder' | translate"
              (blur)="saveNote($event.target.value)">{{ employee.note }}</textarea>

    <!-- DONE BUTTON -->
    <ui-button-text color="primary"
                    (click)="saveNote(noteArea.value); noteEditMode = false">
        {{ 'shared.done' | translate }}
    </ui-button-text>
</div>