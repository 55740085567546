import { Pipe, PipeTransform } from '@angular/core';
import { capitalize } from '@angular-devkit/core/src/utils/strings';

@Pipe({
    name: 'toTitleCase',
})
export class ToTitleCasePipe implements PipeTransform {

    /**
     * Capitalizes every word in the given string
     * @param {string} value, string to be capitalized
     * @return {string} capitalized string
     */
    public transform(value: string): string {
        if (value) {
            return value.split(' ').map(capitalize).join(' ');
        }
        return value;
    }

}
