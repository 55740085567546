import { DatePipe } from '@angular/common';
import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'date',
})
export class CustomDatePipe implements PipeTransform {

    constructor(@Inject(LOCALE_ID) private locale: string) { }

    public transform(value: Date | string | number | null | undefined, format?: string, timezone?: string, locale?: string): string {
        return new DatePipe(this.locale).transform(value, format, timezone, locale) || '';
    }
}
