import { Holiday } from './Holiday';
import { Weekday } from './Weekday';
interface BaseSalarySupplement {
    id: string;
    name: string;
    amount: number;
    /**
     * Minutes since midnight
     */
    start: number;
    /**
     * Minutes since midnight (max. 24 hours = 1440 minutes)
     */
    end: number;
}

export interface WeekdaySalarySupplement extends BaseSalarySupplement {
    /**
     * Object mapping each weekday to whether or not the supplement should be added on the given weekday
     */
    weekdays: {
        [key in Weekday]: boolean;
    };
}

export interface HolidaySalarySupplement extends BaseSalarySupplement {
    /**
     * Object mapping each holiday to whether or not the supplement should be added on the given holiday
     */
    holidays: {
        [key in Holiday]: boolean;
    };
}

export type SalarySupplement = WeekdaySalarySupplement | HolidaySalarySupplement;

export function isWeekdaySalarySupplement(supplement: SalarySupplement): supplement is WeekdaySalarySupplement {
    const castedSupplement = supplement as WeekdaySalarySupplement;
    return !!castedSupplement.weekdays;
}

export function isHolidaySalarySupplement(supplement: SalarySupplement): supplement is HolidaySalarySupplement {
    const castedSupplement = supplement as HolidaySalarySupplement;
    return !!castedSupplement.holidays;
}
