import { Injectable, NgZone } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { SnackbarComponent } from './snackbar/snackbar.component';

export enum SnackbarColor {
    primary = 'primary',
    warn = 'warn',
    success = 'success',
    info = 'info',
}

export interface Translation {
    translationKey: string;
    translationParams?: {
        [param: string]: string | number;
    };
}

@Injectable({
    providedIn: 'root',
})
export class SnackbarService {

    constructor(
        private snackBar: MatSnackBar,
        private zone: NgZone,
        private translateService: TranslateService,
    ) { }

    /**
     * Displays a notification message with the message and color input.
     */
    public async displaySnack(message: string | Translation, color: SnackbarColor, duration?: number): Promise<void> {
        let msg: string;
        if (typeof message === 'string') {
            msg = message;
        } else {
            msg = await this.translateService.get(message.translationKey, message.translationParams).toPromise();
        }

        this.zone.run(() =>
            this.snackBar.openFromComponent(SnackbarComponent, {
                panelClass: ['snackbar-' + color],
                duration: duration || 3000,
                horizontalPosition: 'center',
                verticalPosition: 'bottom',
                data: msg,
            }));
    }
}
