import { Injector, NgZone } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import * as _ from 'lodash';
import { combineLatest, Subscription, timer } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { Employee } from '../../../../../../../../../core/model';
import { DataloenEmployee } from '../../../../../../../../../core/model/Integrations/Dataloen';
import { EmployeeMapping, SalaryIntegration, SalaryIntegrationSettings } from '../../../../../../../../../core/model/Integrations/Shared';
import { EmployeeService } from '../../../../../../../../../core/services';
import { CloudFunctionsService } from '../../../../../../../../../core/services/cloud-functions.service';
import { IntegrationSettingsService } from '../../../../../../../../../core/services/Settings/IntegrationSettings/Firestore/integration-settings.service';
import { IntegrationModalComponent } from '../integration-modal/integration-modal.component';
import { DataloenIntegrationMatchingModalComponent, DataloenIntegrationMatchingModalData } from './dataloen-integration-matching-modal/dataloen-integration-matching-modal.component';

export function mapDataloenEmployees(injector: Injector, modalRef: MatDialogRef<IntegrationModalComponent>): Subscription {
    const employeeService = injector.get(EmployeeService);
    const cloudFunctionsService = injector.get(CloudFunctionsService);
    const integrationSettingsService = injector.get(IntegrationSettingsService);

    const matchEmployees = (dataloenEmployee: DataloenEmployee, relionEmployee: Employee) => {
        const relionNames = (relionEmployee.firstname + ' ' + relionEmployee.lastname).split(' ');
        const dataloenNames = dataloenEmployee.name.split(' ');
        const relionMatchesDataloen = relionNames.every((relionName: string) => dataloenNames.includes(relionName));
        const dataloenMatchesRelion = dataloenNames.every((dataloenName: string) => relionNames.includes(dataloenName));

        return relionMatchesDataloen
            || dataloenMatchesRelion;
    };
    const matchingResult$ = combineLatest(
        employeeService.getEmployees(),
        cloudFunctionsService.listDataloenEmployees(),
        integrationSettingsService.loadSetting(SalaryIntegration.DATALOEN),
    ).pipe(
        take(1),
        map(([relionEmployees, dataloenEmployees, integrationSettings]: [Employee[], DataloenEmployee[], SalaryIntegrationSettings]) => {
            const unmappedDataloenEmployees = [...dataloenEmployees];
            const unmappedRelionEmployees: Employee[] = [...relionEmployees];
            const mappedEmployees: Map<string, string> = relionEmployees.reduce(
                (employeeMap: Map<string, string>, relionEmployee: Employee) => {
                    const dataloenMatches = unmappedDataloenEmployees.filter((dataloenEmployee: DataloenEmployee) =>
                        matchEmployees(dataloenEmployee, relionEmployee));

                    const relionMatches = _.flatten(dataloenMatches.map((demp: DataloenEmployee) =>
                        unmappedRelionEmployees.filter((remp: Employee) =>
                            matchEmployees(demp, remp))));
                    if (dataloenMatches.length === 1 && relionMatches.length === 1) {
                        const matchedDataloenEmployee =
                            unmappedDataloenEmployees.splice(unmappedDataloenEmployees.indexOf(dataloenMatches[0]!), 1)[0]!;
                        const matchedRelionEmployee =
                            unmappedRelionEmployees.splice(unmappedRelionEmployees.indexOf(relionMatches[0]!), 1)[0]!;

                        return employeeMap.set(
                            matchedRelionEmployee.id,
                            matchedDataloenEmployee.employeeId);
                    }
                    return employeeMap;
                }, new Map());
            return { mappedEmployees, unmappedRelionEmployees, unmappedDataloenEmployees, existingMap: integrationSettings.employeeMap };
        }));

    return combineLatest([timer(3500), matchingResult$]).subscribe({
        next: ([_, matchingResult]: [number, {
            mappedEmployees: Map<string, string>;
            unmappedRelionEmployees: Employee[];
            unmappedDataloenEmployees: DataloenEmployee[];
            existingMap: EmployeeMapping[] | undefined;
        }]) => {
            if (matchingResult.unmappedRelionEmployees.length === 0) {
                const employeeMap: EmployeeMapping[] = [...matchingResult.mappedEmployees.entries()]
                    .map((mapping: [string, string]) => ({ [mapping[0]]: mapping[1] }));
                integrationSettingsService.saveSetting(SalaryIntegration.DATALOEN, { employeeMap }).pipe(take(1)).subscribe();
            } else {
                // Needs to run in the zone, to update the initial view of the modal correctly upon opening
                injector.get(NgZone).run(() =>
                    injector.get(MatDialog).open<DataloenIntegrationMatchingModalComponent, DataloenIntegrationMatchingModalData>(
                        DataloenIntegrationMatchingModalComponent,
                        { data: matchingResult, width: '808px', maxHeight: '80vh' },
                    ));
            }
            modalRef.close();
        },
    });
}
