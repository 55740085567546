import { Department } from './Department/Department.model';
import { Role } from './Role/Role.model';

/**
 * This is the interface for an Employee, reflecting the structure from the database.
 */
export interface Employee {
    id: string;
    firstname: string;
    lastname: string;
    CPR: string;
    street: string;
    city: string;
    zip: string;
    phone: string;
    email: string;
    imageURL: string;
    isDeleted: boolean;
    roles: Role[];
    primaryDepartment: Department | null;
    departments: Department[];
    hourlyWage: number | null;
    note: string;
}

/**  Compares two employees. Sorts them by firstname and then lastname
 * @param employee1 - The first employee to compare
 * @param employee2 - The second employee to compare
 */
export function sortEmployeesByName(employee1: Employee, employee2: Employee): number {
    return employee1.firstname.trim().localeCompare(employee2.firstname.trim())
        || employee1.lastname.trim().localeCompare(employee2.lastname.trim());
}
