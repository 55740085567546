import { Pipe, PipeTransform } from '@angular/core';
import { Shift } from '../../../core/model';


export enum ShiftStatus {
    CHECKED_IN = 'checkedIn',
    CHECKED_OUT = 'checkedOut',
    WORKING = 'working',
    NOT_WORKING = 'notWorking',
}

@Pipe({
    name: 'shiftStatus',
})
export class ShiftStatusPipe implements PipeTransform {

    public transform(shift: Shift, punchclockEnabled: boolean): ShiftStatus {
        if (punchclockEnabled && !!shift.checkout) return ShiftStatus.CHECKED_OUT;
        if (punchclockEnabled && !!shift.checkin) return ShiftStatus.CHECKED_IN;
        if (!punchclockEnabled && shift.start.getTime() < Date.now() && shift.end.getTime() > Date.now()) return ShiftStatus.WORKING;
        return ShiftStatus.NOT_WORKING;
    }
}
