import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivationEnd, Event, Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { Routes, routes } from '../../../app-settings';
import { AuthService, AuthUser } from '../../../core/services';

@Component({
    selector: 'app-bottom-nav',
    templateUrl: './bottom-nav.component.html',
    styleUrls: ['./bottom-nav.component.scss'],
})
export class BottomNavComponent implements OnInit, OnDestroy {
    public routes: Routes = routes;
    public authUser$: Observable<AuthUser | null>;
    public active: number;
    private pathSubscription: Subscription;
    private activeShiftSubscription: Subscription;

    constructor(
        private router: Router,
        private authService: AuthService,
    ) { }

    public ngOnInit(): void {
        this.authUser$ = this.authService.getUser();

        this.pathSubscription = this.router.events.pipe(
            filter((event: Event) => event instanceof ActivationEnd),
            map(() => this.router.routerState.snapshot.url.substring(1)),
        ).subscribe({ next: this.setActivePath.bind(this) });
    }

    public ngOnDestroy(): void {
        this.pathSubscription.unsubscribe();
        this.activeShiftSubscription?.unsubscribe();
    }

    private setActivePath(path: string): number | undefined {
        switch (path) {
            case routes.admin.home:
            case routes.employee.home:
                return this.active = 1;
            case routes.admin.schedule:
            case routes.employee.wishes:
                return this.active = 2;
            case routes.admin.salary:
            case routes.employee.calendar:
                return this.active = 3;
            case routes.admin.team:
            case routes.employee.team:
                return this.active = 4;
            case routes.admin.settings:
            case routes.employee.settings:
                return this.active = 5;
        }
    }

}
