import { Component, OnInit } from '@angular/core';
import { ButtonDirective } from '../button/button.directive';

@Component({
    selector: 'ui-button-action',
    templateUrl: './button-action.component.html',
    styleUrls: ['./button-action.component.scss'],
})

export class ButtonActionComponent extends ButtonDirective implements OnInit {
    public ngOnInit(): void {
        if (!this.color) this.color = 'primary';
    }
}
