import { ChangeDetectorRef, Directive, Input, NgZone, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { Package } from '../../../core/model/Freemium/Package';
import { ConfigurationService } from '../../../core/services/Configuration/configuration-service.model';
import { IfPro } from './if-pro';

@Directive({
    selector: '[appIfPro]',
})
export class IfProDirective extends IfPro implements OnInit, OnDestroy {
    @Input() public appIfPro: Package;

    constructor(
        // Reference to the element on which this directive is placed
        templateRef: TemplateRef<HTMLElement>,
        // Reference to the containing view of the element on which this directive is placed
        viewContainer: ViewContainerRef,
        configurationService: ConfigurationService,
        ngZone: NgZone,
        cdf: ChangeDetectorRef,
    ) {
        super(templateRef, viewContainer, configurationService, ngZone, cdf);
    }

    public ngOnInit(): void {
        super.init(this.appIfPro, false);
    }

    public ngOnDestroy(): void {
        super.destroy();
    }
}
