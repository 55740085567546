import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { DateService, MediaService } from '../../../core/services';

@Component({
    selector: 'ui-time-input',
    templateUrl: './time-input.component.html',
    styleUrls: ['./time-input.component.scss'],
})
export class TimeInputComponent implements OnInit, OnDestroy {
    @Input() public punchclock: boolean;
    @Input() public start: Date;
    @Input() public end: Date;
    @Output() public startChange: EventEmitter<Date>;
    @Output() public endChange: EventEmitter<Date>;
    @Input() public bigScreen: boolean;

    @ViewChild('fromHH') public fromHH: ElementRef;
    @ViewChild('fromMM') public fromMM: ElementRef;
    @ViewChild('toHH') public toHH: ElementRef;
    @ViewChild('toMM') public toMM: ElementRef;

    // Small screen version
    @ViewChild('from') public from: ElementRef;
    @ViewChild('to') public to: ElementRef;

    public regex: { minutes: string, hoursFrom: string, hoursTo: string } = {
        minutes: '^[0-9]$|^([0-5][0-9])$',
        hoursFrom: '^[0-9]$|^([0-1][0-9]|2[0-3])$',
        hoursTo: '^[0-9]$|^([0-1][0-9]|2[0-4])$',
    };

    private subscriptions: Subscription[];
    constructor(public dateService: DateService,
        private mediaService: MediaService) {
        this.startChange = new EventEmitter();
        this.endChange = new EventEmitter();
        this.subscriptions = [];
    }

    public ngOnInit(): void {
        if (this.bigScreen === undefined) {
            this.subscriptions.push(
                this.mediaService.observeMediaChanges()
                    .subscribe((big: boolean) => {
                        this.bigScreen = big;
                    }));
        }
    }

    public ngOnDestroy(): void {
        this.subscriptions.forEach((subs: Subscription) => subs.unsubscribe());
    }

    /**
     * Sends focus to the next input field if the user has entered two characters
     * @param newValue The string of the new value (a zero, one or two character number)
     * @param tabTarget Optional target html element to jump to if the user input is done
     */
    public input(newValue: string, type: 'hours' | 'minutes', tabTarget?: HTMLInputElement): void {
        // If nothing is typed do nothing
        if (newValue.length === 0) return;

        // If one char is typed, act based on whether it is hour or minute input
        if (newValue.length === 1) {
            // If hours is l.e. 2 input might not be done
            if (type === 'hours' && Number(newValue) <= 2) return;
            // If minutes is l.e. 5 input might not be done
            if (type === 'minutes' && Number(newValue) <= 5) return;
        }

        // Otherwise jump to tab target or blur field
        if (tabTarget) {
            tabTarget.focus();
            tabTarget.select();
        } else {
            (<HTMLInputElement> document.activeElement).blur();
        }
    }

    /**
     * Handles dates and emits a change event
     * @param input The input element
     */
    public save(): void {
        if (!this.start) {
            this.dateService.getDateObservable()
                .pipe(
                    take(1),
                    map((date: Date) => {
                        this.start = new Date(date.toDateString());
                        this.end = new Date(date.toDateString());
                        this.setTime();
                    })).subscribe();
        } else {
            this.setTime();
        }
    }

    /**
     * Sets the time of the shift and emits the event
     */
    public setTime(): void {
        // Set the shift times
        const [hoursFrom, minutesFrom]: [string, string] = this.bigScreen
            ? [this.fromHH.nativeElement.value, this.fromMM.nativeElement.value]
            : this.from.nativeElement.value.split(':');

        const [hoursTo, minutesTo]: [string, string] = this.bigScreen
            ? [this.toHH.nativeElement.value, this.toMM.nativeElement.value]
            : this.to.nativeElement.value.split(':');

        if (hoursFrom) {
            const hours = Number(hoursFrom);
            const minutes = minutesFrom ? Number(minutesFrom) : 0;
            this.start.setHours(hours, minutes, 0, 0);
        }

        if (hoursTo) {
            const hours = Number(hoursTo);
            const minutes = minutesTo ? Number(minutesTo) : 0;
            this.end.setHours(hours, minutes, 0, 0);
        }

        this.checkIfEndIsBeforeStart();
        this.startChange.emit(new Date(this.start.toString()));
        this.endChange.emit(new Date(this.end.toString()));
    }

    /**
     * Check if the HH:mm of the end is less than of the start
     */
    private checkIfEndIsBeforeStart(): void {
        const endIsBeforeStart: boolean = this.end.getHours() < this.start.getHours() ||
            this.end.getHours() === this.start.getHours() &&
            this.end.getMinutes() < this.start.getMinutes() ||
            this.end.getHours() === this.start.getHours() &&
            this.end.getMinutes() === this.start.getMinutes();
        // Set the end date to the start date (add one day if HH:mm of end is less than of start)
        this.end.setFullYear(this.start.getFullYear());
        this.end.setMonth(this.start.getMonth());
        this.end.setDate(this.start.getDate() + Number(endIsBeforeStart));
    }
}
