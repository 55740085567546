import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { DepartmentStore, DepartmentState } from './department.store';

@Injectable({ providedIn: 'root' })
export class DepartmentQuery extends QueryEntity<DepartmentState> {

  constructor(protected store: DepartmentStore) {
    super(store);
  }

}
