import { Pipe, PipeTransform } from '@angular/core';
import { DateService } from '../../core/services';

@Pipe({ name: 'decimalToTime' })
export class DecimalToTimePipe implements PipeTransform {

    /**
     * Transforms a decimal time value into a formatted string (e.g. 1.5 to 1:30 or 22.25 to 22:15)
     * @param value - The decimal time value to format.
     */
    public transform(value: number): string {
        return DateService.getFormattedTimeStringFromDecimals(value);
    }
}
