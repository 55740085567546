<div class="department-employee-item">
    <div class="pic-name-container">
        <img class="pic"
             [lazyLoad]="employee.imageURL">
        <div class="name">
            {{employee.firstname + ' ' + employee.lastname}}
        </div>
    </div>
    <ng-select [items]="roles$ | async"
               #select
               bindLabel="name"
               [hideSelected]="true"
               [multiple]="true"
               [ngModel]="selectedDepartmentRoles$ | async"
               appendTo="body"
               notFoundText="{{ 'admin.department.no-roles-found' | translate | capitalize }}"
               (blur)="selectedRoles.emit(newSelection)"
               (change)="newSelection = $event; select.blur()"
               class="select"></ng-select>
    <ui-button-text color="warn"
                    (click)="removeEmployeeClicked.emit()">
        {{'shared.remove' | translate | capitalize}}</ui-button-text>
</div>