import { animate, keyframes, style, transition, trigger } from '@angular/animations';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { take } from 'rxjs/operators';
import { AuthService, AuthUser } from '../../../../../core/services';

@Component({
    selector: 'app-invite-modal',
    templateUrl: './invite-modal.component.html',
    styleUrls: ['./invite-modal.component.scss'],
    animations: [
        trigger('pulse', [
            transition('* => *', [
                animate('300ms', keyframes([
                    style({ boxShadow: '0 0 1px 3px rgb(75, 228, 98)' }),
                    style({ boxShadow: '0 0 1px 6px rgb(75, 228, 98)' }),
                    style({ boxShadow: '0 0 1px 1px rgb(75, 228, 98)' }),
                    style({ boxShadow: '0 0 1px 3px rgb(75, 228, 98)' }),
                ])),
            ]),
        ]),
    ],
})
export class InviteModalComponent implements OnInit {
    @ViewChild('modalInvite') public inviteRef: ElementRef;

    public modalURL: string;
    public isCopied: boolean;
    public isAnimating: boolean;

    constructor(
        public authService: AuthService,
        private modalRef: MatDialogRef<InviteModalComponent>,
    ) { }

    /**
     * OnInit
     */
    public ngOnInit(): void {
        this.authService.getUser().pipe(take(1)).subscribe((user: AuthUser) => {
            this.modalURL = `https://app.relion.dk/signup?companyID=${ user.companyID }`;
        });
    }

    /**
     * Copies the invite link to the user's clipboard and animates the input border.
     */
    public copyInviteLink(): void {
        this.isCopied = true;
        this.isAnimating = !this.isAnimating;

        const invite: ElementRef = this.inviteRef;
        const fsize: string = invite.nativeElement.style.fontSize;

        invite.nativeElement.setAttribute('contenteditable', 'true');
        invite.nativeElement.setAttribute('readonly', 'false');
        invite.nativeElement.style.fontSize = '16pt';
        invite.nativeElement.focus();
        invite.nativeElement.style.fontSize = fsize;
        invite.nativeElement.setSelectionRange(0, invite.nativeElement.value.length);
        document.execCommand('copy');
        invite.nativeElement.setSelectionRange(0, 0);
        invite.nativeElement.blur();
        invite.nativeElement.setAttribute('contenteditable', 'false');
        invite.nativeElement.setAttribute('readonly', 'true');

        setTimeout(() => this.closeModal(), 1000);
    }

    /**
     * closes the modal window
     */
    public closeModal(): void {
        this.modalRef.close();
    }
}
