import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { MediaService } from '../../../core/services';

@Component({
    selector: 'ui-search-field',
    templateUrl: './searchfield.component.html',
    styleUrls: ['./searchfield.component.scss'],
})
export class SearchfieldComponent<T> implements OnInit {
    @ViewChild('searchInput') public searchInput: ElementRef<HTMLInputElement>;
    @Input() public placeholderText: string;
    @Input() public searchStringBuilder: (item: T) => string;
    @Output() public filteredList: EventEmitter<T[]>;
    @Input() public set searchList(list: T[]) {
        this._searchList = list;
        this.filterContent(this.searchInput ? this.searchInput.nativeElement.value : '');
    }

    public bigScreen: boolean;
    public showSearchField: boolean;
    private _searchList: T[] | undefined;
    private subscriptions: Subscription[];



    constructor(
        private mediaService: MediaService,
    ) {
        this.subscriptions = [];
        this.showSearchField = false;
        this.filteredList = new EventEmitter();
    }
    /**
     * Takes any given list and filters the search content
     * @param searchList The list we are filterering
     * @param searchKeywords what the user types in the searchfield
     */
    public filterSearchContent(searchList: T[] | undefined, searchKeywords: string[], searchStringBuilder: (item: T) => string): T[] {
        const result: T[] = [];
        if (!searchList) return result;

        for (const searchItem of searchList) {
            let numberOfMatches: number = 0;

            const searchString: string = searchStringBuilder(searchItem);
            for (const keyword of searchKeywords) {
                keyword.toLocaleLowerCase();
                if (searchString.toLowerCase().indexOf(keyword) !== -1) {
                    numberOfMatches += 1;

                }
            }
            // We need exact number of matches to match number of keywords
            if (numberOfMatches === searchKeywords.length) {
                result.push(searchItem);
            }
        }
        return result;
    }

    public ngOnInit(): void {
        this.subscriptions.push(
            this.mediaService.observeMediaChanges().subscribe((big: boolean) => this.bigScreen = big));
    }

    /**
     * Filter results according to search keywords
     * @param searchString - the input event
     */
    public filterContent(inputString: string): void {
        const searchString: string = inputString.toLowerCase();
        const searchKeywords: string[] = searchString.split(' ');

        const result: T[] = this.filterSearchContent(this._searchList, searchKeywords, this.searchStringBuilder);
        this.filteredList.emit(result);

    }
    /**
     * Flip the state of showSearchField
     */
    public toggleSearchField(): void {
        this.showSearchField = !this.showSearchField;
        this.filteredList.emit(this._searchList);
        this.focusInput();
    }

    public focusInput(): void {
        setTimeout(() => this.searchInput.nativeElement.focus(), 0);
    }

}
