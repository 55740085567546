import { Component, Input } from '@angular/core';
import { Shift, ShiftForSale } from '../../../../core/model';
import { ShiftForSaleService } from '../../../../core/services';

@Component({
    selector: 'app-shift-detail-sell-shift-action',
    templateUrl: './shift-detail-sell-shift-action.component.html',
    styleUrls: ['./shift-detail-sell-shift-action.component.scss'],
})
export class ShiftDetailSellShiftActionComponent {

    @Input() public shift: Shift;
    @Input() public shiftForSale: ShiftForSale | null;

    constructor(private shiftForSaleService: ShiftForSaleService) { }

    public sellShift(): void {
        this.shiftForSaleService.createShiftForSale(this.shift);
    }

    public async cancelSellShift(): Promise<void> {
        if (!this.shiftForSale) return;
        this.shiftForSaleService.revokeShiftForSale(this.shiftForSale);
    }
}
