<div class="future-shifts"
     *ngIf="futureShifts$ | async as futureShifts; else loading">
    <!-- PREVIOUS SHIFTS BUTTON -->
    <div class="previous-shifts-btn"
         *ngIf="isMobile$ | async"
         [routerLink]="['/' + routes.employee.shifts]">
        <div class="text">
            {{ 'employee.shift-list.previous-shifts' | translate }}
        </div>
        <div class="arrow">
            <i class="far fa-chevron-right"></i>
        </div>
    </div>

    <!-- FUTURE SHIFTS LIST -->
    <app-full-shift-card *ngFor="let shift of futureShifts; trackBy: trackFutureShifts"
                         class="w-100 shift-card"
                         [shift]="shift"
                         (click)="openShiftDetails(shift)">
    </app-full-shift-card>
</div>

<!-- NO UPCOMING SHIFTS MESSAGE -->
<div class="no-shifts mx-auto my-5"
     *ngIf="(futureShifts$ | async)?.length === 0">
    <p class="text-center text-faded font-weight-500">
        {{'widget.you-dont-have-any-upcoming-shifts' | translate | capitalize}}</p>
</div>

<!-- LOADING SCREEN -->
<ng-template #loading>
    <div class="mb-3">
        <i>{{'shared.loading' | translate | capitalize}}</i>
        <i class="fa fa-spinner fa-spin"></i>
    </div>
</ng-template>