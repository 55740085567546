import { ChangeDetectorRef, Component, Inject, Injector, OnDestroy, OnInit, TrackByFunction } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, combineLatest, Observable, Subject, Subscription } from 'rxjs';
import { filter, first, map, pluck, shareReplay, switchMap, take, takeWhile, tap } from 'rxjs/operators';
import { scaleInOut } from '../../../../../../../assets/animations/animations';
import { routes } from '../../../../../../app-settings';
import { Day, Department, Employee, Role, Shift, ShiftBreak, sortShiftsByRoleName, sortShiftsByTime, Wish } from '../../../../../../core/model';
import { Package } from '../../../../../../core/model/Freemium/Package';
import { GroupShiftsBy, UserSettings } from '../../../../../../core/model/UserSettings';
import { CompanySetting, CompanySettingsService, DateService, EmployeeService, MediaService, SessionStorageService, ShiftService, SupportedStorageKeys, UserActivity, UserActivityService, UserSettingsService } from '../../../../../../core/services';
import { AnimationService } from '../../../../../../core/services/animation/animation.service';
import { DayService } from '../../../../../../core/services/day.service';
import { DepartmentService } from '../../../../../../core/services/Department/DepartmentService.model';
import { isSameDate } from '../../../../../../shared/utilities/DateUtils/isSameDate';
import { getHoursThisWeek, getRoles, getWishes } from '../../../../../../shared/utilities/DayViewShiftUtils';
import { canPasteDay$, copyShifts, createNewShift, pasteDay } from '../../../../../../shared/utilities/ShiftUtils';
import { getShiftDuration } from '../../../../../../shared/utilities/ShiftUtils/durationUtils';

@Component({
    selector: 'app-day-view',
    templateUrl: './day-view.component.html',
    styleUrls: ['./day-view.component.scss'],
    animations: [scaleInOut],
})

export class DayViewComponent implements OnInit, OnDestroy {
    public Packages: typeof Package = Package;
    public canPasteDay$: Observable<boolean>;
    public hours$: Observable<number>;
    public roles$: Observable<Role[]>;
    public shifts$: Observable<Shift[]>;
    public wishes$: Observable<Wish[]>;
    public hoursThisWeek$: Observable<Map<string, number>>;
    public showCreateShiftTip$: Observable<boolean>;
    public dailyNoteExists: boolean;
    public day$: Observable<Day>;
    public shifts: Shift[];
    public shiftSubscription?: Subscription;
    private subscriptions: Subscription[] = new Array<Subscription>();

    constructor(
        public animationService: AnimationService,
        public dateService: DateService,
        public translateService: TranslateService,
        private cdr: ChangeDetectorRef,
        private employeeService: EmployeeService,
        private mediaService: MediaService,
        private router: Router,
        private shiftService: ShiftService,
        private departmentService: DepartmentService,
        private userSettingsService: UserSettingsService,
        private sessionStorageService: SessionStorageService,
        private userActivityService: UserActivityService,
        private dayService: DayService,
        private companySettingsService: CompanySettingsService,
        private injector: Injector,
        @Inject(MAT_DIALOG_DATA) private startDate?: Date,
    ) { }

    public async saveDailyNote(day$: Observable<Day>, newNote: string): Promise<void> {
        const day = await day$.pipe(take(1)).toPromise();
        // If the note hasn't changed, return
        if (newNote === day.note?.message) return;
        const department = await this.departmentService.getActiveDepartment().pipe(take(1)).toPromise();
        if (newNote) {
            // If there's a note, set it with yourself as sender
            const sender: Employee = await this.employeeService.getAuthenticatedEmployee().pipe(take(1)).toPromise();
            day.note = {
                message: newNote,
                sender,
                time: new Date(),
            };
        } else {
            // Otherwise, delete the note
            delete day.note;
        }

        // Update the day
        this.dayService.updateDay(day, department);
    }

    public ngOnInit(): void {

        const shiftsSubject: Subject<Observable<Shift[]> | undefined> = new BehaviorSubject(undefined);
        const shiftsObservable$: Observable<Observable<Shift[]> | undefined> =
            shiftsSubject.asObservable().pipe(filter((obs: Observable<Shift[]> | undefined) => obs !== undefined));

        if (this.startDate) this.dateService.changeDate(this.startDate);

        this.day$ =
            combineLatest([this.dateService.getDateObservable(), this.departmentService.getActiveDepartment()]).pipe(
                switchMap(([date, department]: [Date, Department]) => this.dayService.getDay(date, department)),
                shareReplay(1),
            );
        this.subscriptions.push(this.day$.subscribe((day: Day) => {
            this.dailyNoteExists = !!day.note;
        }));
        this.subscriptions.push(this.dateService.getDateObservable().subscribe((date: Date) => {
            // Update the day (for notes), whenever the date changes
            this.shifts$ = combineLatest([
                this.userSettingsService.loadSetting(UserSettings.GROUP_SHIFTS_BY),
                this.getShifts(date),
            ]).pipe(
                map(([groupBy, shifts]: [GroupShiftsBy, Shift[]]) => {
                    const sortBy = (a: Shift, b: Shift) => groupBy === GroupShiftsBy.ROLE
                        ? sortShiftsByRoleName(a, b) || sortShiftsByTime(a, b)
                        : sortShiftsByTime(a, b) || sortShiftsByRoleName(a, b);
                    return shifts.sort(sortBy);
                }),
                shareReplay(1),
            );
            shiftsSubject.next(this.shifts$);
        }));

        this.shifts = [];
        this.setupShiftsSubscription(shiftsSubject);

        this.hours$ = combineLatest([
            shiftsObservable$.pipe(switchMap((obs: Observable<Shift[]>) => obs)),
            this.companySettingsService.loadSetting(CompanySetting.SHIFT_BREAK),
        ]).pipe(
            map(([shifts, shiftBreak]: [Shift[], ShiftBreak | null]) =>
                shifts.reduce((acc: number, shift: Shift) => acc + getShiftDuration(shift, shiftBreak), 0)),
            shareReplay(1),
        );

        this.roles$ = getRoles(this.injector);

        this.wishes$ = getWishes(this.dateService.getDateObservable(), this.injector);

        this.hoursThisWeek$ = getHoursThisWeek(this.injector, this.day$.pipe(pluck('date')));

        this.checkCanPaste();

        this.mediaService.observeMediaChanges().pipe(
            first((big: boolean) => !big),
        ).subscribe({
            next: (): Promise<boolean> => this.router.navigateByUrl(routes.admin.schedule),
        });

        this.setupTipObservables();
    }

    /**
     * Returns an observable with a list of shifts from the given date.
     * @param date The date we are retrieving shifts from.
     */
    public getShifts(date: Date): Observable<Shift[]> {
        return this.departmentService.getActiveDepartment().pipe(
            switchMap((activeDepartment: Department) => this.shiftService.getShifts({
                startDateFrom: DateService.midnight(date),
                startDateTo: DateService.midnight(date, 1),
                department: activeDepartment,
            })),
            tap(() => setTimeout(() => MediaService.detectChanges(this.cdr), 0)),
        );
    }

    /**
     * This method loads the necessary data from user settings then create and save a new shift
     */
    public newShift(): void {
        createNewShift(this.injector, this.roles$);
    }

    /**
     * Copies a day for future insertion
     */
    public async copyDay(): Promise<void> {
        copyShifts(
            await this.shifts$.pipe(take(1)).toPromise(),
            SupportedStorageKeys.COPY_DAY_SHIFTS,
            this.sessionStorageService,
        );
        this.checkCanPaste();
    }

    /**
     * Pastes the currently copied shifts into the current day chosen in the dayview
     */
    public pasteDay(date: Date): void {
        pasteDay(date, this.injector);
    }

    public track: TrackByFunction<Shift> = (_index: number, shift: Shift) => shift.id;

    /**
     * Terminates all subscriptions when navigating away.
     */
    public ngOnDestroy(): void {
        this.subscriptions.forEach((subs: Subscription) => subs.unsubscribe());
    }

    private checkCanPaste(): void {
        this.canPasteDay$ = canPasteDay$(this.injector);
    }

    private setupTipObservables(): void {
        this.showCreateShiftTip$ = this.userActivityService.load(UserActivity.CREATED_FIRST_SHIFT)
            .pipe(
                takeWhile((createdShift: Date | null) => !createdShift, true),
                map((createdShift: Date | null) => !createdShift),
            );
    }

    /**
     * Setup a listener for new shifts
     */
    private setupShiftsSubscription(shiftsSubject: Subject<Observable<Shift[]> | undefined>): void {
        this.subscriptions.push(
            shiftsSubject.subscribe(((shiftsObservable?: Observable<Shift[]>) => {
                this.shiftSubscription?.unsubscribe();
                this.shiftSubscription = shiftsObservable?.subscribe({
                    next: (newShifts: Shift[]) => {
                        // If no existing shifts, if no new shifts or if the existing and new shifts are on different dates
                        if (!this.shifts[0] ||
                            !newShifts[0] ||
                            !isSameDate(this.shifts[0].start, newShifts[0].start)) {
                            // Just use the (sorted) ones from db
                            return this.shifts = newShifts;
                        }

                        // Otherwise calculate the update
                        this.updateShiftsList(newShifts);
                    },
                });
            })),
        );
    }

    /**
     * Takes a list of new shifts from the database and update the existing list of shifts to reflect the changes from the database
     */
    private updateShiftsList(newShifts: Shift[]): void {
        //  The existing shifts that don't appear in the new shifts should be deleted
        const existingShiftsToDelete = this.shifts.filter(
            (existingShift: Shift) => !newShifts.find(
                (updatedShift: Shift) => updatedShift.id === existingShift.id));

        existingShiftsToDelete.forEach((shiftToDelete: Shift) => {
            const index = this.shifts.findIndex((existingShift: Shift) => shiftToDelete.id === existingShift.id);
            this.shifts.splice(index, 1);
        });

        // Go through the new shifts
        newShifts.forEach((newShift: Shift) => {
            const indexOfNewShift = this.shifts.findIndex(((oldShift: Shift) => oldShift.id === newShift.id));
            // If the new shift was not present in the existing list, add it to the top
            if (indexOfNewShift === -1) this.shifts = [newShift].concat(this.shifts);

            // Otherwise update the shift
            else this.shifts[indexOfNewShift] = newShift;
        });
    }
}
