import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { Shift, ShiftBreak } from '../../core/model';
import { getShiftDuration } from '../utilities/ShiftUtils/durationUtils';

@Pipe({
    name: 'sumShiftDuration',
})
export class SumShiftDurationPipe implements PipeTransform {

    constructor(private translateService: TranslateService) { }

    /**
     * Get a human readable combined duration of an list of shifts
     * @param shifts - The shifts to combine the duration of
     * @param lang - The language to output in (only used as trigger for the transform function)
     */
    public async transform(shifts: Shift[] | null, shiftBreak: ShiftBreak | null, _lang: string): Promise<string> {
        // Return dash if no shifts
        if (!shifts || shifts.length === 0) return '-';

        // Init translation keys
        const minuteTK: string = 'time.minute';
        const minutesTK: string = 'time.minutes';
        const hourTK: string = 'time.hour';
        const hoursTK: string = 'time.hours';
        const andTK: string = 'shared.and';

        // Get the translations
        const translation: {
            minuteTK: string,
            minutesTK: string,
            hourTK: string,
            hoursTK: string,
            andTK: string,
        } = await this.translateService.get([minuteTK, minutesTK, hourTK, hoursTK, andTK]).pipe(take(1)).toPromise();

        // Get the combined duration of the shifts
        const hoursWithDecimal: number =
            shifts.reduce((acc: number, shift: Shift) => acc += getShiftDuration(shift, shiftBreak), 0);

        // Get the minute count (0-59) and the total hours (0+) of the duration
        const hours: number = Math.floor(hoursWithDecimal);
        const minutes: number = Math.round((hoursWithDecimal % 1) * 60);

        // Handle plurality
        const minuteString: string = minutes + ' ' + (minutes === 1 ? translation[minuteTK] : translation[minutesTK]);
        const hourString: string = hours + ' ' + (hours === 1 ? translation[hourTK] : translation[hoursTK]);

        if (minutes === 0 || hours === 0) {
            // If one of the counts is zero, return the other
            return minutes === 0 ? hourString : minuteString;
        } else {
            // Otherwise return the combined string
            return hourString + ' ' + translation[andTK] + ' ' + minuteString;
        }
    }
}
