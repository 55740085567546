import { Injector } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { Duration } from 'moment';
import { combineLatest, Observable, of } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';
import { Employee, isValidShift, NewShift, Role, Shift, ShiftBreak } from '../../core/model';
import { Department } from '../../core/model/Department/Department.model';
import { RoleImpl } from '../../core/model/Role/RoleImpl';
import { SalarySupplement } from '../../core/model/SalarySupplement.model';
import { UserSettings } from '../../core/model/UserSettings';
import { CompanySetting, CompanySettingsService, DateService, EmployeeService, SessionStorageService, ShiftService, SupportedStorageKeys, UserActivity, UserActivityService, UserSettingsService } from '../../core/services';
import { DepartmentService } from '../../core/services/Department/DepartmentService.model';
import { ModalService } from '../../core/services/ModalService.model';
import { SalarySupplementService } from '../../core/services/salary-supplement.service';
import { SalaryService } from '../../core/services/salary.service';
import { getShiftDuration } from './ShiftUtils/durationUtils';
import { getCurrentLang } from './TranslateUtility';

export function copyShifts(
    shifts: Shift[],
    key: SupportedStorageKeys,
    sessionStorageService: SessionStorageService,
): void {
    const copies: Omit<Shift, 'id'>[] = [];
    shifts.forEach((shift: Shift) => {
        const copy: Omit<Shift, 'id'> = {
            role: new RoleImpl(shift.role.id, shift.role.name, shift.role.department, shift.role.color),
            start: shift.start,
            end: shift.end,
            isForSale: false,
            employee: shift.employee,
            approved: false,
            released: false,
            exportLogs: [],
        };
        if (shift.comment) copy.comment = shift.comment;
        copies.push(copy);
    });
    sessionStorageService.set(key, copies);
}

export function pasteDay(date: Date, injector: Injector): void {
    // Get the shifts from localstorage
    const shifts: Omit<Shift, 'id'>[] = injector.get(SessionStorageService).get(SupportedStorageKeys.COPY_DAY_SHIFTS) || [];

    // Convert them to shifts on the supplied date
    shifts.forEach((shift: Shift) => {
        const pastMidnight: boolean = shift.end.getDate() !== shift.start.getDate();
        shift.role = new RoleImpl(shift.role.id, shift.role.name, shift.role.department, shift.role.color);
        shift.start = DateService.getSameTimeOnDifferentDay(date, shift.start);
        shift.end = DateService.getSameTimeOnDifferentDay(pastMidnight ? DateService.getTheDayAfter(date) : date, shift.end);
    });

    // Create the shifts and log activity
    injector.get(ShiftService).createShifts(shifts).pipe(take(1)).subscribe();
    injector.get(UserActivityService).save(UserActivity.COPIED_FIRST_DAY).subscribe();
}

/**
 * Returns an observable of whether the current COPY_DAY_SHIFTS
 * "clipboard" can be pasted in the active department
 */
export function canPasteDay$(injector: Injector): Observable<boolean> {
    // Get the shifts from localstorage
    const shifts: Omit<Shift, 'id'>[] = injector.get(SessionStorageService).get(SupportedStorageKeys.COPY_DAY_SHIFTS) || [];

    // If no shifts, user cannot paste
    if (shifts.length === 0) return of(false);

    // Get the active department
    const activeDepartment$ = injector.get(DepartmentService).getActiveDepartment();

    // Return whether there's a shift with the right department
    return activeDepartment$.pipe(
        map((activeDepartment: Department) =>
            !!shifts.find((shift: Shift) => shift.role.department.id === activeDepartment.id)),
    );
}

export function getFormattedShiftDuration(injector: Injector, ...shifts: (Shift | NewShift)[]): Observable<string> {
    const companySettingsService = injector.get(CompanySettingsService);
    const translateService = injector.get(TranslateService);

    return combineLatest([
        companySettingsService.loadSetting(CompanySetting.SHIFT_BREAK),
        translateService.stream('shared.hour-short'),
        translateService.stream('shared.minute-short'),
    ]).pipe(
        map(([shiftBreak, hoursString, minutesString]: [ShiftBreak | null, string, string]) => {
            const duration: Duration = shifts.reduce(
                (acc: Duration, shift: Shift) => isValidShift(shift)
                    ? acc.add(moment.duration(getShiftDuration(shift, shiftBreak), 'hours'))
                    : acc,
                moment.duration(),
            );

            const hours: number = Math.floor(duration.asHours());
            const minutes: number = Math.round(duration.asMinutes() % 60);
            const display: string = hours + hoursString + ' ' + minutes + minutesString;
            return display.trim();
        }),
    );
}

export function getFormattedSalary(injector: Injector, ...shifts: Shift[]): Observable<string> {
    const translateService = injector.get(TranslateService);
    return combineLatest([
        calculateSalary(injector, ...shifts),
        getCurrentLang(translateService),
    ]).pipe(switchMap(([totalSalary, language]: [number, string]) => {
        const formatOptions: Intl.NumberFormatOptions = { minimumIntegerDigits: 1, minimumFractionDigits: 2, maximumFractionDigits: 2 };
        const amount = totalSalary.toLocaleString(language, formatOptions);
        return translateService.get('freemium.dkk', { amount });
    }));
}

export function calculateSalary(injector: Injector, ...shifts: Shift[]): Observable<number> {
    const companySettingsService = injector.get(CompanySettingsService);
    const salarySupplementService = injector.get(SalarySupplementService);
    const employeeService = injector.get(EmployeeService);

    return combineLatest([
        companySettingsService.loadSetting(CompanySetting.SHIFT_BREAK),
        salarySupplementService.getSalarySupplements(),
        employeeService.getEmployees(),
    ]).pipe(
        map(([shiftBreak, supplements, _]: [ShiftBreak | null, SalarySupplement[], Employee[]]) => {
            const totalSalary: number = shifts.reduce((acc: number, shift: Shift) => {
                const employee = shift.employee;
                if (!employee) return acc;
                return acc + SalaryService.calculateShiftSalary(employee, shift, shiftBreak, supplements);
            }, 0);
            return totalSalary;
        }));
}

export function createNewShift(injector: Injector, roles$: Observable<Role[]>, isMobile?: boolean): void {

    const userSettingsService = injector.get(UserSettingsService);
    const dateService = injector.get(DateService);
    const shiftService = injector.get(ShiftService);
    const userActivityService = injector.get(UserActivityService);
    const modalService = injector.get(ModalService);

    // Load the last used time span from user settings
    userSettingsService.loadSetting(UserSettings.LAST_USED_TIMESPAN).pipe(take(1))
        .subscribe(lastUsedTimeSpan => {
            // Get the current date what we see in the day view
            dateService.getDateObservable().pipe(take(1)).subscribe((date: Date) => {
                const shift: NewShift = {
                    isForSale: false,
                    employee: null,
                    released: false,
                    approved: false,
                    exportLogs: [],
                };
                // Set the last used time span for the new shift
                shift.start = moment(date).hours(lastUsedTimeSpan.startHour).minutes(lastUsedTimeSpan.startMinute).toDate();
                let momentEnd = moment(date).hours(lastUsedTimeSpan.endHour).minutes(lastUsedTimeSpan.endMinute);
                if (lastUsedTimeSpan.endHour < lastUsedTimeSpan.startHour) {
                    momentEnd = momentEnd.add(1, 'days');
                }
                shift.end = momentEnd.toDate();
                // Load the last selected role, validate the shift and save it
                combineLatest([
                    roles$,
                    userSettingsService.loadSetting(UserSettings.LATEST_SELECTED_ROLE),
                ]).pipe(take(1)).toPromise()
                    .then(([roles, latestRoleId]: [Role[], string]) => {
                        const latestRole: Role | undefined = roles.find((role: Role) => role.id === latestRoleId);
                        if (shift !== undefined) {
                            shift.role = latestRole || roles[0]!;
                            if (isValidShift(shift)) {
                                shiftService.createShift(shift)
                                    .pipe(take(1))
                                    .subscribe(createdShift => {
                                        userActivityService.save(UserActivity.CREATED_FIRST_SHIFT).subscribe();
                                        if (isMobile !== undefined && isMobile) {
                                            modalService.openShiftDetailModal(createdShift);
                                        }
                                    });
                            }
                        }
                    });
            });
        });
}
