import { TranslateService } from '@ngx-translate/core';
import * as FileSaver from 'file-saver';
import * as _ from 'lodash';
import * as moment from 'moment';
import { take } from 'rxjs/operators';
import { Employee, Shift, ShiftBreak } from '../../core/model';
import { SalarySupplement } from '../../core/model/SalarySupplement.model';
import { SalaryRange } from '../../core/model/UserSettings';
import { SalarySupplementService } from '../../core/services/salary-supplement.service';
import { SalaryService } from '../../core/services/salary.service';
import { getShiftBreakDuration, getShiftDuration } from './ShiftUtils/durationUtils';


type ShiftWithEmployee = Shift & { employee: Employee };
// "Afdeling","CPR","Navn","Rolle","Kommentar","Start","Slut","Timer","Pause","Timeløn","Tillæg","Løn"
type CSVRow = [string, string, string, string, string, string, string, number, number, number, number, number];

// Function builders
const buildHeaderPrefixer = (headers: string[]) => (csvString: string) => convertRowToCSVString(headers) + '\n' + csvString;
const buildNumberFormatter = (locale: string) => (num: number): string => roundToDecimals(num).toLocaleString(locale);

// Row builders
const buildCSVRows = async (shifts: ShiftWithEmployee[], shiftBreak: ShiftBreak | null, salaryService: SalaryService): Promise<CSVRow[]> =>
    await Promise.all(shifts.map(async (shift: ShiftWithEmployee) => await buildCSVRow(shift, shiftBreak, salaryService)));

const buildCSVRow = async (shift: ShiftWithEmployee, shiftBreak: ShiftBreak | null, salaryService: SalaryService): Promise<CSVRow> => {
    const totalShiftSalary = await salaryService.shiftToSalary(shift, shift.employee).pipe(take(1)).toPromise();
    const row: CSVRow = [
        shift.role.department.name,
        shift.employee.CPR || '',
        formatEmployeeName(shift.employee),
        shift.role.name,
        shift.comment?.message || '',
        moment(shift.start).format('YYYY-MM-DD HH:mm'),
        moment(shift.end).format('YYYY-MM-DD HH:mm'),
        getShiftDuration(shift),
        shiftBreak ? getShiftBreakDuration(shift, shiftBreak) : 0,
        shift.employee.hourlyWage || 0,
        totalShiftSalary - (getShiftDuration(shift, shiftBreak) * (shift.employee.hourlyWage || 0)),
        totalShiftSalary,
    ];
    return row;
};

const shouldRemoveDepartmentBreakSupplement = (shifts: Shift[], shiftBreak: ShiftBreak | null, supplements: SalarySupplement[]):
    [boolean, boolean, boolean] => {
    const shouldRemoveDepartment = shifts.reduce((acc: string[], shift: Shift) => {
        if (!acc.includes(shift.role.department.id)) {
            acc.push(shift.role.department.id);
        }
        return acc;
    }, []).length === 1;
    const shouldRemoveBreak = !shiftBreak;
    return [shouldRemoveDepartment, shouldRemoveBreak, supplements.length === 0];
};

// Formatting
const toTitleCase = (lowercase: string) => lowercase[0] ? lowercase[0].toUpperCase() + lowercase.slice(1) : lowercase;

const roundToDecimals = (num: number) => Number(num.toFixed(2));

const formatEmployeeName = (employee: Employee) => employee.firstname + ' ' + employee.lastname;
const formatRow = (row: CSVRow, formatNumber: (num: number) =>
    string, removeDepartment: boolean, removeBreak: boolean, removeSupplement: boolean): string[] =>
    <string[]> row
        .slice(removeDepartment ? 1 : 0, 7)
        .concat([formatNumber(row[7])])
        .concat(removeBreak ? [] : [formatNumber(row[8])])
        .concat([formatNumber(row[9])])
        .concat(removeSupplement ? [] : [formatNumber(row[10])])
        .concat([formatNumber(row[11])]);
const formatRows = (removeDepartment: boolean, removeBreak: boolean, removeSupplement: boolean) =>
    (formatNumber: (num: number) => string) => (rows: CSVRow[]) =>
        rows.map((value: CSVRow) => formatRow(value, formatNumber, removeDepartment, removeBreak, removeSupplement));

const convertRowToCSVString = (row: string[]): string =>
    _.map(row, (val: string) => `"${ val }"`).join(';');
const convertRowsToCSVString = (arrayOfRows: string[][]) =>
    arrayOfRows.reduce((csvString: string, row: string[]) => csvString + convertRowToCSVString(row) + '\n', '');


/**
 * Returns a csv-formatted string of the exported employees with salary and workhour totals
 * @param employeesWithSalary The employees to export
 * @param translateService A translateService instance, to provide translations and current locale
 */
export async function generateCSVExport(
    shifts: Shift[],
    translateService: TranslateService,
    range: SalaryRange,
    shiftBreak: ShiftBreak | null,
    salaryService: SalaryService,
    supplementService: SalarySupplementService,
): Promise<void> {

    const shiftsWithEmployees = filterShiftsWithoutEmployees(shifts);

    const supplements: SalarySupplement[] = await supplementService.getSalarySupplements().pipe(take(1)).toPromise();

    const [removeDepartment, removeBreak, removeSupplement]: [boolean, boolean, boolean] =
        shouldRemoveDepartmentBreakSupplement(shiftsWithEmployees, shiftBreak, supplements);

    const prefixHeaderRow = await prepareHeaders(translateService, removeDepartment, removeBreak, removeSupplement);

    const formatNumber = buildNumberFormatter(translateService.currentLang);

    const getSortedShifts = _.flow(
        filterShiftsWithoutEmployees,
        sortShifts,
    );

    const createCSVExportString = _.flow(
        formatRows(removeDepartment, removeBreak, removeSupplement)(formatNumber),
        convertRowsToCSVString,
        prefixHeaderRow,
    );

    const sortedShifts = getSortedShifts(shifts);
    const csvRows = await buildCSVRows(sortedShifts, shiftBreak, salaryService);
    const csvString = createCSVExportString(csvRows);
    const salary = await translateService.get('salary.salary').toPromise();
    const filename =
        `Relion ${ salary } ${ moment(range.start).format('YYYY-MM-DD') } - ${ moment(range.end).format('YYYY-MM-DD') }.csv`;

    saveCSV(csvString, filename);
}

function saveCSV(csvString: string, filename: string): void {
    // Used to signal that this is UTF-8 in Excel
    const utf8BOM = '\uFEFF';
    const fileContentType = 'text/csv;charset=utf-8';
    const blob = new Blob([utf8BOM + csvString], { type: fileContentType });
    FileSaver.saveAs(blob, filename);
}

async function prepareHeaders(
    translateService: TranslateService, removeDepartment: boolean, removeBreak: boolean, removeSupplement: boolean):
    Promise<(csvString: string) => string> {

    const headerKeys =
        ['shared.department', 'shared.cpr-number', 'shared.name', 'shared.role', 'shared.comment',
            'shared.start', 'shared.end', 'shared.hours', 'salary.csv.headers.break', 'salary.csv.headers.hourly-wage',
            'salary.csv.headers.supplements', 'salary.csv.headers.total-salary'];
    if (removeBreak) headerKeys.splice(8, 1);
    if (removeDepartment) headerKeys.splice(0, 1);
    if (removeSupplement) headerKeys.splice(headerKeys.length - 2, 1);
    const translatedHeaders =
        await Promise.all(headerKeys.map(async (translationKey: string) =>
            toTitleCase(await translateService.get(translationKey).toPromise())),
        );

    return buildHeaderPrefixer(translatedHeaders);
}

const filterShiftsWithoutEmployees = (shifts: Shift[]) => shifts.filter((shift: Shift) => !!shift.employee) as ShiftWithEmployee[];

const sortShifts = (shifts: ShiftWithEmployee[]): ShiftWithEmployee[] =>
    shifts.sort((a: ShiftWithEmployee, b: ShiftWithEmployee) => {
        const aDepartment = a.role.department.name;
        const bDepartment = b.role.department.name;
        const aEmployee = formatEmployeeName(a.employee);
        const bEmployee = formatEmployeeName(b.employee);
        return aDepartment.localeCompare(bDepartment)
            || aEmployee.localeCompare(bEmployee)
            || a.start.getTime() - b.start.getTime()
            || a.end.getTime() - b.end.getTime();
    });

