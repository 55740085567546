import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { Moment } from 'moment';
import { DateRange, extendMoment, MomentRange } from 'moment-range';
import { combineLatest, Observable, of } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';
import { isSameDate } from '../../shared/utilities/DateUtils/isSameDate';
import { getShiftDuration } from '../../shared/utilities/ShiftUtils/durationUtils';
import { Employee, Role, Shift, Wish, WishType } from '../model';
import { Department } from '../model/Department/Department.model';
import { EmployeeService } from './Employee/EmployeeService.model';
import { ShiftService } from './Shift/ShiftService.model';
import { WishService } from './Wish/WishService.model';
const momentRange: MomentRange = extendMoment(moment);


export interface FillShiftsInput {
    start: Date;
    end: Date;
    department: Department;
}

@Injectable()
/*
 * This service is used by the calendar to determine conflicts
 */
export class PlannerService {

    constructor(
        private employeeService: EmployeeService,
        private shiftService: ShiftService,
        private wishService: WishService,
        private translateService: TranslateService,
    ) { }

    /**
     * Check if a shift is within the frame of a wish.
     * @param wish - The wish to check
     * @param shift - The shift to check
     */
    public static isShiftWithinWishTimes(wish: Wish | Omit<Wish, 'id'>, shift: Shift): boolean {
        // Return false if shift and wish are on different days
        if (!isSameDate(wish.from, shift.start)) return false;

        // Return true if from hours is before shift AND EITHER to hours is after shift OR wish.to is the day after the shift
        return ((wish.from <= shift.start) && (wish.to >= shift.end));
    }

    /**
     * Fills shifts based on wishes, first according to work wishes, then no wishes
     * @param {Shift[]} shifts The shifts to work on
     * @returns {Promise<string>} A promise that will resolve if no conflicts and reject with a reason if conflicts
     */
    public async fillShifts({ start, end, department }: FillShiftsInput): Promise<string> {

        const shiftsInRange: Shift[] =
            await this.shiftService.getShifts({ startDateFrom: start, startDateTo: end, department }).pipe(take(1)).toPromise();

        // Return with message if all shifts already filled
        if (!shiftsInRange.some((shift: Shift) => !shift.employee)) {
            return this.translateService.get('planner.shifts-are-asigned').toPromise();
        }

        return this.getEmployeesAndWishes(department).pipe(
            take(1),
            map(async ([employees, employeeWishes]: [Employee[], Map<string, Wish[]>]) => {
                try {
                    return await this.fillShiftsWithWishType(start, end, shiftsInRange, employees, WishType.WORK, employeeWishes);
                } catch (error) {
                    return await this.fillShiftsWithWishType(start, end, shiftsInRange, employees, WishType.NO_WISH, employeeWishes);
                }
            },
            )).toPromise();
    }

    /**
     * Fills the given shifts with employees based on their wishes and the given wishtype,
     * and then saves the updated shifts to the ShiftService
     * @param {Shift[]} shifts The shifts to work on
     * @param {WishType} wishType The wishtype to fill shifts with
     * @returns {Promise<string>} A promise that will resolve if no conflicts and reject with a reason if conflicts
     */
    public fillShiftsWithWishType(
        rangeStart: Date,
        rangeEnd: Date,
        shifts: Shift[],
        employees: Employee[],
        wishType: WishType,
        employeeWishes: Map<string, Wish[]>,
    ): Promise<string> {
        // Remove all the already assigned shifts from consideration
        const unassignedShifts = shifts.filter((shift: Shift) => !shift.employee);

        // Init a map holding the employees and the hours, they've worked so far with the plan
        const employeeHourMap: Map<string, number> = new Map<string, number>();
        // Put all employees in the map
        employees.forEach((emp: Employee) => employeeHourMap.set(
            emp.id,
            shifts.reduce((hours: number, cur: Shift) =>
                hours + (cur.employee?.id === emp.id ? getShiftDuration(cur) : 0), 0)),
        );
        // Get an array of the dates of this month sorted by wish count (lowest first)
        const datesSortedByWishCount: Date[] = this.getDatesSortedByWishCount(
            rangeStart,
            rangeEnd,
            wishType,
            employees,
            employeeWishes,
        );

        // For each date with wishes,
        datesSortedByWishCount.forEach((currentWorkingDate: Date) => {
            // Get the shifts of the current day
            const shiftsOfCurrentDay = unassignedShifts.filter((shift: Shift) => isSameDate(shift.start, currentWorkingDate));
            // Get the roles of the shifts of the current day
            const roleIDsOfCurrentDay = shiftsOfCurrentDay.map((shift: Shift) => shift.role.id);
            // Init the role => shifts object
            const roleToShifts: { [roleId: string]: Shift[] } = {};
            // Fill the role => shifts object
            for (const roleID of roleIDsOfCurrentDay) {
                roleToShifts[roleID] = shiftsOfCurrentDay.filter((shift: Shift) => shift.role.id === roleID);
            }

            // Hent employees der har meldt at de vil arbejde den pågældende dag
            const workingEmployees: Employee[] = employees.filter((employee: Employee) =>
                this.getWish(employee, currentWorkingDate, employeeWishes).type === wishType);

            // Get roles
            const roleIds: string[] = Object.keys(roleToShifts);

            // Sæt den employee der har færrest timer på vagten
            // For each role of that day
            roleIds.forEach((role: string) => {
                // For each shift of that role
                roleToShifts[role]!.forEach((shift: Shift) => {
                    // Get the employees that can take the shift
                    const employeesWithRole: Employee[] =
                        workingEmployees.filter((employee: Employee) =>
                            employee.roles &&
                            employee.roles.map((r: Role) => r.id).includes(shift.role.id) &&
                            !shifts.find((xShift: Shift) => {
                                return isSameDate(xShift.start, shift.start)
                                    && (!!xShift.employee && (xShift.employee.id === employee.id));
                            }) &&
                            PlannerService.isShiftWithinWishTimes(
                                this.getWish(employee, shift.start, employeeWishes),
                                shift,
                            ),
                        );

                    // If there are employees that can take the shift
                    if (employeesWithRole.length > 0) {
                        // Find emp with fewest hours
                        let min: Employee | undefined = employeesWithRole[0];
                        if (!min) return;
                        employeesWithRole.forEach((emp: Employee) => {
                            if (<number> employeeHourMap.get(emp.id) < (<number> employeeHourMap.get(min!.id))) {
                                min = emp;
                            }
                        });
                        // Assign employee
                        shift.employee = min;
                        // Add the length of the shift to the employee's hours
                        employeeHourMap.set(min.id, <number> employeeHourMap.get(min.id) + this.getShiftHoursAsNumber(shift));
                        // Slet employee så den ikke kan få flere vagter på den aktuelle dag
                        workingEmployees.splice(workingEmployees.indexOf(min), 1);
                    }
                });
            });
        });

        // Return a promise calling resolve on no conflicts and reject if there are conflicts
        return new Promise<string>(async (resolve: (msg: string) => void, reject: (msg: string) => void) => {
            const updateList: Shift[] = unassignedShifts
                .filter((shift: Shift) => !!shift.employee);

            if (unassignedShifts.find((shift: Shift) => !shift.employee)) {
                // There are conflict(s), if a shift has no employee, so reject with an appropriate message
                const msg: string = await this.createConflictMessage(unassignedShifts);
                if (updateList.length === 0) {
                    reject(msg);
                } else {
                    this.shiftService.updateShifts(updateList).pipe(take(1)).toPromise()
                        .then(() => reject(msg))
                        .catch(async () => reject(await this.translateService.get('planner.server-error').toPromise()));
                }
            } else {
                // No conflicts - resolve with success message
                this.shiftService.updateShifts(updateList).pipe(take(1)).toPromise()
                    .then(async () => resolve(await this.translateService.get('planner.shifts-assigned-succesfully').toPromise()))
                    .catch(async () => reject(await this.translateService.get('planner.server-error').toPromise()));
            }
        });
    }

    public getShiftHoursAsNumber(shift: Shift): number {
        const diffInMilliseconds = shift.end.valueOf() - shift.start.valueOf();
        const diffAsDate = new Date(diffInMilliseconds);
        const hours = diffAsDate.getHours();
        const minutes = diffAsDate.getMinutes();
        return Number(hours + '.' + minutes);
    }

    /**
     * Returns an observable of employees and a map of employee wishes
     * @param primaryDepartment The primary department that the employees must belong to
     */
    private getEmployeesAndWishes(primaryDepartment: Department): Observable<[Employee[], Map<string, Wish[]>]> {
        const employees$: Observable<Employee[]> = this.employeeService.getEmployees({ primaryDepartment });
        const employeeWishes$: Observable<Map<string, Wish[]>> =
            employees$.pipe(switchMap((employees: Employee[]) => this.getEmployeeWishMap(employees)));
        return combineLatest(
            employees$,
            employeeWishes$,
        ).pipe(take(1));
    }

    /**
     * Creates a conflict message for the end user explaining which days gave rise to conflicts
     * @param {Shift[]} shifts The shifts that need to be analyzed
     * @returns {string} The message for the user
     */
    private async createConflictMessage(shifts: Shift[]): Promise<string> {
        // Get the unique conflict days
        const conflictDates: Date[] = shifts.filter((shift: Shift) => !shift.employee).map((shift: Shift) => shift.start);
        const uniqueConflictDays: Date[] = [];
        for (const conflictDate of conflictDates) {
            if (!uniqueConflictDays.find((day: Date) => isSameDate(day, conflictDate))) {
                uniqueConflictDays.push(conflictDate);
            }
        }

        // Create the message
        let msg = '';
        if (uniqueConflictDays.length > 1) {
            uniqueConflictDays.sort((a: Date, b: Date) => a.getTime() - b.getTime());

            // If more than one conflict, set the string to the format "c1, c2, ... og cn"
            msg += await this.translateService.get('planner.conflict-days').toPromise();
            for (let i = 0; i < uniqueConflictDays.length - 1; i++) {
                const date: Date = uniqueConflictDays[i]!;
                msg += ' ' + date.getDate() + '/' + (date.getMonth() + 1) + ', ';
            }
            msg = msg.substring(0, msg.length - 2);
            const cd: Date = uniqueConflictDays[uniqueConflictDays.length - 1]!;
            msg += ' ' + await this.translateService.get('shared.and').toPromise() + ' ' + cd.getDate() + '/' + (cd.getMonth() + 1) + '.';
        } else {
            // Otherwise, just do format "c1".
            const cd: Date = uniqueConflictDays[0]!;
            msg = (await this.translateService.get('planner.conflicting-day').toPromise()) + cd.getDate() + '/' + (cd.getMonth() + 1 + '.');
        }
        return Promise.resolve(msg);
    }

    /**
     * Returns an observable of an employee wish map, based on the given employees
     * @param {Employee[]} employees The employees for which to retrieve wishes
     */
    private getEmployeeWishMap(employees: Employee[]): Observable<Map<string, Wish[]>> {
        if (!employees.length) return of(new Map());
        return combineLatest(
            employees.map((employee: Employee) =>
                this.wishService.getWishes({ employee })
                    .pipe(
                        map((wishes: Wish[]) => ({ employeeID: employee.id, wishes })),
                    ),
            ),
        ).pipe(
            // Converts the array of objects with employee.id and wishes into a map
            map((employeeWishesObjectArray: { employeeID: string, wishes: Wish[] }[]) =>
                employeeWishesObjectArray.reduce((acc: Map<string, Wish[]>, val: { employeeID: string, wishes: Wish[] }) =>
                    acc.set(val.employeeID, val.wishes), new Map<string, Wish[]>())),
        );
    }

    /**
     * Given a date range, returns a sorted list of each date in the range, in order of wish counts
     * @param {Date} month
     * @returns {Date[]}
     */
    private getDatesSortedByWishCount(
        start: Date,
        end: Date,
        wishType: WishType,
        employees: Employee[],
        employeeWishes: Map<string, Wish[]>,
    ): Date[] {

        const wishCount: { [date: string]: number } = {};
        const datesInRange: Date[] = this.getDatesInRange(start, end);

        // fills the wishCount array, with each day of the date span
        datesInRange.forEach((date: Date) => {
            wishCount[date.toDateString()] = 0;
        });

        const dateRange: DateRange = momentRange.range(start, end);

        // Goes through all wishes and increments the wishCount array, for the corresponding date (if the wish is in the range)
        // and the wish type is to WORK
        employees.forEach((employee: Employee) => {
            const curEmployeeWishes: Wish[] | undefined = employeeWishes.get(employee.id);
            if (curEmployeeWishes) {
                curEmployeeWishes.forEach((wish: Wish) => {
                    if (wish.type === wishType && wish.type !== WishType.NO_WISH && dateRange.contains(wish.from)) {
                        return wishCount[wish.from.toDateString()] += 1;
                    }
                });
            }
        });

        // Converts the array into a 2-tuple of date and wishCount
        const wishTuples: [Date, number][] = [];
        for (const key in wishCount) {
            if (wishCount.hasOwnProperty(key)) {
                wishTuples.push([new Date(key), wishCount[key]!]);
            }
        }

        // Sorts the tuple
        const sortedTuples: [Date, number][] = wishTuples.sort((a: [Date, number], b: [Date, number]) => a[1] - b[1]);

        // Maps the tuple into a (sorted) list of dates
        return sortedTuples.map((entry: [Date, number]) => entry[0]);
    }

    private getDatesInRange(startDate: Date, endDate: Date): Date[] {
        const dates: Date[] = [];

        const currDate: Moment = moment(startDate).startOf('day');
        const lastDate: Moment = moment(endDate).startOf('day');

        do dates.push(currDate.clone().toDate());
        while (currDate.add(1, 'days').diff(lastDate) < 0);

        return dates;
    }


    /**
     * Returns the type of the wish on a given date. If there is no wish in the wishes given, a NO_WISH is returned
     * @param wishes The wishes to search through
     * @param date The date for which to find the wishtype
     */
    private getWish(employee: Employee, date: Date, employeeWishes: Map<string, Wish[]>): Wish | Omit<Wish, 'id'> {
        const newWish = (): Omit<Wish, 'id'> => {
            const from: Date = new Date(date.toDateString());
            const to: Date = new Date(date.toDateString());
            to.setDate(to.getDate() + 1);
            return { from, to, employee, type: WishType.NO_WISH };
        };

        const wishes: Wish[] | undefined = employeeWishes.get(employee.id);
        const wish: Wish | undefined = wishes && wishes.find((w: Wish) => isSameDate(w.from, date));
        return wish ? wish : newWish();
    }
}
