import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { debounceTime, filter, switchMap, take } from 'rxjs/operators';
import { Company } from '../../../../../../../core/model';
import { CompanyService } from '../../../../../../../core/services';
import { CustomValidators } from '../../../../../../../shared/utilities/FormUtils';

@Component({
    selector: 'app-general-settings',
    templateUrl: './general-settings.component.html',
    styleUrls: ['./general-settings.component.scss'],
})
export class GeneralSettingsComponent implements OnInit {
    public formGroup: FormGroup;
    public company: Company;

    constructor(
        private companyService: CompanyService,
        private cdr: ChangeDetectorRef,
    ) {
        // Init dummy company for ngModel template two-way binding
        this.company = { id: '', name: '' };
    }
    public async ngOnInit(): Promise<void> {
        this.formGroup = this.buildFormGroup();

        // Get the current company
        this.companyService.getCurrentCompany().pipe(take(1)).toPromise()
            .then((company: Company) => {
                // Clone the company, so we don't edit it directly
                this.company = { ...company };
                this.setupUpdateListener();
            });
    }

    /**
     * Builds and returns a form group, including form controls with validators.
     */
    private buildFormGroup(): FormGroup {
        const COMPANY_NAME: FormControl = new FormControl('', [Validators.required, CustomValidators.CompanyNameValidator]);
        const COMPANY_INVOICE_MAIL: FormControl = new FormControl('', Validators.email);
        const COMPANY_VAT: FormControl = new FormControl('', CustomValidators.VATValidator);
        const COMPANY_STREET: FormControl = new FormControl('', CustomValidators.StreetValidator);
        const COMPANY_ZIP: FormControl = new FormControl('', CustomValidators.ZipValidator);
        const COMPANY_CITY: FormControl = new FormControl('', CustomValidators.CityValidator);

        return new FormGroup({ COMPANY_NAME, COMPANY_INVOICE_MAIL, COMPANY_VAT, COMPANY_STREET, COMPANY_ZIP, COMPANY_CITY });
    }

    /**
     * Sets up the listener for changes in the form
     */
    private setupUpdateListener(): void {
        // Trigger change detection to avoid triggering an update when loading the company into the form
        this.cdr.detectChanges();

        this.formGroup.valueChanges.pipe(
            // Debounce 1 second to avoid spamming the updateCompany
            debounceTime(1000),
            // Only emit values when the form is valid
            filter(() => this.formGroup.status === 'VALID'),
            // Update the company
            switchMap(() => this.companyService.updateCompany(this.company).pipe(take(1))),
        ).subscribe();
    }
}
