import { Pipe, PipeTransform } from '@angular/core';
import { DateService } from '../../core/services';

@Pipe({
    name: 'dateToDecimals',
})
export class DateToDecimalsPipe implements PipeTransform {

    /**
     * Converts a date to decimal.
     */
    public transform(date: Date): number {
        return DateService.getHourDecimalsFromDate(date);
    }

}
