<app-upgrade-overlay-base [hideQuote]="paymentState !== PaymentState.INFO"
                          (close)="close()"
                          [ngSwitch]="paymentState">
    <ng-container *ngSwitchCase="PaymentState.INFO">
        <div class="title">{{ 'modal.upgrade-overlay.pro.title' | translate }}</div>
        <div *ngIf="!loading && !hideInputForm"
             class="subtitle">{{ 'modal.upgrade-overlay.pro.subtitle' | translate }}</div>
        <div *ngIf="!loading && hideInputForm"
             class="subtitle">{{ 'modal.upgrade-overlay.pro.subtitle2' | translate }}</div>
        <div *ngIf="loading"><i class="loading-spinner fad fa-spinner-third fa-spin"></i></div>
        <div *ngIf="!loading"
             class="d-flex flex-row">
            <div *ngIf="!hideInputForm"
                 class="payment-details">
                <ng-form [formGroup]="formGroup">
                    <!-- EMPLOYEE NAME -->
                    <input formControlName="EMPLOYEE_NAME"
                           required
                           (blur)="trimWhitespace()"
                           [appFloatingLabel]="'shared.your-name' | translate"
                           [errorMessage]="'error.input.user-name' | translate"
                           [(ngModel)]="employeeName" />

                    <!-- COMPANY NAME -->
                    <input formControlName="COMPANY_NAME"
                           required
                           (blur)="trimWhitespace()"
                           [appFloatingLabel]="'settings.company-information.name' | translate"
                           [errorMessage]="'error.input.company-name' | translate"
                           [(ngModel)]="company.name" />

                    <!-- COMPANY INVOICE EMAIL -->
                    <input formControlName="COMPANY_INVOICE_MAIL"
                           required
                           (blur)="trimWhitespace()"
                           [appFloatingLabel]="'settings.company-information.invoice-mail' | translate"
                           [errorMessage]="'error.input.invoice-mail' | translate"
                           [(ngModel)]="company.invoiceMail" />

                    <!-- COMPANY VAT -->
                    <input formControlName="COMPANY_VAT"
                           required
                           (blur)="trimWhitespace()"
                           [appFloatingLabel]="'shared.vat' | translate "
                           [errorMessage]="'error.input.vat' | translate"
                           [(ngModel)]="company.VAT" />

                    <!-- COMPANY STREET -->
                    <input formControlName="COMPANY_STREET"
                           required
                           (blur)="trimWhitespace()"
                           [appFloatingLabel]="'settings.company-information.address' | translate"
                           [errorMessage]="'error.input.street' | translate"
                           [(ngModel)]="company.street" />

                    <div class="zip-city d-flex flex-row">
                        <!-- COMPANY ZIP -->
                        <input class="zip"
                               formControlName="COMPANY_ZIP"
                               required
                               (blur)="trimWhitespace()"
                               [appFloatingLabel]="'shared.zip-code' | translate"
                               [errorMessage]="'error.input.zip' | translate"
                               [(ngModel)]="company.zip" />

                        <!-- COMPANY CITY -->
                        <input class="city"
                               formControlName="COMPANY_CITY"
                               required
                               (blur)="trimWhitespace()"
                               [appFloatingLabel]="'settings.company-information.city' | translate"
                               [errorMessage]="'error.input.city' | translate"
                               [(ngModel)]="company.city" />
                    </div>
                </ng-form>
            </div>
            <div class="plan-details d-flex flex-column align-items-center">
                <div class="d-flex flex-row align-items-center toggle-container">
                    {{'modal.pro-shop.monthly' | translate}} <ui-toggle [(checked)]="yearlySubscription"></ui-toggle>
                    {{'modal.pro-shop.yearly' | translate}}
                </div>
                <div class="price">
                    {{'freemium.dkk' | translate: { 'amount': pricePerMonth$ | async | number: '1.0-0'} }}
                </div>
                <div class="price-details">{{ 'modal.pro-shop.per-user-per-month' | translate }}</div>
                <div class="d-flex flex-column checklist">
                    <div class="d-flex flex-row align-items-center">
                        <i class="far fa-check"></i>
                        <span>{{ 'modal.upgrade-overlay.pro.access-all-features' | translate }}</span>
                    </div>
                    <div class="d-flex flex-row align-items-center">
                        <i class="far fa-check"></i>
                        <span>{{ 'modal.upgrade-overlay.pro.unlimited-departments' | translate }}</span>
                    </div>
                    <div class="d-flex flex-row align-items-center">
                        <i class="far fa-check"></i>
                        <span>{{'modal.pro-shop.best-employee-experience' | translate}}</span>
                    </div>
                </div>
                <div class="total-price-container d-flex flex-column flex-start">
                    <div class="calculation">
                        {{'freemium.dkk' | translate: { 'amount': pricePerMonth$ | async | number: '1.0-0' } }}
                        x
                        {{employeeCount$ | async}}
                        {{ 'shared.users' | translate }}
                        <ng-container *ngIf="yearlySubscription"> x 12
                            {{ 'shared.months' | translate }}
                        </ng-container>
                        <i class="fas fa-question-circle"
                           [matTooltip]="'modal.pro-shop.rounding-explainer' | translate"
                           [matTooltipShowDelay]="0"></i>
                    </div>
                    <div class="total-price">
                        {{ 'freemium.dkk' | translate: { 'amount': totalPrice$ | async | number: '1.0-0' } }}</div>
                    <div class="total-price-subtext">{{'modal.pro-shop.ex-vat' | translate}}</div>
                </div>
                <ui-button-action [disabled]="goingToPayment"
                                  [loading]="goingToPayment"
                                  (click)="goToPayment()"
                                  [wide]="true">{{'freemium.go-to-payment' | translate}}</ui-button-action>
            </div>
        </div>
    </ng-container>
    <ng-container *ngSwitchCase="PaymentState.ADDING_CARD">
        <div><i class="loading-spinner fad fa-spinner-third fa-spin"></i></div>
        <div class="title">{{ 'modal.upgrade-overlay.pro.complete-payment' | translate }}</div>
        <div class="subtitle">{{'modal.upgrade-overlay.pro.waiting-for-card-input' | translate}}</div>
        <ui-button-standard [loading]="loadingCardUrl"
                            [disabled]="loadingCardUrl"
                            (click)="openPaymentTab()">{{ 'modal.upgrade-overlay.pro.open-card-tab' | translate }}
        </ui-button-standard>
    </ng-container>
    <ng-container *ngSwitchCase="PaymentState.CREATING_SUBSCRIPTION">
        <div><i class="loading-spinner fad fa-spinner-third fa-spin"></i></div>
        <div class="payment-preparing">{{ 'modal.upgrade-overlay.pro.preparing-payment' | translate }}</div>
        <div class="title">{{ 'modal.upgrade-overlay.pro.starting-subscription' | translate }}</div>
    </ng-container>
    <ng-container *ngSwitchCase="PaymentState.DONE">
        <div><i class="far fa-check done-check"></i></div>
        <div class="title">{{ 'modal.upgrade-overlay.pro.payment-complete' | translate }}</div>
        <div class="subtitle">{{ 'modal.upgrade-overlay.pro.access-to-every-feature' | translate }}</div>
        <ui-button-action (click)="close()"> {{ 'modal.upgrade-overlay.pro.okay' | translate }}! </ui-button-action>
    </ng-container>
</app-upgrade-overlay-base>