<ng-container *ngIf="!(mediaService.observeMediaChanges() | async)">
    <app-settings-divider [title]="'settings.notifications' | translate"
                          [mobile]="true"></app-settings-divider>
    <div class="notification-settings">
        <app-notification-settings-toggle
                *ngFor="let notificationSetting of notificationSettings"
                [title]="notificationSetting.key | translate"
                [notificationType]="notificationSetting.notificationType"
                [checked]="(disabledNotifications$ | async)?.indexOf(notificationSetting.notificationType) === -1"
                (checkedChange)="onChangeNotificationSettings($event)">
        </app-notification-settings-toggle>
    </div>
</ng-container>
