import { AfterViewInit, Component, EventEmitter, Injectable, Input, Output, ViewChild } from '@angular/core';
import { NgbDate, NgbDatepicker, NgbDatepickerI18n } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';

export enum Color {
    PRIMARY,
    RED,
    SUCCESS,
    WARN,
    GREY,
}

export enum SelectionStyle {
    DOT,
    CIRCLE,
}

@Injectable()
export class CustomDatepickerI18n extends NgbDatepickerI18n {
    private days: string[] = [];
    constructor(private translateService: TranslateService) {
        super();
        type StringManipulator = (str: string) => string;
        const getFirstLetter: StringManipulator = (str: string): string => str.substr(0, 1);
        const capitalize: StringManipulator = (str: string): string => _.clone(str).toUpperCase();
        const firstLetterCaps: StringManipulator = (str: string): string => capitalize(getFirstLetter(str));
        const getTranslation: (service: TranslateService, str: string) => Promise<string>
            = (service: TranslateService, id: string): Promise<string> => service.get(id).toPromise();

        const getDays: (service: TranslateService) => Promise<string[]> = (service: TranslateService): Promise<string[]> => Promise.all([
            'shared.monday',
            'shared.tuesday',
            'shared.wednesday',
            'shared.thursday',
            'shared.friday',
            'shared.saturday',
            'shared.sunday',
        ].map((str: string) => getTranslation(service, str)));
        getDays(this.translateService).then((days: string[]) => this.days = days.map(firstLetterCaps));
    }
    // These methods are not in use but are required to be imported
    public getMonthShortName = (): string => '';
    public getMonthFullName = (): string => '';
    public getDayAriaLabel = (): string => '';

    // This method overwrites the Weekday names and replaces them with single letter abreviations in chosen language
    public getWeekdayShortName(weekday: number): string {
        try {
            return this.days[weekday - 1]!;
        } catch {
            throw Error('Unexpected weekday number from');
        }
    }
}
@Component({
    selector: 'ui-date-picker',
    templateUrl: './date-picker.component.html',
    styleUrls: ['./date-picker.component.scss'],
    providers: [{ provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n }],
})
export class DatePickerComponent implements AfterViewInit {
    @ViewChild('ngbDatepicker') public ngbDatepicker: NgbDatepicker;
    @Input() public selectionStyle: SelectionStyle;
    @Output() public dateSelect: EventEmitter<Date>;
    public selectionStyles: typeof SelectionStyle = SelectionStyle;
    public color: typeof Color = Color;
    public model: NgbDate;

    private _startDate: NgbDate;
    @Input() public set startDate(date: Date) {
        const newDate: NgbDate = new NgbDate(date.getFullYear(), date.getMonth() + 1, date.getDate());
        this._startDate = newDate;
        this.model = newDate;
        if (this.ngbDatepicker) this.ngbDatepicker.navigateTo(this._startDate);
    }

    private _colorDateMap: Map<string, Color>;
    @Input() public set colorDateMap(map: Map<Date, Color>) {
        this._colorDateMap = new Map();
        if (map) map.forEach((value: Color, key: Date) => this._colorDateMap.set(key.toDateString(), value));
    }

    constructor() {
        this.dateSelect = new EventEmitter();
    }

    // This method changes the colour of the weeknumbers and makes the current weeknumber a different colour
    public ngAfterViewInit(): void {
        this.updateWeekNumberColour();
    }

    /**
     * This method checks whether or not the map exists and if it does gets the date sent as a parameter
     * but as a Date instead of a NgbDate and makes it a string
     */
    public getColor(date: NgbDate): Color | undefined {
        return this._colorDateMap && this._colorDateMap.get(new Date(date.year, date.month - 1, date.day).toDateString());
    }

    /**
     * This method is called on the datePicker and takes the selected date and emits it to whomever that wants to listen
     * @param date The selected date
     */
    public onDateSelect(date: NgbDate): void {
        const jsDate: Date = new Date(date.year, date.month - 1, date.day);
        this.dateSelect.emit(jsDate);
    }

    /**
     * When clicking on another day/month, the method fades all weeknumbers and colors the current weeknumber text-primary
     */
    public updateWeekNumberColour(): void {
        setTimeout(() => {
            Array.from((document.getElementsByClassName('ngb-dp-week-number'))).forEach((el: Element) => el.classList.add('text-faded'));
            const today: Element = document.getElementsByClassName('ngb-dp-today')[0]!;
            if (today && today.parentElement) {
                today.parentElement.
                    getElementsByClassName('ngb-dp-week-number')[0]!.classList.replace('text-faded', 'text-primary');
            }
        }, 0);
    }
}
