<app-upgrade-overlay-base (close)="close()">
    <div class="title">{{ 'modal.confirm-go-back-to-free.title' | translate }}</div>
    <div class="subtitle">{{ 'modal.confirm-go-back-to-free.subtitle' | translate }}</div>
    <div class="description">
        <div class="image">
            <img src="assets/images/rain.svg"
                 alt="Illustration of a rainy day">
        </div>
        <div class="features-and-cta">
            <ul class="bullets">
                <li>{{ 'modal.upgrade-overlay.shared.bullet-1' | translate }}</li>
                <li>{{ 'modal.upgrade-overlay.shared.bullet-2' | translate }}</li>
                <li>{{ 'modal.upgrade-overlay.shared.bullet-3' | translate }}</li>
            </ul>
            <div class="cta">
                <ui-button-action (click)="close()"
                                  color="warn">
                    {{ 'modal.upgrade-overlay.trial-expired.go-back-to-free' | translate }}
                </ui-button-action>
                <ui-button-standard (click)="upgrade()">
                    {{ 'modal.confirm-go-back-to-free.cta' | translate }}
                </ui-button-standard>
            </div>
        </div>
    </div>
</app-upgrade-overlay-base>