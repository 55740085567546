import { ChangeDetectorRef, NgZone, TemplateRef, ViewContainerRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { Package } from '../../../core/model/Freemium/Package';
import { ConfigurationService } from '../../../core/services/Configuration/configuration-service.model';

export class IfPro {

    private isPro: Subscription;

    constructor(
        // Reference to the element on which this directive is placed
        private templateRef: TemplateRef<HTMLElement>,
        // Reference to the containing view of the element on which this directive is placed
        private viewContainer: ViewContainerRef,
        private configurationService: ConfigurationService,
        private ngZone: NgZone,
        private cdf: ChangeDetectorRef,
    ) { }

    public init(packageType: Package, reverse: boolean): void {
        // Subscribe to the schedule pro observable and render the template accordingly
        this.isPro = this.configurationService.isPro(packageType).subscribe({
            next: ((isPro: boolean): void => this.ngZone.run(() => this.renderTemplate(reverse ? !isPro : isPro))),
        });
    }

    public destroy(): void {
        this.isPro.unsubscribe();
    }

    /**
     * Render the referenced template or clear it
     * @param shouldRender - Whether or not, the referenced template should be rendered
     */
    private renderTemplate(shouldRender: boolean): void {
        // If should not render, clear the the view and return
        if (!shouldRender) return this.viewContainer.clear();

        // Else check that it's not already rendered and then render the template
        if (this.viewContainer.length === 0) this.viewContainer.createEmbeddedView(this.templateRef);
        this.cdf.markForCheck();
    }
}
