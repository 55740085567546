import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { NotificationType } from '../../../../../functions/src/notifications/model/NotificationType';
import { UserSettings } from '../../../core/model/UserSettings';
import { MediaService, UserSettingsService } from '../../../core/services';
import { changeNotificationSettings } from '../../utilities/NotificationUtils/changeNotificationSettingsHelper';

@Component({
    selector: 'app-push-notification-settings',
    templateUrl: './push-notification-settings.component.html',
    styleUrls: ['./push-notification-settings.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PushNotificationSettingsComponent implements OnInit {
    @Input() public notificationSettings: { key: string, notificationType: NotificationType }[];
    public disabledNotifications$: Observable<NotificationType[]>;

    constructor(
        public mediaService: MediaService,
        private userSettingsService: UserSettingsService,
    ) {
    }

    public ngOnInit(): void {
        this.disabledNotifications$ = this.userSettingsService.loadSetting(UserSettings.DISABLED_NOTIFICATIONS);
    }

    public async onChangeNotificationSettings(event: { checked: boolean, notificationType: NotificationType }): Promise<void> {
        const disabledNotifications: NotificationType[] = await this.disabledNotifications$
            .pipe(take(1))
            .toPromise();

        changeNotificationSettings(
            event.checked,
            event.notificationType,
            disabledNotifications,
            this.userSettingsService);
    }
}
