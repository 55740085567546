import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { InputsType } from 'ng-dynamic-component';

@Injectable({
    providedIn: 'root',
})
export class SideDrawerService {

    public components: { component: ComponentType<unknown>, inputData: InputsType }[];
    public opened: boolean;

    constructor() {
        this.components = [];
    }

    /**
     * Set the opened state of the side drawer
     */
    public setOpened(opened: boolean): void {
        this.opened = opened;
        if (!opened) this.components = [];
    }

    /**
     * Open the side drawer with a specified component
     * @param component The component to open in the side drawer
     */
    public open(component: ComponentType<unknown>, inputData?: InputsType): void {
        this.components.push({ component, inputData: { ...inputData, back: this.pop.bind(this) } });
        this.opened = true;
    }

    /**
     * Pop the topmost component
     */
    public pop(): void {
        this.components.pop();
        if (this.components.length === 0) this.setOpened(false);
    }
}
