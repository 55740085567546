<div class="approve-punch-container"
     *ngIf="start && end">
    <ui-time-input [(start)]="start"
                   [(end)]="end"
                   [punchclock]="true"
                   [bigScreen]="false"></ui-time-input>
    <ui-button-action [wide]="true"
                      [tall]="true"
                      (click)="approve()"
                      color="success">{{ 'modal.shift-detail.approve-punch' | translate }}</ui-button-action>
</div>