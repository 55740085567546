<div class="main">
    <ui-text-input [title]="'shared.name' | translate"
                   [(value)]="partialRole.name">
    </ui-text-input>
    <div class="right"
         *ngIf="!saving">
        <span class="remove"
              *ngIf="!!partialRole?.id && (departmentRoles$ | async)?.length > 1"
              (click)="remove()">
            {{ 'shared.remove' | translate | capitalize }}
        </span>
        <span class="cancel"
              (click)="close()">{{'shared.cancel' | translate }}</span>
        <span class="save"
              (click)="save()">
            {{(partialRole?.id ? 'shared.save' : 'shared.add') | translate | capitalize}}
        </span>
    </div>
    <div class="right saving"
         *ngIf="saving">
        <i class="fa fa-spin fa-spinner"></i>
    </div>
</div>
