import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { SalaryIntegration } from '../../../../../../../../../../core/model/Integrations/Shared';

@Component({
    selector: 'app-dataloen-why-match-modal',
    templateUrl: './dataloen-why-match-modal.component.html',
    styleUrls: ['./dataloen-why-match-modal.component.scss'],
})
export class DataloenWhyMatchModalComponent {

    public integration: SalaryIntegration = SalaryIntegration.DATALOEN;

    constructor(public modalRef: MatDialogRef<DataloenWhyMatchModalComponent>) { }
}
